<template>
  <v-card>
    <v-dialog
      v-model="openDialog"
      max-width="700px"
    >
      <v-card
        class="pa-4"
      >
        <v-card-title
          class="mb-4"
        >
          {{ $t('map.locationsSync.sync') }}
        </v-card-title>
        <v-card-text>
          {{ $t('map.locationsSync.mapNotHavingStockLoc') + ': ' + getStockLocationsToCreate.length }}

          <v-btn
            small
            depressed
            :disabled="getStockLocationsToCreate.length === 0"
            color="accent"
            class="mb-4 ml-4 mt-4"
            @click="createStockLocations"
          >
            <v-icon
              left
            >
              $addItem
            </v-icon>
            {{ $t('map.locationsSync.createStockLocCorrespondingMap') }}
          </v-btn>
        </v-card-text>
        <v-card-text>
          {{ $t('map.locationsSync.stockNotHavingMapLoc') + ': ' + getNotMappedStockLocations.length }}

          <v-btn
            small
            depressed
            :disabled="getNotMappedStockLocations.length === 0"
            color="accent"
            class="mb-4 ml-4 mt-4"
            @click="deleteUnmappedStockLocations"
          >
            <v-icon
              left
            >
              $deleteObject
            </v-icon>
            {{ $t('map.locationsSync.deleteStockLocCorrespondingMap') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
    import {MapMixin} from "@/app/mixins/MapMixin";
    export default {
        name: 'SynchronizeLocationsDialog',
        mixins: [MapMixin],
        props: {
            activate: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
        }),
        computed: {
            openDialog: {
                get: function () {
                    return this.activate;
                },
                set: function (value) {
                    if (!value) {
                        this.closeDialog();
                    }
                }
            },
            getNotMappedStockLocations(){
                return this.$store.getters["storageMap/getNotMappedStockLocations"];
            },
            getStockLocationsToCreate(){
                return this.$store.getters["storageMap/getStockLocationsToCreate"];
            },
        },
        methods: {
            closeDialog: function () {
                this.$emit("closeDialog");
            },
            createStockLocations: function () {
                this.$emit("startSyncCreateStockLocations");
                this.closeDialog();
            },
            deleteUnmappedStockLocations: function () {
                this.$emit("startSyncDeleteUnmappedStockLocations");
                this.closeDialog();
            },
        }
    };
</script>

<style scoped lang="sass">

.mapWindow
  height: 50vh
  overflow: auto
  background-color: var(--v-mapViewBackground-base) !important

</style>
