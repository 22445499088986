import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),(_vm.deletedProduct !== null)?_c('ConfirmDeleteDialog',{attrs:{"show":_vm.confirmDialog,"text":_vm.deletedProduct.name + ', ' + _vm.deletedProduct.model + ', ' + _vm.deletedProduct.manufacturer.name},on:{"update:show":function($event){_vm.confirmDialog=$event},"confirm-delete":_vm.reallyDeleteProduct}}):_vm._e(),_c(VCard,[_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"item-class":item => item.visible === false && 'text--disabled',"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload,"search-hint":_vm.$t('products.searchHint')},on:{"update:loading":function($event){_vm.loading=$event},"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"item.tableImage",fn:function({ item }){return [_c('td',[(item.default_image !== null)?_c(VImg,{staticStyle:{"float":"right"},attrs:{"src":item.default_image,"contain":"","max-height":"40px","max-width":"40px"}}):_vm._e()],1)]}},{key:"item.can_have_batch",fn:function({ item }){return [_c('span',{class:{
            'text--disabled': !item.can_have_batch
          }},[_vm._v(" "+_vm._s(_vm._f("yesOrNo")(item.can_have_batch))+" ")])]}},{key:"item.can_have_serial_number",fn:function({ item }){return [_c('span',{class:{
            'text--disabled': !item.can_have_serial_number
          }},[_vm._v(" "+_vm._s(_vm._f("yesOrNo")(item.can_have_serial_number))+" ")])]}},{key:"item.created_at",fn:function({ item }){return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.created_at}})]}},{key:"footer",fn:function(){return [_c('TableAddItemButton',{attrs:{"to":_vm.productCreateLink,"label":"products.create.title"}})]},proxy:true}])})],1),_c('x-btn-fab',{attrs:{"to":_vm.productCreateLink},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VIcon,[_vm._v("$addItem")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }