import {config} from "@/config/config";

const routerModule = {
    namespaced: true,
    persistent: false,
    state: {
        lastPath: '/',
        lastName: config.DOMAIN_LABEL
    },
    mutations: {
        setLastPath(state, path) {
            state.lastPath = path;
        },
        setLastName(state, name) {
            state.lastName = name || config.DOMAIN_LABEL;
        }
    },
    getters: {
        getLastPath: state => state.lastPath,
        getLastName: state => state.lastName
    }
};

export default routerModule;
