export default {
    title: "Постачальники",
    link: "Постачальники",

    name: "Назва",
    ico: "ЄДРПОУ",
    dic: "ІПН",
    phone: "Телефон",
    email: "E-mail",
    website: "Веб-сайт",
    billing_address: "Юридична адреса",

    list: {
        unableToLoad: "Неможливо завантажити список постачальників. Будь ласка, спробуйте пізніше."
    },
    show: {
        title: "Постачальник",
    },
    create: {
        label: "Створити нового постачальника",
        title: "Створити постачальника",
        done: "Постачальника '{0}' створено.",
        undo: {
            done: "Створення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити створення постачальника!"
        }
    },
    update: {
        titleSimple: "Оновити постачальника",
        title: "Оновити {0}",
        done: "Постачальника '{0}' оновлено",
        undo: {
            done: "Оновлення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити оновлення постачальника!"
        }
    },
    delete: {
        done: "Постачальника '{0}' видалено.",
        failed: "Постачальника '{0}' неможливо видалити. Будь ласка, спробуйте пізніше.",
        undo: {
            done: "Видалення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити видалення постачальника!"
        },
        status: {
            404: "Вказаний постачальник не існує!",
            409: "Неможливо видалити постачальника через його зв'язки з іншими об'єктами!"
        }
    },
    status: {
        404: "Вказаний постачальник не існує!",
        409: "Введений ЄДРПОУ вже має інший постачальник!"
    }
};