export default {
    link: "Перевізники",
    title: "Перевізники",
    name: "Назва",
    type: "Тип",
    active: "Активний",
    notActive: "Неактивний",
    services: {
        title: "Сервіси",
        noServices: "Немає доступних сервісів",
        showAll: "Показати всі",
        hideAll: "Сховати всі",

        name: "Сервіс",
        carrier_service_parameters: "Параметри посилок",
        required_carrier_service_parameters: "Обов'язкові параметри посилок",
        optional_carrier_service_parameters: "Необов'язкові параметри посилок",
        type: "Формат друку етикеток",
        create: {
            title: "Створити сервіс перевізника",
            label: "Створити новий сервіс перевізника",
            done: "Сервіс перевізника '{0}' створено."
        },
        update: {
            titleSimple: "Оновити сервіс перевізника",
            title: "Оновити {0}",
            done: "Сервіс перевізника '{0}' оновлено"
        },
        delete: {
            done: "Сервіс перевізника '{0}' видалено.",
            failed: "Сервіс перевізника '{0}' неможливо видалити. Будь ласка, спробуйте пізніше.",
        },
    },
    tableImage: "",
    tableFoilImage: "Фольга",
    doesNotSupportMultiPackageShipments: "Цей перевізник не підтримує багатопакетні посилки",
    searchHint: "Шукайте за назвою та типом",

    show: {
        titleSimple: "Деталі перевізника",
        configInSubstock: "Конфігурацію підключення до перевізника можна знайти в деталях кожного підпорядкованого складу.",
    },

    create: {
        title: "Створити перевізника",
        done: "Перевізника '{0}' створено."
    },
    update: {
        titleSimple: "Оновити перевізника",
        title: "Оновити {0}",
        done: "Перевізника '{0}' оновлено"
    },
    delete: {
        done: "Перевізника '{0}' видалено.",
        failed: "Перевізника '{0}' неможливо видалити. Будь ласка, спробуйте пізніше.",
    },
    status: {
        404: "Вказаного перевізника не існує!",
        409: "Введена назва вже існує!"
    },

    images: {
        nameFoil: "Зображення фольги",
        alt: "Зображення, призначене для перевізника",
        altCreate: "Зображення для призначення перевізнику",
        unableToLoad: "Неможливо завантажити зображення перевізника. Будь ласка, спробуйте пізніше.",
        create: {
            foil: {
                titleSimple: "Додати зображення фольги",
                title: "Додати зображення фольги до '{0}'",
                done: "Зображення фольги додано до '{0}'"
            }
        },
        update: {
            foil: {
                titleSimple: "Оновити зображення фольги",
                title: "Оновити зображення фольги '{0}'",
                done: "Зображення фольги '{0}' оновлено"
            }
        },
        status: {
            404: "Перевізник або зображення не знайдено!"
        }
    },

    contracted: {
        label: "Законтрактовано",
        parameters: {
            required: {
                name: "Необхідні параметри"
            },
            optional: {
                name: "Інші необов'язкові параметри"
            },
            key: "Назва",
            value: "Значення",
            writeOnly: "Неможливо прочитати з міркувань безпеки"
        },

        create: {
            label: "Додати до списку законтрактованих перевізників власника",
            titleSimple: "Налаштувати параметри законтрактованого перевізника",
            title: "Налаштувати параметри '{0}'",
            info: "Ви налаштовуєте параметри '{0}' для '{1}'",
            done: "'{0}' додано до списку законтрактованих перевізників власника"
        },
        update: {
            label: "Оновити параметри законтрактованого перевізника власника",
            titleSimple: "Оновити параметри законтрактованого перевізника",
            title: "Оновити параметри '{0}'",
            info: "Ви оновлюєте параметри '{0}' для '{1}'",
            done: "Параметри '{0}' оновлено",
            substock: {
                title: "Укласти контракт для цього підскладу",
                done: "'{0}' зараз законтрактований для цього підскладу"
            }
        },
        delete: {
            titleSimple: "Видалити зі списку законтрактованих перевізників власника",
            done: "'{0}' видалено зі списку законтрактованих перевізників власника",
            substock: {
                title: "Скасувати контракт для цього підскладу",
                done: "'{0}' більше не законтрактований для цього підскладу"
            }
        }
    },

    run: {
        job: "Запустити роботу на фоні",
        validatePackages: "Валідація відправлень",
        generateProtocols: "Передавальні протоколи",
        trackPackages: "Відстеження відправлень"
    }
};
