const TaskStockTakingRestriction = {
    STOCK: 'STOCK',
    SUB_STOCK: 'SUB_STOCK',
    STOCK_LOCATION: 'STOCK_LOCATION',
    STOCK_OWNER: 'STOCK_OWNER',
    MANUFACTURER: 'MANUFACTURER',
    PRODUCT: 'PRODUCT',
    PRODUCT_INSTANCE: 'PRODUCT_INSTANCE'
};

const TaskStockTakingRestrictionIcon = {
    [TaskStockTakingRestriction.STOCK]: '$stock',
    [TaskStockTakingRestriction.SUB_STOCK]: '$substock',
    [TaskStockTakingRestriction.STOCK_LOCATION]: '$taskStockTakingLocations',
    [TaskStockTakingRestriction.STOCK_OWNER]: '$owners',
    [TaskStockTakingRestriction.MANUFACTURER]: '$manufacturer',
    [TaskStockTakingRestriction.PRODUCT]: '$product',
    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: '$productInstance'
};

export {TaskStockTakingRestriction, TaskStockTakingRestrictionIcon};
