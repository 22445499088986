<template>
  <span>
    <v-layout
      v-if="(deliveryType === deliveryTypes.RETURN && Object.values(InstanceType).includes(itemType))
        || (deliveryType === deliveryTypes.DELIVERY && !Object.values(InstanceType).includes(itemType))"
    >
      <v-flex class="orange--text">
        {{ itemText }}
      </v-flex>
      <v-flex class="text-right">
        <v-tooltip top>
          <template #activator="{ on }">
            <div
              v-on="on"
            >
              <v-icon
                color="orange"
                class="ml-1"
              >
                $info
              </v-icon>
            </div>
          </template>
          <span v-if="deliveryType === deliveryTypes.RETURN">
            {{ $t('tasks.deliveryAccept.items.isNotCustomInstance') }}
          </span>
          <span v-else>
            {{ $t('tasks.deliveryAccept.items.isCustomInstance') }}
          </span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <span v-else>
      {{ itemText }}
    </span>
  </span>
</template>

<script>
    import {InstanceType} from "@/enum/instance_type";
    import {deliveryTypes} from "@/enum/delivery_type";

    export default {
        name: "DeliveryAcceptItemSelector",
        props: {
            deliveryType: {
                type: String,
                default: deliveryTypes.DELIVERY
            },
            itemType: {
                type: String,
                default: InstanceType.NORMAL
            },
            itemText: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            InstanceType: InstanceType,
            deliveryTypes: deliveryTypes,
        })
    };
</script>

<style scoped>

</style>
