import Vue from "vue";
import {Vuetify} from "@/service/Vuetify";

import {TiptapVuetifyPlugin} from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';

function useTipTap() {
    Vue.use(TiptapVuetifyPlugin, {
        vuetify: Vuetify,
        iconsGroup: 'md'
    });
}

export {useTipTap};
