import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{staticClass:"my-2 px-1"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + 'label'))+" ")]),_c(VExpansionPanelContent,[_c(VAutocomplete,{attrs:{"chips":"","deletable-chips":"","items":_vm.availableBuyerTypes,"loading":_vm.loading,"item-disabled":() => _vm.loading,"multiple":"","outlined":""},on:{"input":_vm.onInput},model:{value:(_vm.assignedBuyerTypes),callback:function ($$v) {_vm.assignedBuyerTypes=$$v},expression:"assignedBuyerTypes"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }