import {BaseAPI} from "@/api/BaseAPI";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";
import {Status} from "@/enum/import&export_state";

const ExportsAPI = {

    API: BaseAPI,
    DOMAIN: 'exports',
    DOMAIN_CONFIGURATIONS: 'configurations',
    DOMAIN_BY_CONFIGURATION: 'by-configuration',
    DOMAIN_TEMPLATES: 'templates',
    DOMAIN_PROCESS: 'process',
    DOMAIN_TYPES: 'types',

    /**
     * @param id
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */

    getAll(id, ...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_BY_CONFIGURATION, id], 'exports.errors.config', merge(...params));
    },
    getAllPages(id, ...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_BY_CONFIGURATION, id], 'exports.errors.config', merge(...params));
    },

    getAllConfigurations(...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_CONFIGURATIONS], undefined, merge(...params));
    },

    getAllConfigurationsPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_CONFIGURATIONS], undefined, merge(...params));
    },

    get(exportId) {
        return this.API.get([this.DOMAIN, exportId], 'exports.errors.export');
    },

    getStatistics(exportId) {
        return APIHelper.polling(this.API.get.bind(
            this.API,
            [this.DOMAIN, exportId],
            this.LANG
        ), 100, res => {
            return res.data.error || res.data.status === Status.DRY_RUN_FINISHED || res.data.status === Status.FINISHED;
        }); // ~4.5 minutes
    },

    getExports(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    create(data) {
        return this.API.post(this.DOMAIN, data, 'exports.errors.exportCreate');
    },

    update(id, data) {
        return this.API.patch([this.DOMAIN, id], data, 'exports.errors.exportUpdate');
    },

    process(id, dryRun) {
        return this.API.post([this.DOMAIN, id, this.DOMAIN_PROCESS], dryRun, 'exports.errors.exportProcess');
    },

    delete(id) {
        return this.API.delete([this.DOMAIN, id], 'exports.error.exportDelete');
    },

    download(id) {
        return this.API.get(['public', this.DOMAIN, id, 'download'], 'exports.download');
    },

    getConfiguration(id) {
        return this.API.get([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], 'exports.errors.config');
    },

    createConfiguration(data) {
        return this.API.post([this.DOMAIN, this.DOMAIN_CONFIGURATIONS], data, 'exports.errors.configCreate');
    },

    updateConfiguration(id, data) {
        return this.API.patch([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], data, 'exports.errors.configUpdate');
    },


    deleteConfiguration(id) {
        return this.API.delete([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], 'exports.errors.configDelete');
    },

    getTypes() {
        return this.API.get([this.DOMAIN, this.DOMAIN_TYPES], undefined);
    },

    getType(type) {
        return this.API.get([this.DOMAIN, this.DOMAIN_TYPES, type]);
    }

};

export {ExportsAPI};
