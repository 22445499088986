import {Roles} from "@/enum/roles";
import StocksList from "@/app/stocks/StocksList.view";
import StockShow from "@/app/stocks/StockShow.view";
import StockCreateUpdate from "@/app/stocks/StockCreateUpdate.view";
import StockLocationCreateUpdate from "@/app/stocks/locations/StockLocationCreateUpdate.view";
import StockSubstockShow from "@/app/stocks/substocks/StockSubstockShow.view";
import StockSubstockCreateUpdate from "@/app/stocks/substocks/StockSubstockCreateUpdate.view";
import StockOwnersList from "@/app/stocks/owners/StockOwnersList.view";
import StockOwnerCreateUpdate from "@/app/stocks/owners/StockOwnerCreateUpdate.view";
import StockLocationShow from "@/app/stocks/locations/StockLocationShow.view";
import StockOwnerShow from "@/app/stocks/owners/StockOwnerShow.view";
import CarrierParametersList from "@/app/carriers/CarrierParametersList.view";
import StockLocationLockCreate from "@/app/stocks/locations/locks/StockLocationLockCreate.view";

const BASE_URL = '/stocks';

const routes = {
    base: {
        path: BASE_URL,
        component: StocksList,
        meta: {
            title: 'stocks.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },


    show: {
        path: BASE_URL + '/:stockId(\\d+)',
        component: StockShow,
        meta: {
            title: 'stocks.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    create: {
        path: BASE_URL + '/create',
        component: StockCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'stocks.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:stockId(\\d+)/update',
        component: StockCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'stocks.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    locationShow: {
        path: BASE_URL + '/:stockId(\\d+)/locations/:locationId(\\d+)',
        component: StockLocationShow,
        meta: {
            title: 'stocks.locations.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    locationCreate: {
        path: BASE_URL + '/:stockId(\\d+)/locations/create',
        component: StockLocationCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'stocks.locations.create.titleSimple',
            requireRole: [Roles.CHIEF, Roles.STOREKEEPER],
            getParent: () => routes.show
        }
    },
    locationUpdate: {
        path: BASE_URL + '/:stockId(\\d+)/locations/:locationId(\\d+)/update',
        component: StockLocationCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'stocks.locations.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    locationLockCreate: {
        path: BASE_URL + '/:stockId(\\d+)/locations/:locationId(\\d+)/locks/create',
        component: StockLocationLockCreate,
        meta: {
            title: 'stocks.locations.locks.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.locationShow
        }
    },


    substockShow: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/:subStockId(\\d+)',
        alias: BASE_URL + '/:stockId(\\d+)/subordinates/:subStockId(\\d+)',
        component: StockSubstockShow,
        meta: {
            title: 'stocks.substocks.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    substockCreate: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/create',
        component: StockSubstockCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'stocks.substocks.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    substockUpdate: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/:subStockId(\\d+)/update',
        component: StockSubstockCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'stocks.substocks.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.substockShow
        }
    },
    substockAddContractedCarrier: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/:subStockId(\\d+)/carriers/:carrierId(\\d+)/create',
        component: CarrierParametersList,
        props: {
            isEdit: false
        },
        meta: {
            title: 'carriers.contracted.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.substockShow
        }
    },
    substockUpdateContractedCarrier: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/:subStockId(\\d+)/carriers/:carrierId(\\d+)/update',
        component: CarrierParametersList,
        props: {
            isEdit: true
        },
        meta: {
            title: 'carriers.contracted.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.substockShow
        }
    },


    substockLocationShow: {
        path: BASE_URL + '/:stockId(\\d+)/substocks/:subStockId(\\d+)/locations/:locationId(\\d+)',
        component: StockLocationShow,
        meta: {
            title: 'stocks.locations.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.substockShow
        }
    },


    ownerList: {
        path: BASE_URL + '/owners',
        component: StockOwnersList,
        meta: {
            title: 'stocks.owners.list.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    ownerShow: {
        path: BASE_URL + '/owners/:ownerId(\\d+)',
        component: StockOwnerShow,
        meta: {
            title: 'stocks.owners.show.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.ownerList
        }
    },
    ownerCreate: {
        path: BASE_URL + '/owners/create',
        component: StockOwnerCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'stocks.owners.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.ownerList
        }
    },
    ownerUpdate: {
        path: BASE_URL + '/owners/:ownerId(\\d+)/update',
        component: StockOwnerCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'stocks.owners.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.ownerList
        }
    },


    showFallback: {
        path: BASE_URL + '/:stockId(\\d+)/*',
        beforeEnter: (to, from, next) => {
            next(BASE_URL + '/' + to.params.stockId);
        }
    },
    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
