<template>
  <x-autocomplete
    :value="filters[header.value]"
    :api-data-source="xAutocomplete.apiDataSource"
    :api-filter="xAutocomplete.apiFilter"
    :api-sort="xAutocomplete.apiSort"
    :api-primary-key-name="xAutocomplete.apiPrimaryKeyName"
    :all-item-label="$t(header.nested.allItemLabel)"
    :clearable="filters[header.value] !== null"
    :disable-autoselect-first="!!xAutocomplete.disableAutoselectFirst"
    :label="$t('base.filterBy') + ' ' + header.text"
    :multiple="header.filterType === tableFilter.SELECT_MULTIPLE || header.filterType === tableFilter.ARRAY_CONTAINS_MULTIPLE"
    :no-data-text="header.noDataText"
    :result-transform="xAutocomplete.resultTransform"
    prepend-search-bar
    @input="onInput"
  >
    <template
      #prepend-inner
    >
      <v-chip v-if="chosenNestedItem">
        {{ chosenNestedItem.text }}
      </v-chip>
    </template>
    <template #prepend-item>
      <x-autocomplete
        :value="chosenNestedItemValue"
        :api-data-source="nestedXAutocomplete.apiDataSource"
        :api-filter="nestedXAutocomplete.apiFilter"
        :api-sort="nestedXAutocomplete.apiSort"
        :api-primary-key-name="nestedXAutocomplete.apiPrimaryKeyName"
        clearable
        :disable-autoselect-first="!!nestedXAutocomplete.disableAutoselectFirst"
        :label="$t(header.nested.label)"
        :no-data-text="header.nested.noDataText"
        :prepend-icon="header.nested.icon"
        :result-transform="nestedXAutocomplete.resultTransform"
        @input="(input, item) => onNestedInput(input, item)"
        @click:clear="$emit('nested-item-cleared')"
      />
    </template>
  </x-autocomplete>
</template>

<script>
    import {TableFilter} from "@/enum/table_filter";

    export default {
        name: "NestedTableFilter",
        props: {
            filters: {
                type: Object,
                default: () => {}
            },
            header: {
                type: Object,
                default: () => {}
            },
        },
        data: () => ({
            tableFilter: TableFilter,
            chosenNestedItem: null,
        }),
        computed: {
            chosenNestedItemValue: function () {
                return this.filters[this.header.nested.key];
            },
            xAutocomplete: function () {
                return this.header.xAutocomplete(this.chosenNestedItemValue);
            },
            nestedXAutocomplete: function () {
                return this.header.nested.xAutocomplete;
            }
        },
        methods: {
            onInput: function (input) {
                if (input.length === 0 || input.find(val => val === null) === null) {
                    this.$emit('main-item-chosen', []);
                    return;
                }
                this.$emit('main-item-chosen', input.filter(val => val !== null));
            },
            onNestedInput: function (input, item) {
                this.chosenNestedItem = item ?? null;
                if (!input) {
                    this.$emit('nested-item-chosen', null);
                    return;
                }
                this.$emit('nested-item-chosen', input);
            },
        }
    };
</script>

<style scoped>

</style>