export default {
    required: "Це поле є обов'язковим.",
    maxLen: "Максимально допустима довжина - {0} символів",
    minLen: "Мінімальна вимога до довжини - {0} символів",
    maxInteger: 'Максимально допустиме значення {0}',
    pattern: "Введення не відповідає вимогам формату",
    integer: "Введіть ціле число",
    positiveInteger: "Введіть ціле додатне число",
    nonNegativeInteger: "Введіть ціле невід'ємне число",
    number: "Введіть номер",
    nonNegativeNumber: "Введіть невід’ємне число",
    range: "Має бути між {0} та {1}",
    save: "Зберегти",
    send: "Відправити",
    generate: "Згенерувати",
    sequenceBegin: "Послідовність має починатися з числа або літери",
    notAllowedChar: "Недозволений символ",
    download: "Завантажити",
    notFilled: "Не заповнено",
    invalidDate: "Неможливо обробити вказану дату",
    now: "Зараз",
    today: "Сьогодні",
    yesterday: "Вчора",
    start_of_month: "Початок місяця",
    end_of_last_month: "Кінець минулого місяця"

};
