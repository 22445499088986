const scannerModes = {
    IDLE: 0,
    IDLE_SCAN_PACKING: 1,
    IDLE_ORDER_ITEM: 2,
    IDLE_LOCATION_OR_ITEM: 3,
    SOURCE: 4,
    DESTINATION: 5,
    ORDER: 6
};

export {scannerModes};
