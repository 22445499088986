<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.serial_numbers."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import FormFields from "@/app/components/form/FormFields.component";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import {
        ProductSerialNumberForm,
        ProductSerialNumberRender
    } from "@/app/products/serial-numbers/definitions/productSerialNumber.form";

    export default {
        name: "ProductSerialNumberCreate",
        components: {FormFields},
        mixins: [InstanceCacheMixin, RouteParamsMapperMixin],
        data: () => ({
            form: new ProductSerialNumberForm,
            formRender: new ProductSerialNumberRender,
            valid: true,
            loading: false,
            productName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.productName !== '') {
                    title = this.$t('products.serial_numbers.create.title', [this.productName]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        createdOrActivated: function () {
            API.get(this.productId)
                .then(response => {
                    this.productName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/products');
                });
        },
        methods: {
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                API.createSerial(this.productId, this.form)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.serial_numbers.create.done',
                            params: [this.form.number, this.productName]
                        });
                        this.clearInstanceCachesByProduct(this.productId);
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push('/products/' + this.productId);
                    }).catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
