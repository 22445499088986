import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
import {ExternalOrderState, TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";
import {deliveryTypes} from "@/enum/delivery_type";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

const notFoundOrACL = "Zadané číslo úkolu nenalezeno, nebo nemáte k úkolu přístup!";
const notFoundOrACLOrItem = "Zadané číslo úkolu nebo ID položky nenalezeno, nebo nemáte k úkolu přístup!";
const approve409 = "Úkol není ve stavu, ze kterého by šel přijmout!";
const reject409 = "Úkol není ve stavu, ze kterého by šel odmítnout!";
const notActive409 = "Úkol není v aktivním stavu, nebo nebyly zpracovány všechny položky!";
const memberOfSet = "Tato akce je zakázána, protože je úkol součástí skupiny!";

const state = {
    name: "Stav",
    [TaskState.CREATED]: "Nový",
    [TaskState.IN_PROGRESS]: "Probíhající",
    [TaskState.WAITING]: "Čeká",
    [TaskState.CLOSED]: "Dokončený",
    [TaskState.CANCELED]: "Zrušený",
    [TaskState.UNASSIGNED]: "Nepřiřazený"
};

export default {
    name: "Úkol",
    id: 'ID úkolu',
    external_id: "Externí číslo",
    parent_task_id: "Nadúkol",
    state: state,
    type: 'Typ úkolu',
    priority: {
        name: "Priorita",
        [TaskPriority.LOW]: "Nízká",
        [TaskPriority.NORMAL]: "Normální",
        [TaskPriority.HIGH]: "Vysoká",
        unknown: "Neznámá priorita"
    },
    location: {
        name: "Umístění",
        [TaskPreferredLocation.EXISTING]: "Jakékoliv existující",
        [TaskPreferredLocation.LAST]: "Poslední použité"
    },
    description: "Popis",
    assigned_user: "Přiřazeno",
    nobody: "<nikdo>",
    delivery_type: "Typ příjmu",
    delivery_number: "Číslo dodacího listu",
    invoice_number: "Číslo faktury",
    supplier_id: "Dodavatel",
    external_order_task_id: "Číslo objednávky",
    supplier: "Dodavatel",
    note: "Poznámka",
    time_spent: "Strávený čas",
    ean: "EAN",
    lastScanned: "Poslední načtení",
    queue: "Fronta",
    created_at: "Vytvořeno",
    updated_at: "Aktualizováno",
    viewTask: "Zobrazit úkol",
    search: "Pokročilé hledání v úkolech",
    searchHint: "Prohledává číslo úkolu, číslo nadúkolu a externí číslo.",

    noItems: "Žádné položky!",
    itemsNotReadyYet: "Pro načtení položek začněte pracovat na úkolu",
    useScanner: "Použijte čtečku nebo políčko výše pro vložení položek.",
    syncRunning: "Probíhá synchronizace",
    generateReport: {
        pdf: "Vygenerovat report v PDF",
        csv: "Vygenerovat report v CSV",
        html: "Vygenerovat report v HTML",
    },

    keep_storekeeper: "Ponechat úkol přiřazený stejnému skladníkovi",

    card: {
        actions: {
            label: "Štítek",
            labelDone: "Štítek '{0}' odeslán na tiskárnu.",
            split: "Rozdělit",
            splitDone: "'{0}' rozděleno!",
            splitFail: "Nelze rozdělit zboží které má jen 1 kus.",
            delete: "Smazat",
            deleteDone: "'{0}' odstraněno!"
        },
        create: {
            product: "Vytvořit skladovou položku",
            location: "Vytvořit umístění"
        }
    },

    attributes: {
        name: "Atributy úkolu",
        configureFirst: "Nejdříve nastavte povolené atributy v nastavení podskladu",
        override: "Přepsat existující hodnoty atributů při sloučení s jiným úkolem",
        created_at: "Atribut vytvořen v",

        update: {
            done: "Atributy upraveny",
        },
    },

    attachments: {
        name: "Přílohy",
        filename: "Název souboru",
        download_link: "Stáhnout",
        status: {
            404: "Zadané číslo úkolu nebo nepřiřazené přílohy nenalezeno!",
            498: "Nezdařilo se nahrát vložený soubor!"
        }
    },

    notes: {
        name: "Poznámky",
        text: "Text",
        status: {
            404: "Zadané číslo úkolu nebo poznámky nenalazeno!"
        }
    },

    filter: {
        chooseAttr: "Vyberte atribut úkolu",
        id: {
            name: "Číslo"
        },
        parent_task_id: {
            name: "Nadúkol"
        },
        substock: {
            id: {
                name: "Podsklad"
            }
        },
        external_id: {
            name: "Externí číslo"
        },
        state: state,
        priority: {
            name: "Priorita"
        },
        type: {
            name: 'Typ'
        },
        assigned_user: {
            id: {
                name: "Přiřazený uživatel"
            }
        },
        created_at: {
            name: "Vytvořeno"
        },
        updated_at: {
            name: "Aktualizováno"
        },
        created_by: {
            name: "Vytvořil"
        },
        updated_by: {
            name: "Upravil"
        }
    },

    timeEntries: {
        name: "Strávený čas",
        link: "Strávený čas v úkolech",
        title: "Strávený čas v úkolech",
        user: "Uživatel",
        hours: "Hodin",
        created_at: "Vytvořeno",
        track: "Zaznamenávat čas",
        saveTime: "Uložit strávený čas?",
        saveTimeDetails: "U úkolu je spuštený záznam stráveného času. Přejete si jej uložit?",
        saved: "Strávený čas zaznamenán.",
        saveFailed: "Nepodařilo se uložit strávený čas!",
        selectTask: "Zvolte úkol",
        edit: "Změnit čas",
        editDone: "Čas změněn.",
        deleteDone: "Čas smazán!",
        table: {
            user: 'Uživatel',
            hours: 'Hodin',
            created_at: 'Vytvořeno'
        },
        status: {
            404: "Zadané číslo úkolu nebo záznamu času nenalezeno!"
        }
    },

    chooseItems: {
        product: "Skladová položka",
        instance: "Konkrétní položka",
        productSpecification: "Upřesnění položky",
        quantity: "Množství",
        sellPricePerUnit: "Prodejní cena za jednotku",
        priceVat: "Sazba DPH",
        buyPricePerUnit: 'Nákupní cena za jednotku',
        buyPriceVat: 'Nákupní sazba DPH',
        chooseConcreteInstance: "Vybrat konkrétní položku",
        itemAlreadySelected: "Tato položka je již zvolena!",
        typeAlreadySelected: "Tento typ je pro tuto skladovou položku již zvolen!",
        unableToRemoveLastItem: "Nelze odebrat poslední položku!",
        removeItem: "Odebrat položku",

        scanOrChoose: "Načtěte libovolnou položku nebo vyberte ze seznamu níže",
        locationScanned: "Načetli jste umístění místo položky!",
        itemNotAvailable: "Položku '{0}' nelze vybrat!",
        itemQuantityExceeded: "Této položky lze vybrat nejvíce {0}!",
        itemCannotBePutOnDestination: "Tuto položku nelze položit na zvolené cílové umístění!",
        duplicityWarn: "Tato konkrétní položka je zadaná vícekrát. Prosím slučte množství těchto položek do jedné, nebo odstraňte duplicitu.",

        items: "položek",
        pieces: "kusů"
    },

    strictMode: {
        label: "Je striktní",
        [TaskItemsStrictMode.FREE]: "Volné",
        [TaskItemsStrictMode.NO_EXTRA]: "Nic navíc",
        [TaskItemsStrictMode.EXACT]: "Striktní",
    },

    deliveryAccept: {
        name: "Příjem dodávky",
        create: "Vytvořit příjem dodávky",
        createDone: "Úkol příjem dodávky vytvořen.",

        deliveryType: {
            [deliveryTypes.DELIVERY]: "Nové zboží",
            [deliveryTypes.RETURN]: "Vratka"
        },
        items: {
            delivered: "Dodané položky",
            isCustomInstance: "Jedná se o speciální instanci",
            isNotCustomInstance: "Nejedná se o speciální instanci"
        },

        [TaskItemsCardType.ASSIGNMENT]: "Přijato",

        approve: {
            name: "Vytvořit naskladnění",
        },

        acceptor: "Přijal",
        updateDetails: "Upravit úkol příjmu dodávky",
        newTask: "Parametry nového úkolu naskladnění",
        status: {
            404: notFoundOrACL
        },

        show: {
            label: "Příjmy dodávky dodavatele"
        },
        searchHint: "Prohledává číslo úkolu, číslo faktury, číslo dodacího listu a číslo objednávky.",
    },

    stock_loading_priority: "Priorita",
    stock_loading_preferred_location: "Preferované umístění",
    stock_loading_description: "Popis",
    stock_loading_stock_id: "Sklad",
    stock_loading_subordinate_stock_id: "Podsklad",
    stock_loading_strict_mode: "Je striktní",
    stockLoading: {
        name: "Naskladnění",
        create: "Vytvořit úkol naskladnění",
        createDone: "Úkol naskladnění vytvořen.",

        priority: "Priorita",
        preferred_location: "Preferované umístění",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        supplier_id: "Dodavatel",
        delivery_number: "Číslo dodacího listu",
        invoice_number: "Číslo faktury",
        strict_mode: "Je striktní",
        itemsToLoad: "Položky k naskladnění",

        scanLocation: "Nejprve načtěte umístění, kam chcete položku umístit.",
        scanSomeProducts: "Nelze dokončit prázdné naskladnění.",
        assignAllLocations: "Nastavte cílové umístění pro všechny položky.",
        chooseProductForLocation: "Nejprve zvolte položku, které chcete toto umístění nastavit.",
        locationNotAllowed: "Zvolené umístění není v cílovém podskladu povolené!",
        notToBeLoaded: "Načtená položka není určena k naskladnění!",
        loadedLimit: "Překročen limit k naskladnění: {0}/{1}.",
        onlyItemsFromInventory: "Pokud jsou v přesunu nějaké položky, lze na umístění přesouvat pouze položky z přesunu!",
        noItemsCanBePutOnLocation: "Na zvolené umístění nelze položit žádné položky z úkolu! Zkuste načíst nějaké položky do přesunu.",
        itemCanNotBePutOnLocation: "Načtenou položku nelze položit na zvolené umístění!",

        loadTo: "Naskladnit do",
        chooseInstanceAndHowMuch: "Pokud chcete vytisknout štítky, zvolte konkrétní položku a počet kusů.",
        chooseHowMuch: "Pokud chcete vytisknout štítky '{0}', zvolte počet kusů.",
        rightProductWrongInstanceScanned: "Nenačetli jste správnou konkrétní položku.",
        lookForAnotherCode: "Nenačetl jste správnou konkrétní položku, zkuste na položce najít jiný čárový kód.",

        [TaskItemsCardType.TO_MOVE]: "K naskladnění",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Naskladněno",
        [TaskItemsCardType.ASSIGNMENT]: "K naskladnění",

        returnTo: {
            [TaskItemsCardType.TO_MOVE]: "Vrátit k položkám k naskladnění",
            [TaskItemsCardType.IN_INVENTORY]: "Vrátit k položkám v přesunu",
        },

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Avizované položky jsou pouze doporučení, je možné naskladnit cokoliv.",
            [TaskItemsStrictMode.NO_EXTRA]: "Je umožněno naskladnit pouze avizované položky, některé mohou chybět nebo jich být méně.",
            [TaskItemsStrictMode.EXACT]: "Je umožněno naskladnit pouze avizované položky, žádné nesmí chybět a musí jich být uvedené množství.",
        },

        confirmDelete: {
            fromInventory: "z přesunu",
            fromDestination: "z umístění"
        },

        navigation: {
            assignment: "Zadání",
            stockLoading: "Naskladňování",
            finish: "Dokončení",
            buyPrices: "Nastavení nákupních cen"
        },
        buyPrices: {
            title: "Nákupní ceny",
            instance: "Skladová položka",
            manufacturer: {
                id: "Výrobce"
            },
            measure_unit: "Měrná jednotka",
            price: "Nákupní cena za jednotku",
            vat: "Sazba DPH",
            batchMultiply: {
                by: "Koeficient",
                action: "Vynásobit vyfiltrované ceny",
                done: "Ceny byly aktualizovány",
                failed: "Aktualizace se nezdařila!"
            },
            negativeNotAllowed: "Aktualizace se nezdařila! Není možné ukládat zápornou nákupní cenu.",
            status: {
                404: notFoundOrACLOrItem,
                409: approve409
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Sériové číslo položky je již použito jinde, nebo úkol není v aktivním stavu!"
            },
            update: {
                status: {
                    404: notFoundOrACLOrItem,
                    409: notActive409
                }
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobek s daným sériovým číslem již existuje, nebo umístění není pro zvolený podsklad povoleno."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Výrobek s daným sériovým číslem je již naskladňován v jiném naskladnění, nebo se již nachází na skladě."
        }
    },

    stockTaking: {
        name: "Inventura",
        create: "Vytvořit úkol inventury",
        createDone: "Úkol inventury vytvořen.",

        priority: "Priorita",
        description: "Popis",
        restrict_type: "Druh omezení",
        restrict_references: "Omezeno na",
        product: "Skladová položka",
        stock_id: "Sklad",
        attachments: "Přílohy",

        scanLocation: "Nejprve načtěte umístění, kde se položka nachází.",
        noItems: "Žádné položky k inventurizaci!",
        substockAccessForbidden: "Tento úkol si nemůžete přiřadit, neboť nemáte přístup ke všem podskladům.",

        entityHelper: {
            label: "Použijte vyhledávač pro hromadné zvolení více omezení pro {0}. Zadejte hledaný výraz, zkontrolujte vyfiltrované položky pro omezení, a klikněte na 'Nastavit tato omezení pro {0}'.",
            hint: "Pole přijímá libovolný regulární výraz",
            set: "Nastavit tato omezení pro {0}"
        },

        navigation: {
            assignment: "Zadání",
            stockTaking: "Inventura",
            finish: "Dokončení"
        },

        restrictions: {
            [TaskStockTakingRestriction.STOCK]: 'Sklad',
            [TaskStockTakingRestriction.STOCK_OWNER]: 'Vlastník skladu',
            [TaskStockTakingRestriction.MANUFACTURER]: 'Výrobce',
            [TaskStockTakingRestriction.PRODUCT]: 'Skladová položka',
            [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Konkrétní skladová položka',
            [TaskStockTakingRestriction.STOCK_LOCATION]: 'Umístění',
            [TaskStockTakingRestriction.SUB_STOCK]: 'Podsklad',
            wrongLocation: 'Toto umístění není předmětem inventury!'
        },

        [TaskItemsCardType.PRESENT]: "Nalezené položky",
        [TaskItemsCardType.ASSIGNMENT]: "Předpokládaný stav",

        items: {
            partOfInventory: {
                yes: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Položky výrobců, které jsou předmětem inventury',
                    [TaskStockTakingRestriction.PRODUCT]: 'Skladové položky, které jsou předmětem inventury',
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Skladové položky, které jsou předmětem inventury',
                    [TaskStockTakingRestriction.STOCK_LOCATION]: 'Tato položka by se na dané lokaci neměla nacházet'
                },
                no: {
                    [TaskStockTakingRestriction.MANUFACTURER]: 'Výrobce této položky není předmětem inventury!',
                    [TaskStockTakingRestriction.PRODUCT]: 'Tato skladová položka není předmětem inventury!',
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Tato konkrétní skladová položka není předmětem inventury!',
                    [TaskStockTakingRestriction.STOCK_LOCATION]:  'Tato položka by se na dané lokaci neměla nacházet'
                }
            },
            status: {
                404: notFoundOrACLOrItem,
                409: notActive409
            },
        },
        status: {
            404: notFoundOrACL
        }
    },

    stockPickingSet: {
        name: "Skupina vyskladnění",
        create: "Vytvořit skupinu vyskladnění",
        createDone: "Úkol skupina vyskladnění vytvořen.",
        stockPickingTasks: "Úkoly vyskladnění",
        usedStrategy: "Vytvořeno pomocí strategie",
        pickingAlreadySelected: "Tato úloha je již zvolena!",
        unableToRemoveLastItem: "Nelze odebrat poslední položku!",
        stockPickingLocationFilled: "S cílovým umístěním",
        stockPickingLocationBlank: "bez cílového umístění",
        itemAlreadySelected: "Duplicitní položka!",
        strategies: {
            SAME_SINGLE_PRODUCT: "Stejné jednopoložkové",
            SINGLE_PRODUCT: "Jednopoložkové",
            IDENTICAL_ORDERS: "Stejné",
            FROM_OLDEST: "Od nejstarších"
        },
        delete: {
            title: "Rozpustit skupinu a smazat nadúkol",
            deleteOne: "Odstraní vyskladnění ze skupiny, vyskladnění zůstane v přehledu úloh pro pozdější zpracování",
            status: {
                404: notFoundOrACL,
                409: "Skupinu vyskladnění nelze smazat kvůli jejím vztahům k dalším objektům!"
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobek není na daném umístění, umístění není pro zvolený podsklad povoleno, nebo byl dosažen limit pro přesun."
            }
        },
        location: {
            status: {
                409: "Nelze změnit cílové umístění - na zvoleném umístění už probíhá balení!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Vybrané úkoly vyskladnění spolu nemohou být sloučeny."
        },
        advancedErrorMessages: {
            invalid_task_state: 'Úkol není v platném stavu pro provedení této operace. Stav úkolu je {0}',
            location_not_allowed: 'Toto umístění není povoleno pro aktuální podsklad.',
            missing_location: 'Umístění, které se snažíte použít, je neplatná nebo neexistuje.',
            movement_failed: 'Pohyb se nezdařil, na zdrojovém umístění není dostatek položek.' +
                ' Požadovaný Počet {0}.',
            task_not_found: 'Úkol nebyl nalezen nebo operace uzamčení úkolu selhala.',
            invalid_moving_quantity: 'Množství položek, které chcete přesunout, je větší než existující množství na zdrojovém umístění.' +
                ' Požadovaný počet {0}. Na umístěná {1}.'
        }
    },

    stockPicking: {
        name: "Vyskladnění",
        create: "Vytvořit úkol vyskladnění",
        createDone: "Úkol vyskladnění vytvořen",
        createDoneAdded: "Vyskladnění přidáno k úkolu #{0}",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        tasks: "Úkoly",
        shipping_type: "Způsob dokončení",
        destination_stock_location_id: "Balicí umístění",
        destination_stock_location_id_hint: "Umístění, kde bude zboží zabaleno.",
        buyer_id: "Odběratel",
        buyer_delivery_address_id: "Doručovací adresa odběratele",
        destination_location_id: "Balicí umístění",
        partOfSet: "Součástí skupiny",
        external_order_id: "Objednávka",

        navigation: {
            assignment: "Zadání",
            stockPicking: "Vyskladnění",
            finish: "Dokončení",
            movements: "Pohyby"
        },

        chooseTargetLocation: "Zvolte cílové umístění!",
        scannedLocationNotEmpty: "Naskenované umístění není prázdné, chcete ho přesto zvolit jako cílové?",
        notToBeMoved: "Načtená položka není určena k přesunu, nebo už byla přesunuta!",
        notInInventory: "Načtená položka není v přesunu!",
        unableToFinishDueItems: "Úkol nelze dokončit, protože nejsou přesunuty všechny požadované položky!",
        lookForAnotherCode: "Nenačetl jste správnou konkrétní položku, zkuste na položce najít jiný čárový kód.",

        type: {
            label: "Způsob převzetí",
            [TaskShippingType.COURIER]: "Expedice",
            [TaskShippingType.PERSONAL_COLLECTION]: "Přímé předání odběrateli"
        },

        yetUnknown: "zatím nezvolené umístění",
        handOverTo: "předávku odběrateli",

        autoMoveInProgress: "Probíhá automatický přesun položek, které už jsou na svém cílovém umístění.",

        itemsToPick: "Položky k vyskladnění",

        removeItem: "Odebrat položku",

        [TaskItemsCardType.TO_MOVE]: "Zbývá vyskladnit",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Vyskladněné",
        [TaskItemsCardType.ASSIGNMENT]: "K vyskladnění",
        pickedToHandover: "Připraveno k předání odběrateli",

        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Úkol není ve stavu, ve kterém je možné upravovat prodejní cenu zboží!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobek není na daném umístění, umístění není pro zvolený podsklad povoleno, nebo byl dosažen limit pro přesun."
            }
        },
        location: {
            status: {
                409: "Nelze změnit cílové umístění - na zvoleném umístění už probíhá balení!"
            }
        },

        status: {
            404: notFoundOrACL,
            409: "Ve skladu není dostatek požadovaných položek."
        }
    },

    preparePackage: {
        name: "Balení",
        order: "Objednávka",
        subordinate_stock_id: "Podsklad",
        location: "Umístění skladových položek",
        disengagePackingMode: "Zpět na přehled objednávek",

        navigation: {
            assignment: "Zadání",
            preparePackage: "Balení",
            finish: "Dokončení"
        },

        scanAnything: "Začněte naskenováním libovolné nezabalené položky.",
        scannedUnknown: "Naskenovaná položka není součástí žádné objednávky!",
        scannedUnknownOrTooMuch: "Naskenovaná položka není součástí žádné objednávky, nebo alespoň ne v tomto množství!",
        scannedWrongItem: "Naskenovaná položka není součástí této objednávky!",
        scannedTooMuchItem: "Do této objednávky patří menší počet kusů této položky!",

        ean_read_required: "V tomto podskladu je nutné napípnout všechny položky k balení čtečkou!",
        auto_packed_rest: "Objednávka byla automaticky zabalena!",
        haveYouPackedAllPieces: "Jsou zabaleny všechny kusy?",
        packAllPieces: "Zabalit {0}",

        orders: {
            label: "Objednávky",
            order_id: 'Objednávka',
            external_order_id: 'Externí číslo objednávky',
            external_order_note: 'Poznámka',
            printInvoice: 'Vytisknout fakturu',
            printInvoiceAmount: {
                1: 'jednu',
                2: 'dvě'
            },
            invoiceCopies: "kopie",
            unknown: "Neznámá objednávka!",
            cancelled: "Objednávka byla zrušena!"
        },

        shipments: {
            carrier: {
                label: "Dopravce"
            },
            lastValidated: "Poslední kontrola správnosti",
            never: "<nikdy>",
            valid: "Zásilku lze odeslat",
            notValid: "Zásilku nelze odeslat",
            notValidatedYet: "Ještě neproběhla kontrola odeslatelnosti zásilky.",
            errorMessages:  "Chybové hlášky",
            package_count: "Počet balíků",
            total_value: "Celková hodnota",
            total_weight: "Celková hmotnost",
            change: {
                total_value: "Změnit celkovou hodnotu zásilky",
                total_weight: "Změnit celkovou hmotnost zásilky"
            },
            print: 'Vytisknout',
            label: "štítek",
            deliveryNote: "dodací list",
            printLabelAfterAllItemsPacked: 'Štítek vytiskněte teprve po zabalení všech položek objednávky.',
            printLabelPending: 'Získává se štítek dopravce ...',
            labelPrintedAlready: 'Štítek pro tuto zásilku byl již vytištěn. Pokud opravdu potřebujete štítek vytisknout znovu, kontaktujte vedoucího skladu.'
        },

        items: {
            type: {
                [PreparePackageItemListTypes.TODO]: 'K zabalení',
                [PreparePackageItemListTypes.DONE]: 'Zabaleno',
                [PreparePackageItemListTypes.ALL]: 'Objednané zboží'
            }
        },

        packing: {
            status: {
                404: notFoundOrACL,
                409: "Tato položka nepatří do zvolené objednávky, nebo už je jí zabaleno dostatečné množství!"
            }
        },

        status: {
            404: notFoundOrACL,
        }
    },

    moveProducts: {
        name: "Přesun položek",
        create: "Vytvořit úkol přesunu položek",
        createDone: "Úkol přesunu položek vytvořen.",
        type: {
            label: "Typ přesunu",
            [TaskMoveProductsType.COLLAPSE]: "Seskladnit položky odkudkoliv na {1}",
            [TaskMoveProductsType.DISTRIBUTE]: "Rozmístit položky z {0} kamkoliv",
            [TaskMoveProductsType.RELOCATE]: "Přemístit položky z {0} na {1}",
            [TaskMoveProductsType.MANYTOMANY]: "Přemístit odkudkoliv kamkoliv",
        },

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        source_location_id: "Zdrojové umístění",
        destination_location_id: "Cílové umístění",
        transfer_mode: "Je striktní",
        attachments: "Přílohy",

        anyLocation: "<libovolné umístění>",
        scanSourceOrDestLocation: "Načtěte prosím správné zdrojové nebo cílové umístění: {0} / {1}!",
        notToBeMoved: "Načtená položka není určena k přesunu, nebo už byla přesunuta!",
        notInInventory: "Načtená položka není v přesunu!",
        unableToFinishDueItems: "Úkol nelze dokončit, protože nejsou přesunuty všechny požadované položky!",

        unableToMoveAnyToAny: "Nelze přesouvat z libovolného na libovolné umístění. Vyberte alespoň jedno umístění konkrétně.",
        unableToMoveToSame: "Nelze přesouvat na stejné umístění. Vyberte jiné zdrojové nebo cílové umístění.",
        locationNotAllowed: "Zvolené umístění není v cílovém podskladu povolené!",
        notEnoughItems: "Na zvoleném umístění není dostatečné množství této položky!",
        noItemsCanBePutOnLocation: "Na zvolené umístění nelze položit žádné položky z úkolu!",
        itemCanNotBePutOnLocation: "Načtenou položku nelze položit na zvolené umístění!",
        alreadyUsedAsDestination: "Na toto umístění již byla položka položena! Vraťte ji z již přesunutých.",
        alreadyUsedAsSource: "Z tohoto místa byla položka sebrána!",

        [TaskItemsCardType.TO_MOVE]: "Zbývá přesunout",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Přesunuto",
        [TaskItemsCardType.ASSIGNMENT]: "K přesunutí",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Zadané položky jsou pouze doporučení, je možné přesunout cokoliv.",
            [TaskItemsStrictMode.NO_EXTRA]: "Je umožněno přesunout pouze zadané položky, některé mohou chybět nebo jich být méně.",
            [TaskItemsStrictMode.EXACT]: "Je umožněno přesunout pouze avizované položky, žádné nesmí chybět a musí jich být uvedené množství.",
        },

        navigation: {
            assignment: "Zadání",
            moveProducts: "Přesun",
            finish: "Dokončení",
            movements: "Pohyby"
        },

        items: {
            productsToMove: "Položky k přesunu",
            status: {
                404: notFoundOrACLOrItem,
                409: "Nebylo možné přidat novou položku k přesunu!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobek není na daném umístění, umístění není pro zvolený podsklad povoleno, nebo byl dosažen limit pro přesun."
            }
        },
        finish: {
            status: {
                404: notFoundOrACL,
                409: "Úkol není v aktivním stavu, nebo máte u sebe neumístěné položky!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Na zdrojovém umístění či v podskladu není dostatek požadovaných položek.",
        }
    },

    locationTransfer: {
        name: "Přesun umístění",
        create: "Vytvořit úkol přesunu umístění",
        createDone: "Úkol přesunu umístění vytvořen.",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        allow_filled_locations: "Povolit přesuny na již obsazená umístění",
        attachments: "Přílohy",

        scanLocation: "Načtěte umístění! V přesunu celého umístění nelze načítat jednotlivé položky!",
        scanningTo: "Načtěte umístění, na které se všechny položky přesunou.",

        canNotUseSame: "Již zvoleno jako zdroj!",
        canNotUseNotEmpty: "Umístění není prázdné!",

        confirmTitle: "Potvrďte přesun {0} položek ({1} ks) z '{2}' na '{3}'",
        confirmWithReader: "Pro potvrzení můžete také znovu načíst kód '{0}'.",
        confirmButton: "Potvrdit",

        moved: "Přesunuté",
        noneMoved: "Žádná umístění nebyla přesunuta",

        navigation: {
            assignment: "Zadání",
            locationTransfer: "Přesun",
            finish: "Dokončení"
        },

        status: {
            404: notFoundOrACL,
        }
    },

    substockTransfer: {
        name: "Přesun mezi podsklady",
        create: "Vytvořit úkol přesunu mezi podsklady",
        createDone: "Úkol přesunu mezi podsklady vytvořen.",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        source_subordinate_stock_id: "Zdrojový podsklad",
        destination_subordinate_stock_id: "Cílový podsklad",
        attachments: "Přílohy",

        unableToMoveToSame: "Nelze přesouvat na stejný podsklad. Vyberte jiný zdrojový nebo cílový podsklad.",
        notEnoughItems: "Ve zvoleném podskladu není dostatečné množství této položky!",
        noItemsCanBePutOnLocation: "Na zvolené umístění nelze položit žádné položky z úkolu.",
        itemCanNotBePutOnLocation: "Načtenou položku nelze položit na zvolené umístění!",

        from: "Přesunout z",
        to: "Přesun do",

        notToBeMoved: "Načtená položka není určena k přesunu, nebo už byla přesunuta!",
        notInInventory: "Načtená položka není v přesunu!",

        autoMoveInProgress: "Probíhá automatický přesun položek, které už jsou na svém cílovém umístění.",

        [TaskItemsCardType.TO_MOVE]: "Zbývá přesunout",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Přesunuto",
        [TaskItemsCardType.ASSIGNMENT]: "K přesunutí",

        isAutocompleted: "Úkol byl automaticky dokončen, protože cílový podsklad povoluje stejná umístění, jako zdrojový podsklad.",

        navigation: {
            assignment: "Zadání",
            transferProducts: "Přesun",
            finish: "Dokončení",
            movements: "Pohyby"
        },

        items: {
            productsToMove: "Položky k přesunu",
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Výrobek není na daném umístění, umístění není pro zvolený podsklad povoleno, nebo byl dosažen limit pro přesun."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Máte špatně zvolené zdrojové a cílové umístění, nebo na zdrojovém umístění či v podskladu není dostatek požadovaných položek."
        }
    },

    externalOrder: {
        name: "Externí objednávka",
        create: "Vytvořit externí objednávku",
        createDone: "Externí objednávka vytvořena.",

        priority: "Priorita",
        description: "Popis",
        stock_id: "Sklad",
        subordinate_stock_id: "Podsklad",
        buyer_id: "Objednatel",
        buyer_contact: "Jiné údaje objednatele",
        buyer_delivery_address_id: "Doručovací adresa",
        processing_mode: "Způsob zpracování objednávky",
        carrier_id: "Dopravce",
        carrier_service_id: "Služba dopravce",
        carrierServiceParameters: {
            title: "Parametry služby dopravce",
            name: "Název",
            value: "Hodnota"
        },

        id: 'Číslo objednávky',
        carrier: "Dopravce",
        quantity: "Objednané množství",
        satisfied_quantity: "Zpracované množství",
        reserved_quantity: "Rezervované množství pro tuto objednávku",
        available: "Dostupné množství",
        toBePicked: "Množství k vyskladnění",
        product: {
            name: "Položka"
        },
        stock_picking_task_ids: "Vyskladnění",
        prepare_shipment_package_task_ids: "Balení",
        shipment_ids: "Zásilky",
        selectedInstance: "Zvolený kus k vyskladnění",
        default: "<výchozí>",
        external_author_id: "ID autora externích objednávek",
        external_author_name: "Jméno autora externích objednávek",
        external_note: "Poznámka k  externím objednávkám",
        externalFields: {
            label: "Parametry objednávky",
            author_id: "ID autora",
            author_name: "Jméno autora",
            id: "Identifikátor",
            note: "Poznámka",
            order_created_at: "Vytvořena",
            order_id: "Externí číslo objednávky"
        },
        searchHint: "Prohledává číslo úkolu a číslo objednávky",

        state: {
            label: "Stav objednávky",
            [ExternalOrderState.EO_STATE_CREATED]: "Nová",
            [ExternalOrderState.EO_STATE_UNABLE_TO_PROCESS]: "Nelze zpracovat",
            [ExternalOrderState.EO_STATE_IN_PROGRESS]: "Na části objednávky se právě pracuje",
            [ExternalOrderState.EO_STATE_WAITING]: "Čeká na dílčí úkoly",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING]: "Čeká na vyskladnění",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS]: "Vyskladňována",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_COMPLETED]: "Vyskladněna",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_CANCELED]: "Vyskladnění zrušeno",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING]: "Čeká na balení",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS]: "V balení",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_COMPLETED]: "Zabalena",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_CANCELED]: "Balení zrušeno",
            [ExternalOrderState.EO_STATE_SHIPMENTS_WAITING]: "Zásilky čekají na převzetí dopravcem",
            [ExternalOrderState.EO_STATE_SHIPMENT_CANCELLED]: "Zásilka zrušená",
            [ExternalOrderState.EO_STATE_SHIPPED]: "U dopravce",
            // More detailed EO_STATE_SENT
            [ExternalOrderState.EO_STATE_SENT]: "Odeslaná",
            [ExternalOrderState.EO_STATE_BEING_DELIVERED]: "Doručována",
            [ExternalOrderState.EO_STATE_DELIVERED_TO_PICKUP_LOCATION]: "Doručena na výdejní místo",
            [ExternalOrderState.EO_STATE_LOST]: "Ztracena",
            // end of EO_STATE_SENT details
            [ExternalOrderState.EO_STATE_DELIVERED]: "Doručené zásilky",
            [ExternalOrderState.EO_STATE_RETURNING]: "Zásilky se vracejí na sklad",
            [ExternalOrderState.EO_STATE_RETURNED]: "Zásilky vráceny na sklad",
            [ExternalOrderState.EO_STATE_CANCELLED]: "Zrušená",
            [ExternalOrderState.EO_STATE_CLOSED]: "Dokončená",
        },

        processingMode: {
            label: "Způsob zpracování objednávky",
            changeRequired: "Pokud chcete objednávku zpracovat, změňte její způsob zpracování",
            [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Zpracovat dohromady",
            [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Zpracovat dostupné",
            [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Nezpracovávat",

            hint: {
                [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Čeká se na skladovou dostupnost všech objednaných položek.",
                [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Jakmile jsou některé položky na skladě, dojde k vytvoření vyskladnění.",
                [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Objednávka nebude zpracována, nedojde k vytvoření žádného vyskladnění."
            }
        },

        createStockPicking: "Detaily nové úlohy vyskladnění",
        approveNotPossible: "Objednávku ve stavu 'Nezpracovávat' nelze přijmout",
        someItemsNotAvailable: "Objednávka je ve stavu 'Zpracovat dohromady', ale nejsou dostupné všechny položky",

        navigation: {
            assignment: "Zadání",
            order: "Objednávka",
            finish: "Dokončení"
        },

        update: {
            status: {
                409: "Pouze objednávka ve stavu 'Nový' může být změněna!"
            }
        },

        process: {
            status: {
                404: notFoundOrACL,
                409: "Úkol není ve stavu ke schválení, nebo je požadováno více položek, než je na skladě!"
            }
        },

        approve: {
            name: "Vytvořit vyskladnění",
        },

        close: {
            label: "Uzavřít objednávku",
            note: "Poznámka",
            shipment_state: "Nový stav zásilek",
            done: "#{0} {1} uzavřena, zásilky nastaveny na '{2}'"
        },

        items: {
            title: "Objednané položky",
            status: {
                404: notFoundOrACLOrItem,
                409: "Úkol není ve stavu, kdy by šlo odstranit položku!"
            }
        },

        show: {
            label: "Objednávky odběratele"
        }
    },

    productsReservation: {
        name: "Rezervace",
        create: "Vytvořit rezervaci",
        createDone: "Rezervace vytvořena.",

        priority: "Priorita",
        description: "Popis",

        id: 'Číslo rezervace',
        quantity: "Rezervované množství",
        externalFields: {
            label: "Parametry rezervace",
            author_id: "ID autora",
            author_name: "Jméno autora",
            id: "Identifikátor",
            note: "Poznámka",
            order_created_at: "Vytvořena",
            order_id: "Číslo"
        },
        searchHint: "Prohledává číslo úkolu a číslo rezervace",

        [TaskItemsCardType.ASSIGNMENT]: "Rezervováno",
    },

    itemsCard: {
        canBeFoundAt: "Položku lze nalézt na:",
        canBePutOn: "Položku lze položit na:",
        alreadyPlacedAt: "Položka již leží na:",
        pickedUpFrom: "Položka byla sebrána z:",

        updateQuantity: {
            notEnoughItemsInInventory: "Na umístění nelze přesunout více kusů, než je aktuálně v přesunu!",
            notEnoughItemsOnSource: "Na zdrojovém umístění není dostatek položek k přesunu!",
            itemNotPickedFromThisLocation: "Položka nebyla sebrána z daného umístění!",
            notSoManyItemsPickedFromSource: "Ze zdrojového umístění nebylo sebráno tolik položek!",
            destinationLimit: "Překročen limit k přesunu: {0}/{1}.",
        },

        returnAmount: "Vrátit {0} {1}",
        returnWhere: "na {0}",
        returnToInventory: "mezi položky v přesunu",
        returnTo: {
            location: "Vrátit na původní umístění",
            inventory: "Vrátit mezi položky v přesunu"
        },
        remove: "Zrušit",

        from: "z",
        fromAnywhere: "odkudkoliv",
        to: "na",
        toAnywhere: "kamkoliv",
        substockLocations: "Umístění povolená pro cílový podsklad",

        scanLocation: "Začněte naskenováním umístění.",
        scanPackingLocation: "Začněte naskenováním balícího umístění {0}",
        scanOrderItem: "Načtěte libovolnou položku",
        scanLocationOrItem: "Začněte naskenováním umístění, nebo položky.",
        scanningFrom: "Načtěte položku ke zvednutí",
        scanningTo: "Načtěte položku k umístění",
        scanningOrder: "Načtěte další položku z objednávky #{0}",
        scannedExpeditionLocation: "Opravdu chcete načíst balicí umístění?",
        scannedMobileLocation: "Opravdu chcete načíst mobilní umístění?",
        scanConfirm: "Načíst {0}",
        cancelByScanning: "Nebo načtěte jiný kód",
        confirmByScanning: "Nebo načtěte kód {0} znovu",

        moreDetails: "Více detailů",
        changeTargetLocation: "Změnit cílové umístění",
        cannotChangeTargetLocation: "Nelze změnit cílové umístění, nejprve z aktuálního umístění seberte zpět položky!",

        moveEverything: "Přesunout možné na ",
        moveEverythingInProgress: "Probíhá přesouvání všech položek, které lze položit na cílové umístění.",
    },

    locationsCard: {
        canBePutOn: "Na umístění lze z úkolu položit:",
        cannotBePutOnThisLocation: "Na toto umístění nelze tuto položku položit!",
        cannotBePutOnAnyLocation: "Tuto položku nelze umístit na žádné umístění v úkolu!",
        reloadAllowedLocations: "Aktualizovat cache"
    },

    itemPick: {
        inStock: "na skladě",
        inProgress: "v zadaných úlohách",
        available: "k dispozici"
    },

    unableToLoad: "Seznam úkolů nebylo možné načíst. Zkuste to prosím znovu později.",

    approve: {
        name: "Přijmout",
        done: "#{0} {1} přijato",
        doneTotal: "#{0} {1} dokončeno",
        status: {
            404: notFoundOrACL,
            409: approve409,
            423: memberOfSet
        }
    },
    cancel: {
        name: "Stornovat",
        done: "#{0} {1} stornováno",
        status: {
            404: notFoundOrACL,
            409: "Úkol není ve stavu, ze kterého by šel zrušit!"
        }
    },
    reject: {
        name: "Odmítnout",
        done: "#{0} {1} odmítnuto",
        status: {
            404: notFoundOrACL,
            409: reject409,
            423: memberOfSet
        }
    },
    assign: {
        name: "Přiřadit",
        self: "Začít pracovat na úkolu",
        notAssignable: "S vyskladněním nelze pracovat, protože je součástí skupiny!",
        useReader: "Pro spuštění úkolu můžete také načíst libovolný kód čtečkou.",
        justAssign: {
            self: "Přiřadit si úkol"
        },
        done: "#{0} {1} přiřazeno",
        status: {
            404: notFoundOrACL,
            403: "Úkol je již přiřazený nebo nemáte přístup k cílovému podskladu!",
            423: memberOfSet
        }
    },
    startProcessing: {
        self: "Začít pracovat na úkolu",
        useReader: "Pro spuštění úkolu můžete také načíst libovolný kód čtečkou.",
        done: "#{0} {1} rozpracováno",
        status: {
            409: "Úkol nemůžete zpracovat, protože ho nemáte přiřazený!"
        }
    },
    unassign: {
        name: "Odpřiřadit",
        disabled: "Úkol nelze odpřiřadit, dokuď jsou v přesunu nějaké položky.",
        done: "#{0} {1} odpřiřazeno",
        status: {
            404: "Zadané číslo úkolu nenalezeno!",
            409: "Zadaný úkol nemáte přiřazený!",
            423: "Úkol nelze odpřiřadit, protože v přesunu jsou nějaké položky!"
        }
    },
    finish: {
        name: "Předat ke schválení",
        disabled: "Úkol nelze dokončit, protože některé položky jsou na nepovolených umístěních.",
        done: "#{0} {1} dokončeno, předáno.",
        doneTotal: "#{0} {1} dokončeno",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    handOver: {
        name: "Potvrdit předání odběrateli",
        done: "#{0} {1} dokončeno, předáno.",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    finishAndApprove: "Dokončit",
    finishAndApproveHint: "Tato akce je dostupná, protože máte současně roli skladníka i vedoucího.",
    movementsOverview: {
        title: "Přehled pohybů v úkolu",
        sourceSubstock: "Zdrojový podsklad",
        destinationSubstock: "Cílový podsklad",
    },

    status: {
        404: "Zadané číslo úkolu nenalazeno!",
        409: "Úkol ještě nebyl dokončen!",
        423: memberOfSet
    }
};
