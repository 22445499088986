import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {locationLabel} from "@/utils/string";

/**
 * Requires:
 * - this.taskInfo.details
 * - this.taskInfo.movementType
 * Optionally:
 * - this.taskInfo.source_location
 * - this.taskInfo.destination_location
 */
const TaskMoveProductsMixin = {
    computed: {
        details: function () {
            return this.taskInfo.details;
        },
        movementType: function () {
            return this.taskInfo.movementType;
        },
        sourceString: function () {
            return [TaskMoveProductsType.COLLAPSE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType)
                ? '*'
                : locationLabel(this.details.source_location);
        },
        destString: function () {
            return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.movementType)
                ? '*'
                : locationLabel(this.details.destination_location);
        }
    }
};

export {TaskMoveProductsMixin};
