<template>
  <div>
    <FabSpacer />
    <v-speed-dial
      v-model="fab"
      fixed
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      v-bind="$attrs"
      style="z-index: 5"
    >
      <template #activator>
        <v-btn
          v-model="fab"
          color="secondary"
          fab
          class="elevation-8"
        >
          <v-icon
            :class="{'upsideDown': fab}"
          >
            $showLess
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        v-for="action in actions"
        :key="action.to"
        fab
        small
        color="accent"
        :disabled="action.disabled"
        :to="action.to"
        class="speed-dial-list elevation-8"
      >
        <template v-if="action.subscriptIcon">
          <v-icon
            class="mb-3 ml-1"
          >
            {{ action.icon }}
          </v-icon>
          <v-icon
            small
            class="mt-2 ml-n3"
          >
            {{ action.subscriptIcon }}
          </v-icon>
        </template>
        <template v-else>
          <v-icon>
            {{ action.icon }}
          </v-icon>
        </template>
        <v-chip
          v-if="action.label"
          class="chip-fab text-body-2 elevation-8"
        >
          {{ $t(action.label) }}
        </v-chip>
        <div />
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
    import FabSpacer from "@/app/components/FabSpacer.component";

    export default {
        //eslint-disable-next-line
        name: "x-btn-fab-expander",
        components: {FabSpacer},
        props: {
            actions: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            fab: false,
        }),
    };
</script>

<style scoped lang="sass">
    .chip-fab
        position: absolute
        right: 48px
        max-width: fit-content
        text-transform: none
        cursor: pointer

    .speed-dial-list.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--text):not(.v-btn--outlined)
        background-color: rgb(220, 220, 220) !important

    .speed-dial-list.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--text):not(.v-btn--outlined).theme--dark
        background-color: rgb(85, 85, 85) !important
</style>
