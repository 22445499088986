import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

const MapAPI =  {

    API: BaseAPI,
    DOMAIN_MAP: 'maps',
    DOMAIN_STOCKS: 'stocks',
    DOMAIN_HEATMAP: 'heatmap',
    DOMAIN_RACKS: 'racks',
    DOMAIN_LOCATION_GROUPS: 'location-groups',
    DOMAIN_OBSTACLES: 'obstacles',
    DOMAIN_ZONES: 'zones',
    DOMAIN_EXPORT: 'export',

    // Get all maps for a particular stock. Only map properties retrieved, actual objects aren't.
    getAll(stockId) {
        return this.API.get([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP]);
    },

    // Get heat map data for a given map.
    getHeatMap(stockId, mapId){
        return this.API.get([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_HEATMAP]);
    },

    // Get map with given identificator. Only map properties retrieved, actual objects aren't.
    getMap(stockId, mapId) {
        return this.API.get([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId]);
    },

    // Attempt to create a new map
    create(stockId, data) {
        return this.API.post([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP], data);
    },

    // Update map properties with given identificator and data
    update(stockId, mapId, data) {
        return this.API.put([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId], data);
    },

    // Get all racks in given map with their cells and locations
    getAllRacksAllPages(stockId, mapId, ...params){
        return APIHelper.getAllPages(
            [this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_RACKS],
            [this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_RACKS],
            merge(...params));
    },

    // Create new rack in given map
    createRack(stockId, mapId, data) {
        return this.API.post([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_RACKS], data);
    },

    // Update rack with given identificator and data
    updateRack(stockId, mapId, objectId, data) {
        return this.API.put([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_RACKS, objectId], data);
    },

    // Delete rack with given identificator
    deleteRack(stockId, mapId, objectId) {
        return this.API.delete([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_RACKS, objectId]);
    },

    // Get all location groups in given map
    getAllLocationGroupsAllPages(stockId, mapId){
        return APIHelper.getAllPages([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_LOCATION_GROUPS]);
    },

    // Create location group in given map
    createLocationGroup(stockId, mapId, data) {
        return this.API.post([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_LOCATION_GROUPS], data);
    },

    // Update location group with given identificator and data
    updateLocationGroup(stockId, mapId, objectId, data) {
        return this.API.put([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_LOCATION_GROUPS, objectId], data);
    },

    // Delete location group with given identificator
    deleteLocationGroup(stockId, mapId, objectId) {
        return this.API.delete([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_LOCATION_GROUPS, objectId]);
    },

    // Get all obstacles in given map
    getAllObstaclesAllPages(stockId, mapId){
        return APIHelper.getAllPages([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_OBSTACLES]);
    },

    // Create new obstacle in given map
    createObstacle(stockId, mapId, data) {
        return this.API.post([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_OBSTACLES], data);
    },

    // Update obstacle with given identificator and data
    updateObstacle(stockId, mapId, objectId, data) {
        return this.API.put([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_OBSTACLES, objectId], data);
    },

    // Delete obstacle with given identificator
    deleteObstacle(stockId, mapId, objectId) {
        return this.API.delete([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_OBSTACLES, objectId]);
    },

    // Get all zones in given map
    getAllZonesAllPages(stockId, mapId){
        return APIHelper.getAllPages([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_ZONES]);
    },

    // Create new zone in given map
    createZone(stockId, mapId, data) {
        return this.API.post([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_ZONES], data);
    },

    // Update zone with given identificator and data
    updateZone(stockId, mapId, objectId, data) {
        return this.API.put([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_ZONES, objectId], data);
    },

    // Delete zone with given identificator
    deleteZone(stockId, mapId, objectId) {
        return this.API.delete([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_ZONES, objectId]);
    },

    // Get full map with all objects
    export(stockId, mapId) {
        return this.API.get([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId, this.DOMAIN_EXPORT]);
    },

    // Delete map with given identificator
    delete(stockId, mapId) {
        return this.API.delete([this.DOMAIN_STOCKS, stockId, this.DOMAIN_MAP, mapId]);
    },
};

export {MapAPI};
