import i18n from "@/service/lang/i18n";
import formRules from "@/utils/formRules";
import {RackNamingSymbols} from "@/enum/stock_map";

function RackSizeForm(){
    return {
        objectWidth: null,
        rackShelves: null
    };
}

function RackSizeRender(widthColisionRule){
    return {
        objectWidth: {
            type: 'number',
            required: true,
            rules: [formRules.positiveInteger, widthColisionRule],
            sizeCoefficient: 0.7
        },
        rackShelves: {
            type: 'number',
            required: true,
            rules: [formRules.positiveInteger],
            sizeCoefficient: 0.7
        },
    };
}

function LocationAmountForm(){
    return {
        count: null
    };
}

function LocationAmountRender(){
    return {
        count: {
            type: 'number',
            required: true,
            rules: [formRules.nonNegativeInteger],
            sizeCoefficient: 0.3,
            stretchCoefficient: 1
        },
    };
}

function RackNamingForm() {
    return {
        numberingColumn: null,
        numberingShelf: null,
        firstSymbolValue: null,
        firstStartingSymbol: null,
        secondSymbolValue: null,
        secondStartingSymbol: null,
        thirdSymbolValue: null,
        thirdStartingSymbol: null,
        firstSeparator: null,
        secondSeparator: null,
        thirdSeparator: null
    };
}

const numberingColumnsItems = [
    {
        value: false,
        text: i18n.t('map.leftToRight')
    },
    {
        value: true,
        text: i18n.t('map.rightToLeft')
    }
];

const numberingShelvesItems = [
    {
        value: false,
        text: i18n.t('map.bottomToTop')
    },
    {
        value: true,
        text: i18n.t('map.topToBottom')
    }
];

const rackNamingSymbolsItems = [
    {
        value: RackNamingSymbols.COLUMN,
        text: i18n.t('map.column')
    },
    {
        value: RackNamingSymbols.SHELF,
        text: i18n.t('map.shelf')
    },
    {
        value: RackNamingSymbols.CELL_COUNT,
        text: i18n.t('map.countInCell')
    }
];

function RackNamingRender() {
    return {
        namingDirection: {
            header: true,
        },
        numberingColumn: {
            required: true,
            autocomplete: {
                items: numberingColumnsItems
            },
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        numberingShelf: {
            required: true,
            autocomplete: {
                items: numberingShelvesItems
            },
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        firstSymbol: {
            header: true,
        },
        firstSymbolValue: {
            autocomplete: {
                items: rackNamingSymbolsItems
            },
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        firstStartingSymbol: {
            max: 10,
            rules: [formRules.sequenceBegin],
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        secondSymbol: {
            header: true,
        },
        secondSymbolValue: {
            autocomplete: {
                items: rackNamingSymbolsItems
            },
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        secondStartingSymbol: {
            max: 10,
            rules: [formRules.sequenceBegin],
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        thirdSymbol: {
            header: true,
        },
        thirdSymbolValue: {
            autocomplete: {
                items: rackNamingSymbolsItems
            },
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        thirdStartingSymbol: {
            max: 10,
            rules: [formRules.sequenceBegin],
            sizeCoefficient: 0.5,
            stretchCoefficient: 0.5
        },
        separators: {
            header: true,
            sizeCoefficient: 20
        },
        firstSeparator: {
            max: 5,
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.33
        },
        secondSeparator: {
            max: 5,
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.34
        },
        thirdSeparator: {
            max: 5,
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.33
        }
    };
}

function DimensionsForm(){
    return {
        width: 1,
        height: 1,
        depth: 1,
        inputColor: ""
    };
}

function DimensionsRender(){
    return {
        width: {
            type: 'number',
            required: true,
            rules: [formRules.positiveInteger],
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.33
        },
        height: {
            type: 'number',
            required: true,
            rules: [formRules.positiveInteger],
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.34
        },
        depth: {
            type: 'number',
            required: true,
            rules: [formRules.positiveInteger],
            sizeCoefficient: 0.3,
            stretchCoefficient: 0.33
        },
    };
}

export { RackSizeForm, RackSizeRender,
    LocationAmountForm, LocationAmountRender,
    RackNamingForm, RackNamingRender,
    DimensionsForm, DimensionsRender,
    numberingColumnsItems, numberingShelvesItems, rackNamingSymbolsItems};
