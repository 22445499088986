<template>
  <div>
    <v-card>
      <ConfirmDeleteDialog
        v-if="deletedImportConfig !== null"
        :show.sync="confirmDialog"
        :text="deletedImportConfig.name"
        @confirm-delete="reallyDeleteItem"
      />
      <v-card-title>
        {{ $t('imports.config.templates') }}
      </v-card-title>
      <x-data-table
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :default-action="false"
        :expanded="expanded"
        :headers="configHeader"
        :items.sync="items"
        :loading.sync="loading"
        :reload="reload"
        show-expand
      >
        <template #item.domain="{item}">
          <v-icon class="pr-2">
            {{ domainIcons[item.domain] }}
          </v-icon>
          {{ $t('exports.domains.' + item.domain) }}
        </template>
        <template #footer>
          <TableAddItemButton
            :to="importConfigCreateLink"
            label="imports.config.create"
          />
        </template>
        <template #expanded-item="{ item }">
          <ImportsListComponent
            :config-id="item.id"
            @reloadParent="reload++"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="importConfigCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import {createHeaders} from "@/utils/table";
    import {importConfigsTable} from "@/app/imports/definitions/importConfigsTable.form";
    import {ImportsAPI as API} from "@/api/ImportsAPI";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import * as Export from "@/service/Export";
    import ImportsListComponent from "@/app/imports/components/ImportsList.component";
    import {LoadingStatuses} from "@/enum/import&export_state";
    import {DomainIcon} from "@/enum/domains";
    import XBtnFab from "@/app/vuetify-extend/x-btn-fab";
    import {APIFilters} from "@/service/APIFilters";
    import {UserAPI} from "@/api/UserAPI";
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import {APIFilterOP} from "@/service/APIFilters";


    export default {
        name: "ImportConfigList",
        components: {
            XBtnFab,
            ImportsListComponent,
            ConfirmDeleteDialog,
            TableAddItemButton,
            XDataTable
        },
        mixins: [ReactiveUserCacheMixin],
        data: () => ({
            loading: true,
            items: [],
            importConfigCreateLink: '/imports/create',
            deletedImportConfig: null,
            confirmDialog: false,
            expanded: [],
            domainIcons: DomainIcon,
            configHeader: null,
            reload: 0,
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        icon: '$addItem',
                        label: 'imports.create',
                        color: "primary",
                        notDefaultAction: true,
                        routerLink: item => '/imports/create/' + item.id,
                    },
                    {
                        loading: this.loading,
                        icon: '$showItem',
                        label: 'imports.config.view',
                        color: "secondary",
                        routerLink: item => 'imports/config/' + item.id
                    },
                    {
                        loading: this.loading,
                        icon: '$updateItem',
                        label: 'imports.config.edit',
                        color: "accent",
                        routerLink: item => 'imports/config/' + item.id + '/copy'

                    },
                    {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        condition: item => (item.count === 0),
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return API.getAllConfigurations.bind(API, {sort: APIFilters.makeSort({id: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return API.getAllConfigurationsPages.bind(API);
            }
        },
        createdOrActivated() {
            this.expanded = [];
            this.configHeader = createHeaders(importConfigsTable, 'imports.config.', true, true);
        },
        watch: {
            items() {
                this.getCounts();
            }
        },
        methods: {
            getData: function () {
                this.loading = true;
                const filter = {
                    [APIFilterOP.IN]: {
                        status: LoadingStatuses
                    }
                };
                API.getAllPages({
                    filter: APIFilters.makeFilter(filter),
                    sort: APIFilters.makeSort({created_at: "DESC"})
                }).then(res => {
                    const userIds = res.data.items.map(item => item.created_by);
                    [...new Set(userIds)].filter(userId => !!userId)
                        .forEach(userId => {
                            this.cacheUser(UserAPI.get(userId), userId).catch(err => console.error(err));
                        });
                    const allImports = res.data.items.filter(item => LoadingStatuses.includes(item.status));
                    const openImport = allImports.sort((a, b) => b.id - a.id)[0];
                    if (typeof openImport !== "undefined") {
                        API.get(openImport.id)
                            .then(response => {
                                const openConfig = this.items.find(config => config.id === response.data.configuration.id);
                                if (openConfig) {
                                    this.expanded.push(openConfig);
                                }
                            }).catch(err => {
                                this.snack(err);
                            }).finally(() => {
                                this.loading = false;
                            });
                    } else {
                        this.loading = false;
                    }
                }).catch(err => {
                    this.snack(err);
                    this.loading = false;
                });
            },
            getCounts: function () {
                const promises = [];
                this.items.forEach(item => {
                    promises.push(API.getAll(item.id).then(res => item.count = res.data.items.length));
                });
                Promise.all(promises).then(() => this.getData());
            },
            deleteItem: function (item) {
                this.deletedImportConfig = item;
                this.confirmDialog = true;
            },

            reallyDeleteItem: function () {
                API.deleteConfig(this.deletedImportConfig.id)
                    .then(() => {
                        this.snack('imports.config.deleted');
                        this.confirmDialog = false;
                        this.getData();
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.reload++;
                    });
            },
            downloadImport(item) {
                Export.url(item.download_link);
            },
        }
    };
</script>
