<template>
  <v-card
    width="100%"
    elevation="0"
    color="primary"
  >
    <v-card-title
      class="white--text font-weight-bold flex justify-center text-h3"
      :class="{'pb-16': !inputText}"
    >
      {{ inputText }}
    </v-card-title>
    <v-list
      max-height="300"
      class="overflow-y-auto"
    >
      <v-list-item-group>
        <v-list-item
          v-for="year in years"
          :key="year"
          class="flex justify-center"
          :class="{'primary--text text-h5 ': year === inputText}"
          @click="inputText = year"
        >
          {{ year }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
    export default {
        name: "FormYearPicker",
        props: {
            value: {
                type: String,
                default: ''
            },
            yearsBack: {
                type: Number,
                default: 30
            },
            hideCurrentYear: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            years: [],
            inputText: '',
        }),
        createdOrActivated() {
            this.getYears();
            this.years = this.getYears();
        },
        watch: {
            inputText(val) {
                if (!val) {
                    this.$emit('input', null);
                } else {
                    this.$emit('input', this.inputText);
                }
            },
            value: {
                immediate: true,
                handler: function() {
                    this.internalVal = this.$moment(this.value).format('YYYY');
                }
            },
        },
        methods: {
            getYears() {
                let year = new Date().getFullYear();
                this.hideCurrentYear && year--;
                return Array.from({length: this.yearsBack}, (v, i) => (year - this.yearsBack + i + 1).toString()).reverse();
            },
            getCurrentYear() {
                return this.$moment(new Date()).getFullYear();
            }
        }
    };
</script>

<style scoped lang="sass">

</style>