import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

const StockMovementsAPI = {

    API: BaseAPI,
    DOMAIN: 'stock-movements',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(
            [this.DOMAIN],
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN],
            this.DOMAIN,
            merge(...params)
        );
    },

    getAllForSubstock(subStockId, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId],
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPagesForSubstock(subStockId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, subStockId],
            this.DOMAIN,
            merge(...params)
        );
    }
};

export {StockMovementsAPI};
