<template>
  <div class="w-full">
    <v-menu
      v-model="dialog"
      :close-on-content-click="false"
      :max-width="290"
      allow-overflow
      bottom
      nudge-bottom="20"
    >
      <template #activator="{on}">
        <v-text-field
          ref="name"
          v-model="inputText"
          :append-outer-icon="help !== undefined ? 'help_outline' : ''"
          :clearable="!sticky"
          :disabled="readonly"
          :error-messages="errors"
          :label="label"
          :name="name"
          :suffix="required === true ? '*' : ''"
          :rules="(rules === undefined ? [] : rules)
            .concat(required !== true ? [] : [rulesImpl.required])"
          :prepend-icon="icon || '$datePick'"
          autocomplete="one-time-code"
          persistent-hint
          v-on="on"
          @click:append-outer.stop="$emit('clickHelp')"
        >
          <template #append-outer>
            <slot name="append-outer" />
          </template>
        </v-text-field>
      </template>
      <v-sheet
        v-if="showPicker"
        class="d-flex flex-row flex-wrap"
      >
        <VDatePicker
          v-if="interval === TimeIntervals.WEEK"
          v-model="internalVal"
          :allowed-dates="allowedDates"
          :first-day-of-week="dayOfWeek.MONDAY"
        />
        <VDatePicker
          v-else-if="interval === TimeIntervals.MONTH"
          v-model="internalVal"
          type="month"
        />
        <FormYearPicker
          v-else-if="interval === TimeIntervals.YEAR"
          v-model="internalVal"
          hide-current-year
        />
        <v-btn
          block
          text
          @click="onConfirm"
        >
          <v-icon>
            $check
          </v-icon>
        </v-btn>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
    import {DayOfWeek} from "@/enum/dates";
    import rulesImpl from "@/utils/formRules";
    import {TimeIntervals} from "@/enum/time_intervals";
    import FormYearPicker from "@/app/components/form/FormYearPicker";

    export default {
        name: "FormIntervalPicker",
        components: {FormYearPicker},
        props: {
            interval: {
                type: String,
                default: TimeIntervals.DAY
            },
            isIntervalFrom: { // Decides if value represent the start or end of interval ( ex. when month is picked, returns first day if true, last if false )
                type: Boolean,
                required: true,
            },
            allowedDayOfWeek: {
                type: Number,
                default: -1
            },
            errors: {
                type: Array,
                default: () => []
            },
            help: {
                type: String,
                default: undefined
            },
            hint: {
                type: String,
                default: undefined
            },
            icon: {
                type: String,
                default: 'today'
            },
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: 'datetimepicker'
            },
            readonly: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            rules: {
                type: Array,
                default: () => []
            },
            sticky: {
                type: Boolean,
                default: false
            },
            value: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            rulesImpl: rulesImpl,
            TimeIntervals: TimeIntervals,
            dayOfWeek: DayOfWeek,
            dialog: false,
            internalVal: null,
            showPicker: true,
        }),
        computed: {
            inputText() {
                return this.internalVal ? this.$moment(this.internalVal).format('DD-MM-YYYY') : '';
            }
        },
        watch: {
            interval() {
                this.$emit('input', null);
                this.internalVal = null;
                this.resetPicker();
            },
            value: {
                immediate: true,
                handler: function(val) {
                    if (val && this.$moment(val) !== this.$moment(this.internalVal)) {
                        this.internalVal = this.$moment(val).format('YYYY-MM-DD');
                    }
                }
            },
            internalVal(newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                if(this.$moment(newVal) !== this.$moment(oldVal) && newVal) {
                    if (this.interval !== TimeIntervals.WEEK) {
                        if (this.isIntervalFrom) {
                            this.$emit('input', this.$moment(newVal).startOf(this.interval).format());
                        } else {
                            this.$emit('input', this.$moment(newVal).endOf(this.interval).format());
                        }
                    } else {
                        this.$emit('input', this.$moment(newVal).endOf(TimeIntervals.DAY).format());
                    }
                }
            }
        },
        methods: {
            onConfirm() {
                this.dialog = false;
            },
            resetPicker() {
                this.showPicker = false;
                this.$nextTick(() => {
                    this.showPicker = true;
                });
            },
            allowedDates(date) {
                if (this.allowedDayOfWeek !== -1) {
                    return this.$moment(date).day() === this.allowedDayOfWeek;
                }
                return true;
            }
        }
    };
</script>

<style scoped lang="sass">

.w-full
  width: 100%

</style>