import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"dense"},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.state.label'))+" ")]),_c(VExpansionPanelContent,{staticClass:"dense-panel"},[(!_vm.loading)?_vm._l((_vm.states),function(state){return _c('div',{key:state.id,staticClass:"pb-3"},[_c('div',{staticClass:"font-weight-bold",class:state.active_to !== null ? 'muted--text' : 'primary--text'},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.state.' + state.state))+" ")]),_c('div',{staticClass:"pt-1 align-center text-body-2 d-flex justify-space-between"},[_c('div',[_c('DateTimeWithTooltip',{attrs:{"date-time":state.active_from}})],1),_c(VDivider,{staticClass:"mx-2"}),_c('div',[(state.active_to)?_c('DateTimeWithTooltip',{attrs:{"date-time":state.active_to}}):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({attrs:{"color":"accent"}},on),[_vm._v(" $active ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('base.active'))+" ")])],1)],1)])}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }