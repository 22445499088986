export const dates = {
    NOW: 'now',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    START_OF_MONTH: 'start_of_month',
    END_OF_LAST_MONTH: 'end_of_last_month'
};

export const DayOfWeek = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
};
