import {TableFilter} from "@/enum/table_filter";
import {taskNames, taskTypes} from "@/enum/task_type";
import i18n from "@/service/lang/i18n";
import {UserAPI} from "@/api/UserAPI";
import {productInstances, visibleProducts} from "@/app/tasks/definitions/taskItemsDataSources";
import {stocks} from "@/app/stocks/definitions/stockDataSources";
import {instanceLabel} from "@/utils/filters";
import {emptySource} from "@/app/definitions/emptyDataSources";
import {substocksInStock} from "@/app/stocks/substocks/definitions/substockDataSources";

const movementsTable = (showTaskId = true, showTaskType = true, showSubStock = true) => ({
    ...showSubStock ? {
        'substock.id': {
            filterType: TableFilter.SELECT_MULTIPLE,
            xAutocomplete: nestedItem => nestedItem ? substocksInStock(nestedItem, true) : emptySource(),
            nested: {
                key: 'stock.id',
                filterType: TableFilter.SELECT,
                xAutocomplete: stocks(),
                label: 'stocks.stockStatus.table.stock.choose',
                icon: '$stock',
                allItemLabel: 'stocks.stockStatus.allSubstocks'
            }
        }
    } : {},
    type: {
        icon: '$movementType',
        filterType: TableFilter.SELECT,
        filterItems: ['PUT', 'PICK'].map(type => ({
            text: i18n.t('stocks.stockMovements.table.movement.' + type),
            value: type
        })),
    },
    amount: {
        align: 'right',
        filterType: TableFilter.NUMBER
    },
    'location.type': {
        icon: '$locationType',
        filterType: TableFilter.SELECT,
        filterItems: ['STOCK', 'USER'].map(type => ({
            text: i18n.t('stocks.stockMovements.table.location.' + type),
            value: type
        })),
    },
    'location.id': {
        icon: '$location',
        // TODO filters #20359
    },
    'product_instance.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        xAutocomplete: nestedItem => nestedItem ? productInstances(nestedItem) : emptySource(),
        itemLabel: item => instanceLabel(item.product_instance),
        nested: {
            key: 'product.id',
            filterType: TableFilter.SELECT,
            xAutocomplete: visibleProducts(),
            label: 'stocks.stockStatus.table.product_instance.choose',
            icon: '$productInstance',
            allItemLabel: 'stocks.stockStatus.allProducts'
        },
    },
    ...showTaskId ? {
        'task.id': {
            filterType: TableFilter.NUMBER,
            showInHint: true
        },
    } : {},
    ...showTaskType ? {
        'task.type': {
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(taskTypes).map(type => ({
                text: i18n.t(taskNames[type]),
                value: type,
            })),
        },
    } : {},
    created_by: {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => UserAPI.getAll(),
            thenFn: response => response.data.map(el => ({
                text: (el.first_name + ' ' + el.last_name + ' (' + el.username + ')'),
                value: el.id
            }))
        },
    },
    created_at: {
        filterType: TableFilter.DATETIME
    },
});

export {movementsTable};
