<template>
  <v-tooltip
    top
    :disabled="!hasDeliveryNumber"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-chip
          label
          outlined
          class="mx-1"
          v-on="hasDeliveryNumber ? { click: copyDeliveryNumbers } : {}"
        >
          {{ packages.length }}
          <v-icon
            v-if="hasDeliveryNumber"
            small
            class="ml-2"
          >
            content_copy
          </v-icon>
        </v-chip>
      </span>
    </template>
    <span>
      {{ deliveryNumbers }}. {{ $t('base.clickToCopy') }}
    </span>
  </v-tooltip>
</template>

<script>
    import {copyToClipboard} from "@/utils/string";

    export default {
        name: "ShipmentDetailPackages",
        props: {
            packages: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            hasDeliveryNumber: function () {
                return this.packages.some(pkg => pkg.carrier_delivery_number);
            },
            deliveryNumbers: function () {
                return this.packages.map(pkg => pkg.carrier_delivery_number).join(', ');
            }
        },
        methods: {
            copyDeliveryNumbers: function () {
                copyToClipboard(this.deliveryNumbers)
                    .then(() => {
                        this.advancedSnack({
                            text: 'base.copyToClipboardDone',
                            params: [this.deliveryNumbers]
                        });
                    }).catch(() => {
                        this.snack('base.copyToClipboardFail');
                    });
            }
        }
    };
</script>

<style scoped>

</style>
