<template>
  <v-alert
    :value="readonly"
    type="warning"
    transition="scale-transition"
  >
    {{ $t('base.readonly') }}
  </v-alert>
</template>

<script>

    import {Env} from "@/service/Environment";

    export default {
        name: "ReadonlyNotifier",
        computed: {
            readonly: function () {
                return !!Env.isReadonly();
            }
        }
    };
</script>

<style scoped>

</style>
