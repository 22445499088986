<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <CarrierServiceCreateUpdateComponent
      :is-edit="isEdit"
      :carrier-service-id="isEdit ? serviceId : null"
      is-in-view
      :carrier-id="carrierId"
      @carrier-service-updated="carrierServiceUpdated"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import CarrierServiceCreateUpdateComponent from "@/app/carriers/components/CarrierServiceCreateUpdate.component";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";

    export default {
        name: "CarrierServiceCreateUpdate",
        components: {CarrierServiceCreateUpdateComponent},
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            loading: false,
            serviceName: ''
        }),
        createdOrActivated: function () {
            return ShipmentCarrierAPI.getService(this.carrierId, this.serviceId)
                .then(response => {
                    this.serviceName = response.data.name;
                }).catch(this.snack);
        },
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.serviceName !== '') {
                    title = this.$t('carriers.services.update.title', [this.serviceName]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            carrierServiceUpdated: function () {
                this.$router.back();
            }
        }
    };
</script>

<style scoped>

</style>
