import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{staticClass:"my-2 px-1"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + 'label'))+" ")]),_c(VExpansionPanelContent,[_vm._l((_vm.taskTypeGroups),function(group){return _c('div',{key:group.labelPath,staticClass:"mb-4"},[_c('div',{staticClass:"d-flex flex-row align-center mb-2"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + group.labelPath))+" ")]),(group.hintPath)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"ml-1"},on),[_vm._v(" $hint ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + group.hintPath))+" ")]):_vm._e()],1),_c(VRow,_vm._l((group.items),function(taskType,index){return _c(VCol,{key:index,staticClass:"d-flex justify-space-between",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('x-autocomplete',{attrs:{"api-data-source":_vm.apiDataSource,"label":_vm.$t(_vm.taskNames[taskType]),"prepend-icon":_vm.taskIcons[taskType],"result-transform":_vm.transform,"multiple":"","chips":"","disable-autoselect-first":""},model:{value:(_vm.values[taskType]),callback:function ($$v) {_vm.$set(_vm.values, taskType, $$v)},expression:"values[taskType]"}})],1)}),1)],1)}),_c(VBtn,{attrs:{"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }