<template>
  <div class="d-flex flex-wrap">
    <div
      v-if="selection && selection.length"
      class="d-flex align-center"
    >
      <v-icon class="ml-4 mr-1">
        $itemsSelected
      </v-icon>
      {{ selection.length }}
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-1"
            icon
            v-on="on"
            @click="$emit('clear-selection')"
          >
            <v-icon>
              $removeSelection
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('base.table.removeSelection') }}
        </span>
      </v-tooltip>
    </div>
    <div
      v-if="batchActions && batchActions.length && ((selection && selection.length) || !fulltextActive)"
      class="float-left"
    >
      <v-menu
        class="float-left"
        top
        offset-y
      >
        <template #activator="{ on, attrs }">
          <div class="float-left">
            <v-btn
              color="secondary"
              class="ml-3 my-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">
                expand_less
              </v-icon>
              {{ $t('base.table.batchActions') }}
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-subheader>
            <template v-if="selection && selection.length">
              {{ $t('base.table.batchAllSelected') }} ({{ selection.length }})
            </template>
            <template v-else-if="getAllPagesRequest === null">
              {{ $t('base.table.batchAllFiltered') }} ({{ filteredItems.length }})
            </template>
            <template v-else>
              {{ $t('base.table.batchAllFiltered') }} ({{ apiItemsLength }})
            </template>
          </v-subheader>
          <v-tooltip
            v-for="action in batchActions"
            :key="action.label"
            :disabled="!action.hint || (action.condition !== undefined && action.condition())"
            top
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-list-item
                  :disabled="action.condition !== undefined && !action.condition()"
                  link
                  @click="action.action ? runBatchAction(action.action) : () => {}"
                >
                  <v-list-item-title>
                    <v-icon
                      dense
                      class="mr-2"
                    >
                      {{ action.icon }}
                    </v-icon>
                    {{ $t(action.label) }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </template>
            <span>
              {{ $t(action.hint) }}
            </span>
          </v-tooltip>
        </v-list>
      </v-menu>
    </div>
    <template v-if="batchActionProgress !== null">
      <v-btn
        disabled
        class="ml-3 my-2"
      >
        <v-progress-circular
          indeterminate
          :size="16"
          :width="2"
          class="mx-3"
        />
        {{ batchActionProgress }} / {{ batchActionTotal }}
      </v-btn>
    </template>
  </div>
</template>

<script>
    export default {
        name: "TableBatchActions",
        props: {
            apiItemsLength: {
                type: Number,
                default: 0
            },
            batchActions: {
                type: Array,
                default: () => []
            },
            batchActionProgress: {
                type: Number,
                default: null
            },
            batchActionTotal: {
                type: Number,
                default: null
            },
            filteredItems: {
                type: Array,
                default: () => []
            },
            fulltextActive: {
                type: Boolean,
                default: false
            },
            getAllPagesRequest: {
                type: Function,
                default: null
            },
            selection: {
                type: Array,
                default: () => []
            },
        },
        methods: {
            runBatchAction(action) {
                if (this.selection && this.selection.length) {
                    action(this.selection);
                } else if (this.getAllPagesRequest === null) {
                    action(this.filteredItems.map(item => item.id));
                } else {
                    this.getAllPagesRequest()
                        .then(response => {
                            action(response.data.items.map(item => item.id));
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
