import {Roles} from "@/enum/roles";
import Shipping from "@/app/overview/shipping/Shipping.view";
import ShippingHandover from "@/app/overview/shipping/ShippingHandover.view";
import ShipmentProtocolsList from "@/app/overview/shipping/ShipmentProtocolsList.view";

const BASE_URL = '/shipping';

const routes = {
    base: {
        path: BASE_URL,
        component: Shipping,
        meta: {
            title: 'base.shipping.title',
            requireRole: [Roles.CHIEF, Roles.PACKER],
            getParent: () => '/'
        }
    },
    report: {
        path: BASE_URL + '/report',
        redirect: () => {
            return { path: '/reports' };
        },
    },

    protocols: {
        path: BASE_URL + '/protocols',
        component: ShipmentProtocolsList,
        meta: {
            title: 'base.shipping.handover.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    createProtocol: {
        path: BASE_URL + '/protocols/create',
        component: ShippingHandover,
        meta: {
            title: 'base.shipping.handover.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.protocols
        }
    }
};

export default Object.values(routes);
