<template>
  <v-container fluid>
    <v-row>
      <v-divider
        class="my-3"
      />
    </v-row>
    <v-row>
      <div class="text-subtitle-1 ml-2">
        {{ $t(titlePath) }}:
      </div>
    </v-row>
    <v-row class="mb-n5">
      <v-col>
        <!-- do not validate this as part of outer form -->
        <v-form>
          <v-textarea
            auto-grow
            class="mt-2 readerInput-items iconScan"
            :error="unknownCode"
            :error-messages="readerInputErrors"
            :hide-details="readerQueue.length !== 0"
            :label="$t('tasks.chooseItems.scanOrChoose')"
            :loading="barcodeLoading"
            persistent-hint
            prepend-icon="$scannerIdle"
            :rows="1"
            outlined
            :value="readerInput"
            @input="readerInputReset"
            @keyup.enter.native.prevent="evt => onBarcodeRead(evt.target.value)"
            @keydown.enter.prevent=""
            @keyup.up.native="readerInput = readerLastScanned"
          />
        </v-form>
        <div
          v-if="readerQueue.length"
          class="d-flex v-messages ml-11 my-2 text--secondary"
        >
          <div class="widthFitContent v-messages__message">
            {{ $t('tasks.queue') }}:
          </div>
          <div class="d-flex flex-column flex-wrap ml-1">
            <div
              v-for="(item, index) of readerQueue"
              :key="index"
              class="v-messages__message"
            >
              <strong>{{ item.quantity }}x</strong>
              <TaskCodeLabel
                :code="item.code"
                :show-x="false"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card
      v-for="(localItem, index) of chosenItemsLocal"
      :key="localItem.id ?? localItem.uid"
      outlined
      class="py-1 mb-1 px-2"
      :class="{'duplicate-card': localItem.product_instance_id && duplicates.includes(localItem.product_instance_id)}"
    >
      <TaskChooseItemsItem
        :item="localItem"
        :item-fields="itemFields"
        :readonly="readonly"
        :product-id-source="productIdSource"
        :instance-id-source="instanceIdSource"
        :instance-type-id-source="instanceTypeIdSource"
        :require-product-specification="requireProductSpecification"
        :allow-empty="allowEmpty || (chosenItemsLocal.length - 1 === index)"
        :allow-instance-type-select="allowInstanceTypeSelect"
        :allow-create-new-instances="allowCreateNewInstances"
        :show-buy-price="allowPriceSet"
        @removeItem="removeItem(index)"
      >
        <template #instanceItem="{ item }">
          <slot
            name="instanceItem"
            :item="item"
          />
        </template>
      </TaskChooseItemsItem>
      <WarnText
        v-if="localItem.product_instance_id && duplicates.includes(localItem.product_instance_id)"
        text="tasks.chooseItems.duplicityWarn"
      />
    </v-card>
    <TaskItemAmounts
      :items="chosenItems.filter(item => item.product_id !== null)"
      class="mt-4"
    />
  </v-container>
</template>

<script>
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskCodeLabel from "@/app/tasks/components/TaskCodeLabel.component";
    import TaskItemAmounts from "@/app/tasks/components/TaskItemAmounts.component";
    import {readerFeedback} from "@/utils/readerFeedback";
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import TaskChooseItemsItem from "@/app/tasks/components/TaskChooseItemsItem.component.vue";
    import {CodeType} from "@/enum/code_type";
    import {
        instanceInSubstockOnLocation, instancesInSubstock, productInstances, productInstanceTypes,
        productsInSubstock, productsInSubstockOnLocation, visibleProducts
    } from "@/app/tasks/definitions/taskItemsDataSources";
    import {APIFilters} from "@/service/APIFilters";
    import {productLabel} from "@/utils/filters";
    import {BarcodeReaderParseMixin} from "@/app/mixins/BarcodeReaderParseMixin";
    import WarnText from "@/app/components/WarnText";

    let uid = Math.round(Math.random() * 10000); // Math functions just for HMR

    export default {
        name: "TaskChooseItems",
        components: {WarnText, TaskChooseItemsItem, TaskItemAmounts, TaskCodeLabel},
        mixins: [EventsListenerMixin, BarcodeReaderParseMixin],
        props: {
            chosenItems: {
                type: Array,
                default: () => []
            },
            valid: {
                type: Boolean,
                default: true
            },
            titlePath: {
                type: String,
                default: ''
            },
            onlyInSubstockId: {
                type: Number,
                default: null
            },
            onlyOnLocationId: {
                type: Number,
                default: null
            },
            // product.id is not enough, user needs to specify instance.id or instance_type.id
            requireProductSpecification: {
                type: Boolean,
                default: false
            },
            // whether user can choose just instance type instead of concrete instance
            allowInstanceTypeSelect: {
                type: Boolean,
                default: false
            },
            // which properties user should supply for each item
            // some of: quantity, sell_price_per_unit, price_vat
            itemFields: {
                type: Array,
                default: () => ['quantity']
            },
            // allow user to create new instances
            allowCreateNewInstances: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            allowEmpty: {
                type: Boolean,
                default: false
            },
            allowInstanceDuplicity: {
                type: Boolean,
                default: false
            },
            allowPriceSet: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            langPath: 'tasks.chooseItems.',
            readerInput: null,
            barcodeLoading: false,
            readerLastScanned: null,
            unknownCode: false,
            readerInputErrors: [],
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.onBarcodeRead,
                };
            },
            productIdSource: function () {
                if (this.onlyInSubstockId) {
                    if (this.onlyOnLocationId) {
                        return productsInSubstockOnLocation(this.onlyInSubstockId, this.onlyOnLocationId);
                    } else {
                        return productsInSubstock(this.onlyInSubstockId);
                    }
                } else {
                    return visibleProducts();
                }
            },
            instanceIdSource: function () {
                if (this.onlyInSubstockId) {
                    if (this.onlyOnLocationId) {
                        return instanceInSubstockOnLocation.bind(this, this.onlyInSubstockId, this.onlyOnLocationId);
                    } else {
                        return instancesInSubstock.bind(this, this.onlyInSubstockId);
                    }
                } else {
                    return productInstances;
                }
            },
            instanceTypeIdSource: function () {
                return productInstanceTypes;
            },
            chosenItemsLocal: {
                get: function () {
                    return this.chosenItems;
                },
                set: function (newValue) {
                    this.$emit('update:chosen-items', newValue);
                }
            },
            readerQueue: function () {
                return this.$store.getters['barcodeReaderQueue/list'];
            },
            duplicates: function () {
                if (this.allowInstanceDuplicity) {
                    return [];
                }
                const duplicates = [];
                const instanceIds = this.chosenItemsLocal.map(instance => instance.product_instance_id);
                if (instanceIds.length !== new Set(instanceIds).size) {
                    instanceIds.forEach((outerId, outerIndex) => {
                        if (duplicates.includes(outerId)) {
                            return;
                        }
                        // use every to optimize and stop after duplicity is found
                        instanceIds.every((innerId, innerIndex) => {
                            if (outerId === innerId && outerIndex !== innerIndex) {
                                duplicates.push(innerId);
                                return false;
                            }
                            return true;
                        });
                    });
                }
                this.$emit('update:valid', duplicates.length === 0);
                return duplicates;
            }
        },
        watch: {
            barcodeLoading: function (value) {
                if (!value && !this.$store.getters['barcodeReaderQueue/empty']) {
                    const front = this.$store.getters['barcodeReaderQueue/front'];
                    this.$store.dispatch('barcodeReaderQueue/pop');
                    this.acceptBarcode(front);
                }
            },
            productIdSource: {
                deep: true,
                handler: function () {
                    this.sourceChanged();
                }
            },
            instanceIdSource: {
                deep: true,
                handler: function () {
                    this.sourceChanged();
                }
            },
            instanceTypeIdSource: {
                deep: true,
                handler: function () {
                    this.sourceChanged();
                }
            },
            chosenItems: {
                immediate: true,
                deep: true,
                handler: function () {
                    if (this.chosenItems.filter(item => item.product_id === null).length === 0) {
                        this.chosenItemsLocal.push(this.defaultChosenItem());
                    }
                }
            },
        },
        methods: {
            sourceChanged: function () {
                this.chosenItemsLocal = [];
            },
            removeItem: function (index) {
                if (this.chosenItemsLocal.length === 1) {
                    this.snack(this.langPath + 'unableToRemoveLastItem');
                    return;
                }
                this.chosenItemsLocal.splice(index, 1);
            },
            acceptBarcode: function ({code, quantity}) {
                this.barcodeLoading = true;
                BarcodeAPI.decode(code)
                    .then(response => {
                        const barcodeInfo = response.data;
                        if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                            this.readerLastScanned = code;
                            const productId = barcodeInfo.object_info.product.id;
                            const instanceId = barcodeInfo.object_info.id;
                            const dataSource = this.instanceIdSource(productId, instanceId);
                            dataSource.apiDataSource({
                                itemsPerPage: 1,
                                filter: APIFilters.makeFilter(dataSource.apiFilter || [])
                            }).then(response => {
                                const availableItem = response.data.items[0];
                                if (!availableItem) {
                                    this.handleBarcodeReaderError('tasks.chooseItems.itemNotAvailable',
                                                                  [productLabel(barcodeInfo.object_info.product)]);
                                    return;
                                }
                                const item = this.chosenItemsLocal.find(item => item.product_instance_id === instanceId);
                                const desiredQuantity = (item ? +item.quantity : 0) + (quantity * barcodeInfo.quantity);
                                if (availableItem && availableItem.quantity && desiredQuantity > availableItem.quantity) {
                                    this.handleBarcodeReaderError('tasks.chooseItems.itemQuantityExceeded',
                                                                  [availableItem.quantity]);
                                    return;
                                }
                                if (item) {
                                    item.quantity = desiredQuantity;
                                } else {
                                    this.chosenItemsLocal.splice(this.chosenItemsLocal.length - 1, 0, {
                                        product_id: productId,
                                        product_instance_id: instanceId,
                                        product_instance_type_id: null,
                                        quantity: desiredQuantity,
                                        uid: uid++
                                    });
                                }
                                readerFeedback.success();
                            }).catch(err => this.handleBarcodeReaderError(err));
                        } else {
                            this.handleBarcodeReaderError('tasks.chooseItems.locationScanned');
                        }
                    })
                    .catch(err => this.handleBarcodeReaderError(err))
                    .finally(() => this.barcodeLoading = false);
            },
            fetchBarcodeToCache: function (code) {
                this.cacheBarcode(BarcodeAPI.decode(code), code).catch(this.snack);
            },
            defaultChosenItem: function () {
                return {
                    product_id: null,
                    product_instance_id: null,
                    product_instance_type_id: null,
                    quantity: 1,
                    uid: uid++
                };
            },
        }
    };
</script>

<style scoped lang="sass">

.duplicate-card
  border-color: var(--v-error-base) !important

</style>
