<template>
  <div>
    <template v-if="shipment">
      <v-card class="mx-1 px-1">
        <v-container fluid>
          <div>
            <div class="d-flex">
              <div>
                <v-list-item-avatar tile>
                  <v-img
                    :src="shipment.carrier.image ? shipment.carrier.image.url : ''"
                    contain
                  />
                </v-list-item-avatar>
                {{ shipment.carrier.name }}
                <v-list-item-avatar tile>
                  <v-img
                    :src="shipment.carrier.foil_image ? shipment.carrier.foil_image.url : ''"
                    contain
                  />
                </v-list-item-avatar>
              </div>
              <v-spacer />
              <v-icon
                v-if="closeable"
                @click="close"
              >
                $closeItem
              </v-icon>
            </div>
            <div v-if="isEditable">
              <template
                v-if="shipment.last_validated !== null && shipment.carrier_api_error_messages === null"
              >
                <span class="success--text">
                  <v-icon color="success">
                    done
                  </v-icon>
                  {{ $t('tasks.preparePackage.shipments.valid') }}
                </span>
              </template>
              <v-alert
                v-else-if="shipment.carrier_api_error_messages === null"
                type="warning"
              >
                <span class="font-italic">
                  {{ $t('tasks.preparePackage.shipments.notValidatedYet') }}
                </span>
              </v-alert>
              <template v-else>
                <span class="error--text">
                  <v-icon color="error">
                    $error
                  </v-icon>
                  {{ $t('tasks.preparePackage.shipments.notValid') }}
                </span>
              </template>
            </div>
            <template v-if="isChief && shipment.carrier_api_error_messages !== null">
              <div
                v-for="(error, index) of shipment.carrier_api_error_messages"
                :key="index"
                class="error--text"
              >
                <v-icon color="error">
                  $error
                </v-icon>
                {{ decodeURIComponent(error) }}
              </div>
            </template>
          </div>
          <div class="mt-2">
            <span class="text-caption">{{ $t('base.shipping.table.state') }}:</span>
            {{ $t('base.shipping.shipment.state.' + shipment.state) }}
          </div>
          <div>
            <span class="text-caption">
              {{ $t('tasks.preparePackage.shipments.package_count') + ': ' }}
            </span>
            <template v-if="isEditable">
              <span v-if="shipment.carrier.supports_multi_package_shipment">
                <v-btn
                  v-if="packages.length"
                  :loading="loadingPackages || addWaitingToBeSent"
                  color="accent darken-2"
                  outlined
                  rounded
                  @click="removePackage"
                >
                  <v-icon>$removePackage</v-icon>
                </v-btn>
                <ShipmentDetailPackages :packages="packages" />
                <v-btn
                  :loading="loadingPackages"
                  color="accent darken-2"
                  outlined
                  rounded
                  @click="addPackage"
                >
                  <v-icon>$addItem</v-icon>
                </v-btn>
              </span>
              <span v-else>
                <ShipmentDetailPackages :packages="packages" />
                <span class="caption">({{
                  $t('carriers.doesNotSupportMultiPackageShipments')
                }})</span>
              </span>
            </template>
            <template v-else>
              <ShipmentDetailPackages :packages="packages" />
            </template>
          </div>
          <template
            v-if="isChief"
          >
            <div
              v-for="item of items"
              :key="item.property"
              class="mt-1"
            >
              <span class="text-caption">
                {{ $t('tasks.preparePackage.shipments.' + item.property) + ': ' }}
              </span>
              <template v-if="isEditable">
                <v-btn
                  dense
                  outlined
                  rounded
                  :loading="loadingShipment"
                  @click="changeProperty(item)"
                >
                  {{ Number.parseInt(shipment[item.property]) }}
                  <v-icon
                    dense
                    class="ml-3"
                  >
                    $updateItem
                  </v-icon>
                </v-btn>
              </template>
              <template v-else>
                {{ Number.parseInt(shipment[item.property]) }}
              </template>
            </div>
          </template>
          <div class="mt-3">
            <span class="warning--text">
              <v-icon color="warning">
                warning
              </v-icon>
              {{ $t('tasks.preparePackage.shipments.printLabelAfterAllItemsPacked') }}
            </span>
          </div>
          <div class="mt-3">
            <span
              v-show="$vuetify.breakpoint.smAndUp"
              class="text-caption"
            >
              {{ $t('tasks.preparePackage.shipments.print') }}:
            </span>
            <v-btn
              color="secondary"
              :disabled="hasCarrierErrors || (!isChief && !allPacked)"
              outlined
              :loading="loadingPrintLabel || loadingPackages || addWaitingToBeSent"
              @click="printLabel"
            >
              <v-icon class="iconLabel mr-1">
                book
              </v-icon>
              {{ $t('tasks.preparePackage.shipments.label') }}
            </v-btn>
            <v-btn
              v-if="stockPickingState === TaskState.CLOSED"
              :color="deliveryNotePrinted ? '' : 'secondary'"
              outlined
              :loading="loadingPrintDeliveryNote"
              @click="printDeliveryNote"
            >
              <v-icon class="mr-1">
                {{ deliveryNotePrinted ? 'done' : '$deliveryNote' }}
              </v-icon>
              {{ $t('tasks.preparePackage.shipments.deliveryNote') }}
            </v-btn>
          </div>
          <div
            v-if="shipment.tracking_link !== null"
            class="my-1"
          >
            <a
              :href="shipment.tracking_link"
              target="_blank"
              class="text-decoration-none"
            >
              <v-btn text>
                {{ $t('base.shipping.shipment.track') }}
                <v-icon class="ml-2">$openItem</v-icon>
              </v-btn>
            </a>
          </div>
        </v-container>
      </v-card>
      <v-dialog
        v-if="selectedItem !== null"
        v-model="editModal"
        width="400"
      >
        <v-card>
          <v-card-title>
            {{ $t('tasks.preparePackage.shipments.change.' + selectedItem.property) }}
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent
            >
              <v-text-field
                v-model="inputValue"
                autofocus
                :prepend-icon="selectedItem.icon"
                type="number"
                :rules="[formRules.required, formRules.positiveInteger]"
              />
              <v-card-actions>
                <v-btn
                  text
                  @click="editModal = false"
                >
                  {{ $t('base.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="accent"
                  type="submit"
                  @click="reallyChangeProperty"
                >
                  {{ $t('base.save') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <v-skeleton-loader
        type="card-heading, list-item-two-line"
        class="ma-1"
      />
    </template>
    <v-btn
      v-if="showBigPrintLabelButton"
      :loading="loadingPrintLabel"
      color="accent"
      class="printLabelButton elevation-12"
      x-large
      @click="bigButtonClick"
    >
      {{ $t('tasks.preparePackage.shipments.print') }} {{ $t('tasks.preparePackage.shipments.label') }}
    </v-btn>
  </div>
</template>

<script>
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {ShipmentState} from "@/enum/shipment_state";
    import * as Export from "@/service/Export";
    import formRules from "@/utils/formRules";
    import {TaskState} from "@/enum/task_state";
    import {PrintType} from "@/enum/print_type";
    import {debounce} from "lodash";
    import {ReportType} from "@/enum/report_type";
    import ShipmentDetailPackages from "@/app/components/details/ShipmentDetailPackages.component";
    import {readerFeedback} from "@/utils/readerFeedback";

    export default {
        name: "ShipmentDetail",
        components: {ShipmentDetailPackages},
        mixins: [ACLMixin],
        props: {
            shipment: {
                type: Object,
                default: () => null
            },
            order: {
                type: Object,
                default: () => null
            },
            orderItems: {
                type: Array,
                default: () => []
            },
            shipmentCompletedTrigger: {
                type: Number,
                default: 0
            },
            closeable: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loadingShipment: false,
            packages: [],
            loadingPackages: false,
            loadingPrintLabel: false,
            loadingPrintDeliveryNote: false,
            deliveryNotePrinted: false,
            showBigPrintLabelButton: false,
            stockPickingState: null,
            editModal: false,
            inputValue: 0,
            selectedItem: null,
            formRules: formRules,
            TaskState: TaskState,
            addPackageCount: 0,
            addWaitingToBeSent: false
        }),
        computed: {
            isEditable: function () {
                return [ShipmentState.CREATED, ShipmentState.WAITING_FOR_PICKUP].includes(this.shipment.state);
            },
            items: function () {
                if (this.showShipmentAttrUpdate) {
                    return [{
                        property: 'total_value',
                        icon: 'payments'
                    }, {
                        property: 'total_weight',
                        icon: 'fitness_center'
                    }];
                } else return [];
            },
            showShipmentAttrUpdate: function () {
                // User needs to be packer AND either chief or storekeeper, to be able to change the shipment's value or weight
                return this.isStorekeeper || this.isChief;
            },
            hasCarrierErrors: function () {
                return this.shipment.last_validated === null
                    || (this.shipment.carrier_api_error_messages && this.shipment.carrier_api_error_messages.length > 0);
            },
            invoiceLink: function () {
                const carrierServiceParams = this.order?.carrier_service_parameters;
                if (!carrierServiceParams) {
                    return null;
                }
                return carrierServiceParams.find(param => param.name === 'invoiceLink')?.value;
            },
            debouncedApiAdd: function () {
                return debounce(
                    (shipmentId, addPackageCount) => this.reallyAddPackage(shipmentId, addPackageCount),
                    1000,
                    {leading: false}
                );
            },
            allPacked: function () {
                return this.orderItems.every(item => item.quantity_packed >= item.quantity_ordered);
            },
        },
        watch: {
            shipment: {
                handler: function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.debouncedApiAdd.flush();
                    }
                    if (this.shipment) {
                        this.fetchPackages();
                        this.fetchStockPicking();
                    }
                },
                immediate: true
            },
            shipmentCompletedTrigger: function () {
                // do not automatically print delivery note when invoice is available
                if (this.shipment.carrier.print_delivery_note && !this.invoiceLink) {
                    this.printDeliveryNote();
                }
                this.showBigPrintLabelButton = true;
            }
        },
        beforeDestroy: function () {
            this.debouncedApiAdd.flush();
        },
        methods: {
            fetchPackages: function () {
                if (!this.shipment) {
                    return;
                }
                this.loadingPackages = true;
                ShipmentAPI.getAllPackages(this.shipment.id)
                    .then(response => {
                        this.packages = response.data;
                    })
                    .catch(this.snack)
                    .finally(() => this.loadingPackages = false);
            },
            fetchStockPicking: function () {
                this.loadingPrintLabel = true;
                this.loadingPrintDeliveryNote = true;
                TaskStockPickingAPI.get(this.shipment.stock_picking_id)
                    .then(response => {
                        this.stockPickingState = response.data.state;
                    }).catch(this.snack)
                    .finally(() => {
                        this.loadingPrintLabel = false;
                        this.loadingPrintDeliveryNote = false;
                    });
            },
            removePackage: function () {
                this.loadingPackages = true;
                ShipmentAPI.removePackage(this.shipment.id, this.packages[this.packages.length - 1].id)
                    .catch(this.snack)
                    .finally(this.fetchPackages);
            },
            addPackage: function () {
                if (this.loadingPackages) {
                    return;
                }
                this.packages.push({}); // dummy package
                this.addPackageCount++;
                this.addWaitingToBeSent = true;
                this.debouncedApiAdd(this.shipment.id, this.addPackageCount);
            },
            reallyAddPackage: function (shipmentId, addPackageCount) {
                this.addWaitingToBeSent = false;
                this.loadingPackages = true;
                ShipmentAPI.addPackage(shipmentId, addPackageCount)
                    .catch(this.snack)
                    .finally(() => {
                        if (!this._isDestroyed && !this._isBeingDestroyed) { // In case the component got destroyed do not fetch packages
                            this.addPackageCount = 0;
                            this.fetchPackages();
                        }
                    });
            },
            changeProperty: function (item) {
                this.selectedItem = item;
                this.inputValue = Number.parseInt(this.shipment[item.property]);
                this.editModal = true;
            },
            reallyChangeProperty: function () {
                this.editModal = false;
                this.loadingShipment = true;
                ShipmentAPI.updateShipment(this.shipment.id, {[this.selectedItem.property]: Number.parseInt(this.inputValue)})
                    .then(() => {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.shipment[this.selectedItem.property] = this.inputValue;
                    })
                    .catch(this.snack)
                    .finally(() => {
                        this.selectedItem = null;
                        this.loadingShipment = false;
                    });
            },
            printLabel: function () {
                if (!this.isChief
                    && ((this.shipment.labelAlreadyPrinted === true || this.shipment.label_first_printed_at !== null)
                        && !this.order.subordinate_stock.allow_multiple_shipment_label_prints)) {
                    // User is just packer or storekeeper, he already printed a label and multiple printing of shipment label is not allowed.
                    // There should be no need for another label if he did not mess up.
                    readerFeedback.error();
                    // advanced snack for no timeout
                    this.advancedSnack({text: 'tasks.preparePackage.shipments.labelPrintedAlready'});
                    return;
                }
                this.loadingPrintLabel = true;
                this.advancedSnack({
                    text: 'tasks.preparePackage.shipments.printLabelPending',
                    timeout: 14000
                });
                return ShipmentAPI.printLabel(this.shipment.id)
                    .then(response => {
                        const printType = this.shipment.carrier_service ? this.shipment.carrier_service.type : PrintType.ZPL;
                        Export.print(response.data.url, 'shipment_label_' + this.shipment.id, printType);
                        this.$set(this.shipment, 'labelAlreadyPrinted', true);
                    }).catch(err => {
                        this.snack(err);
                        throw err;
                    })
                    .finally(() => {
                        this.loadingPrintLabel = false;
                    });
            },
            printDeliveryNote: function () {
                this.loadingPrintDeliveryNote = true;
                Export.report(TaskStockPickingAPI, this.shipment.stock_picking_id, ReportType.PDF, 'delivery_note_' + this.shipment.id)
                    .then(() => {
                        this.deliveryNotePrinted = true;
                    }).catch(this.snack)
                    .finally(() => {
                        this.loadingPrintDeliveryNote = false;
                    });
            },
            bigButtonClick: function () {
                this.printLabel().then(() => this.$emit('disengage-packing-mode'));
            },
            close: function () {
                this.$emit('close');
            }
        }
    };
</script>

<style scoped lang="sass">
i.v-icon.iconLabel
  transform: scaleX(-1) rotate(-90deg)

.printLabelButton
  position: fixed
  bottom: 4em
  left: 1vw
  width: 98%
  z-index: 6
</style>
