import {StockStatusAPI} from "@/api/StockStatusAPI";
import {APIFilterOP} from "@/service/APIFilters";
import {ProductAPI} from "@/api/ProductAPI";
import {instanceTypeLabel, productLabel} from "@/utils/filters";

//
// Products
//

export function visibleProducts(multiple = false) {
    return {
        apiDataSource: ProductAPI.getAll.bind(ProductAPI),
        apiFilter: {
            [APIFilterOP.EQUALS]: {'visible': true}
        },
        resultTransform: el => {
            el.value = el.id;
            el.text = productLabel(el);
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple
    };
}

export function productsInSubstock(subStockId) {
    return {
        apiDataSource: StockStatusAPI.getSubstockSummary.bind(StockStatusAPI, subStockId),
        apiFilter: {
            [APIFilterOP.AND]: [
                {[APIFilterOP.GREATER_THAN]: {'quantity': 0}}
            ]
        },
        apiPrimaryKeyName: 'product.id',
        resultTransform: el => {
            el.value = el.product.id;
            el.text = productLabel(el.product);
            return el;
        }
    };
}

export function productsInSubstockOnLocation(subStockId, locationId) {
    return {
        apiDataSource: StockStatusAPI.getSubstockSummaryOnLocations.bind(StockStatusAPI, subStockId, [locationId]),
        apiFilter: {
            [APIFilterOP.AND]: [
                {[APIFilterOP.GREATER_THAN]: {'quantity': 0}}
            ]
        },
        apiPrimaryKeyName: 'product.id',
        resultTransform: el => {
            el.value = el.product.id;
            el.text = productLabel(el.product);
            return el;
        }
    };
}

//
// Instances
//

export function productInstances(productId, instanceId = null, multiple = false) {
    const ret = {
        apiDataSource: productId
            ? ProductAPI.getAllInstances.bind(ProductAPI, productId)
            : () => Promise.resolve({data: {items: []}}),
        resultTransform: el => {
            el.value = el.id;
            el.text = instanceTypeLabel(el);
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple
    };

    if (instanceId) {
        ret.apiFilter = {
            [APIFilterOP.AND]: [
                {[APIFilterOP.EQUALS]: {'id': instanceId}},
            ]
        };
    }
    return ret;
}


export function instancesInSubstock(subStockId, productId, instanceId = null) {
    const ret = {
        apiDataSource: StockStatusAPI.getBlockedInSubstock.bind(StockStatusAPI, subStockId),
        apiFilter: {
            [APIFilterOP.AND]: [
                {[APIFilterOP.EQUALS]: {'product.id': productId}},
                {[APIFilterOP.GREATER_THAN]: {'stock_count': 0}}
            ]
        },
        apiPrimaryKeyName: 'product_instance.id',
        resultTransform: el => {
            el.value = el.product_instance.id;
            el.text = instanceTypeLabel(el.product_instance);
            return el;
        }
    };

    if (instanceId) {
        ret.apiFilter[APIFilterOP.AND].push(
            {[APIFilterOP.EQUALS]: {'product_instance.id': instanceId}},
        );
    }
    return ret;
}

/**
 * @TODO We should also work with 'blocked' but item can not be blocked on a concrete location.
 * @TODO ... This would have to be a BE calculation:
 * @TODO ... `blocked_location_quantity = substock_blocked_quantity - (substock_quantity - location_quantity))`
 * @TODO ... This is only case for move-products with concrete source, and BE does not permit to create an invalid task.
 */
export function instanceInSubstockOnLocation(subStockId, locationId, productId, instanceId = null) {
    const ret = {
        apiDataSource: StockStatusAPI.getAll.bind(StockStatusAPI),
        apiFilter: {
            [APIFilterOP.AND]: [
                {[APIFilterOP.EQUALS]: {'substock.id': subStockId}},
                {[APIFilterOP.EQUALS]: {'stock_location.id': locationId}},
                {[APIFilterOP.EQUALS]: {'product.id': productId}},
                {[APIFilterOP.GREATER_THAN]: {'quantity': 0}}
            ]
        },
        apiPrimaryKeyName: 'product_instance.id',
        resultTransform: el => {
            el.value = el.product_instance.id;
            el.text = instanceTypeLabel(el.product_instance);
            return el;
        }
    };

    if (instanceId) {
        ret.apiFilter[APIFilterOP.AND].push(
            {[APIFilterOP.EQUALS]: {'product_instance.id': instanceId}},
        );
    }
    return ret;
}

export function productInstanceTypes(productId) {
    return {
        apiDataSource: ProductAPI.getCustomInstanceTypesForProduct.bind(ProductAPI, productId),
        apiPrimaryKeyName: 'instance_type_id',
        resultTransform: el => {
            el.value = el.instance_type_id;
            el.text = el.name;
            return el;
        }
    };
}
