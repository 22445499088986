<template>
  <v-alert
    :value="showAlert"
    v-bind="$attrs"
    :type="type"
    class="mt-1"
  >
    <slot name="content">
      {{ displayText }}
    </slot>
    <v-btn
      v-for="({text, icon, handler}, index) of actions"
      :key="index"
      light
      class="ma-2"
      @click="handler"
    >
      <v-icon
        v-if="icon"
        class="mr-2"
      >
        {{ icon }}
      </v-icon>
      {{ text }}
    </v-btn>
  </v-alert>
</template>

<script>
    export default {
        name: "Alert",
        props: {
            showAlert: {
                type: Boolean,
                default: false,
            },
            displayText: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'info'
            },
            actions: {
                type: Array,
                default: () => []
            }
        }
    };
</script>

<style scoped>

</style>
