<template>
  <v-tooltip left>
    <template #activator="{ on }">
      <router-link to="/hp-config">
        <v-icon
          class="text--disabled dashboardIcon mr-2"
          v-on="on"
          @click.prevent="handleConfigNavigation"
        >
          $homepageConfig
        </v-icon>
      </router-link>
    </template>
    {{ $t('homepage.config.config') }}
  </v-tooltip>
</template>

<script>
    export default {
        name: "DashboardConfigLink",
        props: {
            configEnterCallback: {
                type: Function,
                default: () => Promise.resolve()
            }
        },
        methods: {
            handleConfigNavigation: function () {
                // show overlay so that user knows something is happening
                this.$store.commit('loader/overlay/show');
                this.configEnterCallback().then(() => this.$router.push('/hp-config'))
                    .finally(() => this.$store.commit('loader/overlay/hide'));
            }
        }
    };
</script>

<style scoped>

</style>
