import { render, staticRenderFns } from "./TaskItemsCardIconNavigation.component.vue?vue&type=template&id=14518629"
import script from "./TaskItemsCardIconNavigation.component.vue?vue&type=script&lang=js"
export * from "./TaskItemsCardIconNavigation.component.vue?vue&type=script&lang=js"
import style0 from "./TaskItemsCardIconNavigation.component.vue?vue&type=style&index=0&id=14518629&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.95.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports