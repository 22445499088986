import {CACHE_VERSION, CacheConfiguration} from "@/service/cache/CacheConfiguration";
import {Sentry} from "@/service/Sentry";
import {roughSizeOf} from "@/utils/object";

const handlers = {};

function getHandler(collection) {
    return new Promise((resolve, reject) => {
        if (handlers[collection] === undefined) {
            const request = indexedDB.open('cache', CACHE_VERSION);
            request.onupgradeneeded = () => {
                const newKeys = Object.keys(CacheConfiguration);
                const oldKeys = [...request.result.objectStoreNames];
                for (const oldKey of oldKeys.filter(key => !newKeys.includes(key))) {
                    request.result.deleteObjectStore(oldKey);
                }
                for (const newKey of newKeys.filter(key => !oldKeys.includes(key))) {
                    const os = request.result.createObjectStore(newKey);
                    os.createIndex('created_at', 'created_at');
                    const indices = CacheConfiguration[newKey].indices;
                    if (indices) {
                        for (const [name, keyPath] of Object.entries(indices)) {
                            os.createIndex(name, 'data.' + keyPath);
                        }
                    }
                }
            };
            request.onsuccess = () => {
                handlers[collection] = request.result;
                resolve(handlers[collection]);
            };
            request.onblocked = () => {
                reject('IndexedDB blocked');
            };
            request.onerror = error => {
                reject(error.message);
            };
        } else {
            resolve(handlers[collection]);
        }
    });
}

function deleteAllByIndex(store, index) {
    return new Promise(resolve => {
        let deletedCount = 0;
        index.onsuccess = event => { // called when index is created, or .continue() has been used
            const cursor = event.target.result;
            if (cursor) {
                store.delete(cursor.primaryKey);
                deletedCount++;
                cursor.continue();
            } else {
                resolve(deletedCount);
            }
        };
        index.onerror = error => {
            logWarning(error.message);
        };
    });
}

function logWarning(message) {
    message = '[cache] ' + message;
    window.console.warn(message);
    Sentry.captureWarning(message);
}

const IndexedDB = {
    /**
     * @param collection {string}
     * @param key {number|string}
     * @param value {T}
     * @return {Promise<void>}
     */
    save: (collection, key, value) => {
        return new Promise(resolve => {
            getHandler(collection).then(handler => {
                const cacheValue = {
                    created_at: Date.now(),
                    data: value
                };
                const transaction = handler.transaction(collection, 'readwrite').objectStore(collection).put(cacheValue, key);
                transaction.onsuccess = resolve;
                transaction.onerror = error => {
                    logWarning(error.message);
                };
            }).catch(message => {
                logWarning(message);
                resolve();
            });
        });
    },

    /**
     * @param collection {string}
     * @param key {number|string}
     * @param maxAge {number} how old is cached item allowed to be
     * @return {Promise<T>} resolved with cache value, or rejected when value not found or an error has occurred
     */
    get: (collection, key, maxAge = CacheConfiguration[collection].lifetime) => {
        return new Promise((resolve, reject) => {
            getHandler(collection).then(handler => {
                const transaction = handler.transaction(collection, 'readonly').objectStore(collection).get(key);
                transaction.onsuccess = event => {
                    const result = event.target.result;
                    if (result) {
                        if (result.created_at > Date.now() - maxAge) {
                            resolve(result.data);
                        } else {
                            IndexedDB.clearByKey(collection, key);
                            reject();
                        }
                    } else {
                        reject();
                    }
                };
                transaction.onerror = reject;
            }).catch(message => {
                logWarning(message);
                reject();
            });
        });
    },

    /**
     * @param collection {string}
     * @return {Promise<number>}
     */
    getCount: (collection) => {
        return new Promise(resolve => {
            getHandler(collection).then(handler => {
                const transaction = handler.transaction(collection, 'readonly').objectStore(collection).count();
                transaction.onsuccess = event => {
                    resolve(event.target.result);
                };
                transaction.onerror = error => {
                    logWarning(error.message);
                    resolve(0);
                };
            }).catch(message => {
                logWarning(message);
                resolve(0);
            });
        });
    },

    /**
     * @param collection {string}
     * @return {Promise<number>}
     */
    getRoughSizeOfOne: (collection) => {
        return new Promise(resolve => {
            getHandler(collection).then(handler => {
                const transaction = handler.transaction(collection, 'readonly').objectStore(collection).openCursor();
                transaction.onsuccess = event => {
                    const result = event.target.result;
                    if (result) {
                        resolve(roughSizeOf(result.value.data));
                    } else {
                        resolve(0);
                    }
                };
                transaction.onerror = error => {
                    logWarning(error.message);
                    resolve(0);
                };
            }).catch(message => {
                logWarning(message);
                resolve(0);
            });
        });
    },

    getKeys: (collection) => {
        return new Promise((resolve, reject) => {
            getHandler(collection).then(handler => {
                const transaction = handler.transaction(collection, 'readonly').objectStore(collection).getAllKeys();
                transaction.onsuccess = () => resolve(transaction.result);
                transaction.onerror = error => {
                    logWarning(error.message);
                    reject();
                };
            }).catch(err => {
                logWarning(err);
                reject();
            });
        });
    },

    /**
     * @param collection {string}
     * @param key {number|string}
     * @return void
     */
    clearByKey: (collection, key) => {
        getHandler(collection).then(handler => {
            const transaction = handler.transaction(collection, 'readwrite').objectStore(collection).delete(key);
            transaction.onerror = error => {
                logWarning(error.message);
            };
        }).catch(logWarning);
    },

    /**
     * @param collection {string}
     * @param indexName {string}
     * @param indexValue {number|string}
     */
    clearByIndex: (collection, indexName, indexValue) => {
        const indices = CacheConfiguration[collection].indices;
        if (indices && indices[indexName]) {
            getHandler(collection).then(handler => {
                const store = handler.transaction(collection, 'readwrite').objectStore(collection);
                const index = store.index(indexName).openKeyCursor(indexValue);
                deleteAllByIndex(store, index)
                    .then(deletedCount => {
                        if (deletedCount > 0) {
                            window.console.info(`[cache] Removed ${deletedCount} changed items from ${collection} cache`);
                        }
                    });
            }).catch(logWarning);
        } else {
            logWarning(`Index '${indexName}' does not exists on '${collection}'!`);
        }
    },

    /**
     * @return void
     */
    clearOld: () => {
        for (const [collection, {lifetime}] of Object.entries(CacheConfiguration)) {
            getHandler(collection).then(handler => {
                const store = handler.transaction(collection, 'readwrite').objectStore(collection);
                const index = store.index('created_at').openKeyCursor(IDBKeyRange.upperBound(Date.now() - lifetime));
                deleteAllByIndex(store, index)
                    .then(deletedCount => {
                        if (deletedCount > 0) {
                            window.console.info(`[cache] Removed ${deletedCount} old items from ${collection} cache`);
                        }
                    });
            }).catch(logWarning);
        }
    },

    /**
     *
     * @param collection {string}
     * @return void
     */
    clearAll: (collection) => {
        getHandler(collection).then(handler => {
            const transaction = handler.transaction(collection, 'readwrite').objectStore(collection).clear();
            transaction.onerror = error => {
                logWarning(error.message);
            };
        }).catch(logWarning);
    }
};

export {IndexedDB};
