import rules from "@/utils/formRules";
import {SupplierAPI} from "@/api/SupplierAPI";
import i18n from "@/service/lang/i18n";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {EventBus} from "@/service/EventBus";
import {deliveryTypes} from "@/enum/delivery_type";
import {StockAPI} from "@/api/StockAPI";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {externalOrders} from "@/app/tasks/definitions/taskDataSources";
import {BuyerAPI} from "@/api/BuyerAPI";

function StockId(allowSmall = true) {
    return {
        icon: '$stock',
        required: true,
        autocomplete: {
            callFn: () => StockAPI.getAllPages(),
            thenFn: response => response.data.items
                .filter(el => allowSmall ? true : !el.is_small)
                .map(el => ({
                    text: el.name,
                    value: el.id
                }))
        }
    };
}

function SubordinateStockId() {
    return {
        icon: '$substock',
        required: true,
        autocomplete: {
            items: []
        }
    };
}

function DeliveryType() {
    return {
        icon: '$deliveryType',
        select: Object.values(deliveryTypes).map(type => ({
            text: i18n.t('tasks.deliveryAccept.deliveryType.' + type),
            value: type
        })),
        required: true
    };
}

function DeliveryNumber() {
    return {
        icon: '$deliveryNumber',
        max: 30,
        rules: [
            rules.pattern(/^[0-9A-Za-z\\-]*$/),
        ]
    };
}

function InvoiceNumber() {
    return {
        icon: '$invoiceNumber',
        max: 30
    };
}

function SupplierId() {
    return {
        icon: '$supplier',
        autocomplete: {
            callFn: () => SupplierAPI.getAll(),
            thenFn: response => response.data.map(el => ({
                text: (el.name + ' (' + (el.ico || i18n.t('base.noIco')) + ')'),
                value: el.id
            })),
        },
        createNew: {
            icon: '$addUser',
            label: 'suppliers.create.label',
            action: () => {
                EventBus.$emit('create-supplier');
            }
        }
    };
}

function BuyerId(required = true) {
    return {
        icon: '$buyer',
        required: required,
        xAutocomplete: {
            apiDataSource: BuyerAPI.getAll.bind(BuyerAPI),
            apiSort: {created_at: 'DESC'},
            resultTransform: el => ({
                text: (el.name + ' (' + (el.ico || i18n.t('base.noIco')) + ')'),
                value: el.id,
                email:  el.email,
                address: el.default_delivery_address,
                phone: el.phone,
                dic: el.dic
            })
        },
        createNew: {
            icon: '$addUser',
            label: 'buyers.create.label',
            action: () => {
                EventBus.$emit('create-buyer');
            }
        }
    };
}

function ExternalOrderTaskId() {
    return {
        icon: '$taskExternalOrder',
        show: form => form.delivery_type === deliveryTypes.RETURN,
        xAutocomplete: externalOrders()
    };
}

function Note() {
    return {
        icon: '$taskNote',
        textarea: true
    };
}

const taskPriorityItems = function () {
    return Object.values(TaskPriorityEnum).map(priority => ({
        text: i18n.t('tasks.priority.' + priority),
        value: priority
    }));
};

function TaskPriority() {
    return {
        icon: '$taskPriority',
        required: true,
        select: taskPriorityItems()
    };
}

function TaskDescription() {
    return {
        icon: '$description',
        textarea: true
    };
}

function TaskStrictMode() {
    return {
        icon: '$taskStrictMode',
        select: Object.values(TaskItemsStrictMode).map(mode => ({
            text: i18n.t('tasks.strictMode.' + mode),
            value: mode
        })),
        required: true
    };
}

export {
    StockId,
    SubordinateStockId,
    DeliveryType,
    DeliveryNumber,
    InvoiceNumber,
    SupplierId,
    BuyerId,
    ExternalOrderTaskId,
    Note,
    TaskPriority,
    TaskDescription,
    TaskStrictMode
};
