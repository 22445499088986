import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_c(VChipGroup,{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length),expression:"files.length"}],attrs:{"column":""}},_vm._l((_vm.files),function(file,index){return _c(VChip,{key:file.name + file.key,attrs:{"color":file.uploaded === true ? 'primary' : 'warning',"close":""},on:{"click:close":function($event){return _vm.removeFile(index)}}},[(!file.uploaded === true)?_c(VProgressCircular,{staticClass:"mr-2",attrs:{"size":"16","width":"2","indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(file.name)+" ")],1)}),1),_c(VFileInput,{attrs:{"chips":"","flat":"","hide-details":"","label":_vm.$t('base.uploadAttachment'),"multiple":"","solo":"","value":_vm.inputFiles},on:{"change":_vm.fileChanged}}),(_vm.standalone && _vm.taskId && _vm.filesToAttach.length)?_c(VBtn,{staticClass:"accent my-2",attrs:{"loading":_vm.loading},on:{"click":_vm.attachAll}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }