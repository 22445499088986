export default {
    required: "Ez a mező kötelező.",
    maxLen: "A megengedett maximális hossz {0} karakter",
    minLen: "A minimálisan szükséges hossz {0} karakter",
    maxInteger: 'A maximálisan megengedett érték {0}',
    pattern: "A bemenet nem felel meg az előírt formátumnak",
    integer: "Töltse ki az egész számot",
    positiveInteger: "Írjon be egy pozitív egész számot",
    nonNegativeInteger: "Az értéknek nem negatív egész számnak kell lennie",
    number: "Töltse ki a számot",
    nonNegativeNumber: "Írjon be egy nem negatív számot",
    range: "{0} és {1} között kell lennie",
    save: "Mentés",
    send: "Küldés",
    generate: "Generál",
    sequenceBegin: "Sekvencia musí začínať číslom alebo písmenom",
    notAllowedChar: "Nepovolený symbol",
    download: "Letöltés",
    notFilled: "Nincs kitöltve",
    invalidDate: "Adott dátum feldolgozása nem lehetséges",
    now: "Most",
    today: "Ma",
    yesterday: "Tegnap",
    start_of_month: "Hónap kezdete",
    end_of_last_month: "Előző hónap vége"


};
