<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <a
        v-on="on"
        @click="viewTask"
      >
        #{{ task.id }}
      </a>
    </template>
    {{ $t(taskNames[task.type]) }}
  </v-tooltip>
</template>

<script>
    import {taskLinks, taskNames} from "@/enum/task_type";

    export default {
        name: "TaskWithTypeTooltip",
        props: {
            task: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            taskNames: taskNames
        }),
        methods: {
            viewTask: function () {
                this.$router.push(taskLinks[this.task.type] + '/' + this.task.id);
            },
        }
    };
</script>

<style scoped>

</style>
