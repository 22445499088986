import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar',{attrs:{"title":_vm.pageTitle,"tabs":_vm.hasTabs,"full-width":true,"extension":_vm.hasTabs},scopedSlots:_vm._u([(_vm.hasTabs)?{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"background-color":"primary lighten-1","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"accent"}}),_vm._l((_vm.tabItems),function(item){return _c(VTab,{key:item.label},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.navigation.' + item.label))+" ")])})],2)]},proxy:true}:null],null,true)}),_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.changeProcessingModeDialog),callback:function ($$v) {_vm.changeProcessingModeDialog=$$v},expression:"changeProcessingModeDialog"}},[_c('ExternalOrderProcessingModePicker',{attrs:{"task-info":_vm.taskInfo,"change-required":""},on:{"processing-mode-changed":_vm.handleProcessingModeChange}})],1),(_vm.hasCarrierErrors)?_c(VAlert,{attrs:{"type":"error"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.preparePackage.shipments.errorMessages') + ': ')+" ")]),_vm._v(" "+_vm._s((_vm.details.carrier_api_error_messages || []).join(', '))+" ")]):_vm._e(),(_vm.taskInfo && _vm.taskInfo.details && _vm.isProcessTogether
      && !_vm.allItemsAvailable)?_c(VAlert,{attrs:{"type":"warning"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.someItemsNotAvailable'))+" ")])]):_vm._e(),(_vm.details)?[(_vm.assignableWithModeChange)?[_c('TaskStartActionButton',{attrs:{"loading":_vm.loading},on:{"continue":_vm.onAssign}})]:_vm._e(),(_vm.conflict)?_c('TaskConflict',{attrs:{"error":_vm.conflict}}):_vm._e(),(_vm.hasTabs)?[_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c(VTabItem,{key:item.label,attrs:{"eager":""}},[_c(item.component,{tag:"component",attrs:{"task-info":_vm.taskInfo,"items":_vm.items,"all-items-available":_vm.allItemsAvailable}})],1)}),1)]:[_c('ExternalOrderAssignment',{attrs:{"task-info":_vm.taskInfo}})]]:[_c(VProgressLinear,{attrs:{"indeterminate":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }