import { render, staticRenderFns } from "./TaskCreateStrictModeItem.component.vue?vue&type=template&id=45946383&scoped=true"
import script from "./TaskCreateStrictModeItem.component.vue?vue&type=script&lang=js"
export * from "./TaskCreateStrictModeItem.component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.95.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45946383",
  null
  
)

export default component.exports