import formRules from "@/utils/formRules";

function ObjectNameForm(){
    return {
        newName: null
    };
}

function ObjectNameRender(){
    return {
        newName: {
            max: 50,
            required: true,
            sizeCoefficient: 0.4,
        },
    };
}

function NewObjectNameForm(){
    return {
        newObjectName: null
    };
}

function NewObjectNameRender(){
    return {
        newObjectName: {
            max: 50,
            required: true,
        },
    };
}

function ObjectColumnAmountForm(){
    return {
        objectWidth: null
    };
}

function ObjectColumnAmountRender(colisionRule){
    return {
        objectWidth: {
            type: 'number',
            rules: [formRules.positiveInteger, colisionRule],
            required: true,
        },
    };
}

function ObjectRowsAmountForm(){
    return {
        objectHeight: null
    };
}

function ObjectRowsAmountRender(colisionRule){
    return {
        objectHeight: {
            type: 'number',
            rules: [formRules.positiveInteger, colisionRule],
            required: true,
        },
    };
}


function LocationNameForm(){
    return {
        locationName: null
    };
}

function LocationNameRender(){
    return {
        locationName: {
            max: 40,
            required: true,
            sizeCoefficient: 0.7,
        },
    };
}

export { NewObjectNameForm, NewObjectNameRender,
    ObjectNameForm, ObjectNameRender,
    ObjectColumnAmountForm, ObjectColumnAmountRender,
    ObjectRowsAmountForm, ObjectRowsAmountRender,
    LocationNameForm, LocationNameRender };
