<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockPicking."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <span class="text-subtitle-1 ml-2">
              {{ $t('tasks.stockPickingSet.stockPickingTasks') }}:
            </span>
          </v-flex>
          <v-flex
            xs12
          >
            <v-layout
              v-for="(pickingItem, index) of items"
              :key="pickingItem.id"
              wrap
              align-center
            >
              <v-flex
                xs12
                sm8
                md8
                lg5
                xl3
                class="px-1"
              >
                <v-autocomplete
                  v-model="pickingItem.task_id"
                  :items="currentSubstockItemsFiltered"
                  :error-messages="pickingItem.errors"
                  :label="$t('tasks.stockPicking.name')"
                  :loading="loadingSubstockTasks"
                  prepend-icon="$taskStockPickingSet"
                  :rules="index === 0 ? [formRules.required] : []"
                  single-line
                  :suffix="index === 0 ? '*' : ''"
                  @input="itemChanged(index)"
                />
              </v-flex>
              <v-flex
                xs2
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      v-show="pickingItem.task_id !== null"
                      :disabled="loading"
                      icon
                      v-on="on"
                      @click="removeItem(index)"
                    >
                      <v-icon>
                        $removeItem
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('tasks.stockPicking.removeItem') }}
                  </span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockPicking
              </v-icon>
              {{ $t('tasks.stockPickingSet.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        StockPickingSetCreateForm,
        StockPickingSetCreateRender
    } from "@/app/tasks/stockPickingSet/definitions/stockPickingSetCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import formRules from "@/utils/formRules";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskStockPickingSetAPI} from "@/api/TaskStockPickingSetAPI";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import {has} from "@/utils/object";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";

    export default {
        name: "StockPickingCreate",
        components: {TaskAttributes, FormFields},
        data: () => ({
            form: new StockPickingSetCreateForm,
            formRender: new StockPickingSetCreateRender,
            valid: true,
            validAttributes: true,
            loading: false,
            items: [{task_id: null}],
            formRules: formRules,
            substockItems: {},
            loadingSubstockTasks: false,
            type: taskTypes.STOCK_PICKING_SET,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            validAll: function () {
                return this.valid && this.validAttributes;
            },
            currentSubstockItems: function () {
                if (has(this.substockItems, this.form.subordinate_stock_id)) {
                    return this.substockItems[this.form.subordinate_stock_id];
                } else {
                    return [];
                }
            },
            currentSubstockItemsFiltered: function () {
                let items = this.currentSubstockItems;
                items = items.filter(item => {
                    return item.task.priority === this.form.priority;
                });

                return items;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                this.items = [{id: null}];
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'form.subordinate_stock_id': function (newValue) {
                this.items = [{id: null}];
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubstockTasks();
                }
            },
            'form.shipping_type': function (newValue) {
                this.items = [{id: null}];
                if (newValue !== undefined && newValue !== null
                    && this.form.subordinate_stock_id !== undefined && this.form.subordinate_stock_id !== null) {
                    this.loadSubstockTasks();
                }
            },
            'form.priority': function () {
                this.items = [{id: null}];
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        activated() {
            if(this.form.subordinate_stock_id !== undefined && this.form.subordinate_stock_id !== null){
                this.loadSubstockTasks();
            }
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstocks(this.form.stock_id)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadSubstockTasks: function () {
                const ssId = this.form.subordinate_stock_id;

                this.loadingSubstockTasks = true;
                TaskStockPickingAPI.getBySubstock(ssId, this.form.shipping_type)
                    .then(response => {
                        this.$set(this.substockItems, ssId, response.data.items
                            .map(task => ({
                                task: task,
                                text: this.getTaskLabel(task),
                                value: task.id
                            })));
                    }).catch(this.snack)
                    .finally(() => {
                        this.loadingSubstockTasks = false;
                    });
            },
            getTaskLabel: function (task) {
                const locationName = task.target_location_name || `<${this.$t('tasks.stockPickingSet.stockPickingLocationBlank')}>`;
                return `#${task.id} - ${task.description} - ${locationName}`;
            },
            itemChanged: function (index) {
                const instanceId = this.items[index].task_id;
                if (this.items.filter(item => item.task_id === instanceId).length > 1) {
                    this.items[index].errors = [this.$t('tasks.stockPickingSet.itemAlreadySelected')];
                } else {
                    this.items[index].errors = [];
                }
                if (this.items.filter(item => item.task_id === null).length === 0) {
                    this.items.push({task_id: null});
                }
            },
            removeItem: function (index) {
                if (this.items.length === 1) {
                    this.snack('tasks.stockPickingSet.unableToRemoveLastItem');
                    return;
                }
                this.items.splice(index, 1);
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {
                    tasks: this.items.filter(i => i.task_id).map(i => i.task_id)
                };

                TaskStockPickingSetAPI.create(data)
                    .then(response => {
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.stockPickingSet.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        setFormErrors.call(this, err);
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
