<template>
  <div>
    <Dashboard v-if="$store.getters['oauth/isAuthenticated']" />
    <Login v-else />
  </div>
</template>

<script>
    import Login from "@/app/Login.view";
    import Dashboard from "@/app/homepage/components/Dashboard.component";

    export default {
        name: "Homepage",
        components: {Dashboard, Login}
    };
</script>

<style scoped>

</style>
