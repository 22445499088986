import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
import {ExternalOrderState, TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";
import {deliveryTypes} from "@/enum/delivery_type";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

const notFoundOrACL = "Зазначений номер завдання не знайдено, або ви не маєте доступу до завдання!";
const notFoundOrACLOrItem = "Зазначений номер завдання чи ідентифікатор елемента не знайдено, або ви не маєте доступу до завдання!";
const approve409 = "Завдання не в стані, в якому його можна схвалити.";
const reject409 = "Завдання не в стані, в якому його можна відхилити!";
const notActive409 = "Завдання неактивне, або не всі товари були спрацьовані!";
const memberOfSet = "Цю дію вимкнено, оскільки завдання є частиною групи!";

const state = {
    name: "Стан",
    [TaskState.CREATED]: "Новий",
    [TaskState.IN_PROGRESS]: "В процесі",
    [TaskState.WAITING]: "Чекає",
    [TaskState.CLOSED]: "Завершений",
    [TaskState.CANCELED]: "Скасований",
    [TaskState.UNASSIGNED]: "Непризначений"
};

export default {
    name: "Завдання",
    id: "Завдання ID",
    external_id: "Externí číslo",
    parent_task_id: "Надзавдання",
    state: state,
    type: "Тип завдання",
    priority: {
        name: "Пріоритет",
        [TaskPriority.LOW]: "Низький",
        [TaskPriority.NORMAL]: "Нормальний",
        [TaskPriority.HIGH]: "Високий",
        unknown: "Невідомий пріоритет"
    },
    location: {
        name: "Місце зберігання",
        [TaskPreferredLocation.EXISTING]: "Будь-яке існуюче",
        [TaskPreferredLocation.LAST]: "Останнє використане"
    },
    description: "Опис",
    assigned_user: "Призначено",
    nobody: "<Ніхто>",
    delivery_type: "Тип доставки",
    delivery_number: "Номер доставки",
    invoice_number: "Номер накладної",
    supplier_id: "Постачальник",
    external_order_task_id: "Номер замовлення",
    supplier: "Постачальник",
    note: "Примітка",
    time_spent: "Витрачений час",
    ean: "EAN",
    lastScanned: "Востаннє сканувано",
    queue: "Черга",
    created_at: "Створено",
    updated_at: "Редаговано",
    viewTask: "Переглянути завдання",
    search: "Розширений пошук у завданнях",
    searchHint: "Він допоможе знайти номер завдання, номер батьківського завданння чи зовнішнє число завдання.",

    noItems: "Немає предметів!",
    useScanner: "Використовуйте зчитувач або поле вище, щоб добавити товари.",
    syncRunning: "Виконується синхронізація",
    generateReport: {
        pdf: "Створити звіт у форматі PDF",
        csv: "Створити звіт у форматі CSV",
        html: "Створити звіт у форматі HTML",
    },

    keep_storekeeper: "Залишити завдання призначене тому самому працівнику.",

    card: {
        actions: {
            label: "Štítek",
            labelDone: "Štítek '{0}' odeslán na tiskárnu.",
            split: "Rozdělit",
            splitDone: "'{0}' rozděleno!",
            splitFail: "Nelze rozdělit zboží které má jen 1 kus.",
            delete: "Smazat",
            deleteDone: "'{0}' odstraněno!"
        },
        create: {
            product: "Створити продукт на складі",
            location: "Створити місце зберігання"
        }
    },

    attributes: {
        name: "Атрибути завдання",
        configureFirst: "Спочатку встановіть дозволені атрибути в налаштуваннях підпапки",
        override: "Перевизначення існуючих значень атрибутів під час злиття з іншим завданням",
        created_at: "Атрибут створено в",

        update: {
            done: "Атрибути змінено",
        },
    },

    attachments: {
        name: "Вкладення",
        filename: "Назва файлу",
        download_link: "Скачати",
        status: {
            404: "Завдання чи вкладення не знайдено!",
            498: "Не вдалося завантажити вибраний файл!"
        }
    },

    notes: {
        name: "Примітки",
        text: "Текст",
        status: {
            404: "Завдання або примітку не знайдено!"
        }
    },

    filter: {
        chooseAttr: "Виберіть атрибут завдання",
        id: {
            name: "Номер"
        },
        parent_task_id: {
            name: "Надзавдання"
        },
        substock: {
            id: {
                name: "Підсклад"
            }
        },
        external_id: {
            name: "Зовнішній ідентифікатор"
        },
        state: state,
        priority: {
            name: "Пріоритет"
        },
        type: {
            name: 'Тип'
        },
        assigned_user: {
            id: {
                name: "Призначений користувач"
            }
        },
        created_at: {
            name: "Створено"
        },
        updated_at: {
            name: "Редаговано"
        },
        created_by: {
            name: "Створено"
        },
        updated_by: {
            name: "Редаговано"
        }
    },

    timeEntries: {
        name: "Витрачений час",
        link: "Витрачений час в завданнях",
        title: "Витрачений час в завданнях",
        user: "Користувач",
        hours: "Годин",
        created_at: "Створено",
        track: "Відстежувати час",
        saveTime: "Зберегти відстежений час?",
        saveTimeDetails: "В завданні є запущене відстеження витраченого часу. Бажаєте зберегти?",
        saved: "Витрачений час збережений.",
        saveFailed: "Не вдалося зберегти витрачений час!",
        selectTask: "Виберте завдання",
        edit: "Змінити час",
        editDone: "Час змінено",
        deleteDone: "Час було видалено!",
        table: {
            user: 'Користувач',
            hours: 'Годин',
            created_at: 'Створено'
        },
        status: {
            404: "Завдання або витрачений час не знайдено!"
        }
    },

    chooseItems: {
        product: "Товар",
        instance: "Екземпляр товару",
        productSpecification: "Специфікація товару",
        quantity: "Кількіст",
        sellPricePerUnit: "Продажна ціна за одиницю товару",
        priceVat: "Ставка ПДВ",
        buyPricePerUnit: 'Закупівельна ціна за одиницю',
        buyPriceVat: 'ставка ПДВ при купівлі',
        chooseConcreteInstance: "Вибрати конкретний товар",
        itemAlreadySelected: "Цей товар вже вибраний!",
        typeAlreadySelected: "Цей тип вже вибраний для цього складського товару!",
        unableToRemoveLastItem: "Неможливо видалити останній товар!",
        removeItem: "Видалити товар",

        scanOrChoose: "Скануйте будь-який товар або виберіть зі списку нижче",
        locationScanned: "Ви відсканували місце розташування замість коду товару!",
        itemNotAvailable: "Товар '{0}' неможливо вибрати!",
        itemQuantityExceeded: "Цей товар можна вибрати максимум {0}!",
        itemCannotBePutOnDestination: "Цей товар неможливо помістити на вибране місце призначення!",
        duplicityWarn: "Ця позиція введена кілька разів. Будь ласка, об'єднайте кількість цих позицій в одну або видаліть дублювання.",

        items: "товарів",
        pieces: "штук"
    },

    strictMode: {
        label: "Є строгим",
        [TaskItemsStrictMode.FREE]: "Вільний",
        [TaskItemsStrictMode.NO_EXTRA]: "Нічого зайвого",
        [TaskItemsStrictMode.EXACT]: "Строгий",
    },

    deliveryAccept: {
        name: "Прийняття доставки",
        create: "Створити прийняття доставки",
        createDone: "Завдання Прийняття доставки успішно створено",

        deliveryType: {
            [deliveryTypes.DELIVERY]: "Новий товар",
            [deliveryTypes.RETURN]: "Повернений товар"
        },
        items: {
            delivered: "Доставлені товари",
            isCustomInstance: "Це спеціальний екземпляр товару",
            isNotCustomInstance: "Це не спеціальний екземпляр товару"
        },

        [TaskItemsCardType.ASSIGNMENT]: "Прийнято",

        approve: {
            name: "Створити завантаження",
        },


        acceptor: "Прийняв",
        updateDetails: "Оновити деталі завдання прийняття доставки",
        newTask: "Параметри нового завдання завантаження",
        status: {
            404: notFoundOrACL
        },

        show: {
            label: "Дохід постачальника від поставок"
        },
        searchHint: "Він здійснює пошук за номером завдання, номером інвойсу, номером накладної та номером замовлення.",
    },

    stock_loading_priority: "Пріоритет",
    stock_loading_preferred_location: "Бажане місце збереження",
    stock_loading_description: "Опис",
    stock_loading_stock_id: "Склад",
    stock_loading_subordinate_stock_id: "Підпорядкований склад",
    stock_loading_strict_mode: "Строгий режим",
    stockLoading: {
        name: "Завантаження",
        create: "Створити завдання завантаження",
        createDone: "Завантаження створено.",

        priority: "Пріоритет",
        preferred_location: "Бажане місце збереження",
        description: "Опис",
        stock_id: "Склад",
        subordinate_stock_id: "Підпорядкований склад",
        supplier_id: "Постачальник",
        delivery_number: "Номер доставки",
        invoice_number: "Номер накладної",
        strict_mode: "Строгий режим",
        itemsToLoad: "Товари для завантаження",

        scanLocation: "Спочатку відскануйте місце збереження, куди ви хочете помістити товар.",
        scanSomeProducts: "Не можна завершити пусте завантаження.",
        assignAllLocations: "Встановіть цільове місце збереження для всіх товарів.",
        chooseProductForLocation: "Спочатку виберіть товар, якому ви хочете встановити це місце збереження.",
        locationNotAllowed: "Обране місце збереження не дозволено в цільовому підпорядкованому складі!",
        notToBeLoaded: "Завантажений товар не призначений для завантаження!",
        loadedLimit: "Перевищено ліміт по прибуттю на склад: {0}/{1}.",
        onlyItemsFromInventory: "Якщо у інвентарі є якісь товари, то на місце збереження можна переміщувати тільки товари з інвертарю користувача!",
        noItemsCanBePutOnLocation: "На обране місце не можна поставити жоден товар з завдання! Спробуйте завантажити деякі товари до інвентаря.",
        itemCanNotBePutOnLocation: "Завантажений товар не можна помістити на обране місце збереження!",

        loadTo: "Завантажити до",
        chooseInstanceAndHowMuch: "Якщо ви хочете роздрукувати ярлики, виберіть конкретний товар та його кількість.",
        chooseHowMuch: "Якщо ви хочете роздрукувати ярлики '{0}', виберіть кількість штук.",
        rightProductWrongInstanceScanned: "Ви не відсканували правильний товар.",
        lookForAnotherCode: "Ви не відсканували правильний товар, спробуйте знайти інший штрих-код на товарі.",

        [TaskItemsCardType.TO_MOVE]: "Для завантаження на склад",
        [TaskItemsCardType.IN_INVENTORY]: "Товари в інвентарі",
        [TaskItemsCardType.MOVED]: "Завантажено",
        [TaskItemsCardType.ASSIGNMENT]: "Для завантаження",

        returnTo: {
            [TaskItemsCardType.TO_MOVE]: "Повернутися до товарів на складі",
            [TaskItemsCardType.IN_INVENTORY]: "Повернення до товарів у дорозі",
        },

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Оголошені товари - лише рекомендація, можна прийти на склад будь-що.",
            [TaskItemsStrictMode.NO_EXTRA]: "Дозволяється завантаження на склад тільки оголошених товарів, але вони можуть бути і в меншій кількості.",
            [TaskItemsStrictMode.EXACT]: "Дозволяється прибуття на склад тільки оголошених товарів, але їх повинна бути відповідна кількість.",
        },

        confirmDelete: {
            fromInventory: "З інвентаря",
            fromDestination: "З місця збереження"
        },

        navigation: {
            assignment: "Завдання",
            stockLoading: "Завантаження",
            finish: "Завершення",
            buyPrices: "Налаштування закупівельних цін"
        },
        buyPrices: {
            title: "Закупівельні ціни",
            instance: "Складський товар",
            manufacturer: {
                id: "Виробник"
            },
            measure_unit: "Одиниця виміру",
            price: "Закупівельна ціна за одиницю",
            vat: "Ставка ПДВ",
            batchMultiply: {
                by: "Коефіцієнт",
                action: "Помножити відфільтровані ціни",
                done: "Ціни були оновлені",
                failed: "Оновлення цін не вдалося!"
            },
            negativeNotAllowed: "Змінити ціну не вдалося! Неможливо зберегти негативну ціну покупки.",
            status: {
                404: notFoundOrACLOrItem,
                409: approve409
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Серійний номер товару вже використовується, або завдання не знаходиться в активному стані!"
            },
            update: {
                status: {
                    404: notFoundOrACLOrItem,
                    409: notActive409
                }
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Товар з таким серійним номером вже існує, або місце збереження не дозволено для обраного підпорядкованого складу."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Товар з даним серійним номером вже знаходиться в іншому завданні завантаження, або вже є на складі."
        }
    },

    stockTaking: {
        name: "Інвентаризація",
        create: "Створити завдання інвентаризації",
        createDone: "Завдання інвентаризації створено.",

        priority: "Пріоритет",
        description: "Опис",
        restrict_type: "Тип обмеження",
        restrict_reference_id: "Обмежено на",
        stock_locations: "Місце збереження",
        stock_id: "Склад",
        attachments: "Додатки",

        scanLocation: "Спочатку відскануйте місце, де знаходиться товар.",
        noItems: "Немає товарів для інвентаризації!",
        substockAccessForbidden: "Ви не можете розпочати роботу над цим завданням, оскільки не маєте доступу до всіх підпорядкованих запасів.",

        entityHelper: {
            label: "Використовуйте пошук для масового вибору декількох місць. Введіть шуканий термін, перевірте відфільтровані місця, та натисніть 'Встановити ці місця збереження'.",
            hint: "Поле приймає будь-який регулярний вираз",
            set: "Встановити ці місця збереження"
        },

        navigation: {
            assignment: "Задача",
            stockTaking: "Інвентаризація",
            finish: "Завершити"
        },

        restrictions: {
            [TaskStockTakingRestriction.STOCK]: 'Склад',
            [TaskStockTakingRestriction.STOCK_OWNER]: 'Власник складу',
            [TaskStockTakingRestriction.MANUFACTURER]: 'Виробник',
            [TaskStockTakingRestriction.PRODUCT]: 'Складський товар',
            [TaskStockTakingRestriction.PRODUCT_INSTANCE]: 'Складський товар',
            [TaskStockTakingRestriction.STOCK_LOCATION]: 'Місцезнаходження',
            [TaskStockTakingRestriction.SUB_STOCK]: 'Підпорядкований склад',
            wrongLocation: 'Це місце не є предметом інвентаризації!'
        },

        [TaskItemsCardType.PRESENT]: "Знайдені товари",
        [TaskItemsCardType.ASSIGNMENT]: "Припущений стан",

        items: {
            partOfInventory: {
                yes: {
                    [TaskStockTakingRestriction.MANUFACTURER]: "Продукція виробників, які включені до інвентаризації",
                    [TaskStockTakingRestriction.PRODUCT]: 'Продукція, яка включена до інвентаризації',
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: "Бетонні вироби, які включено до інвентаризації",
                    [TaskStockTakingRestriction.STOCK_LOCATION]: 'Цього елемента не повинно бути на локації'
                },
                no: {
                    [TaskStockTakingRestriction.MANUFACTURER]: "Виробник цього товару не підлягає інвентаризації!",
                    [TaskStockTakingRestriction.PRODUCT]: "Такий складовий член не має інвентаризації!",
                    [TaskStockTakingRestriction.PRODUCT_INSTANCE]: "Ця конструкція складського складу створена для розміщення запасів!",
                    [TaskStockTakingRestriction.STOCK_LOCATION]: 'Цього елемента не повинно бути на локації'
                }
            },
            status: {
                404: notFoundOrACLOrItem,
                409: notActive409
            },
        },
        status: {
            404: notFoundOrACL
        }
    },

    stockPickingSet: {
        name: "Група розвантаженння",
        create: "Створити групу розвантаженння",
        createDone: "Завдання група розвантаженння створено.",
        stockPickingTasks: "Завдання розвантаженння",
        usedStrategy: "Створено за допомогою стратегії",
        pickingAlreadySelected: "Це завдання вже вибрано!",
        unableToRemoveLastItem: "Неможливо видалити останній товар!",
        stockPickingLocationFilled: "З цільовим місцем збереження",
        stockPickingLocationBlank: "без цільового місця збереження",
        itemAlreadySelected: "Дублікат товару!",
        strategies: {
            SAME_SINGLE_PRODUCT: "Один і той же одиночний продукт",
            SINGLE_PRODUCT: "Один продукт",
            IDENTICAL_ORDERS: "Ідентичні",
            FROM_OLDEST: "Від найстаріших"
        },

        delete: {
            title: "Розформувати групу розвантаженння та видалити завдання",
            deleteOne: "Видалити розвантаження з групи, розвантаження залишиться в огляді завдань для подальшої обробки",
            status: {
                404: notFoundOrACL,
                409: "Групу розвантаженння неможливо видалити через її зв'язки з іншими об'єктами!"
            }
        },
        items: {
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Товару немає на цьому місці, місце знаходження не дозволено для обраного підпорядкованого складу, або було досягнуто обмеження на переміщення з інвентаря."
            }
        },
        location: {
            status: {
                409: "Неможливо змінити цільове місце знаходження - на обраному місці вже йде пакування товару!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "Обрані розвантаження не можуть бути об'єднані."
        },
        advancedErrorMessages: {
            invalid_task_state: 'Завдання не перебуває в стані в якому можливо виконанати цю операцію. Став завдання {0}',
            location_not_allowed: 'Ця локація не дозволена для поточного підскладу.',
            missing_location: 'Локація, яку ви намагаєтеся використовувати, є недійсною або не існує.',
            movement_failed: 'Переміщення не вдалося, не вистачає кількості товарів на розміщенні.' +
                ' на переїзд {0}.',
            task_not_found: 'Завдання не знайдено або операція блокування завдання не вдалася.',
            invalid_moving_quantity: 'Кількість товарів, яку ви хочете перемістити, більша, ніж наявна кількість на розміщенні.' +
                ' на переїзд {0}. На місці {1}.'
        }
    },

    stockPicking: {
        name: "Розвантаження",
        create: "Створити завдання Розвантаження",
        createDone: "Завдання Розвантаження створено",
        createDoneAdded: "Товари додані до завдання №{0}",

        priority: "Пріоритет",
        description: "Опис",
        stock_id: "Склад",
        subordinate_stock_id: "Підпорядкований склад",
        tasks: "Завдання",
        shipping_type: "Спосіб завершення",
        destination_stock_location_id: "Місце пакування",
        destination_stock_location_id_hint: "Місце, де буде упаковано товар.",
        buyer_id: "Покупець",
        buyer_delivery_address_id: "Адреса доставки покупця",
        destination_location_id: "Цільове місце пакування",
        partOfSet: "Частина групи",
        external_order_id: "Замовлення",

        navigation: {
            assignment: "Задача",
            stockPicking: "Розвантаження",
            finish: "Завершення",
            movements: "Переміщення товарів"
        },

        chooseTargetLocation: "Виберіть цільове місце збереження!",
        scannedLocationNotEmpty: "Скановане місце збереження не порожнє, ви все одно хочете вибрати його як цільове?",
        notToBeMoved: "Сканований товар не призначено для переміщення або вже переміщено!",
        notInInventory: "Сканований товар не в переміщенні!",
        unableToFinishDueItems: "Завдання неможливо завершити, тому що не всі товари були переміщені!",
        lookForAnotherCode: "Ви не зчитали правильний товар, спробуйте знайти інший штрих-код на товарі.",

        type: {
            label: "Спосіб отримання",
            [TaskShippingType.COURIER]: "Доставка",
            [TaskShippingType.PERSONAL_COLLECTION]: "Персональна передача покупцю"
        },

        yetUnknown: "місце збереження ще не вибрано",
        handOverTo: "передача покупцю",

        autoMoveInProgress: "Автоматичне переміщення товарів, які вже на своєму цільовому місці знаходження.",
        itemsToPick: "Товари для видачі",
        removeItem: "Видалити товар",

        [TaskItemsCardType.TO_MOVE]: "Залишилося розвантажити",
        [TaskItemsCardType.IN_INVENTORY]: "Товари в процесі переміщення (в інвентарі користувача)",
        [TaskItemsCardType.MOVED]: "Видача",
        [TaskItemsCardType.ASSIGNMENT]: "Для розвантаження",
        pickedToHandover: "Готовий до передачі замовнику",

        items: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Неможливо змінити ціну продажу товару, тому що завдання не знаходиться в відповідному стані!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Товару немає на цьому місці, місце не дозволено для обраного підпорядкованого складу, або було досягнуто ліміту на переміщення."
            }
        },
        location: {
            status: {
                409: "Не можна змінити цільове місце збереження - пакування вже виконується на обраному місці!"
            }
        },

        status: {
            404: notFoundOrACL,
            409: "На складі недостатньо потрібних товарів."
        }
    },

    preparePackage: {
        name: "Пакування",
        order: "Замовлення",
        subordinate_stock_id: "Підпорядкований склад",
        location: "Розташування складських товарів",
        disengagePackingMode: "Повернутися до перегляду замовлень",

        navigation: {
            assignment: "Завдання",
            preparePackage: "Пакування",
            finish: "Завершення"
        },

        scanAnything: "Почніть зі сканування будь-якого непакованого товару.",
        scannedUnknown: "Сканований товар не є частиною жодного замовлення!",
        scannedUnknownOrTooMuch: "Сканований товар не є частиною жодного замовлення, або принаймні не в такій кількості!",
        scannedWrongItem: "Сканований товар не є частиною цього замовлення!",
        scannedTooMuchItem: "До цього замовлення входить менша кількість цього товару!",

        ean_read_required: "В цьому підпорядкованому складі необхідно відсканувати всі товари для пакування!",
        auto_packed_rest: "Замовлення автоматично запаковане!",
        haveYouPackedAllPieces: "Чи запаковані всі товари?",
        packAllPieces: "Запакувати {0}",

        orders: {
            label: "Замовлення",
            order_id: 'Замовлення',
            external_order_id: 'Номер замовлення',
            external_order_note: 'Примітка',
            printInvoice: 'Надрукувати накладну',
            printInvoiceAmount: {
                1: 'одну',
                2: 'дві'
            },
            invoiceCopies: "копії",
            unknown: "Невідоме замовлення!",
            cancelled: "Замовлення було скасовано!"
        },

        shipments: {
            carrier: {
                label: "Перевізник"
            },
            lastValidated: "Остання перевірка правильності",
            never: "<ніколи>",
            valid: "Посилку можна відправити",
            notValid: "Посилку не можна відправити",
            notValidatedYet: "Перевірка відправлення посилки ще не проводилася.",
            errorMessages:  "Повідомлення про помилки",
            package_count: "Кількість пакунків",
            total_value: "Загальна вартість",
            total_weight: "Загальна вага",
            change: {
                total_value: "Змінити загальну вартість посилки",
                total_weight: "Змінити загальну вагу посилки"
            },
            print: 'Надрукувати',
            label: "етикетка",
            deliveryNote: "накладна",
            printLabelAfterAllItemsPacked: 'Друкуйте етикетку лише після упакування всіх товарів замовлення.',
            printLabelPending: 'Отримується мітка перевізника ...',
            labelPrintedAlready: 'Етикетка для цієї посилки вже надрукована. Якщо вам дійсно потрібно надрукувати етикетку знову, зверніться до керівника складу.'
        },

        items: {
            type: {
                [PreparePackageItemListTypes.TODO]: 'Товари для упаковки',
                [PreparePackageItemListTypes.DONE]: 'Упаковано',
                [PreparePackageItemListTypes.ALL]: 'Замовлені товари'
            }
        },

        packing: {
            status: {
                404: notFoundOrACL,
                409: "Цей предмет не належить до обраного замовлення, або вже упакована достатня кількість!"
            }
        },

        status: {
            404: notFoundOrACL,
        }
    },

    moveProducts: {
        name: "Переміщення товарів",
        create: "Створити завдання на переміщення товарів",
        createDone: "Завдання на переміщення товарів створено.",
        type: {
            label: "Тип переміщення",
            [TaskMoveProductsType.COLLAPSE]: "Зібрати товари з будь-якого розміщення в {1}",
            [TaskMoveProductsType.DISTRIBUTE]: "Розподілити товари з {0} в будь-якому розміщенні",
            [TaskMoveProductsType.RELOCATE]: "Перемістити товари з {0} в {1}",
            [TaskMoveProductsType.MANYTOMANY]: "Переміщати з будь-якого розміщення в будь-яке",
        },

        priority: "Пріоритет",
        description: "Опис",
        stock_id: "Склад",
        subordinate_stock_id: "Підсклад",
        source_location_id: "Початкове розміщення",
        destination_location_id: "Кінцеве розміщення",
        transfer_mode: "Строгий режим",
        attachments: "Вкладення",

        anyLocation: "<будь-яке місце>",
        scanSourceOrDestLocation: "Будь ласка, відскануйте правильне початкове або кінцеве розміщення: {0} / {1}!",
        notToBeMoved: "Відсканований товар не призначений для переміщення, або вже переміщений!",
        notInInventory: "Відсканований товар не в інвентарі користувача!",
        unableToFinishDueItems: "Не можна завершити завдання, оскільки не були переміщені всі потрібні товари!",

        unableToMoveAnyToAny: "Неможливо переміщати з будь-якого місця в будь-яке. Будь ласка, виберіть хоча б одне конкретне розміщення.",
        unableToMoveToSame: "Неможливо перемістити в те ж саме місце. Будь ласка, виберіть інше початкове або кінцеве розміщення.",
        locationNotAllowed: "Вибране місце не дозволено для цільового підпорядкованого складу!",
        notEnoughItems: "На вибраному розміщенні недостатня кількість цього товару!",
        noItemsCanBePutOnLocation: "На вибраному розміщенні неможливо розмістити жоден товар з завдання!",
        itemCanNotBePutOnLocation: "Відсканований товар неможливо розмістити на вибраному розміщенні!",
        alreadyUsedAsDestination: "На це місце вже було розміщено товар! Поверніть його з переміщених.",
        alreadyUsedAsSource: "З цього розміщення товар вже був зібраний!",

        [TaskItemsCardType.TO_MOVE]: "Zbývá přesunout",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Přesunuto",
        [TaskItemsCardType.ASSIGNMENT]: "K přesunutí",
        [TaskItemsCardType.TO_MOVE]: "Залишилось перемістити",
        [TaskItemsCardType.IN_INVENTORY]: "Товари в процесі переміщення",
        [TaskItemsCardType.MOVED]: "Переміщено",
        [TaskItemsCardType.ASSIGNMENT]: "Для переміщення",

        strictHint: {
            [TaskItemsStrictMode.FREE]: "Zadané položky jsou pouze doporučení, je možné přesunout cokoliv.",
            [TaskItemsStrictMode.NO_EXTRA]: "Je umožněno přesunout pouze zadané položky, některé mohou chybět nebo jich být méně.",
            [TaskItemsStrictMode.EXACT]: "Je umožněno přesunout pouze avizované položky, žádné nesmí chybět a musí jich být uvedené množství.",
            [TaskItemsStrictMode.FREE]: "Вказані товари є лише рекомендацією, можна переміщати будь-що.",
            [TaskItemsStrictMode.NO_EXTRA]: "Дозволено переміщати тільки вказані товари, деякі можуть бути відсутні або бути в меншій кількості.",
            [TaskItemsStrictMode.EXACT]: "Дозволено переміщати тільки вказані товари, жоден не повинен бути відсутній та повинна бути зазначена кількість цих товарів.",
        },

        navigation: {
            assignment: "Завдання",
            moveProducts: "Переміщення",
            finish: "Завершення",
            movements: "Переміщення товарів"
        },

        items: {
            productsToMove: "Товари для переміщення",
            status: {
                404: notFoundOrACLOrItem,
                409: "Не вдалося додати новий товар для переміщення!"
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Товар відсутній на даному розміщенні, місце не дозволено для обраного підпорякованого складу, або було досягнуто ліміт на переміщення."
            }
        },
        finish: {
            status: {
                404: notFoundOrACL,
                409: "Завдання не знаходиться в активному стані, або у вас є нерозміщені товари!"
            }
        },
        status: {
            404: notFoundOrACL,
            409: "На початковому місці або в підпорядкованому складі недостатньо потрібних товарів.",
        }
    },

    locationTransfer: {

        name: "Переміщення локації",
        create: "Створити завдання по переміщенню локації",
        createDone: "Завдання по переміщенню локації створено.",
        priority: "Пріоритет",
        description: "Опис",
        stock_id: "Склад",
        allow_filled_locations: "Дозволити переміщення на вже зайняті локації",
        attachments: "Вкладення",

        scanLocation: "Наскануйте код розміщення! У випадку переміщення всієї локації, не можливо сканувати окремі товари!",
        scanningTo: "Наскануйте код розміщення, куди будуть переміщені всі товари.",
        canNotUseSame: "Вже обрано як початкове розміщення!",
        canNotUseNotEmpty: "Локація не порожня!",
        confirmTitle: "Підтвердіть переміщення {0} предметів ({1} шт) з '{2}' до '{3}'",
        confirmWithReader: "Для підтвердження ви також можете знову відсканувати код '{0}'.",
        confirmButton: "Підтвердити",
        moved: "Переміщено",
        noneMoved: "Жодна локація не була переміщена",

        navigation: {
            assignment: "Завдання",
            locationTransfer: "Переміщення",
            finish: "Завершення"
        },

        status: {
            404: notFoundOrACL,
        }
    },

    substockTransfer: {

        name: "Переміщення між підпорядкованими складами",
        create: "Створити завдання між підпорядкованими складами",
        createDone: "Завдання по переміщенню між підпорядкованими складами створено.",

        priority: "Пріоритет",
        description: "Опис",
        stock_id: "Склад",
        source_subordinate_stock_id: "Вихідний підпорядкований склад",
        destination_subordinate_stock_id: "Цільовий підпорядкований склад",
        attachments: "Вкладення",

        unableToMoveToSame: "Неможливо перемістити в один і тойже же підпорядкований склад. Оберіть інше вихідний або цільовий підпорядкований склад.",
        notEnoughItems: "У вибраному підпорядкованому складі недостатньо цього товару!",
        noItemsCanBePutOnLocation: "На вибране місце не можна покласти жодний товар із завдання.",
        itemCanNotBePutOnLocation: "Не можна помістити сканований товар на вибране місце!",

        from: "Перемістити з",
        to: "Перемістити до",

        notToBeMoved: "Сканований товар не призначено для переміщення або вже було переміщено!",
        notInInventory: "Сканований товар не знаходиться в процесі переміщення!",

        autoMoveInProgress: "Відбувається автоматичне переміщення товарів, які вже знаходяться на своєму місці призначення.",

        [TaskItemsCardType.TO_MOVE]: "Zbývá přesunout",
        [TaskItemsCardType.IN_INVENTORY]: "Položky v přesunu",
        [TaskItemsCardType.MOVED]: "Přesunuto",
        [TaskItemsCardType.ASSIGNMENT]: "K přesunutí",

        [TaskItemsCardType.TO_MOVE]: "Залишилось перемістити",
        [TaskItemsCardType.IN_INVENTORY]: "Товари в процесі переміщення",
        [TaskItemsCardType.MOVED]: "Переміщено",
        [TaskItemsCardType.ASSIGNMENT]: "Залишилось перемістити",

        isAutocompleted: "Завдання було автоматично завершено, оскільки цільовий підпорядкований склад має дозволені такі ж розміщення, як вихідний підпорядкований склад.",

        navigation: {
            assignment: "Завдання",
            transferProducts: "Переміщення",
            finish: "Завершення",
            movements: "Переміщення товарів"
        },

        items: {
            productsToMove: "Товари для переміщення",
            status: {
                404: notFoundOrACLOrItem
            }
        },
        inventory: {
            status: {
                404: notFoundOrACLOrItem,
                409: "Товар не на вибраному розміщені, місце не дозволено для вибраного підпорядкованого складу, або було досягнуто обмеження переміщення."
            }
        },
        status: {
            404: notFoundOrACL,
            409: "У вас неправильно обрано вихідне або цільове розміщення, або на вихідному розміщенні або в підпорядкованому складі недостатня кількість товарів."
        }
    },

    externalOrder: {
        name: "Замовлення",
        create: "Створити замовлення",
        createDone: "Замовлення створено.",

        priority: "Пріоритет",
        description: "Опис",
        stock_id: "Склад",
        subordinate_stock_id: "Підпорядкований склад",
        buyer_id: "Замовник",
        buyer_contact: "Інша інформація замовника",
        buyer_delivery_address_id: "Адреса доставки",
        processing_mode: "Спосіб спрацювання замовлення",
        carrier_id: "Перевізник",
        carrier_service_id: "Послуга перевізника",
        carrierServiceParameters: {
            title: "Параметри служби перевізника",
            name: "Назва",
            value: "Значення"
        },

        id: 'Номер замовлення',
        carrier: "Перевізник",
        quantity: "Замовлена кількість",
        satisfied_quantity: "Оброблена кількість",
        reserved_quantity: "Резервована кількість про дане замовлення",
        available: "Доступна кількість",
        toBePicked: "Кількість для розвантаження",
        product: {
            name: "Товар"
        },
        stock_picking_task_ids: "Розвантаження",
        prepare_shipment_package_task_ids: "Упаковка",
        shipment_ids: "Замовлення",
        selectedInstance: "Обрана одиниця для розвантаження",
        default: "<за замовчуванням>",
        external_author_id: "Ідентифікатор автора зовнішнього замовлення",
        external_author_name: "Ім'я автора зовнішнього замовлення",
        external_note: "Примітка до зовнішнього замовлення",
        externalFields: {
            label: "Параметри замовлення",
            author_id: "ID автора",
            author_name: "Ім'я автора",
            id: "Ідентифікатор",
            note: "Примітка",
            order_created_at: "Створено",
            order_id: "Зовнішній номер замовлення"
        },
        searchHint: "Шукає за номером завдання та номером замовлення",

        state: {
            label: "Стан замовлення",
            [ExternalOrderState.EO_STATE_CREATED]: "Нове",
            [ExternalOrderState.EO_STATE_UNABLE_TO_PROCESS]: "Неможливо спрацювати",
            [ExternalOrderState.EO_STATE_WAITING]: "Очікує спрацювання підзавдань",
            [ExternalOrderState.EO_STATE_IN_PROGRESS]: "На частині замовлення зараз працюють",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING]: "Замовлення очікує на розвантаження",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS]: "Замовлення зараз розвантажується",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING]: "Замовлення очікує на упаковку",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS]: "Замовлення зараз упаковується",
            [ExternalOrderState.EO_STATE_SHIPMENTS_WAITING]: "Деякі замовлення очікують на прийняття перевізником",
            [ExternalOrderState.EO_STATE_SHIPPED]: "Деякі замовлення були відправлені",

            [ExternalOrderState.EO_STATE_STOCK_PICKING_COMPLETED]: "Замовлення розвантажено зі складу",
            [ExternalOrderState.EO_STATE_STOCK_PICKING_CANCELED]: "Розвантаження скасовано",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_COMPLETED]: "Запаковане",
            [ExternalOrderState.EO_STATE_PREPARE_PACKAGE_CANCELED]: "Пакування скасовано",
            [ExternalOrderState.EO_STATE_SHIPMENT_CANCELLED]: "Замовлення скасовано",

            // More detailed EO_STATE_SENT
            [ExternalOrderState.EO_STATE_SENT]: "Відправлено",
            [ExternalOrderState.EO_STATE_BEING_DELIVERED]: "В процесі доставки",
            [ExternalOrderState.EO_STATE_DELIVERED_TO_PICKUP_LOCATION]: "Доставлено в пункт видачі товару",
            [ExternalOrderState.EO_STATE_LOST]: "Втрачено",

            // end of EO_STATE_SENT details
            [ExternalOrderState.EO_STATE_DELIVERED]: "Деякі замовлення були доставлені",
            [ExternalOrderState.EO_STATE_RETURNING]: "Деякі замовлення повертаються на склад",
            [ExternalOrderState.EO_STATE_RETURNED]: "Деякі замовлення повернулися на склад",
            [ExternalOrderState.EO_STATE_CANCELLED]: "Замовлення було скасовано",
            [ExternalOrderState.EO_STATE_CLOSED]: "Замовлення завершено",
        },

        processingMode: {
            label: "Спосіб спрацювання замовлення",
            changeRequired: "Якщо ви хочете спрацювати замовлення, змініть його спосіб спрацювання",
            [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Спрацювати разом",
            [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Спрацювати доступні товари",
            [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Не обробляти",

            hint: {
                [TaskExternalOrderProcessingMode.PROCESS_TOGETHER]: "Очікується наявність всіх замовлених товарів на складі.",
                [TaskExternalOrderProcessingMode.PROCESS_AVAILABLE]: "Як тільки деякі товари будуть на складі, буде створено розвантаження.",
                [TaskExternalOrderProcessingMode.DO_NOT_PROCESS]: "Замовлення не буде спрацьовано, розвантаження не буде створено."
            }
        },

        createStockPicking: "Деталі нового завдання розвантаження",
        approveNotPossible: "Замовлення в стані 'Не обробляти' не можна прийняти",
        someItemsNotAvailable: "Замовлення є в стані 'Спрацювати разом', але не всі товари доступні",

        navigation: {
            assignment: "Завдання",
            order: "Замовлення",
            finish: "Завершення"
        },

        update: {
            status: {
                409: "Тільки замовлення в стані 'Нове' може бути змінено!"
            }
        },

        process: {
            status: {
                404: notFoundOrACL,
                409: "Завдання не в стані для схвалення, або вимагається більше товарів, ніж є на складі!"
            }
        },

        approve: {
            name: "Створити розвантаження",
        },

        close: {
            label: "Закрити замовлення",
            note: "Примітка",
            shipment_state: "Новий стан посилок",
            done: "#{0} {1} закрито, відправлення встановлено на '{2}'"
        },

        items: {
            title: "Замовлені товари",
            status: {
                404: notFoundOrACLOrItem,
                409: "Завдання не в стані, коли можна видалити товар!"
            }
        },

        show: {
            label: "Замовлення покупця"
        }
    },

    productsReservation: {
        name: "Бронювання",
        create: "Створити бронювання",
        createDone: "Бронювання створено.",

        priority: "Пріоритет",
        description: "Опис",

        id: 'Номер бронювання',
        quantity: "Кількість бронювань",
        externalFields: {
            label: "Параметри бронювання",
            author_id: "Ідентифікатор автора",
            author_name: "Ім'я автора",
            id: "Ідентифікатор",
            note: "Примітка",
            order_created_at: "Створено",
            order_id: "Номер"
        },
        searchHint: "Робить пошук за номером завдання чи номером бронювання",

        [TaskItemsCardType.ASSIGNMENT]: "Зарезервований",
    },


    itemsCard: {
        canBeFoundAt: "Товар можна знайти на:",
        canBePutOn: "Товар можна помістити на:",
        alreadyPlacedAt: "Товар вже розташований на:",
        pickedUpFrom: "Товар було знято з:",

        updateQuantity: {
            notEnoughItemsInInventory: "На розташуванні неможливо перемістити більше одиниць, ніж в даний час є інвентарі користувача!",
            notEnoughItemsOnSource: "На початковому місці недостатньо товарів для переміщення!",
            itemNotPickedFromThisLocation: "Товар не було знято з даного місця!",
            notSoManyItemsPickedFromSource: "Ви намагаєтесь перемістити більше товарів ніж є на даному розміщені!",
            destinationLimit: "Перевищено ліміт переміщення: {0}/{1}.",
        },

        returnAmount: "Повернути {0} {1}",
        returnWhere: "на {0}",
        returnToInventory: "до товарів в інвентарі",
        returnTo: {
            location: "Повернути на початкове місце",
            inventory: "Повернути до товарів в інветарі"
        },

        remove: "Скасувати",

        from: "з",
        fromAnywhere: "звідки завгодно",
        to: "на",
        toAnywhere: "куди завгодно",

        substockLocations: "Розташування, дозволені для цільового підпорядкованого складу",

        scanLocation: "Почніть зі сканування місця.",
        scanPackingLocation: "Почніть зі сканування місця пакування {0}",
        scanOrderItem: "Наскануйте будь-який товар",
        scanLocationOrItem: "Почніть зі сканування місця або товару.",
        scanningFrom: "Наскануйте товар для зняття",
        scanningTo: "Наскануйте товар для розміщення",
        scanningOrder: "Наскануйте наступний товар замовлення #{0}",
        scannedExpeditionLocation: "Ви дійсно хочете відсканувати місце пакування?",
        scannedMobileLocation: "Ви дійсно хочете відсканувати мобільне розташування?",
        scanConfirm: "Сканувати {0}",
        cancelByScanning: "Або відскануйте інший код",
        confirmByScanning: "Або відскануйте код {0} знову",

        moreDetails: "Більше деталей",
        changeTargetLocation: "Змінити цільове розташування",
        cannotChangeTargetLocation: "Неможливо змінити цільове розташування, спочатку заберіть товари з поточного місця!",

        moveEverything: "Можна перемістити на розміження: ",
        moveEverythingInProgress: "Відбувається переміщення всіх товарів, які можна розташувати на цільовому місці.",
    },

    locationsCard: {
        canBePutOn: "На розміщення можна перемістити з завдання:",
        cannotBePutOnThisLocation: "На це місце не можна покласти цей товар!",
        cannotBePutOnAnyLocation: "Цей товар не можна розмістити на будь-якому місці в цьому завданні!",
        reloadAllowedLocations: "Оновити кеш"
    },

    itemPick: {
        inStock: "на складі",
        inProgress: "в призначених завданнях",
        available: "доступний"
    },

    unableToLoad: "Не вдалося завантажити список завдань. Спробуйте, будь ласка, пізніше.",


    approve: {
        name: "Прийняти",
        done: "#{0} {1} прийнято",
        doneTotal: "#{0} {1} завершено",
        status: {
            404: notFoundOrACL,
            409: approve409,
            423: memberOfSet
        }
    },
    cancel: {
        name: "Скасувати",
        done: "#{0} {1} скасовано",
        status: {
            404: notFoundOrACL,
            409: "Завдання не в статусі, з якого його можна скасувати!"
        }
    },
    reject: {
        name: "Відхилити",
        done: "#{0} {1} відхилено",
        status: {
            404: notFoundOrACL,
            409: reject409,
            423: memberOfSet
        }
    },
    assign: {
        name: "Призначити",
        self: "Почати працювати над завданням",
        notAssignable: "З розвантаженням неможливо працювати, тому що воно є частиною групи розвантажень!",
        useReader: "Для запуску завдання ви також можете сканувати будь-який код читачем.",
        done: "#{0} {1} призначено",
        status: {
            404: notFoundOrACL,
            403: "Завдання вже призначено або ви не маєте доступу до цільового запасу!",
            423: memberOfSet
        }
    },
    unassign: {
        name: "Відмінити призначення",
        disabled: "Завдання неможливо призначити, поки в процесі переміщення є якісь товари.",
        done: "#{0} {1} призначення скасовано",
        status: {
            404: "Вказаний номер завдання не знайдено!",
            409: "Ви не маєте призначеного вказаного завдання!",
            423: "Завдання не можна відмінити, тому що в процесі пересування є переміщені товари!"
        }
    },
    finish: {
        name: "Надати на затвердження",
        disabled: "Завдання неможливо завершити, оскільки деякі товари знаходяться на недозволених місцях.",
        done: "#{0} {1} завершено, передано.",
        doneTotal: "#{0} {1} завершено",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    handOver: {
        name: "Підтвердити передачу отримувачу",
        done: "#{0} {1} завершено, передано.",
        status: {
            404: notFoundOrACL,
            409: notActive409,
            423: memberOfSet
        }
    },
    finishAndApprove: "Завершити",
    finishAndApproveHint: "Ця дія доступна, оскільки ви відразу є складником і керівником складу.",
    movementsOverview: {
        title: "Огляд переміщень в завданні",
        sourceSubstock: "Початковий підпорядкований склад",
        destinationSubstock: "Цільовий підпорядкований склад",
    },

    status: {
        404: "Вказаний номер завдання не знайдено!",
        409: "Завдання ще не завершено!",
        423: memberOfSet
    }
};
