import {TableFilter} from "@/enum/table_filter";
import {Status} from "@/enum/import&export_state";
import i18n from "@/service/lang/i18n";
import {UserAPI} from "@/api/UserAPI";

export const importsTable = () => {
    return {
        status: {
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(Status).filter(item => item !== Status.CREATED && item !== Status.UPLOADED)
                .map(item => ({
                    text: i18n.t('exports.state.' + item),
                    value: item
                }))
        },
        processed_at: {
            filterType: TableFilter.DATETIME
        },
        created_by: {
            filterType: TableFilter.SELECT_MULTIPLE,
            autocomplete: {
                callFn: () => UserAPI.getAll(),
                thenFn: response => response.data.map(el => ({
                    text: (el.first_name + ' ' + el.last_name + ' (' + el.username + ')'),
                    value: el.id
                }))
            },
        },
        created_at: {
            filterType: TableFilter.DATETIME
        }
    };
};
