<template>
  <v-list-item
    href="https://manual.sklady.jagu.cz/"
    target="_blank"
  >
    <v-icon>
      help
    </v-icon>
    <span class="mx-3">
      {{ $t('base.help.title') }}
    </span>
    <v-icon>
      $openItem
    </v-icon>
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarHelp"
    };
</script>

<style scoped>

</style>
