<template>
  <div>
    <Alert
      :show-alert="showAutoMoveAlert"
      :display-text="$t('tasks.substockTransfer.autoMoveInProgress')"
    />
    <Alert
      :show-alert="showMovingAllAlert"
      :display-text="$t('tasks.itemsCard.moveEverythingInProgress')"
    />
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      :show-scan-confirmation="isLocationSource"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetActiveLocationId"
    />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-flex
        v-for="card of cardTypes"
        :key="card.type"
        xs12
        :class="'md' + 12 / cardTypes.length "
      >
        <TaskItemsCard
          :active-location-id="card.type === TaskItemsCardType.MOVED
            ? (whitelist.includes(activeLocationId) ? activeLocationId : null)
            : activeLocationId"
          :api="API"
          :card-type="card.type"
          :items="card.items"
          items-update-emit="fetch-items"
          :task-info="taskInfo"
          task-lang-path="substockTransfer."
          :is-destination-scanned="isDestinationScanned"
          :highlight-single-location="highlightSingleLocation(card.type)"
          @moveAll="moveAllFromInventory"
          @return-item="onReturnItem"
        >
          <template #items-location-header="{ locationId }">
            <TaskItemsLocationHeaderWithAllowedItems
              :location-id="locationId"
              :allowed-instances="allowedInstances[locationId]"
              :allowed-locations-loaded="allowedLocationsLoaded"
              :card-type="card.type"
              :num-of-instances="items.length"
            />
          </template>
        </TaskItemsCard>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskSubstockTransferAPI as API} from "@/api/TaskSubstockTransferAPI";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {CodeType} from "@/enum/code_type";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {scrollTo} from "@/service/Vuetify";
    import {readerFeedback} from "@/utils/readerFeedback";
    import Alert from "@/app/components/Alert.component";
    import {MoveAllMixin} from "@/app/mixins/MoveAllMixin";
    import {UpdateQuantityMixin} from "@/app/mixins/UpdateQuantityMixin";
    import {StockAPI} from "@/api/StockAPI";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import TaskItemsLocationHeaderWithAllowedItems
        from "@/app/tasks/components/taskItemsCard/TaskItemsLocationHeaderWithAllowedItems.component.vue";

    export default {
        name: "SubstockTransferTransfer",
        components: {TaskItemsLocationHeaderWithAllowedItems, TaskConflict, TaskBarcodeReader, TaskItemsCard, Alert},
        mixins: [
            TaskStateMixin,
            TaskAssignMixin,
            TaskFetchItemsMixin,
            EventsListenerMixin,
            MoveAllMixin,
            UpdateQuantityMixin
        ],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            activeLocationId: null,
            whitelist: [],
            API: API,
            showMovingAllAlert: false,
            TaskItemsCardType: TaskItemsCardType,
            autoMoveTriggered: false,
            showAutoMoveAlert: false,
            conflict: null
        }),
        computed: {
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'update-quantity': this.updateQuantity,
                    'taskItems-quantitiesLoaded': this.moveAlreadyCorrectItems,
                    'task-movement-conflict': cnflct => this.conflict = cnflct
                };
            },
            itemsToMove: function () {
                return this.items.filter(el => el.quantity_to_move - (el.quantity_in_user_inventory + el.processed_quantity) > 0);
            },
            itemsInInventory: function () {
                return this.items.filter(el => el.quantity_in_user_inventory !== 0);
            },
            itemsMoved: function () {
                return this.items.filter(el => el.processed_quantity !== 0);
            },
            cardTypes: function () {
                return [
                    {
                        type: TaskItemsCardType.TO_MOVE,
                        items: this.itemsToMove
                    }, {
                        type: TaskItemsCardType.IN_INVENTORY,
                        items: this.itemsInInventory
                    }, {
                        type: TaskItemsCardType.MOVED,
                        items: this.itemsMoved
                    }
                ];
            },
            scannerMode: function () {
                if (this.activeLocationId !== null) {
                    if (this.isLocationSource(this.activeLocationId)) {
                        return scannerModes.SOURCE;
                    } else {
                        return scannerModes.DESTINATION;
                    }
                }
                return scannerModes.IDLE;
            },
            isDestinationScanned: function () {
                return this.scannerMode === scannerModes.DESTINATION;
            },
            highlightSingleLocation: function () {
                return cardType => {
                    switch (cardType) {
                    case TaskItemsCardType.MOVED:
                        return this.scannerMode === scannerModes.DESTINATION;
                    case TaskItemsCardType.IN_INVENTORY:
                        return this.scannerMode !== scannerModes.IDLE;
                    default:
                        return false;
                    }
                };
            },
            allowedLocationsLoaded: function () {
                return !this.items.filter(item => item.allowedLocationIds === undefined).length;
            }

        },
        createdOrActivated: function (lifeCycleHook) {
            this.fetchAllowedLocations()
                .then(() => {
                    this.fetchItems({initial: lifeCycleHook === this.LifeCycleHook.CREATED})
                        .then(() => {
                            this.ready = true;
                        }).catch(this.snack);
                }).catch(this.snack);
            this.conflict = null;
        },
        methods: {
            onFetchItems: function (callback) {
                this.fetchItems()
                    .then(callback)
                    .catch(this.snack);
            },
            isLocationSource: function (locationToCheckId) {
                return !this.taskInfo.destinationLocations.map(location => location.id).includes(locationToCheckId);
            },
            fetchAllowedLocations: function () {
                const subStock = this.taskInfo.details.destination_subordinate_stock;
                return StockAPI.getAllSubstockAvailableLocationsAllPages(subStock.stock_id, subStock.id)
                    .then(response => {
                        this.whitelist = response.data.items.map(location => location.id);
                    }).catch(this.snack);
            },
            moveAlreadyCorrectItems: function () {
                if (this.autoMoveTriggered || !this.isInProgress) {
                    return;
                }
                this.autoMoveTriggered = true;
                const destinationLocationsIds = this.taskInfo.destinationLocations.map(loc => loc.id);
                const promises = [];
                this.items.forEach(item => {
                    if (item.locations !== undefined) {
                        item.locations.forEach(location => {
                            const locationId = location.stock_location.id;
                            if (!destinationLocationsIds.includes(locationId)) {
                                return;
                            }
                            const itemDestinationLocation = item.destination_locations.find(dstLoc => dstLoc.stock_location_id === locationId);
                            const quantity = Math.min(location.quantity - (itemDestinationLocation ? itemDestinationLocation.store_quantity : 0),
                                                      item.quantity_to_move - (item.quantity_in_user_inventory + item.processed_quantity));
                            if (quantity > 0) {
                                promises.push(new Promise((resolve, reject) => {
                                    this.API.pickUpFromSource(this.taskInfo.taskId, item.id, locationId, quantity)
                                        .then(() => {
                                            this.API.putToDestination(this.taskInfo.taskId, item.id, locationId, quantity)
                                                .then(resolve)
                                                .catch(reject);
                                        })
                                        .catch(reject);
                                }));
                            }
                        });
                    }
                });
                if (promises.length > 0) {
                    this.showAutoMoveAlert = true;
                    Promise.all(promises)
                        .then(() => {
                            this.conflict = null;
                            this.fetchItems()
                                .then(() => {
                                    this.showAutoMoveAlert = false;
                                });
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 409) {
                                this.conflict = err.response.data;
                            } else {
                                this.snack(err);
                            }
                        });
                }
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    if (this.scannerMode === scannerModes.IDLE) {
                        this.readingFail('tasks.itemsCard.scanLocation');
                    } else {
                        this.tryToMove(barcodeInfo.object_id, barcodeInfo.quantity * quantity);
                        const item = this.items.find(item => item.product_instance_id === barcodeInfo.object_id);
                        if (item) {
                            this.fetchInstanceAllowedLocations(item);
                        }
                    }
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    this.changeActiveLocation(barcodeInfo);
                    this.$nextTick(() => {
                        if (this.activeLocationSide === 'pick') {
                            scrollTo('taskItemsCard-' + TaskItemsCardType.TO_MOVE);
                        } else if (this.activeLocationSide === 'put') {
                            scrollTo('taskItemsCard-' + TaskItemsCardType.MOVED);
                        }
                    });
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                this.readingFail();
            },
            tryToMove: function (instanceId, quantity) {
                let promiseCallback = () => Promise.resolve();
                if (this.scannerMode === scannerModes.SOURCE) {
                    const item = this.itemsToMove.find(item => item.product_instance_id === instanceId);
                    if (item) {
                        promiseCallback = () => API.pickUpFromSource(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                    } else {
                        this.readingFail('tasks.substockTransfer.notToBeMoved');
                        return;
                    }
                } else {
                    const item = this.itemsInInventory.find(item => item.product_instance_id === instanceId);
                    if (item) {
                        if (this.allowedInstances[this.activeLocationId]
                            && item.allowedLocationIds
                            && !this.allowedInstances[this.activeLocationId].find(instance => instance.id === item.instance.id)) {
                            this.readingFail('tasks.substockTransfer.itemCanNotBePutOnLocation');
                            return;
                        }
                        if ((item.processed_quantity + quantity <= item.quantity_to_move)) {
                            promiseCallback = () => API.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                        } else {
                            this.advancedSnack({
                                text: 'tasks.itemsCard.updateQuantity.destinationLimit',
                                params: [item.processed_quantity + quantity, item.quantity_to_move]
                            });
                            this.readingFail();
                            return;
                        }
                    } else {
                        this.readingFail('tasks.substockTransfer.notInInventory');
                        return;
                    }
                }
                this.sendUpdatedQuantity().then(() => {
                    promiseCallback().then(() => {
                        this.conflict = null;
                        readerFeedback.success();
                        this.fetchItems({
                            debounceQuantities: true,
                            onlyInstanceId: instanceId
                        }).catch(this.snack)
                            .finally(this.readingDoneNoVibrate);
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            this.conflict = err.response.data;
                            // send empty message to stop barcode loading
                            this.readingFail('');
                        } else {
                            this.readingFail(err);
                        }
                    });
                }).catch(() => this.readingFail());
            },
            resetActiveLocationId: function () {
                this.activeLocationId = null;
            },
            changeActiveLocation: function (barcodeLocation) {
                const locationId = barcodeLocation.object_id;
                const locationAllowedInstances = this.getAllowedInstances(locationId);
                if (!this.isLocationSource(locationId)) {
                    if (locationAllowedInstances.length === 0) {
                        this.readingFail('tasks.substockTransfer.noItemsCanBePutOnLocation');
                        return;
                    }
                    if (!this.whitelist.includes(locationId)) {
                        this.readingFail('tasks.moveProducts.locationNotAllowed');
                        return;
                    }
                }
                this.activeLocationId = locationId;
                this.readingDone();
            },
        }
    };
</script>

<style scoped>

</style>
