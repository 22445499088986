<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedStock !== null"
      :show.sync="confirmDialog"
      :text="deletedStock.name"
      @confirm-delete="reallyDeleteStock"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :show-search-bar="false"
      >
        <template #item.is_small="{ item }">
          <td>{{ item.is_small | yesOrNo }}</td>
        </template>
        <template #footer>
          <TableAddItemButton
            :to="stockCreateLink"
            label="stocks.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="stockCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {createHeaders} from "@/utils/table";
    import {stockTable} from "@/app/stocks/definitions/stock.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "StocksList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            items: [],
            stockCreateLink: '/stocks/create',
            reload: 0,
            deletedStock: null,
            confirmDialog: false
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/stocks/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        routerLink: item => '/stocks/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return StockAPI.getAll.bind(StockAPI);
            },
            apiDataSourceAllPages: function () {
                return StockAPI.getAllPages.bind(StockAPI);
            },
            headers: function () {
                return createHeaders(stockTable, 'stocks.');
            }
        },
        methods: {
            deleteItem: function (item) {
                this.deletedStock = item;
                this.confirmDialog = true;
            },
            reallyDeleteStock: function () {
                this.loading = true;
                this.confirmDialog = false;
                StockAPI.delete(this.deletedStock.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'stocks.delete.done',
                            params: [this.deletedStock.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.reload++;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
