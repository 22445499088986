import {has} from "@/utils/object";
import {StockAPI} from "@/api/StockAPI";
import {ShipmentAPI} from "@/api/ShipmentAPI";

/**
 * Requires:
 *  - this.getShipmentsFilter
 *  - this.render.subStockId
 *
 */
const ShipmentProtocolMixin = {
    data: () => ({
        subStocks: {}
    }),
    createdOrActivated: function () {
        this.subStocks = {};
    },
    methods: {
        fetchSubStocks: function (includeAll = false) {
            const stockId = this.form.stockId;
            if (!has(this.subStocks, null)) {
                // subStocks for unknown carrier
                this.$set(this.subStocks, null, {});
            }
            if (has(this.subStocks[null], stockId)) {
                this.render.subStockId.autocomplete.items = this.subStocks[null][stockId];
                return Promise.resolve();
            }
            this.$set(this.render.subStockId, 'loading', true);
            return StockAPI.getAllSubstockPages(stockId)
                .then(response => {
                    const subStocksAutocomplete = response.data.items.map(substock => ({
                        text: substock.name,
                        value: substock.id,
                        ownerId: substock.owner.id
                    }));
                    if (includeAll && subStocksAutocomplete.length > 1) {
                        subStocksAutocomplete.unshift({
                            text: this.$t('stocks.stockStatus.allSubstocks'),
                            value: null
                        });
                    }
                    this.$set(this.subStocks[null], stockId, subStocksAutocomplete);
                    this.render.subStockId.autocomplete.items = this.subStocks[null][stockId];
                }).catch(this.snack)
                .finally(() => {
                    this.$set(this.render.subStockId, 'loading', false);
                });
        },
        checkChosenSubStockAvailable: function () {
            const availableSubStocks = this.render.subStockId.autocomplete.items.map(itm => itm.value);
            if (Array.isArray(this.form.subStockId)) {
                this.form.subStockId = this.form.subStockId.filter(id => id === null || availableSubStocks.includes(id));
            } else if (!availableSubStocks.includes(this.form.subStockId)) {
                this.form.subStockId = null;
            }
        },
        getSubStocksForCarrier: function (carrierId, includeAll = false) {
            const stockId = this.form.stockId;
            if (!stockId) {
                return;
            }
            let promise = Promise.resolve();
            if (!has(this.subStocks, null) || !has(this.subStocks[null], stockId)) {
                promise = this.fetchSubStocks(includeAll);
            }
            promise.then(() => {
                if (!has(this.subStocks, carrierId)) {
                    this.$set(this.subStocks, carrierId, {});
                }
                if (has(this.subStocks[carrierId], stockId)) {
                    this.render.subStockId.autocomplete.items = this.subStocks[carrierId][stockId];
                    this.checkChosenSubStockAvailable();
                    return;
                }

                this.loading = true;
                this.$set(this.render.subStockId, 'loading', true);
                const stockSubstocks = this.subStocks[null][stockId];
                const subStocksForCarrier = [];
                const promises = [];
                stockSubstocks.forEach(substock => {
                    if (substock.value === null) {
                        return;
                    }
                    promises.push(
                        ShipmentAPI.getAll({
                            itemsPerPage: 1,
                            filter: this.getShipmentsFilter(carrierId, substock.value)
                        }).then(response => {
                            if (response.data.item_count > 0) {
                                subStocksForCarrier.push(substock);
                            }
                        })
                    );
                });

                Promise.all(promises).then(() => {
                    subStocksForCarrier.sort((a, b) => a.id - b.id);
                    if (includeAll && subStocksForCarrier.length > 1) {
                        subStocksForCarrier.unshift({
                            text: this.$t('stocks.stockStatus.allSubstocks'),
                            value: null
                        });
                    }
                    this.$set(this.subStocks[carrierId], stockId, subStocksForCarrier);
                    this.render.subStockId.autocomplete.items = this.subStocks[carrierId][stockId];
                    this.checkChosenSubStockAvailable();
                }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.$set(this.render.subStockId, 'loading', false);
                    });
            });
        },
    }
};

export {ShipmentProtocolMixin};