function ProductSerialNumberForm() {
    return {
        number: '',
        same_barcode: true
    };
}

function ProductSerialNumberRender() {
    return {
        number: {
            icon: '$serialNumber',
            max: 50,
            required: true
        },
        same_barcode: {
            icon: '$barcode',
            checkbox: true
        }
    };
}

export {ProductSerialNumberForm, ProductSerialNumberRender};
