<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col
          class="mt-2"
        >
          {{ $t('map.selectedLocations') }}
        </v-col>
        <v-col
          style="text-align: right"
          class="mt-2"
        >
          <v-btn
            icon
          >
            <v-icon
              @click="unselectCells()"
            >
              $close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="4"
          >
            {{ $t('map.cellCount') }}
          </v-col>
          <v-col>
            {{ getRackSelectionAttributes.cellsAmount }}
          </v-col>
        </v-row>
        <v-row
          class="pt-4 pb-3 text-h6"
        >
          {{ $t('map.automaticLocationEdit') }}
        </v-row>
        <v-row>
          <v-col
            cols="4"
            class="pt-6"
          >
            {{ $t('map.locationCount') }}
          </v-col>
          <v-col
            class="pt-0 pl-0 pr-6"
          >
            <v-form
              ref="locationAmountForm"
              @submit.prevent
            >
              <FormFields
                :form="locationAmountForm"
                :render="locationAmountFormRender"
                lang-path="map."
                stretch-form
              />
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="getRackSelectionAttributes.cellsAmount === 1"
      >
        <div
          class="mb-5 text-h6"
        >
          {{ $t('map.manualLocationEdit') }}
        </div>
        <EditLocationsForm
          :locations="getRackSelectionAttributes.locations"
          @addLocation="addNewLocationToCell"
          @removeLocation="removeLocationFromLocationGroup"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
    import {
        LocationAmountForm, LocationAmountRender,
    } from "@/app/stocks/map/definition/rackSetup.form";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {MapMixin} from "@/app/mixins/MapMixin";
    import EditLocationsForm from "@/app/stocks/map/components/forms/EditLocationsForm.component.vue";
    export default {
        name: "RackCellDetailPanel",
        components: {EditLocationsForm, FormFields},
        mixins: [ MapMixin ],
        data: () => ({
            locationAmountForm: new LocationAmountForm(),
            locationAmountFormRender: new LocationAmountRender(),
        }),
        computed: {
            // grouped info of selected cells
            getRackSelectionAttributes() {
                return this.$store.getters["storageMap/getRackSelectionAttributes"];
            },
        },
        watch: {
            getRackSelectionAttributes:  function () {
                if (this.getRackSelectionAttributes.amount >= 0) {
                    this.locationAmountForm.count = this.getRackSelectionAttributes.amount;
                }
                else {
                    this.locationAmountForm.count = null;
                }
            },
            locationAmountForm: {
                handler: function () {
                    if (this.showMap === false && this.$refs.locationAmountForm.validate() && this.locationAmountForm.count!=null) {
                        this.$store.dispatch('storageMap/setLocationAmountInSelection', {
                            amount: Number.parseInt(this.locationAmountForm.count, 10)
                        });
                    }
                },
                deep: true
            },
        },
        methods: {
            addNewLocationToCell: function (data) {
                this.$store.dispatch('storageMap/addNewLocationToSelectedRackCell',{
                    name: data.name,
                    stockLocationId: data.stockLocationId
                });
            },
            removeLocationFromLocationGroup: function (data) {
                this.$store.dispatch('storageMap/removeLocationFromSelectedRackCell',{
                    name: data.location.name,
                    stockLocationId: data.location.stockLocationId
                });
            },
            unselectCells: function () {
                this.$store.commit('storageMap/setRackSelection', {
                    tl: [-1,-1],
                    br: [-1,-1]
                });
            }
        }
    };
</script>

<style scoped>

</style>
