<template>
  <v-list-item v-if="enabled">
    <v-icon
      class="mr-3"
    >
      $version
    </v-icon>
    <span
      class="text--disabled"
    >
      {{ $t('base.version') + ' ' + version }}
    </span>
  </v-list-item>
</template>

<script>
    import config from "@/../package";

    export default {
        name: "ToolbarVersion",
        data: () => ({
            enabled: false, // TODO load version from gitlab tags or inject while building, then enable
            version: config.version
        })
    };
</script>

<style scoped>

</style>
