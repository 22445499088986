<template>
  <FormFields
    :form="form"
    :render="formRender"
    lang-path="base.bank_account."
  />
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {BankAccountForm, BankAccountRender} from "@/app/components/form/definitions/bankAccount.form";

    export default {
        name: "FormBankAccount",
        components: {FormFields},
        props: {
            form: {
                type: Object,
                default: () => (new BankAccountForm)
            }
        },
        data: () => ({
            formRender: new BankAccountRender,
        })
    };
</script>

<style scoped>

</style>
