<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedAddress !== null"
      :show.sync="confirmDialog"
      :text="addressInline(deletedAddress)"
      @confirm-delete="reallyDeleteAddress"
    />
    <x-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :actions="actions"
      :search-hint="$t('buyers.addresses.searchHint')"
    >
      <template #item.default="{ item }">
        <td>{{ item.default | yesOrNo }}</td>
      </template>
      <template #item.privacy="{ item }">
        <td>{{ item.privacy === null ? '' : usersCache[item.privacy] }}</td>
      </template>
      <template #footer>
        <TableAddItemButton
          :to="'/buyers/' + buyerId + '/address/create'"
          label="buyers.addresses.create.titleSimple"
        />
      </template>
    </x-data-table>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {addressTable} from "@/app/components/form/definitions/address.form";
    import {flatten} from "@/utils/object";
    import {BuyerAPI as API} from "@/api/BuyerAPI";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {buyerAddressTable} from "@/app/buyers/definitions/buyerAddress.form";
    import {UserAPI} from "@/api/UserAPI";
    import {addressInline} from "@/utils/string";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "BuyerDeliveryAddressList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            loading: false,
            items: [],
            usersCache: [],
            addressInline: addressInline,
            deletedAddress: null,
            confirmDialog: false
        }),
        computed: {
            headers: () => [
                ...createHeaders(addressTable, 'base.address.', false),
                ...createHeaders(buyerAddressTable, 'buyers.addresses.', true)
            ],
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/buyers/' + this.buyerId + '/address/' + item.address_id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                API.getAllAddresses(this.buyerId)
                    .then(response => {
                        this.items = response.data.map(address => flatten(address, true));
                        const userIDs = [...new Set(this.items.map(item => item.privacy).filter(item => item !== null))];
                        const promises = [];
                        for (const userId of userIDs) {
                            if (this.usersCache[userId] === undefined) {
                                promises.push(UserAPI.get(userId));
                            }
                        }
                        Promise.all(promises)
                            .then(responses => {
                                responses.forEach(response => {
                                    this.$set(this.usersCache, response.data.id, response.data.first_name + ' ' + response.data.last_name);
                                });
                            });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            deleteItem: function (item) {
                this.deletedAddress = item;
                this.confirmDialog = true;
            },
            reallyDeleteAddress: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.deleteAddress(this.buyerId, this.deletedAddress.address_id)
                    .then(() => {
                        this.snack('buyers.addresses.delete.done');
                    }).catch(this.snack)
                    .finally(this.getData);
            }
        }
    };
</script>

<style scoped>

</style>
