import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c(VIcon,{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.onIconIntersect),expression:"onIconIntersect"}],staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.taskItemsCardTypeIcons[_vm.cardType])+" ")]),_c(_vm.fabDirection === 'up' ? 'v-scroll-y-reverse-transition' : 'v-scroll-y-transition',{tag:"component"},[_c(VBtn,_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showNavigationFab),expression:"showNavigationFab"}],staticClass:"fab-pointer",class:{
        'mr-16': _vm.nudgeRight,
        'fab-pointer-up': _vm.fabDirection === 'up',
        'fab-pointer-down': _vm.fabDirection === 'down',
      },style:(_vm.fabDirection === 'up' ? `top: ${_vm.topOffset} !important` : ''),attrs:{"fixed":"","color":"secondary","fab":"","dark":""},on:{"click":function($event){return _vm.scrollTo(`taskItemsCard-${_vm.cardType}`)}}},'v-btn',_vm.position,false),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.taskItemsCardTypeIcons[_vm.cardType])+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }