<template>
  <div>
    <v-card-title>
      <template v-if="showSearchBar">
        <v-text-field
          v-show="showSearchBar"
          v-model="search"
          append-icon="$search"
          :label="$t('base.searchAll')"
          single-line
          hide-details="auto"
          :hint="searchHint ? searchHint : fullTextSearchHint"
          :persistent-hint="!!searchHint || !!fullTextSearchHint"
        />
      </template>
      <slot
        v-if="$scopedSlots.header !== undefined"
        name="header"
      />
      <v-spacer v-else />
      <span
        v-show="$vuetify.breakpoint.smAndUp"
        class="transition-general cursor-pointer mt-2"
      >
        <span
          v-if="hasAdvancedFilters"
          @click="toggleFilters"
        >
          <span
            v-show="$vuetify.breakpoint.mdAndUp"
            class="text-body-2"
            :class="{'text--disabled' : !showFilters}"
          >
            {{ $t('base.advancedFilter') }}
          </span>
          <v-icon
            :disabled="!showFilters"
            class="ml-2"
          >
            $extendedFilter
          </v-icon>
        </span>
        <v-menu
          left
          offset-x
        >
          <template #activator="{ on: onMenu, attrs }">
            <v-tooltip top>
              <template #activator="{ on: onTooltip }">
                <span v-on="onTooltip">
                  <v-btn
                    icon
                    small
                    class="ml-4"
                    v-bind="attrs"
                    v-on="onMenu"
                  >
                    <v-icon>
                      $export
                    </v-icon>
                  </v-btn>
                </span>
              </template>
              {{ $t('base.download', ['']) }}
            </v-tooltip>
          </template>
          <v-list class="pa-0">
            <v-list-item-group
              v-for="format of Object.keys(exportFormats)"
              :key="format"
            >
              <v-list-item @click="exportFormat(format)">
                {{ $t('base.download', [format.toUpperCase()]) }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </span>
    </v-card-title>
    <v-data-table
      :options.sync="options"
      :search="search"
      :headers="headers"
      :items="filteredItems"
      :show-select="showSelectLocal"
      :footer-props="{'items-per-page-options': itemsPerPageOptions}"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :show-expand="showExpand"
      :expanded.sync="expandedLocal"
      :item-key="itemKey"
      v-bind="$attrs"
      @current-items="updateCurrentItems"
      @toggle-select-all="toggleSelectAll"
    >
      <template
        v-for="column of headers.filter(col => col.icon)"
        #[headerSlotName(column)]="{ header }"
      >
        <v-tooltip
          :key="column.value"
          top
        >
          <template #activator="{ on }">
            <v-icon v-on="on">
              {{ column.icon }}
            </v-icon>
          </template>
          {{ header.text }}
        </v-tooltip>
      </template>
      <template
        v-if="$vuetify.breakpoint.smAndUp"
        #item="{ item, expand, isExpanded }"
      >
        <tr
          :key="showExpand ? item[itemKey] : undefined"
          :class="rowClass(item, isExpanded)"
          @contextmenu.prevent="showContextMenu(item)"
        >
          <td
            v-if="showSelectLocal"
          >
            <v-checkbox
              :key="item.id"
              v-model="selection"
              :value="item.id"
              dense
              :color="itemSelectColor(item)"
            />
          </td>
          <td
            v-for="header of headers.filter(h => h.value !== 'x_actions' && h.value !== 'data-table-expand')"
            :key="header.value"
            @click="clickRow(item)"
          >
            <slot
              v-if="$scopedSlots['item.' + header.value] !== undefined"
              :name="'item.' + header.value"
              :item="item"
            />
            <template v-else>
              {{ header.itemLabel(item) }}
            </template>
          </td>
          <td v-if="headers.find(h => h.value === 'x_actions') !== undefined">
            <TableActionButtons
              :actions="actionsWithLoading"
              :item="item"
            />
          </td>
          <td
            v-if="showExpand"
          >
            <v-icon
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded}"
              @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </td>
        </tr>
      </template>
      <template
        v-else-if="$scopedSlots['item'] === undefined"
        #item="{ item, expand, isExpanded }"
      >
        <tr
          :key="showExpand ? item[itemKey] : undefined"
          class="v-data-table__mobile-table-row"
          :class="rowClass(item, isExpanded)"
          @click="clickRow(item)"
        >
          <td
            v-for="header of headers.filter(h => h.value !== 'x_actions' && h.value !== 'data-table-expand')"
            :key="header.value"
            class="v-data-table__mobile-row"
          >
            <div class="v-data-table__mobile-row__header">
              {{ header.text }}
            </div>
            <div class="v-data-table__mobile-row__cell">
              <slot
                v-if="$scopedSlots['item.' + header.value] !== undefined"
                :name="'item.' + header.value"
                :item="item"
              />
              <template v-else>
                {{ header.itemLabel(item) }}
              </template>
            </div>
          </td>
          <td
            v-if="headers.find(h => h.value === 'x_actions') !== undefined"
            class="v-data-table__mobile-row"
          >
            <div class="v-data-table__mobile-row__header">
              {{ headers.find(h => h.value === 'x_actions').text }}
            </div>
            <div
              class="v-data-table__mobile-row__cell"
            >
              <TableActionButtons
                :actions="actionsWithLoading"
                :item="item"
              />
            </div>
          </td>
          <td
            v-if="showExpand"
            class="v-data-table__mobile-row"
          >
            <div class="v-data-table__mobile-row__header" />
            <div class="v-data-table__mobile-row__cell">
              <v-icon
                class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded}"
                @click.stop="expand(!isExpanded)"
              >
                $expand
              </v-icon>
            </div>
          </td>
        </tr>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        v-else
        #[name]="slotProps"
      >
        <slot
          :name="name"
          v-bind="slotProps"
        />
      </template>
      <template
        v-if="$scopedSlots['item'] === undefined && $vuetify.breakpoint.xsOnly"
        #item.x_actions="{ item }"
      >
        <TableActionButtons
          :actions="actionsWithLoading"
          :item="item"
        />
      </template>

      <template #header>
        <tr
          v-show="showFilters"
        >
          <td v-if="showSelectLocal" />
          <td
            v-for="header of headers"
            :key="header.value"
            class="px-3"
          >
            <slot
              v-if="$scopedSlots['filter-' + header.value] !== undefined"
              :filters="filters"
              :header="header"
              :name="'filter-' + header.value"
            />
            <template
              v-else-if="header.filterType !== undefined"
            >
              <v-text-field
                v-if="header.filterType === tableFilter.TEXT"
                v-model="filters[header.value]"
                :label="$t('base.filterBy') + ' ' + header.text"
                single-line
                clearable
              />
              <v-text-field
                v-if="header.filterType === tableFilter.NUMBER"
                v-model="filters[header.value]"
                :label="$t('base.filterBy') + ' ' + header.text"
                single-line
                type="number"
                clearable
              />
              <x-checkbox-tristate
                v-if="[tableFilter.BOOLEAN, tableFilter.IS_NULL, tableFilter.IS_NOT_NULL, tableFilter.ARRAY_EMPTY, tableFilter.ARRAY_NOT_EMPTY]
                  .includes(header.filterType)"
                v-model="filters[header.value]"
              />
              <template
                v-if="[tableFilter.SELECT, tableFilter.SELECT_MULTIPLE, tableFilter.ARRAY_CONTAINS, tableFilter.ARRAY_CONTAINS_MULTIPLE]
                  .includes(header.filterType)"
              >
                <NestedTableFilter
                  v-if="header.nested"
                  :filters="filters"
                  :header="header"
                  @main-item-chosen="val => filters[header.value] = val"
                  @nested-item-chosen="val => $set(filters, header.nested.key, val)"
                  @nested-item-cleared="filters[header.nested.key] = null"
                />
                <x-autocomplete
                  v-else-if="header.xAutocomplete"
                  v-model="inputFilters[header.value]"
                  :api-data-source="header.xAutocomplete.apiDataSource"
                  :api-filter="header.xAutocomplete.apiFilter"
                  :api-sort="header.xAutocomplete.apiSort"
                  :api-primary-key-name="header.xAutocomplete.apiPrimaryKeyName"
                  clearable
                  :disable-autoselect-first="!!header.xAutocomplete.disableAutoselectFirst"
                  :label="$t('base.filterBy') + ' ' + header.text"
                  :multiple="header.filterType === tableFilter.SELECT_MULTIPLE || header.filterType === tableFilter.ARRAY_CONTAINS_MULTIPLE"
                  :chips="!!header.xAutocomplete.chips"
                  :no-data-text="header.noDataText"
                  :result-transform="header.xAutocomplete.resultTransform"
                />
                <component
                  :is="header.filterAllowCustom ? 'v-combobox' : 'v-autocomplete'"
                  v-else
                  v-model="filters[header.value]"
                  clearable
                  :items="header.filterItems"
                  :label="$t('base.filterBy') + ' ' + header.text"
                  :loading="header.loading"
                  :multiple="header.filterType === tableFilter.SELECT_MULTIPLE || header.filterType === tableFilter.ARRAY_CONTAINS_MULTIPLE"
                  :no-data-text="header.noDataText"
                />
              </template>
              <template
                v-if="header.filterType === tableFilter.DATETIME && isApiResource"
              >
                <v-btn
                  outlined
                  :color="filters[header.value] === null ? '' : 'secondary'"
                  @click="openDateFilterDialog(header.value, header.text)"
                >
                  <v-icon dense>
                    filter_alt
                  </v-icon>
                  <v-icon dense>
                    $openItem
                  </v-icon>
                </v-btn>
              </template>
            </template>
            <!-- TODO implement tableFilter.DATETIME type for non-api-resource -->
            <!-- TODO implement tableFilter.MULTI_BOOLEAN type -->
          </td>
        </tr>
      </template>
      <template
        v-if="$scopedSlots['expanded-item'] !== undefined"
        #expanded-item="props"
      >
        <tr class="v-data-table__expanded v-data-table__expanded__content">
          <td
            :colspan="props.headers.length"
            style="padding: 0px; vertical-align: top"
          >
            <slot
              name="expanded-item"
              :headers="props.headers"
              :item="props.item"
            />
          </td>
        </tr>
      </template>
      <template #footer>
        <div class="d-flex flex-wrap">
          <TableBatchActions
            :api-items-length="serverItemsLength"
            :batch-actions="batchActions"
            :batch-action-progress="batchActionProgress"
            :batch-action-total="batchActionTotal"
            :filtered-items="filteredItems"
            :fulltext-active="fulltextActive"
            :get-all-pages-request="isApiResource ? getAllPagesRequest : null"
            :selection="selection"
            @clear-selection="selection = []"
          />
          <slot name="footer" />
        </div>
      </template>
    </v-data-table>
    <TableContextMenu
      :actions="actionsWithLoading"
      :settings="conMenuSettings"
      :item="conMenuActiveItem"
    />
    <v-dialog
      v-model="dateFilterDialog"
      width="400"
    >
      <TableDateFilter
        :key="dateFilterValue"
        :value="filters[dateFilterValue]"
        :title="dateFilterTitle"
        :attribute="dateFilterValue"
        @close="dateFilterDialog = false"
        @input="value => $set(filters, dateFilterValue, value)"
      />
    </v-dialog>
  </div>
</template>

<script>
    import {VAutocomplete, VCombobox} from "vuetify/lib";
    import TableActionButtons from "@/app/components/table/TableActionButtons.component";
    import TableBatchActions from "@/app/components/table/TableBatchActions.component";
    import TableContextMenu from "@/app/components/table/TableContextMenu.component";
    import TableDateFilter from "@/app/components/table/TableDateFilter.component";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import * as Export from "@/service/Export";
    import {TableFilter, TableFilterApiOperator} from "@/enum/table_filter";
    import {has} from "@/utils/object";
    import {debounce} from "lodash";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {resolveValueFormat} from "@/utils/url";
    import NestedTableFilter from "@/app/components/table/NestedTableFilter.component.vue";

    export default {
        // eslint-disable-next-line
        name: "x-data-table",
        components: {
            NestedTableFilter,
            TableBatchActions, TableDateFilter, TableContextMenu, TableActionButtons,
            // dynamic components have to be manually imported
            VAutocomplete, VCombobox
        },
        mixins: [EventsListenerMixin],
        props: {
            actions: {
                type: Array,
                default: () => []
            },
            batchActions: {
                type: Array,
                default: () => []
            },
            batchActionProgress: {
                type: Number,
                default: null
            },
            batchActionTotal: {
                type: Number,
                default: null
            },
            headers: {
                type: Array,
                default: () => []
            },
            // filters which do not have their columns but can be defined using query param
            hiddenQueryFilters: {
                type: Array,
                default: () => []
            },
            items: {
                type: Array,
                default: () => []
            },
            loading: {
                type: Boolean,
                default: false
            },
            itemClass: {
                type: Function,
                default: () => ''
            },
            apiDataSource: {
                type: Function,
                default: null
            },
            apiDataSourceAllPages: {
                type: Function,
                default: null
            },
            reload: {
                type: Number,
                default: 0
            },
            showSearchBar: {
                type: Boolean,
                default: true
            },
            customFilters: {
                type: Boolean,
                default: false
            },
            searchHint: {
                type: String,
                default: null
            },
            apiFilter: {
                type: Array,
                default: () => []
            },
            showSelect: {
                type: Boolean,
                default: false
            },
            itemSelectColor: {
                type: Function,
                default: () => 'primary'
            },
            itemKey: {
                type: String,
                default: 'id'
            },
            showExpand: {
                type: Boolean,
                default: false
            },
            expanded: {
                type: Array,
                default: () => []
            },
            additionalItemsCount: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            search: '',
            options: {
                page: 1,
                itemsPerPage: 10,
                descending: false
            },
            optionsChangedWhileFetching: false,
            conMenuSettings: {
                menuShow: false,
                menuX: 0,
                menuY: 0
            },
            conMenuActiveItem: {},
            showFilters: false,
            filters: {},
            fulltextActive: false,
            tableFilter: TableFilter,
            currentItems: [],
            serverItemsLength: -1,
            selection: [],
            dateFilterDialog: false,
            dateFilterValue: null,
            dateFilterTitle: '',
            lastRequestParams: null,

            curRouteParams: [],
            lastRouteParams: [],

            loaded: false
        }),
        computed: {
            events: function () {
                return {
                    'set-table-filter': this.setFilter,
                    'show-filters': this.changeShowFilters
                };
            },
            isApiResource: function () {
                return this.apiDataSource !== null;
            },
            showSelectLocal: function () {
                return this.$vuetify.breakpoint.smAndUp && (this.showSelect || (this.batchActions && this.batchActions.length > 0));
            },
            exportFormats: function () {
                return {
                    csv: Export.csv,
                    xlsx: Export.xlsx
                };
            },
            inputFilters: function() {
                return this.loaded ? this.filters : {} ;
            },
            expandedLocal: {
                get: function () {
                    return this.expanded;
                },
                set: function (newValue) {
                    this.$emit('update:expanded', newValue);
                }
            },
            fullTextSearchHint: function () {
                const hints = Object.values(this.headers)
                    .filter(item => item.showInHint)
                    .map(item => item.text);
                if (hints.length === 0) {
                    return '';
                } else if (hints.length === 1) {
                    return this.$t('base.searchHintSingle', [hints]);
                } else {
                    return this.$t('base.searchHint', [hints.slice(0, hints.length - 1).join(', '), hints.pop()]);
                }
            },
            headerFilterTypes: function () {
                const hft = {};
                this.headers.forEach(item => {
                    hft[item.value] = item.filterType;
                });
                return hft;
            },
            hasAdvancedFilters: function () {
                return this.customFilters || this.headers.filter(header => header.filterType !== undefined).length > 0;
            },
            itemsPerPageOptions: function () {
                return [
                    ...[5, 10, 20, 50, 100],
                    ...this.isApiResource ? [] : [-1]
                ];
            },
            filteredItems: function () {
                if (this.showFilters && !this.isApiResource) {
                    // TODO maybe wrap this in debounce https://lodash.com/docs/4.17.11#debounce
                    return this.items.filter(item => {
                        for (const attr in this.filters) {
                            if (has(this.filters, attr)) {
                                let nestedItem = item;
                                const deserialized = attr.split('.');
                                while (deserialized.length > 1) {
                                    const nestedAttr = deserialized.shift();
                                    if (has(nestedItem, nestedAttr)) {
                                        nestedItem = nestedItem[nestedAttr];
                                    }
                                }
                                const singleAttr = deserialized[0];
                                if (this.headerFilterTypes[attr] === TableFilter.TEXT) {
                                    if (this.filters[attr] !== null && this.filters[attr] !== ''
                                        && (nestedItem === null || nestedItem[singleAttr] === undefined || nestedItem[singleAttr] === null
                                            || (nestedItem[singleAttr] !== null && nestedItem[singleAttr].toLowerCase().indexOf(this.filters[attr].toLowerCase()) === -1))) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[attr] === TableFilter.NUMBER) {
                                    if (this.filters[attr] !== null && this.filters[attr] !== ''
                                        && (nestedItem === null || nestedItem[singleAttr] === undefined || nestedItem[singleAttr] === null
                                            || nestedItem[singleAttr] !== Number(this.filters[singleAttr]))) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[attr] === TableFilter.BOOLEAN) {
                                    if (this.filters[attr] !== null && this.filters[attr] !== nestedItem[singleAttr]) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[singleAttr] === TableFilter.DATETIME) {
                                    return true; // TODO
                                } else if (this.headerFilterTypes[attr] === TableFilter.SELECT) {
                                    if (this.filters[attr] !== null
                                        && (nestedItem === null
                                            || this.filters[attr] !== nestedItem[singleAttr])) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[attr] === TableFilter.SELECT_MULTIPLE) {
                                    if (this.filters[attr] !== null
                                        && this.filters[attr].length !== 0
                                        && (nestedItem === null
                                            || !this.filters[attr].includes(nestedItem[singleAttr]))) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[attr] === TableFilter.ARRAY_CONTAINS) {
                                    if (this.filters[attr] !== null
                                        && (nestedItem === null
                                            || !nestedItem[singleAttr].includes(this.filters[attr]))) {
                                        return false;
                                    }
                                } else if (this.headerFilterTypes[attr] === TableFilter.ARRAY_CONTAINS_MULTIPLE) {
                                    if (this.filters[attr] !== null) {
                                        if (nestedItem === null) {
                                            return false;
                                        }
                                        this.filters[attr].forEach(val => {
                                            if (!nestedItem[singleAttr].includes(val)) {
                                                return false;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                        return true;
                    });
                } else {
                    return this.items;
                }
            },
            actionsWithLoading: function () {
                if (this.isApiResource) {
                    return this.actions.map(action => {
                        action.loading = this.loading;
                        return action;
                    });
                } else return this.actions;
            },
            defaultActions: function () {
                return this.actions.filter(action => !action.notDefaultAction);
            },
            debouncedFetch: function () {
                return debounce(this.fetchDataSource, 500);
            }
        },
        watch: {
            options: {
                deep: true,
                handler: function () {
                    if (!this.optionsChangedWhileFetching) {
                        this.fetchDataSource();
                    } else {
                        this.optionsChangedWhileFetching = false;
                    }
                }
            },
            filters: {
                deep: true,
                handler: function () {
                    let filterCorrection = false;
                    for (const filter of Object.keys(this.filters)) {
                        const filterValue = this.filters[filter];
                        const header = this.headers.find(header => header.value === filter);
                        if (header && header.filterAction) {
                            header.filterAction(filterValue);
                        }
                        if (Array.isArray(filterValue) && filterValue.length === 0) {
                            this.filters[filter] = null;
                            //will trigger this watcher again, we do not want to fetch now
                            filterCorrection = true;
                        }
                    }
                    this.showFilters && !filterCorrection && this.fetchDataSource();
                    this.$emit('update:filter', this.filters);
                }
            },
            reload: function () {
                this.fetchDataSource();
            },
            search: function (newValue, oldValue) {
                if (!!newValue !== !!oldValue) {
                    this.fulltextActive = !!newValue;
                    this.$emit('fulltext-active', !!newValue);
                }
                this.debouncedFetch();
            },
            selection: {
                deep: true,
                handler: function () {
                    if (this.showSelect) {
                        this.$emit('update:selection', this.selection);
                    }
                }
            },
            additionalItemsCount: function (newValue, oldValue) {
                this.serverItemsLength = this.serverItemsLength - oldValue + newValue;
            }
        },
        createdOrActivated(lifeCycleHook) {
            this.loaded = false;
            this.setFiltersFromRouteParams(lifeCycleHook === this.LifeCycleHook.CREATED);
            if (lifeCycleHook === this.LifeCycleHook.ACTIVATED) {
                this.fetchDataSource();
            }
        },
        mounted: function () {
            this.options.itemsPerPage = this.$vuetify.breakpoint.xs ? 5 :
                (this.$vuetify.breakpoint.xl ? 20 : 10);
        },
        methods: {
            setFilterFromRouteParam: function (filterKey, filterType, init = false) {
                if (this.$route.query[filterKey]) {
                    let value = resolveValueFormat(this.$route.query[filterKey]);
                    if ([TableFilter.SELECT_MULTIPLE, TableFilter.ARRAY_CONTAINS_MULTIPLE]
                        .includes(filterType))
                        value = [value];
                    this.$set(this.filters, filterKey, value);
                    this.curRouteParams.push(filterKey);
                } else if (init || this.lastRouteParams.includes(filterKey)) {
                    // init all filters to null
                    // or clear filter if query param was specified last time but not now
                    this.$set(this.filters, filterKey, filterType === TableFilter.TEXT ? '' : null);
                }
            },
            setFiltersFromRouteParams: function (init = false) {
                this.lastRouteParams = this.curRouteParams;
                this.curRouteParams = [];

                for (const {value, filterType, nested} of this.headers) {
                    this.setFilterFromRouteParam(value, filterType, init);
                    if (nested)
                        this.setFilterFromRouteParam(nested.key, nested.filterType, init);
                }
                for (const {value, filterType} of this.hiddenQueryFilters) {
                    this.setFilterFromRouteParam(value, filterType, init);
                }
                if (Object.values(this.filters).some(value => value)) {
                    this.showFilters = true;
                }
            },
            fetchDataSource: function () {
                if (this.isApiResource) {
                    this.$emit('update:loading', true);
                    const apiParams = {};
                    apiParams.page = this.options.page;
                    apiParams.itemsPerPage = this.options.itemsPerPage;
                    if (this.options.sortBy !== undefined && this.options.sortBy.length > 0) {
                        let sortByParam = this.options.sortBy[0];
                        sortByParam = this.headers.find(header => header.value === sortByParam).sortBy || sortByParam;
                        apiParams.sort = APIFilters.makeSort({[sortByParam]: (this.options.sortDesc[0] ? 'DESC' : 'ASC')});
                    }
                    const apiFilter = this.getApiFilters();
                    if (apiFilter) {
                        apiParams.filter = apiFilter;
                    }

                    this.lastRequestParams = apiParams;
                    this.apiDataSource(apiParams)
                        .then(response => {
                            if (this.lastRequestParams !== apiParams) {
                                // A newer request is already running, ignore this result
                                return;
                            }
                            if (this.options.page !== response.data.page) {
                                this.optionsChangedWhileFetching = true;
                                this.options.page = response.data.page;
                            }
                            this.serverItemsLength = response.data.item_count + this.additionalItemsCount;
                            this.$emit('update:items', response.data.items);
                        }).catch(err => {
                            if (this.$te(err)) {
                                this.snack(err);
                            } else {
                                this.snack('base.list.unableToLoad');
                            }
                        }).finally(() => {
                            this.$emit('update:loading', false);
                            this.loaded = true;
                        });
                }
            },
            toggleFilters: function () {
                this.showFilters = !this.showFilters;
                this.$emit('toggleFilters', this.showFilters);
            },
            changeShowFilters: function (show = true) {
                this.showFilters = show;
                this.$emit('toggleFilters', this.showFilters);
            },
            setFilter: function (attr, val) {
                if (!has(this.filters, attr)) {
                    return;
                }
                this.filters[attr] = val;
                if (val)
                    this.showFilters = true;
            },
            getApiFilter: function (value, filterType, filterBy) {
                const filterValue = this.filters[value];
                const filterKey = filterBy !== undefined ? filterBy : value;
                if ((filterType === TableFilter.SELECT_MULTIPLE || filterType === TableFilter.ARRAY_CONTAINS_MULTIPLE)
                    && filterValue && Array.isArray(filterValue) && filterValue.includes(null)) {
                    // null means all items, we do not want to filter
                    return null;
                }
                if (filterType === TableFilter.SELECT_MULTIPLE && filterValue && filterValue.length === 0) {
                    // TODO implement
                    return null;
                }
                if (filterType === TableFilter.ARRAY_CONTAINS_MULTIPLE && filterValue && filterValue.length) {
                    return {
                        [APIFilterOP.AND]: [
                            ...filterValue.map(val => (
                                {
                                    [TableFilterApiOperator[filterType]]: {
                                        [filterKey]: val.value || val
                                    }
                                }
                            ))
                        ]
                    };
                }
                if (filterType === TableFilter.DATETIME && filterValue) {
                    return filterValue;
                }
                if ([TableFilter.IS_NULL, TableFilter.IS_NOT_NULL, TableFilter.ARRAY_EMPTY, TableFilter.ARRAY_NOT_EMPTY]
                    .includes(filterType)) {
                    if (filterValue !== null) {
                        return {
                            [TableFilterApiOperator[filterType][filterValue]]: value
                        };
                    }
                }
                if (filterValue !== undefined && filterValue !== null && filterValue !== '') {
                    return {
                        [TableFilterApiOperator[filterType]]: {
                            [filterKey]: filterValue.value || filterValue
                        }
                    };
                }
                return null;
            },
            getApiFilters: function () {
                const ret = [];
                if (this.showFilters) {
                    for (const {value, filterType, filterBy, nested} of this.headers.filter(header => header.filterType !== undefined)) {
                        const headerFilter = this.getApiFilter(value, filterType, filterBy);
                        if (headerFilter !== null)
                            ret.push(headerFilter);
                        if (nested) {
                            const nestedFilter = this.getApiFilter(nested.key, nested.filterType, nested.filterBy);
                            if (nestedFilter !== null)
                                ret.push(nestedFilter);
                        }
                    }
                    for (const {value, filterType, filterBy} of this.hiddenQueryFilters) {
                        const queryFilter = this.getApiFilter(value, filterType, filterBy);
                        if (queryFilter !== null)
                            ret.push(queryFilter);
                    }
                }
                if (this.showSearchBar && this.search !== undefined && this.search !== null && this.search !== ''
                ) {
                    ret.push(
                        {
                            [APIFilterOP.FULL_TEXT]: `"${this.search}"`
                        }
                    );
                }
                if (this.apiFilter && this.apiFilter.length) {
                    ret.push(...this.apiFilter);
                }
                return ret.length > 0 ? APIFilters.makeFilter(ret) : undefined; // TODO refactor, debounce, allow OR
            },
            clickRow: function (item) {
                if (this.loading) {
                    return;
                }
                if (this.showExpand) {
                    const expandedIdx = this.expandedLocal.findIndex(itm => itm.protocol_id === item.protocol_id);
                    if (expandedIdx > -1) {
                        this.expandedLocal.splice(expandedIdx, 1);
                    } else {
                        this.expandedLocal.push(item);
                    }
                    return;
                }
                const itemActions = this.defaultActions.filter(ca => !ca.condition || ca.condition(item));
                if (itemActions.length) {
                    const action = itemActions[0];
                    if (action !== undefined) {
                        if (itemActions[0].routerLink) {
                            this.$router.push(itemActions[0].routerLink(item));
                        }
                        if (itemActions[0].action) {
                            itemActions[0].action(item);
                        }
                    }
                }
            },
            showContextMenu: function (item) {
                this.conMenuActiveItem = item;
                this.conMenuSettings.menuShow = false;
                this.conMenuSettings.menuX = event.clientX;
                this.conMenuSettings.menuY = event.clientY;
                this.$nextTick(() => {
                    this.conMenuSettings.menuShow = true;
                });
            },
            updateCurrentItems: function (current) {
                this.currentItems = current;
            },
            getAllPagesRequest: function () {
                const apiFilter = this.getApiFilters();
                const apiParams = apiFilter ? {filter: apiFilter} : {};
                return this.apiDataSourceAllPages(apiParams);
            },
            headerSlotName: function (header) {
                return 'header.' + header.value;
            },
            exportFormat: function (format) {
                if (this.isApiResource && this.apiDataSourceAllPages !== null) {
                    this.getAllPagesRequest()
                        .then(response => {
                            this.exportFormats[format](response.data.items);
                        });
                } else {
                    const bak = this.options.itemsPerPage;
                    this.options.itemsPerPage = -1;
                    this.$nextTick(() => {
                        this.exportFormats[format](this.currentItems);
                        this.options.itemsPerPage = bak;
                    });
                }
            },
            rowClass: function (item, isExpanded = false) {
                let cls = 'xdta-darkThemeLightFont';
                if (this.defaultActions.length > 0 || this.showExpand) {
                    cls += ' xdta-activeRow';
                }
                if (isExpanded) {
                    cls += ' v-data-table__expanded v-data-table__expanded__row';
                }
                return (cls + ' ' + this.itemClass(item));
            },
            toggleSelectAll: function ({items, value}) {
                if (value) {
                    const newSelection = items.map(item => item.id);
                    this.selection = [...new Set(this.selection.concat(newSelection))];
                } else {
                    const current = this.currentItems.map(item => item.id);
                    this.selection = this.selection.filter(selected => !current.includes(selected));
                }
            },
            openDateFilterDialog: function (value, title) {
                this.dateFilterValue = value;
                this.dateFilterTitle = title;
                this.dateFilterDialog = true;
            }
        }
    }
    ;
</script>

<style lang="sass">
// TODO should be scoped, but first row does not work with it
.xdta-activeRow
  cursor: pointer

.theme--dark .xdta-darkThemeLightFont,
.theme--dark .v-data-table__mobile-table-row,
.v-data-table.theme--dark tbody .container
  color: #FFF

.v-data-table-header-mobile th
  height: 8px !important
  border-bottom: unset !important

// horizontal scroll shadows
.v-data-table__wrapper
  background-position: left center, right center, left center, right center
  background-size: 2em 100%
  background-attachment: local, local, scroll, scroll

  .theme--light &
    background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 98, 88, .2), white), linear-gradient(to left, rgba(0, 98, 88, .2), white)

  .theme--dark &
    background-image: linear-gradient(to right, #1e1e1e, #1e1e1e), linear-gradient(to right, #1e1e1e, #1e1e1e), linear-gradient(to right, rgba(0, 98, 88, .8), #1e1e1e), linear-gradient(to left, rgba(0, 98, 88, .8), #1e1e1e)

</style>
