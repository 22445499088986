import {Roles} from "@/enum/roles";
import {taskNames, taskLinks, taskTypes} from "@/enum/task_type";
import Show from "@/app/tasks/preparePackage/PreparePackageShow.view";

const type = taskTypes.PREPARE_PACKAGE;
const BASE_URL = taskLinks[type];

const routes = {
    show: {
        path: BASE_URL + '/:taskId(\\d+)',
        component: Show,
        meta: {
            title: taskNames[type],
            requireRole: [Roles.CHIEF, Roles.PACKER],
            getParent: () => '/',
            trackTime: true
        }
    }
};

export default Object.values(routes);
