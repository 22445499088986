<template>
  <v-expansion-panel-content>
    <v-tabs
      v-model="viewOption"
      class="mt-2"
      mandatory
      dense
    >
      <v-tab
        v-for="[index, data] of Object.entries(MapViewOptions)"
        :key="index"
        style="max-width: 400px;"
        :value="data"
        :disabled="changes && data === MapViewOptions.USAGE_HEATMAP"
      >
        <v-icon
          left
        >
          {{ data.icon }}
        </v-icon>
        {{ $t(data.label) }}
      </v-tab>
    </v-tabs>
    <v-divider
      class="mb-4"
    />

    <!-- Map Editor -->

    <v-row
      v-if="Object.values(MapViewOptions)[viewOption] === MapViewOptions.MAP_EDITOR"
    >
      <v-col
        cols="8"
      >
        <v-card
          class="pa-4"
        >
          <MapEditorToolbar />

          <v-divider />

          <MapViewToolbar
            v-if="showMap"
            @zoomChange="assignZoom"
          />

          <RackViewToolbar
            v-if="showRackView && !showMap"
          />

          <v-divider
            class="mb-1"
          />

          <MapView
            v-if="showMap"
            :debounced-zoom="debouncedZoom"
          />
          <RackView
            v-if="showRackView && !showMap"
          />
        </v-card>
      </v-col>
      <v-col
        cols="4"
      >
        <SidePanels
          :mode="panelMode"
        />
      </v-col>
    </v-row>
    
    <!-- Heat map view -->
    
    <v-row
      v-if="Object.values(MapViewOptions)[viewOption] === MapViewOptions.USAGE_HEATMAP"
    >
      <v-col>
        <v-card
          class="pl-4 pt-4"
        >
          <MapView
            :debounced-zoom="debouncedZoom"
            :show-heat-map="true"
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- Locations mapping -->

    <LocationMappingPanel
      v-if="Object.values(MapViewOptions)[viewOption] === MapViewOptions.MAP_EDITOR"
      class="mt-5"
    />
  </v-expansion-panel-content>
</template>

<script>
    import MapView from '@/app/stocks/map/components/map-view/MapView.component.vue';
    import SidePanels from '@/app/stocks/map/components/panels/SidePanels.component.vue';
    import RackView from '@/app/stocks/map/components/map-view/RackView.component.vue';
    import {MapAPI} from "@/api/MapAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import MapViewToolbar from "@/app/stocks/map/components/toolbars/MapViewToolbar.component.vue";
    import RackViewToolbar from "@/app/stocks/map/components/toolbars/RackViewToolbar.component.vue";
    import {MapMixin} from "@/app/mixins/MapMixin";
    import LocationMappingPanel from "@/app/stocks/map/components/panels/LocationMappingPanel.component.vue";
    import {SnackbarMixin} from "@/app/mixins/SnackbarMixin";
    import {MapObjectType, MapViewOptions} from "@/enum/stock_map";
    import MapEditorToolbar from "@/app/stocks/map/components/toolbars/MapEditorToolbar.vue";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    export default {
        name: 'StockMapEdit',
        components: {
            MapEditorToolbar,
            LocationMappingPanel,
            RackViewToolbar,
            MapViewToolbar,
            MapView,
            SidePanels,
            RackView
        },
        mixins: [RouteParamsMapperMixin, MapMixin, SnackbarMixin, EventsListenerMixin],
        data: () => ({
            debouncedZoom: 32,
            viewOption: MapViewOptions.MAP_EDITOR,
            MapViewOptions: MapViewOptions,
        }),
        computed: {
            events: function () {
                return {
                    'delete-stock-location': this.loadMap,
                };
            },
            showRackView() {
                if (this.selectedObject == null) {
                    return false;
                }
                return this.selectedObject.type === MapObjectType.RACK;
            },
        },
        createdOrActivated: function() {
            this.setLoading(true);
            this.mapId = undefined;
            // Get id of map, that is in use in stock, or create new
            this.fetchUsedMapId().then(()=>{
                if (this.mapId === undefined) {
                    MapAPI.create(this.stockId, {
                        in_use: true,
                        height: 10,
                        width: 15
                    }).then(() => {
                        this.fetchUsedMapId().then( () => this.loadMap() );
                    }).catch(err => {
                        this.snack(err);
                    });
                }
                else {
                    this.loadMap();
                }
            }).catch(err => {
                this.snack(err);
            });

        },
        methods: {
            fetchUsedMapId: function () {
                return MapAPI.getAll(this.stockId).then(response => {
                    this.mapId = response.data.find(map => map.in_use)?.id;
                }).catch(err => {
                    this.snack(err);
                });
            },
            assignZoom(newVal){
                this.debouncedZoom = newVal;
            },
        }
    };
</script>

<style scoped>

</style>
