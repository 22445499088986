import {StockAPI} from "@/api/StockAPI";
import i18n from "@/service/lang/i18n";

export function substocksInStock(stockId, multiple = false, chips = false) {
    return {
        apiDataSource: StockAPI.getAllSubstocks.bind(StockAPI, stockId),
        resultTransform: el => {
            el.value = el.id;
            el.text = el.name;
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple,
        chips: chips,
        allItemLabel: i18n.t('stocks.stockStatus.allSubstocks')
    };
}