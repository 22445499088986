import {AresAPI} from "@/api/AresAPI";
import {populate} from "@/utils/object";

/**
 * Requires:
 * - this.form.ico
 * - this.form.billing_address
 * - this.allowAres
 * - this.loadingAres
 * Optionally:
 * - this.form.delivery_address
 */
const LoadAresMixin = {
    computed: {
        allowAres: function () {
            const ico = this.form.ico;
            return ico !== undefined && ico !== null && ico.length === 8;
        },
    },
    watch: {
        'form.ico': function (_, oldValue) {
            if (oldValue !== null && this.allowAres) {
                this.loadAres();
            }
        }
    },
    methods: {
        loadAres: function () {
            this.loadingAres = true;
            AresAPI.getSubjectInfo(this.form.ico)
                .then(response => {
                    populate(this.form, response.data);
                    populate(this.form.billing_address, response.data);
                    if (this.form.delivery_address !== undefined) {
                        populate(this.form.delivery_address, response.data);
                    }
                }).catch(this.snack) // TODO suggest to display / merge
                .finally(() => {
                    this.loadingAres = false;
                });
        }
    }
};

export {LoadAresMixin};
