import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";
import {APIFilterOP, APIFilters} from "../service/APIFilters";

export const UserPermissionsAPI = {

    API: BaseAPI,
    DOMAIN: 'permissions',
    SUBORDINATES: 'subordinates',
    USER: 'users',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, merge(...params));
    },

    getPermissionsByUser(userId) {
        const filter = {
            [APIFilterOP.EQUALS]: {
                user_id: userId
            }
        };
        return this.getAll({filter: APIFilters.makeFilter(filter)});
    },

    updateUserPermissions(userId, data) {
        return this.API.patch([this.DOMAIN, this.USER, userId], data);
    },

    getPermissionsBySubstock(substockId) {
        const filter = {
            [APIFilterOP.EQUALS]: {
                substock_id: substockId
            }
        };
        return this.getAll({filter: APIFilters.makeFilter(filter)});
    },

    updateSubordinatePermissions(substockId, data) {
        return this.API.patch([this.DOMAIN, this.SUBORDINATES, substockId], data);
    },

};
