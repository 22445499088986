export default {
    title: "Dodávatelia",
    link: "Dodávatelia",

    name: "Názov",
    ico: "IČO",
    dic: "DIČ",
    phone: "Telefón",
    email: "E-mail",
    website: "Web",
    billing_address: "Fakturačná adresa",

    list: {
        unableToLoad: "Zoznam dodávateľov nebolo možné načítať. Skúste to prosím opäť neskôr."
    },
    show: {
        title: "Dodávateľ",
    },
    create: {
        label: "Vytvoriť nového dodávateľa",
        title: "Vytvoriť dodávateľa",
        done: "Dodávateľ '{0}' vytvorený.",
        undo: {
            done: "Tvorba '{0}' úspešne zrušená!",
            failed: "Nepodarilo sa zrušiť tvorbu dodávateľa!"
        }
    },
    update: {
        titleSimple: "Upraviť dodávateľa",
        title: "Upraviť {0}",
        done: "Dodávateľ '{0}' upravený",
        undo: {
            done: "Úpravy '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť úpravu dodávateľa!"
        }
    },
    delete: {
        done: "Dodávateľ '{0}' zmazaný.",
        failed: "Dodávateľa '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
        undo: {
            done: "Zmazanie '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť zmazanie dodávateľa!"
        },
        status: {
            404: "Zadaný dodávateľ neexistuje!",
            409: "Dodávateľa nie je možné zmazať kvôli jeho vzťahom k ďalším objektom!"
        }
    },
    status: {
        404: "Zadaný dodávateľ neexistuje!",
        409: "Vyplnené IČO už má iný dodávateľ!"
    }
};
