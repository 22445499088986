<template>
  <div
    :class="getCellClasses(cell)"
    class="mapCellEdges"
    :style="'width:' + debouncedZoom * cell.width + '; height: ' + debouncedZoom * cell.height + '; font-size:' + (debouncedZoom / 3.6) + ';'"
  >
    <div
      v-if="cell.object === null"
      :style="'width: ' + 100/cell.width + '% ; height: ' + 100/cell.height + '% ;'"
      @click.left="mouseClickCellEvent(cell, cell.x, cell.y, true)"
    />
    <div
      v-else
    >
      <div
        v-if="cell.object.type === MapObjectType.RACK"
        class="rackName"
        :class="cell.object.vertical ? 'objectVertical' : 'objectHorizontal'"
      >
        <div>
          {{ getFirstRackColumnSymbol(cell.object) }}
        </div>
        <div>
          <b>
            {{ cell.name }}
          </b>
        </div>
        <div>
          {{ getLastRackColumnSymbol(cell.object) }}
        </div>
      </div>
      
      <v-icon
        v-if="cell.object.type === MapObjectType.OBSTACLE || cell.object.type === MapObjectType.LOCATIONS_GROUP"
        :size="(debouncedZoom / 2)"
        :style="'text-align: center; left: ' + (((debouncedZoom * cell.width)/2)-(debouncedZoom / 2/2)) + '; top: ' + (((debouncedZoom * cell.height)/2)-(debouncedZoom / 2/2)) + ';'"
      >
        {{ cell.object.type === MapObjectType.OBSTACLE ? '$obstacle' : '$locationGroup' }}
      </v-icon>

      <div
        v-for="widthCoordinate in cell.width"
        :key="widthCoordinate"
      >
        <div
          v-for="heightCoordinate in cell.height"
          :key="heightCoordinate"
        >
          <v-menu
            v-if="!showHeatMap"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <div
                class="objectOverlayCell"
                :class="getOverlayCellClasses(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1)"
                :style="
                  'width: ' + 100/cell.width + '% ; ' +
                    'height: ' + 100/cell.height + '% ;  ' +
                    'left: ' + (100/cell.width) * (widthCoordinate-1) + '% ; ' +
                    'top: ' + (100/cell.height) * (heightCoordinate-1) + '%;'"
                v-bind="attrs"
                v-on="on"
                @click.left="!isZonePresentOnThisPosition(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1, render.zones) ?
                  mouseClickCellEvent(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1)
                  : ''"
              />
            </template>
            <v-list
              v-if="isZonePresentOnThisPosition(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1, render.zones) && isSetPanelModeStandby"
            >
              <v-list-item
                @click="mouseClickCellEvent(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1, true)"
              >
                <v-icon
                  small
                  class="mr-2 ml-0"
                >
                  $zone
                </v-icon>
                <v-list-item-title>
                  {{ $t('map.zone') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="mouseClickCellEvent(cell, cell.x + widthCoordinate-1, cell.y + heightCoordinate-1)"
              >
                <v-icon
                  small
                  class="mr-2 ml-0"
                >
                  {{ cell.object.type === MapObjectType.RACK ? '$rack' : (cell.object.type === MapObjectType.LOCATIONS_GROUP ? '$locationGroup': '$obstacle' ) }}
                </v-icon>
                <v-list-item-title v-if="cell.object.type === MapObjectType.RACK">
                  {{ $t('map.rack') }}
                </v-list-item-title>
                <v-list-item-title v-if="cell.object.type === MapObjectType.LOCATIONS_GROUP">
                  {{ $t('map.locationGroups') }}
                </v-list-item-title>
                <v-list-item-title v-if="cell.object.type === MapObjectType.OBSTACLE">
                  {{ $t('map.obstacle') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div
            v-if="showHeatMap && (cell.object.type === MapObjectType.RACK || cell.object.type === MapObjectType.LOCATIONS_GROUP)"
            class="objectOverlayCell"
            :style="
              'width: ' + 100/cell.width + '% ; ' +
                'height: ' + 100/cell.height + '% ;  ' +
                'left: ' + (100/cell.width) * (widthCoordinate-1) + '% ; ' +
                'top: ' + (100/cell.height) * (heightCoordinate-1) + '%; ' +
                'background-color: '+ heatMapColor(getHeatMap(cell.y + heightCoordinate-1,cell.x + widthCoordinate-1)) + '; ' +
                'opacity: 0.5;'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {MapObjectType, PanelMode, RackNamingSymbols} from '@/enum/stock_map';
    import { getRackColumns, nextInSequence } from '@/service/Map.js';
    import { MapMixin } from "@/app/mixins/MapMixin";

    export default {
        name: 'MapCell',
        mixins: [ MapMixin ],
        props: {
            debouncedZoom: {
                type: Number,
                default: 0
            },
            showHeatMap: {
                type: Boolean,
                default: false
            },
            cell: {
                type: Object,
                required: true
            },
        },
        computed: {
            heatMap() {
                return this.$store.getters['storageMap/getHeatMap'];
            },
            isTimeToHighlightCells() {
                return [PanelMode.NEW_RACK, 
                        PanelMode.NEW_OBSTACLE,
                        PanelMode.NEW_ZONE,
                        PanelMode.NEW_LOCATIONS_GROUP,
                        PanelMode.MOVE,
                        PanelMode.COPY
                ].includes(this.panelMode);
            },
            isTimeToHighlightZoneCells() {
                return this.panelMode === PanelMode.NEW_RACK
                    || this.panelMode === PanelMode.NEW_OBSTACLE
                    || this.panelMode === PanelMode.NEW_LOCATIONS_GROUP
                    || this.panelMode === PanelMode.MOVE && this.selectedObject.type !== MapObjectType.ZONE
                    || this.panelMode === PanelMode.COPY && this.selectedObject.type !== MapObjectType.ZONE;
            },
            isTimeToHighlightOverlayCells() {
                return  this.panelMode === PanelMode.NEW_ZONE
                    || this.panelMode === PanelMode.MOVE && this.selectedObject.type === MapObjectType.ZONE;
            },
            isSetPanelModeStandby() {
                return this.panelMode === PanelMode.STANDBY;
            },
        },
        methods: {
            getCellClasses(cell) {
                let cellClasses = "";

                const zone = this.getZoneOnThisPosition(cell, cell.x, cell.y, this.render.zones);

                if (cell.object === null) {
                    if (zone !== null) {
                        cellClasses += this.showHeatMap ? "aisle " : "zone ";
                        if (!this.showHeatMap && zone === this.selectedObject) {
                            cellClasses += "selectedZone ";
                        }
                        if (this.isTimeToHighlightZoneCells) {
                            cellClasses += "zoneHighlight ";
                        }
                    }
                    else if (this.isTimeToHighlightCells) {
                        cellClasses += "aisleHighlight ";
                    }
                    else {
                        cellClasses += "aisle ";
                    }
                }
                else {
                    if (!this.showHeatMap && cell.object === this.selectedObject) {
                        cellClasses += "selectedObject ";
                    }
                    else {
                        switch (cell.object.type) {
                        case MapObjectType.RACK:
                            cellClasses += !this.showHeatMap ? "rack " : "heatMapObjectWithLocation ";
                            break;
                        case MapObjectType.OBSTACLE:
                            cellClasses += "obstacle ";
                            break;
                        case MapObjectType.LOCATIONS_GROUP:
                            cellClasses += !this.showHeatMap ? "locationsGroup " : "heatMapObjectWithLocation ";
                        }
                    }
                }

                return cellClasses;
            },
            getOverlayCellClasses: function (cell, x, y) {
                let returnClass = '';

                const zone = this.getZoneOnThisPosition(cell, x, y, this.render.zones);
                if (zone !== null) {
                    if (this.isTimeToHighlightZoneCells && this.isTimeToHighlightOverlayCells){
                        returnClass += "overlayCellHighlight ";
                    }
                    returnClass += "zoneOverObjectCell ";
                    if (zone === this.selectedObject) {
                        returnClass += "selectedZone ";
                    } 
                }
                else if (this.isTimeToHighlightOverlayCells) {
                    returnClass += "overlayCellHighlight ";
                }
                return returnClass;
            },
            getHeatMap: function(y,x) {
                return this.$store.state.storageMap.heatMap!==null ? this.heatMap[y][x] : 0;
            },

            heatMapColor: function(value) {
                const h = (1.0 - value) * 240;
                return "hsl(" + h + ", 100%, 50%)";
            },
            mouseClickCellEvent: function (cell, x, y, zoneClick = false) {
                this.$emit("cellClick", {
                    cell: cell,
                    coordinates: {x: x, y: y},
                    zoneClick: zoneClick,
                });
            },
            getFirstRackColumnSymbol: function (rack) {
                let colSym = rack.naming.symbols.find(symbol => symbol.namingSymbol === RackNamingSymbols.COLUMN)?.startingSymbol;
                if (rack.naming.numberColsFromRight) {
                    return nextInSequence(colSym, getRackColumns(rack) - 1, colSym);
                }
                else {
                    return colSym;
                }
            },
            getLastRackColumnSymbol: function (rack) {
                const colSym = rack.naming.symbols.find(symbol => symbol.namingSymbol === RackNamingSymbols.COLUMN)?.startingSymbol;
                if (rack.naming.numberColsFromRight) {
                    return colSym;
                } 
                else {
                    return nextInSequence(colSym, getRackColumns(rack) - 1, colSym);
                }
            },
        }
    };
</script>

<style scoped lang="sass">

.mapCellEdges
  border: 1px solid var(--v-mapCellBorder-base)

.aisle
  background-color: var(--v-mapAisle-base)

.aisleHighlight
  background-color: var(--v-mapAisle-base)
  transition: background-color 0s

.aisleHighlight:hover
  background-color: var(--v-mapCellBorder-base)

// Map objects styles
.rack
  background-color: var(--v-mapRack-base)
  color: var(--v-mapFont-base)
  position: relative

.locationsGroup
  background-color: var(--v-mapLocationsGroup-base)
  color: var(--v-mapFont-base)
  position: relative

.obstacle
  background-color: var(--v-mapObstacle-base)
  color: var(--v-mapFont-base)
  position: relative

.selectedObject
  background-color: var(--v-mapSelected-base)
  position: relative

.overlayCellHighlight:hover
  background-color: var(--v-mapCellBorder-base)
  opacity: 0.4

.rackName
  position: absolute
  margin: auto
  left: 5%
  top: 5%
  width: 90%
  height: 90%
  display: flex !important
  justify-content: space-between !important
  align-items: center !important

.objectHorizontal
  flex-direction: row !important

.objectVertical
  margin-top: auto !important
  flex-direction: column !important

.objectOverlayCell
  position: absolute
  top: 0
  left: 0

// Map zone styles
.zone
  background-color: var(--v-mapZone-base)
  cursor: pointer

.zoneOverObjectCell
  background-color: var(--v-mapZone-base)
  opacity: 0.5

.selectedZone
  background-color: var(--v-mapSelected-base)

.zoneHighlight:hover
  background-color: var(--v-mapZoneHover-base)

// Heat map styles
.heatMapObjectWithLocation
  background-color: var(--v-heatMapWhiteObject-base)
  position: relative

</style>
