import {EventBus} from "@/service/EventBus";
import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
import {taskTypes} from "@/enum/task_type";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
import {has} from "@/utils/object";
import {TaskMovementType} from "@/enum/task_movement_type";
import {TaskItemsCardType} from "@/enum/task_items_card_type";

/**
 * Requires:
 * - this.items
 * - this.API
 * - this.activeLocationId
 * - this.conflict
 * - this.taskInfo.tab
 * - this.taskInfo.taskId
 * - this.taskInfo.details.type
 * - this.itemsInInventory when this.taskInfo.details.type is taskTypes.STOCK_LOADING
 * - this.taskInfo.details.strict_mode when this.taskInfo.details.type is taskTypes.STOCK_LOADING
 * - this.taskInfo.details.transfer_mode when this.taskInfo.details.type is taskTypes.MOVE_PRODUCTS
 */
const UpdateQuantityMixin = {
    mixins: [TaskTypeMixin],
    data: () => ({
        itemMovements: {},
        timeout: null,
        wait: 10000
    }),
    deactivated: function () {
        this.sendUpdatedQuantity();
    },
    destroyed: function () {
        this.sendUpdatedQuantity();
    },
    watch: {
        'taskInfo.tab': function () {
            // have to flush when user changes tab so that he can finish this task
            this.sendUpdatedQuantity();
        }
    },
    methods: {
        updateQuantity: function (payload) {
            let flush = false;
            const itemId = payload.itemId;
            const item = this.items.find(item => item.id === itemId);
            if (item) {
                let destLoc = undefined;
                if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                    destLoc = item.destination_locations.find(dstLoc => dstLoc.stock_location_id === payload.locationId);
                } else if (this.isType(taskTypes.STOCK_LOADING)) {
                    destLoc = item.destination_locations.find(dstLoc => dstLoc.location_id === payload.locationId);
                } else {
                    destLoc = item.destination_location?.stock_location_id === payload.locationId ? item.destination_location : undefined;
                }
                if (destLoc) {
                    // change of quantity on destination location
                    const quantityOnLocation = this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                        ? item.processed_quantity
                        : this.isType(taskTypes.STOCK_LOADING)
                            ? destLoc.quantity
                            : destLoc.store_quantity;
                    const toBeMoved = payload.quantity - quantityOnLocation;
                    const inventoryEmpty = this.itemsInInventory.length === 0;
                    if (this.isType(taskTypes.STOCK_LOADING) && inventoryEmpty) {
                        // inventory in stock loading is empty, move items directly from source
                        const updatedProcessedQuantity = item.processed_quantity + toBeMoved;
                        if (item.quantity_to_move !== 0 && this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE
                            && updatedProcessedQuantity > item.quantity_to_move - item.quantity_in_user_inventory) {
                            this.advancedSnack({
                                text: 'tasks.stockLoading.loadedLimit',
                                params: [updatedProcessedQuantity + item.quantity_in_user_inventory, item.quantity_to_move]
                            });
                            EventBus.$emit('bad-quantity-entered', itemId);
                            return;
                        }
                    }
                    if (toBeMoved > 0) {
                        if ((!this.isType(taskTypes.STOCK_LOADING) || !inventoryEmpty) && item.quantity_in_user_inventory < toBeMoved) {
                            // not enough items in inventory
                            this.snack(
                                this.isType(taskTypes.STOCK_LOADING) && !item.quantity_in_user_inventory
                                    ? 'tasks.stockLoading.onlyItemsFromInventory'
                                    : 'tasks.itemsCard.updateQuantity.notEnoughItemsInInventory'
                            );
                            EventBus.$emit('bad-quantity-entered', itemId);
                            return;
                        }
                    }
                    if (!this.isType(taskTypes.STOCK_LOADING) || toBeMoved < 0 || (toBeMoved > 0 && item.quantity_in_user_inventory > 0)) {
                        // do not add stock loading item to inventory if user wants to move it to source
                        if (!this.isType(taskTypes.STOCK_LOADING) || toBeMoved > 0 || payload.returnCard === TaskItemsCardType.IN_INVENTORY) {
                            item.quantity_in_user_inventory -= toBeMoved;
                        }
                    }
                    if (this.isType(taskTypes.SUBSTOCK_TRANSFER)) {
                        item.quantity_in_destination_user_inventory -= toBeMoved;
                    }
                    item.processed_quantity = item.processed_quantity + toBeMoved;
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                        destLoc.store_quantity = payload.quantity;
                    } else {
                        destLoc.quantity = payload.quantity;
                    }
                    if (toBeMoved > 0) {
                        if (this.isType(taskTypes.STOCK_LOADING) && inventoryEmpty) {
                            this.addItemMovement(item, payload.locationId, quantityOnLocation, toBeMoved, TaskMovementType.FROM_SOURCE_TO_DESTINATION);
                        } else {
                            this.addItemMovement(item, payload.locationId, quantityOnLocation, toBeMoved, TaskMovementType.FROM_INVENTORY_TO_DESTINATION);
                        }
                    } else if (toBeMoved < 0) {
                        if (this.isType(taskTypes.STOCK_LOADING) && payload.returnCard === TaskItemsCardType.TO_MOVE) {
                            this.addItemMovement(item, payload.locationId, quantityOnLocation, -toBeMoved, TaskMovementType.FROM_DESTINATION_TO_SOURCE);
                        } else {
                            this.addItemMovement(item, payload.locationId, quantityOnLocation, -toBeMoved, TaskMovementType.FROM_DESTINATION_TO_INVENTORY);
                        }
                    }
                } else {
                    // change of quantity in inventory
                    if (((this.isType(taskTypes.SUBSTOCK_TRANSFER)
                            || (this.isType(taskTypes.MOVE_PRODUCTS)
                                && this.taskInfo.details.transfer_mode !== TaskItemsStrictMode.FREE))
                            && payload.quantity > item.quantity_to_move - item.processed_quantity)
                        || (this.isType(taskTypes.STOCK_LOADING) && item.quantity_to_move !== 0
                            && this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE
                            && payload.quantity > item.quantity_to_move - item.processed_quantity)
                        || (!this.isAnyOfTypes(([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS]))
                            && ((
                                this.taskInfo.details.shipping_type === TaskShippingType.COURIER
                                && payload.quantity > item.quantity_to_move - item.processed_quantity
                            ) || payload.quantity > item.quantity_to_move))) {
                        // more items would be moved
                        this.advancedSnack({
                            text: this.isType(taskTypes.STOCK_LOADING) ? 'tasks.stockLoading.loadedLimit' : 'tasks.itemsCard.updateQuantity.destinationLimit',
                            params: [item.processed_quantity + payload.quantity, item.quantity_to_move]
                        });
                        EventBus.$emit('bad-quantity-entered', itemId);
                        return;
                    }
                    const toBeMoved = payload.quantity - item.quantity_in_user_inventory;
                    if (this.isType(taskTypes.STOCK_LOADING)) {
                        const curQuantityOnSource = item.quantity_to_move - item.processed_quantity - item.quantity_in_user_inventory;
                        item.quantity_in_user_inventory = payload.quantity;
                        if (toBeMoved > 0) {
                            this.addItemMovement(item, payload.locationId, curQuantityOnSource, toBeMoved, TaskMovementType.FROM_SOURCE_TO_INVENTORY);
                        } else {
                            this.addItemMovement(item, payload.locationId, curQuantityOnSource, -toBeMoved, TaskMovementType.FROM_INVENTORY_TO_SOURCE);
                        }
                    } else {
                        if (!this.activeLocationId) {
                            // no location chosen, should not happen
                            EventBus.$emit('bad-quantity-entered', itemId);
                            return;
                        }
                        const itemLocation = item.locations.find(srcLoc => srcLoc.stock_location.id === this.activeLocationId);
                        const sourceLocIdx = item.source_locations.findIndex(srcLoc => srcLoc.stock_location_id === this.activeLocationId);
                        const sourceLoc = item.source_locations[sourceLocIdx];
                        if (toBeMoved > 0) {
                            if (!itemLocation) {
                                EventBus.$emit('bad-quantity-entered', itemId);
                                return;
                            }
                            if (toBeMoved > itemLocation.quantity) {
                                // not enough items on source location
                                this.snack('tasks.itemsCard.updateQuantity.notEnoughItemsOnSource');
                                EventBus.$emit('bad-quantity-entered', itemId);
                                return;
                            }
                            let curQuantityOnSource = 0;
                            if (!sourceLoc) {
                                item.source_locations.push({
                                    pick_quantity: toBeMoved,
                                    stock_location_id: this.activeLocationId
                                });
                            } else {
                                curQuantityOnSource = sourceLoc.pick_quantity;
                                sourceLoc.pick_quantity = sourceLoc.pick_quantity + toBeMoved;
                            }
                            itemLocation.quantity -= toBeMoved;
                            if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                                && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION) {
                                item.processed_quantity = item.processed_quantity + toBeMoved;
                            }
                            item.quantity_in_user_inventory = payload.quantity;
                            this.addItemMovement(item, this.activeLocationId, curQuantityOnSource, toBeMoved, TaskMovementType.FROM_SOURCE_TO_INVENTORY);
                        } else if (toBeMoved < 0) {
                            if (!sourceLoc) {
                                // item was not picked from this location
                                this.snack('tasks.itemsCard.updateQuantity.itemNotPickedFromThisLocation');
                                EventBus.$emit('bad-quantity-entered', itemId);
                                return;
                            }
                            if (-toBeMoved > sourceLoc.pick_quantity) {
                                // not so many items picked from source
                                this.snack('tasks.itemsCard.updateQuantity.notSoManyItemsPickedFromSource');
                                EventBus.$emit('bad-quantity-entered', itemId);
                                return;
                            }
                            if (!itemLocation) {
                                flush = true;
                            } else {
                                itemLocation.quantity = itemLocation.quantity + -toBeMoved;
                            }
                            const curQuantityOnSource = sourceLoc.pick_quantity;
                            sourceLoc.pick_quantity += toBeMoved;
                            if (sourceLoc.pick_quantity === 0) {
                                item.source_locations.splice(sourceLocIdx, 1);
                            }
                            if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                                && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION) {
                                item.processed_quantity = item.processed_quantity + toBeMoved;
                            }
                            item.quantity_in_user_inventory = payload.quantity;
                            this.addItemMovement(item, this.activeLocationId, curQuantityOnSource, -toBeMoved, TaskMovementType.FROM_INVENTORY_TO_SOURCE);
                        }
                        if (this.isType(taskTypes.SUBSTOCK_TRANSFER)) {
                            item.quantity_in_source_user_inventory = payload.quantity - item.quantity_in_destination_user_inventory;
                        }
                    }
                }
                let onlyInstanceId = item.product_instance_id;
                if (flush) {
                    this.sendUpdatedQuantity(false).catch(() => onlyInstanceId = null)
                        .finally(() => this.fetchItems({debounceQuantities: true, onlyInstanceId: onlyInstanceId}));
                }
            }
        },
        addItemMovement: function (item, locationId, curQuantity, toBeMoved, movementType) {
            const itemId = item.id;
            if (!has(this.itemMovements, itemId)) {
                this.itemMovements[itemId] = {
                    sources: {},
                    inventory: {lastSyncQuantity: null, movedQuantity: 0},
                    destinations: {}
                };
            }
            const curItemMovements = this.itemMovements[itemId];
            switch (movementType) {
                case TaskMovementType.FROM_INVENTORY_TO_SOURCE:
                case TaskMovementType.FROM_SOURCE_TO_INVENTORY: {
                    let sign = movementType === TaskMovementType.FROM_INVENTORY_TO_SOURCE ? +1 : -1;
                    let sources = curItemMovements.sources;
                    if (!has(sources, locationId)) {
                        sources[locationId] = {lastSyncQuantity: curQuantity, movedQuantity: 0};
                    }
                    let newQuantity = sources[locationId].movedQuantity + (sign * toBeMoved);
                    if (this.isType(taskTypes.STOCK_LOADING)) {
                        // in FREE stock loading we can add items to task, we need to check whether we are really moving items or just adding them
                        const movedQuantity = item.processed_quantity + item.quantity_in_user_inventory;
                        if (movementType === TaskMovementType.FROM_SOURCE_TO_INVENTORY) {
                            let leftToMove = item.quantity_to_move - (movedQuantity - toBeMoved);
                            if (leftToMove < 0) {
                                leftToMove = 0;
                            }
                            if (toBeMoved > leftToMove) {
                                newQuantity = sources[locationId].movedQuantity + (sign * leftToMove);
                            }
                        } else {
                            let added = movedQuantity + toBeMoved - item.quantity_to_move;
                            if (added > 0) {
                                if (added - toBeMoved > 0) {
                                    newQuantity = sources[locationId].movedQuantity;
                                } else {
                                    newQuantity = sources[locationId].movedQuantity + (sign * -1 * (added - toBeMoved));
                                }
                            }
                        }
                    }
                    sources[locationId].movedQuantity = newQuantity;

                    const inventory = curItemMovements.inventory;
                    inventory.movedQuantity = inventory.movedQuantity + (-1 * sign * toBeMoved);
                    if (inventory.lastSyncQuantity === null) {
                        inventory.lastSyncQuantity = item.quantity_in_user_inventory + (sign * toBeMoved);
                    }
                    break;
                }
                case TaskMovementType.FROM_INVENTORY_TO_DESTINATION:
                case TaskMovementType.FROM_DESTINATION_TO_INVENTORY: {
                    let sign = movementType === TaskMovementType.FROM_INVENTORY_TO_DESTINATION ? +1 : -1;
                    let destinations = curItemMovements.destinations;
                    if (!has(destinations, locationId)) {
                        destinations[locationId] = {lastSyncQuantity: curQuantity, movedQuantity: sign * toBeMoved};
                    } else {
                        destinations[locationId].movedQuantity = destinations[locationId].movedQuantity + (sign * toBeMoved);
                    }
                    const inventory = curItemMovements.inventory;
                    inventory.movedQuantity = inventory.movedQuantity + (-1 * sign * toBeMoved);
                    if (inventory.lastSyncQuantity === null) {
                        inventory.lastSyncQuantity = item.quantity_in_user_inventory + (sign * toBeMoved);
                    }
                    break;
                }
                case TaskMovementType.FROM_SOURCE_TO_DESTINATION:
                case TaskMovementType.FROM_DESTINATION_TO_SOURCE: {
                    // these movements can occur only in stock loading
                    let sign = movementType === TaskMovementType.FROM_SOURCE_TO_DESTINATION ? +1 : -1;
                    let destinations = curItemMovements.destinations;
                    if (!has(destinations, locationId)) {
                        destinations[locationId] = {lastSyncQuantity: curQuantity, movedQuantity: sign * toBeMoved};
                    } else {
                        destinations[locationId].movedQuantity = destinations[locationId].movedQuantity + (sign * toBeMoved);
                    }

                    const sources = curItemMovements.sources;
                    // there is just one source, no concrete location in stock loading
                    const sourceId = null;
                    if (!has(sources, sourceId)) {
                        // last synced quantity not needed, we never update quantity on source
                        sources[sourceId] = {lastSyncQuantity: null, movedQuantity: 0};
                    }
                    if (movementType === TaskMovementType.FROM_DESTINATION_TO_SOURCE) {
                        // have to save movement from destination to source or unwanted movements from source to inventory can occur
                        // in stock loading we can't move from inventory to source and therefore this movement will always be handled by update of quantity on destination
                        let newQuantity = sources[sourceId].movedQuantity + toBeMoved;
                        const movedQuantity = item.processed_quantity + item.quantity_in_user_inventory;
                        let added = movedQuantity + toBeMoved - item.quantity_to_move;
                        if (added > 0) {
                            // we are loading more items than requested
                            if (added - toBeMoved > 0) {
                                newQuantity = sources[sourceId].movedQuantity;
                            } else {
                                newQuantity = sources[sourceId].movedQuantity + (-1 * (added - toBeMoved));
                            }
                        }
                        sources[sourceId].movedQuantity = newQuantity;
                    } else {
                        // save movement from source to destination otherwise delete from destination might fail
                        // might be treated as movement from source to inventory and from inventory to destination which is okay
                        sources[sourceId].movedQuantity = sources[sourceId].movedQuantity - toBeMoved;
                    }
                    const inventory = curItemMovements.inventory;
                    if (inventory.lastSyncQuantity === null) {
                        inventory.lastSyncQuantity = item.quantity_in_user_inventory;
                    }
                    break;
                }
            }
            if (this.timeout === null) {
                this.timeout = setTimeout(() => this.sendUpdatedQuantity(), this.wait);
            }
        },
        onReturnItem: function (promiseCallback) {
            this.sendUpdatedQuantity().then(() => promiseCallback());
        },
        sendUpdatedQuantity: function (fetchItemsOnError = true) {
            clearTimeout(this.timeout);
            this.timeout = null;
            return new Promise((resolve, reject) => {
                const numOfItems = Object.keys(this.itemMovements).length;
                if (numOfItems === 0) {
                    resolve();
                    return;
                }
                let itemIdx = 0;
                const itemPromises = [];
                const itemMovements = this.itemMovements;
                this.itemMovements = {};
                for (const [itemId, movements] of Object.entries(itemMovements)) {
                    // first move items from source or destination to inventory
                    const moveToInventoryPromises = [];
                    for (const [locationId, quantity] of Object.entries(movements.sources)) {
                        if (quantity.movedQuantity < 0) {
                            moveToInventoryPromises.push(this.API.pickUpFromSource(this.taskInfo.taskId, itemId, locationId, -quantity.movedQuantity));
                            movements.inventory.movedQuantity += quantity.movedQuantity;
                        }
                    }
                    for (const [locationId, quantity] of Object.entries(movements.destinations)) {
                        if (quantity.movedQuantity < 0) {
                            moveToInventoryPromises.push(this.API.pickUpFromDestination(this.taskInfo.taskId, itemId, locationId, -quantity.movedQuantity));
                            movements.inventory.movedQuantity += quantity.movedQuantity;
                        }
                    }
                    const moveFromInventoryPromises = [];
                    // then we must have enough items in inventory to move them to source or destination
                    Promise.all(moveToInventoryPromises).then(() => {
                        for (const [locationId, quantity] of Object.entries(movements.sources)) {
                            if (quantity.movedQuantity > 0) {
                                if (!this.isType(taskTypes.STOCK_LOADING)) {
                                    // not in stock loading
                                    moveFromInventoryPromises.push(this.API.putToSource(this.taskInfo.taskId, itemId, locationId, quantity.movedQuantity));
                                } else {
                                    // in stock loading we need to update item in inventory
                                    moveFromInventoryPromises.push(this.API.updateItem(this.taskInfo.taskId, itemId, null, -quantity.movedQuantity));
                                    // we need to save that we already synced this movement with BE
                                    movements.inventory.movedQuantity += quantity.movedQuantity;
                                }
                            }
                        }
                        for (const [locationId, quantity] of Object.entries(movements.destinations)) {
                            if (quantity.movedQuantity > 0) {
                                if (this.isType(taskTypes.STOCK_LOADING)) {
                                    // have to move items from inventory and then update the rest
                                    const movedFromInventory = movements.inventory.movedQuantity;
                                    let toMoveFromInventory = quantity.movedQuantity;
                                    if (toMoveFromInventory > -movedFromInventory) {
                                        toMoveFromInventory = -movedFromInventory;
                                    }
                                    let promise = Promise.resolve();
                                    if (toMoveFromInventory > 0) {
                                        promise = this.API.putToDestination(this.taskInfo.taskId, itemId, locationId, toMoveFromInventory);
                                        movements.inventory.movedQuantity += toMoveFromInventory;
                                    }
                                    moveFromInventoryPromises.push(
                                        new Promise((resolve, reject) => {
                                            promise.then(() => {
                                                if (toMoveFromInventory > 0) {
                                                    // subtract amount we already moved
                                                    quantity.movedQuantity -= toMoveFromInventory;
                                                    // also have to update last synced quantity
                                                    quantity.lastSyncQuantity += toMoveFromInventory;
                                                }
                                                if (quantity.lastSyncQuantity + quantity.movedQuantity !== 0) {
                                                    if (quantity.movedQuantity !== 0) {
                                                        this.API.updateItem(this.taskInfo.taskId, itemId, locationId, quantity.movedQuantity).then(resolve).catch(reject);
                                                    } else {
                                                        resolve();
                                                    }
                                                } else if (quantity.lastSyncQuantity !== 0) {
                                                    this.API.deleteItem(this.taskInfo.taskId, itemId, locationId).then(resolve).catch(reject);
                                                } else {
                                                    resolve();
                                                }
                                            }).catch(reject);
                                        })
                                    );
                                } else {
                                    moveFromInventoryPromises.push(this.API.putToDestination(this.taskInfo.taskId, itemId, locationId, quantity.movedQuantity));
                                }
                            }
                        }
                        // we need to wait for movement from inventory
                        Promise.all(moveFromInventoryPromises).then(() => {
                            // in stock loading items can be added to task, need to check inventory
                            let inventoryPromise = Promise.resolve();
                            if (this.isType(taskTypes.STOCK_LOADING)) {
                                const inventoryMovements = movements.inventory;
                                if (inventoryMovements.lastSyncQuantity + inventoryMovements.movedQuantity !== 0) {
                                    if (inventoryMovements.movedQuantity !== 0) {
                                        inventoryPromise = this.API.updateItem(this.taskInfo.taskId, itemId, null, inventoryMovements.movedQuantity);
                                    }
                                } else if (inventoryMovements.lastSyncQuantity !== 0) {
                                    inventoryPromise = this.API.deleteItem(this.taskInfo.taskId, itemId, null);
                                }
                            }
                            itemPromises.push(inventoryPromise);
                            itemIdx++;
                            if (itemIdx === numOfItems) {
                                Promise.all(itemPromises).then(() => {
                                    this.conflict = null;
                                    resolve();
                                }).catch(err => {
                                    if (err.response && err.response.status === 409) {
                                        this.conflict = err.response.data;
                                    } else {
                                        this.snack(err);
                                    }
                                    if (fetchItemsOnError) {
                                        this.fetchItems({debounceQuantities: true});
                                    }
                                    reject();
                                });
                            }
                        }).catch(err => {
                            if (err.response && err.response.status === 409) {
                                this.conflict = err.response.data;
                            } else {
                                this.snack(err);
                            }
                            if (fetchItemsOnError) {
                                this.fetchItems({debounceQuantities: true});
                            }
                            reject();
                        });
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            this.conflict = err.response.data;
                        } else {
                            this.snack(err);
                        }
                        if (fetchItemsOnError) {
                            this.fetchItems({debounceQuantities: true});
                        }
                        reject();
                    });
                }
            });
        }
    }

};

export {UpdateQuantityMixin};
