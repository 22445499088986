import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{staticClass:"priority",class:'p' + _vm.priority,attrs:{"ripple":"","to":_vm.to}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":_vm.priority !== 2 ? _vm.TaskPriorityColor[_vm.priority] : 'default',"x-large":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.header)+" ")]),(_vm.content)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.content)+" ")]):_vm._e(),(_vm.subContent)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.subContent)+" ")]):_vm._e()],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"text-right"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.actionText)+" ")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.actionTextTooltip)}})])],1)],1),_vm._t("item-append")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }