import {StocksRender} from "@/app/overview/definitions/overview.render.form";
import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";

function ShippingHandoverForm() {
    return {
        carrierId: null,
        stockId: null,
        subStockId: null,
        selectShipments: true
    };
}

function ShippingHandoverRender(create) {
     let carrierAutocomplete = {
        items: []
    };
    if (!create) {
        carrierAutocomplete = {
            callFn: () => ShipmentCarrierAPI.getAll(),
            thenFn: response => response.data.map(carrier => ({
                text: carrier.name,
                value: carrier.id
            }))
        };
    }
    let fields = {
        carrierId: {
            icon: '$carrier',
            required: true,
            sticky: true,
            autocomplete: carrierAutocomplete
        },
        ...(new StocksRender(true, create, !create, false)),
    };
    if (create) {
        fields = {
            ...fields,
            selectShipments: {
                icon: '$shipmentSelect',
                checkbox: true,
                hint: 'base.shipping.handover.create.form.noShipmentSelect'
            }
        };
    }
    return fields;
}

export {ShippingHandoverForm, ShippingHandoverRender};
