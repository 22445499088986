const loaderModule = {
    namespaced: true,
    modules: {
        overlay: {
            namespaced: true,
            state: {
                show: false,
            },
            mutations: {
                show (state) {
                    state.show = true;
                },
                hide (state) {
                    state.show = false;
                },
            },
            getters: {
                show: (state) => state.show,
            }
        },
        bottom: {
            namespaced: true,
            state: {
                show: false,
                requests: 0
            },
            mutations: {
                show (state) {
                    state.show = true;
                    state.requests++;
                },
                hide (state) {
                    state.requests--;
                    if (state.requests <= 0) {
                        state.requests = 0;
                        state.show = false;
                    }
                },
            },
            getters: {
                show: (state) => state.show,
            }
        }
    }
};

export default loaderModule;
