<template>
  <span>
    <template v-if="value === 'type'">
      <span
        class="d-inline-flex"
        :class="moveTypeClass(item)"
      >
        <v-icon>
          ${{ item.type }}
        </v-icon>
      </span>
    </template>
    <template v-if="value === 'amount'">
      <v-row
        class="float-right"
        :class="moveTypeClass(item)"
      >
        {{ (item.type === 'PICK' ? '-' : '+') + item.amount }}
      </v-row>
      <v-row class="mt-1 float-right text--disabled text-caption">
        {{
          item.stock_amount_after_change + (item.amount * (item.type === 'PICK' ? 1 : -1))
        }}➜{{ item.stock_amount_after_change }}
      </v-row>
    </template>
    <template v-if="value === 'substock.id'">
      {{ SubStockCache[item.subordinate_stock_id] | subStockLabel }}
    </template>
    <template v-if="value === 'location.type'">
      <span
        class="d-inline-flex"
        :class="moveTypeClass(item)"
      >
        <v-icon>
          ${{ item.location.type }}
        </v-icon>
      </span>
    </template>
    <template v-if="value === 'location.id'">
      <span v-if="item.location.name">
        {{ item.location.name }}
      </span>
      <span v-else>
        <UserInitialsWithTooltip :user="UserCache[item.location.user_id]" />
      </span>
    </template>
    <template v-if="value === 'product_instance.id'">
      {{ item.product_instance | productLabel }}
    </template>
    <template v-if="value === 'task.id'">
      <TaskWithTypeTooltip :task="item.task" />
    </template>
    <template v-if="value === 'task.type'">
      {{ $t(taskNames[item.task.type]) }}
    </template>
    <template v-if="value === 'created_by'">
      {{ UserCache[item.created_by] | fullName }}
    </template>
    <template v-if="value === 'created_at'">
      <DateTimeWithTooltip :date-time="item.created_at" />
    </template>
  </span>
</template>

<script>
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component.vue";
    import UserInitialsWithTooltip from "@/app/components/UserInitialsWithTooltip.component.vue";
    import TaskWithTypeTooltip from "@/app/components/TaskWithTypeTooltip.component.vue";
    import {ReactiveSubStockCacheMixin} from "@/app/mixins/ReactiveSubStockCacheMixin";

    export default {
        name: "StockMovementItem",
        components: {TaskWithTypeTooltip, UserInitialsWithTooltip, DateTimeWithTooltip},
        mixins: [ReactiveUserCacheMixin, ReactiveSubStockCacheMixin],
        props: {
            value: {
                type: String,
                default: ''
            },
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            taskNames: taskNames
        }),
        methods: {
            isOutsideMovement: function (item) {
                return [
                    taskTypes.STOCK_LOADING,
                    taskTypes.STOCK_PICKING,
                    taskTypes.STOCK_PICKING_SET
                ].includes(item.task.type);
            },
            moveTypeClass: function (item) {
                return ['no-background', this.isOutsideMovement(item) ? (
                    item.location.type === 'STOCK' ? (item.type === 'PICK' ? 'moveOut' : 'moveIn') : 'move'
                ) : 'move'];
            }
        }
    };
</script>

<style scoped>

</style>
