<template>
  <v-app>
    <!-- Toolbar will be rendered in views so they can provide their content for slots-->
    <v-main>
      <OfflineNotifier />
      <ReadonlyNotifier />
      <v-container
        fluid
        :class="{
          'pa-1': $vuetify.breakpoint.smAndDown,
          'px-3': $vuetify.breakpoint.mdAndUp,
          'px-8': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <keep-alive
          :exclude="cacheExclude"
          :max="30"
        >
          <router-view :key="$route.path + '-' + ($route.meta.uuid || 0)" />
        </keep-alive>
      </v-container>
    </v-main>
    <TimeTrackerNotice />
    <Snackbar />
    <LoaderOverlay />
    <LoaderBottom />
  </v-app>
</template>

<script>
    import Snackbar from "@/app/components/Snackbar.component";
    import LoaderOverlay from "@/app/components/loader/LoaderOverlay.component";
    import LoaderBottom from "@/app/components/loader/LoaderBottom.component";
    import OfflineNotifier from "@/app/components/OfflineNotifier.component";
    import ReadonlyNotifier from "@/app/components/ReadonlyNotifier.component.vue";
    import TimeTrackerNotice from "@/app/components/TimeTrackerNotice.component";
    import {Env} from "@/service/Environment";

    export default {
        name: "App",
        components: {ReadonlyNotifier, TimeTrackerNotice, OfflineNotifier, LoaderOverlay, LoaderBottom, Snackbar},
        computed: {
            cacheExclude: function () {
                // watch https://github.com/vuejs/vue-loader/issues/1332 for better solution
                return Env.isDevelopment() ? /.+/ : [];
            }
        },
        mounted: function () {
            const landingLoader = document.getElementById('landingLoader');
            landingLoader && landingLoader.parentNode.removeChild(landingLoader);
        }
    };
</script>

<style lang="sass">
#app
  background: var(--v-background-base)

a
  text-decoration: none

.transition-general
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

.cursor-pointer
  cursor: pointer

.v-window.v-item-group.v-tabs-items
  background-color: unset
  height: auto

@keyframes rotation
  from
    transform: rotate(359deg)
  to
    transform: rotate(0deg)

.centered-input input
  text-align: center

.v-tabs-items
  overflow: visible

.card-list-item
  transition: all .3s

.card-list-enter, .card-list-leave-to
  opacity: 0
  transform: translateY(-30px)

.card-list-leave-active
  position: absolute
  width: 100%


.chip-list-item
  transition: all .2s
  display: inline-block

.chip-list-enter, .chip-list-leave-to
  opacity: 0
  transform: translateX(-30px)

.chip-list-leave-active
  position: absolute


.v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev
  display: none !important
// TODO this is ugly, but it seems to be bug in Vuetify itself

.v-file-input.v-text-field > .v-input__control > .v-input__slot
  cursor: default

.v-file-input.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot
  cursor: pointer

.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span
    font-size: 50px !important

.overlay
  position: fixed
  z-index: 100
  background-color: rgba(0, 0, 0, 0.3)
  width: 100%
  height: 100%

.v-dialog .v-divider
  width: 100%

.v-bottom-sheet .v-sheet
  overflow-y: auto

.v-tabs-items
  height: calc(100% - 96px)

.fieldAsButton .v-input__slot, .fieldAsButton input
  cursor: pointer

.upsideDown
  transform: rotate(-180deg)

.rotate90
  transform: rotate(90deg)

.flex-gap-8
  row-gap: 8px

.widthFitContent
  min-width: fit-content


.moveOut, .moveOut i
  color: var(--v-moveOut-base) !important

.moveIn, .moveIn i
  color: var(--v-moveIn-base) !important

.move, .move i
  color: var(--v-move-base) !important

.v-application .v-data-table .no-background
  background-color: unset !important
  border-color: unset !important

.v-select__selections
  max-height: 10em
  overflow-y: auto

::-webkit-scrollbar
  width: 7px

::-webkit-scrollbar-track
  background: none

::-webkit-scrollbar-thumb
  background: #888
  border-radius: 5px

::-webkit-scrollbar-thumb:hover
  background: #555

.multiLineButton
    display: inline-block !important
    width: 95% !important
    height: auto !important
    white-space: normal !important

.dashboardIcon:hover
    transform: rotate(-30deg)

// TODO move these to some other place?
</style>
