import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"position-x":_vm.settings.menuX,"position-y":_vm.settings.menuY,"absolute":"","offset-y":""},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.settings.menuShow
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.settings, "menuShow", $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      settings.menuShow\n    "}},[_c(VList,_vm._l((_vm.possibleActions),function(action){return _c(VListItem,{key:action.label,attrs:{"disabled":action.loading,"to":action.routerLink ? action.routerLink(_vm.item) : null},on:{"click":function($event){action.action ? action.action(_vm.item) : () => {}}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2 pb-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(action.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t(action.label))+" ")],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }