import {TableFilter} from "@/enum/table_filter";
import rules from "@/utils/formRules";

function StockLocationForm() {
    return {
        name: '',
        code: null,
        availability: 50,
        is_expedition: false,
        is_mobile: false
    };
}

function StockLocationRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
        batch: {
            show: false
        },
        code: {
            icon: '$barcode',
            max: 40,
            hint: 'stocks.locations.codehint',
            rules: [
                rules.pattern(/^[\x21-\x7E]*$/),
            ]
        },
        availability: {
            icon: '$availability',
            hint: 'stocks.locations.availability_hint',
            type: 'number',
            rules: [
                rules.range(0, 100)
            ]
        },
        is_expedition: {
            icon: '$expeditionLocation',
            checkbox: true
        },
        is_mobile: {
            icon: '$mobileLocation',
            checkbox: true
        }
    };
}

const stockLocationTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    code: {
        filterType: TableFilter.TEXT
    },
    availability: {
        filterType: TableFilter.NUMBER
    },
    is_expedition: {
        filterType: TableFilter.BOOLEAN
    },
    is_mobile: {
        filterType: TableFilter.BOOLEAN
    }
};

const stockLocationTableInSubstock = {
    ...stockLocationTable,
    is_allowed: {
        filterType: TableFilter.BOOLEAN
    }
};

export {StockLocationForm, StockLocationRender, stockLocationTable, stockLocationTableInSubstock};
