<template>
  <div class="py-5">
    <StockBuyPricesComponent
      v-for="(type, index) of ['IN', 'CURR', 'OUT']"
      :key="type"
      :type="type"
      :class="index > 0 ? 'mt-4' : ''"
    />
  </div>
</template>

<script>
    import StockBuyPricesComponent from "@/app/overview/reports/components/StockBuyPrices.component";

    export default {
        name: "StockBuyPricesBundleComponent",
        components: {StockBuyPricesComponent}
    };
</script>

<style scoped>

</style>
