import {Roles} from "@/enum/roles";
import List from "@/app/manufacturers/ManufacturersList.view";
import CreateUpdate from "@/app/manufacturers/ManufacturerCreateUpdate.view";

const BASE_URL = '/manufacturers';

const routes = {
    base: {
        path: BASE_URL,
        component: List,
        meta: {
            title: 'manufacturers.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    create: {
        path: BASE_URL + '/create',
        component: CreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'manufacturers.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:manufacturerId(\\d+)/update',
        component: CreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'manufacturers.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            // TODO snack message
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
