export default {
    warehouseMap: "Mapa skladu",
    usageHeatMap: "Heatmapa vyťaženia skladových pozícií",
    mapEditor: "Editor mapy skladu",
    backToMap: "Späť na mapu",
    rack: "Regál",
    obstacle: "Prekážka",
    location: "Umiestnenie",
    locations: "Umiestnenie",
    zone: "Zóna",
    shelf: "Patro",
    shelfCount: "Počet poschodí",
    column: "Stĺpec",
    cell: "Bunka",
    countInCell: "Poradie v bunke",
    firstSymbol: "Prvý symbol",
    secondSymbol: "Druhý symbol",
    thirdSymbol: "Tretí symbol",
    firstSymbolValue: "Symbol",
    secondSymbolValue: "Symbol",
    thirdSymbolValue: "Symbol",
    firstStartingSymbol: "Počiatočný symbol",
    secondStartingSymbol: "Počiatočný symbol",
    thirdStartingSymbol: "Počiatočný symbol",
    startingSymbol: "Počiatočný symbol",
    numbering: "Číslovanie",
    namingDirection: "Smer pomenovania",
    startingSymbolColumn: "Počiatočný symbol",
    numberingColumn: "Stĺpce",
    startingSymbolShelf: "Počiatočný symbol",
    numberingShelf: "Patra",
    separators: "Oddeľovače",
    firstSeparator: "Prvý oddeľovač",
    secondSeparator: "Druhý oddeľovač",
    thirdSeparator: "Tretí oddeľovač",
    actions: "Akcia",
    undefined: "Nenastavené",
    multipleValues: "Viac hodnôt",
    untitled: "Bez názvu",
    width: "Šírka",
    height: "Výška",
    mapWidth: "Šírka mapy",
    mapHeight: "Výška mapy",
    depth: "Hĺbka",
    general: "Všeobecné",
    naming: "Menovací systém",
    dimensions: "Rozmery",
    stockObjects: "Skladové objekty",
    racks: "Regály",
    locationGroups: "Umiestnenie",
    zones: "Zóny",
    obstacles: "Prekážky",
    detail: "Detail",
    remove: "Odstrániť",
    rename: "Premenovať",
    newName: "Nové meno",
    advancedSettings: "Nastavenia",

    objectWidth: "Šírka (počet stĺpcov)",
    objectHeight: "Výška (počet riadkov)",
    rackShelves: "Výška (počet poschodí)",
    locationsOnPosition: "Umiestnenie na pozícii",
    locationName: "Meno umiestnenia",
    oneZoneTypes: "Typy zóny",

    dimension: "Rozmer",
    newDimension: "Nový rozmer",
    selectedLocations: "Vybrané umiestnenia",
    selectedCells: "Vybrané bunky",
    count: "Počet",
    locationCount: "Počet umiestnení",
    cellCount: "Počet buniek",
    assign: "Priradiť",
    moreValues: "Viac hodnôt",
    withoutDimensions: "Bez rozmeru",

    bottomToTop: "Zdola nahor",
    topToBottom: "Zhora nadol",
    leftToRight: "Zľava doprava",
    rightToLeft: "Sprava doľava",

    noMaps: "Ešte ste nevytvorili žiadnu mapu.",
    createMap: "Nová mapa",
    goBack: "Späť na prehľad",
    goBackAnyway: "Zahodiť zmeny a odísť",
    stay: "Zostať",
    unsavedChanges1: "Naozaj chcete odísť?",
    unsavedChanges2: "Máte neuložené zmeny, ktoré budú po odchode zahodené.",
    deleteConfirm1: "Naozaj chcete zmazať túto mapu?",
    deleteConfirm2: "Táto akcia je nevratná.",
    delete: "Odstrániť",
    cancel: "Zrušiť",
    save: "Uložiť",
    saved: "Mapa uložená",
    create: "Vytvoriť",
    mapCannotSaveDuplicit: "Nie je možné uložiť - duplicitné umiestnenie",
    errors: "Neuložené, opravte chyby",

    addRack: "Nový regál",
    addObstacle: "Nová prekážka",
    addZone: "Nová zóna",
    addLocationsGroup: "Nové umiestnenia",
    newObjectName: "Meno nového objektu",
    newLocationGroupLocations: "Nové umiestnenia na tejto pozícii",
    addLocation: "Pridať umiestnenie",
    newZoneType: "Typ novej zóny",

    detectedCollisions: "Bola detekovaná kolízia",
    cannotFinishActionDueToCollision: "Akcia nemohla byť dokončená, bola detekovaná kolízia.",
    zoom: "Priblíženie",

    automaticLocationEdit: "Automatická úprava umiestnenia",
    manualLocationEdit: "Manuálna úprava umiestnenia",

    zoneTypes: {
        receive: "Príjem",
        delivery: "Výdaj",
        packing: "Baliace miesto",
        forklift: "Parkovanie retrakov",
    },

    locationMappingDetails: {
        notMapped: "Nenamapované umiestnenie",
        mapped: "Namapované umiestnenia",
        toCreate: "Na vytvorenie (neexistujúce umiestnenie)",
        duplicit: "Duplicitné umiestnenie (viackrát v mape)",
    },

    bookmarks: {
        map: "Mapa skladu",
        rack: "Detail regálu"
    },

    tools: {
        edit: "Editovať",
        move: "Pohyb",
        rotate: "Rotácia",
        copy: "Kopírovať",
        delete: "Zmazať",
        cancel: "Zrušiť výber",
        cancelAction: "Zrušiť akciu"
    },

    guide: {
        move: "Kliknite na voľné miesto, kam objekt presunúť.",
        createRack: "Kliknite na voľné miesto, kde bude začínať nový regál.",
        createObstacle: "Kliknite na voľné miesto, kde bude začínať nová prekážka.",
        createZone: "Kliknite na voľné miesto, kde bude začínať nová zóna.",
        createLocationsZone: "Kliknite na voľné miesto, kde bude začínať nová skupina umiestnení.",
        copy: "Kliknite na voľné miesto, kam bude objekt skopírovaný."
    },

    locationsSync: {
        sync: "Synchronizovať umiestnenie",
        mapNotHavingStockLoc: "Umiestnenia na mape, ktoré nemajú zodpovedajúce umiestnenie v sklade",
        stockNotHavingMapLoc: "Umiestnenia v sklade, ktoré nemajú zodpovedajúce umiestnenie na mape",
        createStockLocCorrespondingMap: "Automaticky vytvoriť zodpovedajúce umiestnenie v sklade",
        deleteStockLocCorrespondingMap: "Automaticky odstrániť nenamapované umiestnenia v sklade"
    },

    locationCodeDecide: {
        chooseLocation: "Vyberte umiestnenie",
        guide1: "V sklade sa nachádza viacero umiestnení s názvom ",
        guide2: ". Vyberte umiestnenie, ktoré si prajete pridať.",
        notMapped: "Nenamapované umiestnenia",
        mapped: "Už namapované umiestnenia",
        add1: "Pridať umiestnenie",
        add2: "s kódom",
    },

    error: {
        applyDimensionsToNull: "Rozmery nemohli byť priradené. Najskôr vykonajte výber buniek regálu."
    }
};
