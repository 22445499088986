<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.stockPicking.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <TaskBarcodeDialog
      v-if="showBarcodeDialog"
      :show-dialog.sync="showBarcodeDialog"
      :title="$t('tasks.stockPicking.scannedLocationNotEmpty')"
      :code="scannedCode"
      @cancel="cancelScanLocation"
      @confirm="reallyScanLocation"
    />
    <TaskPrintReport
      v-if="isClosed"
      :api="API"
      allow-csv
      :export-handler="exportXlsxOrCvs"
    />
    <template v-if="details">
      <template v-if="assignable">
        <TaskStartActionButton
          :loading="loading"
          @continue="assign"
        />
      </template>
      <template v-else-if="isPartOfSet">
        <Alert
          :show-alert="true"
          :display-text="$t('tasks.assign.notAssignable')"
          :type="'info'"
        />
      </template>
      <TaskConflict
        v-if="conflict"
        :error="conflict"
      />
      <template v-if="hasTabs">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
          >
            <component
              :is="item.component"
              :task-info="taskInfo"
              :api="API"
              @refresh-task="getTask"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <StockPickingAssignment
          :task-info="taskInfo"
          :api="API"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>


<script>
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import {TaskStockPickingSetAPI} from "@/api/TaskStockPickingSetAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskStartActionButton from "@/app/tasks/components/TaskStartActionButton.vue";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";
    import StockPickingAssignment from "@/app/tasks/stockPicking/components/StockPickingAssignment.component";
    import StockPickingPicking from "@/app/tasks/stockPicking/components/StockPickingPicking.component";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import {TaskSetTargetLocationMixin} from "@/app/mixins/TaskSetTargetLocationMixin";
    import TaskBarcodeDialog from "@/app/tasks/components/TaskBarcodeDialog.component";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {readerFeedback} from "@/utils/readerFeedback";
    import Alert from "@/app/components/Alert.component";
    import TaskPrintReport from "@/app/tasks/components/TaskPrintReport.component";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import {TaskStockPickingAPI as API} from "@/api/TaskStockPickingAPI";
    import {ProductAPI} from "@/api/ProductAPI";
    import * as Export from "@/service/Export";
    import {BuyerAPI} from "@/api/BuyerAPI";

    export default {
        name: "StockPickingShow",
        components: {
            TaskConflict, TaskPrintReport, Alert, TaskStartActionButton, StockPickingAssignment, TaskBarcodeDialog
        },
        mixins: [
            ACLMixin, TaskStateMixin, TaskAssignMixin, EventsListenerMixin, BarcodeListenerMixin,
            RouteParamsMapperMixin, TaskSetTargetLocationMixin, CreatedOrActivatedMixin
        ],
        props: {
            isTaskSet: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            loadingReport: false,
            details: null,
            tab: null,
            showBarcodeDialog: false,
            scannedCode: null
        }),
        computed: {
            API: function () {
                return this.isTaskSet ? TaskStockPickingSetAPI : TaskStockPickingAPI;
            },
            type: function () {
                return this.isTaskSet ? taskTypes.STOCK_PICKING_SET : taskTypes.STOCK_PICKING;
            },
            isPartOfSet: function () {
                return this.details.parent_task_id !== undefined && this.details.parent_task_id !== null;
            },
            events: function () {
                return {
                    'onBarcodeRead': this.activateTab,
                    'task-targetLocation-picked': this.fetchDestinationLocation,
                    'display-barcode-dialog': this.onDisplayBarcodeDialog
                };
            },
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.typeName;
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.COLLAPSE
                };
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: StockPickingAssignment
                }]
                    .concat(
                        (
                            this.isStorekeeper || this.isChief
                        ) ? [{
                            label: 'stockPicking',
                            component: StockPickingPicking
                        }] : [])
                    .concat(
                        (
                            (this.isStorekeeper && this.assignedToCurrentUser && this.isInProgress) || this.isChief
                        ) ? [{
                            label: this.isOpenState ? 'finish' : 'movements',
                            component: TaskDefaultOverview
                        }] : []); // TODO refactor these in all tasks
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook).then(() => {
                this.fetchDestinationLocation();
            });
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return this.API.get(this.taskId, true)
                    .then(response => {
                        if (this.details === null || lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                        } else {
                            assign(this.details, response.data);
                        }
                        if (this.details.buyer_id) {
                            BuyerAPI.get(this.details.buyer_id)
                                .then(response => {
                                    this.$set(this.details, 'buyer', response.data);
                                });
                        }
                        this.getEmptyLocations(true);
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            },
            fetchDestinationLocation: function () {
                if (this.details.shipping_type === TaskShippingType.COURIER) {
                    const locationId = this.details.target_location_id;
                    if (locationId) {
                        this.cacheLocation(locationId)
                            .then(response => {
                                this.$set(this.details, 'target_location', response.data);
                            }).catch(this.snack);
                    } else {
                        this.$set(this.details, 'target_location', {
                            code: '...',
                            name: this.$t('tasks.stockPicking.yetUnknown')
                        });
                        return Promise.resolve();
                    }
                } else {
                    this.$set(this.details, 'target_location', {
                        code: this.$t('tasks.stockPicking.handOverTo'),
                        name: this.details.buyer ? this.details.buyer.name : "-"
                    });
                    return Promise.resolve();
                }
            },
            onDisplayBarcodeDialog: function (code) {
                readerFeedback.warning();
                this.scannedCode = code;
                this.showBarcodeDialog = true;
            },
            reallyScanLocation: function () {
                BarcodeAPI.decode(this.scannedCode)
                    .then(response => {
                        this.reallySetTargetLocation(response.data.object_id);
                        this.showBarcodeDialog = false;
                        this.scannedCode = null;
                        readerFeedback.success();
                    });
            },
            cancelScanLocation: function () {
                this.showBarcodeDialog = false;
                this.scannedCode = null;
            },
            exportXlsxOrCvs: function (is_csv = true) {
                const taskId = this.taskInfo.details.id;
                const promise = this.isTaskSet ? TaskStockPickingSetAPI.getAllItems(taskId) : API.getAllItems(taskId);

                promise
                    .then(response => {
                        const promises = [];
                        const exportData = response.data.map(record => {
                            const row = {};
                            promises.push(ProductAPI.getInstanceWOProduct(record.product_instance_id)
                                .then(res => {
                                    const product = res.data;
                                    row.name = product.product.name;
                                    row.model = product.product.model;
                                    row.type = product.type.name;
                                    row.amount = record.processed_quantity;
                                    row.measure_unit = product.product.measure_unit === '' ?
                                        this.$t('base.pcs') : product.product.measure_unit;
                                    row.price_vat = product.product.price_vat;
                                }));
                            return row;
                        });
                        Promise.all(promises).then(() => {
                            if(is_csv){
                                Export.csv(exportData, [], 'stock_picking_' + taskId + '.csv');
                            } else {
                                Export.xlsx(exportData, [], 'stock_picking_' + taskId + '.xlsx');
                            }
                        }).finally(() => {
                            this.loadingReport = false;
                        });
                    }).catch(this.snack);
            }
        }
    };
</script>

<style scoped>

</style>
