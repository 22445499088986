<template>
  <div>
    <v-autocomplete
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        item.props.subStockIds
      "
      chips
      hide-details
      :items="substocks"
      :label="$t('homepage.config.onlySubstocks')"
      :loading="loadingSubstocks"
      multiple
      outlined
      prepend-icon="$filter"
    />
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "DashboardOrdersStatsConfig",
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            chosenSubstocks: [],
            substocks: [],
            loadingSubstocks: false
        }),
        createdOrActivated: function () {
            this.fetchSubstocks();
        },
        methods: {
            fetchSubstocks: function () {
                this.loadingSubstocks = true;
                this.substocks = [];
                StockAPI.getAllPages().then(response => {
                    const promises = [];
                    response.data.items.map(stock => {
                        promises.push(
                            StockAPI.getAllSubstockPages(stock.id)
                                .then(response => {
                                    if (response.data.items.length) {
                                        this.substocks.push(
                                            {header: stock.name},
                                            {divider: true}
                                        );
                                    }
                                    this.substocks.push(
                                        ...response.data.items.map(el => ({
                                            text: el.name,
                                            value: el.id,
                                            ownerId: el.owner.id,
                                            stockId: stock.id
                                        }))
                                    );
                                })
                        );
                    });
                    Promise.all(promises).catch(this.snack).finally(() => this.loadingSubstocks = false);
                }).catch(err => {
                    this.snack(err);
                    this.loadingSubstocks = false;
                });
            }
        }
    };
</script>

<style scoped>

</style>
