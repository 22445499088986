import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:{'ma-1': !_vm.inline},attrs:{"flat":_vm.inline}},[_c(VContainer,{class:{'ma-0 pa-0': _vm.inline},attrs:{"fluid":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('tasks.notes.name') + ': '))]),_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.notes),function(note){return [_c(VListGroup,{key:note.id,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VIcon,{staticClass:"mr-3"},[_vm._v(" "+_vm._s(note.created_by.is_chief ? _vm.roleIcons[_vm.Roles.CHIEF] : _vm.roleIcons[_vm.Roles.STOREKEEPER])+" ")]),_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(note.text)+" ")])]},proxy:true}],null,true)},[_c(VContainer,{staticClass:"text-caption",attrs:{"fluid":""}},[_vm._v(" "+_vm._s(_vm._f("fullName")(note.created_by))+" "),_c('DateTimeWithTooltip',{attrs:{"date-time":note.created_at}})],1)],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }