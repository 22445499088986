export function ImportForm(isNewConfig) {
    const fields = {
        file: null,
    };
    if (isNewConfig) {
        fields.name = '';
        fields.domain = '';
        fields.easyMode = true;
        fields.latestOnDuplicity = false;
    }
    return fields;
}

export function ImportFormRender(isNewConfig) {
    const renders = {
        file: {
            required: true,
            filePicker: true,
            appendOuter: true,
        }
    };
    if (isNewConfig) {
        renders.name = {
            icon: '$carrierName',
            required: true,
            max: 50,
        };
        renders.domain = {
            icon: '$tableFileType',
            select: [],
            required: true,
            valueLangPath: 'exports.domains.',
        };
        renders.easyMode = {
            checkbox: true,
        };
        renders.latestOnDuplicity = {
            checkbox: true,
        };
    }
    return renders;
}

export function ImportEditForm() {
    return {
        name: '',
        easyMode: true,
        latestOnDuplicity: false
    };
}

export function ImportEditFormRender() {
    return {
        name: {
            icon: '$carrierName',
            required: true,
            max: 50,
        },
        easyMode: {
            checkbox: true,
        },
        latestOnDuplicity: {
            checkbox: true,
        }
    };
}
