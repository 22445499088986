import {MapObjectAction, MapObjectType, PanelMode, RackNamingSymbols} from '@/enum/stock_map';
import {
  getObjectBrCell,
  getObjectBrPoint,
  getRackColumns,
  incrementLast,
  nextInSequence
} from '@/service/Map.js';
import {cloneDeep} from 'lodash';

const storageMapModule = {
  namespaced: true,
  persistent: false,
  state: {
    map: {
      width: null,
      height: null,
      objects: [],
      zones: [],
      dimensions: [],
      deletedObjectsAndZones: []
    },
    stockLocationMapping: [],
    heatMap: null,
    selectedObject: null,
    // Currently selected coordinates in Rack View
    selectedRackArea: {
      tl: [-1, -1],
      br: [-1, -1]
    },
    // Unique Ids for created objects
    blockUniqueId: 10,
    changes: false, // Indicates if any local changes to the map have been made since loading/saving
    app: {
      stockId: -1,            // Currently handled stock
      mapId: -1,              // Currently handled map (being loaded, saved, edited, deployed...)
      loading: false,          // A loading wheel that's displayed alone.
      saving: false,
    },
    panelMode: PanelMode.STANDBY,
    defaultDimension: {
      default: true,
      width: 200,
      height: 300,
      depth: 60,
      color: '#FFFFFF' // Color that represents the dimension - in app, user inputs this in color picker
    },
  },

  mutations: {

    resetMap(state) {
      state.map.width = null;
      state.map.height = null;
      state.map.objects = [];
      state.map.zones = [];
      state.map.dimensions = [];
      state.map.deletedObjectsAndZones = [];
      state.stockLocationMapping = [];
      state.heatMap = null;
      state.selectedObject = null;
      state.selectedRackArea = {
        tl: [-1, -1],
        br: [-1, -1]
      };
      state.blockUniqueId = 10;
      state.changes = false;
      state.app.stockId = -1;
      state.app.mapId = -1;
      state.app.saving = false;
      state.app.loading = false;
      state.panelMode = PanelMode.STANDBY;
    },

    setMapId(state, {value}) {
      state.app.mapId = value;
    },

    setStockId(state, {value}) {
      state.app.stockId = value;
    },

    setMapObjects(state, {objects, zones, dimensions, deletedObjectsAndZones}){
      state.map.objects = objects;
      state.map.zones = zones;
      state.map.dimensions = dimensions;
      state.map.deletedObjectsAndZones = deletedObjectsAndZones;
    },

    setLoading(state, {value}){
      state.app.loading = value;
    },

    setSaving(state, {value}){
      state.app.saving = value;
    },

    setChanges(state, {value}) {
      state.changes = value;
    },

    setHeatMap(state, {data}){
      state.heatMap = data;
    },

    unmapFromStockLocation(state, {name, stockLocationId}){
      const slot = state.stockLocationMapping.find(sl => sl.name === name);
      if (slot === undefined) {
        return;
      }
      const mapLoc = slot.locations.find(loc => loc.stockLocationId === stockLocationId);
      if (mapLoc === undefined) {
        return;
      }

      if (mapLoc.stockLocationId === null && mapLoc.mappedAmount <= 1) {
        slot.locations = slot.locations.filter(loc => loc.stockLocationId !== stockLocationId);
        if (slot.locations.length === 0) {
          state.stockLocationMapping = state.stockLocationMapping.filter(sl => sl.name !== name);
        }
      }
      else {
        mapLoc.mappedAmount <= 1 ? mapLoc.mappedAmount = 0 : mapLoc.mappedAmount--;
      }
    },

    resetStockLocationMapping(state, {data}){
      state.stockLocationMapping = [];
      data.forEach(location => {
        let slot = state.stockLocationMapping.find(sl => sl.name === location.name);
        if (slot === undefined) {
          state.stockLocationMapping.push({
            name: location.name,
            locations: []
          });
        }
        slot = state.stockLocationMapping.find(sl => sl.name === location.name);
        slot.locations.push({
          code: location.code,
          stockLocationId: location.id,
          mappedAmount: 0
        });
      });
    },

    createNewObstacle(state, { tl, name }) {
      state.changes = true;
      state.blockUniqueId++;
      const obstacleLen = state.map.objects.push({
        action: MapObjectAction.CREATE,
        id: state.blockUniqueId,
        type: MapObjectType.OBSTACLE,
        width: 1,
        height: 1,
        name: name,
        vertical: false,
        tl: tl,
      });
      state.selectedObject = state.map.objects[obstacleLen - 1];
    },

    createNewZone(state, { tl, name, type }) {
      state.changes = true;
      state.blockUniqueId++;
      const obstacleLen = state.map.zones.push({
        action: MapObjectAction.CREATE,
        id: state.blockUniqueId,
        type: MapObjectType.ZONE,
        zoneTypes: type,
        width: 1,
        height: 1,
        name: name,
        vertical: false,
        tl: tl,
      });
      state.selectedObject = state.map.zones[obstacleLen - 1];
    },

    applyDimension(state, { dimension }) {
      state.changes = true;
      if (state.selectedObject !== null) {
        for (let i = state.selectedRackArea.tl[0]; i <= state.selectedRackArea.br[0]; ++i) {
          for (let j = state.selectedRackArea.tl[1]; j <= state.selectedRackArea.br[1]; ++j) {
            state.selectedObject.locations[i][j].dimensions = dimension;
            state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
          }
        }
      }
    },

    removeDimension(state, { dimension }) {
      state.changes = true;
      const rack = state.selectedObject;
      for (let i = 0; i < rack.shelves; ++i) {
        for (let j = 0; j < getRackColumns(rack); ++j) {
          if (rack.locations[i][j].dimensions === dimension) {
            rack.locations[i][j].dimensions = state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true);
          }
        }
      }
      state.map.dimensions = state.map.dimensions.filter(x => {
        if (x.default !== undefined && x.default === true) {
          return true;
        }
        return x !== dimension;
      });
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    addDimension(state, { dimension }) {
      state.changes = true;
      state.map.dimensions.push({
        width: dimension.width,
        height: dimension.height,
        depth: dimension.depth,
        color: dimension.color
      });
    },

    changeDimension(state, { dimension, newDimension }) {
      state.changes = true;
      dimension.width = newDimension.width;
      dimension.height = newDimension.height;
      dimension.depth = newDimension.depth;
      dimension.color = newDimension.color;
      state.map.objects.forEach(mapObject => {
        if (mapObject.type === MapObjectType.RACK) {
          mapObject.locations.find(row => row.find(cell => cell.dimensions === dimension) !== undefined) !== undefined ? mapObject.action !== MapObjectAction.CREATE ? mapObject.action = MapObjectAction.UPDATE : null : null;
        }
      });
    },

    setRackSelection(state, { tl, br }) {
      state.selectedRackArea.tl = tl;
      state.selectedRackArea.br = br;
    },

    setNonRackObjectDim(state, { cols, rows }) {
      state.changes = true;
      const obstacle = state.selectedObject;

      if (!(cols < 0 || rows < 0)) {
        obstacle.height = rows;
        obstacle.width = cols;
      }
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    setZoneTypes(state, { types }) {
      state.changes = true;
      state.selectedObject.zoneTypes = types;
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    setPanelMode(state, { mode }) {
      state.panelMode = mode;
    },

    selectObject(state, { object }) {
      state.selectedObject = object;
      state.selectedRackArea.tl = [-1, -1];
      state.selectedRackArea.br = [-1, -1];
    },

    setMapSize(state, { x, y }) {
      state.changes = true;
      state.map.width = x;
      state.map.height = y;
    },
  },

  getters: {

    getMapId: (state) => {
      return state.app.mapId;
    },

    getMapSize: (state) => {
      return {
        width: state.map.width,
        height: state.map.height,
      };
    },

    getMap: (state) => {
      return state.map;
    },

    getDefaultDimension: (state) => {
      return state.defaultDimension;
    },

    getPanelMode: (state) => {
      return state.panelMode;
    },

    isLoading: (state) => {
      return state.app.loading;
    },

    isSaving: (state) => {
      return state.app.saving;
    },

    isChanged: (state) => {
      return state.changes;
    },

    getSelectedObject: (state) => {
      return state.selectedObject;
    },

    getSelectedRackArea: (state) => {
      return state.selectedRackArea;
    },

    isSelectedObjectRack: (state) => {
      if (state.selectedObject !== null) {
        return state.selectedObject.type === MapObjectType.RACK;
      }
      return false;
    },

    doesRackSelectionExist: (state) => {
      return state.selectedRackArea.tl[0] !== -1;
    },

    // Is the map supposed to be shown in the editor?
    showMap: (state) => {
      return [PanelMode.STANDBY, PanelMode.MOVE, PanelMode.NEW_RACK, PanelMode.NEW_OBSTACLE, PanelMode.NEW_ZONE, PanelMode.COPY, PanelMode.NEW_LOCATIONS_GROUP].includes(state.panelMode);
    },

    // Check if an object is inside the map bounds.
    isObjectInsideMap: (state) => (object) => {
      const mapTl = [0, 0];
      const mapBr = [state.map.height - 1, state.map.width - 1];

      const objectTl = object.tl;
      const objectBr = getObjectBrCell(object);

      let res = true;

      if (objectTl[0] < mapTl[0] || objectBr[0] > mapBr[0] || objectTl[1] < mapTl[1] || objectBr[1] > mapBr[1]) {
        res = false;
      }
      return res;
    },

    // If an object can't fit inside a map, try to calculate if map can't be expanded to accommodate
    // object.
    // Map can only be expanded downwards and rightwards
    calculateMapOverhangs: (state) => (object) => {
      const mapTl = [0, 0];
      const mapBr = [state.map.height - 1, state.map.width - 1];
      const objectTl = object.tl;
      const objectBr = getObjectBrCell(object);
      let lowerOverhang = 0;
      let rightOverhang = 0;
      let canExpand = false;

      if (objectBr[0] > mapBr[0] && objectTl[0] >= mapTl[0]) {
        canExpand = true;
        lowerOverhang = objectBr[0] - mapBr[0];
      }
      if (objectBr[1] > mapBr[1] && objectTl[1] >= mapTl[1]) {
        canExpand = true;
        rightOverhang = objectBr[1] > mapBr[1];
      }

      return {
        canExpand: canExpand,
        lowerOverhang: lowerOverhang,
        rightOverhang: rightOverhang
      };
    },

    // Check if a particular object does not collide with any other objects in the map.
    noCollisions: (state) => (object, exclude = null) => {
      let tl = [];
      let br = [];
      const inTl = object.tl;
      const inBr = getObjectBrPoint(object);
      let res = true;

      if (object.type !== MapObjectType.ZONE) {
        state.map.objects.forEach(obj => {
          if (obj !== exclude) {
            tl = obj.tl;
            br = getObjectBrPoint(obj);
            if (tl[1] < inBr[1] && br[1] > inTl[1] &&
                tl[0] < inBr[0] && br[0] > inTl[0]) {
              res = false;
            }
          }
        });
      }
      else {
        state.map.zones.forEach(obj => {
          if (obj !== exclude) {
            tl = obj.tl;
            br = getObjectBrPoint(obj);
            if (tl[1] < inBr[1] && br[1] > inTl[1] &&
                tl[0] < inBr[0] && br[0] > inTl[0]) {
              res = false;
            }
          }
        });
      }

      return res;
    },

    // Check if it's ok to rescale map (if no objects are out of bounds)
    checkMapRescale: (state) => (newWidth, newHeight) => {
      let tl = [];
      let br = [];
      const newMapTl = [0, 0];
      const newMapBr = [newHeight - 1, newWidth - 1];
      let res = true;
      const allMapStuff = [].concat(state.map.objects, state.map.zones);
      allMapStuff.forEach(object => {
        tl = object.tl;
        br = getObjectBrCell(object);
        if (newMapTl[0] > tl[0] || newMapBr[0] < br[0] || newMapTl[1] > tl[1] || newMapBr[1] < br[1]) {
          res = false;
        }
      });
      return res;
    },

    // Transforms data from 'map' so it is renderable in the map view
    render: (state) => {
      // table of nested rows and cells
      const table = [];
      const zones = state.map.zones;

      for (let i = 0; i < state.map.height; ++i) {
        table.push([]);
        for (let j = 0; j < state.map.width; ++j) {
          // the cell object
          table[i].push({
            name: '',
            object: null,
            width: 1,
            height: 1,
            x: j,
            y: i,
            grave: false,   // nahrobek
            zones: false
          });
        }
      }

      zones.forEach(zone => {
        for (let i = 0; i < zone.height; i++) {
          for (let j = 0; j < zone.width; j++) {
            table[zone.tl[0] + i][zone.tl[1] + j].zones = true;
          }
        }
      });

      state.map.objects.forEach(object => {
        const refCell = table[object.tl[0]][object.tl[1]];
        refCell.width = object.width;     // colspan attribute
        refCell.height = object.height;   // rowspan attribute
        refCell.object = object;
        if (object.name !== null) {
          refCell.name = object.name;
        }
        // mark cells to destroy later if the dimensions are greater than 1x1
        if (refCell.width > 1) {
          for (let i = 1; i < refCell.width; ++i) {
            table[object.tl[0]][object.tl[1] + i].grave = true;
            if (table[object.tl[0]][object.tl[1] + i].zones) {
              refCell.zones = true;
            }
          }
        }
        if (refCell.height > 1) {
          for (let i = object.tl[0] + 1; i <= object.tl[0] + refCell.height - 1; ++i) {
            for (let j = 0; j < refCell.width; ++j) {
              table[i][object.tl[1] + j].grave = true;
              if (table[i][object.tl[1] + j].zones) {
                refCell.zones = true;
              }
            }
          }
        }

      });


      // Filter out cells with graves
      for (let i = 0; i < state.map.height; ++i) {
        for (let j = 0; j < state.map.width - 1; ++j) {
          table[i] = table[i].filter(obj => {
            return obj.grave === false;
          });
        }
      }

      return { map: table, zones: state.map.zones };
    },

    renderRack: (state) => {
      if (state.selectedObject === null) {
        return [];
      }

      const table = [];
      const rack = state.selectedObject;

      const rackColumns = getRackColumns(rack);

      for (let i = 0; i < rack.shelves; ++i) {
        table.push([]);
        for (let j = 0; j < rackColumns; ++j) {
          if (rack.locations[i][j].mapLocations.length <= 0) {
            table[i].push({
              names: [
                ''
              ],
              color: '#969696' // Color that represents the dimension - in app, user inputs this in color picker
            });
          }
          else {
            if (rack.locations[i][j].dimensions !== null) {
              table[i].push({
                names: rack.locations[i][j].mapLocations.map(loc => loc.name).sort((code1, code2) => {
                  if (code1.toLowerCase() < code2.toLowerCase())  {
                    return -1;
                  }
                  if (code1.toLowerCase() > code2.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }),
                color: rack.locations[i][j].dimensions.color
              });
            }
            else {
              table[i].push({
                names: rack.locations[i][j].mapLocations.map(loc => loc.name),
                color: '#ffffff' // Color that represents the dimension - in app, user inputs this in color picker
              });
            }
          }
        }
      }
      return table;
    },

    getHeatMap(state){
      return state.heatMap;
    },

    getCellCodes: (state) => (columnCoordinate, shelfCoordinate, amount) => {
      if (amount === 0) {
        return [];
      }

      const rack = state.selectedObject;
      const naming = rack.naming;
      const nrOfColumns = getRackColumns(rack);

      const codes = [];

      const symbols = naming.symbols.map(symbol => {
        if (symbol.namingSymbol === RackNamingSymbols.COLUMN) {
          return {
            namingSymbol: symbol.namingSymbol,
            symbolToDisplay: nextInSequence(symbol.startingSymbol,naming.numberColsFromRight ? nrOfColumns-1-columnCoordinate : columnCoordinate,symbol.startingSymbol)
          };
        }
        else if (symbol.namingSymbol === RackNamingSymbols.SHELF) {
          return {
            namingSymbol: symbol.namingSymbol,
            symbolToDisplay: nextInSequence(symbol.startingSymbol,naming.numberShelvesFromUp ? shelfCoordinate : rack.shelves-1-shelfCoordinate,symbol.startingSymbol)
          };
        }
        else {
          return {
            namingSymbol: symbol.namingSymbol,
            symbolToDisplay: symbol.startingSymbol
          };
        }
      });
      for (let i = 0; i < amount; i++) {
        let code = rack.name;
        for (let sym = 0; sym < 3; sym++) {
          code += naming.separators[sym] === null ? '' : naming.separators[sym];
          if (symbols[sym].namingSymbol === RackNamingSymbols.CELL_COUNT) {
            code += nextInSequence(symbols[sym].symbolToDisplay,i,symbols[sym].symbolToDisplay);
          }
          else {
            code += symbols[sym].symbolToDisplay === null ? '' : symbols[sym].symbolToDisplay;
          }
        }
        codes.push(code);
      }

      return codes;

    },

    // Group information of selected cells in a rack.
    getRackSelectionAttributes: (state) => {
      const cellsAmount = state.selectedRackArea.tl[1]!== -1 ? (state.selectedRackArea.br[1]+1 - state.selectedRackArea.tl[1]) * (state.selectedRackArea.br[0]+1 - state.selectedRackArea.tl[0]) : 0;
      if (state.selectedObject !== null && state.selectedRackArea.tl[0] !== -1) {

        let groupedDimensions = -1;
        let groupedAmount = -1;
        let groupedLocations = [];
        for (let i = state.selectedRackArea.tl[0]; i <= state.selectedRackArea.br[0]; ++i) {
          for (let j = state.selectedRackArea.tl[1]; j <= state.selectedRackArea.br[1]; ++j) {
            if (groupedDimensions === -1) {
              groupedDimensions = state.selectedObject.locations[i][j].dimensions;
              groupedAmount = state.selectedObject.locations[i][j].mapLocations.length;
              groupedLocations = groupedLocations.concat(state.selectedObject.locations[i][j].mapLocations);
            } else {
              if (groupedDimensions !== state.selectedObject.locations[i][j].dimensions) {
                groupedDimensions = -2; // mixed info
              }
              if (groupedAmount !== state.selectedObject.locations[i][j].mapLocations.length) {
                groupedAmount = -2;
              }
            }
          }
        }
        return {
          dimensions: groupedDimensions,
          amount: groupedAmount,
          cellsAmount: cellsAmount,
          tl: [state.selectedRackArea.tl[0],state.selectedRackArea.tl[1]],
          locations: groupedLocations
        };
      }
      return {
        dimensions: null,
        amount: null,
        cellsAmount: cellsAmount,
        tl: [state.selectedRackArea.tl[0],state.selectedRackArea.tl[1]],
        locations: null
      };
    },

    getLocationMappingForName: (state) => (name) => {
      return state.stockLocationMapping.find(slot => slot.name === name);
    },

    getMappedStockLocations: (state) => {
      const locations = [];
      state.stockLocationMapping.forEach(slot => {
        slot.locations.forEach(loc => {
          if (loc.stockLocationId !== null && loc.mappedAmount>0) {
            locations.push({
              name: slot.name,
              stockLocationId: loc.stockLocationId,
              code: loc.code,
              mappedAmount: loc.mappedAmount
            });
          }
        });
      });
      return locations;
    },

    getNotMappedStockLocations: (state) => {
      const locations = [];
      state.stockLocationMapping.forEach(slot => {
        slot.locations.forEach(loc => {
          if (loc.stockLocationId !== null && loc.mappedAmount===0) {
            locations.push({
              name: slot.name,
              stockLocationId: loc.stockLocationId,
              code: loc.code,
              mappedAmount: loc.mappedAmount
            });
          }
        });
      });
      return locations;
    },

    getStockLocationsToCreate: (state) => {
      const locations = [];
      state.stockLocationMapping.forEach(slot => {
        slot.locations.forEach(loc => {
          if (loc.stockLocationId === null) {
            locations.push({
              name: slot.name,
              stockLocationId: loc.stockLocationId,
              code: loc.code,
              mappedAmount: loc.mappedAmount
            });
          }
        });
      });
      return locations;
    },

    getDuplicitMapLocations: (state) => {
      const locations = [];
      state.stockLocationMapping.forEach(slot => {
        slot.locations.forEach(loc => {
          if (loc.mappedAmount>1) {
            locations.push({
              name: slot.name,
              stockLocationId: loc.stockLocationId,
              code: loc.code,
              mappedAmount: loc.mappedAmount
            });
          }
        });
      });
      return locations;
    },

    getObjects: (state) => {
      return state.map.objects;
    },

    getZones: (state) => {
      return state.map.zones;
    },

    getDimensions: (state) => {
      return state.map.dimensions;
    },

  },

  actions: {

    async mapToStockLocation({state}, {name, stockLocationId = undefined}){
      let slot = state.stockLocationMapping.find(loc => loc.name === name);

      if (slot === undefined) {
        state.stockLocationMapping.push({
          name: name,
          locations: []
        });
      }

      slot = state.stockLocationMapping.find(loc => loc.name === name);

      let added = false;
      let code = null;
      slot.locations.forEach(slotLoc => {
        if (!added && (slotLoc.stockLocationId === stockLocationId || (stockLocationId === undefined && (slotLoc.mappedAmount === 0 || slotLoc.stockLocationId === null)))) {
          stockLocationId = slotLoc.stockLocationId;
          slotLoc.mappedAmount++;
          added = true;
          code = slotLoc.code;
        }
      });
      if (stockLocationId === undefined && !added && slot.locations.length!==0) {
        const slotLoc = slot.locations[slot.locations.length-1];
        stockLocationId = slotLoc.stockLocationId;
        slotLoc.mappedAmount++;
        added = true;
        code = slotLoc.code;
      }
      if (!added) {
        stockLocationId = null;
        slot.locations.push({
          code: null,
          stockLocationId: stockLocationId,
          mappedAmount: 1
        });
      }
      return {
        code: code,
        stockLocationId: stockLocationId
      };

    },

    setRackColumns({ state, dispatch }, { columns }) {
      dispatch('setRackDim', {
        cols: columns,
        shelves: state.selectedObject.shelves
      });
    },

    setNonRackObjectColumns({ state, commit }, { columns }) {
      commit('setNonRackObjectDim', {
        cols: columns,
        rows: state.selectedObject.height
      });
    },

    setNonRackObjectRows({ state, commit }, { rows }) {
      commit('setNonRackObjectDim', {
        cols: state.selectedObject.width,
        rows: rows
      });
    },

    renameObject({ state, commit, dispatch }, { name }) {
      state.changes = true;
      const oldName = state.selectedObject.name;
      state.selectedObject.name = name;
      if (state.selectedObject.type === MapObjectType.RACK) {
        state.selectedObject.locations.forEach( (shelf) => {
          shelf.forEach( (column) => {
            column.mapLocations.forEach(mapLoc => {
              if (mapLoc.name.startsWith(oldName)) {
                commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId});
                mapLoc.stockLocationId = null;
                mapLoc.name = name + mapLoc.name.substring(oldName.length);
                dispatch('mapToStockLocation',{name: mapLoc.name}).then(response => {
                  mapLoc.stockLocationId = response.stockLocationId;
                  mapLoc.code = response.code;
                });
              }
            });
          });
        });
      }
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    createNewLocationGroup({state, dispatch}, { tl, locations }){
      state.changes = true;
      const locationsWithStockLoc = locations.map( loc => {
        const newLocation = {name: loc.name, code: null, stockLocationId: null};
        dispatch('mapToStockLocation',{name: loc.name, stockLocationId: loc.stockLocationId}).then(response => {
          newLocation.stockLocationId = response.stockLocationId;
          newLocation.code = response.code;
        });
        return newLocation;
      });
      state.blockUniqueId++;
      const LocGroupLen = state.map.objects.push({
        action: MapObjectAction.CREATE,
        id: state.blockUniqueId,
        type: MapObjectType.LOCATIONS_GROUP,
        width: 1,
        height: 1,
        vertical: false,
        tl: tl,
        locations: locationsWithStockLoc
      });
      state.selectedObject = state.map.objects[LocGroupLen - 1];
    },

    addNewLocationToLocationGroup({state, dispatch}, { name, stockLocationId }){
      state.changes = true;
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
      const newLocation = {name: name, code: null, stockLocationId: null};
      dispatch('mapToStockLocation',{name: name, stockLocationId: stockLocationId}).then(response => {
        newLocation.stockLocationId = response.stockLocationId;
        newLocation.code = response.code;
      });
      state.selectedObject.locations.push(newLocation);
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    removeLocationFromLocationGroup({state, commit}, {name, stockLocationId}) {
      state.changes = true;
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
      commit('unmapFromStockLocation',{name: name, stockLocationId: stockLocationId});
      let removed = false;
      state.selectedObject.locations = state.selectedObject.locations.filter(location => {
        if((name === location.name && stockLocationId === location.stockLocationId) && !removed){
          removed = true;
          return false;
        }
        return true;
      });
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    // creates new 1x1 rack
    createNewRack( { state }, { tl, name }) {
      state.changes = true;
      state.blockUniqueId++;
      const rackLen = state.map.objects.push({
        action: MapObjectAction.CREATE,
        id: state.blockUniqueId,
        type: MapObjectType.RACK,
        width: 1,
        height: 1,
        shelves: 1,
        name: name,
        vertical: false,
        tl: tl,
        naming: {
          numberShelvesFromUp: false,
          numberColsFromRight: false,
          symbols: [
            {
              namingSymbol: RackNamingSymbols.COLUMN,
              startingSymbol: '1',
            },
            {
              namingSymbol: RackNamingSymbols.SHELF,
              startingSymbol: '1',
            },
            {
              namingSymbol: RackNamingSymbols.CELL_COUNT,
              startingSymbol: '1',
            },
          ],
          separators: ['-', '-', '-']
        },
        locations: [
          [
            {
              dimensions: state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true),
              mapLocations: []
            }
          ],
        ]
      });
      state.selectedObject = state.map.objects[rackLen - 1];
    },

    addNewLocationToSelectedRackCell({ state, dispatch }, { name, stockLocationId}){
      if (state.selectedObject !== null && state.selectedRackArea !== null) {
        state.changes = true;
        state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
        if (state.selectedObject.locations[state.selectedRackArea.tl[0]][state.selectedRackArea.tl[1]].dimensions === null) {
          state.selectedObject.locations[state.selectedRackArea.tl[0]][state.selectedRackArea.tl[1]].dimensions = state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true);
        }
        dispatch('mapToStockLocation',{name: name, stockLocationId: stockLocationId})
            .then(response => {
              state.selectedObject.locations[state.selectedRackArea.tl[0]][state.selectedRackArea.tl[1]].mapLocations.push({name: name, code: response.code, stockLocationId: response.stockLocationId});
            });
      }
    },

    removeLocationFromSelectedRackCell({ state, commit }, { name, stockLocationId }) {
      if (state.selectedObject !== null && state.selectedRackArea !== null) {
        state.changes = true;
        state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
        commit('unmapFromStockLocation',{name: name, stockLocationId: stockLocationId});
        let removed = false;
        state.selectedObject.locations[state.selectedRackArea.tl[0]][state.selectedRackArea.tl[1]].mapLocations = state.selectedObject.locations[state.selectedRackArea.tl[0]][state.selectedRackArea.tl[1]].mapLocations.filter(location => {
          if((name === location.name && stockLocationId === location.stockLocationId) && !removed){
            removed = true;
            return false;
          }
          return true;
        });
      }
    },

    setLocationAmountInSelection({ state, commit, dispatch, getters }, { amount }) {
      state.changes = true;
      if (state.selectedObject !== null) {
        for (let i = state.selectedRackArea.tl[0]; i <= state.selectedRackArea.br[0]; ++i) {
          for (let j = state.selectedRackArea.tl[1]; j <= state.selectedRackArea.br[1]; ++j) {

            let currentAmount = state.selectedObject.locations[i][j].mapLocations.length;

            if (currentAmount !== amount) {
              state.selectedObject.locations[i][j].mapLocations.forEach(mapLoc => commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId}));
              if (state.selectedObject.locations[i][j].dimensions === null) {
                state.selectedObject.locations[i][j].dimensions = state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true);
              }


              if (currentAmount > amount) {
                state.selectedObject.locations[i][j].mapLocations = state.selectedObject.locations[i][j].mapLocations.sort((loc1, loc2) => {
                  const code1 = loc1.name.toLowerCase();
                  const code2 = loc2.name.toLowerCase();
                  if (code1 < code2) {
                    return -1;
                  }
                  if (code1 > code2) {
                    return 1;
                  }
                  return 0;
                }).slice(0, amount-currentAmount);
              }

              else if (currentAmount < amount) {

                if (currentAmount === 1) {
                  const codeFirstSpecial = getters.getCellCodes(j,i,1)[0];
                  const specLoc = state.selectedObject.locations[i][j].mapLocations.find(mapLoc => mapLoc.name === codeFirstSpecial);
                  if (specLoc) {
                    state.selectedObject.locations[i][j].mapLocations.find(mapLoc => mapLoc.name === codeFirstSpecial).name = getters.getCellCodes(j,i,2)[0];
                  }

                }

                const generatedCodes = getters.getCellCodes(j,i,amount);

                generatedCodes.every(name => {
                  if (currentAmount === amount) {
                    return false;
                  }
                  if (!state.selectedObject.locations[i][j].mapLocations.filter(loc => loc.name === name).length > 0) {
                    state.selectedObject.locations[i][j].mapLocations.push({name: name, code: null, stockLocationId: null});
                    currentAmount++;
                  }
                  return true;
                });

                while (currentAmount < amount) {
                  state.selectedObject.locations[i][j].mapLocations.push({name: generatedCodes[currentAmount], code: null, stockLocationId: null});
                  currentAmount++;
                }
              }

              state.selectedObject.locations[i][j].mapLocations.forEach(mapLoc => dispatch('mapToStockLocation',{name: mapLoc.name}).then(response => {
                mapLoc.stockLocationId = response.stockLocationId;
                mapLoc.code = response.code;
              }));
              state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
            }
          }
        }
      }
    },

    setNaming({ state, commit, dispatch, getters}, { numberShelvesFromUp, numberColsFromRight, firstNamingSymbol, firstStartingSymbol, secondNamingSymbol, secondStartingSymbol, thirdNamingSymbol, thirdStartingSymbol, firstNamingSeparator, secondNamingSeparator, thirdNamingSeparator }) {
      const sort = (name1, name2) => {
        if (name1.toLowerCase() < name2.toLowerCase()) {
          return -1;
        }
        if (name1.toLowerCase() > name2.toLowerCase()) {
          return 1;
        }
        return 0;
      };

      const oldGeneratedNames = [];
      state.selectedObject.locations.forEach( (shelf, shelfIndex) => {
        const namesRow = [];
        shelf.forEach( (column, columnIndex) => {
          namesRow.push(getters.getCellCodes(columnIndex,shelfIndex,column.mapLocations.length).sort(sort));
        });
        oldGeneratedNames.push(namesRow);
      });

      const naming = state.selectedObject.naming;

      naming.numberShelvesFromUp = numberShelvesFromUp;
      naming.numberColsFromRight = numberColsFromRight;
      naming.symbols[0].namingSymbol = firstNamingSymbol;
      naming.symbols[0].startingSymbol = firstStartingSymbol;
      naming.symbols[1].namingSymbol = secondNamingSymbol;
      naming.symbols[1].startingSymbol = secondStartingSymbol;
      naming.symbols[2].namingSymbol = thirdNamingSymbol;
      naming.symbols[2].startingSymbol = thirdStartingSymbol;
      naming.separators[0] = firstNamingSeparator;
      naming.separators[1] = secondNamingSeparator;
      naming.separators[2] = thirdNamingSeparator;

      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
      state.changes = true;
      state.selectedObject.locations.forEach( (shelf, shelfIndex) => {
        shelf.forEach( (column, columnIndex) => {
          const names = getters.getCellCodes(columnIndex,shelfIndex,column.mapLocations.length).sort(sort);
          column.mapLocations.forEach(mapLoc => {
            const nameIndex = oldGeneratedNames[shelfIndex][columnIndex].findIndex(name => name === mapLoc.name);
            if (nameIndex !== -1) {
              commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId});
              mapLoc.stockLocationId = null;
              mapLoc.name = names[nameIndex];
              dispatch('mapToStockLocation',{name: mapLoc.name}).then(response => {
                mapLoc.stockLocationId = response.stockLocationId;
                mapLoc.code = response.code;
              });
            }
          });
        });
      });
    },

    // Alter the location table of a rack after you change its dimensions (either amount of columns and shelves)
    setRackDim( { state, commit }, { cols, shelves }) {
      state.changes = true;
      const rack = state.selectedObject;

      const columns = getRackColumns(rack);

      // Decreasing columns
      if (cols < columns) {
        const delta = columns - cols;
        state.selectedObject.locations.forEach(row => {
          for (let i = 0; i < delta; ++i) {
            if (rack.naming.numberColsFromRight) {
              row.reverse();
            }
            row.pop().mapLocations.forEach(mapLoc => commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId}));
            if (rack.naming.numberColsFromRight) {
              row.reverse();
            }
          }
        });
        // Increasing columns
      } else if (cols > columns) {
        const delta = cols - columns;
        state.selectedObject.locations.forEach((row) => {
          for (let i = 0; i < delta; ++i) {
            if (rack.naming.numberColsFromRight) {
              row.reverse();
            }
            row.push({
              dimensions: state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true), //TODO
              mapLocations: []
            });
            if (rack.naming.numberColsFromRight) {
              row.reverse();
            }
          }
        });
      }

      // Decreasing shelves
      if (shelves < rack.shelves) {
        const delta = rack.shelves - shelves;
        for (let i = 0; i < delta; ++i) {
          if (!rack.naming.numberShelvesFromUp) {
            rack.locations.reverse();
          }
          rack.locations.pop().forEach(cell => cell.mapLocations.forEach(mapLoc => commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId})));
          if (!rack.naming.numberShelvesFromUp) {
            rack.locations.reverse();
          }
        }
        // Increasing shelves
      } else if (shelves > rack.shelves) {
        const delta = shelves - rack.shelves;
        for (let i = 0; i < delta; ++i) {
          const newRow = [];
          for (let j = 0; j < columns; j++) {
            newRow.push({
              dimensions: state.map.dimensions.find(dim => dim.default !== undefined && dim.default === true), //TODO
              mapLocations: []
            });
          }
          if (!rack.naming.numberShelvesFromUp) {
            rack.locations.reverse();
          }
          rack.locations.push(newRow);
          if (!rack.naming.numberShelvesFromUp) {
            rack.locations.reverse();
          }
        }
      }

      // Set the new values
      if (rack.vertical) {
        rack.height = cols;
      } else {
        rack.width = cols;
      }
      rack.shelves = shelves;

      if (rack.shelves - 1 < state.selectedRackArea.br[0]) {
        state.selectedRackArea.br[0] = rack.shelves - 1;
      }
      if (rack.cols - 1 < state.selectedRackArea.br[1]) {
        state.selectedRackArea.br[1] = rack.cols - 1;
      }
      state.selectedObject.action !== MapObjectAction.CREATE ? state.selectedObject.action = MapObjectAction.UPDATE : null;
    },

    copyObject({ state, getters, commit, dispatch }, { object, newTl }) {
      let oldName;
      state.changes = true;
      let newObject = {
        tl: newTl,
        width: object.width,
        height: object.height,
        type: object.type
      };

      if (getters.noCollisions(newObject)
          && getters.isObjectInsideMap(newObject)) {
        newObject = cloneDeep(object, 2);
        if (object.type === MapObjectType.RACK) {
          oldName = object.name;
          newObject.name = incrementLast(object.name);
        } else {
          newObject.name = object.name;
        }
        newObject.tl = newTl;

        if (object.type === MapObjectType.ZONE) {
          state.map.zones.push(newObject);
        }
        else{
          state.map.objects.push(newObject);
        }
        commit('selectObject', {
          object: newObject
        });
        state.selectedObject.action = MapObjectAction.CREATE;
        if (state.selectedObject.type === MapObjectType.RACK) {
          state.selectedObject.locations.forEach( (shelf) => {
            shelf.forEach( (column) => {
              column.mapLocations.forEach(mapLoc => {
                mapLoc.stockLocationId = null;
                if (mapLoc.name.startsWith(oldName)) {
                  mapLoc.name = state.selectedObject.name + mapLoc.name.substring(oldName.length);
                }
                else {
                  mapLoc.name = state.selectedObject.name + mapLoc.name;
                }
                dispatch('mapToStockLocation',{name: mapLoc.name}).then(response => {
                  mapLoc.stockLocationId = response.stockLocationId;
                  mapLoc.code = response.code;
                });
              });
            });
          });
        }
        else if (state.selectedObject.type === MapObjectType.LOCATIONS_GROUP) {
          state.selectedObject.locations.forEach(mapLoc => dispatch('mapToStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId}).then(response => {
            mapLoc.stockLocationId = response.stockLocationId;
            mapLoc.code = response.code;
          }));
        }
      }

    },

    moveObject({ getters, commit, state }, { object, newTl }) {
      state.changes = true;

      const movedObject = {
        tl: newTl,
        width: object.width,
        height: object.height,
        type: object.type
      };
      if (getters.noCollisions(movedObject, object)
          && getters.isObjectInsideMap(movedObject)) {
        object.tl = newTl;
        commit('setPanelMode', {
          mode: PanelMode.STANDBY
        });
      }
      object.action !== MapObjectAction.CREATE ? object.action = MapObjectAction.UPDATE : null;

    },

    rotateObject({ getters, commit, state }, { object }) {
      state.changes = true;
      const rotatedObject = {
        tl: object.tl,
        height: object.width,
        width: object.height,
        type: object.type
      };
      if (getters.noCollisions(rotatedObject, object)) {
        // newly rotated rack can't fit inside current map
        if (!getters.isObjectInsideMap(rotatedObject)) {
          const overhangs = getters.calculateMapOverhangs(rotatedObject);
          // but we can expand the map to fit it
          if (overhangs.canExpand) {
            commit('setMapSize', {
              x: state.map.width + overhangs.rightOverhang,
              y: state.map.height + overhangs.lowerOverhang,
            });
          } else {
            return;
          }
        }
        const backupHeight = object.height;
        object.height = object.width;
        object.width = backupHeight;
        if ('vertical' in object) {
          object.vertical = !object.vertical;
        }
        commit('setPanelMode', {
          mode: PanelMode.STANDBY
        });
      }
      object.action !== MapObjectAction.CREATE ? object.action = MapObjectAction.UPDATE : null;
    },

    deleteObject({state, commit}) {
      state.changes = true;
      if(state.selectedObject.type === MapObjectType.RACK) {
        state.selectedObject.locations.forEach( row => row.forEach( cell => cell.mapLocations.forEach(mapLoc => commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId}))));
      }
      else if (state.selectedObject.type === MapObjectType.LOCATIONS_GROUP) {
        state.selectedObject.locations.forEach(mapLoc => commit('unmapFromStockLocation',{name: mapLoc.name, stockLocationId: mapLoc.stockLocationId}));
      }
      if (state.selectedObject.type === MapObjectType.ZONE) {
        state.map.zones = state.map.zones.filter(x => {
          return x !== state.selectedObject;
        });
      }
      else {
        state.map.objects = state.map.objects.filter(x => {
          return x !== state.selectedObject;
        });
      }
      if (state.selectedObject.action !== MapObjectAction.CREATE) {
        state.map.deletedObjectsAndZones.push({type: state.selectedObject.type, id: state.selectedObject.id});
      }
      state.selectedObject = null;
    }
  }
};

export default storageMapModule;
