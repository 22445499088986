<template>
  <v-list
    :class="{'ml-3': !first}"
  >
    <v-list-item
      v-if="first && currentColumn.templateCode !== ''"
      @click="unmap"
    >
      <v-container class="pa-0 text-center">
        <v-icon
          color="error"
        >
          $closeItem
        </v-icon>
        {{ $t('imports.config.unmap') }}
      </v-container>
    </v-list-item>
    <v-text-field
      v-if="domain.is_dynamic"
      v-model="filter"
      class="px-4"
      prepend-icon="$search"
      @click.stop
    />
    <template
      v-for="header in filteredList"
    >
      <v-list-group
        v-if="header.columns"
        :key="header.name"
        :value="findCodeInGroup(header.columns)"
        @click.stop
      >
        <template #activator>
          <v-list-item-title :class="{'red--text':checkRequiredGroup(header)}">
            {{ domainService.getTranslation(header.name, domainName) }}
          </v-list-item-title>
        </template>
        <DomainList
          :column="currentColumn"
          :domain="header"
          :domain-name="domainName"
          :reload-trigger="reloadTrigger"
          :required-attributes="requiredAttributes"
          @updateHeader="updateHeader"
          @valid="data => $emit('valid', data)"
        />
      </v-list-group>
      <v-list-item
        v-else
        :key="header.name"
        :class="{'primary': currentColumn.templateCode === header.code}"
        :disabled="header.is_used && currentColumn.templateCode !== header.code"
        @click="changeHeaderColumn(header.code)"
      >
        <v-list-item-title>
          <div
            :class="{
              'white--text': currentColumn.templateCode === header.code,
              'red--text' : currentColumn.templateCode !== header.code && checkRequired(header),
            }"
          >
            {{ !domain.is_dynamic ? domainService.getTranslation(header.name, domainName) : header.name }}
            {{ checkRequired(header) ? '*' : '' }}
          </div>
        </v-list-item-title>
        <v-tooltip
          v-if="currentColumn.templateCode === header.code && !domain.is_dynamic"
          top
        >
          <template #activator="{on,attr}">
            <div
              v-bind="attr"
              v-on="on"
              @click="toggleHeaderPrimary"
              @click.stop
            >
              <v-icon
                v-if="currentColumn.is_primary"
                color="accent"
              >
                $primaryKey
              </v-icon>
              <v-icon
                v-else
                color="accent"
              >
                $primaryKeyOff
              </v-icon>
            </div>
          </template>
          <v-container v-if="!currentColumn.is_primary">
            {{ $t('imports.config.primary_set') }}
          </v-container>
          <v-container v-else>
            {{ $t('imports.config.primary_unset') }}
          </v-container>
        </v-tooltip>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
    import * as DomainService from "@/enum/domains";

    export default {
        name: "DomainList",
        props: {
            domain: {
                type: Object,
                default: null,
            },
            column: {
                type: Object,
                default: null,
            },
            reloadTrigger: {
                type: Number,
                default: 0,
            },
            requiredAttributes: {
                type: Array,
                default: null
            },
            domainName: {
                type: String,
                default: ''
            },
            first: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            currentColumn: {},
            sortedList: [],
            domainService: DomainService,
            subGroupRequired: false,
            filter: '',
        }),
        computed: {
            filteredList() {
                return this.sortedList.filter(item => item.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));
            },
        },
        watch: {
            reloadTrigger() {
                this.updateList();
            }
        },
        createdOrActivated() {
            this.currentColumn = this.column;
            this.updateList();
        },
        methods: {
            updateList() {
                const returnArr = [...this.domain.columns];
                this.sortedList = returnArr.sort((a, b) => {
                    if (a.columns || b.columns) {
                        return !b.columns - !a.columns;
                    } else if (this.currentColumn.templateCode === a.code || this.currentColumn.templateCode === b.code) {
                        return (this.currentColumn.templateCode === b.code) - (this.currentColumn.templateCode === a.code);
                    } else {
                        return !b.is_used - !a.is_used;
                    }
                });
                this.sortedList.map(item => item.title = DomainService.getTranslation(item.name, this.domainName));
                this.$emit('valid', {
                    group: this.domain.name,
                    valid: !this.hasUsed() ? true : this.hasPrimary(),
                });
            },
            updateHeader(column) {
                if (column === 0) {
                    this.$emit('updateHeader', this.currentColumn);
                } else {
                    this.$emit('updateHeader', column);
                }
            },
            changeHeaderColumn(code) {
                if (this.currentColumn.templateCode !== code) {
                    this.currentColumn.templateCode = code;
                    if (!this.hasPrimary() && this.domain.is_primary_column_required) {
                        this.currentColumn.is_primary = true;
                    }
                    if (this.domain.is_dynamic) {
                        this.currentColumn.is_primary = false;
                    }
                    this.updateHeader(0);
                } else if (!this.domain.is_dynamic) {
                    this.toggleHeaderPrimary();
                }
            },
            toggleHeaderPrimary() {
                this.currentColumn.is_primary = !this.currentColumn.is_primary;
                this.updateHeader(0);
            },
            findCodeInGroup(group) {
                if (group.filter(item => !item['columns']).some(item => item.code === this.currentColumn.templateCode)) {
                    return true;
                }
                return group.filter(item => item['columns']).some(group => this.findCodeInGroup(group.columns));
            },
            checkRequired(header) {
                return this.requiredAttributes.find(item => header.code === item.code) && !header.is_used;
            },
            checkRequiredGroup(group) {
                if (group.columns.some(item => this.checkRequired(item)) || this.checkRequired(group)) {
                    return true;
                } else {
                    return group.columns.filter(item => item.columns).some(item => this.checkRequiredGroup(item));
                }
            },
            unmap() {
                this.currentColumn.templateCode = '';
                this.currentColumn.is_primary = false;
                this.updateHeader(0);
            },
            hasPrimary() {
                return this.domain.columns.some(item => item.is_primary);
            },
            hasUsed() {
                return this.domain.columns.some(item => item.is_used);
            },
        }
    };
</script>
