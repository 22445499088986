import {measurementUnits} from "@/enum/measurement_unit";

const measurementUnit = {
    name: 'Одиниця виміру',
    [measurementUnits.PIECE]: "Штука",
    [measurementUnits.CARTON]: "Картон",
    [measurementUnits.HECTOLITER]: "Гектолітр",
    [measurementUnits.LITER]: "Літр",
    [measurementUnits.DECILITER]: "Децилітр",
    [measurementUnits.CENTILITER]: "Сантилітр",
    [measurementUnits.MILLILITER]: "Мілілітр",
    [measurementUnits.TONNE]: "Тонна",
    [measurementUnits.KILOGRAM]: "Кілограм",
    [measurementUnits.DECAGRAM]: "Дециграм",
    [measurementUnits.GRAM]: "Грам",
    [measurementUnits.MILLIGRAM]: "Міліграм",
    [measurementUnits.KILOMETER]: "Кілометр",
    [measurementUnits.METER]: "Метр",
    [measurementUnits.CENTIMETER]: "Сантиметр",
    [measurementUnits.MILLIMETER]: "Міліметр",
    [measurementUnits.SQUARE_METER]: "Квадратний метр",
    [measurementUnits.CUBIC_METER]: "Кубічний метр",

    [measurementUnits.MILE]: "Миля",
    [measurementUnits.INCH]: "Дюйм",
    [measurementUnits.POUND]: "Фунт",
    [measurementUnits.OUNCE]: "Унція",
    [measurementUnits.US_GALLON]: "Американський галон",
    [measurementUnits.UK_GALLON]: "Британський галон",
    [measurementUnits.SQUARE_FOOT]: "Квадратна фута",
    [measurementUnits.CUBIC_FOOT]: "Кубічна фута"
};

export default {
    title: "Складські товари",
    titleSingle: "Складський товар",
    link: "Складські товари",

    name: "Назва",
    description: "Опис",
    model: "Модель",
    manufacturer_id: "Виробник",
    type_id: "Тип",
    manufacturer: {
        id: "Виробник",
        label: "Виробник",
        name: "Виробник"
    },
    type: {
        id: "Тип",
        label: "Тип"
    },
    measure_unit: "Одиниця виміру",
    measurementUnit: measurementUnit,
    visible: "Видимий",
    notVisible: "Прихований",
    deleted: "Видалено",
    can_have_batch: "Може мати партію",
    can_have_serial_number: "Може мати серійний номер",
    created_at: "Створено",
    suggested_buy_price: "Стандартна закупівельна ціна (без ПДВ)",
    suggested_sell_price: "Рекомендована роздрібна ціна (без ПДВ)",
    buy_price: "Закупівельна ціна за одиницю",
    sell_price: "Роздрібна ціна за одиницю",
    price_vat: "Ставка ПДВ",
    vat_included: "з ПДВ",
    unknown: "<Невідомо>",
    tableImage: "",
    instance: "Партія / Серія",
    quantity: "Кількість",
    searchHint: "Шукає за назвою, моделлю, виробником та одиницею виміру",

    attributeAlreadyEntered: "Рекомендований атрибут вже існує!",
    unableToRemoveLastAttribute: "Неможливо видалити останній атрибут!",

    attributes: {
        name: "Атрибути",
        key: "Назва",
        value: "Значення",
        description: "Опис",
        existing_attributes: "Існуючі Aтрибути",
        unableToLoad: "Неможливо завантажити список атрибутів складської позиції. Будь ласка, спробуйте ще раз пізніше.",
        hint: "Атрибути з незаповненими значеннями не зберігатимуться",
        create: {
            titleSimple: "Додати атрибут",
            title: "Додати атрибут до '{0}'",
            done: "Додано '{0}' до '{1}'"
        },
        update: {
            titleSimple: "Оновити атрибут",
            title: "Оновити '{0}' у '{1}'",
            done: "Оновлено '{0}' у '{1}'"
        },
        delete: {
            done: "Атрибут '{0}' видалено.",
            failed: "Не можливо видалити атрибут '{0}'. Будь ласка, спробуйте ще раз пізніше."
        },
        status: {
            404: "Складський товар або назва атрибуту не знайдені!",
            409: "Вказана назва атрибуту вже існує!"
        }
    },

    overview: {
        name: "Огляд товару",
        inStock: "В наявності",
        notInStock: "Цього товару немає на складі",
        InTasks: "У завданнях",
        notInTasks: "Цей товар не блокований в жодних завданнях",
        Ordered: "Замовлено",
        notOrdered: "Немає замовлень для цієї позиції",
        altogetherPieces: "{0} шт.",
    },

    types: {
        link: "Типи складських товарів",
        title: "Типи складських товарів",
        titleSingle: "Тип складського товару",
        name: "Назва",
        default: "<за замовчуванням>",
        suggestedAttributes: {
            name: "Рекомендовані атрибути",
            key: "Назва",
            description: "Опис",
            hint: "Є типовим"
        },

        removeAttribute: "Видалити атрибут",

        create: {
            label: "Створити новий тип складського товару",
            title: "Новий тип складського товару",
            done: "Тип складського товару '{0}' створено"
        },
        update: {
            titleSimple: "Оновити тип складського товару",
            title: "Оновити '{0}'",
            done: "Тип складського товару '{0}' оновлено"
        },
        delete: {
            done: "Тип складського товару '{0}' видалено.",
            failed: "Неможливо видалити тип складського товару '{0}'. Будь ласка, спробуйте пізніше."
        },

        status: {
            404: "Тип складського товару не знайдено!",
            409: "Неможливо видалити тип складського товару, оскільки на складі є позиція цього типу!"
        }
    },

    batches: {
        name: "Партії",
        code: "Код",
        manufactured_date: "Дата виробництва",
        manufactured_time: "Час виробництва",
        expire_date: "Дата закінчення терміну придатності",
        expire_time: "Час закінчення терміну придатності",
        create: {
            titleSimple: "Додати партію",
            title: "Додати партію до '{0}'",
            done: "Додано '{0}' до '{1}'"
        },
        update: {
            titleSimple: "Оновити партію",
            title: "Оновити партію '{0}' у '{1}'",
            done: "Оновлено партію '{0}' у '{1}'"
        },
        delete: {
            done: "Партія '{0}' видалена.",
            failed: "Неможливо видалити партію '{0}'. Будь ласка, спробуйте пізніше.",
            status: {
                404: "Складську картку або код партії не знайдено!",
                409: "Код партії неможливо видалити через його зв'язки з іншими об'єктами!"
            }
        },
        status: {
            404: "Складську картку або код партії не знайдено!",
            409: "Вказаний код партії вже існує!"
        }
    },

    customInstances: {
        name: "Спеціальні екземпляри",
        hint: "Якщо вам потрібно розрізняти стан, зношеність предмета та керувати його кодами.",
        product_instance_type_id: "Тип",
        create: {
            titleSimple: "Додати спеціальний екземпляр",
            label: "Створити новий конкретний предмет",
            title: "Додати спеціальний екземпляр до '{0}'",
            done: "Додано екземпляр типу '{0}' до '{1}'"
        },
        update: {
            titleSimple: "Оновити спеціальний екземпляр",
            title: "Оновити екземпляр типу '{0}' у '{1}'",
            done: "Оновлено екземпляр типу '{0}' у '{1}'",
            status: {
                404: "Складську картку або екземпляр не знайдено!",
                409: "Тип екземпляра не може бути змінений через зв'язки екземпляра з іншими об'єктами!"
            }
        },
        delete: {
            titleSimple: "видалити користувацький екземпляр",
            title: "Видалити екземпляр типу '{0}' за адресою '{1}'",
            done: "Видалено екземпляр типу '{0}' за адресою '{1}'",
            status: {
                404: "Складську картку або екземпляр не знайдено!",
                409: "Тип екземпляра не може бути змінений через зв'язки екземпляра з іншими об'єктами!"
            }
        },
        status: {
            404: "Складську картку не знайдено!",
            409: "Невідомий або недійсний тип екземпляра!"
        }
    },

    images: {
        alt: "Зображення, призначене для предмету",
        altCreate: "Зображення, призначене для предмету",
        unableToLoad: "Не вдалося завантажити зображення складського товару. Будь ласка, спробуйте пізніше.",
        store_external_images: 'Збереження зовнішніх зображень',
        status: {
            404: "Складську картку або зображення не знайдено!"
        }
    },

    instances: {
        name: "Екземпляри основного товару",
        type: {
            name: "Тип",
            NORMAL: "Звичайний",
            SERIAL_NUMBER: "Серійний номер",
            BATCH: "Партія"
        },
        manufactured_date: "Дата виробництва",
        expire_date: "Термін придатності",
        serial_number: "Серійний номер",
        batch_code: "Код партії",
        serial: "Серія: {0}",
        batch: "Партія: {0}",


        types: {
            abel: "Спеціальні типи екземплярів",
            name: "Назва",

            create: {
                title: "Новий спеціальний тип екземпляра",
                label: "Створити новий спеціальний тип екземпляра",
                done: "Спеціальний тип екземпляра '{0}' додано"
            },
            update: {
                title: "Оновити спеціальний тип екземпляра '{0}'",
                titleSimple: "Оновити спеціальний тип екземпляра",
                done: "Спеціальний тип екземпляра '{0}' оновлено"
            },
            delete: {
                done: "Спеціальний тип екземпляра '{0}' видалено!",
                status: {
                    404: "Спеціальний тип екземпляра не знайдено!",
                    409: "На складі існують екземпляри даного типу!"
                }
            },
            status: {
                404: "Спеціальний тип екземпляра не знайдено!",
                409: "Спеціальний тип з даним ім'ям вже існує!"
            }
        },

        barcodes: {
            link: "Деталі",
            title: "Штрих-коди товару '{0}'",
            titleSimple: "Штрих-коди",
            name: "Штрих-код",
            code: "Код",
            image: "Попередній перегляд",
            quantity: "Кількість",
            description: "Опис коду",
            descriptionHint: 'Наприклад, тип упаковки: "коробка", "палета" тощо.',
            conflict: "Цей код вже має товар {0}",

            print: "Надрукувати штрих-код",
            printMany: "Надрукувати штрих-коди",
            printHowMuch: "Скільки етикеток '{0}' надрукувати?",
            printMultipleHowMuch: "Скільки етикеток надрукувати?",
            printDone: "Друкую етикетку '{0}'...",

            batchPrint: {
                label: "Виберіть параметри друку",
                printAll: "Надрукувати стільки ж етикеток, скільки товарів",
                printJustOne: "Тільки одна етикетка для кожного товару",
                totally: "тобто в цілому",
            },

            create: {
                title: "Додати штрих-код до '{0}'",
                titleSimple: "Додати штрих-код",
                done: "Код '{0}' додано до '{1}'"
            },
            update: {
                title: "Оновити код '{0}' у '{1}",
                titleSimple: "Оновити код товару",
                done: "Код '{0}' у '{1}' оновлено"
            },
            delete: {
                done: "Код '{0}' у '{1}' видалено!"
            },
            status: {
                404: "Складську картку, конкретний складський товар або штрих-код не знайдено!",
                409: "Вказаний штрих-код вже існує!"
            }
        },

        status: {
            404: "Складську картку або конкретний складський товар не знайдено!"
        }
    },

    serial_numbers: {
        name: "Серійні номери",
        number: "Серійний номер",
        serial_number: "Серійний номер",
        same_barcode: "Також створити однаковий штрих-код",
        create: {
            titleSimple: "Додати серійний номер",
            title: "Додати серійний номер до '{0}'",
            done: "'{0}' додано до '{1}'"
        },
        delete: {
            done: "Серійний номер видалено.",
            failed: "Не вдалося видалити серійний номер. Будь ласка, спробуйте пізніше.",
            status: {
                404: "Складську картку, конкретний складський товар або серійний номер не знайдено!",
                409: "Серійний номер не можна видалити через його зв'язки з іншими об'єктами!"
            }
        },
        status: {
            404: "Складську картку, конкретний складський товар або серійний номер не знайдено!",
            409: "Вказаний серійний номер вже існує!"
        }
    },

    pieces: {
        name: "Штуки на складі",
        status: "Стан на складі",
        movements: "Переміщення товару"
    },

    show: {
        titleSimple: "Деталі складського товару",
        title: "{0}"
    },
    create: {
        title: "Новий складський товар",
        done: "Складський товар '{0}' створено."
    },
    update: {
        titleSimple: "Оновити складський товар",
        title: "Оновити {0}",
        done: "Складський товар '{0}' оновлено."
    },
    delete: {
        done: "Складський товар '{0}' видалено.",
        failed: "Не вдалося видалити складський товар '{0}'. Будь ласка, спробуйте пізніше."
    },

    status: {
        404: "Складську картку не знайдено!",
        409: "Товар неможливо видалити, оскільки він ще на складі!",
        412: "Складський товар був оновлений іншим користувачем з моменту останнього завантаження. Будь ласка, спробуйте виконати дію пізніше."
    }
};
