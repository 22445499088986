<template>
  <div>
    <x-toolbar />
    <v-container fluid>
      <v-autocomplete
        v-model="taskId"
        :items="tasks"
        :label="$t('tasks.timeEntries.selectTask')"
        :loading="tasksLoading"
        prepend-icon="$taskSelect"
      />
      <x-data-table
        v-show="taskId !== null"
        :actions="actions"
        :headers="headers"
        :items="tableItems"
        :loading="loading"
      />
    </v-container>
  </div>
</template>

<script>
    import {TaskAPI} from "@/api/TaskAPI";
    import {TableFilter} from "@/enum/table_filter";
    import {taskNames} from "@/enum/task_type";
    import {actionsHeader} from "@/utils/table";

    export default {
        name: "TimeOverview",
        data: () => ({
            timeEntries: [],
            loading: false,

            taskId: null,
            tasks: [],
            tasksLoading: false,

        }),
        computed: {
            tableItems: function () {
                return this.timeEntries.map(item => ({
                    id: item.id,
                    name: item.user.first_name + ' ' + item.user.last_name,
                    hours: Number(item.hours),
                    created_at: this.$options.filters.dateTime(item.created_at)
                }));
            },
            headers: function () {
                return [
                    {
                        text: this.$t('tasks.timeEntries.table.user'),
                        value: 'name',
                        filterType: TableFilter.TEXT
                    }, {
                        text: this.$t('tasks.timeEntries.table.hours'),
                        value: 'hours',
                        filterType: TableFilter.NUMBER
                    }, {
                        text: this.$t('tasks.timeEntries.table.created_at'),
                        value: 'created_at',
                        filterType: TableFilter.DATETIME
                    }
                ].concat(actionsHeader());
            },
            actions: function () {
                return [
                    {
                        // loading: this.loading,
                        // action: this.editTime,
                        // icon: 'edit',
                        // label: 'tasks.timeEntries.edit'
                        // }, {
                        loading: this.loading,
                        action: this.deleteTime,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            }
        },
        watch: {
            taskId: function () {
                if (this.taskId !== null) {
                    this.fetchTimeEntries();
                }
            }
        },
        createdOrActivated: function () {
            this.tasksLoading = true;
            TaskAPI.getAllPages()
                .then(response => {
                    this.tasks = response.data.items.map(task => ({
                        value: task.id,
                        text: '#' + task.id
                            + ' ' + this.$t(taskNames[task.type])
                            + ' (' + this.$t('tasks.state.' + task.state) + ')'
                            + (task.assigned_user !== null
                                ? (' - ' + task.assigned_user.first_name + ' ' + task.assigned_user.last_name)
                                : '')
                    }));
                }).catch(this.snack)
                .finally(() => {
                    this.tasksLoading = false;
                });
        },
        methods: {
            fetchTimeEntries: function () {
                this.loading = true;
                TaskAPI.getAllTimeEntries(this.taskId)
                    .then(response => {
                        this.timeEntries = response.data;
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            deleteTime: function (item) {
                TaskAPI.deleteTimeEntry(this.taskId, item.id)
                    .then(() => {
                        this.snack('tasks.timeEntries.deleteDone');
                        this.fetchTimeEntries();
                    }).catch(this.snack);
            }
        }
    };
    // TODO rework this whole page
    // TODO CRITICAL edit time
</script>

<style scoped>

</style>
