<template>
  <v-container fluid>
    <ConfirmDeleteDialog
      v-if="deletedImport !== null"
      :show.sync="confirmDialog"
      :text="$t('imports.title') + ' - ' + $t('exports.created_at') + ' : ' + deletedImport.created_at"
      @confirm-delete="reallyDeleteImport"
    />
    <v-card-title
      class="py-0 pl-5"
    >
      {{ $t('imports.imports') }}
    </v-card-title>
    <x-data-table
      :actions="actions"
      :api-data-source="apiDataSource"
      :api-data-source-all-pages="apiDataSourceAllPages"
      :custom-filters="false"
      :headers="headers"
      :items.sync="items"
      :loading="loading"
      :reload="reload"
      :show-search-bar="false"
    >
      <template #item.status="{item}">
        <v-chip
          :color="statusColor[item.status]"
        >
          {{ $t('exports.state.' + item.status) }}
        </v-chip>
        <v-btn
          v-if="loadingStatuses.includes(item.status)"
          color="muted"
          loading
          text
          x-small
        />
      </template>
      <template #item.created_by="{item}">
        <div v-if="loading">
          <v-btn
            class="pa-0"
            color="muted"
            loading
            text
            x-small
          />
        </div>
        <div v-else>
          {{ item.created_by_user.username }}
        </div>
      </template>
      <template #item.created_at="{item}">
        <date-time-with-tooltip
          :date-time="item.created_at"
          abs-date-first
        />
      </template>
      <template #item.processed_at="{item}">
        <date-time-with-tooltip
          :date-time="item.processed_at"
          abs-date-first
        />
      </template>
    </x-data-table>
  </v-container>
</template>

<script>
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import {createHeaders} from "@/utils/table";
    import {importsTable} from "@/app/imports/definitions/importsTable.form";
    import {ImportsAPI as API} from "@/api/ImportsAPI";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import * as Export from "@/service/Export";
    import {FailedStatuses, LoadingStatuses, Status, StatusColor} from "@/enum/import&export_state";
    import {APIFilters} from "@/service/APIFilters";
    import {Parameters} from "@/enum/domains";
    import {ImportsAPI} from "@/api/ImportsAPI";
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import {UserAPI} from "@/api/UserAPI";

    export default {
        name: "ImportsListComponent",
        components: {
            ConfirmDeleteDialog, DateTimeWithTooltip, XDataTable
        },
        mixins: [ReactiveUserCacheMixin],
        beforeRouteLeave(to, from, next) {
            if (this.autoReloadTimeout) {
                clearTimeout(this.autoReloadTimeout);
            }
            next();
        },
        props: {
            configId: {
                type: Number,
                default: 0,
            }
        },
        data: () => ({
            loading: true,
            items: [],
            config: null,
            deletedImport: null,
            confirmDialog: false,
            errorMessage: '',
            errorDialog: false,
            statusColor: StatusColor,
            loadingStatuses: LoadingStatuses,
            reload: 0,
            headers: null,
            autoReloadTimeout: null
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        icon: '$showItem',
                        label: 'imports.viewImport',
                        color: "secondary",
                        routerLink: item => '/imports/' + item.id + '/view',
                        condition: item => (item.status === Status.FINISHED || FailedStatuses.includes(item.status))
                    },
                    {
                        loading: this.loading,
                        icon: '$continue',
                        label: 'imports.stepper.third_step',
                        color: "accent",
                        routerLink: item => '/imports/' + item.id + '/process',
                        condition: item => (LoadingStatuses.includes(item.status) || item.status === Status.DRY_RUN_FINISHED)
                    },
                    {
                        loading: this.loading,
                        action: this.showError,
                        icon: '$error',
                        label: 'exports.errorMessage',
                        color: "error",
                        condition: item => item.error !== null
                    },
                    {
                        loading: this.loading,
                        action: this.downloadImport,
                        icon: '$export',
                        label: 'imports.download_imported_file',
                        color: "primary",
                    },
                    {
                        loading: this.loading,
                        action: this.deleteImport,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                if (!this.configId)
                    return [];
                return API.getAll.bind(API, this.configId, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                if (!this.configId)
                    return [];
                return API.getAllPages.bind(API, this.configId);
            }
        },
        watch: {
            async items() {
                this.loading = true;
                const importsPromises = [];
                const userPromises = [];
                this.items.map(item => item.created_by).filter(user => !this.UserCache[user]).forEach(user => {
                    userPromises.push(this.cacheUser(UserAPI.get(user),user));
                });
                Promise.all(userPromises).then(() => {
                    this.items.forEach(item => {
                        // Check for externally created imports by non-existing users
                        if (this.UserCache[item.created_by] && typeof this.UserCache[item.created_by] !== "string") {
                            item.created_by_user = this.UserCache[item.created_by];
                        } else {
                            // Gets import author from import parameters
                            importsPromises.push(ImportsAPI.get(item.id)
                                .then(res => {
                                    const param = res.data.parameters.find(param => param.key === Parameters.EXTERNAL_AUTHOR_NAME);
                                    item.created_by_user = param ? {username: param.value} : '';
                                }).catch(err => this.snack(err))
                            );
                        }
                    });
                    Promise.all(importsPromises).then(() => {
                        if (this.items.some(item => LoadingStatuses.includes(item.status))) {
                            this.autoReload();
                        }
                        this.loading = false;
                    });
                });
            }
        },
        createdOrActivated() {
            this.getData();
        },
        methods: {
            getData() {
                this.headers = createHeaders(importsTable(), 'exports.');
                API.getConfig(this.configId)
                    .then(res => this.config = res.data)
                    .catch(err => this.snack(err));
            },
            deleteImport: function (item) {
                this.deletedImport = item;
                this.confirmDialog = true;
            },
            reallyDeleteImport: function () {
                API.delete(this.deletedImport.id)
                    .then(() => {
                        this.snack('import.deleted');
                        this.confirmDialog = false;
                        if (this.items.length === 1) {
                            this.$emit('reloadParent');
                        }
                        this.reload++;
                    }).catch(err => {
                        this.snack(err);
                    });
            },
            showError(item) {
                this.advancedSnack({
                    text: item.error,
                });
            },
            downloadImport(item) {
                Export.url(item.download_link);
            },
            autoReload() {
                this.autoReloadTimeout = setTimeout(() => this.reload++, 10000);
            },
        }
    };
</script>
