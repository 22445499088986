import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[((_vm.deliveryType === _vm.deliveryTypes.RETURN && Object.values(_vm.InstanceType).includes(_vm.itemType))
      || (_vm.deliveryType === _vm.deliveryTypes.DELIVERY && !Object.values(_vm.InstanceType).includes(_vm.itemType)))?_c(VLayout,[_c(VFlex,{staticClass:"orange--text"},[_vm._v(" "+_vm._s(_vm.itemText)+" ")]),_c(VFlex,{staticClass:"text-right"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c(VIcon,{staticClass:"ml-1",attrs:{"color":"orange"}},[_vm._v(" $info ")])],1)]}}],null,false,831117449)},[(_vm.deliveryType === _vm.deliveryTypes.RETURN)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isNotCustomInstance'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.deliveryAccept.items.isCustomInstance'))+" ")])])],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.itemText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }