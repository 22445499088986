<template>
  <span>
    <v-icon
      v-intersect="onIconIntersect"
      class="mr-2"
    >
      {{ taskItemsCardTypeIcons[cardType] }}
    </v-icon>
    <component
      :is="fabDirection === 'up' ? 'v-scroll-y-reverse-transition' : 'v-scroll-y-transition'"
    >
      <v-btn
        v-show="showNavigationFab"
        fixed
        v-bind="position"
        color="secondary"
        fab
        dark
        class="fab-pointer"
        :class="{
          'mr-16': nudgeRight,
          'fab-pointer-up': fabDirection === 'up',
          'fab-pointer-down': fabDirection === 'down',
        }"
        :style="fabDirection === 'up' ? `top: ${topOffset} !important` : ''"
        @click="scrollTo(`taskItemsCard-${cardType}`)"
      >
        <v-icon>
          {{ taskItemsCardTypeIcons[cardType] }}
        </v-icon>
      </v-btn>
    </component>
  </span>
</template>

<script>
    import {taskItemsCardTypeIcons} from "@/enum/icons";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {scrollTo} from "@/service/Vuetify";
    import {VScrollYTransition, VScrollYReverseTransition} from "vuetify/lib/components";

    export default {
        name: "TaskItemsCardIconNavigation",
        components: {VScrollYTransition, VScrollYReverseTransition},
        props: {
            cardType: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            taskItemsCardTypeIcons: taskItemsCardTypeIcons,
            showNavigationFab: false,
            fabDirection: '',
            scrollTo: scrollTo
        }),
        computed: {
            position: function () {
                return {
                    top: this.cardType === TaskItemsCardType.TO_MOVE,
                    bottom: [TaskItemsCardType.IN_INVENTORY, TaskItemsCardType.MOVED].includes(this.cardType),
                };
            },
            nudgeRight: function () {
                return this.cardType === TaskItemsCardType.IN_INVENTORY;
            },
            topOffset: function () {
                return document.querySelector('header').offsetHeight + 20;
            }
        },
        methods: {
            onIconIntersect: function (entries) {
                if ([TaskItemsCardType.TO_MOVE, TaskItemsCardType.IN_INVENTORY, TaskItemsCardType.MOVED].includes(this.cardType)) {
                    const entry = entries[0];
                    if (entry.isIntersecting) {
                        this.showNavigationFab = false;
                    } else {
                        if (entry.boundingClientRect.top > 0) {
                            if (this.cardType !== TaskItemsCardType.TO_MOVE) {
                                this.showNavigationFab = true;
                                this.fabDirection = 'down';
                            }
                        } else {
                            if (this.cardType !== TaskItemsCardType.MOVED) {
                                this.showNavigationFab = true;
                                this.fabDirection = 'up';
                            }
                        }
                    }
                }
            }
        }
    };
</script>

<style lang="sass">

.fab-pointer
  transform: rotate(45deg)
  right: -10px

  span
    transform: rotate(-45deg)

.fab-pointer-up
  border-radius: 0 28px 28px !important

.fab-pointer-down
  border-radius: 28px 28px 0 !important
  bottom: 5px !important
</style>
