<template>
  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="dense">
        {{ $t('tasks.externalOrder.state.label') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="dense-panel">
        <template v-if="!loading">
          <div
            v-for="state in states"
            :key="state.id"
            class="pb-3"
          >
            <div
              class="font-weight-bold"
              :class="state.active_to !== null ? 'muted--text' : 'primary--text'"
            >
              {{ $t('tasks.externalOrder.state.' + state.state) }}
            </div>
            <div class="pt-1 align-center text-body-2 d-flex justify-space-between">
              <div>
                <DateTimeWithTooltip
                  :date-time="state.active_from"
                />
              </div>
              <v-divider class="mx-2" />
              <div>
                <DateTimeWithTooltip
                  v-if="state.active_to"
                  :date-time="state.active_to"
                />
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <v-icon
                      color="accent"
                      v-on="on"
                    >
                      $active
                    </v-icon>
                  </template>
                  {{ $t('base.active') }}
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {APIFilterOP, APIFilters} from "@jagu/rest-api-filters-client/apiFilters";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";

    export default {
        name: "ExternalOrderStates",
        components: {DateTimeWithTooltip},
        props: {
            taskId: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            loading: false,
            states: [],
        }),
        createdOrActivated() {
            this.getStates();
        },
        methods: {
            getStates() {
                this.loading = true;
                TaskExternalOrderAPI.getAllStates({
                    filter: APIFilters.makeFilter({
                        [APIFilterOP.EQUALS]: {'order.id': this.taskId}
                    })
                })
                    .then(res => {
                        this.states = res.data.items;
                    }).catch(err => this.snack(err))
                    .finally(() => this.loading = false);
            }
        }
    };
</script>


<style scoped lang="sass">

.dense
  padding: 5px !important

::v-deep .dense-panel .v-expansion-panel-content__wrap
  padding: 5px !important

</style>
