export default {
    link: "Fuvarozók",
    title: "Fuvarozók",
    name: "Név",
    type: "Típus",
    active: "Aktív",
    notActive: "Inaktív",
    services: {
        title: "Szolgáltatások",
        noServices: "Nincsenek elérhető szolgáltatások",
        showAll: "mutass mindent",
        hideAll: "Mindent elrejteni",

        name: "Szolgáltatás",
        carrier_service_parameters: "Szállítási paraméterek",
        required_carrier_service_parameters: "Kötelező szállítási paraméterek",
        optional_carrier_service_parameters: "Választható szállítási paraméterek",
        type: "Címkenyomtatási formátum",
        create: {
            title: "Fuvarozói szolgáltatás létrehozása",
            label: "Új fuvarozói szolgáltatás létrehozása",
            done: "Létrejött a '{0}' fuvarozói szolgáltatás."
        },
        update: {
            titleSimple: "Szerkeszd a fuvarozói szolgáltatást",
            title: "Szerkesztette {0}",
            done: "A '{0}' fuvarozói szolgáltatás módosult"
        },
        delete: {
            done: "A '{0}' fuvarozói szolgáltatást törölték.",
            failed: "A '{0}' fuvarozói szolgáltatás nem törölhető. Kérjük, próbálja meg később újra.",
        },
    },
    tableImage: "",
    tableFoilImage: "Csomagolás",
    doesNotSupportMultiPackageShipments: "Ez a fuvarozó nem támogatja a több csomagból álló küldeményeket",
    searchHint: "Név és típus keresése",

    show: {
        titleSimple: "Részletek a fuvarozóról",
        configInSubstock: "A fuvarozói kapcsolat konfigurációját az egyes alraktárak részleteiben találja."
    },

    create: {
        title: "Szállító létrehozása",
        done: "A '{0}' szállítót létrehoztuk."
    },
    update: {
        titleSimple: "Szállító szerkesztése",
        title: "Szerkesztés {0}",
        done: "Szállító '{0}' módosítva"
    },
    delete: {
        done: "A '{0}' szállítót törölték.",
        failed: "A '{0}' szállító nem törölhető. Kérjük, próbálja meg később újra.",
    },
    status: {
        404: "A megadott fuvarozó nem létezik!",
        409: "A kitöltött név már létezik!"
    },

    images: {
        nameFoil: "Csomagolás",
        alt: "A szállítóhoz rendelt kép",
        altCreate: "A szállításhoz rendelt kép",
        unableToLoad: "A képet nem lehetett betölteni. Kérjük, próbálja meg később újra.",
        create: {
            foil: {
                titleSimple: "Fóliakép hozzáadása",
                title: "Fóliakép hozzáadása a '{0}' fájlhoz",
                done: "Fóliakép hozzáadva a következőhöz '{0}'"
            }
        },
        update: {
            foil: {
                titleSimple: "Fóliakép szerkesztése",
                title: "Fóliakép szerkesztése '{0}'",
                done: "Fóliakép ’{0}’  megszerkesztve",
            }
        },
        status: {
            404: "Szállító vagy a hozzárendelt kép nem található",
        }
    },

    contracted: {
        label: "Szerződés megkötve",
        parameters: {
            required: {
                name: "Kötelező paraméterek"
            },
            optional: {
                name: "További opcionális paraméterek"
            },
            key: "Név",
            value: "Paraméterek",
            writeOnly: "Biztonsági okokból nem tölthető be"
        },

        create: {
            label: "Hozzáadás a szerződött fuvarozók listájához",
            titleSimple: "Szerződéses fuvarozói paraméterek beállítása",
            title: "Paraméterek beállítása '{0}'",
            info: "Ön a '{0}' paramétereket a '{1}' paraméterekhez állítja be",
            done: "'{0}'Hozzáadás a szerződött fuvarozók listájához",
        },
        update: {
            label: "A szerződött fuvarozók paramétereinek szerkesztése",
            titleSimple: "A szerződött fuvarozó paramétereinek szerkesztése",
            title: "Paraméterek szerkesztése '{0}'",
            info: "Ön a '{0}' paramétereket szerkeszti a '{1}' számára",
            done: "Paraméterek '{0}' módosítva",
            substock: {
                title: "Szerződés az alábbi tárolandó készlet elemekről",
                done: "’{0}’ szerződés megkötve",
            }
        },
        delete: {
            titleSimple: "Eltávolítás a szerződött fuvarozók listájáról",
            done: "'{0}'eltávolítás a szerződött fuvarozó listájáról",
            substock: {
                title: "A szerződés visszavonása erre a alraktárra",
                done: "'{0}' A szerződést sikeresen visszavonta",
            }
        }
    },

    run: {
        job: "Háttérmunka futtatása",
        validatePackages: "Szállítmányok érvényesítése",
        generateProtocols: "Átadási protokollok",
        trackPackages: "Szállítmánykövetés"
    }
};
