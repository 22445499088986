import {StocksXAutocompleteRender} from "@/app/overview/definitions/overview.render.form";

const dateFormRender = {
    dateTimePicker: true,
    required: true,
    sticky: true
};

function StockBuyPricesForm(omit_from_date = false) {
    return {
        stockId: null,
        subStockId: null,
        ...omit_from_date ? {} : {
            from_date: null
        },
        to_date: null,
    };
}

function StockBuyPricesRender(omit_from_date = false) {
    return {
        ...(new StocksXAutocompleteRender(true, true)),
        ...omit_from_date ? {} : {
            from_date: dateFormRender,
        },
        to_date: dateFormRender,
    };
}

export {StockBuyPricesForm, StockBuyPricesRender};
