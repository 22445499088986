import {BaseAPI} from "@/api/BaseAPI";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";

const ProductTypeAPI = {

    API: BaseAPI,
    DOMAIN: 'products/types',
    lang: 'products.types',
    DOMAIN_SUGGESTED_ATTRIBUTES: 'suggested-attributes',
    LAST_MODIFIED: 'last-modified',
    IF_UNMODIFIED_SINCE: 'If-Unmodified-Since',

    attributesLastModified: '',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, this.lang, merge(...params));
    },
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, this.lang, merge(...params));
    },

    get(typeId) {
        return this.API.get(
            [this.DOMAIN, typeId], this.lang
        ).then(response => {
            this.attributesLastModified = response.headers[this.LAST_MODIFIED];
            return response;
        });
    },

    getSuggestedAttributes(typeId) {
        return this.API.get(
            [this.DOMAIN, typeId, this.DOMAIN_SUGGESTED_ATTRIBUTES], this.lang
        ).then(response => {
            this.attributesLastModified = response.headers[this.LAST_MODIFIED];
            return response;
        });
    },

    updateSuggestedAttributes(typeId, data, lastModified = null) {
        let attributesLastModified = this.attributesLastModified;
        if (lastModified) {
            attributesLastModified = lastModified;
        }
        return this.API.put(
            [this.DOMAIN, typeId, this.DOMAIN_SUGGESTED_ATTRIBUTES],
            {attributes: data},
            this.lang,
            {
                [this.IF_UNMODIFIED_SINCE]: attributesLastModified
            }
        );
    },

    create(data) {
        return this.API.post(this.DOMAIN, data, this.lang);
    },

    update(typeId, data) {
        return this.API.patch([this.DOMAIN, typeId], data, this.lang);
    },

    delete(typeId) {
        return this.API.delete([this.DOMAIN, typeId], this.lang);
    }
};

export {ProductTypeAPI};
