import {Roles} from "@/enum/roles";
import StockStatus from "@/app/overview/status/StockStatus.view";

const BASE_URL = '/status';

const routes = {
    base: {
        path: BASE_URL,
        component: StockStatus,
        meta: {
            title: 'stocks.stockStatus.title',
            requireRole: [Roles.CHIEF, Roles.ORGANIZER],
            getParent: () => '/'
        }
    },
    report: {
        path: BASE_URL + '/locations',
        redirect: () => {
            return { path: '/reports' };
        },

    }
};

export default Object.values(routes);
