/**
 * See cache description in README.md
 */

const CACHE_VERSION = 2; // Increase this every time the CacheConfiguration schema changes

const CachePath = {
    taskDetails: 'taskDetails',
    products: 'products',
    instances: 'instances',
    instanceTypes: 'instanceTypes',
    instanceBarcodes: 'instanceBarcodes',
    allowedLocationIds: 'allowedLocationIds',
    barcodes: 'barcodes',
    locations: 'locations',
    buyers: 'buyers',
    users: 'users'
};

const CacheIndexPath = {
    products: CachePath.products,
    manufacturers: 'manufacturers'
};

const CacheConfiguration = {
    [CachePath.taskDetails]: {
        // contains various task details from GET /tasks/{task-slug}/{taskId}
        // key: taskId
        icon: '$task',
        lifetime: 1000 * 60 * 30 // 30 minutes
    },
    [CachePath.products]: {
        // contains Product from API
        // key: productId
        icon: '$product',
        indices: {
            [CacheIndexPath.manufacturers]: 'manufacturer.id'
        },
        lifetime: 1000 * 60 * 60 * 24 * 7 // 7 days
        // TODO use index in 'instances' and search them as well
    },
    [CachePath.instances]: {
        // contains ProductInstanceDetail from API
        // key: instanceId
        icon: '$productInstance',
        indices: {
            [CacheIndexPath.products]: 'product.id',
            [CacheIndexPath.manufacturers]: 'product.manufacturer.id'
        },
        lifetime: 1000 * 60 * 60 * 24 * 7 // 7 days
    },
    [CachePath.instanceTypes]: {
        // contains ProductInstanceType from API
        // key: instanceTypeId
        icon: '$productInstanceType',
        lifetime: 1000 * 60 * 60 * 24 * 30 // 30 days
    },
    [CachePath.instanceBarcodes]: {
        // contains ProductInstanceBarcode[] from API
        // key: instanceId
        icon: '$barcodeList',
        lifetime: 1000 * 60 * 60 * 24 // 1 day
    },
    [CachePath.allowedLocationIds]: {
        // contains number[]
        // key: stockId-subStockId-instanceId
        icon: '$scannerDestination',
        lifetime: 1000 * 60 * 60 * 24 // 1 day
    },
    [CachePath.barcodes]: {
        // contains BarcodeInfo from API
        // key: code (string)
        icon: '$barcode',
        lifetime: 1000 * 60 * 60 // 1 hour
        // TODO maybe use different backend, LocalStorage instead of IndexedDB, for performance
    },
    [CachePath.locations]: {
        // contains StockLocation from API
        // key: locationId
        icon: '$location',
        lifetime: 1000 * 60 * 60 * 24 * 30 // 30 days
    },
    [CachePath.buyers]: {
        // contains BuyerDetail from API
        // key: buyerId
        icon: '$buyer',
        lifetime: 1000 * 60 * 60 * 24 * 7 // 7 days
    },
    [CachePath.users]: {
        // contains User from API
        // key: userId
        icon: '$cacheUser',
        lifetime: 1000 * 60 * 60 * 24 * 30 // 30 days
    },
    // When adding new value, do not forget to increase CACHE_VERSION above
};

export {CachePath, CacheIndexPath, CacheConfiguration, CACHE_VERSION};
