import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,_vm._g({ref:"autocomplete",attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.$t(_vm.langPath + 'chooseAttr'),"value":_vm.value,"items":_vm.items},on:{"blur":_vm.reSelectValue},scopedSlots:_vm._u([{key:"item",fn:function({ parent, item }){return [_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.getItemHtml(parent.genFilteredText(item.text)))}})],1)]}}])},{..._vm.$listeners, input: _vm.propertyChanged }))
}
var staticRenderFns = []

export { render, staticRenderFns }