export default {
    dailyLocation: 'Щоденний звіт про зайнятість локації',
    dailyLocationByType: 'Щоденний звіт про заповнюваність локацій за типами',
    dailyLocationWithMovement: 'Щоденний звіт про зайнятість локації з рухом',
    dailyLocationOccupancy: 'Щоденна заповнюваність локації',

    parameters: {
        date: 'Дата',
        interval: 'Інтервал',
        interval_from: 'дата від',
        interval_to: 'на дату',
        subordinate_stock_ids: 'Підпорядковані склади',
        manufacturer_ids: "Виробники",
        product_ids: "Складські товари",
        product_type_ids: 'Типи складських товарів',
        location_ids: "Розміщення на складі",
        report_type: 'Тип звіту',
        task_types: 'Тип завдання',
        include_missing_products: 'Додайте відсутні товари',
        include_empty_locations: "Zahrnout prázdné umístění"
    },

    attributes: {
        last_loaded_at: 'Останній Завантаження',
        last_picked_at: 'Останній Розвантаження',
        filled_locations_count: 'Підрахунок заповнених локацій',
        object_id: 'Ідентифікатор об\'єкта',
        filled_location_count: 'Кількість заповнених місць на кінець періоду',
        stock_loaded_amount: 'Кількість завантажено на склад',
        period_end_items_amount: 'Сума статей на кінець періоду',
        transfers_count: 'Кількість переказів',
        stock_picked_amount: 'Кількість обирається зі складу',
        product_type_id: 'Ідентифікатор типу продукту',
        manufacturer_id: 'Виробник ID',
        hour: 'Година.',
        day: 'День',
        week: 'Тиждень',
        month: 'Місяць',
        year: 'Рік',
        week_of_month: 'Тиждень місяця',
        occupancy: 'заповнюваність',
    },

    hints: {
        report_type: 'Тип звіту визначає що будуть містити перші стовпці "Ідентифікатор об\'єкта" та "Назва". Значення останнього стовпчика буде належати об\'єкту з перших стовпчиків.',
        interval: 'Інтервал визначає тривалість часових проміжків, на які буде поділено весь обраний період.'
    }


};
