const notFound = "Zadaný výrobce neexistuje!";

export default {
    title: "Výrobci",
    link: "Výrobci",

    abbreviation: "Zkratka",
    name: "Název",

    list: {
        unableToLoad: "Seznam výrobců nebylo možné načíst. Zkuste to prosím znovu později."
    },
    create: {
        label: "Vytvořit nového výrobce",
        title: "Vytvořit výrobce",
        done: "Výrobce '{0}' vytvořen.",
        undo: {
            done: "Tvorba '{0}' úspěšně vrácena!",
            failed: "Nezdařilo se vrátit tvorbu výrobce!"
        }
    },
    update: {
        titleSimple: "Upravit výrobce",
        title: "Upravit {0}",
        done: "Výrobce '{0}' upraven",
        undo: {
            done: "Úpravy '{0}' úspěšně vráceny!",
            failed: "Nezdařilo se vrátit úpravu výrobce!"
        }
    },
    delete: {
        done: "Výrobce '{0}' smazán.",
        failed: "Výrobce '{0}' nelze smazat. Zkuste to prosím znovu později.",
        undo: {
            done: "Smazání '{0}' úspěšně vráceno!",
            failed: "Nezdařilo se vrátit smazání výrobce!"
        },
        status: {
            404: notFound,
            409: "Výrobce nelze smazat kvůli vazbám na skladové položky."
        }
    },
    status: {
        404: notFound,
        409: "Vyplněná zkratka již existuje!"
    }
};
