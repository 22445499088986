<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/stocks/' + stockId + '/substocks/' + subStockId + '/update'"
              >
                <v-icon>
                  $updateItem
                </v-icon>
              </v-btn>
              {{ details.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container fluid>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div>
                      <span class="text-caption">{{ $t('stocks.description') + ': ' }}</span>
                      {{ details.description }}
                    </div>
                    <div>
                      <span class="text-caption">{{
                        $t('stocks.substocks.owner.name') + ': '
                      }}</span>
                      {{ details.owner.name }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <EntityHistory :changes="details" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <StockSubstockTaskSettings />
      <StockSubstockTaskAttributesSettings />
      <StockSubstockAssignedBuyerTypes />
      <v-expansion-panels class="my-2 px-1">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            {{ $t('stocks.substocks.order_processing.label') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <StockSubstockOrderProcessing />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels
        id="users"
        ref="users"
        v-model="usersDropDown"
        class="my-2 px-1"
      >
        <v-expansion-panel :key="0">
          <v-expansion-panel-header class="text-subtitle-1">
            {{ $t('users.permissions.titleSingle') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <StockSubstockAssignedUsersComponent
              :substock-id="subStockId"
              @loaded="usersLoaded"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout wrap>
        <v-flex
          xs12
          class="pa-1"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-subtitle-1">
                {{ $t('carriers.title') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CarriersListComponent
                  :owner-id="details.owner.id"
                  :stock-id="stockId"
                  :sub-stock-id="subStockId"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <v-flex
          xs12
          class="pa-1"
        >
          <StockLocationsList
            :distinguish-allowed-for="subStockId"
            :substock="true"
          />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {StockAPI as API} from "@/api/StockAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import StockLocationsList from "@/app/stocks/locations/components/StockLocationsList.component";
    import StockSubstockOrderProcessing from "@/app/stocks/substocks/components/StockSubstockOrderProcessing.component";
    import {tabTitle} from "@/utils/string";
    import {scrollTo} from "@/service/Vuetify";
    import CarriersListComponent from "@/app/carriers/components/CarriersList.component";
    import StockSubstockTaskSettings from "@/app/stocks/substocks/components/StockSubstockTaskSettings.component";
    import StockSubstockAssignedBuyerTypes
        from "@/app/stocks/substocks/components/StockSubstockAssignedBuyerTypes.component";
    import StockSubstockAssignedUsersComponent
        from "@/app/stocks/substocks/components/StockSubstockAssignedUsers.component";
    import StockSubstockTaskAttributesSettings from "@/app/stocks/substocks/components/StockSubstockTaskAttributesSettings.component";

    export default {
        name: "StockSubstockShow",
        components: {
            StockSubstockAssignedUsersComponent,
            StockSubstockTaskAttributesSettings,
            StockSubstockAssignedBuyerTypes,
            StockSubstockOrderProcessing,
            StockLocationsList,
            EntityHistory,
            CarriersListComponent,
            StockSubstockTaskSettings
        },
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null,
            stockName: '',
            usersDropDown: null
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    if (this.stockName !== '') {
                        title = this.$t('stocks.substocks.show.title', [this.details.name, this.stockName]);
                    } else {
                        title = this.$t('stocks.substocks.show.title', [this.details.name]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            usersLoaded() {
                if (this.$route.hash === '#users')
                    scrollTo('users');
            }
        },
        createdOrActivated: function () {
            API.get(this.stockId)
                .then(response => {
                    this.stockName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks/' + this.stockId);
                });

            API.getSubstock(this.stockId, this.subStockId)
                .then(response => {
                    this.details = response.data;
                    if (this.$route.hash === '#users') {
                        this.usersDropDown = 0;
                    }
                }).catch(this.snack);
        }
    };

// TODO show more substock details
</script>

<style scoped>

</style>
