import Vue from "vue";

export default function () {
    const moment = require('moment');
    require('moment/locale/cs');
    require('moment/locale/sk');
    require('moment/locale/hu');
    require('moment/locale/uk');

    Vue.use(require('vue-moment'), {
        moment
    });
}
