import {BaseAPI} from "@/api/BaseAPI";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";

const BuyerTypeAPI = {

    API: BaseAPI,
    DOMAIN: 'buyers/types',
    lang: 'buyers.types',

    getAll(...params) {
        return this.API.get(this.DOMAIN, this.lang, merge(...params));
    },
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, this.lang, merge(...params));
    },

    get(typeId) {
        return this.API.get([this.DOMAIN, typeId], this.lang);
    },

    create(data) {
        return this.API.post(this.DOMAIN, data, this.lang);
    },

    update(typeId, data) {
        return this.API.patch([this.DOMAIN, typeId], data, this.lang);
    },

    delete(typeId) {
        return this.API.delete([this.DOMAIN, typeId], this.lang);
    }
};

export {BuyerTypeAPI};
