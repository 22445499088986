const connectionModule = {
    namespaced: true,
    state: {
        online: true,
    },
    getters: {
        online: (state) => state.online,
    },
    mutations: {
        online (state) {
            state.online = true;
        },
        offline (state) {
            state.online = false;
        },
    },
    actions: {
        change ({getters, commit}, newState) {
            if (newState !== getters.online) {
                commit(newState ? 'online' : 'offline');
            }
        }
    }
};

export default connectionModule;
