import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskMovementType} from "@/enum/task_movement_type";
import {TaskAction} from "@/enum/task_action";

const TaskMoveProductsAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/move-products',
    LANG: 'tasks.moveProducts',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',
    LANG_INVENTORY: 'inventory',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG,
            undefined,
            undefined,
            false
        );
    },

    createItem(taskId, location_id, product_instance_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            {
                location_id: location_id,
                product_instance_id: product_instance_id,
                quantity: quantity
            },
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    pickUpFromSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_SOURCE_TO_INVENTORY],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_INVENTORY_TO_SOURCE],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_INVENTORY_TO_DESTINATION],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    pickUpFromDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_DESTINATION_TO_INVENTORY],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    approve(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.APPROVE],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'approve']
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.ASSIGN],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.UNASSIGN],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.CANCEL],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.FINISH],
            {
                hours: hours,
                note: note
            },
            [this.LANG, 'finish']
        );
    },

    reject(taskId, hours, note, withdraw_storekeeper) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.REJECT],
            {
                hours: hours,
                note: note,
                withdraw_storekeeper: withdraw_storekeeper
            },
            [this.LANG_TASKS, 'reject']
        );
    }
};

export {TaskMoveProductsAPI};
