import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {TaskState} from "@/enum/task_state";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskMovementType} from "@/enum/task_movement_type";
import {TaskAction} from "@/enum/task_action";
import {merge} from "@/utils/object";

const TaskStockPickingAPI =  {

    API: BaseAPI,
    DOMAIN: 'tasks/stock-picking',
    LANG: 'tasks.stockPicking',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',
    LANG_INVENTORY: 'inventory',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(params) {
        return this.API.get(
            [this.DOMAIN],
            this.LANG_TASKS,
            params
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN],
            this.LANG_TASKS,
            merge(...params)
        );
    },

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    getBySubstock(subStockId, shippingType) {
        return this.getAllPages({
            filter: APIFilters.makeFilter([
                {
                    [APIFilterOP.EQUALS]: {
                        'substock.id': subStockId
                    }
                },
                {
                    [APIFilterOP.IS_NULL]: 'parent_task.id'
                },
                {
                    [APIFilterOP.IN]: {
                        state: [TaskState.CREATED, TaskState.UNASSIGNED]
                    }
                },
                {
                    [APIFilterOP.EQUALS]: {
                        shipping_type: shippingType
                    }
                }
            ])
        });
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    /**
     * @param data
     * @return {*|Promise<AxiosResponse<any>|never>}
     */
    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG
        );
    },

    report(taskId, acceptType) {
        return this.API.get(
            [this.DOMAIN, taskId, 'print-detail'],
            this.LANG_TASKS,
            undefined,
            {
                headers: {
                    'Accept': acceptType
                }
            }
        );
    },

    setDestination(taskId, destination_stock_location_id) {
        return this.API.put(
            [this.DOMAIN, taskId, 'set-destination-location'],
            {destination_stock_location_id},
            [this.LANG, 'location']
        );
    },

    updateSellPrice(taskId, itemId, sell_price_per_unit, price_vat) {
        return this.API.patch(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            {
                sell_price_per_unit: sell_price_per_unit,
                price_vat: price_vat
            },
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    pickUpFromSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_SOURCE_TO_INVENTORY],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToSource(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_INVENTORY_TO_SOURCE],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    putToDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_INVENTORY_TO_DESTINATION],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    pickUpFromDestination(taskId, itemId, location_id, quantity) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId, TaskMovementType.FROM_DESTINATION_TO_INVENTORY],
            {
                location_id: location_id,
                quantity: quantity
            },
            [this.LANG, this.LANG_INVENTORY]
        );
    },

    /**
     * @param taskId {number}
     * @param data
     * @return {*|Promise<AxiosResponse<any>|never>}
     */
    approve(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.APPROVE],
            data,
            [this.LANG_TASKS, 'approve']
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.ASSIGN],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.UNASSIGN],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.CANCEL],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.FINISH],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },

    handOver(taskId, hours, note) {
        return this.finish(taskId, hours, note);
    },

    reject(taskId, hours, note, withdraw_storekeeper) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.REJECT],
            {
                hours: hours,
                note: note,
                withdraw_storekeeper: withdraw_storekeeper
            },
            [this.LANG_TASKS, 'reject']
        );
    }
};

export {TaskStockPickingAPI};
