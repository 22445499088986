import {InstanceIdRender, ProductIdRender} from "@/app/overview/definitions/overview.render.form";
import {TableFilter} from "@/enum/table_filter";
import {locationLabel} from "@/utils/string";
import {instanceLabel, productMeasureLabel} from "@/utils/filters";
import {productInstances, visibleProducts} from "@/app/tasks/definitions/taskItemsDataSources";
import {stocks} from "@/app/stocks/definitions/stockDataSources";
import {emptySource} from "@/app/definitions/emptyDataSources";
import {locationsInStock} from "@/app/stocks/locations/definitions/stockLocationDataSources";

function StockStatusSpecRender() {
    return {
        locationId: {
            sticky: true,
            readonly: true,
            autocomplete: {items: []}
        },
        productId: {
            ...(new ProductIdRender),
            readonly: true,
            forceFetch: true
        },
        instanceId: new InstanceIdRender
    };
}

const stockStatusTable = {
    'stock_location.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        xAutocomplete: nestedItem => nestedItem ? locationsInStock(nestedItem, true) : emptySource(),
        itemLabel: item => locationLabel(item.stock_location),
        nested: {
            key: 'stock.id',
            filterType: TableFilter.SELECT,
            xAutocomplete: stocks(),
            label: 'stocks.stockStatus.table.stock.choose',
            icon: '$stock',
            allItemLabel: 'stocks.stockStatus.allLocations'
        }
    },
    'product_instance.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        xAutocomplete: nestedItem => nestedItem ? productInstances(nestedItem) : emptySource(),
        itemLabel: item => instanceLabel(item.product_instance),
        nested: {
            key: 'product.id',
            filterType: TableFilter.SELECT,
            xAutocomplete: visibleProducts(),
            label: 'stocks.stockStatus.table.product_instance.choose',
            icon: '$productInstance',
            allItemLabel: 'stocks.stockStatus.allProducts'
        },
    },
    quantity: {
        filterType: TableFilter.NUMBER,
        itemLabel: item => item.quantity + ' ' + productMeasureLabel(item.product_instance.product)
    },
    last_change: {
        filterType: TableFilter.DATETIME,
    },
    loaded_at: {
        filterType: TableFilter.DATETIME,
    }
};

const stockStatusHiddenFilters = {
    'substock.id': {
        filterType: TableFilter.SELECT_MULTIPLE
    }
};

export {StockStatusSpecRender, stockStatusTable, stockStatusHiddenFilters};
