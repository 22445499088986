<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedType !== null"
      :show.sync="confirmDialog"
      :text="deletedType.name"
      @confirm-delete="reallyDeleteType"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('products.instances.types.label') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :actions="actions"
          >
            <template #footer>
              <TableAddItemButton
                to="/products/instance-types/create"
                label="products.instances.types.create.title"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {createHeaders} from "@/utils/table";
    import {instanceTypeTable} from "@/app/products/instances/types/definitions/instanceType.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "InstanceTypesList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            items: [],
            confirmDialog: false,
            deletedType: null
        }),
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/products/instance-types/' + item.instance_type_id + '/update',
                        icon: "$updateItem",
                        label: 'base.edit'
                    }, {
                        action: this.deleteItem,
                        icon: '$deleteItemForever',
                        label: 'base.delete'
                    }
                ];
            },
            headers: function () {
                return createHeaders(instanceTypeTable, 'products.instances.types.');
            }
        },
        createdOrActivated: function() {
            this.fetchItems();
        },
        methods: {
            fetchItems: function () {
                this.loading = true;
                ProductAPI.getAllCustomInstanceTypes().then(response => {
                    this.items = response.data;
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            deleteItem: function (item) {
                this.deletedType = item;
                this.confirmDialog = true;
            },
            reallyDeleteType: function () {
                this.loading = true;
                this.confirmDialog = false;
                ProductAPI.deleteCustomInstanceType(this.deletedType.instance_type_id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.instances.types.delete.done',
                            params: [this.deletedType.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.fetchItems();
                    });
            },
        }
    };
</script>

<style scoped>

</style>
