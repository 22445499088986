import formRules from "@/utils/formRules";

function MapPropertiesForm(){
    return {
        mapWidth: null,
        mapHeight: null,
    };
}

function MapSizeRender(widthColisionRule, heightColisionRule){
    return {
        mapWidth: {
            type: 'number',
            rules: [formRules.positiveInteger, widthColisionRule],
            required: true,
            sizeCoefficient: 0.45,
        },
        mapHeight: {
            type: 'number',
            rules: [formRules.positiveInteger, heightColisionRule],
            required: true,
            sizeCoefficient: 0.45,
        },
    };
}

export {MapPropertiesForm, MapSizeRender};
