import {has} from "@/utils/object";
import {taskTypes} from "@/enum/task_type";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";

/**
 * Requires:
 * - this.items StockLoading, MoveProducts or SubStockTransfer task items
 * - TaskTypeMixin
 * - this.taskInfo.movementType
 */
const TaskAllowedLocationsMixin = {
    data: () => ({
        allowedInstances: {}
    }),
    computed: {
        destinationLocations: function () {
            return [...new Set(this.items.flatMap(item =>
                item.destination_locations !== undefined
                    ? item.destination_locations.map(loc => this.isType(taskTypes.STOCK_LOADING) ? loc.location_id : loc.stock_location_id)
                    : [item.location_id || item.stock_location_id || item.destination_location.stock_location_id]
            ))];
        },
        loadAllowedLocations: function () {
            if (this.isType(taskTypes.MOVE_PRODUCTS)) {
                return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType);
            }
            return this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.SUBSTOCK_TRANSFER]);
        }
    },
    createdOrActivated: function () {
        this.allowedInstances = {};
    },
    methods: {
        computeAllowedInstances: function () {
            this.allowedInstances = {};
            //create local copy, then set it for proper reactivity
            const allowed = {};
            this.items.forEach(item => {
                if (item.allowedLocationIds !== undefined) {
                    item.allowedLocationIds.forEach(locationId => {
                        if (this.destinationLocations.includes(locationId)) {
                            if (!has(allowed, locationId)) {
                                allowed[locationId] = [];
                            }
                            allowed[locationId].push(item.instance);
                        }
                    });
                }
            });
            Object.keys(allowed).forEach(key => {
                this.$set(this.allowedInstances, key, allowed[key]);
            });
        },
        getAllowedInstances(locationId) {
            if (has(this.allowedInstances, locationId)) {
                return this.allowedInstances[locationId];
            }
            const allowedInstances = [];
            this.items.forEach(item => {
                if (!item.allowedLocationIds || item.allowedLocationIds.includes(locationId)) {
                    allowedInstances.push(item.instance);
                }
            });
            this.$set(this.allowedInstances, locationId, allowedInstances);
            return allowedInstances;
        }
    }
};

export {TaskAllowedLocationsMixin};
