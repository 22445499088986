<template>
  <div>
    <div class="px-2">
      <div class="text-caption">
        {{ title }}
      </div>
      <div class="d-flex flex-wrap align-center">
        <v-btn-toggle
          class="d-flex flex-wrap"
          :value="[]"
        >
          <v-tooltip
            v-for="tool of tools"
            :key="tool.label"
            top
          >
            {{ tool.hint }}
            <template #activator="{ on }">
              <v-btn
                outlined
                v-on="on"
                @click="tool.action"
              >
                {{ tool.label }}
              </v-btn>
            </template>
          </v-tooltip>
        </v-btn-toggle>
        <v-tooltip
          top
        >
          {{ $t('base.help.title') }}
          <template #activator="{ on }">
            <v-btn
              class="ml-3 my-1"
              color="secondary"
              outlined
              target="_blank"
              href="https://manual.sklady.jagu.cz/user-documentation/storekeeper/stock-taking/#tabulka-znacek"
              v-on="on"
            >
              <v-icon class="mr-3">
                $hint
              </v-icon>
              <v-icon>
                $openItem
              </v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
      <v-text-field
        ref="searchInput"
        v-model="searchValue"
        outlined
        :hint="$t('tasks.stockTaking.entityHelper.hint')"
      />
    </div>
    <div>
      <v-chip
        v-for="item of selectedItems.slice(0, 20)"
        :key="item.value"
        class="ma-1"
        outlined
        label
        :value="false"
      >
        {{ item.text }}
      </v-chip>
      <v-chip
        v-if="selectedItems.length > 20"
        color="secondary"
        class="ma-1"
        outlined
        label
      >
        +{{ selectedItems.length - 20 }}
      </v-chip>
    </div>
    <div>
      <v-btn
        color="secondary"
        class="my-2"
        :disabled="selectedItems.length === 0"
        @click="submit"
      >
        {{ submitLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    export default {
        name: "RegexInputItemsFilter",
        props: {
            title: {
                type: String,
                default: ''
            },
            submitLabel: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            searchValue: ''
        }),
        computed: {
            tools: function () {
                return ['^', '$', ['.', 'dot'], '?', '*', '+', '\\', '()', ['[]', 'squareBrackets'], '{}']
                    .map(symbolOrTuple => {
                        return Array.isArray(symbolOrTuple) ? symbolOrTuple : [symbolOrTuple, symbolOrTuple];
                    })
                    .map(([symbol, translation]) => ({
                        label: symbol,
                        hint: this.$t('base.regex.' + translation),
                        action: () => this.insertChar(symbol)
                    }));
            },
            selectedItems: function () {
                if (this.searchValue && this.items && this.items.length > 0) {
                    try {
                        return this.items.filter(item => item.text.match(this.searchValue));
                    } catch (e) {
                        window.console.warn(e);
                        return [];
                    }
                } else {
                    return [];
                }
            }
        },
        methods: {
            insertChar: function (char) {
                const cursorPosition = this.$refs.searchInput.$refs.input.selectionStart;
                const textBeforeCursor = this.searchValue.slice(0, cursorPosition);
                const textAfterCursor = this.searchValue.slice(cursorPosition);
                this.searchValue = textBeforeCursor + char + textAfterCursor;
            },
            submit: function () {
                this.$emit('input', this.selectedItems.map(item => item.value));
            },
        }
    };
</script>

<style scoped>

</style>
