<template>
  <div v-if="userId">
    <div class="headline ma-4">
      {{ $t('users.permissions.title') }}
    </div>
    <div class="ml-4">
      {{ $t('users.permissions.info') }}
    </div>
    <v-card>
      <x-data-table
        v-if="!stocksLoading"
        :headers="headers"
        :items.sync="items"
        :item-class="item => item.visible === false && 'text--disabled'"
        :actions="actions"
        :batch-actions="batchActions"
      >
        <template #item.stock_id="{ item }">
          {{ getStockName(item.stock_id) }}
        </template>
        <template #item.has_permission="{ item }">
          <template v-if="loading">
            <v-progress-circular
              size="25"
              color="muted"
              indeterminate
            />
          </template>
          <template v-else>
            <v-icon
              v-if="item.has_permission"
              color="success"
            >
              $success
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              $closeItem
            </v-icon>
          </template>
        </template>
      </x-data-table>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {UserPermissionsAPI} from "@/api/UserPermissionsAPI";
    import {StockAPI} from "@/api/StockAPI";
    import {createHeaders} from "@/utils/table";
    import {permissionsTable} from "@/app/users/definition/permissions.form";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {PermissionType} from "@/enum/permissionTypes";

    export default {
        name: "UserPermissionsList",
        mixins: [RouteParamsMapperMixin, ACLMixin],
        data: () => ({
            loading: false,
            stocksLoading: false,
            headers: [],
            items: [],
            confirmDialog: false,
            deletedProduct: null,
            reload: 0,
            stocks: []
        }),
        createdOrActivated: function () {
            this.headers = createHeaders(permissionsTable, 'stocks.substocks.');
            this.getData();
            this.getStocks();
        },
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/stocks/' + item.stock_id + '/substocks/' + item.id,
                        icon: '$showItem',
                        label: 'base.view',
                        condition: item => item.has_permission
                    }, {
                        condition: item => (this.isChief || item.id === this.$store.getters['oauth/getUserId']) && item.has_permission,
                        loading: this.loading,
                        action: item => this.updatePermissions(item, false),
                        icon: '$unassign',
                        label: 'users.permissions.removeAccess'
                    }, {
                        condition: item => (this.isChief || item.id === this.$store.getters['oauth/getUserId']) && !item.has_permission,
                        loading: this.loading,
                        action: item => this.updatePermissions(item, true),
                        icon: '$addUserAlt',
                        label: 'users.permissions.addAccess'
                    }
                ];
            },
            batchActions: function () {
                return [
                    {
                        action: users => this.updatePermissions(users, true),
                        loading: this.loading,
                        icon: '$addUserAlt',
                        label: 'users.permissions.addAccess'
                    }, {
                        action: users => this.updatePermissions(users, false),
                        loading: this.loading,
                        icon: '$unassign',
                        label: 'users.permissions.removeAccess'
                    }
                ];
            },
        },
        methods: {
            getData() {
                this.loading = true;
                StockAPI.getAllSubstockWOStockPages()
                    .then(res => {
                        this.items = res.data.items;
                        UserPermissionsAPI.getPermissionsByUser(this.userId)
                            .then(res => {
                                const permissions = res.data.items;
                                this.items.map(item => {
                                    const permission = permissions.find(el => el.substock_id === item.id);
                                    item.has_permission = permission ? permission.allowed : false;
                                });
                            }).finally(() => {
                                this.loading = false;
                            });
                    });
            },
            getStocks() {
                this.stocksLoading = true;
                StockAPI.getAllPages()
                    .then(res => {
                        this.stocks = res.data.items;
                    }).catch(err => this.snack(err))
                    .finally(() => {
                        this.stocksLoading = false;
                    });
            },
            getStockName(stockId) {
                return this.stocks.find(stock => stock.id === stockId)?.name ?? '';
            },
            updatePermissions(substocks, allowAccess) {
                const data = {
                    substock_ids: Array.isArray(substocks) ? substocks : [substocks.id],
                    type: allowAccess ? PermissionType.ALLOWED : PermissionType.DENIED
                };
                UserPermissionsAPI.updateUserPermissions(this.userId, data)
                    .then(() => {
                        this.getData();
                    }).catch(err => this.snack(err));
            },
        }
    };
</script>
