function ProductBatchForm() {
    return {
        code: '',
        manufactured_date: null,
        expire_date: null,
    };
}

function ProductBatchRender(isReadOnly) {
    return {
        code: {
            icon: '$batchCode',
            max: 70,
            required: true,
            readonly: isReadOnly,
        },
        manufactured_date: {
            icon: '$manufacturedDate',
            dateTimePicker: true
        },
        expire_date: {
            icon: '$expiryDate',
            dateTimePicker: true,
        }
    };
}

export {ProductBatchForm, ProductBatchRender};
