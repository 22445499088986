<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <StockSubstock :substock="details.source_subordinate_stock" />
              </div>
              <div>
                <StockSubstock
                  :substock="details.destination_subordinate_stock"
                  direction="to"
                />
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :stock-id="details.source_subordinate_stock.stock_id"
            :sub-stock-id="details.source_subordinate_stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
      <TaskItemsCard
        :api="API"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :items="items"
        :task-info="taskInfo"
        task-lang-path="substockTransfer."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskSubstockTransferAPI as API} from "@/api/TaskSubstockTransferAPI";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import StockSubstock from "@/app/components/StockSubstock.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {taskTypes} from "@/enum/task_type";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";

    export default {
        name: "SubstockTransferAssignment",
        components: {TaskAttributes, StockSubstock, TaskAttachments, Notes, EntityHistory, TaskItemsCard},
        mixins: [TaskItemsCardType, TaskFetchItemsMixin, ACLMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            TaskItemsCardType: TaskItemsCardType,
            API: API,
            type: taskTypes.SUBSTOCK_TRANSFER
        }),
        computed: {
            details: function () {
                return this.taskInfo.details;
            }
        },
        watch: {
            'taskInfo.tab': function (tab) {
                if (tab === 0) {
                    this.fetchItemsDebounced({debounceQuantities: true});
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true}).then(() => {
                    this.ready = true;
                }).catch(this.snack);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
    };
</script>

<style scoped>

</style>
