<template>
  <span>
    <v-btn
      v-for="type of Object.values(ReportType).filter(t => (t.suffix !== 'csv' && t.suffix !== 'xlsx') || allowCsv)"
      :key="type.suffix"
      color="secondary"
      class="ml-2"
      :outlined="type.suffix !== 'pdf'"
      :loading="loading"
      type="submit"
      @click="report(type)"
    >
      <v-icon class="mr-2">
        $taskReport
      </v-icon>
      <span v-if="type.suffix === 'pdf'">
        {{ $t('tasks.generateReport.pdf') }}
      </span>
      <span v-else>
        .{{ type.suffix.toUpperCase() }}
      </span>
    </v-btn>
    <v-divider
      class="my-4"
    />
  </span>
</template>

<script>
    import * as Export from "@/service/Export";
    import {ReportType} from "@/enum/report_type";

    export default {
        name: "TaskPrintReport",
        props: {
            api: {
                type: Object,
                default: null
            },
            allowCsv: {
                type: Boolean,
                default: false
            },
            exportHandler: {
                type: Function,
                default: () => {
                }
            }
        },
        data: () => ({
            loading: false,
            ReportType: ReportType
        }),
        methods: {
            report: async function (reportType) {
                if (reportType.suffix === 'csv') {
                    this.exportHandler();
                } else if (reportType.suffix === 'xlsx'){
                    this.exportHandler(false);
                } else {
                    this.loading = true;
                    Export.report(this.api, this.$route.params.taskId, reportType)
                        .catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
