import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

function resolve(processingMode) {
    return this.details && this.details.processing_mode === processingMode
        || this.taskInfo && this.taskInfo.details && this.taskInfo.details.processing_mode === processingMode;
}

/**
 * Requires:
 * - this[.taskInfo].details.processing_mode
 */
const TaskExternalOrderProcessingModeMixin = {
    computed: {
        isDoNotProcess: function () {
            return resolve.call(this, TaskExternalOrderProcessingMode.DO_NOT_PROCESS);
        },
        isProcessTogether: function () {
            return resolve.call(this, TaskExternalOrderProcessingMode.PROCESS_TOGETHER);
        },
        isProcessAvailable: function () {
            return resolve.call(this, TaskExternalOrderProcessingMode.PROCESS_AVAILABLE);
        }
    }
};

export {TaskExternalOrderProcessingModeMixin};
