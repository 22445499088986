import {Store} from "@/service/store/Store";
import axios from "axios";

function registerAxiosInterceptor() {
    let enabled = true;
    let runningRenew = false;
    let runningRenewPromise = null;

    axios.interceptors.request.use((config) => {
        return new Promise(resolve => {
            if (runningRenew && config.url.indexOf('/oauth/') === -1) {
                runningRenewPromise
                    .then(() => {
                        config.headers.Authorization = 'Bearer ' + Store.getters['oauth/getAccessToken'];
                        resolve(config);
                    });
            } else if (enabled && Store.getters['oauth/isAuthenticated']) {
                if (Store.getters['oauth/isAccessExpired']()) {
                    // Remove request interceptor
                    enabled = false;
                    runningRenew = true;
                    // Renew token
                    runningRenewPromise = Store.dispatch('oauth/renewToken', () => {
                        enabled = true;
                        runningRenew = false;
                        runningRenewPromise = null;
                        config.headers.Authorization = 'Bearer ' + Store.getters['oauth/getAccessToken'];
                    }).then(() => {
                        resolve(config);
                    });
                } else {
                    config.headers.Authorization = 'Bearer ' + Store.getters['oauth/getAccessToken'];
                    resolve(config);
                }
            } else {
                resolve(config);
            }
        });
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        return new Promise(resolve => {
            Store.dispatch('connection/change', true);
            resolve(response);
        });
    });
}

export {registerAxiosInterceptor};
