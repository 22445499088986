import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.label)+" "+_vm._s(_vm.tasks.length !== 0 ? (' (' + _vm.tasksCount + ')') : '')+" "),_c(VSpacer),_c('DashboardConfigLink',{attrs:{"config-enter-callback":_vm.configEnterCallback}}),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"text--disabled dashboardIcon",class:{loadingIcon: _vm.loading},attrs:{"disabled":_vm.loading},on:{"click":_vm.reloadData}},on),[_vm._v(" $reloadData ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('base.reload'))+" ")])],1),(_vm.tasksCount === 0)?_c(VAlert,{attrs:{"value":true,"icon":"$allTasksDone","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('homepage.noTasks'))+" ")]):_c('TaskListList',{attrs:{"tasks":_vm.tasks}}),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.moreDataAvailable),expression:"moreDataAvailable"}],ref:"moreData",attrs:{"disabled":_vm.loading,"block":"","text":"","color":"secondary"},on:{"click":_vm.fetchMoreData}},[_vm._v(" "+_vm._s(_vm.moreDataText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }