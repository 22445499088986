<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <AutocompleteWithIcons
      v-show="hardcodedAttribute === null"
      :lang-path="langPath"
      :value="attr"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
    <v-autocomplete
      v-if="showAutoComplete"
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      hide-details
      :rules="[formRules.required]"
      :items="values"
      :label="$t('base.filterConfig.select')"
      suffix="*"
    />
    <FormDateTimePicker
      v-else-if="showDatePicker"
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      single
      show-errors
      required
      :rules="[formRules.suppressMessage(formRules.required)]"
      :label="$t('base.filterConfig.fill')"
      :prepend-inner-icon="attrIcon"
    />
    <v-text-field
      v-else
      ref="textField"
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        filter[operator][attr]
      "
      outlined
      dense
      :label="$t('base.filterConfig.fill')"
      hide-details
      :rules="[formRules.required]"
      :type="htmlType"
      :prepend-inner-icon="attrIcon"
      suffix="*"
    />
  </div>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {APIFilterDataType} from "@/service/APIFilters";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";
    import AutocompleteWithIcons from "@/app/components/filterConfig/components/AutocompleteWithIcons.component.vue";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";

    export default {
        name: "FilterConfigBinaryOp",
        components: {AutocompleteWithIcons, FormDateTimePicker},
        mixins: [FilterMixin],
        data: () => ({
            formRules: formRules
        }),
        computed: {
            attr: function () {
                return Object.keys(this.filter[this.operator])[0];
            },
            attrIcon: function () {
                const item = this.items.find(el => el.value === this.attr);
                return item !== undefined ? item.icon : null;
            },
            values: function () {
                const property = this.possibleValues[this.attr];
                if ([APIFilterDataType.BOOLEAN].includes(property.type)) {
                    return [true, false].map(el => ({
                        text: this.$t(`base.filterConfig.booleanValues.${el}`),
                        value: el
                    }));
                }
                return property.values || [];
            },
            showAutoComplete: function () {
                return Array.isArray(this.possibleValues[this.attr].values) ||
                    (this.filters.type === FilterType.API && this.possibleValues[this.attr].type === APIFilterDataType.BOOLEAN);
            },
            showDatePicker: function () {
                return this.filters.type === FilterType.API && this.possibleValues[this.attr].type === APIFilterDataType.DATE;
            },
            showTextField: function () {
                return !this.showAutoComplete && !this.showDatePicker;
            },
        },
        watch: {
            showTextField: {
                handler: function () {
                    // when changing to text field, force validate the input field
                    this.$nextTick(() => {
                        if (this.$refs.textField) {
                            this.$refs.textField.validate(true);
                        }
                    });
                },
                immediate: true
            }
        },
        methods: {
            propertyChanged: function (newProperty) {
                const oldProperty = this.attr;
                const oldValue = this.filter[this.operator][oldProperty];
                delete this.filter[this.operator][oldProperty];

                const oldPossibleValues = this.possibleValues[oldProperty];
                const newPossibleValues = this.possibleValues[newProperty];

                if (!oldPossibleValues.values && !newPossibleValues.values && oldPossibleValues.type === newPossibleValues.type && !this.isValueEmpty(oldValue)) {
                    // Neither property has possible values, the types are the same and the value is not empty -> carry over the value
                    this.$set(this.filter[this.operator], newProperty, oldValue);
                } else {
                    // Set the default value for the new property
                    this.$set(this.filter[this.operator], newProperty, this.getDefaultDataTypeValue(newProperty));
                }
            }
        }
    };
</script>

<style scoped>

</style>
