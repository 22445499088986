<template>
  <div>
    <x-toolbar />
    <ExportsConfigListComponent />
  </div>
</template>

<script>
    import ExportsConfigListComponent from "@/app/exports/components/ExportConfigList.component";

    export default {
        name: "ExportsList",
        components: {ExportsConfigListComponent}
    };
</script>
