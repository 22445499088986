<template>
  <div>
    <v-btn
      :loading="loading"
      type="submit"
      color="accent"
      class="ma-2"
      @click="$emit('continue')"
    >
      <v-icon
        class="mr-2"
      >
        {{ actionIcons[action] }}
      </v-icon>
      {{ $t(langPath + 'self') }}
    </v-btn>
    <div
      v-if="taskAssignOnly || action !== TaskAction.ASSIGN"
      class="caption text--disabled ml-3 mb-2"
    >
      {{ $t(langPath + 'useReader') }}
    </div>
  </div>
</template>

<script>
    import {TaskAction} from "@/enum/task_action";
    import {actionIcons} from "@/enum/icons";

    export default {
        name: "TaskStartActionButton",
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            // whether task starts after assign or another action is needed afterward
            taskAssignOnly: {
                type: Boolean,
                default: true
            },
            action: {
                type: String,
                default: TaskAction.ASSIGN
            }
        },
        data: () => ({
            actionIcons: actionIcons,
            TaskAction: TaskAction
        }),
        computed: {
            langPath: function () {
                if (this.action === TaskAction.ASSIGN) {
                    if (this.taskAssignOnly) {
                        return 'tasks.' + this.action + '.';
                    }
                    return 'tasks.' + this.action + '.justAssign.';
                } else if (this.action === TaskAction.START_PROCESSING) {
                    return 'tasks.startProcessing.';
                }
                return 'tasks.' + this.action + '.';
            }
        }
    };
</script>

<style scoped>

</style>
