<template>
  <v-card-subtitle class="red--text">
    <v-icon color="error">
      $changedParametersWarn
    </v-icon>
    {{ $t(text) }}
  </v-card-subtitle>
</template>

<script>
    export default {
        name: "WarnText",
        props: {
            text: {
                type: String,
                default: '',
            }
        }
    };
</script>

<style scoped>

</style>
