const timeModule = {
    namespaced: true,
    persistent: true,
    state: {
        start: null,
        end: null,
        showNotice: false,
        navigationPath: '/',
        reloaded: false
    },
    actions: {
        start ({commit, getters, rootState}) {
            if (rootState.userConfig.trackTime) {
                if (getters.isRunning) {
                    if (getters.isReloaded) {
                        commit('isNotReloaded');
                    } else {
                        // This should not happen, time is supposed to be stopped when leaving a task
                        window.console.error('Time tracker already started!');
                    }
                } else {
                    commit('start');
                }
            }
        },
        stop ({commit, getters}) {
            if (getters.isRunning) {
                commit('hideNotice');
                commit('stop');
                commit('isNotReloaded');
            }
        },
        showNotice ({commit}, navigationPath) {
            commit('showNotice');
            commit('setNavigationPath', navigationPath);
        }
    },
    mutations: {
        start (state) {
            state.start = Date.now();
            state.end = null;
        },
        stop (state) {
            state.end = Date.now();
        },
        showNotice (state) {
            state.showNotice = true;
        },
        hideNotice (state) {
            state.showNotice = false;
        },
        setNavigationPath (state, navigationPath) {
            state.navigationPath = navigationPath;
        },
        isNotReloaded (state) {
            state.reloaded = false;
        }
    },
    getters: {
        isRunning: (state) => state.end === null && state.start !== null,
        current: (state, getters, rootState) => now => rootState.userConfig.trackTime
            ? getters.isRunning
                ? now - state.start
                : state.end - state.start
            : 0,
        currentHours: (state, getters) => now => getters.current(now) / (1000 * 60 * 60),
        isNoticeShown: (state) => state.showNotice,
        navigationPath: (state) => state.navigationPath,
        isReloaded: (state) => state.reloaded
    }
    // TODO pause
    // TODO save running time on server in user settings
};

export default timeModule;
