import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c('div',{staticClass:"d-flex justify-center"},[_c(VCard,{attrs:{"min-width":_vm.$vuetify.breakpoint.smAndUp ? 590 : 0}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VList,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('base.cache.contents'))+" ")]),_c(VCardSubtitle,[_vm._v(" ("+_vm._s(_vm._f("humanizeSize")(_vm.estimateUsed))+" "+_vm._s(_vm.$t('base.used'))+" / "+_vm._s(_vm._f("humanizeSize")(_vm.estimateFree))+" "+_vm._s(_vm.$t('base.free'))+") ")]),_vm._l((_vm.entries),function(entry){return _c(VListItem,{key:entry.path},[_c(VListItemIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_c(VIcon,{style:(entry.path === 'allowedLocationIds' ? 'transform: rotate(90deg)' : '')},[_vm._v(" "+_vm._s(entry.icon)+" ")])],1),_c(VListItemContent,{class:{'text--disabled': !_vm.counts[entry.path]}},[_c(VListItemTitle,[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(" "+_vm._s(_vm.counts[entry.path])+"x ")]),_vm._v(" "+_vm._s(_vm.$t('base.cache.' + entry.path))+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm._f("humanizeSize")(_vm.sizes[entry.path] * _vm.counts[entry.path]))+" ")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"small":_vm.$vuetify.breakpoint.xsOnly,"disabled":!_vm.counts[entry.path]},on:{"click":function($event){return _vm.clear(entry.path)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v(" "+_vm._s(_vm.$t('base.cache.clear'))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}]},[_c(VIcon,[_vm._v("$cacheDelete")])],1)])],1)],1)}),_c(VBtn,{staticClass:"my-2",attrs:{"block":""},on:{"click":_vm.clearAll}},[_vm._v(" "+_vm._s(_vm.$t('base.cache.clearAll'))+" ")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }