import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {merge} from "@/utils/object";
import {PrintType} from "@/enum/print_type";

const ProductAPI = {

    API: BaseAPI,
    DOMAIN: 'products',
    DOMAIN_ATTRIBUTES: 'attributes',
    DOMAIN_BATCHES: 'batches',
    DOMAIN_IMAGES: 'images',
    DOMAIN_INSTANCES: 'instances',
    DOMAIN_BARCODES: 'barcodes',
    DOMAIN_INSTANCE_TYPES: 'types',
    DOMAIN_SERIALS: 'serial-numbers',
    LANG_SERIALS: 'serial_numbers',
    LANG_CUSTOM_INSTANCES: 'customInstances',
    LAST_MODIFIED: 'last-modified',
    IF_UNMODIFIED_SINCE: 'If-Unmodified-Since',

    attributesLastModified: '',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, merge(...params));
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getVisibleAllPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, 'visible'], this.DOMAIN, merge(...params));
    },

    get(productId) {
        return APIHelper.getWithCache({
            collection: CachePath.products,
            key: productId,
            requestParams: [[this.DOMAIN, productId]]
        });
    },

    getVersion(productId) {
        return this.API.head(
            [this.DOMAIN, productId]
        ).then(response => {
            this.attributesLastModified = response.headers[this.LAST_MODIFIED];
            return response;
        });
    },

    create(data) {
        return this.API.post(this.DOMAIN, data);
    },

    update(productId, data) {
        return this.API.patch([this.DOMAIN, productId], data);
    },

    delete(productId) {
        return this.API.delete([this.DOMAIN, productId], this.DOMAIN);
    },


    // Attributes

    getAllPossibleAttributes() {
        return this.API.get([this.DOMAIN, this.DOMAIN_ATTRIBUTES, 'keys']);
    },

    getAllAttributes(productId) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES]
        ).then(response => {
            this.attributesLastModified = response.headers[this.LAST_MODIFIED];
            return response;
        });
    },

    updateAttributes(productId, data) {
        return this.API.put(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES],
            {attributes: data},
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            {
                [this.IF_UNMODIFIED_SINCE]: this.attributesLastModified
            }
        );
    },

    getAttribute(productId, key) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES, encodeURIComponent(key)],
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES]
        ).then(response => {
            this.attributesLastModified = response.headers[this.LAST_MODIFIED];
            return response;
        });
    },

    createAttribute(productId, data) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES],
            data,
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            undefined,
            {
                headers: {
                    [this.IF_UNMODIFIED_SINCE]: this.attributesLastModified
                }
            }
        );
    },

    updateAttribute(productId, key, data) {
        return this.API.patch(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES, encodeURIComponent(key)],
            data,
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            {
                [this.IF_UNMODIFIED_SINCE]: this.attributesLastModified
            }
        );
    },

    deleteAttribute(productId, key) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_ATTRIBUTES, encodeURIComponent(key)],
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            {
                [this.IF_UNMODIFIED_SINCE]: this.attributesLastModified
            }
        );
    },


    // Batches

    /**
     * @param productId {int}
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     * }}
     * @returns {Promise<unknown>}
     */
    getAllBatches(productId, params = undefined) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES],
            this.DOMAIN,
            params
        );
    },
    getAllBatchesAllPages(productId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES],
            this.DOMAIN,
            merge(...params)
        );
    },

    getBatch(productId, batchCode) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES, batchCode],
            [this.DOMAIN, this.DOMAIN_BATCHES]
        );
    },

    createBatch(productId, data) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES],
            data,
            [this.DOMAIN, this.DOMAIN_BATCHES]
        );
    },

    updateBatch(productId, batchCode, data) {
        return this.API.patch(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES, batchCode],
            data,
            [this.DOMAIN, this.DOMAIN_BATCHES]
        );
    },

    deleteBatch(productId, batchCode) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_BATCHES, batchCode],
            [this.DOMAIN, this.DOMAIN_BATCHES, 'delete']
        );
    },


    // Serial numbers

    /**
     * @param productId {int}
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     * }}
     * @returns {Promise<unknown>}
     */
    getAllSerials(productId, params = undefined) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_SERIALS],
            [this.DOMAIN_BARCODES],
            params
        );
    },
    getAllSerialsAllPages(productId) {
        return APIHelper.getAllPages(
            [this.DOMAIN, productId, this.DOMAIN_SERIALS],
            [this.DOMAIN_BARCODES]
        );
    },

    getSerial(productId, serialNumber) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_SERIALS, serialNumber],
            [this.DOMAIN, this.LANG_SERIALS]
        );
    },

    createSerial(productId, data) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_SERIALS],
            data,
            [this.DOMAIN, this.LANG_SERIALS]
        );
    },

    deleteSerial(productId, serialNumber) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_SERIALS, serialNumber],
            [this.DOMAIN, this.LANG_SERIALS, 'delete']
        );
    },


    // Custom instances

    createCustomInstance(productId, data) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES],
            data,
            [this.DOMAIN, this.LANG_CUSTOM_INSTANCES]
        );
    },

    updateCustomInstance(productId, instanceId, data) {
        return this.API.patch(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId],
            data,
            [this.DOMAIN, this.LANG_CUSTOM_INSTANCES, 'update']
        );
    },

    deleteCustomInstance(productId, instanceId) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId],
            [this.DOMAIN, this.LANG_CUSTOM_INSTANCES, 'delete']
        );
    },


    // Images

    getAllImages(productId) {
        return this.API.get([this.DOMAIN, productId, this.DOMAIN_IMAGES]);
            // .then(response => {
            //     response.data.forEach(image => {
            //         image.url = image.url.replace('http://', 'https://');
            //     });
            //     return response;
            // });
    },

    getImageInfo(productId, imageId) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, imageId],
            [this.DOMAIN, this.DOMAIN_IMAGES]
        );
    },

    createLocalImage(productId, image) {
        const data = new FormData();
        data.append('image', image);
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, 'local'],
            data,
            [this.DOMAIN, this.DOMAIN_IMAGES],
            'multipart/form-data'
        );
    },

    createExternalImage(productId, url, store) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, 'external'],
            {url: url, store: store}
        );
    },

    updateImage(productId, imageId, is_main_photo) {
        return this.API.patch(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, imageId],
            {is_main_photo},
            [this.DOMAIN, this.DOMAIN_IMAGES]
        );
    },

    deleteImage(productId, imageId) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, imageId],
            [this.DOMAIN, this.DOMAIN_IMAGES]
        );
    },

    storeExternalImage(productId, imageId) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_IMAGES, imageId, 'store'],
            [this.DOMAIN, this.DOMAIN_IMAGES]
        );
    },

    // Instances

    /**
     * @param productId {int}
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAllInstances(productId, ...params) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES],
            this.DOMAIN,
            merge(...params)
        );
    },
    getAllInstancesAllPages(productId, ...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES],
            this.DOMAIN,
            merge(...params)
        );
    },

    getInstance(productId, instanceId) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId],
            [this.DOMAIN, this.DOMAIN_INSTANCES]
        );
    },

    getInstanceWOProduct(instanceId) {
        return APIHelper.getWithCache({
            collection: CachePath.instances,
            key: instanceId,
            requestParams: [[this.DOMAIN, this.DOMAIN_INSTANCES, instanceId],
                [this.DOMAIN, this.DOMAIN_INSTANCES]]
        });
    },

    getInstanceByUrl(url) {
        return this.API.get(
            url,
            [this.DOMAIN, this.DOMAIN_INSTANCES]
        );
    },

    getDirectProductIdForInstance(instanceId) {
        return ProductAPI.getInstanceWOProduct(instanceId)
            .then(response => {
                return response.data.product.id;
            });
    },


    // Custom instance types

    getAllCustomInstanceTypes() {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES],
            [this.DOMAIN]
        );
    },

    getCustomInstanceTypesForProduct(productId) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES],
            [this.DOMAIN]
        );
    },

    createCustomInstanceType(data) {
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES],
            data,
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES]
        );
    },

    getCustomInstanceType(instanceTypeId) {
        return APIHelper.getWithCache({
            collection: CachePath.instanceTypes,
            key: instanceTypeId,
            requestParams: [[this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES, instanceTypeId],
                [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES]]
        });
    },

    updateCustomInstanceType(instanceTypeId, data) {
        return this.API.put(
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES, instanceTypeId],
            data,
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES]
        );
    },

    deleteCustomInstanceType(instanceTypeId) {
        return this.API.delete(
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES, instanceTypeId],
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_INSTANCE_TYPES, 'delete']
        );
    },


    // Instance barcodes

    getAllInstanceBarcodes(productId, instanceId) {
        return APIHelper.getWithCache({
            collection: CachePath.instanceBarcodes,
            key: instanceId,
            requestParams: [[this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES],
                [this.DOMAIN, this.DOMAIN_INSTANCES]]
        });
    },

    getInstanceBarcode(productId, instanceId, code) {
        return this.API.get(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES, code],
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES]
        );
    },

    printInstanceImplicitBarcode(productId, instanceId, type = PrintType.PDF, amount = 1, product_quantity = 1) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES, 'implicit', 'print'],
            {
                type: type,
                amount: amount,
                product_quantity: product_quantity
            },
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES]
        );
    },

    printInstanceBarcode(productId, instanceId, code, type = PrintType.PDF, amount = 1) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES, code, 'print'],
            {
                type: type,
                amount: amount
            },
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES]
        );
    },

    printInstanceBarcodes(type = PrintType.PDF, codes, amount = 1) {
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES, 'batch', 'print'],
            {
                type: type,
                codes: codes,
                amount: amount
            }
        );
    },

    createInstanceBarcode(productId, instanceId, data) {
        return this.API.post(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES],
            data,
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES],
            undefined,
            undefined,
            false
        );
    },

    updateInstanceBarcode(productId, instanceId, code, data) {
        return this.API.patch(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES, code],
            data,
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES]
        );
    },

    deleteInstanceBarcode(productId, instanceId, code) {
        return this.API.delete(
            [this.DOMAIN, productId, this.DOMAIN_INSTANCES, instanceId, this.DOMAIN_BARCODES, code],
            [this.DOMAIN, this.DOMAIN_INSTANCES, this.DOMAIN_BARCODES]
        );
    }
};

export {ProductAPI};
