import {taskNames, taskIcons, taskLinks, taskTypes} from "@/enum/task_type";

const actionsStorekeeper = [
    {
        icon: taskIcons[taskTypes.DELIVERY_ACCEPT],
        label: taskNames[taskTypes.DELIVERY_ACCEPT],
        to: taskLinks[taskTypes.DELIVERY_ACCEPT]
    }
];

export {actionsStorekeeper};
