<template>
  <div
    v-if="selectedObject !== null"
  >
    <div
      v-if="!renameInProgress"
    >
      <v-icon
        left
        class="mb-1 mr-4"
      >
        {{ selectedObjectHeader.icon }}
      </v-icon>
      {{ selectedObjectHeader.title }}
      <v-btn
        v-if="selectedObject.type !== MapObjectType.LOCATIONS_GROUP"
        outlined
        small
        class="mb-1 ml-4"
        @click="renameInProgress=true"
      >
        <v-icon
          left
          small
        >
          $editName
        </v-icon>
        {{ $t('map.rename') }}
      </v-btn>
    </div>
    <div
      v-if="renameInProgress"
    >
      <div
        class="d-flex align-center"
      >
        <v-icon
          left
          class="mb-1 mr-4"
        >
          {{ selectedObjectHeader.icon }}
        </v-icon>
        <v-form
          v-model="nameFormValid"
          class="d-flex align-center"
          @submit.prevent
        >
          <FormFields
            :form="objectNameForm"
            :render="objectNameFormRender"
            lang-path="map."
          />
          <v-btn
            small
            :text="!nameFormValid"
            type="submit"
            color="accent"
            class="mt-1 ml-2 mb-2"
            @click="rename"
          >
            <v-icon>
              $tick
            </v-icon>
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
    import {MapObjectType} from '@/enum/stock_map';
    import {MapMixin} from "@/app/mixins/MapMixin";
    import i18n from "@/service/lang/i18n";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {
        ObjectNameForm, ObjectNameRender,
    } from "@/app/stocks/map/definition/objectDetails.form";
    export default {
        name: 'RenameObjectForm',
        components: {FormFields},
        mixins: [ MapMixin ],
        data: () => ({
            renameInProgress: false,
            nameFormValid: false,
            objectNameForm: new ObjectNameForm(),
            objectNameFormRender: new ObjectNameRender(),
        }),
        computed: {
            selectedObjectHeader: function () {
                if(this.selectedObject === null) {
                    return {icon: '', title: ''};
                }
                else if (this.selectedObject.type === MapObjectType.RACK) {
                    return {icon: '$rack', title: this.selectedObject.name};
                }
                else if (this.selectedObject.type === MapObjectType.ZONE) {
                    return {icon: '$zone', title: this.selectedObject.name};
                }
                else if (this.selectedObject.type === MapObjectType.OBSTACLE) {
                    return {icon: '$obstacle', title: this.selectedObject.name};
                }
                else {
                    return {icon: '$locationGroup', title: i18n.t('map.locationGroups')};
                }
            },
            objectName: function () {
                if(this.selectedObject !== null) {
                    return this.selectedObject.name;
                }
                return '';
            }

        },
        watch: {
            selectedObject: function () {
                this.renameInProgress = false;
                this.initializeFormValues();
            },
            objectName: function () {
                if (this.selectedObject !== null) {
                    this.objectNameForm.newName = this.selectedObject.name;
                }
            },
        },
        createdOrActivated() {
            this.initializeFormValues();
        },
        methods: {
            rename: function() {
                if (this.nameFormValid) {
                    this.$store.dispatch('storageMap/renameObject', {
                        name: this.objectNameForm.newName
                    });
                    this.renameInProgress = false;
                }
            },
            initializeFormValues: function() {
                if (this.selectedObject === null) {
                    this.objectNameForm.newName = null;
                }
                else if (this.selectedObject.type !== MapObjectType.LOCATIONS_GROUP) {
                    this.objectNameForm.newName = this.selectedObject.name;
                }
            },
        }
    };
</script>

<style scoped>

</style>
