import Vue from "vue";
import {StockAPI} from "@/api/StockAPI";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";

// Share Cache values across all components which use this mixin
const LocationCache = {};
const RunningPromises = {};
let trigger = Vue.observable({count: 0});

const ReactiveLocationCacheMixin = {
    computed: {
        loadingLabel: function () {
            return `<${this.$t('base.loading')}...`;
        },
        LocationCache: function () {
            // We need this to trigger an update in each component which might be using this Mixin simultaneously
            trigger.count;
            return LocationCache;
        }
    },
    methods: {
        /**
         * @param locationId {number}
         * @return {Promise<{data}>}
         */
        cacheLocation: function (locationId) {
            if (RunningPromises[locationId]) {
                // location already being fetched
                return RunningPromises[locationId];
            } else if (LocationCache[locationId] === undefined || typeof LocationCache[locationId] === 'string') {
                // location not fetched or being fetched
                LocationCache[locationId] = this.loadingLabel;
                const promise = StockAPI.getLocationWOStock(locationId).then(response => {
                    LocationCache[locationId] = response.data;
                    trigger.count++;
                    return response;
                });
                RunningPromises[locationId] = promise.finally(() => delete RunningPromises[locationId]);
                return promise;
            } else {
                // location already fetched
                return Promise.resolve({data: LocationCache[locationId]});
            }
        },
        /**
         * Prepare cache entry, but only fetch it once it is read for the first time.
         * @param locationId {number}
         */
        cacheLocationLazy: function (locationId) {
            if (!LocationCache[locationId]) {
                Object.defineProperty(LocationCache, locationId, {
                    configurable: true,
                    get: () => {
                        Object.defineProperty(LocationCache, locationId, {
                            configurable: true,
                            writable: true,
                            value: this.loadingLabel,
                        });
                        StockAPI.getLocationWOStock(locationId).then(response => {
                            LocationCache[locationId] = response.data;
                            trigger.count++;
                        }).catch(this.snack);
                        return this.loadingLabel;
                    },
                    set: value => {
                        Object.defineProperty(LocationCache, locationId, {
                            configurable: true,
                            writable: true,
                            value: value,
                        });
                    }
                });
            }
        },
        /**
         * Cache many stock locations at once using paged EP
         * @param stockId {number}
         * @param locationIds {number[]}
         * @return {Promise<{data}>}
         */
        cacheLocationMany: function (stockId, locationIds) {
            if (!stockId) {
                return Promise.resolve();
            }

            const missingIds = locationIds.filter(id => LocationCache[id] === undefined && RunningPromises[id] === undefined);
            const runningPromises = locationIds.filter(id => RunningPromises[id] !== undefined).map(id => RunningPromises[id]);
            if (missingIds.length === 0) {
                return Promise.allSettled(runningPromises);
            }

            missingIds.forEach((id) => {
                LocationCache[id] = this.loadingLabel;
            });

            const newPromises = [];
            // paginate filter due to URL length limitations
            const filterChunkSize = 100;

            for (let i = 0; i < missingIds.length; i += filterChunkSize) {
                const chunkLocationIds = missingIds.slice(i, i + filterChunkSize);

                const filter = {
                    [APIFilterOP.IN]: {
                        'id': chunkLocationIds
                    }
                };
                const promise = StockAPI.getAllLocations(stockId, { filter: APIFilters.makeFilter(filter)})
                    .then(response => {
                        (response.data.items || []).forEach((location) => {
                            LocationCache[location.id] = location;
                            trigger.count++;
                        });
                        return response;
                    });
                newPromises.push(promise);
                missingIds.forEach((id) => {
                    RunningPromises[id] = promise
                        .then(response => {
                            const location = (response.data.items || []).find(loc => loc.id === id) ?? this.loadingLabel;
                            return {
                                data: location
                            };
                        })
                        .finally(() => delete RunningPromises[id]);
                });
            }

            return Promise.allSettled([...runningPromises, ...newPromises]);
        }
    }
};

export {ReactiveLocationCacheMixin};
