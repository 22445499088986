import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {
    StockId,
    SubordinateStockId,
    TaskDescription,
    TaskPriority,
    TaskStrictMode
} from "@/app/tasks/definitions/taskCommon.render.form";
import {EventBus} from "@/service/EventBus";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

function MoveProductsCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        stock_id: null,
        subordinate_stock_id: null,
        source_location_id: -1,
        destination_location_id: -1,
        transfer_mode: TaskItemsStrictMode.FREE,
        attachments: [],
        items: []
    };
}

function MoveProductsCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: new SubordinateStockId,
        source_location_id: {
            icon: '$taskSource',
            autocomplete: {
                items: []
            }
        },
        destination_location_id: {
            icon: '$taskDestination',
            autocomplete: {
                items: []
            },
            createNew: {
                icon: '$addLocation',
                label: 'stocks.locations.create.label',
                condition: (form) => {
                    return form.stock_id !== undefined && form.stock_id !== null
                    && form.subordinate_stock_id !== undefined && form.subordinate_stock_id !== null;
                },
                action: () => {
                    EventBus.$emit('create-location');
                }
            }
        },
        transfer_mode: new TaskStrictMode,
        description: new TaskDescription
    };
}

export {MoveProductsCreateForm, MoveProductsCreateRender};
