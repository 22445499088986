import i18n from "@/service/lang/i18n";
import en from "@/lang/help.en";
import cs from "@/lang/help.cs";
import sk from "@/lang/help.sk";
import hu from "@/lang/help.hu";
import uk from  "@/lang/help.uk";

const data = {en, cs, sk, hu, uk};

const HelpProvider = {
    get: () => data[i18n.locale]
};

export {HelpProvider};
