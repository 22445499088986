<template>
  <div>
    <v-autocomplete
      :key="key"
      dense
      class="searchInput-hideAppend"
      :hide-details="'auto'"
      :hide-no-data="!search || loading"
      :items="items"
      :messages="$t('tasks.search')"
      :label="$t('base.search')"
      :loading="loading"
      loader-height="6"
      :menu-props="{
        'maxHeight': '90vh'
      }"
      no-filter
      outlined
      :search-input.sync="search"
      @update:search-input="onUpdateInput"
      @input="onChooseSelect"
    >
      <template #append-outer>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <span v-on="on"><v-icon>$info</v-icon></span>
          </template>
          <span>{{ $t('base.searchResult.searchesIn') }}</span>
        </v-tooltip>
      </template>
      <template #message="{ message }">
        <router-link
          :to="{ name: 'tasksOverview'}"
          class="text--primary text-decoration-none"
        >
          {{ message }}
          <v-icon x-small>
            $openItem
          </v-icon>
        </router-link>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
    import {
        tasksById,
        tasksByExternalId,
        tasksByState,
        tasksByPriority,
        tasksByType,
        productsByBarcode,
        productsByManufacturer,
        productsByName,
        productsByModel,
        stocksByName,
        locationsByBarcode,
        buyersByIco,
        suppliersByIco,
        stockOwnersByIco
    } from "@/app/components/search/searchDataSource";
    import {debounce} from 'lodash';

    export default {
        name: "Search",
        data: () => ({
            search: null,
            loading: false,
            items: [],
            key: 0,
            langPath: 'base.searchResult.',
            dataSources: [
                {
                    label: 'tasksById',
                    source: tasksById
                }, {
                    label: 'tasksByExternalId',
                    source: tasksByExternalId
                }, {
                    label: 'tasksByState',
                    source: tasksByState
                }, {
                    label: 'tasksByPriority',
                    source: tasksByPriority
                }, {
                    label: 'tasksByType',
                    source: tasksByType
                }, {
                    label: 'productsByBarcode',
                    source: productsByBarcode
                }, {
                    label: 'productsByManufacturer',
                    source: productsByManufacturer
                }, {
                    label: 'productsByName',
                    source: productsByName
                }, {
                    label: 'productsByModel',
                    source: productsByModel
                }, {
                    label: 'stocksByName',
                    source: stocksByName
                }, {
                    label: 'locationsByBarcode',
                    source: locationsByBarcode
                }, {
                    label: 'buyersByIco',
                    source: buyersByIco
                }, {
                    label: 'suppliersByIco',
                    source: suppliersByIco
                }, {
                    label: 'stockOwnersByIco',
                    source: stockOwnersByIco
                }
            ]
        }),
        computed: {
            debouncedRefreshSearch: function () {
                return debounce(this.refreshSearch, 500);
            },
            trimmedSearch: function () {
                return typeof this.search === 'string' ? this.search.trim() : '';
            },
            searchValid: function () {
                return !!(this.search && this.trimmedSearch);
            }
        },
        methods: {
            onUpdateInput: function () {
                this.loading = this.searchValid;
                this.debouncedRefreshSearch();
            },
            refreshSearch: function () {
                this.items = [];
                if (this.searchValid) {
                    this.loading = true;
                    const promises = [];
                    for (const {label, source} of this.dataSources) {
                        promises.push(
                            source(this.trimmedSearch).then(result => {
                                if (result.length) {
                                    this.items.push(...[
                                        {header: this.$t(this.langPath + label, [this.trimmedSearch])},
                                        ...result,
                                    ]);
                                }
                            })
                        );
                    }
                    Promise.all(promises).finally(() => {
                        this.loading = false;
                    });
                }
            },
            onChooseSelect: function (value) {
                this.search = null;
                this.loading = false;
                this.items = [];
                this.key++;
                this.$router.push(value.link);
            }
        }
    };
// TODO search tasks by anything API allows
</script>

<style lang="sass">
.searchInput-hideAppend .v-input__append-inner
    display: none
</style>
