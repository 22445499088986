import Vue from "vue";

// Share Cache values across all components which use this mixin
const SubStockCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveSubStockCacheMixin = {
    computed: {
        loadingLabel: function () {
            return `<${this.$t('base.loading')}...`;
        },
        SubStockCache: function () {
            // We need this to trigger an update in each component which might be using this Mixin simultaneously
            trigger.count;
            return SubStockCache;
        }
    },
    methods: {
        /**
         * @param runningRequest {Promise<{data}>}
         * @param key {number|string}
         * @return {Promise<{data}>}
         */
        cacheSubStock: function (runningRequest, key) {
            SubStockCache[key] = this.loadingLabel;
            return runningRequest.then(response => {
                SubStockCache[key] = response.data;
                trigger.count++;
                return response;
            });
        }
    }
};

export {ReactiveSubStockCacheMixin};
