<template>
  <div>
    <x-toolbar />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
      >
        <template #footer>
          <TableAddItemButton
            :to="manufacturerCreateLink"
            label="manufacturers.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="manufacturerCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {ManufacturerAPI as API} from "@/api/ManufacturerAPI";
    import {EventBus} from "@/service/EventBus";
    import {createHeaders} from "@/utils/table";
    import {manufacturersTable} from "@/app/manufacturers/definitions/manufacturer.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ManufacturersList",
        components: {TableAddItemButton},
        mixins: [EventsListenerMixin],
        data: () => ({
            loading: true,
            items: [],
            manufacturerCreateLink: '/manufacturers/create',
            reload: 0
        }),
        computed: {
            events: function () {
                return {
                    'manufacturers-list-reload': () => this.reload++
                };
            },
            headers: () => createHeaders(manufacturersTable, 'manufacturers.'),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        action: this.updateItem,
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return API.getAll.bind(API);
            },
            apiDataSourceAllPages: function () {
                return API.getAllPages.bind(API);
            }
        },
        methods: {
            updateItem: function (item) {
                this.$router.push('/manufacturers/' + item.id + '/update');
            },
            deleteItem: function (item) {
                this.loading = true;
                API.delete(item.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'manufacturers.delete.done',
                            params: [item.abbreviation],
                            timeout: 0,
                            undo: API.undoDeleteFn(item.id, () => {
                                EventBus.$emit('manufacturers-list-reload');
                                this.advancedSnack({
                                    text: 'manufacturers.delete.undo.done',
                                    params: [item.abbreviation]
                                });
                            }, () => {
                                this.advancedSnack({
                                    text: 'manufacturers.delete.undo.failed',
                                    timeout: 0
                                });
                            })
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.reload++;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
