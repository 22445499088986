import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{key:_vm.key,staticClass:"searchInput-hideAppend",attrs:{"dense":"","hide-details":'auto',"hide-no-data":!_vm.search || _vm.loading,"items":_vm.items,"messages":_vm.$t('tasks.search'),"label":_vm.$t('base.search'),"loading":_vm.loading,"loader-height":"6","menu-props":{
      'maxHeight': '90vh'
    },"no-filter":"","outlined":"","search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.onUpdateInput],"input":_vm.onChooseSelect},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c(VIcon,[_vm._v("$info")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('base.searchResult.searchesIn')))])])]},proxy:true},{key:"message",fn:function({ message }){return [_c('router-link',{staticClass:"text--primary text-decoration-none",attrs:{"to":{ name: 'tasksOverview'}}},[_vm._v(" "+_vm._s(message)+" "),_c(VIcon,{attrs:{"x-small":""}},[_vm._v(" $openItem ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }