// In production mode, 'view' components can have both 'created' and 'activated' lifecycle hooks called,
// based on their 'keep-alive' state, so we need to attach event hooks just once.

/**
 * Use this mixin whenever you need to fetch something on both 'created' and 'activated' lifecycle hooks, but not in both simultaneously
 */
const CreatedOrActivatedMixin = {
    data: () => ({
        _createdOrActivatedCalled: false,
        LifeCycleHook: {
            CREATED: 0,
            ACTIVATED: 1
        }
    }),
    created: function () {
        this._createdOrActivated(this.LifeCycleHook.CREATED);
    },
    activated: function () {
        this._createdOrActivated(this.LifeCycleHook.ACTIVATED);
    },
    deactivated: function () {
        this._createdOrActivatedCalled = false;
    },
    destroyed: function () {
        this._createdOrActivatedCalled = false;
    },
    methods: {
        _createdOrActivated: function (lifeCycleHook) {
            if (!this._createdOrActivatedCalled) {
                this._createdOrActivatedCalled = true;
                if (Array.isArray(this.$options.createdOrActivated)) {
                    this.$options.createdOrActivated.forEach(hook => {
                        hook.call(this, lifeCycleHook);
                    });
                } else if (this.$options.createdOrActivated === 'function') {
                    this.$options.createdOrActivated(lifeCycleHook);
                }
            }
        }
    }
};

export {CreatedOrActivatedMixin};
