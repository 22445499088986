<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedType !== null"
      :show.sync="confirmDialog"
      :text="deletedType.name"
      @confirm-delete="reallyDeleteType"
    />

    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('products.types.title') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :reload="reload"
            :show-search-bar="false"
            @update:loading="updateLoading"
            @update:items="updateItems"
          >
            <template #item.suggested_attributes="{ item }">
              <ProductTypeAttributesList
                :suggested-attributes="item.suggested_attributes"
              />
            </template>
            <template #footer>
              <TableAddItemButton
                to="/products/types/create"
                label="products.types.create.title"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <InstanceTypesList class="mt-4" />

    <x-btn-fab-expander
      :actions="fabActions"
    />
  </div>
</template>

<script>
    import {ProductTypeAPI} from "@/api/ProductTypeAPI";
    import {createHeaders} from "@/utils/table";
    import {productTypeTable} from "@/app/products/types/definitions/productType.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import InstanceTypesList from "@/app/products/instances/types/components/InstanceTypesList.component";
    import {productTypeFabActions} from "@/app/products/types/definitions/productTypeFabActions.definition";
    import XBtnFabExpander from "@/app/vuetify-extend/x-btn-fab-expander";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import ProductTypeAttributesList from "@/app/products/types/components/ProductTypeAttributesList.component";

    export default {
        name: "ProductTypesList",
        components: {XBtnFabExpander, TableAddItemButton, InstanceTypesList, ConfirmDeleteDialog, ProductTypeAttributesList},
        data: () => ({
            loading: false,
            items: [],
            confirmDialog: false,
            deletedType: null,
            reload: 0
        }),
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/products/types/' + item.id + '/update',
                        icon: "$updateItem",
                        label: 'base.edit'
                    }, {
                        action: this.deleteItem,
                        icon: '$deleteItemForever',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return ProductTypeAPI.getAll.bind(ProductTypeAPI, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return ProductTypeAPI.getAllPages.bind(ProductTypeAPI, this.apiFilterParam);
            },
            apiFilterParam: function () {
                const filter = {
                    [APIFilterOP.EQUALS]: {
                        default: false
                    }
                };
                return {filter: APIFilters.makeFilter(filter)};
            },
            headers: function () {
                return createHeaders(productTypeTable, 'products.types.');
            },
            fabActions: function () {
                return productTypeFabActions();
            }
        },
        methods: {
            updateLoading: function (newValue) {
                if (newValue) {
                    this.loading = true;
                }
                // else do nothing, we will set loading to false when suggested attributes are loaded
            },
            // we want to keep showing old items till suggested attributes are loaded
            updateItems: function (newValue) {
                this.fetchSuggestedAttributes(newValue);
            },
            fetchSuggestedAttributes: function (items) {
                const promises = [];
                items.map(type => {
                    promises.push(
                        ProductTypeAPI.getSuggestedAttributes(type.id).then(suggestedAttributes => {
                            type.suggested_attributes = suggestedAttributes.data;
                            return type;
                        })
                    );
                });
                Promise.all(promises).then(() => this.items = items)
                    .catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            deleteItem: function (item) {
                this.deletedType = item;
                this.confirmDialog = true;
            },
            reallyDeleteType: function () {
                this.loading = true;
                this.confirmDialog = false;
                ProductTypeAPI.delete(this.deletedType.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.types.delete.done',
                            params: [this.deletedType.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.reload++;
                        this.loading = false;
                    });
            },
        }
    };
</script>

<style scoped>

</style>
