import {Roles} from "@/enum/roles";
import SuppliersList from "@/app/suppliers/SuppliersList.view";
import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
import SupplierShow from "@/app/suppliers/SupplierShow.view.vue";

const BASE_URL = '/suppliers';

const routes = {
    base: {
        path: BASE_URL,
        component: SuppliersList,
        meta: {
            title: 'suppliers.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    show: {
        path: BASE_URL + '/:supplierId(\\d+)',
        component: SupplierShow,
        meta: {
            title: 'suppliers.show.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    create: {
        path: BASE_URL + '/create',
        component: SupplierCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'suppliers.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:supplierId(\\d+)/update',
        component: SupplierCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'suppliers.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            // TODO snack message
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
