import {DeliveryType, DeliveryNumber, InvoiceNumber, Note, SupplierId, ExternalOrderTaskId} from "@/app/tasks/definitions/taskCommon.render.form";
import {deliveryTypes} from "@/enum/delivery_type";
import {TableFilter} from "@/enum/table_filter";
import {TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {fullName} from "@/utils/filters";
import i18n from "@/service/lang/i18n";

function DeliveryAcceptForm() {
    return {
        delivery_type: deliveryTypes.DELIVERY,
        delivery_number: null,
        invoice_number: null,
        supplier_id: null,
        external_order_task_id: null,
        hours: null,
        note: null,
        attachments: []
    };
}

function DeliveryAcceptRender() {
    return {
        delivery_type: new DeliveryType,
        delivery_number: new DeliveryNumber,
        invoice_number: new InvoiceNumber,
        supplier_id: new SupplierId,
        external_order_task_id: new ExternalOrderTaskId,
        note: new Note
    };
}

const deliveryAcceptTasksTable = (users) => {
    return {
        id: {
            label: 'name',
            filterType: TableFilter.NUMBER
        },
        state: {
            label: 'state.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskState).map(state => ({
                text: i18n.t('tasks.state.' + state),
                value: state,
            })),
            itemLabel: item => i18n.t('tasks.state.' + item.state),
        },
        priority: {
            label: 'priority.name',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: Object.values(TaskPriority).map(priority => ({
                text: i18n.t('tasks.priority.' + priority),
                value: priority,
            })),
        },
        'assigned_user.id': {
            label: 'assigned_user',
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: users.map(user => ({
                text: user.first_name + ' ' + user.last_name,
                value: user.id
            })),
            itemLabel: item => item.assigned_user ? fullName(item.assigned_user) : '-',
        },
        delivery_type: {
            filterType: TableFilter.SELECT,
            filterItems: Object.values(deliveryTypes).map(type => ({
                text: i18n.t('tasks.deliveryAccept.deliveryType.' + type),
                value: type
            })),
        },
        invoice_number: {
            filterType: TableFilter.TEXT,
        },
        delivery_number: {
            filterType: TableFilter.TEXT,
        },
        'external_order.id': {
            label: 'external_order_task_id',
            filterType: TableFilter.NUMBER
        }
    };
};

export {DeliveryAcceptForm, DeliveryAcceptRender, deliveryAcceptTasksTable};
