import Vue from "vue";
import {TableFilter} from "@/enum/table_filter";

export const usersPermissionTable = {
    has_permission: {
        filterType: TableFilter.BOOLEAN,
    },
    first_name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    last_name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    username: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    email: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    phone: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    roles: {
        filterType: TableFilter.MULTI_BOOLEAN
    },
    active: {
        filterType: TableFilter.BOOLEAN,
        itemLabel: ({active}) => Vue.options.filters.yesOrNo(active)
    },
};