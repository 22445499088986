import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(!_vm.hideHeader)?_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.readOnly)?_c(VIcon,{staticClass:"ml-2",attrs:{"color":"muted"},on:{"click":function($event){_vm.hint=!_vm.hint}}},[_vm._v(" $info ")]):_vm._e()],1):_vm._e(),(_vm.hint)?_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$t(_vm.hintPath))+" ")]):_vm._e(),_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.requiredParameters}})],1),(_vm.carrierServiceParameters && _vm.carrierServiceParameters.length !== 0)?_c('CarrierServiceParametersValues',{staticClass:"mx-5",attrs:{"carrier-service-parameters":_vm.carrierServiceParameters,"read-only":_vm.readOnly},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.carrierName + " - " + _vm.carrierServiceName)+" ")]),_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.carrierServiceParameters.title'))+": ")])]},proxy:true}],null,false,2688307978)}):_vm._e(),(!_vm.isValid && !_vm.loading && !_vm.readOnly)?_c('WarnText',{attrs:{"text":"imports.info.parameters_warn"}}):_vm._e(),(_vm.optionalParameters && Object.keys(_vm.optionalParameters).length)?_c(VExpansionPanels,{staticClass:"pb-3 mx-3",attrs:{"popout":""},model:{value:(_vm.optionalParametersDropdown),callback:function ($$v) {_vm.optionalParametersDropdown=$$v},expression:"optionalParametersDropdown"}},[_c(VExpansionPanel,{key:0},[_c(VExpansionPanelHeader,[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('carriers.contracted.parameters.optional.name'))+" ")])]),_c(VExpansionPanelContent,[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.optionalParameters}})],1)],1)],1):_vm._e(),_c(VDivider)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }