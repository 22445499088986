<template>
  <v-list-item>
    <v-btn-toggle
      v-model="productModels"
      mandatory
      class="mr-3"
    >
      <v-btn
        v-for="productModel in [0, 1]"
        :key="productModel"
        text
      >
        {{ $t('base.productModel.' + productModel) }}
      </v-btn>
    </v-btn-toggle>
    {{ $t('base.productLabels') }}
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarProductModels",
        computed: {
            productModels: {
                get() {
                    return this.$store.getters['userConfig/productModels'];
                },
                set(newVal, oldVal) {
                    if (oldVal !== null) {
                        this.$store.dispatch('userConfig/set', {
                            key: 'productModels',
                            value: newVal
                        });
                    }
                }
            }
        }
    };
</script>

<style scoped>

</style>
