import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?[_c(VProgressLinear,{attrs:{"indeterminate":""}})]:[_c(VContainer,{staticStyle:{"max-height":"40vh","overflow-y":"scroll"},attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.headers),function(header){return _c(VCol,{key:header.value,staticClass:"text-caption"},[_vm._v(" "+_vm._s(header.text)+" ")])}),1),_vm._l((_vm.shipmentsLocal),function(shipment){return [_c(VRow,{key:'d-' + shipment.id},[_c(VDivider)],1),_c(VRow,{key:shipment.id},_vm._l((_vm.headers),function(header){return _c(VCol,{key:shipment.id + '_' + header.value},[(header.value === 'last_protocol_created_at')?[_c('DateTimeWithTooltip',{attrs:{"date-time":shipment.last_protocol_created_at,"abs-date-first":""}})]:(header.value === 'external_order_external_order_id')?[_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.EXTERNAL_ORDER] + '/' + shipment.external_order_id}},[_vm._v(" "+_vm._s(shipment.external_order_external_order_id)+" ")])],1)]:(header.value === 'external_order_id')?[_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.EXTERNAL_ORDER] + '/' + shipment.external_order_id}},[_vm._v(" #"+_vm._s(shipment.external_order_id)+" ")])],1)]:(header.value === 'stock_picking_id')?[_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":_vm.taskLinks[_vm.taskTypes.STOCK_PICKING] + '/' + shipment.stock_picking_id}},[_vm._v(" #"+_vm._s(shipment.stock_picking_id)+" ")])],1)]:(header.value === 'buyer.name')?[_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"to":'/buyers/' + shipment.buyer.id}},[_vm._v(" "+_vm._s(shipment.buyer.name)+" ")])],1)]:[_vm._v(" "+_vm._s(header.itemLabel(shipment))+" ")]],2)}),1)]})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }