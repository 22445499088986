<template>
  <div>
    <x-toolbar />
    <InformDialog
      :show="dialog"
      text="exports.info.statistics_warn"
      @confirm="getStatistics"
      @cancel="dialog = false"
    />
    <v-card :loading="loading">
      <div v-if="!loading">
        <v-card-title class="pb-0 pl-5 pt-5 text-lg-h4">
          {{ config.name }}
        </v-card-title>
        <ExportConfig
          :v-if="config"
          :config="config"
          :domain="config.domain"
          read-only
          hide-parameters
        />
      </div>

      <div
        v-if="config && config.parameters.length !== 0"
      >
        <Parameters
          class="pb-5 mx-5"
          :title="$t('exports.parameters.title')"
          :parameter-values="exportData ? exportData.parameters : config.parameters"
          :read-only="!!exportData"
          is-export
          show-optional
          :domain="config.domain"
          @emitParameters="parameters => parameterValues = parameters"
          @validParameters="isValid => validParameters = isValid"
        />
      </div>
      <ImportStatistics
        v-if="exportData"
        :loading="dataLoading"
        :import-data="exportData"
        is-export
        class="pb-5 px-10"
      />
      <div
        v-if="!loading && ( !exportData || exportData.status !== Status.FINISHED)"
        class="d-flex justify-end pt-5 px-5"
      >
        <v-btn
          v-if="!showStatistics && (!exportData || exportData.status !== Status.FINISHED)"
          class="mr-5"
          :loading="loading"
          color="accent"
          :disabled="!validParameters"
          @click="dialog = true"
        >
          {{ $t('exports.showStatistics') }}
        </v-btn>
        <v-btn
          :loading="loading"
          color="secondary"
          :disabled="dataLoading || !validParameters"
          @click="submit()"
        >
          {{ $t('exports.title') }}
        </v-btn>
        <v-flex
          class="pl-5"
          lg1
          md2
          xs3
        >
          <v-select
            v-model="type"
            :items="types"
            class="ma-0 pa-0"
            :disabled="dataLoading"
          />
        </v-flex>
      </div>
    </v-card>
  </div>
</template>

<script>
    import {ExportsAPI as API} from "@/api/ExportsAPI";
    import ExportConfig from "@/app/exports/components/ExportConfig.component";
    import Parameters from "@/app/exports/components/Parameters.component";
    import ImportStatistics from "@/app/imports/components/ImportStatistics.component";
    import InformDialog from "@/app/components/InformDialog.vue";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {Status} from "@/enum/import&export_state";

    export default {
        name: "ExportCreate",
        components: {InformDialog, ImportStatistics, Parameters, ExportConfig},
        mixins: [RouteParamsMapperMixin],
        beforeRouteLeave(to, from, next) {
            if (this.abortPolling) {
                this.abortPolling();
            }
            next();
        },
        props: {
            hasExport: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: true,
            dataLoading: false,
            showStatistics: false,
            exportData: null,
            dialog: false,
            type: '',
            types: [],
            config: null,
            parameterValues: null,
            validParameters: true,
            abortPolling: null
        }),
        computed: {
            Status() {
                return Status;
            }
        },
        createdOrActivated: function () {
            this.loading = true;
            this.showStatistics = this.exportId;
            this.fetchExportsDataTypes();
            if (this.exportId) {
                this.getExport();
            } else {
                this.hasExport = false;
                this.dataLoading = false;
                this.exportData = null;
                this.getConfig();
            }
        },
        methods: {
            getConfig() {
                this.loading = true;
                API.getConfiguration(this.configId)
                    .then(res => {
                        this.config = res.data;
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/exports');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getExport() {
                this.loading = true;
                API.get(this.exportId)
                    .then(res => {
                        this.exportData = res.data;
                        this.config = this.exportData.configuration;
                        this.dataLoading = true;
                        this.waitForStatistic();
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/exports');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            fetchExportsDataTypes() {
                API.getTypes()
                    .then(res => {
                        this.types = res.data.filter(type => type.supports_export).map(type => type.type);
                        this.type = this.types[0];
                    })
                    .catch(err => this.snack(err));
            },
            async createExport() {
                let promise = null;
                const data = {
                    type: this.type,
                    configuration: this.configId,
                    parameters: this.parameterValues,
                };
                await API.create(data)
                    .then(res => {
                        promise = API.get(res.headers.location.split('/').pop())
                            .then(res => {
                                this.exportData = res.data;
                            });
                    }).catch(err => this.snack(err));
                await promise;
            },
            async getStatistics() {
                this.dialog = false;
                await this.createExport();
                API.process(this.exportData.id, {'dry_run': true})
                    .then(() => {
                        this.dataLoading = true;
                        this.showStatistics = true;
                        this.waitForStatistic();
                    }).catch(err => this.snack(err));
            },
            async waitForStatistic() {
                const polling = API.getStatistics(this.exportData.id);
                this.abortPolling = polling.abortPolling;
                polling.promise
                    .then(res => {
                        this.exportData = res.data;
                    })
                    .catch(err => {
                        this.snack(err);
                    }).finally(() => {
                        this.dataLoading = false;
                        this.abortPolling = null;
                    });
            },
            async submit() {
                this.loading = true;
                const data = {
                    type: this.type,
                    configuration: this.configId,
                    parameters: this.parameterValues ?? []
                };
                if (!this.exportData) {
                    await this.createExport();
                }
                API.update(this.exportData.id, data)
                    .then(() => {
                        API.process(this.exportData.id)
                            .then(() => {
                                this.snack('exports.processed');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/exports');
                            });
                    })
                    .catch(err => {
                        this.snack(err);
                    }).finally(() => {
                        this.loading = false;
                    });
            },
        }
    };
</script>
