import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VTabs,{attrs:{"color":_vm.chipsColor},model:{value:(_vm.locationTableTab),callback:function ($$v) {_vm.locationTableTab=$$v},expression:"locationTableTab"}},[_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('map.locationMappingDetails.notMapped'))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('map.locationMappingDetails.mapped'))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('map.locationMappingDetails.toCreate'))+" ")]),_c(VTab,[_vm._v(" "+_vm._s(_vm.$t('map.locationMappingDetails.duplicit'))+" ")])],1),(_vm.loading && !_vm.saving)?_c(VCardText,[_c('div',{staticClass:"mt-2"},[_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}})],1)]):_vm._e(),(!_vm.loading || _vm.saving)?_c(VCardText,[_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.locationsCurrentPage),function(location,index){return _c(VTooltip,{key:index,attrs:{"top":"","disabled":location.code === null},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":_vm.chipsColor,"text-color":"white","model-value":true}},'v-chip',attrs,false),on),[_c('div',[_vm._v(_vm._s(location.name))])])]}}],null,true)},[(location.code !== null)?_c('span',[_vm._v(_vm._s(location.code))]):_vm._e()])}),1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c(VPagination,{staticClass:"pb-5",attrs:{"length":_vm.locationsPageCount,"color":_vm.chipsColor,"total-visible":"20","next-aria-label":"next","previous-aria-label":"previous","page-aria-label":"page","wrapper-aria-label":"wrapper","current-page-aria-label":"current"},model:{value:(_vm.locationsPage),callback:function ($$v) {_vm.locationsPage=$$v},expression:"locationsPage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }