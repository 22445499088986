<template>
  <v-row
    class="toolbarRow"
  >
    <v-col
      class="toolbarColItem pt-1"
      cols="auto"
    >
      <div
        class="toolbarCaption caption"
      >
        {{ $t('map.backToMap') }}
      </div>
      <v-btn
        color="mapObjectActionButton"
        class="mt-1"
        small
        dense
        depressed
        @click="closeRackView"
      >
        <v-icon
          small
          color="mapObjectActionButtonIcon"
        >
          $goBack
        </v-icon>
      </v-btn>
    </v-col>
    <v-divider
      class="toolbarDivider"
      vertical
    />
    <v-col
      class="toolbarColItem"
      cols="auto"
    >
      <v-form
        ref="rackSizeFormRef"
        class="pt-1"
      >
        <FormFields
          :form="rackSizeForm"
          :render="rackSizeFormRender"
          lang-path="map."
        />
      </v-form>
    </v-col>
    <v-divider
      class="toolbarDivider"
      vertical
    />
    <v-col
      v-if="doesRackSelectionExist"
      class="toolbarColItem pt-1"
      cols="auto"
    >
      <div
        class="toolbarCaption caption"
      >
        {{ $t('map.tools.cancel') }}
      </div>
      <v-btn
        color="mapObjectActionButton"
        class="mt-1"
        small
        dense
        depressed
        @click="unselectCells"
      >
        <v-icon
          small
          color="mapObjectActionButtonIcon"
        >
          $close
        </v-icon>
      </v-btn>
    </v-col>
    <v-divider
      v-if="doesRackSelectionExist"
      class="toolbarDivider"
      vertical
    />
  </v-row>
</template>

<script>
    import {MapMixin} from "@/app/mixins/MapMixin";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {RackSizeForm, RackSizeRender} from "@/app/stocks/map/definition/rackSetup.form";
    import {MapObjectType, PanelMode} from "@/enum/stock_map";
    import {getRackColumns} from "@/service/Map";
    export default {
        name: 'RackViewToolbar',
        components: {FormFields},
        mixins: [MapMixin],
        data: () => ({
            rackSizeForm: new RackSizeForm(),
        }),
        computed: {
            rackSizeFormRender() {
                return new RackSizeRender(this.ruleObjectColumns);
            },
        },
        watch: {
            selectedObject: function () {
                this.loadRackSize();
            },
            rackSizeForm: {
                handler: function () {
                    this.applyRackSize();
                },
                deep: true,
            },
        },
        createdOrActivated: function() {
            this.loadRackSize();
        },
        methods: {
            loadRackSize() {
                if (this.selectedObject.type === MapObjectType.RACK) {
                    this.rackSizeForm.objectWidth = getRackColumns(this.selectedObject);
                    this.rackSizeForm.rackShelves = this.selectedObject.shelves;
                }
            },
            applyRackSize() {
                if (this.$refs.rackSizeFormRef.validate() &&
                    !(this.rackSizeForm.rackShelves === this.selectedObject.shelves && this.rackSizeForm.objectWidth === getRackColumns(this.selectedObject))) {
                    this.$store.dispatch("storageMap/setRackDim", {
                        cols: Number.parseInt(this.rackSizeForm.objectWidth, 10),
                        shelves: Number.parseInt(this.rackSizeForm.rackShelves, 10)
                    });
                }
                const selection = this.$store.getters["storageMap/getSelectedRackArea"];
                if (selection.tl[0] !== 0 || selection.tl[1] !== 0 || selection.br[0] !== 0 || selection.br[1] !== 0) {
                    this.unselectCells();
                }
            },
            closeRackView() {
                this.$store.commit('storageMap/setPanelMode', {
                    mode: PanelMode.STANDBY
                });
            },
            unselectCells: function () {
                this.$store.commit('storageMap/setRackSelection', {
                    tl: [-1,-1],
                    br: [-1,-1]
                });
            }
            

        }
    };
</script>

<style scoped lang="sass">

.toolbarRow
  background-color: var(--v-mapToolbarBackground-base)
  margin-bottom: 1px
  margin-top: 2px
  margin-left: 5px

.toolbarColItem
  text-align: center
  align-items: center
  padding-left: 0
  padding-right: 0

.toolbarCaption
  margin-top: 8px

.vuetifySliderCenterHorizontally
  margin-bottom: -22px

.toolbarDivider
  margin: 8px 8px 0 8px !important
  height: 80px

</style>