export default {
    required: "This field is required.",
    maxLen: "Maximum length is {0} characters",
    minLen: "Minimum length is {0} characters",
    maxInteger: 'The maximum allowed value is {0}',
    pattern: "Input does not match required form",
    integer: "Value must be an integer",
    positiveInteger: "Value must be a positive integer",
    nonNegativeInteger: "Value must be a non-negative integer",
    number: "Value must be a number",
    nonNegativeNumber: "Value must be a non-negative number",
    range: "Must be between {0} and {1}",
    save: "Save",
    send: "Send",
    generate: "Generate",
    sequenceBegin: "Sequence must start with a number or a letter",
    notAllowedChar: "Character not allowed",
    download: "Download",
    notFilled: "Not filled",
    invalidDate: "Unable to process given date",
    now: "Now",
    today: "Today",
    yesterday: "Yesterday",
    start_of_month: "Start of month",
    end_of_last_month: "End of last month"
};
