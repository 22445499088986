import {Roles} from "@/enum/roles";
import ExportList from "@/app/exports/ExportsList.view";
import ExportCreate from "@/app/exports/ExportCreate.view";
import ExportConfigCreate from "@/app/exports/ExportConfigCreate.view";
import ExportConfigView from "@/app/exports/ExportConfigView.view";

const BASE_URL = '/exports';

const routes = {
    base: {
        path: BASE_URL,
        component: ExportList,
        meta: {
            title: 'exports.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    create: {
        path: BASE_URL + "/create/:configId(\\d+)",
        component: ExportCreate,
        meta: {
            title: 'exports.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        },
    },
    process: {
        path: BASE_URL + "/:exportId(\\d+)/process",
        component: ExportCreate,
        props: {
            hasExport: true
        },
        meta: {
            title: 'exports.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        },
    },
    createConfig: {
        path: BASE_URL + "/createConfig",
        component: ExportConfigCreate,
        meta: {
            title: 'exports.configuration.create',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    createConfigCopy: {
        path: BASE_URL + "/createConfigCopy/:configurationId(\\d+)",
        component: ExportConfigCreate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'exports.configuration.create_copy',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    viewConfig: {
        path: BASE_URL + "/view/:configurationId(\\d+)",
        component: ExportConfigView,
        meta: {
            title: 'exports.configuration.view',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },

};

export default Object.values(routes);
