<template>
  <v-progress-linear
    v-if="$store.getters['loader/bottom/show']"
    :indeterminate="true"
    class="bottom"
  />
</template>

<script>
    export default {
        name: "LoaderBottom"
    };
</script>

<style scoped lang="sass">
    .bottom
        position: fixed
        bottom: 0
        margin: 0
        z-index: 100
</style>
