export default {
    tasks: "Feladatok",
    noTasks: "Nincsenek feladatok!",
    running: "Fedalatok folyamatban",
    storekeepersRunning: "Folyamatban lévő raktárkezelői feladatok",
    assigned: "Kijelőlt feladatok",
    free: "Feladatok",
    allTasks: "Minden szabad feladat",
    allDone: "Nincs megjelenítendő feladat!",
    overview: "Jelentések",
    toApprove: "Jóváhagyásra vár",
    toPack: "Csomagolás",
    status: "Státusz",
    manage: "Kezelni",
    done: "Befejezett feladatok",
    noDone: "Nincs befejezett feladat!",
    config: {
        title: "Kezdőképernyő konfigurációja",
        config: "Listabeállítások",
        label: "Címke",
        addTaskList: "Feladatlista hozzáadása",
        addOrdersStats: "Rendelések áttekintésének hozzáadása",
        newList: "Új lista",
        all: "minden",
        export: {
            title: "Exportálás",
            done: "Kezdőképernyő exportálva."
        },
        import: {
            title: "Importálás",
            done: "Kezdőképernyő importálva.",
            fail: "A kezdőképernyő importálása sikertelen!"
        },
        reset: "Alapértelmezett listák visszaállítása",
        resetDone: "Az alapértelmezett listák visszaállítása megtörtént",
        showOn: "Megjelenítés",
        desktop: "számítógép asztal",
        mobil: "mobil",
        showOnDesktop: "Megjelenítés az asztalon",
        showOnMobile: "mutasd meg mobilon",
        autoRefresh: "Automatikus frissítés",
        autoRefreshValue: "Automatikus frissítés {0}",
        unableToLoad: "Nem sikerült betölteni a kezdőképernyő konfigurációját. Kérjük, próbálja meg később újra.",
        unableToSave: "Nem sikerült elmenteni a beállításokat!",
        arrayDateConflict: "Több dátum egyidejű kiválasztása nem támogatott. Használja a tók-ig tartományt.",
        onlySubstocks: "Csak alraktárak"
    },
    stats: {
        orders: {
            label: "Rendelési állapotok",
            unprocessed: 'új',
            inStockProcess: 'feldolgozás alatt',
            toBePicked: 'várja, hogy felvegyék',
            beingPicked: 'szedésben',
            toBePacked: 'csomagolásra várva',
            beingPacked: 'a csomagban',
            packed: 'csomagolt',
            inShipping: 'a szállításban',
            returning: 'visszajön',
            delivered: 'szállítva'
        },
        orderCountDisclaimer: 'Részleges elégedettség alkalmazása esetén egy rendelés több állapotban is lehet egyszerre!'
    }
};
