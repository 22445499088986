<script>
    export default {
        name: "CheckBoxHint",
        props: {
            name: {
                type: String,
                default: ''
            },
            hint: {
                type: String,
                default: ''
            }
        }
    };
</script>

<template>
  <div>
    {{ $t(name) }}
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          class="pl-3"
          v-on="on"
        >
          $info
        </v-icon>
      </template>
      <span>{{ $t(hint) }}</span>
    </v-tooltip>
  </div>
</template>

<style scoped>

</style>