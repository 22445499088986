<template>
  <v-card class="pb-3">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <GeneralConfigFilterComponent
        :filters="APIFilters"
        :filter="filter"
        :possible-values="possibleValues"
        lang-path="base.table.filter."
        :hardcoded-attribute="attribute"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close');"
      >
        {{ $t('base.close') }}
      </v-btn>
      <v-spacer />
      <v-btn
        text
        @click="reset"
      >
        {{ $t('base.reset') }}
      </v-btn>
      <v-btn
        class="px-4"
        color="accent"
        :text="!isFilterValid"
        @click="save"
      >
        <v-icon class="mr-2">
          $saveItem
        </v-icon>
        {{ $t('base.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import GeneralConfigFilterComponent from "@/app/components/filterConfig/GeneralConfigFilterPart.component";
    import {APIFilterDataType, APIFilters, APIFilterOP} from "@/service/APIFilters";
    import {FilterValidator} from "@/service/FilterValidator";

    export default {
        name: "TableDateFilter",
        components: {GeneralConfigFilterComponent},
        props: {
            value: {
                type: Object,
                default: null
            },
            title: {
                type: String,
                default: ''
            },
            attribute: {
                type: String,
                default: null
            }
        },
        data: () => ({
            filter: {},
            APIFilters: APIFilters
        }),
        computed: {
            possibleValues: function () {
                return {
                    [this.attribute]: {
                        type: APIFilterDataType.DATE,
                        values: null
                    }
                };
            },
            isFilterValid: function () {
                return FilterValidator.validate(this.filter, APIFilters, APIFilterOP, this.possibleValues);
            }
        },
        createdOrActivated: function () {
            this.filter = this.value || this.getDefaultFilter();
        },
        methods: {
            getDefaultFilter: function () {
                return {gt: {[this.attribute]: this.$moment().startOf('day').format()}};
            },
            reset: function () {
                this.filter = this.getDefaultFilter();
                this.$emit('close');
                this.$emit('input', null);
            },
            save: function () {
                if (this.isFilterValid) {
                    this.$emit('close');
                    this.$emit('input', structuredClone(this.filter));
                }
            }
        }
    };
</script>

<style scoped>

</style>
