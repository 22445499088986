import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isComponent)?_c('x-toolbar',{attrs:{"title":_vm.pageTitle}}):_vm._e(),_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VFlex,{attrs:{"xs12":""}},[(_vm.isComponent)?_c(VCardTitle,{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('stocks.owners.create.label'))+" "),_c(VSpacer),_c(VIcon,{on:{"click":_vm.close}},[_vm._v(" $closeItem ")])],1):_vm._e()],1),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"stocks.owners."},scopedSlots:_vm._u([{key:"ico-append-outer",fn:function(){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":!_vm.allowAres || _vm.loading,"loading":_vm.loadingAres,"fab":"","small":"","bottom":"","color":"secondary"},on:{"click":function($event){return _vm.loadAres()}}},on),[_c(VIcon,[_vm._v("$aresValidation")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('base.ares.load')))])])]},proxy:true}])}),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c(VSubheader,[_vm._v(_vm._s(_vm.$t('stocks.owners.billing_address')))])],1),_c('FormAddress',{attrs:{"form":_vm.form.billing_address}}),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c(VSubheader,[_vm._v(_vm._s(_vm.$t('stocks.owners.bank_account')))])],1),_c('FormBankAccount',{attrs:{"form":_vm.form.bank_account}}),_c(VBtn,{attrs:{"text":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }