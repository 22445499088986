<template>
  <div
    v-if="group"
  >
    <v-btn
      :disabled="readOnly"
      color="primary"
      width="100%"
      small
      :outlined="group.is_hidden"
      class="mb-1"
      @click="toggleGroup(group, !group.is_hidden)"
    >
      {{ getTranslation(group.name, domain) }}
    </v-btn>
    <div
      v-if="!group.is_hidden"
      class="d-flex custom-flex-gap"
    >
      <div
        v-for="(header, index) in group.columns"
        :key="index"
        :class="{'full-width-column': group.columns.length === 1}"
      >
        <template
          v-if="!header.columns"
        >
          <v-btn
            :disabled="readOnly"
            :outlined="header.is_hidden"
            color="primary"
            small
            width="100%"
            class="mb-1"
            @click="toggleScalar(header.code)"
          >
            {{ getTranslation(header.name, domain) }}
          </v-btn>
          <div
            v-for="index in depth"
            :key="'padding' + index"
          >
            <v-btn
              small
              disabled
              class="mb-1 invisible"
            />
          </div>
          <template>
            <div
              v-for="iterator in 3"
              :key="iterator"
              class="d-flex justify-center"
              :class="{background: iterator !== 2}"
            >
              <div
                class="pa-1"
                :class="{invisible: header.is_hidden}"
              >
                <template v-if="header.data_type === ExportDataTypes.DATE_TIME">
                  <DateTimeWithTooltip
                    :date-time="getRandomValue(header.data_type)"
                    abs-date-first
                  />
                </template>
                <template v-else>
                  {{ getRandomValue(header.data_type) }}
                </template>
              </div>
            </div>
          </template>
        </template>

        <div
          v-else
          class=""
        >
          <HeaderGroup
            :group="header"
            :domain="domain"
            :read-only="readOnly"
            :depth="depth - 1"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <template>
        <div
          v-for="index in depth + 1"
          :key="'padding' + index"
        >
          <v-btn
            small
            disabled
            class="mb-1 invisible"
          />
        </div>
        <div
          v-for="iterator in 3"
          :key="iterator"
          class="d-flex justify-center"
          :class="{background: iterator !== 2}"
        >
          <div
            class="pa-1 invisible"
          >
            Tajný text
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
    import {getTranslation} from "@/enum/domains";
    import {ExportDataTypes} from "@/enum/export_data_types";
    import * as Random from "@/service/Random";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";

    export default {
        name: "HeaderGroup",
        components: {DateTimeWithTooltip},
        props: {
            group: {
                type: Object,
                default: () => {
                }
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            domain: {
                type: String,
                default: ''
            },
            depth: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            getTranslation: getTranslation,
            ExportDataTypes: ExportDataTypes
        }),
        methods: {
            toggleScalar(code) {
                const scalar = this.group.columns.find(item => item.code === code);
                if (scalar) {
                    scalar.is_hidden = !scalar.is_hidden;
                }
            },
            toggleGroup(group, newVal) {
                this.group.is_hidden = newVal;
                group.columns.filter(header => !header.columns).forEach(item => item.is_hidden = newVal);
                group.columns.filter(header => header.columns).forEach(item => this.toggleGroup(item, newVal));
            },
            getRandomValue(dataType) {
                {
                    switch (dataType) {
                    case ExportDataTypes.BOOLEAN:
                        return Random.getRandomBoolean();
                    case ExportDataTypes.INTEGER:
                        return Random.getRandomInteger();
                    case ExportDataTypes.TEXT:
                        return Random.getRandomText();
                    case ExportDataTypes.FLOAT:
                        return Random.getRandomFloat();
                    case ExportDataTypes.DATE_TIME:
                        return Random.getRandomDate();
                    default :
                        return 1;
                    }
                }
            },
        }

    };
</script>

<style lang="sass" scoped>
.custom-flex-gap
  > * + *
    margin-left: 4px

.custom-divider
  margin-right: -4px

.invisible
  opacity: 0

.full-width-column
  width: 100%
</style>