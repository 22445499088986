<template>
  <v-container fluid>
    <ConfirmDeleteDialog
      v-if="deletedExport !== null"
      :show.sync="confirmDialog"
      :text="$t('exports.title')+' - '+$t('exports.created_at')+' : '+deletedExport.created_at"
      @confirm-delete="reallyDeleteExport"
    />
    <v-card-title class="pb-0 pt-2">
      {{ $t('exports.exports') }}
    </v-card-title>
    <x-data-table
      :actions="actions"
      :api-data-source="apiDataSource"
      :api-data-source-all-pages="apiDataSourceAllPages"
      :custom-filters="false"
      :headers="headers"
      :items.sync="items"
      :loading.sync="loading"
      :reload="reload"
      :show-search-bar="false"
    >
      <template #item.status="{item}">
        <v-chip
          :color="statusColors[item.status]"
        >
          {{ $t('exports.state.' + item.status) }}
        </v-chip>
        <v-btn
          v-if="loadingStatuses.includes(item.status)"
          color="muted"
          loading
          text
          x-small
        />
      </template>
      <template #item.created_at="{item}">
        <DateTimeWithTooltip
          :date-time="item.created_at"
          abs-date-first
        />
      </template>
      <template #item.processed_at="{item}">
        <DateTimeWithTooltip
          :date-time="item.processed_at"
          abs-date-first
        />
      </template>
    </x-data-table>
  </v-container>
</template>

<script>
    import {ExportsAPI as API} from "@/api/ExportsAPI";
    import {UserAPI} from "@/api/UserAPI";
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {createHeaders} from "@/utils/table";
    import {exportsTable} from "@/app/exports/definitions/exports.form";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import * as Export from "@/service/Export";
    import {FailedStatuses, LoadingStatuses, Status, StatusColor} from "@/enum/import&export_state";
    import {APIFilters} from "@/service/APIFilters";
    import {ImportsAPI} from "@/api/ImportsAPI";


    export default {
        name: "ExportsList",
        components: {
            DateTimeWithTooltip, XDataTable, ConfirmDeleteDialog
        },
        beforeRouteLeave(to, from, next) {
            if (this.autoReloadTimeout) {
                clearTimeout(this.autoReloadTimeout);
            }
            next();
        },
        props: {
            id: {
                type: Number,
                default: 0
            },
            domain: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            loading: true,
            items: [],
            deletedExport: null,
            confirmDialog: false,
            errorDialog: false,
            errorMessage: '',
            loadingStatuses: LoadingStatuses,
            statusColors: StatusColor,
            headers: null,
            template: null,
            reload: 0,
            autoReloadTimeout: null
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        icon: '$continue',
                        label: 'exports.continue',
                        color: "accent",
                        condition: item => (item.status !== Status.FINISHED),
                        routerLink: item => '/exports/' + item.id + '/process',

                    },
                    {
                        loading: this.loading,
                        action: this.showError,
                        icon: '$error',
                        label: 'exports.errorMessage',
                        color: "error",
                        condition: item => item.error !== null

                    },
                    {
                        loading: this.loading,
                        action: this.downloadExport,
                        icon: '$export',
                        label: 'exports.download',
                        color: 'primary',
                        condition: item => item.status === Status.FINISHED
                    },
                    {
                        loading: this.loading,
                        icon: '$showItem',
                        label: 'exports.view',
                        color: "secondary",
                        condition: item => (item.status === Status.FINISHED || FailedStatuses.includes(item.status)),
                        routerLink: item => '/exports/' + item.id + '/process',

                    },
                    {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return API.getAll.bind(API, this.id, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return API.getAllPages.bind(API, this.id);
            }
        },
        watch: {
            async items() {
                this.loading = true;
                this.item = await this.items.map(item => {
                    UserAPI.get(item.created_by)
                        .then(res => item.created_by = res.data.username)
                        .catch(err => {
                            this.snack(err);
                        });
                });
                if (this.items.some(item => LoadingStatuses.includes(item.status))) {
                    this.autoReload();
                }
                this.loading = false;
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders(exportsTable(), 'exports.', true);
            this.getTemplate();
        },
        methods: {
            getTemplate() {
                ImportsAPI.getTemplate(this.domain)
                    .then(res => this.template = res.data)
                    .catch(this.snack);
            },
            deleteItem: function (item) {
                this.confirmDialog = true;
                this.deletedExport = item;
            },
            downloadExport(item) {
                Export.formatXlsx(item.download_link, this.template);
            },
            reallyDeleteExport: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.delete(this.deletedExport.id)
                    .then(() => {
                        this.snack('exports.deleted');
                        if (this.items.length === 1) {
                            this.$emit('reloadParent');
                        }
                        this.getData();
                    }).catch(err => {
                        this.reload++;
                        this.snack(err);
                    });
            },
            showError(item) {
                this.advancedSnack(
                    {text: item.error});
            },
            autoReload() {
                this.autoReloadTimeout = setTimeout(() => this.reload++, 10000);
            },
        }
    };
</script>
