<template>
  <div>
    <component
      :is="configComponent"
      v-if="configComponent"
      :filters="filters"
      :filter="filter"
      :possible-values="possibleValues"
      :lang-path="langPath"
      :hardcoded-attribute="hardcodedAttribute"
      :allowed-operators="allowedOperators"
      :total-filter-count="depth === 0 ? getFilterCount(filter) : totalFilterCount"
      :depth="depth + 1"
      @removedLastCondition="removedLastCondition"
      @removeNestedGroup="$emit('removeNestedGroup');"
    >
      <template #operator>
        <v-autocomplete
          ref="autocomplete"
          outlined
          dense
          hide-details
          :label="$t('base.filterConfig.condition')"
          :value="operator"
          :items="operators"
          @input="operatorChanged"
          @blur="reSelectValue"
        />
      </template>
    </component>
    <div
      v-else
      class="d-flex flex-column"
    >
      <v-btn
        outlined
        color="secondary"
        class="mt-2"
        @click="setFirstFilter"
      >
        <v-icon class="mr-2">
          $addItem
        </v-icon>
        {{ $t('base.filterConfig.addFilter') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
    import FilterConfigUnaryOp from "@/app/components/filterConfig/FilterConfigUnaryOp.component";
    import FilterConfigBinaryOp from "@/app/components/filterConfig/FilterConfigBinaryOp.component";
    import FilterConfigTernaryOp from "@/app/components/filterConfig/FilterConfigTernaryOp.component";
    import FilterConfigArrayOp from "@/app/components/filterConfig/FilterConfigArrayOp.component";
    import {APIFilterOP, APIFilterDataTypeAllowedOperations, APIFilterDataType} from "@/service/APIFilters";
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {operatorType} from "@jagu/rest-api-filters-client/src/abstractFilters";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";
    import {FilterConfigComponent} from "@/enum/filters";

    export default {
        name: "GeneralConfigFilterPart",
        components: {
            // dynamic components have to be manually imported
            FilterConfigUnaryOp, FilterConfigBinaryOp, FilterConfigTernaryOp, FilterConfigArrayOp,
            // recursive component has to be imported this way
            FilterConfigGroupOp: () => import("@/app/components/filterConfig/FilterConfigGroupOp.component")
        },
        mixins: [FilterMixin],
        props: {
            defaultFilter: {
                type: [Object, Array],
                default: null
            },
        },
        computed: {
            filterGroup: function () {
                return !this.isFilterPropEmpty ? this.filters.getType(this.operator) : undefined;
            },
            operators: function () {
                if (this.isFilterPropEmpty) {
                    return undefined;
                }

                const possibleDataTypes = [...new Set(Object.values(this.possibleValues).map(val => val.type))];
                if (this.filters.type === FilterType.API) {
                    const allowedForDataTypes = new Set(possibleDataTypes.map(dataType => APIFilterDataTypeAllowedOperations[dataType]).flat());
                    const allowed = Object.values(APIFilterOP)
                        .filter(operator => {
                            if (operator === APIFilterOP.FULL_TEXT) {
                                return false;
                            }
                            if (this.allowedOperators !== null) {
                                return this.allowedOperators.indexOf(operator) !== -1;
                            }
                            return allowedForDataTypes.has(operator);
                        });
                    return allowed
                        .filter(operator => {
                            if (this.filterGroup.type === operatorType.groupOperators) {
                                return this.filters.getType(operator).type === operatorType.groupOperators;
                            } else {
                                return this.filters.getType(operator).type !== operatorType.groupOperators;
                            }
                        })
                        .map(operator => ({
                            text: this.$t('base.filter.' + operator, ['...', '...', '...']),
                            value: operator
                        }));
                } else {
                    console.warn('Computed property `operators` in GeneralConfigFilterPart is not set for ' + this.filters.type + ' implementation');
                    return [];
                }
            },
            configComponent: function () {
                return !this.isFilterPropEmpty ? FilterConfigComponent[this.filterGroup.type] : undefined;
            },
        },
        methods: {
            filterGroupValue: function (attr) {
                const defaultValue = this.getDefaultDataTypeValue(attr);
                return {
                    group: [{
                        [this.newOperator]: {[attr]: defaultValue}
                    }],
                    unary: attr,
                    binary: {[attr]: defaultValue},
                    ternary: {[attr]: [defaultValue, defaultValue]},
                    array: {[attr]: []},
                };
            },
            operatorChanged: function (newOperator) {
                if (newOperator !== null && newOperator !== this.operator) {
                    const possibleKeys = Object.keys(this.possibleValues).filter(key => {
                        return this.filters.isFilterOPAllowedForDataType(newOperator, this.possibleValues[key].type);
                    });
                    const curFilter = this.filter[this.operator];
                    const curKey = typeof curFilter === 'object' ? Object.keys(this.filter[this.operator])[0] : curFilter;
                    const curOperator = this.operator;
                    const curType = this.filters.getType(curOperator);
                    const newKey = possibleKeys.includes(curKey) ? curKey : possibleKeys[0];
                    const newType = this.filters.getType(newOperator);

                    if (newType.type === operatorType.groupOperators) {
                        // group changed
                        this.$set(this.filter, newOperator, this.filter[curOperator]);
                    } else {
                        // operator changed
                        this.$set(this.filter, newOperator, this.filterGroupValue(newKey)[newType.type]);

                        if (curKey === newKey) {
                            // attribute did not change
                            const curValue = this.filter[curOperator][curKey];
                            const defaultValue = this.getDefaultDataTypeValue(newKey);
                            if (this.filters.isValueConvertable(curType, newType)
                                && !this.isValueEmpty(curValue)
                                && !(Array.isArray(curValue) && curValue.some(el => this.isValueEmpty(el)))
                            ) {
                                let convertedValue = this.filters.convertValue(curType, newType, curValue, defaultValue);
                                // if converting to ternary operator check if the values are in correct order and swap them if not
                                if (newType.type === operatorType.ternaryOperators) {
                                    if (this.filters.type === FilterType.API && this.possibleValues[newKey].type === APIFilterDataType.DATE) {
                                        if (this.$moment(convertedValue[0]).isAfter(convertedValue[1])) {
                                            convertedValue = [convertedValue[1], convertedValue[0]];
                                        }
                                    } else {
                                        if (convertedValue[0] > convertedValue[1]) {
                                            convertedValue = [convertedValue[1], convertedValue[0]];
                                        }
                                    }
                                }
                                this.$set(this.filter[newOperator], newKey, convertedValue);
                            }
                        } else {
                            // attribute changed because of operator incompatibility - inform user about attribute change with snack
                            this.advancedSnack({
                                text: 'base.filterConfig.attributeChanged',
                                params: [
                                    '"' + this.$t(this.langPath + curKey + '.name') + '"',
                                    '"' + this.$t(this.langPath + newKey + '.name') + '"'
                                ]
                            });
                        }
                    }

                    delete this.filter[curOperator];
                }
            },
            reSelectValue: function () {
                this.$refs.autocomplete.selectItem(this.operator);
            },
            setFirstFilter: function () {
                let newFilter = null;
                if (!this.isFilterEmpty(this.defaultFilter)) {
                    newFilter = structuredClone(this.defaultFilter);
                    if (Array.isArray(newFilter)) {
                        // Handle implicit AND
                        switch(this.filters.type) {
                        case FilterType.API:
                            newFilter = {
                                [APIFilterOP.AND]: newFilter
                            };
                            break;
                        default:
                            console.warn('Default filter array is not supported for ' + this.filters.type + ' implementation');
                            newFilter = null;
                        }
                    } else {
                        // Check if object has group key as first key
                        switch(this.filters.type) {
                        case FilterType.API:
                            if ([APIFilterOP.AND, APIFilterOP.OR].includes(Object.keys(newFilter)[0])) {
                                break;
                            }
                        /* eslint-disable-next-line no-fallthrough */
                        default:
                            console.warn('Default filter object is missing group key as first key');
                            newFilter = null;
                        }
                    }
                } else {
                    // Try to create default filter
                    if (Object.keys(this.possibleValues).length === 0) {
                        console.warn('Possible values are empty cannot set first filter');
                    } else {
                        switch(this.filters.type) {
                        case FilterType.API:
                            newFilter = {
                                [APIFilterOP.AND]: this.filterGroupValue(Object.keys(this.possibleValues)[0]).group
                            };
                            break;
                        default:
                            console.warn('Method `setFirstFilter` in GeneralConfigFilterPart is not set for ' + this.filters.type + ' implementation');
                        }
                    }
                }

                this.$emit('setFilter', newFilter);
            },
            removedLastCondition: function () {
                if (this.depth === 0 && this.totalFilterCount === 0) {
                    // update model to null
                    // if filter prop is used it wont be affected
                    this.$emit('setFilter', null);
                }
                // bubble up or notify parent component that is using this component
                this.$emit('removedLastCondition');
            }
        }
    };
</script>

<style scoped>

</style>
