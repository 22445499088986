<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('stocks.substocks.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="stocks.substocks."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <StockOwnerCreateUpdate
      v-if="showCreateOwner"
      id="ownerForm"
      ref="ownerForm"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {EventBus} from "@/service/EventBus";
    import FormFields from "@/app/components/form/FormFields.component";
    import StockOwnerCreateUpdate from "@/app/stocks/owners/StockOwnerCreateUpdate.view";
    import {SubstockForm, SubstockRender} from "@/app/stocks/substocks/definitions/substock.form";
    import {tabTitle} from "@/utils/string";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {scrollTo} from "@/service/Vuetify";
    import {getIdFromLocation} from "@/utils/url";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {UserPermissionsAPI} from "@/api/UserPermissionsAPI";
    import {PermissionType} from "@/enum/permissionTypes";

    export default {
        name: "StockSubstockCreateUpdate",
        components: {StockOwnerCreateUpdate, FormFields,},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, EventsListenerMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            },
            parentStockId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            form: new SubstockForm,
            formRender: new SubstockRender,
            valid: true,
            loading: false,
            stockName: '',
            showCreateOwner: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.stockName !== '') {
                    if (this.isEdit) {
                        title = this.$t('stocks.substocks.update.title', [this.form.name, this.stockName]);
                    } else {
                        title = this.$t('stocks.substocks.create.title', [this.stockName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            events: function () {
                return {
                    'create-owner': this.onCreateOwner,
                    'create-owner-created': this.onCreateOwnerCreated,
                    'create-owner-cancelled': () => this.showCreateOwner = false
                };
            }
        },
        createdOrActivated: function () {
            if (!this.isComponent) {
                StockAPI.get(this.stockId)
                    .then(response => {
                        this.stockName = response.data.name;
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/stocks');
                    });
            } else {
                this.stockId = this.parentStockId;
            }
        },
        methods: {
            formFetchItem: function () {
                return StockAPI.getSubstock(this.stockId, this.subStockId);
            },
            onCreateOwner: function () {
                this.showCreateOwner = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.owner_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('ownerForm');
                });
            },
            onCreateOwnerCreated: function (ownerId) {
                this.showCreateOwner = false;
                this.$set(this.formRender.owner_id, 'loading', true);
                this.formRender.owner_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.owner_id.autocomplete.items = this.formRender.owner_id.autocomplete.thenFn(response);
                        this.form.owner_id = Number.parseInt(ownerId, 10);
                    }).finally(() => this.$set(this.formRender.owner_id, 'loading', false));
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const subStockId = this.subStockId;
                    StockAPI.updateSubstock(this.stockId, subStockId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'stocks.substocks.update.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/stocks/' + this.stockId + '/substocks/' + subStockId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    StockAPI.createSubstock(this.stockId, this.form)
                        .then(response => {
                            const newSubStockId = getIdFromLocation(response);
                            const newSubStockName = this.form.name;
                            this.advancedSnack({
                                text: 'stocks.substocks.create.done',
                                params: [newSubStockName]
                            });
                            const userLocation = response.headers.location;

                            const userId = this.$store.getters['oauth/getUserId'];
                            UserPermissionsAPI.updateUserPermissions(userId, {
                                substock_ids: [newSubStockId],
                                type: PermissionType.ALLOWED
                            }).then(() => {
                                if (this.isComponent) {
                                    EventBus.$emit('create-substock-created', newSubStockId);
                                } else {
                                    this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                    this.$router.push({path: userLocation, hash: '#users'});
                                }
                                this.advancedSnack({
                                    text: 'stocks.substocks.create.addedPermission',
                                    params: [
                                        this.$store.getters['oauth/getUserFullName'],
                                        newSubStockName
                                    ],
                                    timeout: 0,
                                });
                            });
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-substock-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
