import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.getters['loader/overlay/show'])?_c('div',{staticClass:"overlay"},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VCard,{staticClass:"pa-12"},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"70","width":"7","color":"accent"}}),_c('p',{staticClass:"title mt-4"},[_vm._v(" "+_vm._s(_vm.$t('base.loading'))+" ")]),(_vm.takesTooLong)?[_c(VBtn,{on:{"click":function($event){return _vm.cancelLoader()}}},[_vm._v(" "+_vm._s(_vm.$t('base.cancelLoading'))+" ")]),_c('p',{staticClass:"caption mt-1"},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" $cancelLoadingHint ")]),_vm._v(" "+_vm._s(_vm.$t('base.cancelLoadingHint'))+" ")],1)]:_vm._e()],2)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }