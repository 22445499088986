<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-1"
              />
              <div>
                <span class="text-caption">{{ $t('tasks.delivery_type')+ ': ' }}</span>
                {{ $t('tasks.deliveryAccept.deliveryType.' + details.delivery_type) }}
              </div>
              <div v-if="details.delivery_type === deliveryTypes.RETURN">
                <span class="text-caption">{{ $t('tasks.external_order_task_id')+ ': ' }}</span>
                <router-link
                  v-if="details.external_order_task_id"
                  :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + details.external_order_task_id"
                >
                  #{{ details.external_order_task_id }}
                </router-link>
                <span v-else>
                  {{ ('(' + $t('form.notFilled') + ')') }}
                </span>
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.supplier') + ': ' }}</span>
                {{ details.supplier | supplierBrief }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.delivery_number') + ': ' }}</span>
                {{ details.delivery_number || ('(' + $t('form.notFilled') + ')') }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.invoice_number') + ': ' }}</span>
                {{ details.invoice_number || ('(' + $t('form.notFilled') + ')') }}
              </div>
              <v-divider
                class="my-2"
              />
              <div v-if="details.stock_loading_id">
                <span class="text-caption">{{
                  $t('tasks.stockLoading.name') + ': '
                }}</span>
                <router-link
                  :to="taskLinks[taskTypes.STOCK_LOADING] + '/' + details.stock_loading_id"
                >
                  #{{ details.stock_loading_id }}
                </router-link>
                <v-divider
                  class="my-2"
                />
              </div>
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
      <template
        v-if="isWaitingForApproval"
      >
        <v-divider
          class="my-3"
        />
        <v-layout wrap>
          <v-flex
            md8
            xs12
          >
            <DeliveryAcceptApproveCard
              :task-id="taskId"
              :default-data="details"
            />
          </v-flex>
          <v-flex
            v-if="$vuetify.breakpoint.smAndDown"
            xs12
          >
            <div class="my-2" />
          </v-flex>
          <v-flex
            md4
            xs12
          >
            <TaskSimpleStateChangeCard
              :api="API"
              :action="TaskAction.REJECT"
              :task-info="taskInfo"
            />
          </v-flex>
        </v-layout>
      </template>
      <template v-else-if="isClosed">
        <TaskItemsCard
          v-if="ready"
          :api="API"
          :card-type="TaskItemsCardType.ASSIGNMENT"
          :items="items"
          :task-info="taskInfo"
          task-lang-path="deliveryAccept."
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {taskLinks, taskNames, taskTypes} from "@/enum/task_type";
    import {TaskDeliveryAcceptAPI as API} from "@/api/TaskDeliveryAcceptAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskSimpleStateChangeCard from "@/app/tasks/components/TaskSimpleStateChangeCard.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import DeliveryAcceptApproveCard from "@/app/tasks/deliveryAccept/components/DeliveryAcceptApproveCard.component";
    import {tabTitle} from "@/utils/string";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {deliveryTypes} from "@/enum/delivery_type";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {TaskAction} from "@/enum/task_action";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";

    export default {
        name: "DeliveryAcceptShow",
        components: {
            TaskAttributes,
            DeliveryAcceptApproveCard, TaskAttachments, TaskSimpleStateChangeCard, Notes, EntityHistory, TaskItemsCard},
        mixins: [TaskStateMixin, RouteParamsMapperMixin, TaskFetchItemsMixin, ACLMixin, TaskAssignMixin],
        data: () => ({
            type: taskTypes.DELIVERY_ACCEPT,
            taskTypes: taskTypes,
            taskLinks: taskLinks,
            details: null,
            API: API,
            deliveryTypes: deliveryTypes,
            ready: false,
            TaskItemsCardType: TaskItemsCardType,
            items: [],
            TaskAction: TaskAction
        }),
        computed: {
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.$t(taskNames[this.type]);
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.DISTRIBUTE
                };
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            API.get(this.taskId, true)
                .then(response => {
                    this.details = response.data;
                    if (this.isClosed) {
                        if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.fetchItems({initial: true}).then(() => {
                                this.ready = true;
                            }).catch(this.snack);
                        } else {
                            this.fetchItems().catch(this.snack);
                        }
                    }
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/');
                });
        }
    };
</script>

<style scoped>

</style>
