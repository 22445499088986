<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.stockLoading.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <TaskPrintReport
      v-if="isClosed"
      :api="API"
      allow-csv
      :export-handler="exportXlsxOrCvs"
    />
    <template v-if="details">
      <template v-if="assignable">
        <TaskStartActionButton
          :loading="loading"
          @continue="assign"
        />
      </template>
      <TaskConflict
        v-if="conflict"
        :error="conflict"
      />
      <template v-if="hasTabs">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
          >
            <component
              :is="item.component"
              :api="API"
              :task-info="taskInfo"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <StockLoadingAssignment
          :task-info="taskInfo"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {TaskStockLoadingAPI as API} from "@/api/TaskStockLoadingAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import TaskStartActionButton from "@/app/tasks/components/TaskStartActionButton.vue";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";
    import StockLoadingAssignment from "@/app/tasks/stockLoading/components/StockLoadingAssignment.component";
    import StockLoadingLoading from "@/app/tasks/stockLoading/components/StockLoadingLoading.component";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {StockAPI} from "@/api/StockAPI";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import TaskPrintReport from "@/app/tasks/components/TaskPrintReport.component";
    import StockLoadingItemsPrices from "@/app/tasks/stockLoading/components/StockLoadingItemsPrices.component";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import * as Export from "@/service/Export";
    import {ProductAPI} from "@/api/ProductAPI";

    export default {
        name: "StockLoadingShow",
        components: {TaskConflict, TaskPrintReport, TaskStartActionButton, StockLoadingAssignment, StockLoadingItemsPrices},
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin, EventsListenerMixin, BarcodeListenerMixin, RouteParamsMapperMixin],
        data: () => ({
            API: API,
            loading: false,
            loadingReport: false,
            type: taskTypes.STOCK_LOADING,
            details: null,
            tab: null
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.activateTab,
                };
            },
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.typeName;
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.DISTRIBUTE
                };
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: StockLoadingAssignment
                }]
                    .concat(
                        (
                            this.isStorekeeper || this.isChief
                        ) ? [{
                            label: 'stockLoading',
                            component: StockLoadingLoading
                        }] : [])
                    .concat(
                        (
                            this.isOpenState && (
                                (this.isStorekeeper && this.assignedToCurrentUser && this.isInProgress) || this.isChief
                            )
                        ) ? [{
                            label: 'finish',
                            component: TaskDefaultOverview
                        }] : [])
                    .concat(
                        (
                            !this.isOpenState && this.isChief
                        ) ? [{
                            label: 'buyPrices',
                            component: StockLoadingItemsPrices
                        }] : []);
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook);
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return API.get(this.taskId, true)
                    .then(response => {
                        if (this.details === null || lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                        } else {
                            assign(this.details, response.data);
                        }
                        this.fetchWhitelist();
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            },
            fetchWhitelist: function () {
                StockAPI.getAllSubstockAvailableLocationsAllPages(this.details.subordinate_stock.stock_id, this.details.subordinate_stock.id)
                    .then(response => {
                        this.details.whitelist = response.data.items.map(location => [location.id, true]).reduce((acc, curr) => {
                            acc[curr[0]] = curr[1];
                            return acc;
                        }, {});
                    });
            },
            exportXlsxOrCvs: function (is_csv = true) {
                const taskId = this.taskInfo.details.id;
                API.getAllItems(taskId)
                    .then(response => {
                        const promises = [];
                        const exportData = response.data.map(record => {
                            const row = {};
                            promises.push(ProductAPI.getInstanceWOProduct(record.product_instance_id)
                                .then(response => {
                                    const product = response.data;
                                    row.name = product.product.name;
                                    row.model = product.product.model;
                                    row.type = product.type.name;
                                    row.amount = record.processed_quantity;
                                    row.measure_unit = product.product.measure_unit === '' ?
                                        this.$t('base.pcs') : product.product.measure_unit;
                                    row.price_vat = product.product.price_vat;
                                }));
                            return row;
                        });
                        Promise.all(promises).then(() => {
                            if(is_csv){
                                Export.csv(exportData, [], 'stock_loading_' + taskId + '.csv');
                            } else {
                                Export.xlsx(exportData, [], 'stock_loading_' + taskId + '.xlsx');
                            }
                        }).finally(() => {
                            this.loadingReport = false;
                        });
                    }).catch(this.snack);
            }
        }
    };

// TODO ability to finish task once ready to approve
</script>

<style scoped>

</style>
