import DashboardConfig from "@/app/homepage/DashboardConfig.view";
import Homepage from "@/app/homepage/Homepage.view";
import {Roles} from "@/enum/roles";

const BASE_URL = '';

const routes = {
    base: {
        path: BASE_URL + '/',
        component: Homepage
    },
    config: {
        path: BASE_URL + '/hp-config',
        component: DashboardConfig,
        meta: {
            title: 'homepage.config.title',
            requireRole: [Roles.CHIEF, Roles.STOREKEEPER, Roles.PACKER],
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
