<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <UserCreateUpdateComponent
      :is-edit="isEdit"
      :user-id="isEdit ? userId : null"
      is-in-view
      @user-updated="userUpdated"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import UserCreateUpdateComponent from "@/app/users/components/UserCreateUpdate.component";
    import {UserAPI} from "@/api/UserAPI";

    export default {
        name: "UsersCreateUpdate",
        components: {UserCreateUpdateComponent},
        mixins: [ACLMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            user: null
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.user) {
                    title = this.$t('users.update.title', [this.user.first_name + ' ' + this.user.last_name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
        },
        createdOrActivated: function () {
            if (this.isEdit) {
                UserAPI.get(this.userId).then(response => this.user = response.data)
                    .catch(this.snack);
            }
        },
        methods: {
            userUpdated: function () {
                this.$router.push('/users');
            }
        }
    };
</script>

<style scoped>

</style>
