<template>
  <v-dialog
    v-model="model"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('imports.info.warn') }}
      </v-card-title>

      <v-card-text>
        {{ $t(text) }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="$emit('cancel')"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="accent"
          @click="$emit('confirm')"
        >
          {{ $t('tasks.locationTransfer.confirmButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: "InformDialog",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: null
            }
        },
        computed: {
            model: {
                get: function () {
                    return this.show;
                },
                set: function (newValue) {
                    this.$emit('update:show'  , newValue);
                }
            }
        }
    };
</script>
<style>

</style>
