<template>
  <v-row
    class="toolbarRow"
  >
    <v-col
      class="toolbarColItem"
      cols="2"
    >
      <div
        class="toolbarCaption caption"
      >
        {{ $t('map.zoom') }}
      </div>
      <div
        style="display: flex; flex-direction: row; width: 100%"
      >
        <v-icon
          @click="assignZoom(currentZoom-5)"
        >
          $zoomOut
        </v-icon>
        <v-slider
          v-model="currentZoom"
          max="70"
          min="15"
          style="min-width: 80px"
          class="v-slider vuetifySliderCenterHorizontally"
          @change="assignZoom(currentZoom)"
        />
        <v-icon
          @click="assignZoom(currentZoom+5)"
        >
          $zoomIn
        </v-icon>
      </div>
    </v-col>
    <v-divider
      class="toolbarDivider"
      vertical
    />
    <v-col
      class="toolbarColItem"
      cols="auto"
    >
      <v-form
        ref="mapSizeFormRef"
        class="pt-1"
      >
        <FormFields
          :form="mapSizeForm"
          :render="mapSizeFormRender"
          lang-path="map."
        />
      </v-form>
    </v-col>
    <v-divider
      class="toolbarDivider"
      vertical
    />
    <v-col
      v-if="objectActionNavigation !== null"
      class="toolbarColItem"
      cols="4"
    >
      <div
        class="toolbarCaption caption font-weight-bold"
      >
        {{ $t(objectActionNavigation.title) }}
      </div>
      {{ $t(objectActionNavigation.guide) }}
    </v-col>
    <v-divider
      v-if="objectActionNavigation !== null"
      class="toolbarDivider"
      vertical
    />
    <template
      v-for="[toolbarButton, data] of Object.entries(toolbarButtons)"
    >
      <v-col
        v-show="data.conditions"
        :key="toolbarButton"
        class="toolbarColItem"
        cols="auto"
      >
        <div
          class="toolbarCaption caption"
        >
          {{ $t(data.label) }}
        </div>
        <v-btn
          :color="data.type.color"
          class="mt-1"
          small
          dense
          depressed
          @click="data.action"
        >
          <v-icon
            small
            :color="data.type.iconColor"
          >
            {{ data.icon }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-divider
        v-show="data.conditions"
        :key="toolbarButton + 'DIVIDE'"
        class="toolbarDivider"
        vertical
      />
    </template>
  </v-row>
</template>

<script>
    import {PanelMode} from '@/enum/stock_map';
    import {MapMixin} from "@/app/mixins/MapMixin";
    import {MapSizeRender, MapPropertiesForm} from "@/app/stocks/map/definition/mapProperties.form";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {ToolbarButtons} from "@/app/stocks/map/definition/toolbar.button";
    export default {
        name: 'MapViewToolbar',
        components: {FormFields},
        mixins: [MapMixin],
        data: () => ({
            // Current zoom is used for input, debounced for actually zooming in/out
            currentZoom: 32,
            debouncedZoom: 32,
            mapSizeForm: new MapPropertiesForm(),
        }),
        computed: {
            mapSizeFormRender() {
                return new MapSizeRender(this.ruleMapWidth, this.ruleMapHeight);
            },
            objectActionNavigation() {
                switch (this.panelMode) {
                case PanelMode.MOVE:
                    return {title: 'map.tools.move', guide: 'map.guide.move'};
                case PanelMode.NEW_RACK:
                    return {title: 'map.addRack', guide: 'map.guide.createRack'};
                case PanelMode.NEW_OBSTACLE:
                    return {title: 'map.addObstacle', guide: 'map.guide.createObstacle'};
                case PanelMode.NEW_ZONE:
                    return {title: 'map.addZone', guide: 'map.guide.createZone'};
                case PanelMode.NEW_LOCATIONS_GROUP:
                    return {title: 'map.addLocationsGroup', guide: 'map.guide.createLocationsZone'};
                case PanelMode.COPY:
                    return {title: 'map.tools.copy', guide: 'map.guide.copy'};
                default:
                    return null;
                }
            },
            toolbarButtons() {
                return ToolbarButtons();
            },
        },
        watch: {
            mapSize: {
                handler: function () {
                    this.mapSizeForm.mapHeight = this.mapSize.height;
                    this.mapSizeForm.mapWidth = this.mapSize.width;
                },
                deep: true,
            },
            mapSizeForm: {
                handler: function () {
                    this.applyMapSize();
                },
                deep: true,
            },
            render: {
                handler: function () {
                    this.applyMapSize();
                },
                deep: true,
            },
        },
        createdOrActivated: function() {
            this.mapSizeForm.mapHeight = this.mapSize.height;
            this.mapSizeForm.mapWidth = this.mapSize.width;
        },

        methods: {
            applyMapSize: function () {
                if (this.$refs.mapSizeFormRef.validate() &&
                    !(this.mapSizeForm.mapWidth === this.mapSize.width && this.mapSizeForm.mapHeight === this.mapSize.height)) {
                    this.$store.commit('storageMap/setMapSize', {
                        x: Number.parseInt(this.mapSizeForm.mapWidth, 10),
                        y: Number.parseInt(this.mapSizeForm.mapHeight, 10)
                    });
                }
            },          
            // Make sure that zoom doesn't get assigned a million times a second
            assignZoom: function (newZoom) {
                this.currentZoom = newZoom;
                if (this.debouncedZoom !== this.currentZoom) {
                    this.debouncedZoom = this.currentZoom;
                }
                this.$emit("zoomChange", this.debouncedZoom);
            },
            ruleMapWidth(value) {
                const res = this.$store.getters['storageMap/checkMapRescale'](
                    Number.parseInt(value, 10),
                    this.$store.getters["storageMap/getMapSize"].height
                );
                if (!res) {
                    return this.$t('map.detectedCollisions');
                }
                return true;
            },
            ruleMapHeight(value) {
                const res = this.$store.getters['storageMap/checkMapRescale'](
                    this.$store.getters["storageMap/getMapSize"].width,
                    Number.parseInt(value, 10)
                );
                if (!res) {
                    return this.$t('map.detectedCollisions');
                }
                return true;
            },

        }
    };
</script>

<style scoped lang="sass">

.toolbarRow
  background-color: var(--v-mapToolbarBackground-base)
  margin-bottom: 1px
  margin-top: 2px
  margin-left: 5px

.toolbarColItem
  text-align: center
  align-items: center
  padding-left: 0
  padding-right: 0

.toolbarCaption
  margin-top: 8px

.vuetifySliderCenterHorizontally
  margin-bottom: -22px

.toolbarDivider
  margin: 8px 8px 0 8px !important
  height: 80px

</style>
