<template>
  <v-card
    class="ma-1"
  >
    <v-container fluid>
      <v-icon class="mb-1">
        {{ actionIcons.approve }}
      </v-icon>
      <span class="text-subtitle-1 ml-2">
        {{ $t('tasks.externalOrder.approve.name') }}
      </span>
      <v-divider
        class="my-2"
      />
      <v-layout wrap>
        <v-flex
          xs12
        >
          <v-subheader>
            {{ $t('tasks.externalOrder.createStockPicking') }}
          </v-subheader>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <FormFields
              :form="formPicking"
              :render="formPickingRender"
              lang-path="tasks.stockPicking."
            />
            <TaskItemAmounts
              class="mt-2"
              :items="items"
              amount-property="toBePicked"
            />
            <template
              v-if="formPicking.subordinate_stock_id"
            >
              <v-divider
                class="my-3"
              />
              <TaskAttributes
                :task-type="taskTypes.STOCK_PICKING"
                :stock-id="formPicking.stock_id"
                :sub-stock-id="formPicking.subordinate_stock_id"
                :submit-trigger="attributesSubmitTrigger"
                inline
                @update:valid="val => validAttributes = val"
                @update-submit-callback="callback => attributesSubmitCallback = callback"
              />
            </template>
            <v-divider class="my-3" />
            <FormFields
              :form="form"
              :render="formRender"
              lang-path="tasks."
            />
          </v-form>
        </v-flex>
      </v-layout>
      <div>
        <v-tooltip
          :disabled="!isDoNotProcess"
          top
        >
          <template #activator="{ on }">
            <span v-on="on">
              <v-btn
                :text="!validAll"
                :loading="loading"
                :disabled="approveNotPossible"
                type="submit"
                color="accent"
                @click="approve()"
              >
                <v-icon
                  class="mr-2"
                >
                  $approve
                </v-icon>
                {{ $t('tasks.externalOrder.approve.name') }}
              </v-btn>
            </span>
          </template>
          {{ $t('tasks.externalOrder.approveNotPossible') }}
        </v-tooltip>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        ExternalOrderApproveForm, ExternalOrderApprovePickingForm,
        ExternalOrderApprovePickingRender, ExternalOrderApproveRender
    } from "@/app/tasks/externalOrder/definitions/externalOrderApprove.form";
    import {actionIcons} from "@/enum/icons";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {populate} from "@/utils/object";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {StockAPI} from "@/api/StockAPI";
    import {locationLabel} from "@/utils/string";
    import {EventBus} from "@/service/EventBus";
    import TaskItemAmounts from "@/app/tasks/components/TaskItemAmounts.component.vue";
    import {TaskExternalOrderProcessingModeMixin} from "@/app/mixins/TaskExternalOrderProcessingModeMixin";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "ExternalOrderApproveCard",
        components: {TaskAttributes, FormFields, TaskItemAmounts},
        mixins: [TaskExternalOrderProcessingModeMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => ([])
            },
            defaultDataPicking: {
                type: Object,
                default: () => ({})
            },
            allItemsAvailable: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            form: new ExternalOrderApproveForm,
            formPicking: new ExternalOrderApprovePickingForm,
            formRender: new ExternalOrderApproveRender,
            formPickingRender: new ExternalOrderApprovePickingRender,
            valid: true,
            validAttributes: true,
            loading: false,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
            actionIcons: actionIcons,
            taskTypes: taskTypes
        }),
        computed: {
            taskId: function () {
                return this.taskInfo.taskId;
            },
            validAll: function () {
                return this.valid && this.validAttributes;
            },
            approveNotPossible: function () {
                if (this.isDoNotProcess) {
                    return true;
                }
                if (this.isProcessTogether) {
                    return !this.allItemsAvailable || !this.anyItemsSelected;
                }
                return !this.anyItemsSelected;
            },
            anyItemsSelected: function () {
                if (!this.items || (this.items.length !== 0 && this.items[0].available === undefined)) {
                    // availability status not fetched yet
                    return true;
                }
                return this.items.some(item => Number.parseInt(item.toBePicked, 10) > 0);
            }
        },
        watch: {
            'formPicking.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'formPicking.subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadLocations();
                }
            },
            'formPicking.shipping_type': function (newValue) {
                if (newValue === TaskShippingType.COURIER) {
                    this.formPickingRender.destination_stock_location_id.readonly = false;
                } else {
                    this.formPicking.destination_stock_location_id = null;
                    this.formPickingRender.destination_stock_location_id.readonly = true;
                    // TODO trigger required
                    delete this.formPickingRender.destination_stock_location_id.errors;
                }
            }
        },
        created: function () {
            populate(this.formPicking, this.defaultDataPicking);
        },
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formPickingRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.formPicking.stock_id)
                    .then(response => {
                        this.formPickingRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formPickingRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadLocations: function () {
                this.$set(this.formPickingRender.destination_stock_location_id, 'loading', true);
                StockAPI.getAllSubstockAvailableLocationsAllPages(this.formPicking.stock_id, this.formPicking.subordinate_stock_id)
                    .then(response => {
                        const expeditionLocationsFirst = response.data.items.sort((a, b) => b.is_expedition - a.is_expedition);
                        this.formPickingRender.destination_stock_location_id.autocomplete.items = expeditionLocationsFirst.map(el => ({
                            text: locationLabel(el),
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formPickingRender.destination_stock_location_id, 'loading', false);
                    });
            },
            approve: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                this.form.stock_picking = this.formPicking;
                this.form.stock_picking.items = this.items.map(item => {
                    return {
                        external_order_item_id: item.id,
                        quantity: item.toBePicked,
                        sell_price_per_unit: item.sell_price_per_unit,
                        price_vat: item.price_vat
                    };
                }).filter(item => item.quantity !== 0);
                TaskExternalOrderAPI.process(this.taskId, this.form)
                    .then(response => {
                        let taskId;
                        const mergedWithExistingTask = response.status === 200;
                        if (mergedWithExistingTask) {
                            taskId = response.data.id;
                        } else {
                            taskId = getIdFromLocation(response);
                        }
                        this.attributesSubmitCallback(taskId, mergedWithExistingTask)
                            .then(() => {
                                // Intentionally do not wait for result, just print error if there is some.
                                TaskExternalOrderAPI.unassign(this.taskId).catch(this.snack);

                                this.$store.dispatch('time/stop');
                                this.advancedSnack({
                                    text: 'tasks.approve.done',
                                    params: [
                                        this.taskId,
                                        this.$t(taskNames[taskTypes.EXTERNAL_ORDER])
                                    ]
                                });
                                this.$router.push('/');
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        this.loading = false;
                        const responseStatus = err.response.status;
                        if (responseStatus === 400) {
                            setFormErrors.call(this, err.response.data.errors.stock_picking || [], ['formRender', 'formPickingRender']);
                        } else if (responseStatus === 409) {
                            const error = err.response.data;
                            this.advancedSnack({ translatedText: error.message });
                            if (error.type === 'unavailable_item') {
                                const unavailableItem = error.unavailable_item;
                                EventBus.$emit('show-items');
                                this.$nextTick(() => {
                                    EventBus.$emit('item-unavailable', unavailableItem.external_order_item_id);
                                });
                            }
                        } else {
                            const errorMessage = 'base.api.unexpectedError';
                            this.snack(errorMessage);
                            window.console.error(this.$t(errorMessage));
                        }
                    });
            }
        }
    };
</script>

<style scoped>

</style>
