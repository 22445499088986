import { VAlert } from 'vuetify/lib/components/VAlert';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar',{attrs:{"title":_vm.pageTitle,"tabs":_vm.hasTabs,"full-width":true,"extension":_vm.hasTabs},scopedSlots:_vm._u([(_vm.hasTabs)?{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"background-color":"primary lighten-1","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"accent"}}),_vm._l((_vm.tabItems),function(item){return _c(VTab,{key:item.label},[_vm._v(" "+_vm._s(_vm.$t('tasks.substockTransfer.navigation.' + item.label))+" ")])})],2)]},proxy:true}:null],null,true)}),(_vm.details)?[(_vm.isAutoCompleted)?[_c(VAlert,{attrs:{"value":true,"icon":"$hint","color":"secondary","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('tasks.substockTransfer.isAutocompleted'))+" ")])]:_vm._e(),(_vm.hasTabs)?[(_vm.assignable)?[_c('TaskStartActionButton',{attrs:{"loading":_vm.loading},on:{"continue":_vm.assign}})]:_vm._e(),(_vm.conflict)?_c('TaskConflict',{attrs:{"error":_vm.conflict}}):_vm._e(),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c(VTabItem,{key:item.label},[_c(item.component,{tag:"component",attrs:{"api":_vm.API,"task-info":_vm.taskInfo}})],1)}),1)]:[_c('SubstockTransferAssignment',{attrs:{"task-info":_vm.taskInfo}})]]:[_c(VProgressLinear,{attrs:{"indeterminate":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }