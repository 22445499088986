<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedAttribute !== null"
      :show.sync="confirmDialog"
      :text="deletedAttribute.key + ', ' + deletedAttribute.value + (deletedAttribute.description ? `, ${deletedAttribute.description}` : '')"
      @confirm-delete="reallyDeleteAttribute"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('products.attributes.name') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            v-show="items.length > 0"
            :headers="headers"
            :loading="loading"
            :items="items"
            :actions="actions"
          >
            <template #footer>
              <TableAddItemButton
                :to="attributeCreateLink"
                label="products.attributes.create.titleSimple"
              />
            </template>
          </x-data-table>
          <TableAddItemButton
            v-show="items.length === 0"
            :to="attributeCreateLink"
            label="products.attributes.create.titleSimple"
            :show-divider="false"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import {productAttributeTable} from "@/app/products/attributes/definitions/productAttribute.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "ProductAttributesList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            loading: true,
            items: [],
            deletedAttribute: null,
            confirmDialog: false
        }),
        computed: {
            attributeCreateLink: function () {
                return '/products/' + this.productId + '/attributes/create';
            },
            headers: () => createHeaders(productAttributeTable, 'products.attributes.'),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        action: this.updateItem,
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                API.getAllAttributes(this.productId)
                    .then(response => {
                        this.items = response.data;
                    }).catch(() => {
                        this.snack('products.attributes.unableToLoad');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            updateItem: function (item) {
                this.$router.push('/products/' + this.productId + '/attributes/' + encodeURIComponent(item.key));
            },
            deleteItem: function (item) {
                this.deletedAttribute = item;
                this.confirmDialog = true;
            },
            reallyDeleteAttribute: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.deleteAttribute(this.productId, this.deletedAttribute.key)
                    .then(() => {
                        this.advancedSnack({
                            text: 'products.attributes.delete.done',
                            params: [this.deletedAttribute.key]
                        });
                    }).catch(err => {
                        this.snack(err === 412 ? 'products.status.412' : err);
                    })
                    .finally(this.getData);
            }
        }
    };
</script>

<style scoped>

</style>
