<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedAttribute !== null"
      :show.sync="confirmDialog"
      :text="deletedAttribute.value"
      @confirm-delete="reallyDeleteItem"
    >
      <v-alert
        :type="deletedAttributeUsageLoading ? 'info' : deletedAttributeUsageCount === 0 ? 'success' : 'error'"
      >
        <template v-if="deletedAttributeUsageLoading">
          <v-progress-circular
            class="mr-4"
            size="24"
            indeterminate
          />
          {{ $t('base.attributes.templates.delete.usage.loading', [deletedAttribute.value]) }}
        </template>
        <template v-else-if="deletedAttributeUsageCount === 0">
          {{ $t('base.attributes.templates.delete.usage.notInUse', [deletedAttribute.value]) }}
        </template>
        <template v-else>
          {{ $t('base.attributes.templates.delete.usage.inUse', [deletedAttribute.value]) }}
        </template>
      </v-alert>
    </ConfirmDeleteDialog>
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :show-search-bar="false"
      >
        <template #footer>
          <TableAddItemButton
            :to="attributesCreateLink"
            label="base.attributes.templates.create"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="attributesCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {createHeaders} from "@/utils/table";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {attributeTemplateTable} from "@/app/attributes/templates/definitions/attributeTemplate.form";
    import {AttributesAPI} from "@/api/AttributesAPI";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {TaskAPI} from "@/api/TaskAPI";
    import {APIFilterOP, APIFilters} from "@jagu/rest-api-filters-client/apiFilters";

    export default {
        name: "AttributeTemplatesList",
        components: {ConfirmDeleteDialog, TableAddItemButton},
        data: () => ({
            loading: true,
            items: [],
            attributesCreateLink: '/attribute-templates/create',
            deletedAttribute: null,
            deletedAttributeUsageLoading: false,
            deletedAttributeUsageCount: 0,
            confirmDialog: false,
            reload: 0
        }),
        computed: {
            headers: () => createHeaders(attributeTemplateTable(), 'base.attributes.templates.form.'),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/attribute-templates/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return AttributesAPI.getAll.bind(AttributesAPI);
            },
            apiDataSourceAllPages: function () {
                return AttributesAPI.getAllPages.bind(AttributesAPI);
            }
        },
        methods: {
            loadItemUsages: function (item) {
                this.deletedAttributeUsageCount = 0;
                this.deletedAttributeUsageLoading = true;
                const filter = {
                    [APIFilterOP.EQUALS]: {
                        'template.id': item.id
                    }
                };
                TaskAPI.getAllAttributes({ itemsPerPage: 1, filter: APIFilters.makeFilter(filter)} )
                    .then(response => {
                        this.deletedAttributeUsageCount = response.data.item_count;
                    }).catch(this.snack)
                    .finally(() => this.deletedAttributeUsageLoading = false);
            },
            deleteItem: function (item) {
                this.deletedAttribute = item;
                this.loadItemUsages(item);
                this.confirmDialog = true;
            },
            reallyDeleteItem() {
                this.loading = true;
                this.confirmDialog = false;
                AttributesAPI.delete(this.deletedAttribute.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'base.attributes.templates.delete.done',
                            params: [this.deletedAttribute.value]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.reload++;
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
