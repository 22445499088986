import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {StockId, TaskDescription, TaskPriority} from "@/app/tasks/definitions/taskCommon.render.form";

function LocationTransferCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        stock_id: null,
        allow_filled_locations: true,
        attachments: [],
    };
}

function LocationTransferCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        allow_filled_locations: {
            icon: '$allowFilledLocations',
            checkbox: true,
        },
        description: new TaskDescription
    };
}

export {LocationTransferCreateForm, LocationTransferCreateRender};
