<template>
  <div
    v-if="isNoticeShown"
    class="overlay"
  >
    <v-layout
      align-center
      justify-center
      column
      fill-height
    >
      <v-card
        class="pa-6"
      >
        <v-card-title class="text-h5">
          {{ $t('tasks.timeEntries.saveTime') }}
          <br v-show="isVertical">
          ({{ elapsed | elapsedTime }})
        </v-card-title>
        <v-card-text>
          {{ $t('tasks.timeEntries.saveTimeDetails') }}
        </v-card-text>
        <v-card-actions>
          <v-layout
            :row="!isVertical"
            :column="isVertical"
          >
            <v-spacer />
            <v-flex>
              <v-btn
                color="secondary"
                text
                @click="cancel"
              >
                {{ $t('base.cancel') }}
              </v-btn>
            </v-flex>
            <v-flex>
              <v-divider
                :vertical="!isVertical"
                :class="{
                  'mx-4': !isVertical,
                  'my-3': isVertical
                }"
              />
            </v-flex>
            <v-flex>
              <v-btn
                color="accent darken-2"
                text
                class="mr-2"
                @click="discardTime"
              >
                {{ $t('base.notSave') }}
              </v-btn>
              <v-btn
                color="accent"
                @click="logTime"
              >
                {{ $t('base.save') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
    import {TaskAPI} from "@/api/TaskAPI";
    import {LiveTimeTrackerMixin} from "@/app/mixins/LiveTimeTrackerMixin";

    export default {
        name: "TimeTrackerNotice",
        mixins: [LiveTimeTrackerMixin],
        data: () => ({
            loading: false
        }),
        computed: {
            isNoticeShown: function () {
                return this.$store.getters['time/isNoticeShown'];
            },
            isVertical: function () {
                return this.$vuetify.breakpoint.xs;
            }
        },
        created: function () {
            if (this.isNoticeShown && isNaN(this.$route.params.taskId)) {
                window.console.error('Time tracker notice opened on non-task route!');
            }
        },
        methods: {
            logTime: function () {
                const timeSpent = this.$store.getters['time/currentHours'](Date.now());
                TaskAPI.createTimeEntry(this.$route.params.taskId, timeSpent)
                    .then(() => {
                        this.snack('tasks.timeEntries.saved');
                    }).catch(this.snack);
                this.$store.dispatch('time/stop');
                this.navigate();
            },
            discardTime: function () {
                this.$store.dispatch('time/stop');
                this.navigate();
            },
            cancel: function () {
                this.$store.commit('time/hideNotice');
            },
            navigate: function () {
                this.$router.push(this.$store.getters['time/navigationPath']);
            }
        }

    };
</script>

<style scoped>

</style>
