import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.isProductLoaded)?[_c(VListItem,[_c(VListItemAvatar,[_c(VImg,{staticStyle:{"cursor":"zoom-in"},attrs:{"src":_vm.imgUrl,"lazy-src":_vm.imagePlaceholder,"alt":_vm.$t('products.images.alt')},on:{"click":function($event){$event.stopPropagation();_vm.imageDialog = true}}}),_c(VDialog,{model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c(VImg,{attrs:{"src":_vm.imgUrl,"lazy-src":_vm.imagePlaceholder,"alt":_vm.$t('products.images.alt'),"contain":"","max-height":"90vh","max-width":"90vw"},on:{"click":function($event){$event.stopPropagation();_vm.imageDialog = false}}})],1)],1),_c(VListItemContent,{staticClass:"text-pre-wrap",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('select')}}},[_c('strong',[_vm._v(_vm._s(_vm.quantityLabel))]),_vm._v(" "+_vm._s(_vm._f("instanceLabel")(_vm.itemInstance))+" ")])],1)]:[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"14","width":"2"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }