export const stockFabActions = stockId => [
    {
        icon: '$owner',
        label: 'stocks.owners.create.titleSimple',
        to: '/stocks/owners/create'
    }, {
        icon: '$substock',
        label: 'stocks.substocks.create.titleSimple',
        to: '/stocks/' + stockId + '/substocks/create'
    }, {
        icon: '$newLocation',
        label: 'stocks.locations.create.titleSimple',
        to: '/stocks/' + stockId + '/locations/create'
    }
];
