<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <v-flex
            xs12
          >
            <span
              class="text-h5 ml-1"
            >
              {{ info }}
            </span>
            <v-divider
              class="my-3"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <span class="text-subtitle-1 ml-2">
              {{ $t('carriers.contracted.parameters.required.name') }}:
            </span>
          </v-flex>
          <v-flex
            v-if="loading"
            xs12
          >
            <v-skeleton-loader type="list-item@3" />
          </v-flex>
          <v-flex
            v-else
            xs12
          >
            <div
              v-for="parameter of requiredParameters"
              :key="parameter.key"
              class="d-flex flex-row flex-wrap align-center"
            >
              <v-text-field
                v-model="parameter.key"
                :label="$t('carriers.contracted.parameters.key')"
                prepend-icon="$itemName"
                :disabled="true"
                class="mx-5"
              />
              <v-text-field
                v-model="parameter.value"
                :label="$t('carriers.contracted.parameters.value')"
                prepend-icon="$attributeValue"
                :rules="[formRules.required]"
                :name="parameter.key"
                :suffix="'*'"
                class="mx-5"
              >
                <template
                  v-if="isEdit && !loading && (parameter.value === null || parameter.value === undefined)"
                  #append
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-icon>$carrierWriteOnlyParameter</v-icon>
                      </div>
                    </template>
                    <span>{{ $t('carriers.contracted.parameters.writeOnly') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-btn
                class="mx-5"
                :disabled="parameter.value === null || parameter.value === ''"
                @click="saveSingle(parameter.key, parameter.value)"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </div>
          </v-flex>
          <v-divider
            class="my-3"
          />
          <template v-if="optionalParameters.length">
            <v-flex
              xs12
            >
              <span class="text-subtitle-1 ml-2">
                {{ $t('carriers.contracted.parameters.optional.name') }}:
              </span>
            </v-flex>
            <v-flex
              v-if="loading"
              xs12
            >
              <v-skeleton-loader type="list-item@3" />
            </v-flex>
            <v-flex
              v-else
              xs12
            >
              <div
                v-for="parameter of optionalParameters"
                :key="parameter.key"
                class="d-flex flex-row flex-wrap align-center"
              >
                <v-text-field
                  v-model="parameter.key"
                  :label="$t('carriers.contracted.parameters.key')"
                  prepend-icon="$itemName"
                  :disabled="true"
                  class="mx-5"
                />
                <v-text-field
                  v-model="parameter.value"
                  :label="$t('carriers.contracted.parameters.value')"
                  :name="parameter.key"
                  prepend-icon="$attributeValue"
                  class="mx-5"
                />
                <v-btn
                  class="mx-5"
                  @click="saveSingle(parameter.key, parameter.value, false)"
                >
                  <v-icon>save</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </template>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {tabTitle} from "@/utils/string";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {StockAPI} from "@/api/StockAPI";
    import formRules from "@/utils/formRules";

    export default {
        name: "CarrierParametersList",
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            requiredParameters: [],
            optionalParameters: [],
            carrier: null,
            owner: null,
            formRules: formRules,
            valid: true
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.carrier !== null) {
                    title = this.isEdit ? this.$t('carriers.contracted.update.title', [this.carrier.name])
                        : this.$t('carriers.contracted.create.title', [this.carrier.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            info: function () {
                const action = this.isEdit ? '.update.' : '.create.';
                if (this.carrier === null || this.owner === null) {
                    return this.$t('carriers.contracted' + action + 'titleSimple');
                } else {
                    return this.$t('carriers.contracted' + action + 'info', [this.carrier.name, this.owner.name]);
                }
            }
        },
        createdOrActivated: function () {
            if (this.ownerId === undefined) {
                this.$router.back();
            }

            this.loading = true;
            const requiredPromises = [this.fetchCarrier(), this.fetchOwner()];
            const allPromises = [];
            requiredPromises.push(
                ShipmentCarrierAPI.getRequiredParameters(this.carrierId).then(response => {
                    this.requiredParameters = response.data.required_parameters.map(key => ({
                        key: key,
                        required: true
                    }));
                })
            );
            allPromises.push(...requiredPromises);
            allPromises.push(
                ShipmentCarrierAPI.getOptionalParameters(this.carrierId).then(response => {
                    this.optionalParameters = response.data.optional_parameters.map(key => ({
                        key: key,
                        required: false
                    }));
                }).catch(this.snack)
            );

            Promise.all(requiredPromises).catch(err => {
                this.snack(err);
                this.$router.back();
            });
            if (this.isEdit) {
                Promise.all(allPromises).then(() => {
                    ShipmentCarrierAPI.getContracted(this.carrierId, this.ownerId).then(response => {
                        if (response.data.parameters) {
                            this.requiredParameters.map(requiredParam => {
                                const param = response.data.parameters.find(param => param.key === requiredParam.key);
                                if (param) {
                                    this.$set(requiredParam, 'value', param.value);
                                }
                            });
                            this.optionalParameters.map(optionalParam => {
                                const param = response.data.parameters.find(param => param.key === optionalParam.key);
                                if (param) {
                                    this.$set(optionalParam, 'value', param.value);
                                }
                            });
                        }
                    }).finally(() => this.loading = false);
                });
            } else {
                Promise.all(allPromises).finally(() => this.loading = false);
            }
        },
        methods: {
            fetchCarrier: function () {
                return ShipmentCarrierAPI.get(this.carrierId).then(response => {
                    this.carrier = response.data;
                });
            },
            fetchOwner: function () {
                return StockAPI.getOwner(this.ownerId).then(response => {
                    this.owner = response.data;
                });
            },
            saveSingle: function (key, value, required = true) {
                this.loading = true;
                if (!required && (value === null || value === '')) {
                    ShipmentCarrierAPI.deleteParameter(this.carrierId, this.ownerId, key)
                        .catch(this.snack)
                        .finally(() => this.loading = false);
                } else {
                    ShipmentCarrierAPI.createOrUpdateParameter(this.carrierId, this.ownerId, key, value)
                        .catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }

                const parameters = this.requiredParameters.concat(this.optionalParameters);
                this.loading = true;
                if (this.isEdit) {
                    const promises = [];
                    parameters.map(parameter => {
                        if (parameter.value === '') {
                            promises.push(
                                ShipmentCarrierAPI.deleteParameter(this.carrieId, this.ownerId, parameter.key, parameter.value)
                                    .catch(this.snack)
                            );
                        } else {
                            promises.push(
                                ShipmentCarrierAPI.createOrUpdateParameter(this.carrierId, this.ownerId, parameter.key, parameter.value)
                                    .catch(this.snack)
                            );
                        }
                    });
                    Promise.all(promises).then(() => {
                        this.advancedSnack({
                            text: 'carriers.contracted.update.done',
                            params: [this.carrier.name]
                        });
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.back();
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    const parameters = this.requiredParameters.concat(this.optionalParameters);
                    ShipmentCarrierAPI.addContract(this.carrierId, this.ownerId, {
                        contracted_carrier_parameters: parameters
                            .filter(param => delete param.required)
                            .filter(param => param.value)
                    })
                        .then(() => {
                            ShipmentCarrierAPI.updateContractedCarrierSubstocks(this.carrierId, this.ownerId, {subordinate_stocks: [this.subStockId]})
                                .then(() => {
                                    this.advancedSnack({
                                        text: 'carriers.contracted.create.done',
                                        params: [this.carrier.name]
                                    });
                                    this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                    this.$router.back();
                                }).catch(this.snack)
                                .finally(() => {
                                    this.loading = false;
                                });
                        }).catch(err => {
                            this.snack(err);
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
