<template>
  <span>
    <ConfirmDeleteDialog
      v-if="deletedService !== null"
      :show.sync="confirmDialog"
      :text="deletedService.name"
      @confirm-delete="reallyDeleteService"
    />
    <template
      v-if="loading"
    >
      <v-progress-circular
        v-if="inline"
        indeterminate
        :size="20"
        :width="2"
        class="ml-4 mb-1"
      />
      <v-progress-linear
        v-else
        indeterminate
      />
    </template>
    <template
      v-else
    >
      <template v-if="inline">
        <template
          v-if="items.length === 0"
        >
          <span
            class="text-caption"
          >
            {{ $t('carriers.services.noServices') }}
          </span>
        </template>
        <template v-else>
          <WrappedChipList
            v-slot="{item: service}"
            :items="items"
            :chip-class="textClass"
          >
            {{ service.name }}
          </WrappedChipList>
        </template>
      </template>
      <template v-else>
        <x-data-table
          :headers="headers"
          :loading.sync="loading"
          :items="items"
          :actions="actions"
          :show-search-bar="false"
        >
          <template #item.required_carrier_service_parameters="{ item }">
            {{ (item.carrier_service_parameters || []).filter(par => par.required).map(par => par.name).join(', ') }}
          </template>
          <template #item.optional_carrier_service_parameters="{ item }">
            {{ (item.carrier_service_parameters || []).filter(par => !par.required).map(par => par.name).join(', ') }}
          </template>
          <template #footer>
            <TableAddItemButton
              :to="carrierServiceCreateLink"
              label="carriers.services.create.title"
            />
          </template>
        </x-data-table>
      </template>
    </template>
  </span>
</template>

<script>
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {carrierServiceTable} from "@/app/carriers/definitions/carrierService.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import WrappedChipList from "@/app/components/WrappedChipList.component";

    export default {
        name: "CarrierServicesList",
        components: {WrappedChipList, TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        props: {
            services: {
                type: Array,
                default: null
            },
            inline: {
                type: Boolean,
                default: false
            },
            textClass: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            loading: false,
            items: [],
            deletedService: null,
            confirmDialog: false
        }),
        computed: {
            headers: function () {
                return createHeaders(carrierServiceTable, 'carriers.services.');
            },
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/carriers/' + this.carrierId + '/services/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            carrierServiceCreateLink: function () {
                return `/carriers/${this.carrierId}/services/create`;
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        watch: {
            services: function () {
                this.getData();
            }
        },
        methods: {
            getData: function () {
                if (this.services === null && this.carrierId) {
                    this.loading = true;
                    ShipmentCarrierAPI.getServices(this.carrierId)
                        .then(response => {
                            this.items = response.data;
                        }).catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.items = this.services || [];
                }
            },
            deleteItem: function (item) {
                this.deletedService = item;
                this.confirmDialog = true;
            },
            reallyDeleteService: function () {
                this.loading = true;
                this.confirmDialog = false;
                ShipmentCarrierAPI.deleteService(this.carrierId, this.deletedService.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'carriers.services.delete.done',
                            params: [this.deletedService.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.getData();
                    });
            },
        }
    };
</script>

<style scoped>

</style>
