import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

export const AttributesAPI = {

    API: BaseAPI,
    DOMAIN: 'attribute-templates',
    DOMAIN_CONFIGURATIONS: 'task-configurations',

    // Templates

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     */
    getAll(...params) {
        return this.API.get(
            this.DOMAIN,
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(
            this.DOMAIN,
            this.DOMAIN,
            merge(...params)
        );
    },

    get(attributeTemplateId) {
        return this.API.get([this.DOMAIN, attributeTemplateId]);
    },

    create(data) {
        return this.API.post([this.DOMAIN], data);
    },

    update(attributeTemplateId, data) {
        return this.API.patch([this.DOMAIN, attributeTemplateId], data);
    },

    delete(attributeTemplateId) {
        return this.API.delete([this.DOMAIN, attributeTemplateId]);
    },


    // Task configurations for substock

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     */
    getAllConfigurations(...params) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_CONFIGURATIONS],
            [this.DOMAIN],
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllConfigurationsAllPages(...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, this.DOMAIN_CONFIGURATIONS],
            this.DOMAIN,
            merge(...params)
        );
    },

    createConfiguration(attributeTemplateId, data) {
        return this.API.post([this.DOMAIN, attributeTemplateId, this.DOMAIN_CONFIGURATIONS], data);
    },

    updateConfiguration(attributeTemplateId, configurationId, data) {
        return this.API.put([this.DOMAIN, attributeTemplateId, this.DOMAIN_CONFIGURATIONS, configurationId], data);
    },

    deleteConfiguration(attributeTemplateId, configurationId) {
        return this.API.delete([this.DOMAIN, attributeTemplateId, this.DOMAIN_CONFIGURATIONS, configurationId]);
    },
};
