const importNotFound = "Požadovaný import nebol nájdený";
export default {
    title: "Import",
    imports: "Importy",
    link: "Importy",
    create: "Vytvoriť import",
    deleted: "Import zmazaný",
    file: "Súbor",
    file_info_create: "Súbor použitý na vytvorenie šablóny pre budúce importy",
    file_info_use: "Zadajte súbor, z ktorého sa budú dáta importovať. Zadajte súbor s príslušným formátom údajov podľa zvolenej šablóny.",
    domain: "Cieľová oblasť dat",
    domain_info: "Oblasť údajov, do ktorej sa budú tieto dáta importovať s použitím tejto šablóny. Vyberte oblasť údajov, do ktorej chcete v budúcnosti importovať dáta pomocou tejto šablóny.",
    choose_file: "Vyberte súbor",
    process: "Spracovať import",
    process_started: "Import bol odoslaný na spracovanie",
    processed: "Import bol spracovaný",
    download_imported_file: "stiahnuť daný súbor",
    download_error: 'Zlyhalo stiahnutie používateľského súboru',
    array_buffer_error: 'Nepodarilo sa premeniť používateľský súbor',
    viewImport: "Zobraziť import",
    configuration: "Nastavenia importu",


    stepper: {
        first_step: "Vytvoriť šablónu",
        second_step: "Pripraviť import",
        second_step_back: "Odstrániť a pripraviť nový import",
        third_step: "Skontrolovať a importovať",
    },

    hints: {
        parameters_hint: "Parametre importu umožňujú zadať ďalšie informácie potrebné pre importované údaje.",
        header_rows: "Nastavte počet riadkov hlavičky vo vašom súbore",
        first_row: "Ak sú v súbore údaje mimo importu, nastavte prvý riadok pre import",
        first_column: "Ak sú v súbore údaje mimo importu, nastavte prvý stĺpec pre import",
        required_attributes: "Všetky povinné atribúty musia byť zobrazené v tabuľke nižšie. Ak nie je možné zobraziť všetky povinné atribúty z vášho súboru a vybraná oblasť údajov nemusí byť kompatibilná",
        mapping: "Namapujte svoj súbor na vybranú oblasť údajov kliknutím na jej hlavičku a výberom cieľového atribútu. Pri mapovaní vyberte aj stĺpce na použitie ako porovnávače počas importu",
        primary_key: "Tento stĺpec je nastavený ako porovnávací atribút",
        easy_mode: "V tomto režime môžete pridávať iba nové položky v zvolenej cieľovej oblasti údajov",
        latest_on_duplicity: "Ak sa pri identifikácii záznamu nájde viacero zhodných záznamov, použije sa posledný vytvorený záznam.",
        fileTemplate: "Stiahnite si šablónu pre importný súbor Cieľové oblasťi dat."
    },

    rules: {
        file_type: "Nepodporovaný typ súboru",
        multiple_sheets: "Vybraný súbor obsahuje viac ako jednu list",
        cannot_access: "Nie je možné otvoriť vybraný súbor"
    },

    config: {
        title: "Šablóna importu",
        templates: "Šablóny importu",
        create: "Vytvoriť šablónu importu",
        edit: "Duplikovať a upraviť šablónu",
        view: "Zobraziť šablónu",
        created: "Šablóna bola vytvorená",
        save: "Uložiť šablónu",
        save_new: "Uložiť novú šablónu",
        deleted: "Šablóna importu bola zmazaná",
        easyMode: "Režim uloženia",
        latestOnDuplicity: 'Ignorovať duplicity',

        name: "Názov šablóny",
        domain: "Cieľová oblasť údajov šablóny",
        file: "Súbor",
        defaultParameters: "Predvolené parametre šablóny",
        parameters: "Importné parametre",
        required_attributes: "Povinné atribúty",
        mapping: "Mapovanie stĺpcov",

        firstRow: "Prvý riadok",
        firstColumn: "Prvý stĺpec",
        headerRows: "Riadky hlavičky",

        unmap: "zrušiť výber",
        not_mapped: "nie je namapované",
        primary_set: "Nastaviť ako porovnávací atribút",
        primary_unset: "Zrušiť nastavenie porovnávacieho atribútu",
        empty_error: "Konfigurácia nemá import, z ktorého by sa brali referenčné údaje",
    },
    info: {
        title: "Informácie o importe",
        hint: "Informácie o vytvorenom importe sa počítajú, táto akcia môže trvať niekoľko minút.",
        include: "Import bude obsahovať: ",
        error: "Spracovanie tohto importu spôsobí nasledujúcu chybu: ",
        created: 'Vytvorí riadky',
        updated: 'Aktualizuje riadky',
        warn: "Varovanie",

        primary_key_toggle_warn: "Hlavná skupina musí mať aspoň jeden porovnávací atribút",
        primary_key_group_warn: "Skupinám {0} chýba aspoň jeden porovnávací atribút",
        fake_file_warn: "Šablóna zobrazuje ukážkový súbor, skutočný súbor nebol poskytnutý alebo nevyhovuje podmienkam",
        mapping_warn: "Ak máte nesprávne namapované povinné atribúty, znamená to, že vaša šablóna a vybraný súbor nie sú kompatibilné.",
        mapping_warn_create: "Nemáte namapované všetky požadované atribúty požadované vybranou cieľovou oblasťou údajov.",
        parameters_warn: "Všetky povinné parametre musia byť vyplnené",
        change_value_warn: "Zmena tejto hodnoty spôsobí straty aktuálneho mapovania",
        edit_values_warn: "Spracovanie tohto importu zmení existujúce údaje. Chcete pokračovať?",
        short_file_warn: "Počet stĺpcov v nahranom súbore je príliš malý pre aktuálnu šablónu.",
        delete_import: "Chcete zachovať neúspešný import?",
    },

    errors: {
        'import': {
            status: {
                404: importNotFound,
            }
        },
        importCreate: {
            status: {
                409: 'Zlyhalo vytvorenie importu'
            }
        },
        importDelete: {
            status: {
                404: importNotFound,
                409: "Zlyhanie pri odstránení importu"
            }
        },
        importUpdate: {
            status: {
                404: importNotFound,
                409: 'Zlyhalo aktualizovanie importu'
            }
        },
        importProcess: {
            status: {
                404: importNotFound,
                409: "Zlyhalo odoslanie importu na spracovanie."
            }
        },
        domainTemplate: {
            status: {
                404: "Zlyhalo nájdenie údajov pre vybraný zdroj údajov",
            }
        },
    }
};
