import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('base.confirmDelete'))+" ")]),_c(VCardText,[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.text)+" ")]})],2),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.model = false}}},[_vm._v(" "+_vm._s(_vm.$t('base.cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.$emit('confirm-delete')}}},[_vm._v(" "+_vm._s(_vm.$t('base.delete'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }