<template>
  <v-card
    v-show="resolvedTaskId || attachments.length > 0"
    :class="{'ma-1': !inline}"
    :flat="inline"
  >
    <v-container
      fluid
      :class="{'ma-0 pa-0': inline}"
    >
      <div>
        <v-chip
          v-for="(attachment, index) of attachments"
          :key="attachment.id"
          class="my-1 mr-2"
          @click="download(index)"
        >
          <v-icon
            v-show="downloaded[index] === undefined"
            small
            class="mr-2"
          >
            $attachment
          </v-icon>
          <v-icon
            v-show="downloaded[index] === true"
            small
            class="mr-2"
          >
            $taskAttachmentDownloaded
          </v-icon>
          {{ attachment.file_name }}
        </v-chip>
      </div>
      <TaskAttachmentUpload
        v-if="resolvedTaskId"
        standalone
        :task-id="resolvedTaskId"
        @reload="reload"
      />
    </v-container>
  </v-card>
</template>

<script>
    import * as Export from "@/service/Export";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component.vue";
    import {TaskAPI} from "@/api/TaskAPI";

    export default {
        name: "TaskAttachments",
        components: {TaskAttachmentUpload},
        props: {
            attachments: {
                type: Array,
                default: () => []
            },
            inline: {
                type: Boolean,
                default: false
            },
            taskId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            downloaded: []
        }),
        computed: {
            resolvedTaskId: function () {
                return this.taskId || Number.parseInt(this.$route.params.taskId, 10);
            }
        },
        methods: {
            download: function (index) {
                Export.url(this.attachments[index].download_link, this.attachments[index].file_name);
                this.$set(this.downloaded, index, true);
            },
            reload: function () {
                TaskAPI.getAllAttachments(this.resolvedTaskId)
                    .then(response => {
                        /* eslint-disable-next-line vue/no-mutating-props */
                        this.attachments = response.data;
                    }).catch(this.snack);
            }
        }
    };
    // TODO display supported attachments in-place
</script>

<style scoped>

</style>
