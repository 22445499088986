<template>
  <div>
    <x-toolbar />
    <v-card
      class="mb-5"
      :loading="loading"
    >
      <v-card-text class="pb-0">
        {{ $t('imports.hints.fileTemplate') }}
      </v-card-text>
      <div class="d-flex col-md-6 col-lg-4 col-sm-12">
        <v-select
          v-if="!loading"
          v-model="selectedDomain"
          class="col-8 pb-0"
          :items="domains"
          clearable
          :label="$t('imports.domain')"
        >
          <template #item="{item}">
            <v-icon class="pr-2">
              {{ icons[item.domain] }}
            </v-icon>
            {{ item.text }}
          </template>
        </v-select>
        <v-select
          v-model="selectedType"
          class="col-4 pb-0"
          :items="types"
        />
        <div class="d-flex align-center">
          <v-btn
            v-if="selectedDomain"
            md
            accent
            color="accent"
            @click="downloadTemplate()"
          >
            {{ $t("exports.download") }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <ImportConfigListComponent />
  </div>
</template>

<script>
    import ImportConfigListComponent from "@/app/imports/components/ImportConfigList.component";
    import {ImportsAPI} from "@/api/ImportsAPI";
    import {DomainIcon} from "@/enum/domains";
    import {url} from "@/service/Export";
    import {ExportsAPI} from "@/api/ExportsAPI";

    export default {
        name: "ImportsList",
        components: {ImportConfigListComponent},
        data: () => ({
            domains: [],
            types: [],
            loading: false,
            icons: DomainIcon,
            selectedDomain: '',
            selectedType: '',
        }),
        createdOrActivated() {
            this.getTemplates();
        },
        methods: {
            getTemplates() {
                this.loading = true;
                ImportsAPI.getTemplates()
                    .then(res => {
                        this.domains = res.data.filter(item => item.supports_import)
                            .map(item => {
                                item.text = this.$t('exports.domains.' + item.domain);
                                return item;
                            });
                        ExportsAPI.getTypes()
                            .then(res => {
                                this.types = res.data.filter(type => type.supports_import).map(type => type.type);
                                this.selectedType = this.types[0];
                            }).finally(() => this.loading = false);
                    }).catch(err => this.snack(err));
            },
            downloadTemplate() {
                const urlAddress = this.domains.find(item => item.text === this.selectedDomain).templates
                    .find(template => template.type === this.selectedType).download_link;
                url(urlAddress);
            }
        }
    };
</script>

