export default {
    warehouseMap: "Карта складу",
    usageHeatMap: "Теплова карта завантаження складських позицій",
    mapEditor: "Редактор карти складу",
    backToMap: "Повернутися до карти",
    rack: "Стелаж",
    obstacle: "Перешкода",
    location: "Розташування",
    locations: "Розташування",
    zone: "Зона",
    shelf: "Полиця",
    shelfCount: "Кількість полиць",
    column: "Стовпець",
    cell: "Клітинка",
    countInCell: "Порядок у клітинці",
    firstSymbol: "Перший символ",
    secondSymbol: "Другий символ",
    thirdSymbol: "Третій символ",
    firstSymbolValue: "Символ",
    secondSymbolValue: "Символ",
    thirdSymbolValue: "Символ",
    firstStartingSymbol: "Початковий символ",
    secondStartingSymbol: "Початковий символ",
    thirdStartingSymbol: "Початковий символ",
    startingSymbol: "Початковий символ",
    numbering: "Нумерація",
    namingDirection: "Напрямок найменування",
    startingSymbolColumn: "Початковий символ",
    numberingColumn: "Стовпці",
    startingSymbolShelf: "Початковий символ",
    numberingShelf: "Полиці",
    separators: "Роздільники",
    firstSeparator: "Перший роздільник",
    secondSeparator: "Другий роздільник",
    thirdSeparator: "Третій роздільник",
    actions: "Дії",
    undefined: "Не визначено",
    multipleValues: "Декілька значень",
    untitled: "Без назви",
    width: "Ширина",
    height: "Висота",
    mapWidth: "Ширина мапи",
    mapHeight: "Висота мапи",
    depth: "Глибина",
    general: "Загальне",
    naming: "Система найменування",
    dimensions: "Розміри",
    stockObjects: "Складські об'єкти",
    racks: "Стелажі",
    locationGroups: "Розташування",
    zones: "Зони",
    obstacles: "Перешкоди",
    detail: "Деталі",
    remove: "Видалити",
    rename: "Перейменувати",
    newName: "Нова назва",
    advancedSettings: "Налаштування",

    objectWidth: "Ширина (кількість стовпців)",
    objectHeight: "Висота (кількість рядків)",
    rackShelves: "Висота (кількість полиць)",
    locationsOnPosition: "Розташування на позиції",
    locationName: "Назва розташування",
    oneZoneTypes: "Типи зони",

    dimension: "Розмір",
    newDimension: "Новий розмір",
    selectedLocations: "Вибрані розташування",
    selectedCells: "Вибрані клітинки",
    count: "Кількість",
    locationCount: "Кількість розташувань",
    cellCount: "Кількість клітинок",
    assign: "Призначити",
    moreValues: "Більше значень",
    withoutDimensions: "Без розміру",

    bottomToTop: "Знизу догори",
    topToBottom: "Зверху донизу",
    leftToRight: "Зліва направо",
    rightToLeft: "Справа наліво",

    noMaps: "Ви ще не створили жодної карти складу.",
    createMap: "Нова мапа",
    goBack: "Повернутися до огляду",
    goBackAnyway: "Відмінити зміни і вийти",
    stay: "Залишитися",
    unsavedChanges1: "Ви справді хочете вийти?",
    unsavedChanges2: "У вас є незбережені зміни, які будуть втрачені при виході.",
    deleteConfirm1: "Ви справді хочете видалити цю карту?",
    deleteConfirm2: "Ця дія є незворотньою.",
    delete: "Видалити",
    cancel: "Скасувати",
    save: "Зберегти",
    saved: "Мапа збережена",
    create: "Створити",
    mapCannotSaveDuplicit: "Не можна зберегти - дублікат розташування",
    errors: "Не збережено, виправте помилки",

    addRack: "Новий стелаж",
    addObstacle: "Нова перешкода",
    addZone: "Нова зона",
    addLocationsGroup: "Нове розташування",
    newObjectName: "Назва нового об'єкта",
    newLocationGroupLocations: "Нові розташування на цій позиції",
    addLocation: "Додати розташування",
    newZoneType: "Тип нової зони",

    detectedCollisions: "Було виявлено зіткнення",
    cannotFinishActionDueToCollision: "Дію не можна завершити, було виявлено колізію розташувань.",
    zoom: "Збільшення",

    automaticLocationEdit: "Автоматичне редагування розташування",
    manualLocationEdit: "Ручне редагування розташування",

    zoneTypes: {
        receive: "Прийом",
        delivery: "Видавання",
        packing: "Місце пакування",
        forklift: "Паркування навантажувачів",
    },

    locationMappingDetails: {
        notMapped: "Ненамаповане розташування",
        mapped: "Намаповані розташування",
        toCreate: "Для створення (неіснуюче розташування)",
        duplicit: "Дублікат розташування (багаторазово в мапі)",
    },

    bookmarks: {
        map: "Карта складу",
        rack: "Деталі стелажу"
    },

    tools: {
        edit: "Редагувати",
        move: "Рух",
        rotate: "Обертання",
        copy: "Копіювати",
        delete: "Видалити",
        cancel: "Скасувати вибір",
        cancelAction: "Скасувати дію"
    },

    guide: {
        move: "Натисніть на вільне місце, куди хочете перемістити об'єкт.",
        createRack: "Натисніть на вільне місце, де почнеться новий стелаж.",
        createObstacle: "Натисніть на вільне місце, де почнеться нова перешкода.",
        createZone: "Натисніть на вільне місце, де почнеться нова зона.",
        createLocationsZone: "Натисніть на вільне місце, де почнеться нова група розміщень.",
        copy: "Натисніть на вільне місце, куди буде скопійовано об'єкт."
    },

    locationsSync: {
        sync: "Синхронізувати розміщення",
        mapNotHavingStockLoc: "Розміщення на карті, які не мають відповідних розміщень на складі",
        stockNotHavingMapLoc: "Розміщення на складі, які не мають відповідних розміщень на карті",
        createStockLocCorrespondingMap: "Автоматично створити відповідні розміщення на складі",
        deleteStockLocCorrespondingMap: "Автоматично видалити не відображені розміщення на складі"
    },

    locationCodeDecide: {
        chooseLocation: "Виберіть розміщення",
        guide1: "На складі знаходиться кілька розміщень з назвою",
        guide2: ". Виберіть розміщення, яке хочете додати.",
        notMapped: "Не відображені розміщення",
        mapped: "Вже відображені розміщення",
        add1: "Додати розміщення",
        add2: "з кодом",
    },

    error: {
        applyDimensionsToNull: "Розміри не можуть бути призначені. Спершу виконайте вибір комірок стелажу."
    }
};
