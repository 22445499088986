<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.substockTransfer.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <template v-if="details">
      <template v-if="isAutoCompleted">
        <v-alert
          :value="true"
          icon="$hint"
          color="secondary"
          outlined
        >
          {{ $t('tasks.substockTransfer.isAutocompleted') }}
        </v-alert>
      </template>
      <template v-if="hasTabs">
        <template v-if="assignable">
          <TaskStartActionButton
            :loading="loading"
            @continue="assign"
          />
        </template>
        <TaskConflict
          v-if="conflict"
          :error="conflict"
        />
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
          >
            <component
              :is="item.component"
              :api="API"
              :task-info="taskInfo"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <SubstockTransferAssignment
          :task-info="taskInfo"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {TaskSubstockTransferAPI as API} from "@/api/TaskSubstockTransferAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import TaskStartActionButton from "@/app/tasks/components/TaskStartActionButton.vue";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import SubstockTransferAssignment
        from "@/app/tasks/substockTransfer/components/SubstockTransferAssignment.component";
    import SubstockTransferTransfer from "@/app/tasks/substockTransfer/components/SubstockTransferTransfer.component";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import {TaskState} from "@/enum/task_state";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";

    export default {
        name: "SubstockTransferShow",
        components: {TaskConflict, TaskStartActionButton, SubstockTransferAssignment},
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin, EventsListenerMixin, BarcodeListenerMixin, RouteParamsMapperMixin],
        data: () => ({
            API: API,
            loading: false,
            type: taskTypes.SUBSTOCK_TRANSFER,
            details: null,
            tab: null,
            ready: false,
            destinationLocations: []
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.activateTab,
                };
            },
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.typeName;
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: SubstockTransferAssignment
                }]
                    .concat(
                        (
                            this.isStorekeeper || this.isChief
                        ) ? [{
                            label: 'transferProducts',
                            component: SubstockTransferTransfer
                        }] : [])
                    .concat(
                        (
                            (this.isStorekeeper && this.assignedToCurrentUser && this.isInProgress) || this.isChief
                        ) ? [{
                            label: this.isOpenState ? 'finish' : 'movements',
                            component: TaskDefaultOverview
                        }] : []);
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.MANYTOMANY,
                    destinationLocations: this.destinationLocations
                };
            },
            isAutoCompleted: function () {
                return this.details.state === TaskState.CLOSED
                    && this.details.created_at === this.details.updated_at
                    && this.details.created_by === this.details.updated_by;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook);
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return API.get(this.taskId, true)
                    .then(response => {
                        if (this.details === null || lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                            this.fetchDestinationLocations(); // TODO use this in cards
                        } else {
                            assign(this.details, response.data);
                        }
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            },
            fetchDestinationLocations: function () {
                StockAPI.getAllSubstockAvailableLocationsAllPages(this.taskInfo.details.destination_subordinate_stock.stock_id, this.taskInfo.details.destination_subordinate_stock.id)
                    .then(response => {
                        this.destinationLocations = response.data.items;
                    }).catch(this.snack);
            }
        }
    };
</script>

<style scoped>

</style>
