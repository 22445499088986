export default {
    dailyLocation: 'Daily location occupancy report',
    dailyLocationByType: 'Daily location occupancy report by type',
    dailyLocationWithMovement: 'Daily location occupancy report with movement',
    dailyLocationOccupancy: 'Daily location occupancy',

    parameters: {
        date: 'Date',
        interval: 'Interval',
        interval_from: 'Date from',
        interval_to: 'Date to',
        subordinate_stock_ids: 'Sub-warehouses',
        manufacturer_ids: "Manufacturers",
        product_ids: "Products",
        product_type_ids: 'Product types',
        location_ids: "Warehouse locations",
        report_type: 'Report type',
        task_types: 'Task type',
        include_missing_products: 'Include missing Products',
        include_empty_locations: "Zahrnout prázdné umístění"
    },

    attributes: {
        last_loaded_at: 'Last loaded at',
        last_picked_at: 'Last picked at',
        filled_locations_count: 'Filled locations count',
        object_id: 'Object identificator',
        filled_location_count: 'Count of filled locations at the end of period\'',
        stock_loaded_amount: 'Amount loaded to stock',
        period_end_items_amount: 'Amount of items at the end of period',
        transfers_count: 'Count of transfers',
        stock_picked_amount: 'Amount picked from stock',
        product_type_id: 'Product type ID',
        manufacturer_id: 'Manufacturer ID',
        hour: 'Hour',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        week_of_month: 'Week of month',
        occupancy: 'occupancy'
    },

    hints: {
        report_type: 'The report type specifies what the first columns "Object Identifier" and "Name" will contain. The value of the last column will belong to the object from the first columns.',
        interval: 'The interval determines the length of the time periods into which the entire selected period will be divided.'
    }
};