import sha256 from "js-sha256";
import {config} from "@/config/config";
import {arrayBufferToString, stringToArrayBuffer} from "@/utils/string";

function createAesKey(key) {
    const normalizedKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return window.crypto.subtle.importKey(
        "raw",
        stringToArrayBuffer(normalizedKey, 32),
        {name: "AES-CBC"},
        true,
        ["decrypt", "encrypt"]
    );
}

/**
 * @param method {'encrypt' | 'decrypt'}
 * @param data {string}
 * @param key {string}
 * @returns {Promise<string>}
 */
function crypt(method, data, key) {
    if (window.crypto.subtle) {
        return createAesKey(key)
            .then(aesKey => {
                if (method === 'encrypt') {
                    data = encodeURIComponent(data);
                }
                if (method === 'decrypt') {
                    data = atob(data);
                }
                const params = [
                    {
                        name: 'AES-CBC',
                        iv: stringToArrayBuffer(config.APP_URL + config.APP_VERSION, 16),
                    },
                    aesKey,
                    stringToArrayBuffer(data)
                ];
                switch (method) {
                    case 'encrypt':
                        return window.crypto.subtle.encrypt(...params)
                            .then(encryptedArrayBuffer => arrayBufferToString(encryptedArrayBuffer))
                            .then(encryptedString => btoa(encryptedString))
                            .catch(console.error);
                    case 'decrypt':
                        return window.crypto.subtle.decrypt(...params)
                            .then(plainArrayBuffer => arrayBufferToString(plainArrayBuffer))
                            .then(plainString => decodeURIComponent(plainString))
                            .catch(console.error);
                    default:
                        console.error(`Invalid crypt method '${method}', use 'encrypt' or 'decrypt'`);
                }
            });
    } else {
        // crypto.subtle is not available in a non-secure context (localhost)
        return Promise.resolve(data);
    }
}

const Crypto = {

    generateCodeVerifier() {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
        const length = 43;

        let result = '';
        if (window.crypto && window.crypto.getRandomValues) {
            const buffer = new Uint32Array(length);

            window.crypto.getRandomValues(buffer);
            for (let i = 0; i < length; i++) {
                result += CHARSET[buffer[i] % CHARSET.length];
            }
            return result;
        }

        for (let i = 0; i < length; i++) {
            result += CHARSET[Math.floor(Math.random() * CHARSET.length)];
        }
        return result;
    },

    createCodeChallenge(codeVerifier) {
        return btoa(String.fromCharCode(...new Uint8Array(sha256.arrayBuffer(codeVerifier))))
            .replace(/=/g, "")
            .replace(/\+/g, "-")
            .replace(/\//g, "_");
    },

    encrypt(plainData, key) {
        return crypt('encrypt', plainData, key);
    },

    decrypt(encryptedData, key) {
        return crypt('decrypt', encryptedData, key);
    }
};

export {Crypto};
