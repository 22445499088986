<template>
  <div>
    <x-toolbar />
    <v-layout
      justify-center
    >
      <v-btn
        to="/"
        class="mt-5"
      >
        {{ $t('base.home') }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
    import {Sentry} from "@/service/Sentry";

    export default {
        name: "NotFound",
        created: function () {
            Sentry.captureMessage(
                '404 displayed'
            );
        },
        mounted: function () {
            this.snack('base.pageNotFound');
            setTimeout(() => this.$router.push('/'), 1500);
        }
    };
</script>

<style scoped>

</style>
