<template>
  <div>
    <slot />
  </div>
</template>

<script>
    import {ResizeSensor} from "css-element-queries";

    export default {
        data: () => ({
            resizeSensor: null,
            clientHeight: null
        }),
        mounted: function () {
            this.gridItem = this.$parent;
            this.resizeSensor = new ResizeSensor(this.$el, this.resize);
            this.widgetHeight = this.$el.clientHeight;
        },
        activated: function () {
            this.resizeSensor.reset();
        },
        methods: {
            resize: function () {
                const widgetHeight = this.$el.clientHeight;
                const curHeight = this.gridItem.h * this.gridItem.rowHeight;
                if (widgetHeight !== this.widgetHeight || curHeight !== this.widgetHeight) {
                    const newH = Math.ceil((widgetHeight + this.gridItem.margin[1]) / (this.gridItem.rowHeight + this.gridItem.margin[1]));

                    this.widgetHeight = widgetHeight;

                    this.gridItem.eventBus.$emit(
                        'resizeEvent',
                        'resizeend',
                        this.gridItem.i,
                        this.gridItem.x,
                        this.gridItem.y,
                        newH,
                        this.gridItem.w,
                    );
                }
            }
        }
    };
</script>