import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBottomSheet,{attrs:{"scrollable":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({staticClass:"ml-1 mb-1 itemChip",attrs:{"label":"","color":_vm.chipColor,"outlined":!_vm.chipColor}},on),[_c(VFadeTransition,{attrs:{"mode":"out-in"}},[_c('span',{key:_vm.quantity,staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.quantity)+" "),_c('span',{staticClass:"caption",class:!_vm.itemOnWrongLocation ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(_vm.quantityLabel)+" ")])])]),(_vm.isProductLoaded)?[(_vm.$store.getters['userConfig/showProductImagesInTasks'] && _vm.singleImage)?_c(VImg,{staticClass:"mx-1",attrs:{"src":_vm.singleImage.url,"lazy-src":_vm.publicPath + 'assets/product_image_placeholder.jpg',"contain":""}}):_vm._e(),_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(_vm.item.instance))+" ")])]:[_c(VProgressCircular,{staticClass:"mx-2",attrs:{"indeterminate":"","size":"14","width":"2"}})],_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.itemAlreadyPlacedIcon),expression:"itemAlreadyPlacedIcon"}],staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" $alreadyPlaced ")])],2)]}}]),model:{value:(_vm.showSheet),callback:function ($$v) {_vm.showSheet=$$v},expression:"showSheet"}},[_c(VSheet,[_c(VLazy,[(_vm.isProductLoaded)?_c('TaskItemDetails',_vm._b({attrs:{"prices":_vm.prices,"item-on-wrong-location":_vm.itemOnWrongLocation,"active-location-id":_vm.activeLocationId,"active-location-side":_vm.activeLocationSide},on:{"back":_vm.onClickBack,"remove":function($event){return _vm.$emit('remove', _vm.item, _vm.locationId)}}},'TaskItemDetails',_vm.$props,false)):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }