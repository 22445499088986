<template>
  <div>
    <v-card>
      <ConfirmDeleteDialog
        v-if="deletedExport !== null"
        :show.sync="confirmDialog"
        :text="deletedExport.name"
        @confirm-delete="reallyDeleteItem"
      />
      <v-card-title>
        {{ $t('exports.configuration.templates') }}
      </v-card-title>
      <x-data-table
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :default-action="false"
        :expanded="expanded"
        :headers="configHeader"
        :items.sync="items"
        :loading.sync="loading"
        :reload="reload"
        show-expand
      >
        <template #item.domain="{item}">
          <v-icon class="pr-2">
            {{ domainIcons[item.domain] }}
          </v-icon>
          {{ $t('exports.domains.' + item.domain) }}
        </template>
        <template #footer>
          <TableAddItemButton
            :to="exportConfigCreateLink"
            label="exports.configuration.create"
          />
        </template>
        <template #expanded-item="{ item }">
          <ExportsListComponent
            :id="item.id"
            :domain="item.domain"
            @reloadParent="reload++"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab :to="exportConfigCreateLink">
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {ExportsAPI as API} from "@/api/ExportsAPI";
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {createHeaders} from "@/utils/table";
    import {exportsConfigTable} from "@/app/exports/definitions/configuration.form";
    import ExportsListComponent from "@/app/exports/components/ExportList.component";
    import {LoadingStatuses} from "@/enum/import&export_state";
    import {DomainIcon} from "@/enum/domains";
    import {APIFilters, APIFilterOP} from "@/service/APIFilters";

    export default {
        name: "ExportsConfigurationList",
        components: {
            ExportsListComponent, XDataTable, ConfirmDeleteDialog, TableAddItemButton
        },
        data: () => ({
            loading: true,
            items: [],
            exportConfigCreateLink: '/exports/createConfig',
            deletedExport: null,
            confirmDialog: false,
            expanded: [],
            domainIcons: DomainIcon,
            configHeader: null,
            reload: 0,
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        icon: '$addItem',
                        label: 'exports.create.title',
                        color: 'primary',
                        notDefaultAction: true,
                        routerLink: item => '/exports/create/' + item.id,
                    },
                    {
                        loading: this.loading,
                        icon: '$showItem',
                        label: 'exports.configuration.view',
                        color: 'secondary',
                        routerLink: item => '/exports/view/' + item.id,
                    },
                    {
                        loading: this.loading,
                        icon: '$taskReport',
                        label: 'exports.configuration.copy',
                        color: 'accent',
                        routerLink: item => '/exports/createConfigCopy/' + item.id,
                    },
                    {
                        condition: item => item.count === 0,
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return API.getAllConfigurations.bind(API, {sort: APIFilters.makeSort({id: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return API.getAllConfigurationsPages.bind(API);
            }
        },
        createdOrActivated: function () {
            this.getConfigurations();
            this.expanded = [];
            this.configHeader = createHeaders(exportsConfigTable, 'exports.configuration.', true, true);
        },
        watch: {
            items() {
                this.getCounts();
            }
        },
        methods: {
            getConfigurations: function () {
                this.loading = true;
                const filter = {
                    [APIFilterOP.IN]: {
                        status: LoadingStatuses
                    }
                };
                API.getExports({
                    filter: APIFilters.makeFilter(filter),
                    sort: APIFilters.makeSort({created_at: "DESC"})
                })
                    .then(res => {
                        const allExports = res.data.items;
                        const openExport = allExports.filter(item => LoadingStatuses.includes(item.status))
                            .sort((a, b) => b.id - a.id)[0];
                        if (typeof openExport !== "undefined") {
                            API.get(openExport.id)
                                .then(response => {
                                    const openConfig = this.items.find(config => config.id === response.data.configuration.id);
                                    if(openConfig) {
                                        this.expanded.push(openConfig);
                                    }
                                }).catch(err => {
                                    this.snack(err);
                                });
                        }
                    })
                    .catch(err => {
                        this.snack(err);
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            getCounts() {
                const promises = [];
                this.items.forEach(item => {
                    promises.push(API.getAll(item.id).then(res => item.count = res.data.items.length));
                });
                Promise.allSettled(promises).then(() => this.getConfigurations());
            },
            deleteItem: function (item) {
                this.confirmDialog = true;
                this.deletedExport = item;
            },
            reallyDeleteItem: function () {
                this.confirmDialog = false;
                API.deleteConfiguration(this.deletedExport.id)
                    .then(() => {
                        this.snack('exports.configuration.deleted');
                        this.getConfigurations();
                        this.reload++;
                    }).catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        }
    };
</script>

<style scoped>

</style>
