<template>
  <div>
    <x-toolbar />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.substockTransfer."
          />
          <v-flex
            v-if="form.source_subordinate_stock_id"
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :valid.sync="validItems"
              title-path="tasks.substockTransfer.items.productsToMove"
              :readonly="loading"
              :only-in-substock-id="form.source_subordinate_stock_id"
            />
          </v-flex>
          <v-flex
            v-if="form.source_subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.source_subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskSubstockTransfer
              </v-icon>
              {{ $t('tasks.substockTransfer.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2">
      <StockSubstockCreateUpdate
        v-if="showCreateSubstock"
        id="substockForm"
        ref="substockForm"
        :is-edit="false"
        is-component
        :parent-stock-id="form.stock_id"
      />
    </div>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        SubstockTransferCreateForm,
        SubstockTransferCreateRender
    } from "@/app/tasks/substockTransfer/definitions/substockTransferCreate.form";
    import {StockAPI} from "@/api/StockAPI";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {TaskSubstockTransferAPI} from "@/api/TaskSubstockTransferAPI";
    import formRules from "@/utils/formRules";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import StockSubstockCreateUpdate from "@/app/stocks/substocks/StockSubstockCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";

    export default {
        name: "SubstockTransferCreate",
        components: {
            TaskAttributes,
            TaskConflict, StockSubstockCreateUpdate, TaskAttachmentUpload, FormFields, TaskChooseItems},
        mixins: [TaskFetchItemsMixin, EventsListenerMixin],
        data: () => ({
            form: new SubstockTransferCreateForm,
            formRender: new SubstockTransferCreateRender,
            valid: true,
            validAttachments: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            formRules: formRules,
            showCreateSubstock: false,
            conflict: null,
            type: taskTypes.SUBSTOCK_TRANSFER,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-substock': this.onCreateSubstock,
                    'create-substock-created': this.onCreateSubstockCreated,
                    'create-substock-cancelled': this.onCreateSubstockCancelled
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
        },
        createdOrActivated: function () {
            this.conflict = null;
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formRender.source_subordinate_stock_id, 'loading', true);
                this.$set(this.formRender.destination_subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_id)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        const substocksItems = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                        this.formRender.source_subordinate_stock_id.autocomplete.items = substocksItems;
                        this.formRender.destination_subordinate_stock_id.autocomplete.items = substocksItems;
                    }).finally(() => {
                        this.$set(this.formRender.source_subordinate_stock_id, 'loading', false);
                        this.$set(this.formRender.destination_subordinate_stock_id, 'loading', false);
                    });
            },
            onCreateSubstock: function () {
                if (this.form.stock_id !== null && this.form.stock_id !== undefined) {
                    this.showCreateSubstock = true;
                    if (this.$refs.fields !== undefined) {
                        this.$refs.fields.$refs.destination_subordinate_stock_id[0].blur();
                    }
                    this.$nextTick(() => {
                        scrollTo('substockForm');
                    });
                } else {
                    this.snack('base.shipping.table.subordinate_stock.chooseStockFirst');
                }
            },
            onCreateSubstockCreated: function (subStockId) {
                this.showCreateSubstock = false;
                this.loadSubStocks();
                this.form.destination_subordinate_stock_id = Number.parseInt(subStockId, 10);
            },
            onCreateSubstockCancelled: function () {
                this.showCreateSubstock = false;
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                if (this.form.source_subordinate_stock_id === this.form.destination_subordinate_stock_id) {
                    this.snack('tasks.substockTransfer.unableToMoveToSame');
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_instance_id;
                    });
                TaskSubstockTransferAPI.create(data)
                    .then(response => {
                        this.conflict = null;
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.substockTransfer.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        // error is not handled, need to get errors manually
                        if (err.response && err.response.status === 409) {
                            if (err.response.data.blocking_items) {
                                const problems = err.response.data.blocking_items || [];
                                this.chosenItems.filter(item => problems.find(problem => problem.instance_id === item.product_instance_id)).forEach(item => {
                                    this.$set(item, 'errors', [this.$t('tasks.substockTransfer.notEnoughItems')]);
                                });
                            } else {
                                this.conflict = err.response.data;
                            }
                        } else {
                            setFormErrors.call(this, err.response.data.errors);
                        }
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
