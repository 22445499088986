import {BaseAPI} from "@/api/BaseAPI";
import {CacheConfiguration} from "@/service/cache/CacheConfiguration";
import {IndexedDB} from "@/service/cache/IndexedDB";

const cacheGetting = Object.fromEntries(Object.keys(CacheConfiguration).map(key => [key, {}]));

const APIHelper = {

    /**
     * With the signature corresponding to the 'BaseAPI.get' method, this version fetches all pages simultaneously,
     * merges the result and returns it altogether.
     * Supports nested paging, names of all nested lists which are paged have to be passed using nestedProperties
     * @warning USE ONLY WHEN NECESSARY!
     * @param url {string|array}
     * @param langPath {string | string[]}
     * @param params {Object}
     * @param nestedProperties names of all nested lists which are paged
     * @return {Promise<unknown>}
     */
    getAllPages: function (url, langPath = undefined, params = {}, nestedProperties = []) {

        params.page = 1;
        params.itemsPerPage = 100;

        return new Promise((resolve, reject) => {
            BaseAPI.get(url, langPath, params)
                .then(response => {
                    const promises = [];
                    promises.push(Promise.resolve({data: response.data}));
                    const itemCount = response.data.item_count;
                    params.page++;
                    let pageCount = response.data.page_count;
                    if (nestedProperties.length !== 0) {
                        const nestedPageCounts = nestedProperties.map(property => response.data[property]?.page_count);
                        pageCount = Math.max(...nestedPageCounts);
                    }
                    while (params.page <= pageCount) {
                        promises.push(BaseAPI.get(url, langPath, params));
                        params.page++;
                    }
                    Promise.all(promises)
                        .then(responses => {
                            if (nestedProperties.length !== 0) {
                                const data = {};
                                nestedProperties.forEach(property => {
                                    data[property] = {
                                        items: responses.map(response => response.data[property]?.items).flat(1),
                                        item_count: responses[0].data[property]?.item_count
                                    };
                                });
                                resolve(data);
                                return;
                            }
                            resolve({
                                data: {
                                    items: responses.map(response => response.data.items).flat(1),
                                    item_count: itemCount
                                }
                            });
                        });
                })
                .catch(reject);
        });
    },

    /**
     * @param params {{
     *     collection: {string} one of CachePath.*
     *     key: {number|string}
     *     maxAge: {number}
     *     requestParams: {array} BaseAPI.get params
     * }}
     * @param forceFromAPi {boolean} Force to load from API
     * @return {Promise}
     */
    getWithCache: function (params, forceFromAPi = false) {
        const {collection, key, maxAge, requestParams} = params;
        if (!cacheGetting[collection][key]) {
            cacheGetting[collection][key] = new Promise((resolve, reject) => {
                (forceFromAPi ? Promise.reject() : IndexedDB.get(collection, key, maxAge))
                    .then(cacheValue => {
                        resolve({status: 200, data: cacheValue});
                    })
                    .catch(() => {
                        return BaseAPI.get(...requestParams)
                            .then(response => {
                                resolve(response);
                                return IndexedDB.save(collection, key, response.data);
                            }).catch(reject);
                    })
                    .finally(() => {
                        delete cacheGetting[collection][key];
                    });
            });
        }
        return cacheGetting[collection][key];
    },

    /**
     * @param request {Callable<Promise<any>>}
     * @param maxAttempts {number}
     * @param validate  {Function} Custom polling-done function
     * @return {Promise<any>}
     */
    polling: function (request, maxAttempts = 7, validate = () => true) {
        let abort = false;
        let attempts = 0;

        const abortPolling = (() => abort = true);
        const promise = new Promise((resolve, reject) => {
            const polledRequest = () => {
                return request()
                    .then(res => {
                        if (validate(res)) {
                            resolve(res);
                        } else {
                            throw String("error 423");
                        }
                    })
                    .catch(err => {
                        let status = 400;
                        if (err.response && err.response.status) {
                            status = err.response.status;
                        } else if (typeof err === 'string') {
                            status = Number.parseInt(err.slice(-3));
                        }
                        if (status === 423) {
                            attempts++;
                            if (attempts > maxAttempts || abort) {
                                reject(err);
                                return;
                            }
                            setTimeout(polledRequest, attempts * 500);
                        } else {
                            reject(err);
                        }
                    });
            };
            polledRequest();
        });
        return {
            promise: promise,
            abortPolling: abortPolling,
            attempts: attempts
        };
    },
};

export {APIHelper};
