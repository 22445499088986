<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-container grid-list-xl>
      <v-layout
        align-center
        justify-start
        column
        fill-height
        class="mt-2"
      >
        <div v-if="lastUserExists">
          <v-card
            elevation="12"
          >
            <v-layout
              align-center
              column
            >
              <v-flex class="mb-n10">
                <v-card-title>
                  <v-icon x-large>
                    $continueAsSuggestedUser
                  </v-icon>
                </v-card-title>
              </v-flex>
              <v-flex class="mb-n5">
                <v-card-text>
                  <v-btn
                    ref="defaultButton"
                    large
                    color="secondary"
                    class="loginBtn"
                    :loading="state.login.loading"
                    :disabled="state.login.disabled"
                    @click="login()"
                  >
                    {{ $t('base.continueAs') }}
                    <br>
                    {{ lastUser }}
                  </v-btn>
                </v-card-text>
              </v-flex>
              <v-flex>
                <v-btn
                  outlined
                  color="secondary"
                  class="mb-4"
                  :loading="state.logout.loading"
                  :disabled="state.logout.disabled"
                  @click="logout()"
                >
                  {{ $t('base.logout') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card
            elevation="6"
            class="mt-12"
          >
            <v-layout
              align-center
              column
            >
              <v-flex class="mb-n10">
                <v-card-title>
                  <v-icon x-large>
                    $loginAsSomebodyElse
                  </v-icon>
                </v-card-title>
              </v-flex>
              <v-flex>
                <v-card-text class="text-xs-center">
                  <v-btn
                    large
                    outlined
                    color="secondary"
                    :loading="state.relogin.loading"
                    :disabled="state.relogin.disabled"
                    @click="relogin()"
                  >
                    {{ $t('base.loginOther') }}
                  </v-btn>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
        </div>
        <div v-else>
          <v-card
            elevation="12"
          >
            <v-layout
              align-center
              column
            >
              <v-flex>
                <v-card-title class="mb-n10">
                  <v-icon x-large>
                    $login
                  </v-icon>
                </v-card-title>
              </v-flex>
              <v-flex>
                <v-card-text>
                  <v-btn
                    ref="defaultButton"
                    large
                    color="secondary"
                    class="loginBtn"
                    :loading="state.login.loading"
                    :disabled="state.login.disabled"
                    @click="login()"
                  >
                    {{ $t('base.login') }}
                  </v-btn>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
    import {tabTitle} from "@/utils/string";

    export default {
        name: "Login",
        data: () => ({
            lastUser: localStorage.getItem('lastUser'),
            state: {
                login: {
                    loading: false,
                    disabled: false
                },
                logout: {
                    loading: false,
                    disabled: false
                },
                relogin: {
                    loading: false,
                    disabled: false
                }
            }
        }),
        computed: {
            lastUserExists: function () {
                return this.lastUser !== null;
            },
            pageTitle: function () {
                const title = this.$t('base.login');
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        mounted: function () {
            this.$refs.defaultButton.$el.focus();
        },
        methods: {
            login: function () {
                this.loader(this.state.login);
                this.$store.dispatch('oauth/redirectToAuthUrl');
            },
            logout: function () {
                this.loader(this.state.logout);
                this.$store.dispatch('oauth/logout');
            },
            relogin: function () {
                this.loader(this.state.relogin);
                this.$store.dispatch('oauth/relogin');
            },
            loader: function (activeState) {
                for (const key in this.state) {
                    this.state[key].disabled = true;
                }
                activeState.disabled = false;
                activeState.loading = true;
            }
        }
    };
</script>

<style scoped lang="sass">
    .loginBtn
        min-height: 75px
</style>
