<template>
  <div>
    <x-toolbar />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :item-class="item => item.active === false && 'text--disabled'"
        :actions="actions"
      >
        <template #item.roles="{ item }">
          <td>
            <span
              v-for="role of Roles"
              :key="role"
              :class="{visibilityHidden: !item['is_' + role.split('_')[1].toLowerCase()]}"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-chip
                    outlined
                    v-on="on"
                  >
                    <v-icon>
                      {{ icons[role] }}
                    </v-icon>
                  </v-chip>
                </template>
                <span>
                  {{ $t('users.role.' + role) }}
                </span>
              </v-tooltip>
            </span>
          </td>
        </template>
        <template #footer>
          <TableAddItemButton
            :to="userCreateLink"
            label="users.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="userCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {UserAPI as API} from "@/api/UserAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {Roles} from "@/enum/roles";
    import {createHeaders} from "@/utils/table";
    import {usersTable} from "@/app/users/definition/user.form";
    import {roleIcons} from "@/enum/icons";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";

    export default {
        name: "UsersList",
        components: {TableAddItemButton},
        mixins: [ACLMixin],
        data: () => ({
            loading: true,
            items: [],
            icons: roleIcons,
            userCreateLink: '/users/create',
            Roles: Roles
        }),
        computed: {
            headers: () => createHeaders(usersTable, 'users.'),
            actions: function () {
                return [
                    {
                        condition: item => item.active && this.isChief,
                        loading: this.loading,
                        routerLink: item => '/users/' + item.id,
                        icon: 'visibility',
                        label: 'base.view'
                    }, {
                        condition: item => this.isChief || item.id === this.$store.getters['oauth/getUserId'],
                        loading: this.loading,
                        action: this.updateItem,
                        routerLink: item => '/users/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.loading = true;
            API.getAll()
                .then(response => {
                    this.items = response.data;
                }).catch(() => {
                    this.snack('users.list.unableToLoad');
                }).finally(() => {
                    this.loading = false;
                });
        }
    };
</script>

<style scoped lang="sass">
.visibilityHidden
    visibility: hidden
</style>
