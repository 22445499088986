import {AttributeDataTypeIcons, AttributeDataTypes} from "@/enum/attribute_data_types";
import formRules from "@/utils/formRules";

export function TaskAttributesForm (attributes) {
    return Object.fromEntries(attributes.map(attribute => {
        return [attribute.id, null];
    }));
}

function TaskAttributeRules(attribute) {
    switch (attribute.data_type) {
        case AttributeDataTypes.INTEGER: return [formRules.integer];
        case AttributeDataTypes.FLOAT: return [formRules.number];
        default: return [];
    }
}

export function TaskAttributesRender(attributes) {
    const render = {};
    attributes.forEach(attribute => {
       render[attribute.id] = {
           label: attribute.value,
           icon: AttributeDataTypeIcons[attribute.data_type],
           checkbox: attribute.data_type === AttributeDataTypes.BOOLEAN,
           rules: TaskAttributeRules(attribute)
       };
    });
    return render;
}
