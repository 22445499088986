import {Roles} from "@/enum/roles";
import {taskNames, taskLinks, taskTypes} from "@/enum/task_type";
import Create from "@/app/tasks/stockPicking/StockPickingCreate.view";
import Show from "@/app/tasks/stockPicking/StockPickingShow.view";

const type = taskTypes.STOCK_PICKING;
const BASE_URL = taskLinks[type];

const routes = {
    create: {
        path: BASE_URL + '/create',
        component: Create,
        meta: {
            title: 'tasks.stockPicking.create',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    show: {
        path: BASE_URL + '/:taskId(\\d+)',
        component: Show,
        meta: {
            title: taskNames[type],
            requireRole: [Roles.STOREKEEPER, Roles.CHIEF],
            getParent: () => '/',
            trackTime: true
        }
    }
};

export default Object.values(routes);
