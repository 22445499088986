<template>
  <div
    v-if="$store.getters['loader/overlay/show']"
    class="overlay"
  >
    <v-layout
      align-center
      justify-center
      column
      fill-height
    >
      <v-card class="pa-12">
        <v-layout
          align-center
          justify-center
          column
        >
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="accent"
          />
          <p class="title mt-4">
            {{ $t('base.loading') }}
          </p>
          <template v-if="takesTooLong">
            <v-btn
              @click="cancelLoader()"
            >
              {{ $t('base.cancelLoading') }}
            </v-btn>
            <p class="caption mt-1">
              <v-icon small>
                $cancelLoadingHint
              </v-icon>
              {{ $t('base.cancelLoadingHint') }}
            </p>
          </template>
        </v-layout>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
    export default {
        name: "LoaderOverlay",
        data: () => ({
            takesTooLong: false,
            timeout: null
        }),
        computed: {
            isShown: function () {
                return this.$store.getters['loader/overlay/show'];
            }
        },
        watch: {
            isShown: function (newState) {
                if (newState === true) {
                    this.timeout = setTimeout(() => {
                        this.takesTooLong = true;
                    }, 5000);
                } else {
                    this.takesTooLong = false;
                    if (this.timeout !== null) {
                        clearTimeout(this.timeout);
                    }
                }
            }
        },
        methods: {
            cancelLoader: function () {
                this.$router.push('/');
                this.$store.commit('loader/overlay/hide');
            }
        }
    };
</script>

<style scoped>

</style>
