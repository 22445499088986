<template>
  <v-expansion-panels class="my-2 px-1">
    <v-expansion-panel>
      <v-expansion-panel-header class="text-subtitle-1">
        {{ $t(langPath + 'label') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-autocomplete
          v-model="assignedBuyerTypes"
          chips
          deletable-chips
          :items="availableBuyerTypes"
          :loading="loading"
          :item-disabled="() => loading"
          multiple
          outlined
          @input="onInput"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {BuyerTypeAPI} from "@/api/BuyerTypeAPI";

    export default {
        name: "StockSubstockAssignedBuyerTypes",
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            apiAssignedBuyerTypes: [],
            assignedBuyerTypes: [],
            availableBuyerTypes: [],
            loading: false,
            langPath: 'stocks.substocks.buyer_types.'
        }),
        createdOrActivated: function () {
            Promise.all([
                StockAPI.getSubStockAssignedBuyerTypes(this.stockId, this.subStockId)
                    .then(response => {
                        this.apiAssignedBuyerTypes = response.data.map(abt => abt.id);
                        this.assignedBuyerTypes = response.data.map(abt => ({
                            value: abt.id,
                            text: abt.name
                        }));
                    }),
                BuyerTypeAPI.getAllPages()
                    .then(response => {
                        this.availableBuyerTypes = response.data.items.map(abt => ({
                            value: abt.id,
                            text: abt.name
                        }));
                    })
            ])
                .catch(this.snack)
                .finally(() => {
                    this.loading = false;
                });
        },
        methods: {
            onInput: function (inputValue) {
                this.loading = true;

                const toAdd = inputValue.filter(onApi => !this.apiAssignedBuyerTypes.includes(onApi));
                const toDelete = this.apiAssignedBuyerTypes.filter(onApi => !inputValue.includes(onApi));

                const promises = [];
                toAdd.forEach(addId => {
                    promises.push(StockAPI.addSubStockAssignedBuyerType(this.stockId, this.subStockId, addId));
                });
                toDelete.forEach(deleteId => {
                    promises.push(StockAPI.deleteSubStockAssignedBuyerType(this.stockId, this.subStockId, deleteId));
                });

                Promise.all(promises)
                    .catch(this.snack)
                    .finally(() => {
                        this.apiAssignedBuyerTypes = inputValue;
                        this.loading = false;
                    });
            },
        }
    };
</script>

<style scoped>

</style>
