import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";

const BarcodeAPI =  {

    API: BaseAPI,
    DOMAIN: 'barcodes',

    decode(code) {
        return APIHelper.getWithCache({
            collection: CachePath.barcodes,
            key: code,
            requestParams: [[this.DOMAIN, code], 'base.api.barcodes']
        });
    }
};

export {BarcodeAPI};
