<template>
  <v-checkbox
    v-bind="$attrs"
    :value="localValue"
    :indeterminate="indeterminate"
    :true-value="true"
    :false-value="false"
    :class="{
      'x-checkbox-tristate-red': indeterminate
    }"
    @click="click"
  />
</template>

<script>
/**
 * Checkbox providing "null, true, false" values". True = checked, false = 'indeterminate', null = unchecked
 */
    export default {
        // eslint-disable-next-line
    name: "x-checkbox-tristate",
        props: {
            value: {
                type: [Boolean, null],
                default: null
            }
        },
        data: () => ({
            localValue: null,
            indeterminate: false
        }),
        computed: {
            triValue: {
                get: function () {
                    return this.indeterminate ? false : this.localValue ? true : null;
                },
                set: function (newTriValue) {
                    this.localValue = {
                        true: true,
                        false: true,
                        null: false,
                    }[newTriValue];
                    this.indeterminate = {
                        true: false,
                        false: true,
                        null: false,
                    }[newTriValue];
                }
            }
        },
        watch: {
            value: function () {
                this.triValue = this.value;
            },
            triValue: function () {
                this.$emit('input', this.triValue);
            }
        },
        methods: {
            click: function () {
                this.triValue = {
                    true: false,
                    false: null,
                    null: true,
                }[this.triValue];
            }
        }
    };
</script>

<style lang="sass">
.x-checkbox-tristate-red
    .v-input--selection-controls__ripple, i
        color: var(--v-error-base) !important
        caret-color: var(--v-error-base) !important
</style>
