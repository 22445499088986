import {TableFilter} from "@/enum/table_filter";
import {StockAPI} from "@/api/StockAPI";

const permissionsTable = {
    has_permission: {
        sortable: false,
        filterType: TableFilter.BOOLEAN
    },
    name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    stock_id: {
        filterType: TableFilter.SELECT_MULTIPLE,
        filterBy: 'stock.id',
        xAutocomplete: {
            apiDataSource: StockAPI.getAll.bind(StockAPI),
            resultTransform: el => {
                el.value = el.id;
                el.text = el.name;
                return el;
            },
            multiple: true,
        }
    }
};

export {permissionsTable};
