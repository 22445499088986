const notFound = "Zadaný výrobca neexistuje!";

export default {
    title: "Výrobcovia",
    link: "Výrobcovia",

    abbreviation: "Skratka",
    name: "Názov",

    list: {
        unableToLoad: "Zoznam výrobcov nebolo možné načítať. Skúste to prosím opäť neskôr."
    },
    create: {
        label: "Vytvoriť nového výrobcu",
        title: "Vytvoriť výrobcu",
        done: "Výrobca '{0}' vytvorený.",
        undo: {
            done: "Tvorba '{0}' úspešne zrušená!",
            failed: "Nepodarilo sa zrušiť tvorbu výrobcu!"
        }
    },
    update: {
        titleSimple: "Upraviť výrobcu",
        title: "Upraviť {0}",
        done: "Výrobca '{0}' upravený",
        undo: {
            done: "Úpravy '{0}' úspešne zrušené!",
            failed: "Nepodarilo se zrušiť úpravu výrobcu!"
        }
    },
    delete: {
        done: "Výrobca '{0}' zmazaný.",
        failed: "Výrobcu '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
        undo: {
            done: "Zmazanie '{0}' úspešne zrušené!",
            failed: "Nepodarilo sa zrušiť zmazanie výrobcu!"
        },
        status: {
            404: notFound,
            409: "Výrobca nemožno zmazať kvôli väzbám na skladové položky."
        }
    },
    status: {
        404: notFound,
        409: "Vyplnená skratka už existuje!"
    }
};
