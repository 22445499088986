import {MapObjectAction, MapObjectType, PanelMode, ZoneObjectType} from "@/enum/stock_map";
import {StockAPI} from "@/api/StockAPI";
import {MapAPI as MapAPI} from "@/api/MapAPI";
import {APIFilters} from "@/service/APIFilters";
import {ExportMap, ImportMapData} from "@/service/Map";

const MapMixin = {
  data: () => ({
    MapObjectType: MapObjectType,
    ZoneObjectType: ZoneObjectType,
    PanelMode: PanelMode,
    savePercentageIndicator: {
      savesAmount: 0,
      savesDone: 0
    },
    createUpdateMapApiMethods: {
      'map_racks': {
        create: (stockId, mapId, data) => {return MapAPI.createRack(stockId, mapId, data);},
        update: (stockId, mapId, objectId, data) => {return MapAPI.updateRack(stockId, mapId, objectId, data);}
      },
      'map_location_groups': {
        create: (stockId, mapId, data) => {return MapAPI.createLocationGroup(stockId, mapId, data);},
        update: (stockId, mapId, objectId, data) => {return MapAPI.updateLocationGroup(stockId, mapId, objectId, data);}
      },
      'map_obstacles': {
        create: (stockId, mapId, data) => {return MapAPI.createObstacle(stockId, mapId, data);},
        update: (stockId, mapId, objectId, data) => {return MapAPI.updateObstacle(stockId, mapId, objectId, data);}
      },
      'map_zones': {
        create: (stockId, mapId, data) => {return MapAPI.createZone(stockId, mapId, data);},
        update: (stockId, mapId, objectId, data) => {return MapAPI.updateZone(stockId, mapId, objectId, data);}
      },
    },
    deleteMapApiMethods: {
      'map_racks_delete': (stockId, mapId, objectId) => {return MapAPI.deleteRack(stockId,mapId,objectId);},
      'map_location_groups_delete': (stockId, mapId, objectId) => {return MapAPI.deleteLocationGroup(stockId,mapId,objectId);},
      'map_obstacles_delete': (stockId, mapId, objectId) => {return MapAPI.deleteObstacle(stockId,mapId,objectId);},
      'map_zones_delete': (stockId, mapId, objectId) => {return MapAPI.deleteZone(stockId,mapId,objectId);},
    },
  }),
  computed: {
    selectedObject() {
      return this.$store.getters["storageMap/getSelectedObject"];
    },
    doesRackSelectionExist() {
      return this.$store.getters['storageMap/doesRackSelectionExist'];
    },
    panelMode() {
      return this.$store.getters["storageMap/getPanelMode"];
    },
    loading() {
      return this.$store.getters["storageMap/isLoading"];
    },
    saving() {
      return this.$store.getters["storageMap/isSaving"];
    },
    changes() {
      return this.$store.getters["storageMap/isChanged"];
    },
    showMap() {
      return this.$store.getters["storageMap/showMap"];
    },
    getObjects() {
      return this.$store.getters["storageMap/getObjects"];
    },
    getZones() {
      return this.$store.getters["storageMap/getZones"];
    },
    getDimensions() {
      return this.$store.getters["storageMap/getDimensions"];
    },
    render() {
      return this.$store.getters['storageMap/render'];
    },
    mapSize() {
      return this.$store.getters['storageMap/getMapSize'];
    },
    duplicitLocations(){
      return !(this.$store.getters["storageMap/getDuplicitMapLocations"].length === 0);
    },
    notMappedStockLocations() {
      return !(this.$store.getters["storageMap/getNotMappedStockLocations"].length === 0);
    },
    stockLocationsToCreate() {
      return !(this.$store.getters["storageMap/getStockLocationsToCreate"].length === 0);
    },
    mapId: {
      get() {
        return this.$store.getters["storageMap/getMapId"];
      },
      set(val) {
        this.$store.commit('storageMap/setMapId',{value: val});
      }
    },
  },
  methods: {

    setLoading(value) {
      if (this.loading!==value) {
        this.$store.commit('storageMap/setLoading', {
          value: value
        });
      }
    },

    setSaving(value) {
      if (this.saving!==value) {
        this.$store.commit('storageMap/setSaving', {
          value: value
        });
      }
    },

    ruleObjectColumns(value) {
      const parsedValue = Number.parseInt(value, 10);

      const newObject = {
        tl: this.selectedObject.tl,
        width: this.selectedObject.width,
        height: this.selectedObject.height,
        type: this.selectedObject.type,
      };

      if (
        this.selectedObject.type === MapObjectType.RACK &&
        this.selectedObject.vertical
      ) {
        newObject.height = parsedValue;
      } else {
        newObject.width = parsedValue;
      }

      return this.checkCollisionsOfMapObjectRule(newObject);
    },

    ruleObjectRows(value) {
      const parsedValue = Number.parseInt(value, 10);

      const newObject = {
        tl: this.selectedObject.tl,
        width: this.selectedObject.width,
        height: this.selectedObject.height,
        type: this.selectedObject.type,
      };

      newObject.height = parsedValue;

      return this.checkCollisionsOfMapObjectRule(newObject);
    },

    checkCollisionsOfMapObjectRule(newObject) {
      if (
        this.$store.getters["storageMap/noCollisions"](
          newObject,
          this.selectedObject
        ) &&
        this.$store.getters["storageMap/isObjectInsideMap"](newObject)
      ) {
        return true;
      }
      return this.$t("map.detectedCollisions");
    },

    // Finds zone on the given map position if exists, else return null
    getZoneOnThisPosition(cell, x, y, zones) {
      let returnZone = null;
      if (cell.zones && zones != null) {
        for (const zone of zones) {
          for (let yExamined = 0; yExamined < zone.height; yExamined++) {
            for (let xExamined = 0; xExamined < zone.width; xExamined++) {
              if (
                zone.tl[1] + xExamined === x &&
                zone.tl[0] + yExamined === y
              ) {
                returnZone = zone;
                break;
              }
            }
          }
        }
      }
      return returnZone;
    },

    // Find if on the given position, there is a zone
    isZonePresentOnThisPosition(cell, x, y, zones) {
      return this.getZoneOnThisPosition(cell, x, y, zones) != null;
    },

    loadMap: function () {
      this.setLoading(true);
      const promises = [];
      let data = null;
      let racks = null;
      let obstacles = null;
      let locationGroups = null;
      let zones = null;
      let stockLocations = null;
      // Get all data
      promises.push(StockAPI.getAllLocationsAllPages(this.stockId, {sort: APIFilters.makeSort({name: 'ASC'})}).then(response => {stockLocations = response.data.items;}));
      promises.push(MapAPI.getMap(this.stockId, this.mapId).then(response => {data = response.data;}));
      promises.push(MapAPI.getAllRacksAllPages(this.stockId, this.mapId, {sort: APIFilters.makeSort({name: 'ASC'})}).then(response => {racks = response.data.items;}));
      promises.push(MapAPI.getAllLocationGroupsAllPages(this.stockId, this.mapId, {sort: APIFilters.makeSort({x_coordinate: 'ASC'})}).then(response => {locationGroups = response.data.items;}));
      promises.push(MapAPI.getAllObstaclesAllPages(this.stockId, this.mapId, {sort: APIFilters.makeSort({name: 'ASC'})}).then(response => {obstacles = response.data.items;}));
      promises.push(MapAPI.getAllZonesAllPages(this.stockId, this.mapId, {sort: APIFilters.makeSort({name: 'ASC'})}).then(response => {zones = response.data.items;}));
      // Get heat map
      promises.push(MapAPI.getHeatMap(this.stockId, this.mapId).then(response => {
        this.$store.commit('storageMap/setHeatMap', {
          data: response.data.data
        });
      }));
      return Promise.all(promises).then(() => {
        ImportMapData({
          mapData: data,
          racks: racks,
          locationGroups: locationGroups,
          obstacles: obstacles,
          zones: zones,
          stockLocations: stockLocations
        });
        this.savePercentageIndicator.savesDone++;
      }).catch(err => {
        this.snack(err);
      }).finally(() => {
        this.setLoading(false);
      });
    },

    saveMap: function () {
      if (this.changes && !this.duplicitLocations && !this.saving) {
        this.setSaving(true);
        const mapSaveData = ExportMap();
        const promises = [];

        let savesAmount = 2;
        for (const [key] of Object.entries(this.createUpdateMapApiMethods)) {
          savesAmount += mapSaveData[key].length;
        }
        for (const [key] of Object.entries(this.deleteMapApiMethods)) {
          savesAmount += mapSaveData[key].length;
        }
        this.savePercentageIndicator.savesDone = 0;
        this.savePercentageIndicator.savesAmount = savesAmount;

        for (const [key, method] of Object.entries(this.deleteMapApiMethods)) {
          for (const data of mapSaveData[key]) {
            promises.push(method(this.stockId, this.mapId, data).then(() => this.savePercentageIndicator.savesDone++));
          }
        }
        promises.push(MapAPI.update(this.stockId, this.mapId, mapSaveData.map_data).then(() => this.savePercentageIndicator.savesDone++));
        for (const [key, methods] of Object.entries(this.createUpdateMapApiMethods)) {
          for (const mapObject of mapSaveData[key]) {
            mapObject.action === MapObjectAction.CREATE
                ? promises.push(methods.create(this.stockId, this.mapId, mapObject.data).then(() => this.savePercentageIndicator.savesDone++))
                : promises.push(methods.update(this.stockId, this.mapId, mapObject.data.id, mapObject.data).then(() => this.savePercentageIndicator.savesDone++));
          }
        }
        return Promise.all(promises).catch(err => {
          this.snack(err);
        }).finally( () => {
          this.loadMap().finally( () => {
            this.setSaving(false);
          });
        });
      }
      return Promise.resolve();
    },
  },
};

export { MapMixin };
