import {Env} from "@/service/Environment";
import {ReportPrintTypes, ReportType} from "@/enum/report_type";

const Electron = {
    async getVersion() {
        if (Env.isElectron()) {
            return window.require('electron').ipcRenderer.invoke('public.version.get');
        } else {
            return false;
        }
    },

    async hasPrintConfigFor(mimeType) {
        if (Env.isElectron() && await this.getVersion() >= '5.1.0') {
            return window.require('electron').ipcRenderer.invoke('public.print.isMimeTypeConfigured', mimeType);
        } else {
            return false;
        }
    },

    /**
     * @param preferredMimeType {ReportType.*}
     * @returns {Promise<null|ReportType.*>}
     */
    async getConfiguredMimeType(preferredMimeType) {
        if (Env.isElectron()) {
            let selectedMimeType = ReportType.PDF;
            if (await this.getVersion() >= '5.1.0') { // Determine preferred MimeType based on existing configuration
                if (await this.hasPrintConfigFor(preferredMimeType.mime)) {
                    selectedMimeType = preferredMimeType;
                } else {
                    let atLeastOne = false;
                    for (const type of ReportPrintTypes) {
                        const has = await this.hasPrintConfigFor(type.mime);
                        if (has) {
                            atLeastOne = true;
                            selectedMimeType = type;
                            break;
                        }
                    }
                    if (!atLeastOne) {
                        return null;
                    }
                }
            } else {
                // In older Electron, stick to PDF
            }
            return selectedMimeType;
        } else {
            window.console.error('Not in Electron!');
            throw new Error('Not in Electron!"');
        }
    }
};

export {Electron};
