export default {
    warehouseMap: "Mapa skladu",
    usageHeatMap: "Heatmapa vytížení skladových pozic",
    mapEditor: "Editor mapy skladu",
    backToMap: "Zpět na mapu",
    rack: "Regál",
    obstacle: "Překážka",
    location: "Umístění",
    locations: "Umístění",
    zone: "Zóna",
    shelf: "Patro",
    shelfCount: "Počet pater",
    column: "Sloupec",
    cell: "Buňka",
    countInCell: "Pořadí v buňce",
    firstSymbol: "První symbol",
    secondSymbol: "Druhý symbol",
    thirdSymbol: "Třetí symbol",
    firstSymbolValue: "Symbol",
    secondSymbolValue: "Symbol",
    thirdSymbolValue: "Symbol",
    firstStartingSymbol: "Počáteční symbol",
    secondStartingSymbol: "Počáteční symbol",
    thirdStartingSymbol: "Počáteční symbol",
    startingSymbol: "Počáteční symbol",
    numbering: "Číslování",
    namingDirection: "Směr pojmenovávání",
    startingSymbolColumn: "Počáteční symbol",
    numberingColumn: "Sloupce",
    startingSymbolShelf: "Počáteční symbol",
    numberingShelf: "Patra",
    separators: "Oddělovače",
    firstSeparator: "První oddělovač",
    secondSeparator: "Druhý oddělovač",
    thirdSeparator: "Třetí oddělovač",
    actions: "Akce",
    undefined: "Nenastaveno",
    multipleValues: "Více hodnot",
    untitled: "Bez názvu",
    width: "Šířka",
    height: "Výška",
    mapWidth: "Šířka mapy",
    mapHeight: "Výška mapy",
    depth: "Hloubka",
    general: "Obecné",
    naming: "Jmenovací systém",
    dimensions: "Rozměry",
    stockObjects: "Skladové objekty",
    racks: "Regály",
    locationGroups: "Umístění",
    zones: "Zóny",
    obstacles: "Překážky",
    detail: "Detail",
    remove: "Odstranit",
    rename: "Přejmenovat",
    newName: "Nové jméno",
    advancedSettings: "Nastavení",

    objectWidth: "Šířka (počet sloupců)",
    objectHeight: "Výška (počet řádků)",
    rackShelves: "Výška (počet pater)",
    locationsOnPosition: "Umístění na pozici",
    locationName: "Název umístění",
    oneZoneTypes: "Typy zóny",

    dimension: "Rozměr",
    newDimension: "Nový rozměr",
    selectedLocations: "Vybraná umístění",
    selectedCells: "Vybrané buňky",
    count: "Počet",
    locationCount: "Počet umístění",
    cellCount: "Počet buněk",
    assign: "Přiřadit",
    moreValues: "Více hodnot",
    withoutDimensions: "Bez rozměru",

    bottomToTop: "Zdola nahoru",
    topToBottom: "Shora dolů",
    leftToRight: "Zleva doprava",
    rightToLeft: "Zprava doleva",

    noMaps: "Ještě jste nevytvořili žádnou mapu.",
    createMap: "Nová mapa",
    goBack: "Zpět na přehled",
    goBackAnyway: "Zahodit změny a odejít",
    stay: "Zůstat",
    unsavedChanges1: "Opravdu chcete odejít?",
    unsavedChanges2: "Máte neuložené změny, které budou po odchodu zahozeny.",
    deleteConfirm1: "Opravdu chcete smazat tuto mapu?",
    deleteConfirm2: "Tato akce je nevratná.",
    delete: "Odstranit",
    cancel: "Zrušit",
    save: "Uložit",
    saved: "Mapa uložena",
    create: "Vytvořit",
    mapCannotSaveDuplicit: "Nelze uložit - duplicitní umístění",
    errors: "Neuloženo, opravte chyby",

    addRack: "Nový regál",
    addObstacle: "Nová překážka",
    addZone: "Nová zóna",
    addLocationsGroup: "Nová umístění",
    newObjectName: "Jméno nového objektu",
    newLocationGroupLocations: "Nová umístění na této pozici",
    addLocation: "Přidat umístění",
    newZoneType: "Typ nové zóny",

    detectedCollisions: "Byla detekována kolize",
    cannotFinishActionDueToCollision: "Akce nemohla být dokončena, byla detekována kolize.",
    zoom: "Přiblížení",

    automaticLocationEdit: "Automatická úprava umístění",
    manualLocationEdit: "Manuální úprava umístění",

    zoneTypes: {
        receive: "Příjem",
        delivery: "Výdej",
        packing: "Balící místo",
        forklift: "Parkování retraků",
    },

    locationMappingDetails: {
        notMapped: "Nenamapované umístění",
        mapped: "Namapovaná umístění",
        toCreate: "K vytvoření (neexistující umístění)",
        duplicit: "Duplicitní umístění (vícekrát v mapě)",
    },

    bookmarks: {
        map: "Mapa skladu",
        rack: "Detail regálu"
    },

    tools: {
        edit: "Editovat",
        move: "Pohyb",
        rotate: "Rotace",
        copy: "Kopírovat",
        delete: "Smazat",
        cancel: "Zrušit výběr",
        cancelAction: "Zrušit akci"
    },

    guide: {
        move: "Klikněte na volné místo, kam objekt přesunout.",
        createRack: "Klikněte na volné místo, kde bude začínat nový regál.",
        createObstacle: "Klikněte na volné místo, kde bude začínat nová překážka.",
        createZone: "Klikněte na volné místo, kde bude začínat nová zóna.",
        createLocationsZone: "Klikněte na volné místo, kde bude začínat nová skupina umístění.",
        copy: "Klikněte na volné místo, kam bude objekt zkopírován."
    },

    locationsSync: {
        sync: "Synchronizovat umístění",
        mapNotHavingStockLoc: "Umístění na mapě, která nemají odpovídající umístění ve skladě",
        stockNotHavingMapLoc: "Umístění ve skladě, která nemají odpovídající umístění na mapě",
        createStockLocCorrespondingMap: "Automaticky vytvořit odpovídající umístění ve skladě",
        deleteStockLocCorrespondingMap: "Automaticky odstranit nenamapovaná umístění ve skladě"
    },

    locationCodeDecide: {
        chooseLocation: "Vyberte umístění",
        guide1: "Ve skladě se nachází více umístění s názvem ",
        guide2: ". Vyberte umístění, které si přejete přidat.",
        notMapped: "Nenamapovaná umístění",
        mapped: "Již namapovaná umístění",
        add1: "Přidat umístění",
        add2: "s kódem",
    },

    error: {
        applyDimensionsToNull: "Rozměry nemohly být přiřazeny. Nejdříve proveďte výběr buněk regálu."
    }
};
