import {Store} from "@/service/store/Store";
import {has} from "@/utils/object";

/**
 * Distributes errors from API to form fields. Shows snackbar if any errors do not fit component's form fields.
 * 'this' has to be bound to and Object with 'formRender' property
 * @param errors {Object}
 * @param renders {Array}
 */
function setFormErrors(errors, renders = ['formRender']) {
    if (errors === undefined || errors === null) {
        Store.commit('snackbar/set', {text: 'base.api.unexpectedError'});
        return;
    }
    let residueErrors = errors;
    for (const [key, value] of Object.entries(errors)) {
        for (const render of renders) {
            if (has(this[render], key)) {
                this.$set(this[render][key], 'errors', [value.message || value[0]]);
                delete residueErrors[key];
                return;
            }
        }
    }
    if (Object.keys(residueErrors).length > 0) {
        Store.commit('snackbar/set', {
            text: Array.isArray(residueErrors) ? residueErrors[0]
                : typeof residueErrors === 'object' ? Object.values(residueErrors)[0]
                    : residueErrors,
             timeout: 0
        });
    }
}

/**
 * Clears user-defined form errors
 * @param component Vue.js component with 'formRender' property
 */
function clearFormErrors(component) {
    for (const item in component.formRender) {
        if (has(component.formRender, item)) {
            delete component.formRender[item].errors;
        }
    }
}

/**
 * If the device is at least 'lg', then focus given field
 * @param component
 * @param field
 */
function formAutoFocus(component, field) {
    if (component.$vuetify.breakpoint.lgAndUp && typeof field.focus === 'function') {
        field.focus();
    }
}

/**
 *
 * @param component Vue.js component with '$refs.form', '$refs.fields {FormFields}' and 'formRender' attributes
 * @param clearErrors {boolean}
 * @param autoFocus {boolean}
 */
function formMountedProcedure(component, clearErrors = true, autoFocus = true) {
    if (clearErrors) {
        if (component.formRender !== undefined) {
            clearFormErrors(component);
        } else {
            window.console.error('Please provide component with "formRender"');
        }
    }
    if (autoFocus) {
        if (component.$refs.fields === undefined) {
            window.console.error('Please provide component with "$refs.fields"');
            return;
        }
        if (component.formRender === undefined) {
            window.console.error('Please provide component with "formRender"');
            return;
        }
        formAutoFocus(component, component.$refs.fields.$refs[Object.keys(component.formRender)[0]][0]);
    }
}

export {setFormErrors, clearFormErrors, formAutoFocus, formMountedProcedure};
