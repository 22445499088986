import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VCard,{attrs:{"loading":_vm.loading}},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"loading":"","type":"text, divider, text, table-heading, table, action"}}):_c(VContainer,{attrs:{"fluid":""}},[(!_vm.isEdit)?_c('div',[_c('ImportConfigHeader',{attrs:{"config":_vm.config}})],1):_c(VForm,{ref:"form"},[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.render,"lang-path":"imports.config."},scopedSlots:_vm._u([{key:"easyMode-append",fn:function(){return [_c('CheckBoxHint',{attrs:{"name":"imports.config.easyMode","hint":"imports.hints.easy_mode"}})]},proxy:true},{key:"latestOnDuplicity-append",fn:function(){return [_c('CheckBoxHint',{attrs:{"name":"imports.config.latestOnDuplicity","hint":"imports.hints.latest_on_duplicity"}})]},proxy:true}])})],1),_c('ImportConfigComponent',{attrs:{"config":_vm.configCopy,"existing-config":_vm.isEdit ? null : _vm.config,"source-file":_vm.file,"domain":_vm.config.domain,"parameters":_vm.parameters,"import-mode":_vm.importMode,"read-only":!_vm.isEdit,"config-name":_vm.form.name,"save-trigger":_vm.saveTrigger,"show-optional-parameters":_vm.isEdit},on:{"config-save":_vm.handleSaveConfig}}),(_vm.isEdit)?_c(VBtn,{staticClass:"mb-3 ml-3 align-right",attrs:{"loading":_vm.loading,"color":"accent"},on:{"click":_vm.createCopy}},[_vm._v(" "+_vm._s(_vm.$t('imports.config.save'))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }