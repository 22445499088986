<template>
  <v-layout>
    <v-flex>
      {{ item.text }}
    </v-flex>
    <v-flex class="text-right">
      <v-tooltip right>
        <template #activator="{ on }">
          <div v-on="on">
            <v-icon>$info</v-icon>
          </div>
        </template>
        <span>{{ $t(langPath + item.value) }}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
    export default {
        name: "TaskCreateStrictModeItem",
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            langPath: {
                type: String,
                default: '',
            }
        }
    };
</script>

<style scoped>

</style>
