export default {
    dailyLocation: 'Denná správa o obsadenosti lokality',
    dailyLocationByType: 'Denná správa o obsadenosti miesta podľa typu',
    dailyLocationWithMovement: 'Denná správa o obsadenosti miesta s pohybom',
    dailyLocationOccupancy: 'Denná obsadenosť lokality',

    parameters: {
        date: 'Dátum',
        interval: 'Interval',
        interval_from: 'Od dátumu',
        interval_to: 'Do dátumu',
        subordinate_stock_ids: 'Podsklady',
        manufacturer_ids: "Výrobcovia",
        product_ids: "Skladové položky",
        product_type_ids: "Typy skladových položiek",
        location_ids: "Umiestnenie v sklade",
        report_type: 'typ správy',
        task_types: 'Typ úlohy',
        include_missing_products: "Zahrnúť chýbajúce produkty",
        include_empty_locations: "Zahrnout prázdné umístění"
    },

    attributes: {
        last_loaded_at: 'Naposledy naskladnenie',
        last_picked_at: 'Naposledy vyskladnenie',
        filled_locations_count: 'Počet obsadených miest',
        object_id: 'Identifikátor objektu',
        filled_location_count: 'Počet vyplnených umiestnenie na konci obdobia',
        stock_loaded_amount: 'Množstvo vložené do skladu',
        period_end_items_amount: 'Množstvo položiek na konci obdobia',
        transfers_count: 'Počet prevodov',
        stock_picked_amount: 'Množstvo vybrané zo skladu',
        product_type_id: 'ID typu výrobku',
        manufacturer_id: 'Výrobca ID',
        hour: 'hodina',
        day: 'Deň',
        week: 'Týždeň',
        month: 'Mesiac',
        year: 'Rok',
        week_of_month: 'Týždeň v mesiaci',
        occupancy: 'obsadenosť'
    },

    hints: {
        report_type: 'Typ správy určuje čo budú obsahovať prvé stĺpce "Identifikátor objektu" a "Názov". Hodnota posledného stĺpca bude patriť objektu z prvých stĺpcov.',
        interval: 'Interval určuje dĺžku časových úsekov, na ktoré sa rozdelí celé zvolené obdobie.'
    }
};