<template>
  <div class="justify-center layout flex-wrap">
    <span
      v-for="(action, index) in possibleActions"
      :key="action.label"
    >
      <v-tooltip
        top
        open-delay="500"
      >
        <template #activator="{ on }">
          <v-btn
            small
            fab
            :color="action.color ? action.color : ''"
            outlined
            :text="index !== 0 || action.notDefaultAction"
            :disabled="action.loading"
            class="ma-1"
            :to="action.routerLink ? action.routerLink(item) : null"
            v-on="on"
            @click.stop="action.action ? action.action(item) : () => {}"
          >
            <template v-if="action.subscriptIcon">
              <v-icon
                class="mb-3 ml-1"
              >
                {{ action.icon }}
              </v-icon>
              <v-icon
                small
                class="mt-2 ml-n3"
              >
                {{ action.subscriptIcon }}
              </v-icon>
            </template>
            <template v-else>
              <v-icon
                small
              >
                {{ action.icon }}
              </v-icon>
            </template>
          </v-btn>
        </template>
        {{ $t(action.label) }}
      </v-tooltip>
    </span>
  </div>
</template>

<script>
    import {TableActionsMixin} from "@/app/mixins/TableActionsMixin";

    export default {
        name: "TableActionButtons",
        mixins: [TableActionsMixin],
    };
</script>

<style scoped>

</style>
