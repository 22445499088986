import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('x-toolbar'),_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VFlex,{staticClass:"pl-4 pt-5",attrs:{"md4":"","sm6":"","xl3":"","xs12":""}},[_c(VTextField,{attrs:{"label":_vm.$t('exports.configuration.name')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,[_vm._v(" $edit ")])]},proxy:true}]),model:{value:(_vm.configName),callback:function ($$v) {_vm.configName=$$v},expression:"configName"}})],1),(!_vm.loading)?_c('ExportConfig',{attrs:{"config":{
        id: _vm.config.id,
        columns: _vm.config.columns,
        parameters: _vm.config.parameters
      },"domain":_vm.config.domain,"show-optional-parameters":"","read-only":"","new-config-name":_vm.config.name}}):_vm._e(),(_vm.config)?_c(VBtn,{staticClass:"mb-3 ml-3",attrs:{"loading":_vm.btnLoad,"disabled":_vm.configName === _vm.config.name || _vm.configName === '',"color":"accent"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('exports.configuration.save'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }