<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <ImageCreateComponent
      :item-name="itemName"
      :item-id="itemId"
      :is-product-image="isProductImage"
      :is-foil-image="isFoilImage"
      @imagesUpdated="imagesUpdated"
    />
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import ImageCreateComponent from "@/app/images/components/ImageCreate.component";

    export default {
        name: "ImageCreate",
        components: {ImageCreateComponent},
        mixins: [InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isFoilImage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            itemName: '',
            itemId: null,
            loading: false,
            validLocal: false,
            imageLocalName: '',
            imageLocalUrl: '',
            imageLocalFile: '',
            validExternal: false,
            imageExternalUrl: '',
            api: ProductAPI
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.itemName !== '') {
                    const domain = this.isFoilImage ? 'carriers.images.' : 'base.images.';
                    const action = this.isEdit ? 'update' : 'create';
                    const imageType = this.isFoilImage ? '.foil' : '';
                    title = this.$t(domain + action + imageType + '.title', [this.itemName]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            isProductImage: function () {
                return this.productId !== undefined;
            }
        },
        createdOrActivated: function () {
            this.itemId = this.productId;
            if (!this.productId) {
                this.api = ShipmentCarrierAPI;
                this.itemId = this.carrierId;
            }
            this.api.get(this.itemId)
                .then(response => {
                    this.itemName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.productId ? this.$router.push('/products') : this.$router.push('/carriers');
                });
        },
        methods: {
            imagesUpdated() {
                this.productId ? this.$router.push('/products/' + this.itemId) : this.$router.push('/carriers/' + this.itemId);
            }
        }
    };
// TODO use https://vuetifyjs.com/en/components/file-inputs
</script>

<style scoped>

</style>
