<template>
  <v-list-item>
    <v-btn-toggle
      v-model="theme"
      mandatory
      class="mr-3"
    >
      <v-btn
        v-for="themeOption in ['light', 'dark', 'system']"
        :key="themeOption"
        text
      >
        <v-icon>{{ `$${themeOption}Theme` }}</v-icon>
      </v-btn>
    </v-btn-toggle>
    {{ $t('base.darkMode') }}
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarTheme",
        data: () => ({
            themes: ['light', 'dark', 'system'],
        }),
        computed: {
            theme: {
                get() {
                    const index = this.themes.indexOf(this.$store.getters['userConfig/theme']);
                    return index !== -1 ? index : 2;
                },
                set(val) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'theme',
                        value: this.themes[val]
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
