<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedLocation !== null"
      :show.sync="confirmDialog"
      :text="deletedLocation.code + ': ' + deletedLocation.name"
      @confirm-delete="reallyDeleteLocation"
    />
    <BarcodePrintDialog
      v-if="modalItem !== null || modalLocationIDS.length !== 0"
      :show.sync="barcodeModal"
      :barcode-count.sync="barcodeCount"
      :print-type.sync="printType"
      :title="modalItem ? $t('stocks.locations.printBarcodeHowMuch', [modalItem.code]) : $t('stocks.locations.printBarcodesHowMuch')"
      :confirm-text="$t('stocks.locations.printBarcodeMany')"
      @cancel-print="resetPrint"
      @print-barcode="reallyPrintBarcode"
    />
    <AvailabilityDialog
      :show.sync="availabilityModal"
      @set-availability="reallySetAvailability"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('stocks.locations.label') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :item-class="item => distinguishAllowedFor && item.is_allowed === false && item.is_whitelisted === false && 'text--disabled'"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :reload="reload"
            :batch-actions="batchActions"
            :batch-action-progress="batchActionProgress"
            :batch-action-total="batchActionTotal"
            :search-hint="$t('stocks.locations.searchHint')"
          >
            <template #item.availability="{ item }">
              <span :style="'font-weight: ' + (Math.max(10 * (item.availability) - 100, 100))">
                {{ item.availability }}
              </span>
            </template>
            <template #item.is_expedition="{ item }">
              <span
                :class="{
                  'text--disabled': !item.is_expedition
                }"
              >
                {{ item.is_expedition | yesOrNo }}
              </span>
            </template>
            <template #item.is_mobile="{ item }">
              <span
                :class="{
                  'text--disabled': !item.is_mobile
                }"
              >
                {{ item.is_mobile | yesOrNo }}
              </span>
            </template>
            <template #item.is_allowed="{ item }">
              {{ whitelistLabel(item) }}
            </template>
            <template #footer>
              <TableAddItemButton
                :to="locationCreateLink"
                label="stocks.locations.create.titleSimple"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {
        stockLocationTable,
        stockLocationTableInSubstock
    } from "@/app/stocks/locations/definitions/stockLocation.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import * as Export from "@/service/Export";
    import {PrintType} from "@/enum/print_type";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {AllowedLocationIdsCacheMixin} from "@/app/mixins/AllowedLocationIdsCacheMixin";
    import AvailabilityDialog from "@/app/stocks/locations/components/AvailabilityDialog.component";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {EventBus} from "@/service/EventBus";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "StockLocationsList",
        components: {AvailabilityDialog, TableAddItemButton, BarcodePrintDialog, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin, AllowedLocationIdsCacheMixin, EventsListenerMixin],
        props: {
            distinguishAllowedFor: {
                type: Number,
                default: null
            },
            substock: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            loading: true,
            items: [],
            batchActionProgress: null,
            batchActionTotal: 0,
            barcodeModal: false,
            modalItem: null,
            modalLocationIDS: [],
            barcodeCount: 1,
            printType: PrintType.PDF,
            deletedLocation: null,
            confirmDialog: false,
            availabilityModal: false,
            selectedIds: null,
            reload: 0
        }),
        computed: {
            events: function () {
                return {
                    'map-locations-synchronize-finished': this.reloadList,
                };
            },
            locationCreateLink: function () {
                return '/stocks/' + this.stockId + '/locations/create';
            },
            headers: function () {
                return createHeaders(
                    this.distinguishAllowedFor === null ? stockLocationTable : stockLocationTableInSubstock,
                    'stocks.locations.'
                );
            },
            actions: function () {
                return [
                    {
                        condition: item => !this.distinguishAllowedFor || item.is_allowed,
                        loading: this.loading,
                        action: this.showItem,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        routerLink: item => '/stocks/' + this.stockId + '/locations/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        action: this.showStockStatus,
                        icon: '$stockStatus',
                        label: 'stocks.locations.stockStatus'
                    }, {
                        condition: item => item.is_whitelisted,
                        loading: this.loading,
                        action: this.removeFromWhitelist,
                        icon: '$removeItem',
                        label: 'stocks.substocks.whitelist.delete.title'
                    }, {
                        condition: item => this.distinguishAllowedFor && !item.is_whitelisted,
                        loading: this.loading,
                        action: this.addToWhitelist,
                        icon: '$addItem',
                        label: 'stocks.substocks.whitelist.create.title'
                    }, {
                        loading: this.loading,
                        action: this.printBarcode,
                        icon: '$printItem',
                        label: 'stocks.locations.printBarcode'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                if (this.distinguishAllowedFor !== null && this.distinguishAllowedFor !== undefined) {
                    return StockAPI.getAllSubstockLocations.bind(StockAPI, this.stockId, this.distinguishAllowedFor);
                }
                return StockAPI.getAllLocations.bind(StockAPI, this.stockId);
            },
            apiDataSourceAllPages: function () {
                if (this.distinguishAllowedFor !== null && this.distinguishAllowedFor !== undefined) {
                    return StockAPI.getAllSubstockLocationsAllPages.bind(StockAPI, this.stockId, this.distinguishAllowedFor);
                }
                return StockAPI.getAllLocationsAllPages.bind(StockAPI, this.stockId);
            },
            batchActions: function () {
                let arrayOfBatchActions = [
                    {
                        loading: this.loading,
                        action: this.batchPrintBarcode,
                        icon: '$printItem',
                        label: 'stocks.locations.printBarcode'
                    }, {
                        loading: this.loading,
                        action: this.batchSetAvailability,
                        icon: '$availability',
                        label: 'stocks.locations.setAvailability'
                    },
                ];
                // Used to differ stock and substock batch actions.
                if (this.substock) {
                    arrayOfBatchActions = arrayOfBatchActions.concat([
                        {
                            condition: () => this.distinguishAllowedFor,
                            loading: this.loading,
                            action: selectedIDs => this.batchWhitelist(true, selectedIDs),
                            icon: '$batchAddToWhitelist',
                            label: 'stocks.substocks.whitelist.batch.checked.true'
                        }, {
                            condition: () => this.distinguishAllowedFor,
                            loading: this.loading,
                            action: selectedIDs => this.batchWhitelist(false, selectedIDs),
                            icon: '$batchRemoveFromWhitelist',
                            label: 'stocks.substocks.whitelist.batch.checked.false'
                        },
                    ]);
                }
                return arrayOfBatchActions;
            }
        },
        methods: {
            reloadList: function () {
                this.reload++;
            },
            showStockStatus: function (item) {
                this.$router.push('/status?stock.id=' + this.stockId + (
                    isNaN(this.subStockId) ? '' : ('&substock.id=' + this.subStockId)
                ) + '&stock_location.id=' + item.id);
            },
            whitelistLabel: function (item) {
                return (item.is_allowed && !item.is_whitelisted) ? this.$t('stocks.substocks.whitelist.auto') : this.$options.filters.yesOrNo(item.is_whitelisted);
            },
            addToWhitelist: function (item) {
                this.loading = true;
                StockAPI.createSubstockWhitelistLocation(this.stockId, this.subStockId, item.id)
                    .then(() => {
                        this.clearAllowedLocationIdsCacheBySubStock(this.subStockId);
                        this.advancedSnack({
                            text: 'stocks.substocks.whitelist.create.done',
                            params: [item.name]
                        });
                        this.reload++;
                    }).catch(() => {
                        this.advancedSnack({
                            text: 'stocks.substocks.whitelist.create.failed',
                            params: [item.name]
                        });
                        this.loading = false;
                    });
            },
            removeFromWhitelist: function (item) {
                this.loading = true;
                StockAPI.deleteSubstockWhitelistLocation(this.stockId, this.subStockId, item.id)
                    .then(() => {
                        this.clearAllowedLocationIdsCacheBySubStock(this.subStockId);
                        this.advancedSnack({
                            text: 'stocks.substocks.whitelist.delete.done',
                            params: [item.name]
                        });
                        this.reload++;
                    }).catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
            },
            batchWhitelist: function (newValue, selectedIDs = []) {
                this.loading = true;
                this.batchActionProgress = 0;
                this.batchActionTotal = selectedIDs.length;
                const promises = [];
                let error = false;
                for (const locationId of selectedIDs) {
                    let promise = Promise.resolve();
                    if (newValue) {
                        promise = StockAPI.createSubstockWhitelistLocation(this.stockId, this.subStockId, locationId)
                            .catch(reason => {
                                if (typeof reason !== "string" || !reason.endsWith('409')) {
                                    error = true;
                                }
                            });
                    } else {
                        promise = StockAPI.deleteSubstockWhitelistLocation(this.stockId, this.subStockId, locationId)
                            .catch(reason => {
                                if (typeof reason !== "string" || !reason.endsWith('404')) {
                                    error = true;
                                }
                            });
                    }
                    promises.push(promise);
                    promise.then(() => {
                        this.batchActionProgress++;
                    });
                }
                Promise.allSettled(promises).then(() => {
                    if (error) {
                        this.snack('stocks.substocks.whitelist.batch.failed');
                    } else {
                        this.clearAllowedLocationIdsCacheBySubStock(this.subStockId);
                        this.snack('stocks.substocks.whitelist.batch.done.' + newValue);
                    }
                    this.loading = false;
                    this.reload++;
                    this.batchActionProgress = null;
                    this.batchActionTotal = 0;
                });
            },
            printBarcode: function (item) {
                this.modalItem = item;
                this.barcodeModal = true;
            },
            batchPrintBarcode: function (locationIDs) {
                this.barcodeModal = true;
                this.modalLocationIDS = locationIDs;
            },
            reallyPrintBarcode: function () {
                this.loading = true;
                this.barcodeModal = false;
                const promises = [];
                if (this.modalItem !== null) {
                    const item = this.modalItem;
                    promises.push(StockAPI.printLocationBarcode(this.stockId, item.id, this.printType, this.barcodeCount)
                        .then(response => {
                            const fileExtension = this.printType;
                            Export.print(response.data.url, 'location_' + item.id + '.' + fileExtension, fileExtension);
                        }));
                } else if (this.modalLocationIDS.length > 0) {
                    let index = 1;
                    while ( this.modalLocationIDS.length ) {
                        const filename = `locations-${index.toString()}.${this.printType}`;
                        promises.push(StockAPI.printLocationBarcodeBatch(this.stockId, this.modalLocationIDS.splice(0, 10), this.printType, this.barcodeCount)
                            .then(response => {
                                Export.print(response.data.url, filename, this.printType);
                            }));
                        index++;
                    }
                }
                Promise.all(promises)
                    .then(() => {
                        this.snack('base.print.sent');
                        this.resetPrint();
                    }).catch((err) => {
                        if (this.$te(err)) {
                            this.snack(err);
                        }  else {
                            this.snack('base.print.error');
                        }

                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            resetPrint: function () {
                this.barcodeCount = 1;
                this.modalItem = null;
                this.modalLocationIDS = [];
            },
            deleteItem: function (item) {
                this.deletedLocation = item;
                this.confirmDialog = true;
            },
            reallyDeleteLocation: function () {
                this.loading = true;
                this.confirmDialog = false;
                StockAPI.deleteLocation(this.stockId, this.deletedLocation.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'stocks.locations.delete.done',
                            params: [this.deletedLocation.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        EventBus.$emit('delete-stock-location');
                        this.reload++;
                    });
            },
            showItem: function (item) {
                this.distinguishAllowedFor !== null ?
                    this.$router.push('/stocks/' + this.stockId + '/substocks/' + this.subStockId + '/locations/' + item.id) :
                    this.$router.push('/stocks/' + this.stockId + '/locations/' + item.id);
            },
            batchSetAvailability: function (selectedIds) {
                this.selectedIds = selectedIds;
                this.availabilityModal = true;
            },
            reallySetAvailability: function (newAvailability) {
                this.loading = true;
                this.batchActionProgress = 0;
                this.batchActionTotal = this.selectedIds.length;
                const promises = [];
                let error = false;
                for (const locationId of this.selectedIds) {
                    promises.push(
                        StockAPI.updateLocation(this.stockId, locationId, {availability: newAvailability})
                            .then(() => {
                                this.batchActionProgress++;
                                IndexedDB.clearByKey(CachePath.locations, locationId);
                            }).catch(() => error = true)
                    );
                }
                Promise.allSettled(promises).then(() => {
                    this.selectedIds = null;
                    this.batchActionProgress = null;
                    this.batchActionTotal = 0;
                    this.reload++;
                    if (error) {
                        this.snack('stocks.locations.availabilityBatch.failed');
                    } else {
                        this.snack('stocks.locations.availabilityBatch.done');
                    }
                });
            }
        }
    };
</script>

<style scoped>

</style>
