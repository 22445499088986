<template>
  <div>
    <v-flex v-if="buyer.dic">
      {{ $t('buyers.dic') }}:  {{ buyer.dic }}
    </v-flex>
    <v-flex v-if="buyer.address">
      {{ $t('buyers.address') }}:  {{ lineAddress }}
    </v-flex>
    <v-flex v-if="buyer.email">
      {{ $t('buyers.email') }}:  {{ buyer.email }}
    </v-flex>
    <v-flex v-if="buyer.phone">
      {{ $t('buyers.phone') }}:  {{ buyer.phone }}
    </v-flex>
  </div>
</template>

<script>
    import {addressInline} from "@/utils/string";
    export default {
        name: "BuyerTooltip",
        props:{
            buyer: {
                type: Object,
                default: null
            }
        },
        computed: {
            lineAddress() {
                return addressInline(this.buyer.address);
            }
        }
    };
</script>

<style scoped>

</style>
