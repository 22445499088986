export default {
    locationVsSubstock: {
        title: 'What is the difference between a location and a sub-warehouse?',
        content: 'While a location is a real place in the warehouse, sub-warehouses are a virtual part of the warehouse, in which the items can be owned by another subject. One location can contain products from various sub-warehouses.'
    },
    smallWarehouse: {
        title: 'What is a small warehouse?',
        content: 'A small warehouse only has a single location. The number of sub-warehouses is not limited.'
    },
    productLabels: {
        title: 'What does the "Product labels" model/name switch do?',
        content: 'It specifies whether the name or the model of each product is shown. This can, for example, be seen in the stock movement overview, or when creating tasks.'
    },
    setProductAttributes: {
        title: 'How to correctly set product attributes?',
        content: 'When creating a new product, suggested attributes of the product type are automatically shown. ' +
            'Out of those, only the ones with filled-in values will be saved. ' +
            'When updating a product, its current attributes are shown. When the product type is changed, its suggested attributes are also shown. ' +
            'When a suggested attribute matches an already-existing one, then the newly suggested attribute\'s description overwrites the existing one. ' +
            'In both cases (when creating or updating), the default attributes of the currently chosen product type can be updated as well. ' +
            'Descriptions are optional for all attributes.'
    },
    markAsDeliveredAfterPacking: {
        title: 'When is the shipment of the order marked as delivered?',
        content: 'Usually, the shipment of the "personal collection" type order is marked as delivered only when the handover protocol is created.'
    },
};
