<template>
  <div>
    <template v-if="details">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <span class="text-caption">{{ $t('tasks.supplier') + ': ' }}</span>
                {{ details.supplier | supplierBrief }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.delivery_number') + ': ' }}</span>
                {{ details.delivery_number || '-' }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.invoice_number') + ': ' }}</span>
                {{ details.invoice_number || '-' }}
              </div>
              <div>
                <StockSubstock
                  :substock="details.subordinate_stock"
                  direction="to"
                />
              </div>
              <div>
                <span class="text-caption"> {{ $t('tasks.strictMode.label') + ': ' }}</span>
                {{ $t('tasks.strictMode.' + details.strict_mode) }}
                <v-tooltip right>
                  <template #activator="{ on }">
                    <span v-on="on"><v-icon>$info</v-icon></span>
                  </template>
                  <span>{{ $t('tasks.stockLoading.strictHint.' + details.strict_mode) }}</span>
                </v-tooltip>
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <div v-if="details.delivery_accept_id">
                <span class="text-caption">{{
                  $t('tasks.deliveryAccept.name') + ': '
                }}</span>
                <router-link
                  :to="taskLinks[taskTypes.DELIVERY_ACCEPT] + '/' + details.delivery_accept_id"
                >
                  #{{ details.delivery_accept_id }}
                </router-link>
                <v-divider
                  class="my-2"
                />
              </div>
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="taskTypes.STOCK_LOADING"
            :stock-id="details.subordinate_stock.stock_id"
            :sub-stock-id="details.subordinate_stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
      <TaskItemsCard
        v-if="ready"
        :api="API"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :items="itemsToMove"
        :task-info="taskInfo"
        task-lang-path="stockLoading."
        allow-batch-print
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {TaskDeliveryAcceptAPI} from "@/api/TaskDeliveryAcceptAPI";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {TaskStockLoadingAPI as API} from "@/api/TaskStockLoadingAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import StockSubstock from "@/app/components/StockSubstock.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";

    export default {
        name: "StockLoadingAssignment",
        components: {TaskAttributes, StockSubstock, EntityHistory, Notes, TaskAttachments, TaskItemsCard},
        mixins: [CreatedOrActivatedMixin, TaskFetchItemsMixin, ACLMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            deliveryAcceptDetails: null,
            items: [],
            ready: false,
            TaskItemsCardType: TaskItemsCardType,
            taskTypes: taskTypes,
            taskLinks: taskLinks,
            API: API
        }),
        computed: {
            details: function () {
                return this.taskInfo.details;
            },
            itemsToMove: function () {
                return this.items.filter(item => item.quantity_to_move !== 0);
            }
        },
        watch: {
            'taskInfo.tab': function (tab) {
                if (tab === 0) {
                    this.fetchItemsDebounced();
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (this.details.delivery_accept_id && this.deliveryAcceptDetails === null) {
                this.fetchDeliveryAcceptDetails();
            }
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true}).then(() => this.ready = true).catch(this.snack);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
        methods: {
            fetchDeliveryAcceptDetails: function () {
                TaskDeliveryAcceptAPI.get(this.details.delivery_accept_id)
                    .then(response => {
                        this.deliveryAcceptDetails = response.data;
                    }).catch(this.snack);
            },
        }
    };
</script>

<style scoped>

</style>
