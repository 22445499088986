import {BaseAPI} from "@/api/BaseAPI";

export function emptySource(multiple = false) {
    return {
        apiDataSource: () => BaseAPI.emptyDataSource(),
        resultTransform: el => {
            el.value = el.id;
            el.text = el.name;
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple
    };
}