const Status = {
    CREATED: 'created',
    UPLOADED: 'uploaded',
    QUEUED: 'queued',
    PROCESSING: 'processing',
    FAILED: 'failed',
    VALIDATION_FAILED: 'validation-failed',
    FINISHED: 'finished',
    DRY_RUN_QUEUED: 'dry-run-queued',
    DRY_RUN_PROCESSING: 'dry-run-processing',
    DRY_RUN_FAILED: 'dry-run-failed',
    DRY_RUN_VALIDATION_FAILED: 'dry-run-validation-failed',
    DRY_RUN_FINISHED: 'dry-run-finished'
};

const LoadingStatuses = [Status.QUEUED, Status.PROCESSING, Status.DRY_RUN_QUEUED, Status.DRY_RUN_PROCESSING];

const FailedStatuses = [Status.FAILED, Status.DRY_RUN_FAILED, Status.VALIDATION_FAILED, Status.DRY_RUN_VALIDATION_FAILED];

const StatusColor = {
    [Status.CREATED]: '',
    [Status.UPLOADED]: '',
    [Status.QUEUED]: 'accent',
    [Status.PROCESSING]: 'accent',
    [Status.FAILED]: 'error',
    [Status.VALIDATION_FAILED]: 'error',
    [Status.FINISHED]: 'primary',
    [Status.DRY_RUN_QUEUED]: 'accent',
    [Status.DRY_RUN_PROCESSING]: 'accent',
    [Status.DRY_RUN_FINISHED]: 'info',
    [Status.DRY_RUN_VALIDATION_FAILED]: 'error',
    [Status.DRY_RUN_FAILED]: 'error'

};

export {Status, StatusColor, LoadingStatuses, FailedStatuses};
