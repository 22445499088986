import {ProductAPI} from "@/api/ProductAPI";
import {EventBus} from "@/service/EventBus";

function ProductCustomInstanceForm() {
    return {
        product_instance_type_id: null
    };
}

function ProductCustomInstanceRender() {
    return {
        product_instance_type_id: {
            icon: '$productInstanceType',
            required: true,
            autocomplete: {
                callFn: () => ProductAPI.getAllCustomInstanceTypes(),
                thenFn: response => response.data.map(el => ({
                    text: el.name,
                    value: el.instance_type_id
                }))
            },
            createNew: {
                icon: '$productInstanceType',
                label: 'products.instances.types.create.label',
                action: () => {
                    EventBus.$emit('create-instance-type');
                }
            }
        }
    };
}

export {ProductCustomInstanceForm, ProductCustomInstanceRender};
