import {CacheIndexPath, CachePath} from "@/service/cache/CacheConfiguration";
import {IndexedDB} from "@/service/cache/IndexedDB";

const InstanceCacheMixin = {
    methods: {
        clearInstanceCachesByProduct(productId) {
            IndexedDB.clearByIndex(CachePath.instances, CacheIndexPath.products, productId);
            IndexedDB.clearByKey(CachePath.products, productId);
        },
        clearInstanceCachesByManufacturer(manufacturerId) {
            IndexedDB.clearByIndex(CachePath.instances, CacheIndexPath.manufacturers, manufacturerId);
            IndexedDB.clearByIndex(CachePath.products, CacheIndexPath.manufacturers, manufacturerId);
        },
        clearInstanceCachesById(instanceId) {
            IndexedDB.clearByKey(CachePath.instances, instanceId);
        }
    }
};

export {InstanceCacheMixin};
