import {measurementUnits} from "@/enum/measurement_unit";

const measurementUnit = {
    name: 'Merná jednotka',
    [measurementUnits.PIECE]: "Kus",
    [measurementUnits.CARTON]: "Kartón",
    [measurementUnits.HECTOLITER]: "Hektoliter",
    [measurementUnits.LITER]: "Liter",
    [measurementUnits.DECILITER]: "Deciliter",
    [measurementUnits.CENTILITER]: "Centiliter",
    [measurementUnits.MILLILITER]: "Mililiter",
    [measurementUnits.TONNE]: "Tona",
    [measurementUnits.KILOGRAM]: "Kilogram",
    [measurementUnits.DECAGRAM]: "Dekagram",
    [measurementUnits.GRAM]: "Gram",
    [measurementUnits.MILLIGRAM]: "Miligram",
    [measurementUnits.KILOMETER]: "Kilometer",
    [measurementUnits.METER]: "Meter",
    [measurementUnits.CENTIMETER]: "Centimeter",
    [measurementUnits.MILLIMETER]: "Milimeter",
    [measurementUnits.SQUARE_METER]: "Štvorcový meter",
    [measurementUnits.CUBIC_METER]: "Kubický meter",

    [measurementUnits.MILE]: "Míľa",
    [measurementUnits.INCH]: "Palec",
    [measurementUnits.POUND]: "Libra",
    [measurementUnits.OUNCE]: "Unca",
    [measurementUnits.US_GALLON]: "Americký galón",
    [measurementUnits.UK_GALLON]: "Britský galón",
    [measurementUnits.SQUARE_FOOT]: "Štvorcová stopa",
    [measurementUnits.CUBIC_FOOT]: "Kubická stopa"
};

export default {
    title: "Skladové položky",
    titleSingle: "Skladová položka",
    link: "Skladové položky",

    name: "Názov",
    description: "Popis",
    model: "Model",
    manufacturer_id: "Výrobca",
    type_id: "Typ",
    manufacturer: {
        id: "Výrobca",
        label: "Výrobca",
        name: "Výrobca"
    },
    type: {
        id: "Typ",
        label: "Typ"
    },
    measure_unit: "Merná jednotka",
    measurementUnit: measurementUnit,
    visible: "Viditeľný",
    notVisible: "Skrytý",
    deleted: "Zmazaný",
    can_have_batch: "Može mať šaržu",
    can_have_serial_number: "Môže mať sériové číslo",
    created_at: "Vytvorené",
    suggested_buy_price: "Štandardná nákupná cena (bez DPH)",
    suggested_sell_price: "Doporučená predajná cena (bez DPH)",
    buy_price: "Nákupná cena za jednotku",
    sell_price: "Predajná cena za jednotku",
    price_vat: "Sadzba DPH",
    vat_included: "vr. DPH",
    unknown: "<Neznáme>",
    tableImage: "",
    instance: "Šarža / Séria",
    quantity: "Množstvo",
    searchHint: "Prehľadáva názov, model, výrobca a mernú jednotku",

    attributeAlreadyEntered: "Odporúčaný atribút už existuje!",
    unableToRemoveLastAttribute: "Posledný atribút nemožno odstrániť!",


    attributes: {
        name: "Atribúty",
        key: "Názov",
        value: "Hodnota",
        description: "Popis",
        existing_attributes: "Existujúce atribúty",
        unableToLoad: "Zoznam atribútov skladovej položky nebolo možné načítať. Skúste to prosím opäť neskôr.",
        hint: "Atribúty s prázdnou hodnotou sa neukladajú",
        create: {
            titleSimple: "Pridať atribút",
            title: "Pridať atribút k '{0}'",
            done: "Pridané '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upraviť atribút",
            title: "Upraviť '{0}' pri '{1}'",
            done: "Upravené '{0}' pri '{1}'"
        },
        delete: {
            done: "Atribút '{0}' zmazaný.",
            failed: "Atribút '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr."
        },
        status: {
            404: "Skladová karta alebo názov atribútu nenájdený!",
            409: "Zadaný názov atribútu už existuje!"
        }
    },

    overview: {
        name: "Prehľad položky",
        inStock: "Na sklade",
        notInStock: "Táto položka sa na sklade nenachádza",
        InTasks: "V úlohách",
        notInTasks: "Táto položka nie je blokovaná v žiadnej úlohe",
        Ordered: "Objednané",
        notOrdered: "Pre túto položku neexistujú žiadne objednávky",
        altogetherPieces: "{0} ks",
    },

    types: {
        link: "Typy skladových položiek",
        title: "Typy skladových položiek",
        titleSingle: "Typ skladovej položky",
        name: "Názov",
        default: "<predvolený>",
        suggestedAttributes: {
            name: "Odporúčané atribúty",
            key: "Názov",
            description: "Popis",
            hint: "Je typový"
        },

        removeAttribute: "Odobrať atribút",

        create: {
            label: "Vytvoriť nový typ skladovej položky",
            title: "Nový typ skladovej položky",
            done: "Typ skladovej položky '{0}' vytvorený"
        },
        update: {
            titleSimple: "Upraviť typ skladovej položky",
            title: "Upraviť '{0}'",
            done: "Typ skladovej položky '{0}' upravený"
        },
        delete: {
            done: "Typ skladovej položky '{0}' zmazaný.",
            failed: "Typ skladové položky '{0}' nemožno zmazať. Skúste to znova neskôr."
        },

        status: {
            404: "Typ skladovej položky nebol nájdený!",
            409: "Typ skladovej položky nie je možné vymazať, pretože položka s týmto typom je na sklade!"
        }
    },

    batches: {
        name: "Šarža",
        code: "Kód",
        manufactured_date: "Dátum výroby",
        manufactured_time: "Čas výroby",
        expire_date: "Dátum expirácie",
        expire_time: "Čas expirácie",
        create: {
            titleSimple: "Pridať šaržu",
            title: "Pridať šaržu k '{0}'",
            done: "Pridané '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upraviť šaržu",
            title: "Upraviť šaržu '{0}' pri '{1}'",
            done: "Upravená šarža '{0}' pri '{1}'"
        },
        delete: {
            done: "Šarža '{0}' zmazaná.",
            failed: "Šaržu '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
            status: {
                404: "Skladová karta alebo kód šarže nenájdený!",
                409: "Kód šarže nemôže byť zmazaný kvôli jeho vzťahom k ďalším objektom!"
            }
        },
        status: {
            404: "Skladová karta alebo kód šarže nenájdený!",
            409: "Zadaný kód šarže už existuje!"
        }
    },

    customInstances: {
        name: "Špeciálne inštancie",
        hint: "Ak potrebujete rozlíšiť stav, opotrebenie položky a spravovať jej kódy.",
        product_instance_type_id: "Typ",
        create: {
            titleSimple: "Pridať špeciálnu inštanciu",
            label: "Vytvoriť novú konkrétnu položku",
            title: "Pridať špeciálnu inštanciu k '{0}'",
            done: "Pridaná inštancia typu '{0}' k '{1}'"
        },
        update: {
            titleSimple: "Upraviť špeciálnu inštanciu",
            title: "Upraviť inštanciu typu '{0}' pri '{1}'",
            done: "Upravená inštancia typu '{0}' pri '{1}'",
            status: {
                404: "Skladová karta alebo inštancia nenájdená!",
                409: "Typ inštancie nemôže byť zmenený kvôli vzťahom inštancie k ďalším objektom!"
            }
        },
        delete: {
            titleSimple: "vymazať špeciálnu inštanciu",
            title: "vymazať inštanciu typu '{0}' pri '{1}'",
            done: "zmazaná inštancia typu '{0}' pri '{1}'",
            status: {
                404: "Skladová karta alebo inštancia nenájdená!",
                409: "Typ inštancie nemôže byť zmenený kvôli vzťahom inštancie k ďalším objektom!"
            }
        },
        status: {
            404: "Skladová karta nenájdená!",
            409: "Neznámy alebo neplatný typ inštancie!"
        }
    },

    images: {
        alt: "Obrázok priradený položke",
        altCreate: "Obrázok k priradeniu položke",
        unableToLoad: "Obrázky skladovej položky nebolo možné načítať. Skúste to prosím opäť neskôr.",
        store_external_images: 'Uloženie externých obrázkov',
        status: {
            404: "Skladová karta alebo obrázok nenájdený!"
        }
    },

    instances: {
        name: "Čiarové kódy základnej položky",
        id: "ID",
        type: {
            name: "Typ",
            NORMAL: "Bežný",
            SERIAL_NUMBER: "Sériové číslo",
            BATCH: "Šarža"
        },
        manufactured_date: "Dátum výroby",
        expire_date: "Dátum expirácie",
        serial_number: "Sériové číslo",
        batch_code: "Číslo šarže",
        serial: "Séria: {0}",
        batch: "Šarža: {0}",

        types: {
            label: "Špeciálne typy inštancií",
            name: "Názov",

            create: {
                titleSimple: "Pridať špeciálny typ inštancie",
                title: "Nový špeciálny typ inštancie",
                label: "Vytvoriť nový špeciálny typ inštancie",
                done: "Špeciálny typ inštancie '{0}' pridaný"
            },
            update: {
                title: "Upraviť špeciálny typ inštancie '{0}'",
                titleSimple: "Upraviť špeciálny typ inštancie",
                done: "Špeciálny typ inštancie '{0}' upravený"
            },
            delete: {
                done: "Špeciálny typ inštancie '{0}' zmazaný!",
                status: {
                    404: "Špeciálny typ inštancie nenájdený!",
                    409: "Na sklade existujú inštancie s daným typom!"
                }
            },
            status: {
                404: "Špeciálny typ inštancie nenájdený!",
                409: "Špeciálny typ s daným menom už existuje!"
            }
        },

        barcodes: {
            link: "Detail",
            title: "Čiarové kódy položky '{0}'",
            titleSimple: "Čiarové kódy",
            name: "Čiarový kód",
            code: "Kód",
            image: "Náhľad",
            quantity: "Množstvo",
            description: "Popis kódu",
            descriptionHint: 'Napríklad typ balenia: "kartón", "paleta" apod.',
            conflict: "Tento kód už má položka {0}",

            print: "Vytlačiť čiarový kód",
            printMany: "Vytlačiť čiarové kódy",
            printHowMuch: "Koľko štítkov '{0}' vytlačiť?",
            printMultipleHowMuch: "Koľko štítkov vytlačiť?",
            printDone: "Tlačím štítok '{0}'...",

            batchPrint: {
                label: "Zvoľte parametre tlače",
                printAll: "Vytlačiť toľko štítkov, koľko je kusov položiek",
                printJustOne: "Vytlačiť pre každú položku iba jeden štítok",
                totally: "tj. celkom",
            },

            create: {
                title: "Pridať čiarový kód k '{0}'",
                titleSimple: "Pridať čiarový kód",
                done: "Kód '{0}' pridaný k '{1}'"
            },
            update: {
                title: "Upraviť kód '{0}' pri '{1}",
                titleSimple: "Upraviť kód pri položke",
                done: "Kód '{0}' pri '{1}' upravený"
            },
            delete: {
                done: "Kód '{0}' pri '{1}' zmazaný!"
            },
            status: {
                404: "Skladová karta, konkrétna skladová položka alebo čiarový kód nenájdený!",
                409: "Zadaný čiarový kód už existuje!"
            }
        },

        status: {
            404: "Skladová karta alebo konkrétna skladová položka nenájdená!"
        }
    },

    serial_numbers: {
        name: "Sériové čísla",
        number: "Sériové číslo",
        serial_number: "Sériové číslo",
        same_barcode: "Tiež vytvoriť rovnaký čiarový kód",
        create: {
            titleSimple: "Pridať sériové číslo",
            title: "Pridať sériové číslo k '{0}'",
            done: "'{0}' pridané k '{1}'"
        },
        delete: {
            done: "Sériové číslo odobrané.",
            failed: "Sériové číslo sa nepodarilo odobrať. Skúste to prosím opäť neskôr.",
            status: {
                404: "Skladová karta, konkrétna skladová položka alebo sériové číslo nenájdené!",
                409: "Sériové číslo nie je možné zmazať kvôli jeho vzťahom k ďalším objektom!"
            }
        },
        status: {
            404: "Skladová karta, konkrétna skladová položka alebo sériové číslo nenájdené!",
            409: "Zadané sériové číslo už existuje!"
        }
    },

    pieces: {
        name: "Kusy na sklade",
        status: "Stav na sklade",
        movements: "Pohyby položky"
    },

    show: {
        titleSimple: "Detail skladovej položky",
        title: "{0}"
    },
    create: {
        title: "Nová skladová položka",
        done: "Skladová položka '{0}' vytvorená."
    },
    update: {
        titleSimple: "Upraviť skladovú položku",
        title: "Upraviť {0}",
        done: "Skladová položka '{0}' upravená."
    },
    delete: {
        done: "Skladová položka '{0}' zmazaná.",
        failed: "Skladovú položku '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr."
    },

    status: {
        404: "Skladová karta nenájdená!",
        409: "Položku nie je možné odstrániť, pretože je ešte na sklade!",
        412: "Skladová položka bola od posledného načítania upravená iným užívateľom. Skúste prosím akciu vykonať znova neskôr."
    }
};
