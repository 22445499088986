const importNotFound = "The requested import was not found";
export default {
    title: "Import",
    imports: "Imports",
    link: "Imports",
    create: "Create Import",
    deleted: "Import Deleted",
    file: "File",
    file_info_create: "File used to create a template for future imports",
    file_info_use: "Enter the file from which data will be imported. Enter a file with the corresponding data format according to the chosen template.",
    domain: "Target Data Area",
    domain_info: "Data area into which data will be imported using this template. Choose the data area where you want to import data using this template in the future",
    choose_file: "Choose File",
    process: "Process Import",
    process_started: "Import was sent to Process",
    processed: "Import Processed",
    download_imported_file: "Download given file",
    download_error: 'Failed to download user file',
    array_buffer_error: 'Failed to convert user file',
    viewImport: "View Import",
    configuration: "Import Configuration",

    stepper: {
        first_step: "Create Template",
        second_step: "Prepare Import",
        second_step_back: "Delete and preapre new import",
        third_step: "Check & Import",
    },

    hints: {
        parameters_hint: "The import parameters allow you to specify additional information needed for the imported data.",
        header_rows: "Set the number of header rows in your file",
        first_row: "If the file contains data outside of the import, set the first row for import",
        first_column: "If the file contains data outside of the import, set the first column for import",
        required_attributes: "All required attributes must be mapped in the table below. If it's not possible to map all the required attributes from your file and the selected data area may not be compatible",
        mapping: "Map your file to the selected data area by clicking on its header and selecting the target attribute. When mapping, also select columns to use as comparators during import",
        primary_key: "This Column is set as Comparison attribute",
        easy_mode: "In this mode, you can only add new items in selected target data area",
        latest_on_duplicity: "If multiple matching records are found when identifying a record, the last one created is used",
        fileTemplate: "Download a template import file for target data area"
    },

    rules: {
        file_type: "Unsupported file type",
        multiple_sheets: "The selected file has more than one sheet",
        cannot_access: "Unable to open selected file"
    },

    config: {
        title: "Import Template",
        templates: "Import Templates",
        create: "Create Import Template",
        edit: "Duplicate and Edit Template",
        view: "View Template",
        created: "Template has been created",
        save: "Save Template",
        save_new: "Save New Template",
        deleted: "Import Template Deleted",
        easyMode: "Save mode",
        latestOnDuplicity: 'Ignore duplicities',

        name: "Template Name",
        domain: "Target Data Area of the Template",
        file: "Soubor",
        defaultParameters: "Default template parameters",
        parameters: "Import Parameters",
        required_attributes: "Required Attributes",
        mapping: "Column Mapping",

        firstRow: "First Row",
        firstColumn: "First Column",
        headerRows: "Header Rows",

        unmap: "unselect",
        not_mapped: "not mapped",
        primary_set: "Set as a comparison attribute",
        primary_unset: "Unset comparison attribute",
        empty_error: "Configuration does not have an import from which reference data would be taken",
    },
    info: {
        title: "Import Information",
        hint: "Information about the created import is being calculated, this action may take several minutes.",
        include: "Import will include: ",
        error: "Processing this import will result in the following error: ",
        created: 'Will create',
        updated: 'Will update',
        warn: "Warning",

        primary_key_toggle_warn: "The main group must have at least one comparison attribute",
        primary_key_group_warn: "Groups {0} are missing at least one comparison attribute",
        fake_file_warn: "The template displays a sample file, the real file was not provided or does not meet the conditions",
        mapping_warn: "If you have unmapped required attributes, it means your template and the selected file are not compatible.",
        mapping_warn_create: "You haven't mapped all the Required Attributes required by the selected Target Data Area.",
        parameters_warn: "All required parameters must be filled",
        change_value_warn: "Changing this value will result in the loss of current mapping",
        edit_values_warn: "Processing this import will change existing data. Do you want to continue?",
        short_file_warn: "The number of columns in uploaded file it too small for current template.",
        delete_import: "Do you wish to keep failed import?",
    },

    errors: {
        'import': {
            status: {
                404: importNotFound,
            }
        },
        importCreate: {
            status: {
                409: 'Failed to create the import'
            }
        },
        importDelete: {
            status: {
                404: importNotFound,
                409: "Failed to delete the import"
            }
        },
        importUpdate: {
            status: {
                404: importNotFound,
                409: 'Failed to update the import'
            }
        },
        importProcess: {
            status: {
                404: importNotFound,
                409: "Failed to submit the import for processing."
            }
        },
        domainTemplate: {
            status: {
                404: "Failed to find data for the selected data source",
            }
        },
    }
};
