<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">
        {{ user | fullName | initials }}
      </span>
    </template>
    {{ user | fullName }}
  </v-tooltip>
</template>

<script>
    export default {
        name: "UserInitialsWithTooltip",
        props: {
            user: {
                type: [Object, String],
                default: null
            }
        }
    };
</script>

<style scoped>

</style>
