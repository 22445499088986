import {Store} from "@/service/store/Store";

const audioCtx = new(window.AudioContext || window.webkitAudioContext)();

const readerFeedback = {

    ERROR_LENGTH: 800,
    WARNING_LENGTH: 500,
    SUCCESS_LENGTH: 200,

    success: function () {
        this.vibrate(this.SUCCESS_LENGTH);
        this.beep(2000);
    },
    warning: function () {
        this.vibrate(this.WARNING_LENGTH);
        this.beep(400)
            .then(() => this.beep(0))
            .then(() => this.beep(400));
    },
    error: function () {
        this.vibrate(this.ERROR_LENGTH);
        this.beep(120, 300, 1)
            .then(() => this.beep(0))
            .then(() => this.beep(120, 300, 1));
    },

    vibrate: function (length) {
        if (Store.getters['userConfig/vibrate']) {
            // Works in browser
            try {
                //eslint-disable-next-line
                window.navigator.vibrate(length);
            } catch (error) {
                // ignore
            }

            // Works in APK
            try {
                //eslint-disable-next-line
                window.Android.vibrate(length);
            } catch (error) {
                // ignore
            }
        }
    },

    beep: function (frequency = 2000, length = 200, volume = 0.2) {
        return new Promise((resolve) => {
            if (Store.getters['userConfig/beep']) {
                const oscillator = audioCtx.createOscillator();
                const gainNode = audioCtx.createGain();

                oscillator.connect(gainNode);
                gainNode.connect(audioCtx.destination);

                gainNode.gain.value = volume;
                oscillator.frequency.value = frequency;
                oscillator.type = 'sawtooth';

                oscillator.start();
                setTimeout(() => {
                    oscillator.stop();
                    resolve();
                }, length);
            } else {
                resolve();
            }
        });
    }


};

export {readerFeedback};
