import {Roles} from "@/enum/roles";

const ACLMixin = {
    computed: {
        isStorekeeper: function () {
            return this.$store.getters['oauth/hasScope'](Roles.STOREKEEPER);
        },
        isPacker: function () {
            return this.$store.getters['oauth/hasScope'](Roles.PACKER);
        },
        isChief: function () {
            return this.$store.getters['oauth/hasScope'](Roles.CHIEF);
        },
        isOrganizer: function () {
            return this.$store.getters['oauth/hasScope'](Roles.ORGANIZER);
        },
        isEshop: function () {
            return this.$store.getters['oauth/hasScope'](Roles.ESHOP);
        },
        isMaster: function () {
            return this.isStorekeeper && this.isPacker && this.isChief;
        }
    }
};

export {ACLMixin};
