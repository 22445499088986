import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form"},[_c('form-fields',{attrs:{"form":_vm.form,"render":_vm.formRender,"single-per-row":false,"lang-path":"exports.configuration."},scopedSlots:_vm._u([{key:"domain-item",fn:function({item}){return [_c(VIcon,{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.domainsIcons[item])+" ")]),_vm._v(" "+_vm._s(_vm.$t('exports.domains.' + item))+" ")]}}])})],1),(_vm.form.domain!=='')?_c('div',[_c(VDivider),_c('ExportConfig',{attrs:{"config":_vm.config,"domain":_vm.form.domain,"new-config-name":_vm.form.name,"show-optional-parameters":!_vm.isEdit,"save-trigger":_vm.saveTrigger},on:{"validConfig":valid => _vm.validConfig = valid}})],1):_vm._e(),(_vm.form.domain)?_c(VBtn,{staticClass:"ml-2 mb-2",attrs:{"disabled":!_vm.form.name || !_vm.validConfig,"loading":_vm.loading,"color":"accent"},on:{"click":function($event){_vm.isEdit ? _vm.saveTrigger = -1 : _vm.saveTrigger++}}},[_vm._v(" "+_vm._s(_vm.$t('exports.configuration.save'))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }