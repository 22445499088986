/**
 * Requires:
 * - this[.taskInfo].details.type
 */
const TaskTypeMixin = {
    computed: {
        taskType: function () {
            return this.details?.type || this.taskInfo?.details?.type;
        }
    },
    methods: {
        /**
         * @param taskType {string}
         * @return {Boolean}
         */
        isType: function (taskType) {
            return this.taskType === taskType;
        },
        /**
         * @param taskTypes {string[]}
         * @return {Boolean}
         */
        isAnyOfTypes: function (taskTypes) {
            return taskTypes.includes(this.taskType);
        }
    }
};

export {TaskTypeMixin};
