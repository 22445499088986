<template>
  <FormFields
    :form="form"
    :render="formRender"
    lang-path="base.address."
  />
</template>

<script>
    import {AddressForm, AddressRender} from "@/app/components/form/definitions/address.form";
    import FormFields from "@/app/components/form/FormFields.component";

    export default {
        name: "FormAddress",
        components: {FormFields},
        props: {
            form: {
                type: Object,
                default: () => (new AddressForm)
            }
        },
        data: () => ({
            formRender: new AddressRender,
        })
    };
</script>

<style scoped>

</style>
