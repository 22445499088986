import CacheManager from "@/app/cache/CacheManager.view";

const BASE_URL = '/cache';

const routes = {
    base: {
        path: BASE_URL,
        component: CacheManager,
        meta: {
            title: 'base.cache.title',
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
