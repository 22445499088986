<template>
  <v-btn
    color="warning"
    outlined
    @click="$emit('remove', item)"
  >
    <v-icon
      class="mr-2"
    >
      $deleteItemForever
    </v-icon>
    {{ $t('base.delete') }}
  </v-btn>
</template>

<script>
    export default {
        name: "TaskItemDetailRemove",
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>

<style scoped>

</style>
