<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.externalOrder."
          >
            <template #processing_mode-item="{ item }">
              <ExternalOrderProcessingModeItem
                :item="item"
              />
            </template>
            <template #buyer_id-append-each-item="{item}">
              <v-flex class="d-flex justify-space-between">
                {{ item.text }}
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-if="item.dic || item.address || item.email || item.phone"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>
                  <span>
                    <BuyerTooltip
                      :buyer="item"
                    />
                  </span>
                </v-tooltip>
              </v-flex>
            </template>
          </FormFields>
          <v-flex
            v-if="form.buyer_contact"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <div class="text-subtitle-1 ml-2">
              {{ $t('tasks.externalOrder.buyer_contact') }}:
            </div>
            <FormFields
              ref="buyer-contact-fields"
              :form="formBuyerContact"
              :render="formBuyerContactRender"
              lang-path="buyers."
            />
          </v-flex>
          <v-flex
            v-if="carrierServiceParameters !== null && carrierServiceParameters.length !== 0"
            xs12
          >
            <CarrierServiceParametersValues
              :carrier-service-parameters="carrierServiceParameters"
            />
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :valid.sync="validItems"
              title-path="tasks.externalOrder.items.title"
              :readonly="loading"
              allow-instance-type-select
              :item-fields="['quantity', 'sell_price_per_unit', 'price_vat']"
            />
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskExternalOrder
              </v-icon>
              {{ $t('tasks.externalOrder.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <BuyerCreateUpdate
      v-if="showCreateBuyer"
      id="buyerForm"
      ref="buyerForm"
      :is-edit="false"
      @buyer-updated="buyerUpdated"
    />
    <BuyerAddressCreateUpdate
      v-if="showCreateBuyerAddress"
      id="buyerAddressForm"
      ref="buyerAddressForm"
      :is-edit="false"
      :buyer-id="form.buyer_id"
      @buyer-address-updated="buyerAddressUpdated"
    />
    <CarrierServiceCreateUpdate
      v-if="showCreateCarrierService"
      id="carrierServiceForm"
      ref="carrierServiceForm"
      :is-edit="false"
      :carrier-id="form.carrier_id"
      @carrier-service-updated="carrierServiceUpdated"
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        ExternalOrderBuyerContactForm,
        ExternalOrderBuyerContactRender,
        ExternalOrderCreateForm,
        ExternalOrderCreateRender
    } from "@/app/tasks/externalOrder/definitions/externalOrderCreate.form";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {addressInline} from "@/utils/string";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import ExternalOrderProcessingModeItem
        from "@/app/tasks/externalOrder/components/ExternalOrderProcessingModeItem.component";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {getIdFromLocation} from "@/utils/url";
    import CarrierServiceParametersValues
        from "@/app/tasks/externalOrder/components/CarrierServiceParametersValues.component";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {scrollTo} from "@/service/Vuetify";
    import BuyerCreateUpdate from "@/app/buyers/components/BuyerCreateUpdate.component";
    import BuyerAddressCreateUpdate from "@/app/buyers/components/BuyerAddressCreateUpdate.component";
    import CarrierServiceCreateUpdate from "@/app/carriers/components/CarrierServiceCreateUpdate.component";
    import BuyerTooltip from "@/app/components/BuyerTooltip.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ExternalOrderCreate",
        components: {
            TaskAttributes,
            BuyerTooltip,
            CarrierServiceParametersValues,
            TaskAttachmentUpload,
            FormFields,
            ExternalOrderProcessingModeItem,
            TaskChooseItems,
            BuyerCreateUpdate,
            BuyerAddressCreateUpdate,
            CarrierServiceCreateUpdate
        },
        mixins: [FormAutocompleteMixin, EventsListenerMixin],
        data: () => ({
            form: ExternalOrderCreateForm(),
            formRender: ExternalOrderCreateRender(),
            formBuyerContact: ExternalOrderBuyerContactForm(),
            formBuyerContactRender: ExternalOrderBuyerContactRender(),
            valid: true,
            validAttachments: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            carrierServiceParameters: null,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            showCreateBuyer: false,
            showCreateBuyerAddress: false,
            showCreateCarrierService: false,
            type: taskTypes.EXTERNAL_ORDER,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-buyer': this.onCreateBuyer,
                    'create-buyer-cancelled': () => this.showCreateBuyer = false,
                    'create-buyer-address': this.onCreateBuyerAddress,
                    'create-buyer-address-cancelled': () => this.showCreateBuyerAddress = false,
                    'create-carrier-service': this.onCreateCarrierService,
                    'create-carrier-service-cancelled': () => this.showCreateCarrierService = false,
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_id': function () {
                this.fetchSubStocks(this.form.stock_id, ['subordinate_stock_id']);
            },
            'form.subordinate_stock_id': function () {
                this.fetchContractedCarriers(this.form.subordinate_stock_id);
            },
            'form.buyer_id': function () {
                this.fetchBuyerDeliveryAddresses(this.form.buyer_id);
            },
            'form.carrier_id': function () {
                this.fetchCarrierServices(this.form.carrier_id);
            },
            'form.carrier_service_id': function (newValue) {
                this.carrierServiceParameters = null;
                if (newValue !== undefined && newValue !== null) {
                    const carrierServices = this.formRender.carrier_service_id.autocomplete.items;
                    const chosenService = carrierServices.find(service => service.value === newValue);
                    this.carrierServiceParameters = chosenService?.serviceParameters;
                }
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            onCreateBuyer: function () {
                this.showCreateBuyer = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.buyer_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('buyerForm');
                });
            },
            onCreateBuyerAddress: function () {
                if (this.form.buyer_id !== undefined && this.form.buyer_id !== null) {
                    this.showCreateBuyerAddress = true;
                    if (this.$refs.fields !== undefined) {
                        this.$refs.fields.$refs.buyer_delivery_address_id[0].blur();
                    }
                    this.$nextTick(() => {
                        scrollTo('buyerAddressForm');
                    });
                }
            },
            onCreateCarrierService: function () {
                if (this.form.carrier_id !== undefined && this.form.carrier_id !== null) {
                    this.showCreateCarrierService = true;
                    if (this.$refs.fields !== undefined) {
                        this.$refs.fields.$refs.carrier_service_id[0].blur();
                    }
                    this.$nextTick(() => {
                        scrollTo('carrierServiceForm');
                    });
                }
            },
            buyerUpdated: function (buyerId) {
                this.showCreateBuyer = false;
                this.form.buyer_id = Number.parseInt(buyerId, 10);
            },
            buyerAddressUpdated: function (buyerAddressId) {
                this.showCreateBuyerAddress = false;
                this.setFieldItemsLoading('buyer_delivery_address_id', true);
                this.fetchBuyerDeliveryAddresses(this.form.buyer_id, false)
                    .then(() => {
                        this.form.buyer_delivery_address_id = Number.parseInt(buyerAddressId, 10);
                    }).finally(() => this.setFieldItemsLoading('buyer_delivery_address_id'), false);
            },
            carrierServiceUpdated: function (carrierServiceId) {
                this.showCreateCarrierService = false;
                this.setFieldItemsLoading('carrier_service_id', true);
                this.fetchCarrierServices(this.form.carrier_id)
                    .then(() => {
                        this.form.carrier_service_id = Number.parseInt(carrierServiceId, 10);
                    }).finally(() => this.setFieldItemsLoading('carrier_service_id', false));
            },
            fetchBuyerDeliveryAddresses: function (buyerId, setDefaultAddress = true) {
                if (buyerId) {
                    this.setFieldItemsLoading('buyer_delivery_address_id', true);
                    return BuyerAPI.getAllAddresses(buyerId)
                        .then(response => {
                            this.formRender.buyer_delivery_address_id.autocomplete.items = response.data.map(address => ({
                                text: addressInline(address.address) + (address.default ? ' (' + this.$t('buyers.addresses.default') + ')' : ''),
                                value: address.address.address_id
                            }));
                            const defaultAddress = response.data.find(addr => addr.default);
                            if (setDefaultAddress) {
                                this.form.buyer_delivery_address_id = defaultAddress.address.address_id;
                            }
                        }).catch(this.snack)
                        .finally(() => {
                            this.setFieldItemsLoading('buyer_delivery_address_id', false);
                        });
                } else {
                    this.form.buyer_delivery_address_id = null;
                    this.formRender.buyer_delivery_address_id.autocomplete.items = [];
                }
            },
            fetchContractedCarriers: function (subStockId) {
                if (subStockId) {
                    this.setFieldItemsLoading('carrier_id', true);
                    ShipmentCarrierAPI.getAllContractedBySubStock(subStockId)
                        .then(response => {
                            this.formRender.carrier_id.autocomplete.items = response.data.map(el => el.carrier).filter(carrier => carrier.active)
                                .map(carrier => ({
                                    text: carrier.name,
                                    value: carrier.id
                                }));
                        }).catch(this.snack)
                        .finally(() => this.setFieldItemsLoading('carrier_id', false));
                } else {
                    this.form.carrier_id = null;
                    this.formRender.carrier_id.autocomplete.items = [];
                }
            },
            fetchCarrierServices: function (carrierId) {
                if (carrierId) {
                    this.setFieldItemsLoading('carrier_service_id', true);
                    return ShipmentCarrierAPI.getServices(carrierId)
                        .then(response => {
                            this.formRender.carrier_service_id.autocomplete.items = response.data.map(param => ({
                                text: param.name,
                                value: param.id,
                                serviceParameters: param.carrier_service_parameters.sort((lhs, rhs) => {
                                    if (lhs.required && !rhs.required) {
                                        return -1;
                                    } else if (!lhs.required && rhs.required) {
                                        return 1;
                                    }
                                    return lhs.id - rhs.id;
                                })
                            }));
                        }).catch(this.snack)
                        .finally(() => {
                            this.setFieldItemsLoading('carrier_service_id', false);
                        });
                } else {
                    this.form.carrier_service_id = null;
                    this.formRender.carrier_service_id.autocomplete.items = [];
                }
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {...this.form};
                if (this.form.buyer_contact) {
                    data.buyer_contact = this.formBuyerContact;
                } else {
                    delete data.buyer_contact;
                }
                data.carrier_service_parameters =
                    this.carrierServiceParameters.filter(param => param.value !== undefined && param.value !== null && param.value !== "");
                data.items = this.chosenItems.filter(item => {
                    delete item.errors;
                    return (item.product_id !== null && item.product_id !== undefined);
                });
                TaskExternalOrderAPI.create(data)
                    .then(response => {
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.externalOrder.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        if (err.response.status === 409) {
                            setFormErrors.call(this, err.response.data.message);
                        } else {
                            setFormErrors.call(this, err);
                        }
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
