import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c(VBtnToggle,{staticClass:"d-flex flex-wrap",attrs:{"value":[]}},_vm._l((_vm.tools),function(tool){return _c(VTooltip,{key:tool.label,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"outlined":""},on:{"click":tool.action}},on),[_vm._v(" "+_vm._s(tool.label)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(tool.hint)+" ")])}),1),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-3 my-1",attrs:{"color":"secondary","outlined":"","target":"_blank","href":"https://manual.sklady.jagu.cz/user-documentation/storekeeper/stock-taking/#tabulka-znacek"}},on),[_c(VIcon,{staticClass:"mr-3"},[_vm._v(" $hint ")]),_c(VIcon,[_vm._v(" $openItem ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('base.help.title'))+" ")])],1),_c(VTextField,{ref:"searchInput",attrs:{"outlined":"","hint":_vm.$t('tasks.stockTaking.entityHelper.hint')},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('div',[_vm._l((_vm.selectedItems.slice(0, 20)),function(item){return _c(VChip,{key:item.value,staticClass:"ma-1",attrs:{"outlined":"","label":"","value":false}},[_vm._v(" "+_vm._s(item.text)+" ")])}),(_vm.selectedItems.length > 20)?_c(VChip,{staticClass:"ma-1",attrs:{"color":"secondary","outlined":"","label":""}},[_vm._v(" +"+_vm._s(_vm.selectedItems.length - 20)+" ")]):_vm._e()],2),_c('div',[_c(VBtn,{staticClass:"my-2",attrs:{"color":"secondary","disabled":_vm.selectedItems.length === 0},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submitLabel)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }