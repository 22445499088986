<template>
  <div>
    <x-toolbar v-if="!isComponent" />
    <v-container fluid>
      <StockFilter
        :form.sync="form"
        :form-render="formRender"
      />
      <StockMovementsTable
        @stock-chosen="val => form.stockId = val"
        @substock-chosen="val => form.subStockId = val"
      />
    </v-container>
  </div>
</template>
<script>
    import StockMovementsTable from "@/app/overview/movements/components/StockMovementsTable.component";
    import {StockFilterForm} from "@/app/overview/stock/definitions/stockFilter.form";
    import StockFilter from "@/app/overview/stock/components/StockFilter.component.vue";
    import {StocksXAutocompleteRender} from "@/app/overview/definitions/overview.render.form";

    export default {
        name: "StockMovements",
        components: {StockFilter, StockMovementsTable},
        props: {
            isComponent: {
                type: Boolean,
                default: false,
            }
        },
        data: () => ({
            form: new StockFilterForm(),
            formRender: new StocksXAutocompleteRender(false, false, true),
        }),
    };
</script>

<style scoped>
</style>
