import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VCard,[_c('CarriersListComponent')],1),_c(VExpansionPanels,{staticClass:"mt-4"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('carriers.run.job'))+" ")]),_c(VExpansionPanelContent,_vm._l((_vm.options),function(option){return _c(VBtn,{key:option.label,staticClass:"mr-2",attrs:{"color":"accent","disabled":option.disabledBy},on:{"click":option.action}},[_vm._v(" "+_vm._s(_vm.$t('carriers.run.' + option.label))+" ")])}),1)],1)],1),_c('x-btn-fab',{attrs:{"to":_vm.carrierCreateLink},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VIcon,[_vm._v("$addItem")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }