<template>
  <div
    v-show="$vuetify.breakpoint.smAndUp"
    class="float-left"
  >
    <v-divider v-if="showDivider" />
    <v-btn
      :to="to"
      outlined
      color="accent darken-2"
      class="ml-3 my-2"
    >
      <v-icon class="mr-2">
        {{ icon }}
      </v-icon>
      {{ $t(label) }}
    </v-btn>
  </div>
</template>

<script>
    export default {
        name: "TableAddItemButton",
        props: {
            to: {
                type: String,
                default: '/'
            },
            label: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: '$addItem'
            },
            showDivider: {
                type: Boolean,
                default: true
            }
        }
    };
</script>

<style scoped>

</style>
