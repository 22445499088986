<template>
  <v-card>
    <v-card-title class="text-h5">
      {{ $t('tasks.stockLoading.buyPrices.title') }}
    </v-card-title>
    <v-card-text class="mt-n10">
      <x-data-table
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        :batch-actions="batchActions"
        :batch-action-progress="batchMultiplyProgress"
        :batch-action-total="batchMultiplyTotal"
      >
        <template #item.price="{ item }">
          <v-edit-dialog
            :key="'price' + item.id"
            @save="savePriceOrVat(item)"
            @open="editPrice = parseFloat(item.price).toFixed(3)"
          >
            {{ parseFloat(item.price).toFixed(3) }}
            <v-progress-circular
              v-if="item.loadingPrice"
              indeterminate
              size="16"
              class="ml-1"
            />
            <v-icon
              v-else
              small
            >
              $updateItem
            </v-icon>
            <template #input>
              <v-text-field
                v-model="editPrice"
                type="number"
                step="0.001"
                min="0"
                :rules="[formRules.nonNegativeNumber]"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template #item.vat="{ item }">
          <v-edit-dialog
            :key="'vat' + item.id"
            @save="savePriceOrVat(item)"
            @open="editVat = item.vat"
          >
            {{ (item.vat * 100) + '%' }}
            <v-progress-circular
              v-if="item.loadingPrice"
              indeterminate
              size="16"
              class="ml-1"
            />
            <v-icon
              v-else
              small
            >
              $updateItem
            </v-icon>
            <template #input>
              <v-select
                v-model="editVat"
                :items="vatRateItems"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template #footer>
          <div class="d-flex align-center ml-4 flex-wrap">
            <v-text-field
              v-model="multiplyBy"
              class="mr-3"
              outlined
              dense
              hide-details
              :disabled="batchMultiplyProgress !== null"
              :label="$t('tasks.stockLoading.buyPrices.batchMultiply.by')"
              type="number"
            />
          </div>
        </template>
      </x-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {TaskStockLoadingAPI as API} from "@/api/TaskStockLoadingAPI";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {stockLoadingItemPricesTable} from "@/app/tasks/stockLoading/definitions/stockLoadingItemPrices.definition";
    import {createHeaders} from "@/utils/table";
    import {instanceLabel} from "@/utils/filters";
    import {vatRateItems} from "@/app/products/definitions/product.form";
    import formRules from "@/utils/formRules";

    export default {
        name: "StockLoadingItemsPrices",
        mixins: [TaskFetchItemsMixin, CreatedOrActivatedMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            API: API,
            headers: [],
            items: [],
            tableItems: [],
            loading: false,
            vatRateItems: vatRateItems,
            multiplyBy: 1,
            batchMultiplyTotal: 0,
            batchMultiplyProgress: null,
            editPrice: null,
            editVat: null,
            formRules: formRules,
        }),
        computed: {
            batchActions: function () {
                return [{
                    loading: this.loading,
                    action: this.batchMultiplyPrices,
                    icon: '$multiplyPrices',
                    label: 'tasks.stockLoading.buyPrices.batchMultiply.action'
                }];
            }
        },
        watch: {
            'taskInfo.tab': function () {
                this.getData();
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.headers = createHeaders(stockLoadingItemPricesTable, 'tasks.stockLoading.buyPrices.', false);
            this.getData(lifeCycleHook === this.LifeCycleHook.CREATED);
        },
        methods: {
            getData: function (initial = false) {
                this.loading = true;
                this.fetchItems({initial: initial})
                    .then(() => {
                        this.fetchBuyPrices().then(() => this.prepareTableItems())
                            .finally(() => this.loading = false);
                    })
                    .catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
            },
            prepareTableItems: function () {
                this.tableItems = this.items.map(item => ({
                    id: item.id,
                    instance: instanceLabel(item.instance),
                    manufacturer: item.instance.product.manufacturer,
                    measure_unit: item.instance.product.measure_unit,
                    priceId: item.price.priceId,
                    price: item.price.price,
                    vat: item.price.vat
                }));
            },
            fetchBuyPrices: function () {
                return this.API.getAllBuyPrices(this.taskInfo.taskId)
                    .then(response => {
                        const instancePrices = {};
                        response.data.forEach(price => {
                            instancePrices[price.product_instance_id] = {
                                priceId: price.id,
                                price: price.buy_price,
                                vat: Number.parseFloat(price.vat)
                            };
                        });
                        this.items.map(item => {
                            item.price = instancePrices[item.product_instance_id];
                            return item;
                        });
                    })
                    .catch(this.snack);
            },
            savePriceOrVat: function (item) {
                let change = false;
                if (this.editPrice !== null && this.editPrice !== "" && this.editPrice >= 0) {
                    change = true;
                    item.price = this.editPrice;
                }
                if (this.editVat !== null && this.editVat !== "") {
                    change = true;
                    item.vat = this.editVat;
                }
                if (this.editPrice < 0) {
                    this.snack('tasks.stockLoading.buyPrices.negativeNotAllowed');
                }
                if (!change) {
                    return;
                }
                this.$set(item, 'loadingPrice', true);
                this.API.updateBuyPrice(this.taskInfo.taskId, item.priceId, item.price, item.vat)
                    .then(() => {
                        this.editPrice = null;
                        this.editVat = null;
                    })
                    .catch(this.snack)
                    .finally(() => this.$set(item, 'loadingPrice', false));
            },
            batchMultiplyPrices: function (selectedIDs) {
                this.loading = true;
                this.batchMultiplyProgress = 0;
                const itemsToUpdate = this.tableItems.filter(item => selectedIDs.includes(item.id));
                this.batchMultiplyTotal = itemsToUpdate.length;
                const promises = [];
                if (this.multiplyBy === '' || this.multiplyBy === null || this.multiplyBy === undefined) {
                    this.multiplyBy = 1;
                }
                let error = false;
                if (this.multiplyBy !== 1) {
                    itemsToUpdate.forEach(item => {
                        promises.push(
                            this.API.updateBuyPrice(this.taskInfo.taskId, item.priceId, item.price * this.multiplyBy, item.vat)
                                .then(() => {
                                    this.$set(item, 'price', (item.price * this.multiplyBy).toFixed(3));
                                    this.batchMultiplyProgress++;
                                }).catch(() => error = true)
                        );
                    });
                }
                Promise.allSettled(promises).then(() => {
                    if (error) {
                        this.snack('tasks.stockLoading.buyPrices.batchMultiply.failed');
                        this.fetchBuyPrices().then(() => this.prepareTableItems());
                    } else {
                        this.snack('tasks.stockLoading.buyPrices.batchMultiply.done');
                    }
                    this.loading = false;
                    this.batchMultiplyProgress = null;
                    this.batchMultiplyTotal = 0;
                });
            }
        }
    };
</script>
