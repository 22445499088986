<template>
  <v-layout
    column
    align-center
  >
    <v-flex class="text-caption">
      {{ $t('tasks.locationsCard.canBePutOn') }}
    </v-flex>
    <v-flex class="mx-2">
      <v-layout
        align-center
        wrap
        justify-center
      >
        <WrappedChipList
          v-slot="{item: instance}"
          :items="allowedInstances"
        >
          {{ instance | instanceLabel }}
        </WrappedChipList>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
    import WrappedChipList from "@/app/components/WrappedChipList.component";

    export default {
        name: "TaskLocationDetailItems",
        components: {WrappedChipList},
        props: {
            allowedInstances: {
                type: Array,
                default: () => []
            }
        }
    };
</script>

<style scoped>

</style>
