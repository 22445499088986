<template>
  <span>
    <span class="font-weight-bold body-1">{{ location.code }}</span>
    <span
      v-if="location.code !== location.name"
      class="body-2"
    >: {{ location.name }}</span>
  </span>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";

    export default {
        name: "TaskItemsLocationHeader",
        mixins: [ReactiveLocationCacheMixin],
        props: {
            locationId: {
                type: Number,
                default: 0
            },
        },
        computed: {
            location: function () {
                return this.LocationCache[this.locationId] || {
                    name: '',
                    code: `<${this.$t('base.loading')}...>`
                };
            },
        },
        watch: {
            locationId: {
                immediate: true,
                handler: function (locationId) {
                    if (locationId) {
                        this.cacheLocation(locationId);
                    }
                }
            }
        },
    };
</script>

<style scoped>

</style>
