function getRandomInteger() {
    return Math.floor(Math.random() * 100) + 1;
}

function getRandomFloat() {
    return ((Math.random() * (9.9 - 0.1 + 1)) + 0.1).toFixed(2);
}

function getRandomText() {
    const things = ['Fish', 'Shark', 'Octopus', 'Turtle', 'Lobster', 'StarFish', 'SeaHorse', 'Whale'];
    return things[Math.floor(Math.random() * things.length)];
}

function getRandomBoolean() {
    const pivot = getRandomInteger();
    return pivot <= 50;
}

function getRandomDate() {
    const date = new Date;
    return date.toISOString();
}

export {getRandomBoolean, getRandomDate, getRandomFloat, getRandomInteger, getRandomText};
