export default {
    locationVsSubstock: {
        title: 'Aký je rozdiel medzi umiestnením a podskladom?',
        content: 'Umiestnenie je reálna pozícia v sklade, zatiaľ čo podsklad je virtuálna súčasť skladu, v ktorom môže skladové položky vlastniť iný subjekt. Na jednom umiestnení môže byť spoločne uložený tovar z viacerých podskladov.'
    },
    smallWarehouse: {
        title: 'Čo je to malý sklad?',
        content: "Malý sklad má vždy maximálne jedno umiestnenie. Množstvo podskladov nie je obmedzené."
    },
    productLabels: {
        title: 'Čo mení prepínač "Zobrazenie položiek" model/názov?',
        content: 'Mení, či sa pri jednotlivých položkách v sklade zobrazuje ich názov alebo model. Toto se prejaví napríklad na stránke skladových pohybov, pri úlohách a ich tvorbe.'
    },
    setProductAttributes: {
        title: 'Ako nastaviť atribúty skladovej položky?',
        content: 'Pri vytváraní skladovej položky sa postupne načítajú odporúčané atribúty aktuálne vybraného typu skladovej položky. ' +
            'Z týchto atribútov sa uložia len tie, ktoré majú vyplnenú hodnotu. ' +
            'Pri úprave skladovej položky sa načítajú atribúty tejto skladovej položky a pri zmene typu skladovej položky sa opäť načítajú odporúčané atribúty tohto typu. ' +
            'Ak existuje zhoda názvu atribútu skladovej položky a odporúčaného atribútu, potom atribút skladovej položky prevezme popis odporúčaného atribútu. ' +
            'V obidvoch prípadoch (pri vytváraní aj úprave) možno súčasne nastaviť odporúčané atribúty aktuálne vybraného typu. ' +
            'Tieto atribúty nemusia mať vyplnenú hodnotu a popis je nepovinný pre atribúty skladovej položky aj pre odporúčané atribúty.'
    },
    markAsDeliveredAfterPacking: {
        title: 'Kedy je zásielka objednávky označená za doručenú?',
        content: 'Bežne je zásielka objednávky typu "osobný odber" označená za doručenú až vo chvíli vytvorenia odovzdávacieho protokolu.'
    },
};
