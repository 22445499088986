import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.showItemsThatCanBePutOn)?[_c('TaskItemsLocationHeader',{attrs:{"location-id":_vm.locationId}})]:[_c(VBottomSheet,{attrs:{"scrollable":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',[_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_c('TaskItemsLocationHeader',{attrs:{"location-id":_vm.locationId}})],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_c(VIcon,{staticClass:"iconPutItems mr-1"},[_vm._v(" $scannerDestination ")]),_c(VBtn,_vm._g({staticClass:"text--primary text-decoration-none",attrs:{"text":"","x-small":""}},on),[_c(VIcon,{attrs:{"x-small":""}},[_vm._v(" $openItem ")])],1)],1):_c(VLayout,{attrs:{"align-center":"","wrap":""}},[_c(VIcon,{staticClass:"iconPutItems mr-1"},[_vm._v(" $scannerDestination ")]),_vm._l((_vm.items),function(instance,index){return _c(VChip,{key:index,staticClass:"x-smallEllipsis",attrs:{"label":"","outlined":"","x-small":""}},[_vm._v(" "+_vm._s(_vm._f("instanceLabel")(instance))+" ")])}),(_vm.allowedInstances.length > 2)?_c(VBtn,_vm._g({staticClass:"text--primary text-decoration-none",attrs:{"text":"","x-small":""}},on),[_c(VIcon,{attrs:{"x-small":""}},[_vm._v(" $openItem ")])],1):_vm._e()],2)],1)]}}])},[_c(VSheet,[_c(VLayout,{staticClass:"py-3",attrs:{"align-center":"","column":"","wrap":""}},[_c(VFlex,{staticClass:"title mx-4"},[_vm._v(" "+_vm._s(_vm._f("locationLabel")(_vm.location))+" ")]),_c('span',[_c(VDivider,{staticClass:"my-2"}),_c('TaskLocationDetailItems',{staticStyle:{"max-width":"95vw"},attrs:{"allowed-instances":_vm.allowedInstances}})],1)],1)],1)],1),_c(VDivider,{staticClass:"mt-1"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }