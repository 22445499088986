import {ProductAPI} from "@/api/ProductAPI";
import Vue from "vue";
import {stocks} from "@/app/stocks/definitions/stockDataSources";
import {emptySource} from "@/app/definitions/emptyDataSources";
import {StockAPI} from "@/api/StockAPI";

function StocksRender(required = true, ssRequired = false, multipleSubstocks = false, ssSticky = true, readonly = false) {
    return {
        stockId: {
            icon: '$stock',
            required: required,
            sticky: required,
            readonly: readonly,
            autocomplete: {
                callFn: () => StockAPI.getAllPages(),
                thenFn: response => response.data.items.map(el => ({
                    text: el.name,
                    value: el.id
                }))
            }
        },
        subStockId: {
            icon: '$substock',
            required: ssRequired,
            sticky: ssSticky,
            readonly: readonly,
            autocomplete: {
                items: [],
                multiple: multipleSubstocks,
                chips: multipleSubstocks
            }
        }
    };
}

function StocksXAutocompleteRender(required = true, ssRequired = false, multipleSubstocks = false, ssSticky = true, readonly = false) {
    return {
        stockId: {
            icon: '$stock',
            required: required,
            sticky: required,
            readonly: readonly,
            xAutocomplete: stocks(),
        },
        subStockId: {
            icon: '$substock',
            required: ssRequired,
            sticky: ssSticky,
            readonly: readonly,
            xAutocomplete: emptySource(multipleSubstocks)
        }
    };
}

function ProductIdRender() {
    return {
        sticky: true,
        autocomplete: {
            callFn: () => ProductAPI.getVisibleAllPages(),
            thenFn: response => response.data.items.map(el => ({
                ...el,
                value: el.id
            })),
            render: item => item.text || Vue.options.filters.productLabel(item)
        }
    };
}

function InstanceIdRender() {
    return {
        sticky: true,
        show: false,
        autocomplete: {items: []}
    };
}

export {StocksRender, StocksXAutocompleteRender, ProductIdRender, InstanceIdRender};
