<template>
  <v-container fluid>
    <x-toolbar />
    <InformDialog
      :show="showDialog"
      text="imports.info.change_value_warn"
      @cancel="rollbackChange"
      @confirm="finishChange"
    />
    <InformDialog
      :show="showImportDialog"
      text="imports.info.edit_values_warn"
      @cancel="showImportDialog = false"
      @confirm="processImport(false)"
    />
    <v-skeleton-loader
      v-if="loading"
      type="table-heading, table-heading, actions"
    />
    <v-stepper
      v-else
      v-model="step"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          {{ $t('imports.stepper.first_step') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          {{ $t('imports.stepper.second_step') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="3"
        >
          {{ $t('imports.stepper.third_step') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          step="1"
        >
          <div
            :loading="loading || contentLoad"
          >
            <v-form
              v-if="!loading"
              ref="form"
            >
              <FormFields
                :form="form"
                :render="renderer"
                lang-path="imports.config."
              >
                <template #domain-item="{item}">
                  <v-icon class="pr-2">
                    {{ domainsIcons[item] }}
                  </v-icon>
                  {{ $t('exports.domains.' + item) }}
                </template>
                <template #file-append-outer>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        $info
                      </v-icon>
                    </template>
                    <span>{{ $t('imports.file_info_create') }}</span>
                  </v-tooltip>
                </template>
                <template #domain-append-outer>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        $info
                      </v-icon>
                    </template>
                    <span>{{ $t('imports.domain_info') }}</span>
                  </v-tooltip>
                </template>
                <template #easyMode-append>
                  <CheckBoxHint
                    name="imports.config.easyMode"
                    hint="imports.hints.easy_mode"
                  />
                </template>
                <template #latestOnDuplicity-append>
                  <CheckBoxHint
                    name="imports.config.latestOnDuplicity"
                    hint="imports.hints.latest_on_duplicity"
                  />
                </template>
              </FormFields>
            </v-form>
            <div v-if="showConfig">
              <v-divider />
              <ImportConfig
                :config="configDraft"
                :config-name="form.name"
                :domain="validDomain"
                :import-mode="importMode"
                :existing-config="config"
                :read-only="readOnlyConfig"
                :save-trigger="saveConfig"
                :source-file="validFile"
                show-optional-parameters
                @valid="newVal => validConfig = newVal"
                @config-save="handleSaveConfig"
              />
            </div>
            <v-flex
              class="pa-3 d-flex justify-end"
            >
              <v-btn
                v-if="showConfig"
                :disabled="!validConfig"
                :loading="loading"
                color="accent"
                @click="firstStep()"
              >
                {{ $t('imports.stepper.first_step') }}
                <v-icon>
                  $goForward
                </v-icon>
              </v-btn>
            </v-flex>
          </div>
        </v-stepper-content>
        <v-stepper-content
          step="2"
        >
          <div
            v-if="config"
            :loading="loading || contentLoad"
          >
            <ImportConfigHeader :config="config" />
            <v-form v-if="!importData">
              <FormFields
                :form="form"
                :render="renderer"
                lang-path="exports.configuration."
                @fileClick="form.file = null"
              >
                <template #file-append-outer>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        $info
                      </v-icon>
                    </template>
                    <span>{{ $t('imports.file_info_use') }}</span>
                  </v-tooltip>
                </template>
              </FormFields>
            </v-form>
            <WarnText
              v-if="!validFile && !importData"
              text="imports.info.fake_file_warn"
            />
            <Parameters
              v-if="config.parameters.length > 0 && !contentLoad"
              :domain="config.domain"
              :parameter-values="config.parameters"
              :title="$t('imports.config.parameters')"
              hint-path="imports.hints.parameters_hint"
              show-optional
              @emitParameters="val => importParameters = val"
            />
            <WarnText
              v-if="validFile && !validConfig && !importData"
              text="imports.info.mapping_warn"
            />
            <WarnText
              v-if="validFile && !fileLengthCheck"
              text="imports.info.short_file_warn"
            />
            <ImportConfig
              v-if="!contentLoad"
              :existing-config="config"
              :source-file="validFile"
              read-only
              hide-parameters
              @valid="newVal => validConfig = newVal"
              @fileLengthCheck="val => fileLengthCheck = val"
            />
            <div class="d-flex justify-end pa-3">
              <v-btn
                :disabled="(!validFile || !validConfig || !fileLengthCheck) && !importData"
                :loading="loading"
                color="accent"
                @click="secondStep()"
              >
                {{ $t('imports.stepper.second_step') }}
                <v-icon>
                  $goForward
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
        <v-stepper-content
          step="3"
        >
          <div
            :loading="loading || contentLoad"
          >
            <v-card-title>
              {{ $t('imports.info.title') }}
            </v-card-title>
            <ImportStatistics
              v-if="importData"
              :import-data="importData"
              :loading="dataLoading"
            />
            <div
              class="d-flex justify-space-between pa-3"
            >
              <v-btn
                :loading="contentLoad"
                color="accent"
                :disabled="dataLoading"
                @click="stepBack"
              >
                <v-icon>
                  $goBack
                </v-icon>
                {{ $t('imports.stepper.second_step_back') }}
              </v-btn>
              <v-btn
                v-if="importData && importData.status === status.DRY_RUN_FINISHED"
                :disabled="dataLoading"
                :loading="contentLoad"
                color="secondary"
                @click="processImportConfirm(false)"
              >
                {{ $t('imports.process') }}
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-expansion-panels
      v-if="step === 3 && !loading && !contentLoad && !dataLoading"
      class="my-5"
    >
      <v-expansion-panel class="pa-2">
        <v-expansion-panel-header class="text-lg-h6">
          {{ $t('imports.configuration') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Parameters
            v-if="importData.parameters.length > 0 && !contentLoad"
            :domain="template.domain"
            :parameter-values="importData.parameters"
            :title="$t('imports.config.parameters')"
            hint-path="imports.hints.parameters_hint"
            read-only
            show-optional
          />
          <ImportConfig
            :existing-config="importData.configuration"
            :read-only="true"
            :source-file="importData.download_link"
            hide-parameters
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
    import {ImportsAPI as API} from "@/api/ImportsAPI";
    import * as xlsx from "@/service/Xlsx";
    import ImportConfig from "@/app/imports/components/ImportConfig.component";
    import formRules from "@/utils/formRules";
    import ImportStatistics from "@/app/imports/components/ImportStatistics.component";
    import {DomainIcon} from "@/enum/domains";
    import Parameters from "@/app/exports/components/Parameters.component";
    import {ImportForm, ImportFormRender} from "@/app/imports/definitions/import.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import InformDialog from "@/app/components/InformDialog";
    import WarnText from "@/app/components/WarnText";
    import {Status} from "@/enum/import&export_state";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import CheckBoxHint from "@/app/components/CheckBoxHint";
    import {getImportMode, isEasyMode} from "@/utils/importMode";
    import ImportConfigHeader from "./components/ImportConfigHeader";
    import {ExportsAPI} from "@/api/ExportsAPI";


    export default {
        name: "ImportCreate",
        components: {
            ImportConfigHeader,
            CheckBoxHint, WarnText, InformDialog, FormFields, Parameters, ImportStatistics, ImportConfig},
        mixins: [EventsListenerMixin, RouteParamsMapperMixin],
        beforeRouteLeave(to, from, next) {
            if (this.abortPolling) {
                this.abortPolling();
            }
            next();
        },
        props: {
            hasImport: {
                type: Boolean,
                default: false,
            },
            hasTemplate: {
                type: Boolean,
                default: true,
            }
        },
        data: () => ({
            loading: true,
            dataLoading: true,
            contentLoad: true,
            formRules: formRules,
            form: ImportForm(true),
            renderer: ImportFormRender(true),
            status: Status,

            configDraft: null,
            config: null,
            validFile: null,
            validDomain: '',

            importData: null,
            allDomains: null,

            validConfig: true,

            showConfig: false,
            importPath: '',
            step: 1,
            configId: null,
            readOnlyConfig: false,
            saveConfig: 0,
            newConfig: false,
            domainsIcons: DomainIcon,
            showDialog: false,
            disableDialog: false,
            showImportDialog: false,
            fileLengthCheck: false,
            importFileTypes: [],

            importParameters: null,
            abortPolling: null
        }),
        computed: {
            events: function () {
                return {
                    'create-new': data => this.createNew(data)
                };
            },
            importMode() {
                if (this.config) {
                    return this.config.import_mode;
                } else {
                    return getImportMode(this.form.easyMode, this.form.latestOnDuplicity);
                }
            },
            easyMode() {
                return isEasyMode(this.importMode);
            },
            template() {
                if (this.validDomain) {
                    return this.allDomains.find(item => item.domain === this.validDomain);
                } else if (this.config) {
                    return this.allDomains.find(item => item.domain === this.config.domain);
                } else if (this.importData) {
                    return this.allDomains.find(item => item.domain === this.importData.domain);
                } else {
                    return [];
                }
            }
        },
        watch: {
            'form.file'(newVal) {
                if (this.step === 1) {
                    if (this.loading) {
                        return;
                    }
                    if (this.showConfig && !this.disableDialog && !this.loading) {
                        this.showDialog = true;
                    } else {
                        this.validFile = newVal;
                        this.checkFirstStep();
                    }
                } else {
                    this.checkSecondStep();
                }
            },
            'form.domain'(newVal) {
                if (this.step === 1) {
                    if (newVal === this.validDomain && this.showConfig && !this.loading) {
                        return;
                    }
                    if (this.showConfig && !this.disableDialog) {
                        this.showDialog = true;
                    } else {
                        this.validDomain = newVal;
                        this.checkFirstStep();
                    }
                }
            }
        },
        beforeDestroy() {
            if (this.statisticPolling && this.statisticPolling.abortController) {
                this.statisticPolling.abortController.abort();
            }
        },
        createdOrActivated() {
            this.getData();
            this.fetchImportFileTypes();
        },
        methods: {
            fetchImportFileTypes() {
                ExportsAPI.getTypes()
                    .then(res => {
                        this.importFileTypes = res.data.filter(type => type.supports_import).map(type => type.type);
                    })
                    .catch(err => this.snack(err));
            },
            getData() {
                this.loading = true;
                this.contentLoad = true;
                this.dataLoading = true;
                API.getTemplates()
                    .then(res => {
                        this.allDomains = res.data;
                        if (this.hasImport) {
                            this.skipToLast();
                        } else if (this.hasTemplate) {
                            this.step = 2;
                            this.configId = this.configurationId;
                            this.importData = null;
                            this.prepareForms(false);
                            this.setForms();
                        } else {
                            this.step = 1;
                            this.importData = null;
                            this.validFile = null;
                            this.showConfig = false;
                            this.validDomain = null;
                            this.config = null;
                            this.newConfig = true;
                            this.prepareForms(true);
                        }
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.contentLoad = false;
                    });
            },
            getConfigDraft() {
                this.contentLoad = true;
                API.getConfigDraft(this.validDomain, this.form.file)
                    .then(res => this.configDraft = res.data)
                    .finally(() => {
                        this.showConfig = true;
                        this.contentLoad = false;
                    });
            },
            prepareForms(isNew) {
                this.form = ImportForm(isNew);
                this.renderer = ImportFormRender(isNew);
                this.renderer.file.asyncRules = [this.checkFile];
                if (isNew) {
                    this.renderer.domain.select = this.allDomains.filter(item => item.supports_import)
                        .map((item) => item.domain);
                }
            },
            async setForms() {
                API.getConfig(this.configId)
                    .then(res => {
                        this.config = res.data;
                        this.configName = this.config.name;
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/imports');
                    })
                    .finally(() => {
                        this.contentLoad = false;
                        this.disableDialog = false;
                    });
            },
            finishChange() {
                if (this.form.file !== this.validFile) {
                    this.validFile = this.form.file;
                } else {
                    this.validDomain = this.form.domain;
                }
                this.checkFirstStep();
                this.showDialog = false;
            },
            rollbackChange() {
                this.disableDialog = true;
                if (this.form.file !== this.validFile) {
                    this.form.file = this.validFile;
                } else {
                    this.form.domain = this.validDomain;
                }
                this.disableDialog = false;
                this.showDialog = false;
            },
            skipToLast() {
                API.get(this.importId)
                    .then(res => {
                        this.importData = res.data;
                        this.readOnlyConfig = true;
                        this.configName = null;
                        this.step = 3;
                        this.statisticFetch();
                        this.contentLoad = false;
                        this.loading = false;
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/imports');
                    });
            },
            firstStep() {
                if (!this.$refs.form.validate()) {
                    return;
                }
                this.contentLoad = true;
                this.disableDialog = true;
                const data = {
                    domain: this.validDomain ? this.validDomain : this.config.domain,
                    file: this.validFile,
                    name: this.form.name,
                    importMode: this.importMode
                };
                API.createConfig(data)
                    .then(res => {
                        this.importPath = res.headers.location;
                        this.configId = this.importPath.split('/').pop();
                        API.getConfig(this.configId)
                            .then(res => {
                                this.config = res.data;
                                this.saveConfig++;
                            });
                    })
                    .catch(err => {
                        this.snack(err);
                    });
            },
            secondStep() {
                API.create(this.config.id, this.validFile)
                    .then(res => {
                        API.get(res.headers.location.split('/').pop())
                            .then(res => {
                                this.importData = res.data;
                                if (this.config.parameters.length > 0) {
                                    API.update(this.importData.id, {parameters: this.importParameters})
                                        .then(() => this.processImport(true))
                                        .catch(err => this.snack(err));
                                } else {
                                    this.processImport(true);
                                }
                                this.statisticFetch();
                                this.step = 3;
                            })
                            .catch(err => {
                                this.snack(err);
                            });
                    }).finally(() => this.contentLoad = false);
            },
            async handleSaveConfig(id) {
                if (id !== false) {
                    await API.getConfig(this.configId)
                        .then(res => {
                            this.config = res.data;
                            this.step = 2;
                            this.prepareForms(false);
                            this.form.file = this.validFile;
                            this.setForms();
                        })
                        .catch(err => {
                            this.snack(err);
                        });
                } else {
                    this.snack('imports.attribute_error');
                }
                this.contentLoad = false;
            },
            checkFirstStep() {
                this.showConfig = false;
                this.checkFile(this.form.file)
                    .then(res => {
                        if (res === true && this.validDomain) {
                            this.validFile = this.form.file;
                            this.getConfigDraft();
                        } else {
                            this.validFile = null;
                        }
                    });
            },
            checkSecondStep() {
                this.checkFile(this.form.file)
                    .then(res => {
                        if (typeof res !== "string") {
                            this.validFile = this.form.file;
                        } else {
                            this.validFile = null;
                        }
                    });
            },
            async checkFile(testFile) {
                if (!testFile) {
                    return this.$t('form.required');
                } else if (!this.checkFileType(testFile)) {
                    return this.$t('imports.rules.file_type');
                }
                let file = null;
                await testFile.arrayBuffer()
                    .then(res => file = res)
                    .catch(() => file = this.$t('imports.rules.multiple_sheets'));
                if (typeof file === "string") {
                    return file;
                }
                if (xlsx.numberOfSheets(file) > 1) {
                    return this.$t('imports.rules.multiple_sheets');
                }
                return true;
            },
            checkFileType(file) {
                const fileType = file.name.split('.').pop();
                return this.importFileTypes.includes(fileType);
            },
            processImportConfirm(dryRun) {
                if (!this.easyMode && this.importData.statistics.columns.some(item => item.updated > 0)) {
                    this.showImportDialog = true;
                } else {
                    this.processImport(dryRun);
                }
            },
            processImport(dryRun) {
                if (!dryRun) {
                    this.loading = true;
                    this.showImportDialog = false;
                }
                const data = {
                    dry_run: dryRun
                };
                API.process(this.importData.id, data)
                    .then(() => {
                        if (!dryRun) {
                            this.snack('imports.process_started');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/imports');
                        } else {
                            this.loading = false;
                        }
                    })
                    .catch(err => this.snack(err));
            },
            async statisticFetch() {
                const polling = API.getStatistics(this.importData.id);
                this.abortPolling = polling.abortPolling;
                polling.promise
                    .then(res => {
                        this.importData = res.data;
                    })
                    .catch(err => {
                        this.snack(err);
                    }).finally(() => {
                        this.dataLoading = false;
                        this.abortPolling = null;
                    });
            },
            async stepBack() {
                this.contentLoad = true;
                this.step = 2;
                if (!this.configId) {
                    this.configId = this.importData.configuration.id;
                    this.prepareForms(false);
                    await this.setForms();
                }
                await API.delete(this.importData.id)
                    .then(() => {
                        this.importData = null;
                        this.form.file = null;
                        this.dataLoading = true;
                        this.contentLoad = false;
                    }).catch(err => this.snack(err));
            },
        },

    };
</script>

