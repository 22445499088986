const TaskAction = {
    ASSIGN: 'assign',
    START_PROCESSING: 'start-processing',
    PROCESS: 'process',
    UNASSIGN: 'unassign',
    FINISH: 'finish',
    APPROVE: 'approve',
    REJECT: 'reject',
    CLOSE: 'close',
    CANCEL: 'cancel'
};

export {TaskAction};