import {taskNames, taskIcons, taskLinks, taskTypes} from "@/enum/task_type";

const availableTasks = [
    taskTypes.MOVE_PRODUCTS,
    taskTypes.LOCATION_TRANSFER,
];

const actionsOrganizer = availableTasks.map(taskType => ({
        icon: taskIcons[taskType],
        label: taskNames[taskType],
        to: taskLinks[taskType] + '/create'
    })
);

export {actionsOrganizer};
