import i18n from "@/service/lang/i18n";

export const Domains = {
    BUYER: 'buyer',
    PRODUCT: 'product',
    BUY_PRICE: 'buy-price',
    BULK_EXTERNAL_ORDER: 'bulk-external-order',
    DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT: 'daily-location-occupancy-by-type-report',
    DAILY_LOCATION_OCCUPANCY_REPORT: 'daily-location-occupancy-report',
    DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT: 'daily-location-occupancy-with-movements-report',
    INTERVAL_MOVEMENTS_REPORT: 'movements-by-interval-report',
    SHARED_LOCATIONS_REPORT: 'shared-locations-report',
    PRODUCT_AT_STOCK:  'products-at-stock-by-interval-report',
    TASK_ATTRIBUTES: 'task-attributes-report'
};

export const DomainIcon = {
    [Domains.BUYER]: '$buyers',
    [Domains.PRODUCT]: '$product',
    [Domains.BUY_PRICE]: '$buyPrice',
    [Domains.BULK_EXTERNAL_ORDER]: '$taskExternalOrder',
    [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: '$graph',
    [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: '$graph',
    [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: '$graph',
    [Domains.INTERVAL_MOVEMENTS_REPORT]: '$graph',
    [Domains.SHARED_LOCATIONS_REPORT]: '$graph',
    [Domains.PRODUCT_AT_STOCK]: '$graph',
    [Domains.TASK_ATTRIBUTES]: '$graph'
};

export const Parameters = {
    STOCK: 'stockId',
    SUBSTOCK: 'subordinate_stock_id',
    SUBSTOCKS: 'subordinate_stock_ids',
    MANUFACTURERS: 'manufacturer_ids',
    PRODUCT_TYPES: 'product_type_ids',
    PRODUCTS: 'product_ids',
    LOCATIONS: 'location_ids',
    INCLUDE_MISSING_PRODUCTS: 'include_missing_products',
    INCLUDE_EMPTY_LOCATIONS: 'include_empty_locations',
    DATE: 'date',
    FROM_DATE: 'date_from',
    TO_DATE: 'date_to',
    INTERVAL: 'interval',
    INTERVAL_FROM: 'interval_from',
    INTERVAL_TO: 'interval_to',
    CARRIER: 'carrier_id',
    CARRIER_SERVICE: 'carrier_service_id',
    CARRIER_SERVICE_PARAMETERS: 'carrier_service_parameters',
    EXTERNAL_AUTHOR: 'external_author_id',
    EXTERNAL_AUTHOR_NAME: 'external_author_name',
    EXTERNAL_NOTE: 'external_note',
    REPORT_TYPE: 'report_type',
    TASK_TYPES: 'task_types',
    STORE_EXTERNAL_IMAGES: 'store_external_images'
};

const domainTranslations = {
    [Domains.BUYER]: {
        buyer: 'buyers.title',
        id: 'products.instances.id',
        external_id: 'tasks.external_id',
        name: 'buyers.name',
        ico: 'buyers.ico',
        dic: 'buyers.dic',
        phone: 'buyers.phone',
        email: 'buyers.email',
        website: 'buyers.website',
        is_minor: 'buyers.is_minor',
        type: 'buyers.type_id',
        is_default: 'buyers.addresses.default',
        street: 'base.address.street',
        house_number: 'base.address.house_number',
        suburb: 'base.address.suburb',
        city: 'base.address.city',
        postal_code: 'base.address.postal_code',
        country: 'base.address.country',
        country_iso_code: 'base.address.country_iso_code',
        privacy: 'buyers.addresses.privacy',
        address: 'stocks.address',
        delivery_address: 'buyers.delivery_address',
        billing_address: 'buyers.billing_address',
    },
    [Domains.PRODUCT]: {
        product: 'products.title',
        id: 'products.instances.id',
        external_id: 'tasks.external_id',
        name: 'products.name',
        description: 'products.description',
        measure_unit: 'products.measure_unit',
        model: 'products.model',
        visible: 'products.visible',
        deleted: 'products.deleted',
        can_have_serial_number: 'products.can_have_serial_number',
        can_have_batch: 'products.can_have_batch',
        buy_price: 'products.buy_price',
        sell_price: 'products.sell_price',
        vat: 'tasks.stockLoading.buyPrices.vat',
        manufacturer: 'products.manufacturer.label',
        abbreviation: 'manufacturers.abbreviation',
        type: 'products.type.label',
        attribute: 'products.attributes.name',
        existing_attribute: 'products.attributes.existing_attributes',
        key: 'products.attributes.key',
        value: 'products.attributes.value',
        image: 'base.images.name',
        path: 'base.images.url',
        is_main_photo: 'base.images.main',
        batch: 'products.batches.name',
        code: 'products.batches.code',
        manufactured_date: 'products.batches.manufactured_date',
        expire_date: 'products.batches.expire_date',
        number: 'products.serial_numbers.number',
        serial_number: 'products.serial_numbers.serial_number',
        serial: 'products.serial_numbers.serial_number',
        barcode: 'base.cache.barcodes',
        quantity: 'products.quantity',
    },
    [Domains.BUY_PRICE]: {
        'Buy price': 'tasks.stockLoading.buyPrices.title',
        'Product identifier': 'tasks.chooseItems.product',
        'Product instance identifier': 'tasks.chooseItems.instance',
        'Product name': 'products.name',
        'Manufacturer name': 'tasks.stockLoading.buyPrices.manufacturer.id',
        'Quantity at stock': 'tasks.chooseItems.quantity',
        Model: 'products.model',
        'Measure unit': 'tasks.stockLoading.buyPrices.measure_unit',
        'Buying price': 'tasks.stockLoading.buyPrices.price',
        'Total price': 'base.shipping.table.total_value',
        Vat: 'tasks.stockLoading.buyPrices.vat',
        'Created at': 'exports.created_at',
    },
    [Domains.BULK_EXTERNAL_ORDER]: {
        'bulk-external-order': 'exports.domains.' + Domains.BULK_EXTERNAL_ORDER,
        'carrier': 'carriers.title',
        'carrier_service': 'carriers.services.title',
        'carrier_service_parameter': 'carriers.services.carrier_service_parameters',
    },
    [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: {
        'Daily location occupancy report': 'reports.dailyLocation',
        'Product identifier': 'products.titleSingle',
        'Product model': 'products.model',
        "Product name": 'products.name',
        "Product image": 'base.images.name',
        "Product type name": 'products.type.label',
        "Manufacturer name": 'products.manufacturer.label',
        "Amount at stock": 'stocks.stockMovements.table.amount',
        "Count of filled locations": 'reports.attributes.filled_locations_count',
        "Last loaded at": 'reports.attributes.last_loaded_at',
        "Last picked at": 'reports.attributes.last_picked_at',
    },
    [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: {
        'Daily location occupancy with movements report': 'reports.dailyLocationWithMovement',
        'Object identifier': 'reports.object_id',
        'Object name': 'suppliers.name',
        'Daily location occupancy': 'reports.dailyLocationOccupancy'
    },
    [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: {
        'Daily location occupancy by type report': 'reports.dailyLocationByType'
    },
    [Domains.INTERVAL_MOVEMENTS_REPORT]: {
        'Movements by interval': 'exports.domains.'+ Domains.INTERVAL_MOVEMENTS_REPORT,
        'Product ID': 'products.titleSingle',
        'Count of filled locations at the end of period': 'reports.attributes.filled_location_count',
        'Amount loaded to stock': 'reports.attributes.stock_loaded_amount',
        'Amount of items at the end of period': 'reports.attributes.period_end_items_amount',
        'Count of transfers': 'reports.attributes.transfers_count',
        'Amount picked from stock': 'reports.attributes.stock_picked_amount',
        'Product type ID': 'reports.attributes.product_type_id',
        'Manufacturer ID': 'reports.attributes.manufacturer_id',
        'Month': 'reports.attributes.month',
        'Week of month': 'reports.attributes.week_of_month'


    },
    [Domains.SHARED_LOCATIONS_REPORT]: {
        'Shared locations': 'exports.domains.'+ Domains.SHARED_LOCATIONS_REPORT,
        'Location ID': 'stocks.stockStatus.locations.table.location_id',
        'Location name': 'stocks.stockStatus.locations.table.location_name',
        'Occupancy': 'reports.attributes.occupancy'
    },
    [Domains.PRODUCT_AT_STOCK]: {
        'Products at stock by interval': 'exports.domains.'+ Domains.PRODUCT_AT_STOCK,
        'Stock level': 'stocks.stockMovements.table.amount'
    },
    [Domains.TASK_ATTRIBUTES]: {
        'Task attributes': 'export.domains.' + Domains.TASK_ATTRIBUTES,
        'Stock ID': 'stocks.name',
        'Stock name': 'stocks.id',
        'Subordinate stock ID': 'stocks.substocks.id',
        'Subordinate stock name': 'stocks.substocks.name',
        'Task type': 'reports.parameters.task_types',
        'Task ID': 'tasks.id',
        'Attribute name': 'base.attributes.templates.titleSingle',
        'Integer value': 'base.attributes.dataTypes.Integer',
        'String value': 'base.attributes.dataTypes.String',
        'Boolean value': 'base.attributes.dataTypes.Boolean',
        'Float value': 'base.attributes.dataTypes.Float',
        'Attribute created at': 'tasks.attributes.created_at',
        'Task created at': 'tasks.timeEntries.created_at'
    }
};

export const DomainTranslationBridge = {
    [Domains.BUYER]: domainTranslations.buyer,
    [Domains.PRODUCT]: domainTranslations.product,
    [Domains.BUY_PRICE]: domainTranslations["buy-price"],
    [Domains.BULK_EXTERNAL_ORDER]: {
        bulk_external_order: 'exports.domains.' + Domains.BULK_EXTERNAL_ORDER,
        ...domainTranslations[Domains.BUYER],
        ...domainTranslations[Domains.PRODUCT],
        ...domainTranslations[Domains.BULK_EXTERNAL_ORDER]
    },
    [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_REPORT],
    [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: {
        ...domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT],
        ...domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]
    },
    [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT],
    [Domains.INTERVAL_MOVEMENTS_REPORT]: {
        ...domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_REPORT],
        ...domainTranslations[Domains.INTERVAL_MOVEMENTS_REPORT],
    },
    [Domains.SHARED_LOCATIONS_REPORT]: domainTranslations[Domains.SHARED_LOCATIONS_REPORT],
    [Domains.PRODUCT_AT_STOCK]: {
        ...domainTranslations[Domains.PRODUCT_AT_STOCK],
        ...domainTranslations[Domains.INTERVAL_MOVEMENTS_REPORT],
        ...domainTranslations[Domains.DAILY_LOCATION_OCCUPANCY_REPORT]
    },
    [Domains.TASK_ATTRIBUTES]: {
        ...domainTranslations[Domains.TASK_ATTRIBUTES]
    }
};

export function getTranslation(code = null, domain) {
    if (code === null || code === domain) {
        return i18n.t(DomainTranslationBridge[domain][domain]);
    }
    const codeParts = code.split('.');
    return codeParts.reduce((acc, item) => {
        if (acc !== '') {
            acc += ' \u27F6 ';
        }
        if (i18n.te(DomainTranslationBridge[domain][item])) {
            acc += i18n.t(DomainTranslationBridge[domain][item]);
        } else {
            acc += item;
        }
        return acc;
    }, '');

}
