<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedLock !== null"
      :show.sync="confirmDialog"
      :text="$t('stocks.locations.locks.removeType', [$t('stocks.locations.locks.lockTypes.' + deletedLock.lock_type)])"
      @confirm-delete="reallyDeleteLock"
    />
    <masonry
      :cols="$vuetify.breakpoint.lgAndUp ? 2 : 1"
      :gutter="0"
    >
      <div
        v-for="(lock, index) in locks"
        :key="index"
        class="pa-1"
      >
        <v-expansion-panels
          :value="0"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1">
              <div class="d-flex flex-row justify-space-between">
                <span>
                  {{
                    $t('stocks.locations.locks.lockTypes.title', [$t('stocks.locations.locks.lockTypes.' + lock.lock_type)])
                  }}
                </span>
                <v-btn
                  icon
                  color="error"
                  class="mr-6"
                  @click.stop="deleteLock(index)"
                >
                  <v-icon>
                    $deleteTableItem
                  </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <StockLocationLocksList
                :key="lock.lock_type"
                :lock="lock"
                :lock-type="lock.lock_type"
                is-edit
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </masonry>
    <v-btn
      v-if="!hasAllLockTypes"
      :loading="loading"
      color="accent"
      class="ma-2"
      :to="'/stocks/' + stockId + '/locations/' + locationId + '/locks/create'"
    >
      <v-icon
        class="mr-2"
      >
        $addItem
      </v-icon>
      {{ $t('stocks.locations.locks.create.titleSimple') }}
    </v-btn>
  </div>
</template>

<script>
    import StockLocationLocksList from "@/app/stocks/locations/locks/components/StockLocationLocksList.component";
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {StockLocationLockType} from "@/enum/stock_location_lock_type";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "StockLocationLockTypes",
        components: {StockLocationLocksList, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            locks: null,
            loading: true,
            deletedLock: null,
            confirmDialog: false
        }),
        createdOrActivated: function () {
            this.fetchLocks();
        },
        computed: {
            hasAllLockTypes: function () {
                if (this.locks === null) {
                    return true;
                }
                return this.locks.length === Object.keys(StockLocationLockType).length;
            }
        },
        methods: {
            fetchLocks: function () {
                this.loading = true;
                StockAPI.getAllLocationLocks(this.stockId, this.locationId).then(response => {
                    this.locks = response.data;
                    this.$emit('locksLoaded', this.hasAllLockTypes);
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            deleteLock: function (index) {
                this.deletedLock = this.locks[index];
                this.confirmDialog = true;
            },
            reallyDeleteLock: function () {
                this.loading = true;
                this.confirmDialog = false;
                StockAPI.deleteLocationLock(this.stockId, this.locationId, this.deletedLock.lock_type)
                    .then(() => {
                        this.advancedSnack({
                            text: 'stocks.locations.locks.delete.done',
                            params: [this.$t('stocks.locations.locks.lockTypes.' + this.deletedLock.lock_type)]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.fetchLocks();
                    });
            },
        }
    };
</script>

<style scoped>

</style>
