import {TableFilter} from "@/enum/table_filter";

function InstanceTypeForm() {
    return {
        name: ''
    };
}

function InstanceTypeRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
    };
}

const instanceTypeTable = {
    name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    }
};

export {InstanceTypeForm, InstanceTypeRender, instanceTypeTable};
