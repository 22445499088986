<template>
  <TaskDefaultOverview
    :api="API"
    :task-info="taskInfo"
  >
    <template #approveOption>
      <ExternalOrderApproveCard
        v-if="isInProgress && assignedToCurrentUser && anyItemsLeftToBeProcessed"
        :task-info="taskInfo"
        :items="items"
        :default-data-picking="defaultDataPicking"
        :all-items-available="allItemsAvailable"
        class="mb-4"
      />
      <ExternalOrderCloseCard
        v-if="isChief && (!isCreated && !isUnassigned)"
        :task-id="taskInfo.details.id"
      />
    </template>
  </TaskDefaultOverview>
</template>

<script>
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import ExternalOrderApproveCard from "@/app/tasks/externalOrder/components/ExternalOrderApproveCard.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import ExternalOrderCloseCard from "@/app/tasks/externalOrder/components/ExternalOrderCloseCard.component";
    import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

    export default {
        name: "ExternalOrderOverview",
        components: {ExternalOrderCloseCard, TaskDefaultOverview, ExternalOrderApproveCard},
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            },
            allItemsAvailable: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            API: API,
            TaskExternalOrderProcessingMode: TaskExternalOrderProcessingMode
        }),
        computed: {
            defaultDataPicking: function () {
                return {
                    stock_id: this.taskInfo.details.subordinate_stock.stock_id,
                    subordinate_stock_id: this.taskInfo.details.subordinate_stock.id
                };
            },
            anyItemsLeftToBeProcessed: function () {
                return this.items.some(item => item.quantity > item.satisfied_quantity);
            }
        }
    };
</script>

<style scoped>

</style>
