<template>
  <div v-if="loading">
    <v-skeleton-loader
      loading
      type="text, divider, text, table-heading, table, action"
    />
  </div>
  <v-container
    v-else
    fluid
    class="pa-0"
  >
    <div v-if="!contentLoad && template.import_parameters.length > 0 && !hideParameters">
      <v-form ref="parameters_form">
        <Parameters
          :read-only="readOnly"
          :domain="domain"
          :parameter-values="existingConfig ? existingConfig.parameters : parameters"
          :title="$t('imports.config.defaultParameters')"
          hint-path="imports.hints.parameters_hint"
          :show-optional="showOptionalParameters"
          @emitParameters="values => parameterValues = values"
          @validParameters="valid => parametersValid = valid"
        />
      </v-form>
    </div>
    <v-card-title>
      {{ $t('imports.config.required_attributes') }}
      <v-icon
        class="ml-2"
        color="muted"
        @click="showAttributesHint =! showAttributesHint"
      >
        $info
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="showAttributesHint">
      {{ $t('imports.hints.required_attributes') }}
    </v-card-subtitle>
    <v-chip
      v-for="attribute in requiredChips"
      :key="attribute.code"
      :color="attribute.is_used ? 'green' : 'red'"
      :disabled="readOnly"
      :ripple="false"
      class="ma-1"
      outlined
    >
      <v-icon
        v-if="attribute.is_used"
        left
      >
        $success
      </v-icon>
      {{ domainService.getTranslation(attribute.code ? attribute.code : attribute.name, configDomain) }}
    </v-chip>
    <v-card-title>
      {{ $t('imports.config.mapping') }}
      <v-icon
        class="ml-2"
        color="muted"
        @click="showMapHint =! showMapHint"
      >
        $info
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="showMapHint">
      {{ $t('imports.hints.mapping') }}
    </v-card-subtitle>
    <v-layout
      justify-start
      class="pl-3"
    >
      <v-flex
        sm6
      >
        <v-layout
          justify-start
          row
          wrap
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-flex
                v-bind="attrs"
                class="pl-2"
                md4
                xs6
                v-on="on"
              >
                <v-text-field
                  v-model="headerRows"
                  :disabled="readOnly"
                  :label="$t('imports.config.headerRows')"
                  :prepend-icon="'$tableHeader'"
                  :rules="[formRules.range(0,100)]"
                  type="number"
                />
              </v-flex>
            </template>
            <span>{{ $t('imports.hints.header_rows') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-flex
                v-bind="attrs"
                class="pl-2"
                md4
                xs6
                v-on="on"
              >
                <v-text-field
                  v-model="firstRow"
                  :disabled="readOnly"
                  :label="$t('imports.config.firstRow')"
                  :prepend-icon="'$tableRow'"
                  :rules="[formRules.range(0,100)]"
                  validate-on-blur
                  type="number"
                />
              </v-flex>
            </template>
            <span>{{ $t('imports.hints.first_row') }}</span>
          </v-tooltip>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-simple-table
      dense
      class="pa-2"
    >
      <template #default>
        <th
          v-for="(header,headerKey) in columnData"
          :key="headerKey"
        >
          <v-menu
            bottom
            close-on-content-click
            offset-x
            offset-y
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :disabled="readOnly"
                :outlined="header.templateCode === ''"
                color="primary"
                small
                width="99%"
                v-on="on"
              >
                <v-icon
                  v-if="header.is_primary"
                  class="pr-2"
                  color="accent"
                >
                  $primaryKey
                </v-icon>
                {{
                  header.templateCode === '' ?
                    $t('imports.config.not_mapped')
                    : domainService.getTranslation(header.templateCode, configDomain)
                }}
              </v-btn>
            </template>
            <v-list
              class="headerList"
            >
              <DomainList
                v-if="!contentLoad"
                :column="header"
                :domain="templateData"
                :domain-name="configDomain"
                :reload-trigger="reloadTrigger"
                :required-attributes="requiredAttributes"
                first
                @updateHeader="updateHeader($event, headerKey)"
              />
            </v-list>
          </v-menu>
        </th>
        <tbody>
          <tr
            v-for="(row, key) in activeItems"
            :key="'r:' + key"
          >
            <td
              v-for="(cell, cellKey) in row"
              :key="'r:' + key + 'c:' + cellKey"
              :class="key === parseInt(headerRows, 10) ? 'headerBottom' : ''"
            >
              <v-container :class="key < parseInt(headerRows, 10) ? 'header' : ''">
                {{ cell !== 'null' ? cell : '' }}
              </v-container>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <WarnText
      v-if="nonValidGroups.length !== 0"
      :text="$t('imports.info.primary_key_group_warn', [nonValidGroups])"
    />
    <WarnText
      v-if="requiredAttributes.some(item => !item.is_used)"
      text="imports.info.mapping_warn_create"
    />
  </v-container>
</template>

<script>
    import DomainList from "@/app/imports/components/DomainList";
    import {ImportsAPI as API} from "@/api/ImportsAPI";
    import * as xlsx from "@/service/Xlsx";
    import formRules from "@/utils/formRules";
    import * as DomainService from "@/enum/domains";
    import Parameters from "@/app/exports/components/Parameters.component";
    import WarnText from "@/app/components/WarnText";
    import {isEasyMode} from "@/utils/importMode";


    export default {
        name: "ImportConfig",
        components: {WarnText, Parameters, DomainList},
        props: {
            importData: {
                type: Object,
                default: null,
            },
            existingConfig: {
                type: Object,
                default: null
            },
            domain: {
                type: String,
                default: ''
            },
            importMode: {
                type: Number,
                default: 1,
            },
            config: {
                type: Object,
                default: null,
            },
            parameters: {
                type: Array,
                default: () => []
            },
            sourceFile: {
                default: null
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            configName: {
                type: String,
                default: null
            },
            saveTrigger: {
                type: Number,
                default: 0
            },
            hideParameters: {
                type: Boolean,
                default: false
            },
            showOptionalParameters: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loadingCount: 0,
            contentLoad: false,
            items: [],
            headerRows: 1,
            firstRow: 0,
            template: {},
            templateHeaders: [],
            columnData: [],
            formRules: formRules,
            reloadTrigger: 0,
            showMapHint: false,
            showAttributesHint: false,
            domainService: DomainService,
            validGroups: '',
            previewRows: 5,
            parameterValues: null,
            parametersValid: false,
        }),
        computed: {
            loading() {
                if (this.loadingCount === 0) {
                    this.$emit('loaded');
                }
                return this.loadingCount !== 0;
            },
            templateData() {
                if (this.template.columns === undefined || this.template.columns.length === 0) {
                    return;
                }
                const firstGroup = this.template.columns[0];
                return this.updateGroup(firstGroup);
            },
            requiredAttributes() {
                if (typeof this.templateData === "undefined") {
                    return [];
                }
                const attributes = this.getRequiredInGroup(this.templateData);
                this.$emit('attributes', attributes.filter(item => !item.is_used));
                return attributes;
            },
            requiredChips() {
                return this.easyMode ? this.requiredAttributes.filter(item => !item.columns || !item.is_mandatory_for_main_entity)
                    : this.requiredAttributes.filter(item => !item.columns || item.columns.every(item => !item.is_required));
            },
            activeItems() {
                if (!this.items) {
                    return [];
                }
                return this.items.slice(parseInt(this.firstRow, 10), this.previewRows + parseInt(this.headerRows, 10));
            },
            currentConfig() {
                return this.existingConfig ? this.existingConfig : this.config;
            },
            configDomain() {
                return this.existingConfig ? this.existingConfig.domain : this.domain;
            },
            easyMode() {
                return isEasyMode(this.importMode);
            },
            nonValidGroups() {
                if (typeof this.templateData === "undefined") {
                    return [];
                }
                return this.getNonValidGroups(this.templateData)
                    .map(item => DomainService.getTranslation(item, this.configDomain)).join(', ');
            },
            isValid() {
                let valid = true;
                if (typeof this.templateData === "undefined") {
                    return false;
                }
                const attributes = this.getRequiredInGroup(this.templateData);
                if (attributes.some(item => !item.is_used)) {
                    valid = false;
                }
                if (this.getNonValidGroups(this.templateData).length !== 0) {
                    valid = false;
                }
                if (!this.hideParameters && this.template.import_parameters.length > 0 && !this.parametersValid) {
                    valid = false;
                }
                return valid;
            }
        },
        watch: {
            headerRows(newVal) {
                if (newVal < 1) {
                    this.headerRows = 1;
                }
            },
            firstRow(newVal) {
                if (newVal < 0) {
                    this.firstRow = 0;
                }
            },
            saveTrigger() {
                this.saveConfig();
            },
            sourceFile() {
                this.getData();
            },
            template: {
                deep: true,
                handler: function () {
                    this.$emit('valid', this.isValid);
                }
            },
            columnData: {
                deep: true,
                handler: function () {
                    this.$emit('valid', this.isValid);
                }
            },
            parameterValues: {
                deep: true,
                handler: function () {
                    this.$emit('valid', this.isValid);
                }
            }
        },
        createdOrActivated() {
            this.getData();
        },
        methods: {
            updateGroup(group) {
                group.columns.forEach(item => {
                    if (this.isGroup(item)) {
                        item.code = group.code ? group.code + '.' + item.name : item.name;
                        this.updateGroup(item);
                        item.is_used = item.columns.some(el => el.is_used);
                    } else {
                        item.is_used = this.isUsed(item);
                        item.is_primary = this.isPrimary(item);
                    }
                });
                return group;
            },
            isUsed(attribute) {
                return this.columnData.some(item => attribute.code === item.templateCode);
            },
            isPrimary(attribute) {
                const item = this.columnData.find(item => attribute.code === item.templateCode);
                return item ? item.is_primary : false;
            },
            getRequiredInGroup(group) {
                let items = [];
                group.columns.forEach(item => {
                    if (this.isGroup(item)) {
                        if (item.is_required || item.columns.some(item => item.is_used)) {
                            if (this.easyMode && !item.is_mandatory_for_main_entity) {
                                items.push(item);
                            } else {
                                const groupRequired = this.getRequiredInGroup(item);
                                if (groupRequired.length > 0) {
                                    items = items.concat(groupRequired);
                                }
                                items.push(item);
                            }
                        }
                    } else if (item.is_required) {
                        if (this.easyMode && group.is_mandatory_for_main_entity) {
                            items.push(item);
                        } else if ((this.hasActiveGroup(item) || group.is_required))
                            items.push(item);
                    }
                });
                return items;
            },
            getNonValidGroups(group) {
                let returnArr = [];
                if (group.is_primary_column_required && !group.columns.some(item => item.is_primary && item.is_used)
                    && group.columns.some(item => item.is_used)) {
                    returnArr.push(group.name);
                }
                group.columns.filter(item => item.columns).forEach(item => {
                    returnArr = returnArr.concat(this.getNonValidGroups(item));
                });
                return returnArr;
            },
            hasActiveGroup(item) {
                const group = this.findGroupOfElement(item, this.templateData.columns);
                return group.some(item => item.is_used);
            },
            findGroupOfElement(element, group) {
                const foundElement = group.filter(item => !this.isGroup(item)).some(scalar => scalar.code === element.code);
                if (foundElement) {
                    return group;
                }

                let foundGroups;
                group.filter(item => this.isGroup(item)).forEach(group => {
                    const tmpGroup = this.findGroupOfElement(element, group.columns);
                    if (tmpGroup) {
                        foundGroups = tmpGroup;
                    }
                });
                return foundGroups;
            },
            updateHeader(event, position) {
                this.columnData[position] = event;
                this.reloadTrigger++;
            },
            getTemplateHeaders() {
                this.templateHeaders = this.getHeadersInGroup(this.template.columns[0]);
            },
            getHeadersInGroup(group) {
                return group.columns.reduce((acc, curr) => {
                    if (this.isGroup(curr)) {
                        acc.push(...this.getHeadersInGroup(curr));
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
            },
            isGroup(item) {
                return item['columns'];
            },
            getData() {
                this.loadingCount++;
                API.getTemplate(this.configDomain)
                    .then(response => {
                        this.template = response.data;
                        this.getTemplateHeaders();
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loadingCount--;
                    });
                // Fetch is used with authorization that is contained in the download_link, generated using api.
                if (this.sourceFile && typeof this.sourceFile !== 'string') {
                    this.getFile(this.sourceFile);
                } else {
                    this.loadingCount++;
                    fetch(this.sourceFile ? this.sourceFile : this.currentConfig.download_link)
                        .then(res => res.blob())
                        .then(blob => {
                            this.getFile(blob);
                            this.loadingCount--;
                        })
                        .catch(() => {
                            this.snack('imports.download_error');
                        });
                }
            },
            getFile(file) {
                this.loadingCount++;
                file.arrayBuffer()
                    .then(res => {
                        const sheetOptions = {
                            header: 1,
                            raw: true,
                            defval: 'null',
                            blankrows: true
                        };
                        this.items = xlsx.getDataFromFile(res, {dense: true}, sheetOptions);
                        if (this.existingConfig) {
                            let fileLengthOk = true;
                            const maxColumn = this.existingConfig.columns.reduce((max, column) => {
                                return Math.max(max, column.column);
                            }, 0);
                            if (this.items[0].length <= maxColumn) {
                                fileLengthOk = false;
                            }
                            this.$emit('fileLengthCheck', fileLengthOk);
                        }
                        this.getColumnData();
                        this.getDomainTemplate();
                        this.firstRow = this.currentConfig.first_header_row - 1;
                        this.headerRows = this.currentConfig.first_data_row - this.currentConfig.first_header_row;
                        this.loadingCount--;
                    });

            },
            getDomainTemplate() {
                this.loadingCount++;
                API.getTemplate(this.configDomain)
                    .then(res => {
                        this.template = res.data;
                        this.getTemplateHeaders();
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loadingCount--;
                    });
            },
            getColumnData() {
                const returnArr = Array.from({length: this.items[0].length}, () => Object.assign({}, {
                    templateCode: '',
                    is_primary: false
                }));
                this.currentConfig.columns
                    .filter(item => returnArr[item.column])
                    .forEach(item => {
                        returnArr[item.column].is_primary = item.is_primary;
                        returnArr[item.column].templateCode = item.code;
                    });
                this.columnData = returnArr;
            },
            saveConfig() {
                this.contentLoad = true;
                const returnColumns = this.fillColumnData();
                const returnObject = {
                    name: this.configName,
                    first_header_row: parseInt(this.firstRow, 10) + 1,
                    first_data_row: parseInt(this.firstRow, 10) + parseInt(this.headerRows, 10) + 1,
                    columns: returnColumns,
                    parameters: this.parameterValues,
                    import_mode: this.importMode,
                };
                API.updateConfig(this.currentConfig.id, returnObject)
                    .then(() => {
                        this.snack('imports.config.created');
                        this.$emit("config-save", this.currentConfig.id);
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.contentLoad = false;
                    });
            },
            fillColumnData() {
                const returnArr = [];
                this.currentConfig.columns.forEach(column => {
                    const newColumn = this.columnData.find(element => element.templateCode === column.code);
                    if (typeof newColumn !== "undefined") {
                        returnArr.push({
                            code: newColumn.templateCode,
                            column: this.columnData.indexOf(newColumn),
                            primary: newColumn.is_primary,
                        });
                    } else {
                        returnArr.push({
                            code: column.code,
                            column: null,
                            primary: false
                        });
                    }
                });
                return returnArr;
            },
        }
    };
</script>

<style lang="sass" scoped>

.header
  color: var(--v-accent-base) !important

.headerBottom
  border-top: 3px solid var(--v-accent-base)

.headerList
  max-height: 500px
  overflow-y: auto

</style>
