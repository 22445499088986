<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('products.overview.name') }}
      </v-card-title>
    </v-card>
    <template v-if="!loading">
      <masonry
        :cols="$vuetify.breakpoint.lgAndUp ? 2 : 1"
        :gutter="0"
      >
        <div
          v-for="(overview) in detailedOverviews"
          :key="overview.index"
          class="pa-1"
        >
          <v-card outlined>
            <v-card-title
              v-if="stocksWithItem.length !== 0"
              class="body-1"
            >
              {{ $t('products.overview.' + overview.label) }}
            </v-card-title>
            <v-card-title
              v-else
              class="body-1"
            >
              {{ $t('products.overview.' + overview.emptyLabel) }}
            </v-card-title>
            <v-card
              v-for="stock in overview.stocks"
              :key="stock.id"
              class="ma-2"
            >
              <v-card-title class="body-1">
                <span class="font-weight-bold mr-1">{{ overview.getQuantity(stock) }}</span>&nbsp;
                <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                &nbsp;{{ stock.name }}
              </v-card-title>
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="substock in overview.getSubstocks(stock)"
                    :key="'s' + substock.id"
                  >
                    <v-expansion-panel-header>
                      <span>
                        <template v-if="overview.getSubstocks(stock).length !== 0">
                          <span>
                            <span class="font-weight-bold">{{ overview.getSubstockQuantity(substock) }}</span>&nbsp;
                            <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                          </span>
                        </template>
                        {{ substock.name }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-progress-linear
                        v-if="instancesLoading"
                        indeterminate
                      />
                      <ProductStockStatus
                        v-else-if="overview.label === 'inStock'"
                        :product="product"
                        :stock-id="stock.id"
                        :sub-stock-id="substock.id"
                        :instances="instances"
                      />
                      <ProductReservationTasksCard
                        :product-id="product.id"
                        :substock-id="substock.id"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
        <div
          v-for="overview in basicOverviews"
          :key="overview.label"
          class="pa-1"
        >
          <v-card
            outlined
          >
            <v-card-title
              v-if="overview.stocks.length !== 0"
              class="body-1"
            >
              {{ $t('products.overview.' + overview.label) }}
            </v-card-title>
            <v-card-title
              v-else
              class="body-1"
            >
              {{ $t('products.overview.not' + overview.label) }}
            </v-card-title>
            <v-card
              v-for="stock in overview.stocks"
              :key="overview.label + stock.id"
              class="ma-2"
            >
              <v-card-title class="body-1">
                <span class="font-weight-bold mr-1">{{ overview.getQuantity(stock) }}</span>&nbsp;
                <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                &nbsp;{{ stock.name }}
              </v-card-title>
              <v-card-text>
                <v-list class="mt-n4">
                  <template
                    v-for="(substock, index) in overview.getSubstocks(stock)"
                  >
                    <v-list-item
                      :key="'s' + overview.label + substock.id"
                      class="row--dense"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="overview.getSubstocks(stock).length !== 1">
                            <span class="font-weight-bold">{{ overview.getQuantity(substock) }}</span>&nbsp;
                            <span class="caption">{{ product | productMeasureLabel }}{{ ' - ' }}</span>
                          </span>
                          <span>
                            {{ substock.name }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="overview.showMovements">
                        <v-btn
                          :to="movementsLink(stock.id, substock.id)"
                          outlined
                        >
                          <v-icon class="mr-2">
                            $stockMovements
                          </v-icon>
                          <span v-show="$vuetify.breakpoint.mdAndUp">
                            {{ $t('products.pieces.movements') }}
                          </span>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index !== overview.getSubstocks(stock).length - 1"
                      :key="'sD' + substock.id"
                    />
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
      </masonry>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import ProductStockStatus from "@/app/products/components/ProductStockStatus";
    import {ProductAPI} from "@/api/ProductAPI";
    import ProductReservationTasksCard from "@/app/products/components/ProductReservationTasksCard.component";

    export default {
        name: "ProductOverview",
        components: {ProductReservationTasksCard, ProductStockStatus},
        props: {
            product: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            stocks: [],
            loading: false,
            instances: [],
            instancesLoading: false
        }),
        computed: {
            basicOverviews: function () {
                return [
                    {
                        label: 'Ordered',
                        stocks: this.stocksWithOrderedItem,
                        getSubstocks: (stock) => stock.substocksWithOrderedItem,
                        getQuantity: (item) => item.quantity_ordered,
                    }
                ];
            },
            detailedOverviews: function () {
                return [
                    {
                        label: 'inStock',
                        emptyLabel: 'notInStock',
                        stocks: this.stocksWithItem,
                        getSubstocks: (stock) => stock.substocksWithItems,
                        getQuantity: (stock) => stock.pieces,
                        getSubstockQuantity: (substock) => substock.quantity_in_stock
                    },
                    {
                        label: 'InTasks',
                        emptyLabel: 'notInTasks',
                        stocks: this.stocksWithReservedItem,
                        getSubstocks: (stock) => stock.substocksWithReservedItem,
                        getQuantity: (stock) => stock.quantity_reserved,
                        getSubstockQuantity: (substock) => substock.quantity_reserved,
                        showMovements: true
                    },
                ];
            },
            stocksWithItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithItems = stock.substocks.filter(substock => substock.quantity_in_stock);
                        stock.pieces = stock.substocksWithItems.map(substock => substock.quantity_in_stock).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.pieces = 0;
                    }
                    return stock;
                }).filter(stock => stock.pieces);
            },
            stocksWithReservedItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithReservedItem = stock.substocks.filter(substock => substock.quantity_reserved);
                        stock.quantity_reserved = stock.substocksWithReservedItem.map(substock => substock.quantity_reserved).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.quantity_reserved = 0;
                    }
                    return stock;
                }).filter(stock => stock.quantity_reserved);
            },
            stocksWithOrderedItem: function () {
                return this.stocks.map(stock => {
                    if (stock.substocks) {
                        stock.substocksWithOrderedItem = stock.substocks.filter(substock => substock.quantity_ordered);
                        stock.quantity_ordered = stock.substocksWithOrderedItem.map(substock => substock.quantity_ordered).reduce(((acc, cur) => acc + cur), 0);
                    } else {
                        stock.quantity_ordered = 0;
                    }
                    return stock;
                }).filter(stock => stock.quantity_ordered);
            }
        },
        createdOrActivated: function () {
            this.fetchStocks();
            this.instancesLoading = true;
            ProductAPI.getAllInstancesAllPages(this.product.id).then(response => {
                this.instances = response.data.items;
            }).catch(this.snack).finally(() => this.instancesLoading = false);
        },
        methods: {
            fetchStocks: function () {
                this.loading = true;
                const substockPromises = [];
                StockAPI.getAllPages().then(response => {
                    this.stocks = response.data.items;
                    const subStockIds = [];
                    this.stocks.map(stock => {
                        substockPromises.push(
                            StockAPI.getAllSubstockPages(stock.id).then(response => {
                                stock.substocks = response.data.items;
                                subStockIds.push(...response.data.items.map(substock => substock.id));
                                return stock;
                            }));
                    });
                    Promise.all(substockPromises).then(() => {
                        if (subStockIds.length !== 0) {
                            StockStatusAPI.getOrdersForProductsAllPages({
                                filter: APIFilters.makeFilter([
                                    {
                                        [APIFilterOP.EQUALS]: {
                                            'product.id': this.product.id
                                        }
                                    }, {
                                        [APIFilterOP.IN]: {
                                            'substock.id': subStockIds
                                        }
                                    }
                                ])
                            }).then(response => {
                                this.stocks.map(stock => {
                                    stock.substocks.map(subStock => {
                                        const subStockId = subStock.id;
                                        const subStockStatus = response.data.items.find(orderStatus => orderStatus.substock_id === subStockId);
                                        if (!subStockStatus) {
                                            return;
                                        }
                                        subStock.quantity_in_stock = subStockStatus.all_in_stock;
                                        subStock.quantity_ordered = subStockStatus.ordered;
                                        subStock.quantity_reserved = subStockStatus.in_process;
                                    });
                                });
                            }).catch(this.snack)
                                .finally(() => this.loading = false);
                        }
                    }).catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
                }).catch(err => {
                    this.snack(err);
                    this.loading = false;
                });
            },
            movementsLink: function (stockId, subStockId) {
                return '/movements?stock.id=' + stockId + '&substock.id=' + subStockId + '&product.id=' + this.product.id;
            }
        }
    };
</script>

<style scoped>

</style>
