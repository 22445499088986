import {Note} from "@/app/tasks/definitions/taskCommon.render.form";
import {
    StockPickingCreateForm,
    StockPickingCreateRender
} from "@/app/tasks/stockPicking/definitions/stockPickingCreate.form";
import {TaskShippingType} from "@/enum/task_shipping_type";

function ExternalOrderApproveForm() {
    return {
        note: null
    };
}

function ExternalOrderApprovePickingForm() {
    const form = new StockPickingCreateForm(TaskShippingType.COURIER);
    form.shipping_type = TaskShippingType.COURIER;
    return form;
}

function ExternalOrderApproveRender() {
    return {
        note: new Note,
    };
}

function ExternalOrderApprovePickingRender() {
    return new StockPickingCreateRender(TaskShippingType.COURIER);
}

export {
    ExternalOrderApproveForm,
    ExternalOrderApprovePickingForm,
    ExternalOrderApproveRender,
    ExternalOrderApprovePickingRender
};
