import {iconAliases} from "@/enum/icons";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import {en, cs, sk, hu, uk} from "vuetify/lib/locale";
import {themes} from "@/styles/theme";

Vue.use(Vuetify);

const VuetifyInstance = new Vuetify({
    icons: {
        iconfont: 'md',
        values: iconAliases
    },
    lang: {
        locales: {en, cs, sk, hu, uk},
        current: 'cs'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes
    }
});

/**
 * Vuetify's goTo does not seem to work with Router...
 * @param targetId
 */
function scrollTo(targetId) {
    const el = document.getElementById(targetId);
    if (el) {
        window.scrollTo({top: el.offsetTop, behavior: "smooth"});
    }
}

function scrollIntoView(targetId) {
    const el = document.getElementById(targetId);
    if (el) {
        el.scrollIntoView();
    }
}

export {VuetifyInstance as Vuetify, scrollTo, scrollIntoView};
