<template>
  <div
    v-if="filter !== null"
    class="d-flex flex-row flex-wrap flex-gap-8"
  >
    <AutocompleteWithIcons
      v-show="hardcodedAttribute === null"
      :lang-path="langPath"
      :value="attr"
      :items="items"
      @input="propertyChanged"
    />
    <slot name="operator" />
    <template v-if="showAutoComplete">
      <v-autocomplete
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        outlined
        dense
        :rules="[formRules.suppressMessage(formRules.required)]"
        :error-messages="fromInputErrors"
        :items="possibleValues[attr].values || []"
        :label="$t('base.filterConfig.select')"
        suffix="*"
        @input="validateInputs('fromInput')"
      />
      <v-autocomplete
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        outlined
        dense
        :rules="[formRules.suppressMessage(formRules.required)]"
        :error-messages="toInputErrors"
        :items="possibleValues[attr].values || []"
        :label="$t('base.filterConfig.select')"
        suffix="*"
        @input="validateInputs('toInput')"
      />
    </template>
    <template v-else-if="showDatePicker">
      <FormDateTimePicker
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        single
        required
        show-errors
        :rules="[formRules.suppressMessage(formRules.required)]"
        :errors="fromInputErrors"
        :label="$t('base.filterConfig.fillFrom')"
        :prepend-inner-icon="attrIcon"
        @input="validateInputs('fromInput')"
      />
      <FormDateTimePicker
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        single
        required
        show-errors
        :rules="[formRules.suppressMessage(formRules.required)]"
        :errors="toInputErrors"
        :label="$t('base.filterConfig.fillTo')"
        :prepend-inner-icon="attrIcon"
        @input="validateInputs('toInput')"
      />
    </template>
    <template v-else>
      <v-text-field
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][0]
        "
        outlined
        dense
        :rules="[formRules.suppressMessage(formRules.required)]"
        :error-messages="fromInputErrors"
        :type="htmlType"
        :prepend-inner-icon="attrIcon"
        :label="$t('base.filterConfig.fillFrom')"
        suffix="*"
        @input="validateInputs('fromInput')"
      />
      <v-text-field
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          filter[operator][attr][1]
        "
        outlined
        dense
        :rules="[formRules.suppressMessage(formRules.required)]"
        :error-messages="toInputErrors"
        :type="htmlType"
        :prepend-inner-icon="attrIcon"
        :label="$t('base.filterConfig.fillTo')"
        suffix="*"
        @input="validateInputs('toInput')"
      />
    </template>
  </div>
</template>

<script>
    import formRules from "@/utils/formRules";
    import {FilterMixin} from "@/app/mixins/FilterMixin";
    import {APIFilterDataType} from "@/service/APIFilters";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";
    import AutocompleteWithIcons from "@/app/components/filterConfig/components/AutocompleteWithIcons.component.vue";
    import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";
    import {DATETIME_DISPLAY_FORMAT} from "@/utils/datetime";

    export default {
        name: "FilterConfigTernaryOp",
        components: {AutocompleteWithIcons, FormDateTimePicker},
        mixins: [FilterMixin],
        data: () => ({
            formRules: formRules,
            fromInputErrors: [],
            toInputErrors: []
        }),
        computed: {
            attr: function () {
                return Object.keys(this.filter[this.operator])[0];
            },
            attrIcon: function () {
                const item = this.items.find(el => el.value === this.attr);
                return item !== undefined ? item.icon : null;
            },
            attrType: function () {
                return this.possibleValues[this.attr].type;
            },
            showAutoComplete: function () {
                return Array.isArray(this.possibleValues[this.attr].values);
            },
            showDatePicker: function () {
                return this.filters.type === FilterType.API && this.attrType === APIFilterDataType.DATE;
            }
        },
        methods: {
            propertyChanged: function (newProperty) {
                const oldProperty = this.attr;
                const oldValue = this.filter[this.operator][oldProperty];
                delete this.filter[this.operator][oldProperty];

                const oldPossibleValues = this.possibleValues[oldProperty];
                const newPossibleValues = this.possibleValues[newProperty];

                const someHasPossibleValues = oldPossibleValues.values || newPossibleValues.values;
                const attributesAreSameType = oldPossibleValues.type === newPossibleValues.type;
                const valuesAreNotEmpty = !this.isValueEmpty(oldValue[0]) && !this.isValueEmpty(oldValue[1]);
                const valuesAreInGoodOrder = this.fromInputErrors.length === 0 && this.toInputErrors.length === 0;
                if (!someHasPossibleValues && attributesAreSameType && valuesAreNotEmpty && valuesAreInGoodOrder) {
                    // Neither property has possible values, types are the same, the values are not empty and are in good order -> carry over the values
                    this.$set(this.filter[this.operator], newProperty, oldValue);
                } else {
                    // Set the default value for the new property
                    const defaultValue = this.getDefaultDataTypeValue(newProperty);
                    this.fromInputErrors = [];
                    this.toInputErrors = [];
                    this.$set(this.filter[this.operator], newProperty, [defaultValue, defaultValue]);
                }
            },
            isFromBeforeOrEqualTo: function () {
                if (this.attrType === APIFilterDataType.DATE) {
                    const from = this.filter[this.operator][this.attr][0];
                    const to = this.filter[this.operator][this.attr][1];
                    if (this.isValueEmpty(from) || this.isValueEmpty(to) || this.$moment(from).isSameOrBefore(this.$moment(to))) {
                        this.fromInputErrors = [];
                    } else {
                        this.fromInputErrors = [this.$t('base.filterConfig.fromInputError', [this.$moment(to).format(DATETIME_DISPLAY_FORMAT)])];
                    }
                } else if (this.attrType === APIFilterDataType.NUMBER) {
                    const from = Number.parseFloat(this.filter[this.operator][this.attr][0]);
                    const to = Number.parseFloat(this.filter[this.operator][this.attr][1]);
                    if (isNaN(from) || isNaN(to) || from <= to) {
                        this.fromInputErrors = [];
                    } else {
                        const hasPossibleValues = Array.isArray(this.possibleValues[this.attr].values);
                        this.fromInputErrors = [this.$t('base.filterConfig.fromInputError', [
                            hasPossibleValues ? this.possibleValues[this.attr].values.find(val => val.value === to).text : to
                        ])];
                    }
                } else {
                    console.warn(`Unsupported data type in isFromBeforeOrEqualTo: ${this.attrType}`);
                }
            },
            isToAfterOrEqualFrom: function () {
                if (this.attrType === APIFilterDataType.DATE) {
                    const from = this.filter[this.operator][this.attr][0];
                    const to = this.filter[this.operator][this.attr][1];
                    if (this.isValueEmpty(from) || this.isValueEmpty(to) || this.$moment(to).isSameOrAfter(this.$moment(from))) {
                        this.toInputErrors = [];
                    } else {
                        this.toInputErrors = [this.$t('base.filterConfig.toInputError', [this.$moment(from).format(DATETIME_DISPLAY_FORMAT)])];
                    }
                } else if (this.attrType === APIFilterDataType.NUMBER) {
                    const from = Number.parseFloat(this.filter[this.operator][this.attr][0]);
                    const to = Number.parseFloat(this.filter[this.operator][this.attr][1]);
                    if (isNaN(from) || isNaN(to) || to >= from) {
                        this.toInputErrors = [];
                    } else {
                        const hasPossibleValues = Array.isArray(this.possibleValues[this.attr].values);
                        this.toInputErrors = [this.$t('base.filterConfig.toInputError', [
                            hasPossibleValues ? this.possibleValues[this.attr].values.find(val => val.value === from).text : from
                        ])];
                    }
                } else {
                    console.warn(`Unsupported data type in isToAfterOrEqualFrom: ${this.attrType}`);
                }
            },
            validateInputs: function (input) {
                if (input === 'fromInput') {
                    this.isFromBeforeOrEqualTo();
                    if (this.fromInputErrors.length !== 0) {
                        this.toInputErrors = [];
                    }
                    if (this.toInputErrors.length !== 0) {
                        this.isToAfterOrEqualFrom();
                    }
                } else {
                    this.isToAfterOrEqualFrom();
                    if (this.toInputErrors.length !== 0) {
                        this.fromInputErrors = [];
                    }
                    if (this.fromInputErrors.length !== 0) {
                        this.isFromBeforeOrEqualTo();
                    }
                }
            }
        }
    };
</script>

<style scoped>

</style>
