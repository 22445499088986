import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";

export function externalOrders() {
    return {
        apiDataSource: TaskExternalOrderAPI.getAll.bind(TaskExternalOrderAPI),
        apiSort: {created_at: 'DESC'},
        resultTransform: el => {
            el.value = el.id;
            el.text = `#${el.id}`;
            return el;
        }
    };
}