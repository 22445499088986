const importNotFound = "Запитаний імпорт не знайдено";
export default {
    title: "Імпорт",
    imports: "Імпорти",
    link: "Імпорти",
    create: "Створити імпорт",
    deleted: "Імпорт видалено",
    file: "Файл",
    file_info_create: "Файл, який використовується для створення шаблону для майбутніх імпортів",
    file_info_use: "Введіть файл, з якого будуть імпортовані дані. Введіть файл з відповідним форматом даних відповідно до обраного шаблону.",
    domain: "Цільова область даних",
    domain_info: "Область даних, в яку будуть імпортовані дані за допомогою цього шаблону. Виберіть область даних, куди ви хочете імпортувати дані з використанням цього шаблону в майбутньому.",
    choose_file: "Виберіть файл",
    process: "Обробити імпорт",
    process_started: "Імпорт був відправлений на обробку",
    processed: "Імпорт оброблено",
    download_imported_file: "завантажити даний файл",
    download_error: 'Не вдалося завантажити користувацький файл',
    array_buffer_error: 'Не вдалося конвертувати користувацький файл',
    viewImport: "Переглянути імпорт",
    configuration: "Налаштування імпорту",


    stepper: {
        first_step: "Створити шаблон",
        second_step: "Підготувати імпорт",
        second_step_back: "Видалити та підготувати новий імпорт",
        third_step: "Перевірка та імпорт",
    },

    hints: {
        parameters_hint: "Параметри імпорту дозволяють вказати додаткову інформацію, необхідну для імпортованих даних.",
        header_rows: "Встановіть кількість заголовкових рядків у вашому файлі",
        first_row: "Якщо файл містить дані поза імпортом, встановіть перший рядок для імпорту",
        first_column: "Якщо файл містить дані поза імпортом, встановіть перший стовпець для імпорту",
        required_attributes: "Усі обов'язкові атрибути повинні бути відображені в таблиці нижче. Якщо неможливо звільнити всі обов'язкові атрибути з вашого файлу, можливо, вибрана область даних несумісна",
        mapping: "Співставте свій файл з обраною областю даних, натиснувши на його заголовок та вибравши цільовий атрибут. При співставленні виберіть також стовпці для використання як компаратори під час імпорту",
        primary_key: "Цей стовпець встановлено як атрибут порівняння",
        easy_mode: "У цьому режимі ви можете додавати лише нові елементи у вибрану цільову область даних",
        latest_on_duplicity: "Якщо при ідентифікації запису знайдено декілька відповідних записів, використовується останній створений запис",
        fileTemplate: "Завантажити шаблон для файлу імпорту Цільова область даних"
    },

    rules: {
        file_type: "Непідтримуваний тип файлу",
        multiple_sheets: "Вибраний файл має більше однієї аркуша",
        cannot_access: "Не вдається відкрити вибраний файл"
    },

    config: {
        title: "Шаблон імпорту",
        templates: "Шаблони імпорту",
        create: "Створити шаблон імпорту",
        edit: "Дублювати та редагувати шаблон",
        view: "Переглянути шаблон",
        created: "Шаблон був створено",
        save: "Зберегти шаблон",
        save_new: "Зберегти новий шаблон",
        deleted: "Шаблон імпорту видалено",
        easyMode: "Режим простоти",
        latestOnDuplicity: 'Ігнорувати подвійність',

        name: "Назва шаблону",
        domain: "Цільова область даних шаблону",
        file: "Файл",
        defaultParameters: "Параметри шаблону за замовчуванням",
        parameters: "Параметри імпорту",
        required_attributes: "Обов'язкові атрибути",
        mapping: "Відображення стовпців",

        firstRow: "Перший рядок",
        firstColumn: "Перший стовпець",
        headerRows: "Рядки заголовка",

        unmap: "скасувати вибір",
        not_mapped: "не відображено",
        primary_set: "Встановити як атрибут порівняння",
        primary_unset: "Скасувати встановлення атрибута порівняння",
        empty_error: "Конфігурація не має імпорту, з якого були б взяті посилання на дані",
    },
    info: {
        title: "Інформація про імпорт",
        hint: "Інформація про створений імпорт обчислюється, ця дія може зайняти кілька хвилин.",
        include: "Імпорт включатиме: ",
        error: "Обробка цього імпорту призведе до наступної помилки: ",
        created: 'Створить рядки',
        updated: 'Оновить рядки',
        warn: "Попередження",

        primary_key_toggle_warn: "Головна група повинна мати принаймні один атрибут порівняння",
        primary_key_group_warn: "У групах {0} відсутній хоча б один атрибут порівняння",
        fake_file_warn: "Шаблон показує зразковий файл, реальний файл не був наданий або не відповідає умовам",
        mapping_warn: "Якщо у вас є атрибути, які не відображено, це означає, що ваш шаблон та обраний файл несумісні.",
        mapping_warn_create: "Ви не відобразили всі обов'язкові атрибути, які вимагаються вибраною цільовою областю даних.",
        parameters_warn: "Необхідно заповнити всі необхідні параметри",
        change_value_warn: "Зміна цієї вартості призведе до втрат поточного відображення",
        edit_values_warn: "Обробка цього імпорту змінить існуючі дані. Хочете продовжити?",
        short_file_warn: "Кількість стовпців у завантаженому файлі замала для поточного шаблону.",
        delete_import: "Ви хочете зберегти невдалий імпорт?",

    },

    errors: {
        'import': {
            status: {
                404: importNotFound,
            }
        },
        importCreate: {
            status: {
                409: 'Не вдалося створити імпорт'
            }
        },
        importDelete: {
            status: {
                404: importNotFound,
                409: "Не вдалося видалити імпорт"
            }
        },
        importUpdate: {
            status: {
                404: importNotFound,
                409: 'Не вдалося оновити імпорт'
            }
        },
        importProcess: {
            status: {
                404: importNotFound,
                409: "Не вдалося відправити імпорт на обробку."
            }
        },
        domainTemplate: {
            status: {
                404: "Не вдалося знайти дані для вибраного джерела даних",
            }
        },
    }
};
