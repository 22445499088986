<template>
  <div>
    <x-toolbar />
    <v-container fluid>
      <v-card
        class="mb-2"
      >
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <FormFields
              :form="form"
              :render="render"
              lang-path="base.shipping.handover.create.form."
            />
            <x-data-table
              v-if="form.selectShipments && form.subStockId"
              :headers="headers"
              :items="shipments"
              :loading="loading"
              show-select
              :search-hint="$t('base.shipping.table.searchHint')"
              @update:selection="selection => shipmentIds = selection"
            >
              <template #item.last_protocol_created_at="{ item }">
                <DateTimeWithTooltip
                  :date-time="item.last_protocol_created_at"
                  abs-date-first
                />
              </template>
              <template #item.external_order_id="{ item }">
                <span @click.stop>
                  <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_id">
                    #{{ item.external_order_id }}
                  </router-link>
                </span>
              </template>
              <template #item.stock_picking_id="{ item }">
                <span @click.stop>
                  <router-link :to="taskLinks[taskTypes.STOCK_PICKING] + '/' + item.stock_picking_id">
                    #{{ item.stock_picking_id }}
                  </router-link>
                </span>
              </template>
              <template #item.buyer.name="{ item }">
                <span @click.stop>
                  <router-link :to="'/buyers/' + item.buyer.id">
                    {{ item.buyer.name }}
                  </router-link>
                </span>
              </template>
            </x-data-table>
            <v-flex
              xs12
            >
              <v-tooltip
                :disabled="!form.carrierId || !form.stockId || stockShipmentsWaiting"
                bottom
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      :text="!valid"
                      :loading="loading"
                      type="submit"
                      color="accent"
                      :disabled="!loading && form.carrierId && form.stockId && !stockShipmentsWaiting"
                      @click="printProtocols()"
                    >
                      <v-icon
                        class="mr-2"
                      >
                        $printItem
                      </v-icon>
                      {{ $t('base.shipping.handover.printProtocol') }}
                    </v-btn>
                  </span>
                </template>
                <span>
                  {{ $t('base.shipping.handover.noStockShipmentsWaiting') }}
                </span>
              </v-tooltip>
            </v-flex>
          </v-form>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        ShippingHandoverForm,
        ShippingHandoverRender
    } from "@/app/overview/shipping/definitions/shippingHandover.form";
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {ShipmentState} from "@/enum/shipment_state";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import * as Export from "@/service/Export";
    import {createHeaders} from "@/utils/table";
    import {shippingTable} from "@/app/overview/shipping/definitions/shipping.form";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {ShipmentProtocolMixin} from "@/app/mixins/ShipmentProtocolMixin";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "ShippingHandover",
        components: {FormFields, DateTimeWithTooltip},
        mixins: [ShipmentProtocolMixin, RouteParamsMapperMixin],
        data: () => ({
            form: new ShippingHandoverForm(),
            render: new ShippingHandoverRender(true),
            valid: true,
            loading: false,
            shipments: [],
            shipmentIds: [],
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            formUpdated: function () {
                this.form.carrierId;
                this.form.stockId;
                this.form.subStockId;
                this.form.selectShipments;
                return Date.now();
            },
            headers: function () {
                return createHeaders(shippingTable(true), 'base.shipping.table.', false);
            },
            stockShipmentsWaiting: function () {
                const carrierSubStocks = this.subStocks[this.form.carrierId];
                if (!carrierSubStocks) {
                    return null;
                }
                const carrierSubStocksForStock = carrierSubStocks[this.form.stockId];
                if (carrierSubStocksForStock === null || carrierSubStocksForStock === undefined) {
                    return null;
                }
                return carrierSubStocksForStock.length !== 0;
            }
        },
        watch: {
            valid: function () {
                if (this.valid && this.form.selectShipments && this.shipmentIds.length === 0) {
                    this.valid = false;
                }
            },
            formUpdated: function () {
                this.fetchShipments();
            },
            'form.carrierId': function (newValue) {
                this.getSubStocksForCarrier(newValue);
            },
            'form.stockId': function (newValue) {
                if (newValue) {
                    this.fetchSubStocks();
                    this.getSubStocksForCarrier(this.form.carrierId);
                }
            },
            'form.selectShipments': function (newValue) {
                if (!newValue) {
                    this.shipmentIds = [];
                } else {
                    this.valid = false;
                }
            },
            shipmentIds: function () {
                if (!this.form.selectShipments || this.shipmentIds.length !== 0) {
                    this.valid = this.$refs.form.validate();
                } else {
                    this.valid = false;
                }
            }
        },
        createdOrActivated: function () {
            this.fetchCarriers();

            if (this.carrierId !== undefined) {
                this.form.carrierId = this.carrierId;
            }

            if (this.stockId !== undefined) {
                if (this.stockId === this.form.stockId) {
                    this.fetchSubStocks();
                    this.getSubStocksForCarrier(this.form.carrierId);
                }
                this.form.stockId = this.stockId;
            } else if (this.form.stockId) {
                this.fetchSubStocks();
                this.getSubStocksForCarrier(this.form.carrierId);
            }

            if (this.subStockId !== undefined) {
                this.form.subStockId = this.subStockId;
            }
        },
        methods: {
            fetchCarriers: function () {
                this.$set(this.render.carrierId, 'loading', true);
                ShipmentCarrierAPI.getAll().then(response => {
                    const carriersWithWaitingShipments = [];
                    const promises = [];
                    response.data.forEach(carrier => {
                        promises.push(
                            ShipmentAPI.getAll({
                                itemsPerPage: 1,
                                filter: this.getShipmentsFilter(carrier.id)
                            }).then(response => {
                                if (response.data.item_count > 0) {
                                    carriersWithWaitingShipments.push(carrier);
                                }
                            })
                        );
                    });
                    Promise.all(promises).then(() => {
                        carriersWithWaitingShipments.sort((a, b) => a.id - b.id);
                        this.render.carrierId.autocomplete.items = carriersWithWaitingShipments.map(carrier => ({
                            text: carrier.name,
                            value: carrier.id
                        }));
                    }).catch(this.snack)
                        .finally(() => this.$set(this.render.carrierId, 'loading', false));
                }).catch(err => {
                    this.snack(err);
                    this.$set(this.render.carrierId, 'loading', false);
                });
            },
            fetchShipments: function () {
                if (!this.form.selectShipments || !this.form.carrierId || !this.form.subStockId) {
                    return;
                }
                this.loading = true;
                return ShipmentAPI.getAllPages({
                    filter: this.getShipmentsFilter(this.form.carrierId, this.form.subStockId),
                    sort: APIFilters.makeSort({created_at: 'DESC'})
                }).then(response => {
                    this.shipments = response.data.items;
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            getShipmentsFilter: function (carrierId, subStockId = null, noProtocolsYet = false) {
                const filter = [
                    {
                        [APIFilterOP.EQUALS]: {
                            state: ShipmentState.WAITING_FOR_PICKUP
                        }
                    },
                    {
                        [APIFilterOP.IS_NULL]: 'carrier_api_error_messages'
                    },
                    {
                        [APIFilterOP.IS_NOT_NULL]: 'last_validated'
                    },
                    {
                        [APIFilterOP.EQUALS]: {
                            'carrier.id': carrierId
                        }
                    }
                ];
                if (subStockId) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            'subordinate_stock.id': subStockId
                        }
                    });
                }
                if (noProtocolsYet) {
                    filter.push({
                        [APIFilterOP.IS_NULL]: 'last_protocol_created_at'
                    });
                }
                return APIFilters.makeFilter(filter);
            },
            printProtocols: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                const carrierId = this.form.carrierId;
                const stockId = this.form.stockId;
                let subStocks = this.subStocks[carrierId][stockId];
                let chosenSubStock = null;
                if (this.form.subStockId) {
                    chosenSubStock = this.subStocks[carrierId][stockId].find(substock => substock.value === this.form.subStockId);
                    subStocks = [chosenSubStock];
                }
                this.loading = true;
                let success = true;
                const shipmentPromises = [];
                const protocolCreatePromises = [];
                const protocolPrintPromises = [];
                subStocks.forEach(substock => {
                    let promise = Promise.resolve();
                    if (!this.shipmentIds.length) {
                        // no shipments manually chosen
                        promise = ShipmentAPI.getAllPages({
                            filter: this.getShipmentsFilter(carrierId, substock.value, true)
                        });
                        shipmentPromises.push(promise);
                    }
                    promise.then(response => {
                        let shipmentIds = this.shipmentIds;
                        if (!this.shipmentIds.length) {
                            if (response.data.item_count > 0) {
                                shipmentIds = response.data.items.map(shipment => shipment.id);
                            } else {
                                this.snack('base.shipping.handover.noShipmentsWithoutProtocol');
                            }
                        }
                        if (shipmentIds.length) {
                            protocolCreatePromises.push(
                                ShipmentAPI.createProtocol(carrierId, substock.ownerId, substock.value, shipmentIds)
                                    .then(response => {
                                        const protocolId = response.headers.location.split('/').pop();
                                        protocolPrintPromises.push(
                                            ShipmentAPI.getProtocol(carrierId, substock.ownerId, protocolId).then(response => {
                                                Export.print(response.data.protocol_link, 'carrier_' + carrierId + '_substock' + substock.value + '_shipment_protocol');
                                            })
                                        );
                                    }).catch(err => {
                                        this.snack(err);
                                        success = false;
                                    })
                            );
                        }
                    }).catch(err => {
                        this.snack(err);
                        success = false;
                    });
                });
                Promise.allSettled(shipmentPromises).then(() => {
                    Promise.allSettled(protocolCreatePromises).then(() => {
                        Promise.allSettled(protocolPrintPromises).then(protocolPrintResults => {
                            for (const result of protocolPrintResults) {
                                if (result.status === 'rejected') {
                                    // fail for concrete substock or some substock from selected stock
                                    success = false;
                                    const err = result.reason;
                                    this.snack(err);
                                    if (typeof err === 'string' && Number.parseInt(err.slice(-3)) === 423) {
                                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                        const subStockId = this.form.subStockId;
                                        this.$router.push('/shipping/protocols?carrierId=' + carrierId + '&stockId=' + stockId
                                            + (subStockId ? '&subStockId=' + subStockId : ''));
                                    }
                                    break;
                                }
                            }
                            if (success) {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/shipping/protocols');
                            }
                            this.loading = false;
                        });
                    });
                });
            }
        }
    };
</script>

<style scoped>

</style>
