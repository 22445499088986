import {ProductAPI as API} from "@/api/ProductAPI";
import * as Export from "@/service/Export";

/**
 * Requires:
 * - this.loading
 * - this.barcodeModal
 * - this.product.id
 * - this.instanceId
 * - this.modalItem
 * - this.printType
 * - this.barcodeCount
 *
 */
const ProductBarcodePrintMixin = {
    methods: {
        printBarcode: function (item) {
            this.modalItem = item;
            this.barcodeModal = true;
        },
        reallyPrintBarcode: function () {
            this.loading = true;
            this.barcodeModal = false;
            let promise = Promise.resolve();
            if (this.modalItem !== null) {
                const item = this.modalItem;
                API.printInstanceBarcode(this.product.id, this.instanceId, item.code, this.printType, this.barcodeCount)
                    .then(response => {
                        const fileExtension = this.printType;
                        Export.print(response.data.url, 'code_' + item.code + '_' + this.barcodeCount + '.' + fileExtension, fileExtension);
                    });
            } else if (this.modalCodes && this.modalCodes.length !== 0) {
                    const codesToPrint = this.modalCodes.map(code => ({
                        code: code,
                        amount: 1
                    }));
                    API.printInstanceBarcodes(this.printType, codesToPrint, this.barcodeCount)
                        .then(response => {
                            const fileExtension = this.printType;
                            Export.print(response.data.url, 'codes' + '.' + fileExtension, fileExtension);
                        });
            }
            promise.then(() => {
                this.modalItem = null;
                this.snack('base.print.sent');
                this.barcodeCount = 1;
                this.modalCodes = [];
            }).catch(this.snack)
                .finally(() => this.loading = false);
        }
    }
};

export {ProductBarcodePrintMixin};