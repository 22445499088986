import {BarcodeAPI} from "@/api/BarcodeAPI";
import {Store} from "@/service/store/Store";
import {ReactiveBarcodeCacheMixin} from "@/app/mixins/ReactiveBarcodeCacheMixin";
import {readerFeedback} from "@/utils/readerFeedback";

export const BarcodeReaderParseMixin = {
    mixins: [ReactiveBarcodeCacheMixin],
    methods: {
        onBarcodeRead: function (code, quantity = 1) {
            this.readerInputReset();
            const codes = code.split(/[\s,;]/)
                .map(code => code.trim())
                .filter(code => !!code);
            if (codes.length <= 1) {
                this.processAsCodes(codes, quantity);
            } else {
                this.quantityCheck(codes[1])
                    .then(res => {
                        if (res) {
                            this.processAsCodesAndQuantities(codes, quantity);
                        } else {
                            this.processAsCodes(codes, quantity);
                        }
                    }).catch(err => {
                    this.handleBarcodeReaderError(err.message, err.params);
                });
            }
        },
        readerInputReset: function (codeToSet = null) {
            this.readerInput = codeToSet;
            this.unknownCode = false;
            this.readerInputErrors = [];
        },
        handleBarcodeReaderError: function (untranslatedMessage = null, messageParam = []) {
            this.unknownCode = true;
            this.readerLastScanned = null;
            readerFeedback.error();
            untranslatedMessage && (this.readerInputErrors = [this.$t(untranslatedMessage, messageParam)]);
        },
        async quantityCheck(code) {
            if (!/^[0-9]+$/.test(code)) {
                return false;
            }
            let isQuantity = false;
            await BarcodeAPI.decode(code)
                .catch(err => {
                    if (err.split('.').pop() === '404') {
                        if (code.length > 7) {
                            throw ({
                                message: 'form.maxInteger',
                                params: ['9999999']
                            });
                        }
                        return isQuantity = true;
                    }
                });
            return isQuantity;
        },
        processAsCodes(codes, quantity) {
            codes.forEach(code => {
                this.cacheBarcode(BarcodeAPI.decode(code), code).catch(this.snack);
                if (this.barcodeLoading) {
                    Store.dispatch('barcodeReaderQueue/push', {code, quantity});
                } else {
                    this.acceptBarcode({code, quantity});
                }
            });
        },
        async processAsCodesAndQuantities(codes) {
            let currentIndex = 0;
            const pairs = [];
            while (currentIndex < codes.length) {
                const pair = {
                    code: codes[currentIndex],
                    quantity: 1
                };
                if (currentIndex < codes.length - 1) {
                    await this.quantityCheck(codes[currentIndex + 1])
                        .then(res => {
                            if (res) {
                                pair.quantity = parseInt(codes[currentIndex + 1]);
                                currentIndex++;
                            }
                        }).catch(err => {
                            this.handleBarcodeReaderError(err.message, err.params);
                        });
                }
                pairs.push(pair);
                currentIndex++;
            }
            pairs.forEach(pair => {
                this.cacheBarcode(BarcodeAPI.decode(pair.code), pair.code).catch(this.snack);
                if (this.barcodeLoading) {
                    Store.dispatch('barcodeReaderQueue/push', pair);
                } else {
                    this.acceptBarcode(pair);
                }
            });
        },
    }
};
