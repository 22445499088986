const themes = {
    light: {
        primary: '#009688',
        secondary: '#e91e63',
        accent: '#ffc107',
        muted: '#959595',

        error: '#d32f2f',
        success: '#4caf50',

        priorityLow: '#94ecb5',
        priorityNormal: '#ffd026',
        priorityHigh: '#ff7c94',

        priorityTextLow: '#00b114',
        priorityTextHigh: '#b10000',

        moveOut: '#e91e63',
        moveIn: '#009688',
        move: '#ffc107',

        quantityIncrease: '#c8e6c9',
        quantityDecrease: '#ffcdd2',

        background: '#f3f3f3',
        tableBackground: '#B2DFDB',

        mapToolbarBackground: '#ffffff',
        mapViewBackground: '#ffffff',
        mapSelected: '#c57af1',
        mapRack: '#009688',
        mapLocationsGroup: '#009688',
        mapAisle: '#e7e7e7',
        mapObstacle: '#959595',
        mapZone: '#FFCC80',
        mapZoneHover: '#ffdca9',
        mapCellBorder: '#ffffff',
        rackCellBorder: '#6e6e6e',
        heatMapWhiteObject: '#ffffff',
        mapFont: '#000000',

        mapDelete: '#be5f5f',
        mapStorageSelected: '#ba5ee8',
        mapAisleSelected: '#d4aae0',
        mapBlockedSelected: '#a484ae',
        mapStorageDelete: '#00433d',
        mapAisleDelete: '#b36868',
        mapBlockedDelete: '#6f4141',
        mapPackaging: '#dcb78b',
        mapStartingPoint: '#6bc190',
        mapPickup: '#9572d4',
        mapNone: '#ffffff',
        mapNewObjectButtonIcon: '#000000',
        mapObjectActionButton: '#181818',
        mapObjectActionButtonIcon: '#ffffff',
        mapDeleteObjectButton: '#d32f2f',
        mapRackHeight: '#000000',
        locationMapped: '#009688',
        locationNotMapped: '#006e96',
        locationDuplicit: '#d32f2f',
        locationToCreate: '#959595',
    },
    dark: {
        primary: '#009688',
        secondary: '#c71e57',
        accent: '#c59400',
        muted: '#565656',

        error: '#d32f2f',
        success: '#4caf50',

        priorityLow: '#94ecb5',
        priorityNormal: '#ffd026',
        priorityHigh: '#ff7c94',

        priorityTextLow: '#00b114',
        priorityTextHigh: '#ff4d4d',

        moveOut: '#c71e57',
        moveIn: '#009688',
        move: '#c59400',

        quantityIncrease: '#1b5e20',
        quantityDecrease: '#b71c1c',

        background: '#313131',

        mapToolbarBackground: '#1e1e1e',
        mapViewBackground: '#1e1e1e',
        mapSelected: '#ac53b2',
        mapRack: '#009688',
        mapLocationsGroup: '#009688',
        mapAisle: '#33373a',
        mapObstacle: '#a9a9a9',
        mapZone: '#ac6800',
        mapZoneHover: '#9a7031',
        mapCellBorder: '#2a2d2f',
        rackCellBorder: '#c9c9c9',
        heatMapWhiteObject: '#ffffff',
        mapFont: '#ffffff',

        mapDelete: '#932626',
        mapStorageSelected: '#ac53b2',
        mapAisleSelected: '#989593',
        mapBlockedSelected: '#6b757c',
        mapStorageDelete: '#42283f',
        mapAisleDelete: '#7d3f3f',
        mapBlockedDelete: '#593434',
        mapPackaging: '#806231',
        mapStartingPoint: '#206827',
        mapPickup: '#371d5a',
        mapNone: '#423131',
        mapNewObjectButtonIcon: '#000000',
        mapObjectActionButton: '#737373',
        mapObjectActionButtonIcon: '#000000',
        mapDeleteObjectButton: '#d32f2f',
        mapRackHeight: '#000000',
        locationMapped: '#009688',
        locationNotMapped: '#1e6298',
        locationDuplicit: '#d32f2f',
        locationToCreate: '#959595',
    }
};

// Vuetify does not support alpha channel for theme colors, so we need this workaround
const activeCardColor = 'rgba(0, 150, 136, 0.1)';

export {themes, activeCardColor};
