export const carrierFabActions = (carrierId, image, foilImage) => {
    const addImage = {
        icon: '$addImage',
        label: 'base.images.create.titleSimple',
        to: '/carriers/' + carrierId + '/image/create'
    };
    const addFoilImage = {
        icon: '$addFoilImage',
        label: 'carriers.images.create.foil.titleSimple',
        to: '/carriers/' + carrierId + '/foil-image/create'
    };
    const updateImage = {
        icon: '$updateItem',
        subscriptIcon: '$image',
        label: 'base.images.update.titleSimple',
        to: '/carriers/' + carrierId + '/image/update'
    };
    const updateFoilImage = {
        icon: '$updateItem',
        subscriptIcon: '$foilImage',
        label: 'carriers.images.update.foil.titleSimple',
        to: '/carriers/' + carrierId + '/foil-image/update'
    };

    const actions = [
        {
            icon: '$carrierService',
            label: 'carriers.services.create.title',
            to: '/carriers/' + carrierId + '/services/create'
        }
    ];
    image ? actions.push(updateImage) : actions.push(addImage);
    foilImage ? actions.push(updateFoilImage) : actions.push(addFoilImage);

    return actions;
};
