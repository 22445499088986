<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-form>
        <v-container fluid>
          <FormFields
            :form="form"
            :render="render"
            lang-path="base.reports."
          >
            <template #domain-item="{item}">
              <v-icon class="pr-2">
                {{ domainIcons[item.value] }}
              </v-icon>
              {{ item.text }}
            </template>
          </FormFields>
        </v-container>
      </v-form>
    </v-card>
    <ShippingReportComponent v-if="form.type === reportsNames.SHIPPING_REPORT" />
    <StockLocationsStatusComponent v-else-if="form.type === reportsNames.STOCK_LOCATION" />
    <StockBuyPricesBundleComponent v-else-if="form.type === reportsNames.STOCK_BUY_PRICES" />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {ReportsFormRender, ReportsForm} from "@/app/overview/reports/definitions/reports.form";
    import ShippingReportComponent from "@/app/overview/reports/components/ShippingReport.component";
    import StockLocationsStatusComponent from "@/app/overview/reports/components/StockLocationsStatus.component";
    import {domainIcons, reportsNames} from "@/enum/reports_type";
    import {ReportDomains} from "@/app/overview/reports/definitions/reports.definitions";
    import StockBuyPricesBundleComponent from "@/app/overview/reports/components/StockBuyPricesBundle.component";

    export default {
        name: "Reports",
        components: {StockBuyPricesBundleComponent, StockLocationsStatusComponent, ShippingReportComponent, FormFields},
        data: () => ({
            form: ReportsForm(),
            render: ReportsFormRender(),
            reportsNames: reportsNames,
            domainIcons: domainIcons
        }),
        watch: {
            'form.domain'(newVal) {
                this.form.type = null;
                this.render.type.autocomplete.items = ReportDomains.find(item => item.value === newVal).reports
                    .map(item => ({
                        text: this.$t(item.text),
                        value: item.value
                    }));
            }
        }
    };
</script>

<style scoped>

</style>
