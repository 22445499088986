const LiveTimeTrackerMixin = {
    data: () => ({
        update: 0,
        intervalHandle: null
    }),
    computed: {
        isRunning: function () {
            return this.$store.getters['time/isRunning'];
        },
        elapsed: function () {
            this.update; // force update
            return this.$store.getters['time/current'](Date.now());
        }
    },
    watch: {
        isRunning: function () {
            if (this.isRunning) {
                this.startAutoUpdate();
            } else {
                this.stopAutoUpdate();
            }
        }
    },
    created: function () {
        if (this.isRunning) {
            this.startAutoUpdate();
        }
    },
    deactivated: function () {
        this.stopAutoUpdate();
    },
    destroyed: function () {
        this.stopAutoUpdate();
    },
    methods: {
        startAutoUpdate: function () {
            this.stopAutoUpdate();
            this.intervalHandle = setInterval(() => {
                this.update++;
            }, 1000);
        },
        stopAutoUpdate: function () {
            if (this.intervalHandle !== null) {
                clearInterval(this.intervalHandle);
                this.intervalHandle = null;
            }
        }
    }
};

export {LiveTimeTrackerMixin};
