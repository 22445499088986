export default {
    warehouseMap: "Raktári térkép",
    usageHeatMap: "Raktárhelyhasználati hőtérkép",
    backToMap: "Vissza a térképhez",
    mapEditor: "Raktári térképszerkesztő",
    rack: "Rack",
    obstacle: "Akadály",
    location: "Helyszín",
    locations: "Helyek",
    zone: "Zóna",
    shelf: "Polc",
    shelfCount: "Polcok száma",
    column: "Oszlop",
    cell: "Cell",
    countInCell: "Sorrend a cellában",
    firstSymbol: "Első szimbólum",
    secondSymbol: "Második szimbólum",
    thirdSymbol: "Harmadik szimbólum",
    firstSymbolValue: "Szimbólum",
    secondSymbolValue: "Szimbólum",
    thirdSymbolValue: "Szimbólum",
    firstStartingSymbol: "Kezdőszimbólum",
    secondStartingSymbol: "Kezdőszimbólum",
    thirdStartingSymbol: "Kezdőszimbólum",
    startingSymbol: "Kezdőszimbólum",
    numbering: "Számozás",
    nameingDirection: "Elnevezési irány",
    startingSymbolColumn: "Kezdő szimbólum",
    numberingColumn: "Oszlopok",
    startingSymbolShelf: "Kezdő szimbólum",
    numberingShelf: "Polcok",
    separators: "Elválasztók",
    firstSeparator: "Első elválasztó",
    secondSeparator: "Második elválasztó",
    thirdSeparator: "Harmadik elválasztó",
    actions: "Műveletek",
    undefined: "Undefined",
    multipleValues: "Több érték",
    untitled: "Névtelen",
    width: "Szélesség",
    height: "Magasság",
    mapWidth: "Térkép szélessége",
    mapHeight: "Térkép magasság",
    depth: "Mélység",
    general: "Általános",
    naming: "Névrendszer",
    dimensions: "Méretek",
    stockObjects: "Raktári objektumok",
    racks: "Állványok",
    locationGroups: "Helyek",
    zones: "Zónák",
    obstacles: "Akadályok",
    detail: "Részlet",
    remove: "Törlés",
    rename: "Átnevezés",
    newName: "Új név",
    advancedSettings: "Beállítások",

    objectWidth: "Szélesség (oszlopok száma)",
    objectHeight: "Magasság (sorok száma)",
    rackShelves: "Magasság (polcok száma)",
    locationsOnPosition: "Helyek a pozícióban",
    locationName: "A helyszín neve",
    oneZoneTypes: "Zónatípusok",

    dimension: "Dimension",
    newDimension: "Új dimenzió",
    selectedLocations: "Kiválasztott helyek",
    selectedCells: "Kiválasztott cellák",
    count: "Count",
    locationCount: "Helyek száma",
    cellCount: "Cellák száma",
    assign: "Hozzárendelés",
    moreValues: "További értékek",
    withoutDimensions: "Méretek nélkül",

    bottomToTop: "Alulról felfelé",
    topToBottom: "Felülről lefelé",
    leftToRight: "Balról jobbra",
    rightToLeft: "Jobbról balra",

    noMaps: "Még nem hozott létre térképeket.",
    createMap: "Új térkép létrehozása",
    goBack: "Vissza az áttekintéshez",
    goBackAnyway: "Változtatások elvetése és távozás",
    stay: "Maradj",
    unsavedChanges1: "Biztosan ki akar lépni?",
    unsavedChanges2: "Vannak el nem mentett módosításai, amelyek kilépés után el lesznek vetve.",
    deleteConfirm1: "Biztosan törli ezt a térképet?",
    deleteConfirm2: "Ez a művelet visszafordíthatatlan.",
    delete: "Törlés",
    cancel: "Mégse",
    save: "Mentés",
    saved: "Térkép mentve",
    create: "Létrehozás",
    mapCannotSaveDuplicit: "Nem sikerült menteni – duplicit helyek",
    errors: "Nincs mentve, javítsa ki a hibákat",

    addRack: "Új állvány",
    addObstacle: "Új akadály",
    addZone: "Új zóna",
    addLocationsGroup: "Új helyek",
    newObjectName: "Új objektumnév",
    newLocationGroupLocations: "Új helyek ezen a pozíción",
    addLocation: "Hely hozzáadása",
    newZoneType: "Új zóna típusa",

    detectedCollisions: "Ütközések észlelve",
    cantFinishActionDueToCollision: "Nem sikerült befejezni a műveletet, ütközést észleltünk.",
    zoom: "Zoom",

    automaticLocationEdit: "Helyek automatikus szerkesztése",
    manualLocationEdit: "Helyek manuális szerkesztése",

    zoneTypes: {
        receive: "Fogadás",
        delivery: "Szállítás",
        packing: "Csomagolás",
        forklift: "Targoncaparkoló",
    },

    locationMappingDetails: {
        notMapped: "Nem térképezett helyek",
        mapped: "Térképezett helyek",
        toCreate: "Létre kell hozni (nem létező)",
        duplicit: "Kettős (többször a térképen)",
    },

    bookmarks: {
        map: "Térkép",
        rack: "Rack részlet"
    },

    tools: {
        edit: "Szerkesztés",
        move: "Move",
        rotate: "Forgatás",
        copy: "Másolás",
        delete: "Törlés",
        cancel: "Kiválasztás törlése",
        cancelAction: "Művelet megszakítása"
    },

    guide: {
        move: "Kattintson egy üres helyre az objektum mozgatásához.",
        createRack: "Az állvány létrehozásához kattintson egy üres helyre.",
        createObstacle: "Kattintson egy üres helyre akadály létrehozásához.",
        createZone: "Zóna létrehozásához kattintson egy üres helyre.",
        createLocationsZone: "Helycsoport létrehozásához kattintson egy üres helyre.",
        copy: "Kattintson egy üres helyre, ahová az objektumot beilleszti."
    },

    locationsSync: {
        sync: "Helyek szinkronizálása",
        mapNotHavingStockLoc: "Helyek a térképen, amelyek nem rendelkeznek megfelelő helyekkel a raktárban",
        stockNotHavingMapLoc: "Raktárhelyek, amelyeknek nincs megfelelő helyük a térképen",
        createStockLocCorrespondingMap: "A megfelelő helyek automatikus létrehozása a raktárban",
        deleteStockLocCorrespondingMap: "A raktárban lévő, fel nem térképezett helyek automatikus törlése"
    },

    locationCodeDecide: {
        chooseLocation: "Válasszon helyet",
        guide1: "Több hely van ilyen névvel ",
        guide2: " ebben az állományban. Válasszon egy helyet, amelyet hozzá szeretne adni.",
        notMapped: "Nem térképezett helyek",
        mapped: "Már feltérképezett helyek",
        add1: "Hely hozzáadása",
        add2: "kóddal",
    },

    error: {
        applyDimensionsToNull: "Nem lehet méreteket alkalmazni. Először válassza ki a rack cellákat."
    }
};
