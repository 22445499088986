import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.label)+" "),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"text--disabled mr-2"},on),[_vm._v(" $info ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('homepage.stats.orderCountDisclaimer'))+" ")]),_c('DashboardConfigLink',{attrs:{"config-enter-callback":_vm.configEnterCallback}}),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"text--disabled dashboardIcon",class:{loadingIcon: _vm.loading},attrs:{"disabled":_vm.loading},on:{"click":_vm.getStats}},on),[_vm._v(" $reloadData ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('base.reload'))+" ")])],1),_c(VCard,[_c(VTimeline,{attrs:{"dense":""}},_vm._l((_vm.timelineItems),function(item,index){return _c(VTimelineItem,{key:index,attrs:{"color":'primary ' + _vm.iconColor(index),"icon":item.icon,"fill-dot":""}},[(_vm.loading)?_c(VSkeletonLoader,{staticClass:"py-0 my-0",attrs:{"type":"button"}}):[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])]],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }