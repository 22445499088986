<template>
  <v-alert
    :value="!online"
    type="error"
    transition="scale-transition"
  >
    {{ $t('base.offline') }}
  </v-alert>
</template>

<script>
    import {BaseAPI} from "@/api/BaseAPI";

    export default {
        name: "OfflineNotifier",
        data: () => ({
            online: true,
            interval: null
        }),
        created: function () {
            this.$store.watch(state => state.connection.online, () => {
                this.online = this.$store.getters['connection/online'];
                if (this.interval !== null) {
                    if (this.online) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                } else if (!this.online) {
                    this.interval = window.setInterval(() => {
                        BaseAPI.get('');
                    }, 2 * 1000); // 2 sec
                }
            });
        }
    };
</script>

<style scoped>

</style>
