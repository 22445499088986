<template>
  <div>
    <v-card
      v-show="isChief"
    >
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="!isInView"
            class="subheading"
          >
            {{ $t('users.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="users."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <template
      v-if="isEdit"
    >
      <v-card
        class="mt-3"
      >
        <v-container fluid>
          <span class="subheading">{{ $t('users.changePassword') }}</span>
          <div
            v-if="isCurrentlyChief && !form.is_chief"
            class="mt-2 text-caption"
          >
            {{ $t('users.saveChangesFirst') }}
          </div>
          <v-form
            v-else-if="!form.is_chief || userId === loggedUserId"
            ref="formPassword"
            v-model="validPassword"
            @submit.prevent
          >
            <FormFields
              ref="fieldPassword"
              :form="formPassword"
              :render="formPasswordRender"
              lang-path="users.update.password."
            />
            <v-flex
              xs12
            >
              <v-btn
                :text="!validPassword"
                :loading="loadingPassword"
                type="submit"
                color="accent"
                @click="submitPassword()"
              >
                <v-icon
                  class="mr-2"
                >
                  $saveItem
                </v-icon>
                {{ $t('form.save') }}
              </v-btn>
            </v-flex>
          </v-form>
          <div
            v-else
            class="mt-2 text-caption"
          >
            {{ $t('users.cannotChangePasswordOfAnotherChief') }}
          </div>
        </v-container>
      </v-card>
    </template>
  </div>
</template>

<script>
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {PasswordForm, PasswordRender, UserForm, UserRender} from "@/app/users/definition/user.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {UserAPI} from "@/api/UserAPI";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {EventBus} from "@/service/EventBus";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "UsersCreateUpdateComponent",
        components: {FormFields},
        mixins: [FormFetchItemMixin, ACLMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            userId: {
                type: Number,
                default: null
            },
            isInView: {
                type: Boolean,
                default: false
            },
            creatingChief: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new UserForm,
            formRender: new UserRender,
            formPassword: new PasswordForm,
            formPasswordRender: {},
            valid: true,
            validPassword: true,
            loading: false,
            loadingPassword: false,
            isCurrentlyChief: false,
        }),
        computed: {
            loggedUserId: function () {
                return this.$store.getters['oauth/getUserId'];
            }
        },
        created: function () {
            this.formPasswordRender = new PasswordRender(this.isChief);
            if (this.isEdit) {
                delete this.formRender.password;
                this.formRender.username.readonly = true;
            }
            if (this.creatingChief) {
                this.form.is_chief = true;
            }
        },
        methods: {
            formFetchItem: function () {
                return UserAPI.get(this.userId).then((response) => {
                    this.isCurrentlyChief = response.data.is_chief;
                    return response;
                });
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                if (!(this.form.is_storekeeper || this.form.is_chief || this.form.is_packer || this.form.is_organizer)) {
                    this.snack('users.chooseOneRole');
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const userId = this.userId;
                    UserAPI.update(userId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'users.update.done',
                                params: [this.form.first_name + ' ' + this.form.last_name]
                            });
                            IndexedDB.clearByKey(CachePath.users, userId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('user-updated');
                        })
                        .catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    UserAPI.create(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'users.create.done',
                                params: [this.form.first_name + ' ' + this.form.last_name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('user-updated', getIdFromLocation(response));
                        })
                        .catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            submitPassword: function () {
                if (!this.validPassword) {
                    this.$refs.formPassword.validate();
                    return;
                }
                if (this.formPassword.password !== this.formPassword.password_confirmation) {
                    const message = this.$t('users.update.password.passwordsDoNotMatch');
                    this.$set(this.formPasswordRender.password, 'errors', [message]);
                    this.$set(this.formPasswordRender.password_confirmation, 'errors', [message]);
                    return;
                }
                this.loadingPassword = true;
                const userId = this.userId;
                UserAPI.updatePassword(userId, this.formPassword.password)
                    .then(() => {
                        this.advancedSnack({
                            text: 'users.update.password.done',
                            params: [this.form.first_name + ' ' + this.form.last_name]
                        });
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.back();
                    })
                    .catch(setFormErrors.bind(this))
                    .finally(() => {
                        this.loadingPassword = false;
                    });
            },
            close: function () {
                EventBus.$emit('create-user-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
