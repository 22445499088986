import i18n from "@/service/lang/i18n";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";
import {Email, Phone} from "@/app/components/form/definitions/address.form";
import {
    BuyerId,
    StockId,
    SubordinateStockId,
    TaskDescription,
    TaskPriority
} from "@/app/tasks/definitions/taskCommon.render.form";
import {EventBus} from "@/service/EventBus";

function ExternalOrderBuyerContactForm() {
    return {
        email: null,
        phone: null
    };
}

function ExternalOrderBuyerContactRender() {
    return {
        email: new Email(),
        phone: new Phone()
    };
}

function ExternalOrderCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        stock_id: null,
        subordinate_stock_id: null,
        buyer_id: null,
        buyer_contact: false,
        buyer_delivery_address_id: null,
        processing_mode: TaskExternalOrderProcessingMode.PROCESS_TOGETHER,
        carrier_service_id: null,
        attachments: []
    };
}

const processingModeItems = function () {
    return Object.values(TaskExternalOrderProcessingMode).map(mode => ({
        text: i18n.t('tasks.externalOrder.processingMode.' + mode),
        value: mode
    }));
};

function ExternalOrderCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: new SubordinateStockId,
        buyer_id: BuyerId(),
        buyer_contact: {
            icon: '$specificBuyerContact',
            checkbox: true
        },
        buyer_delivery_address_id: {
            icon: '$address',
            autocomplete: {
                items: []
            },
            createNew: {
                icon: '$addBuyerAddress',
                label: 'buyers.addresses.create.titleSimple',
                condition: (form) => {
                    return form.buyer_id !== null && form.buyer_id !== undefined;
                },
                action: () => {
                    EventBus.$emit('create-buyer-address');
                }
            }
        },
        processing_mode: {
            icon: '$taskExternalOrderProcessingMode',
            select: processingModeItems()
        },
        carrier_id: {
            icon: '$carrier',
            required: true,
            autocomplete: {
                items: []
            }
        },
        carrier_service_id: {
            icon: '$carrierService',
            required: true,
            autocomplete: {
                items: []
            },
            createNew: {
                icon: '$carrierService',
                label: 'carriers.services.create.label',
                condition: (form) => {
                    return form.carrier_id !== null && form.carrier_id !== undefined;
                },
                action: () => {
                    EventBus.$emit('create-carrier-service');
                }
            }
        },
        description: new TaskDescription()
    };
}

export {ExternalOrderBuyerContactForm, ExternalOrderBuyerContactRender, ExternalOrderCreateForm, ExternalOrderCreateRender, processingModeItems};
