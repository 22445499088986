import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{attrs:{"chips":"","hide-details":"","items":_vm.substocks,"label":_vm.$t('homepage.config.onlySubstocks'),"loading":_vm.loadingSubstocks,"multiple":"","outlined":"","prepend-icon":"$filter"},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.item.props.subStockIds
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.item.props, "subStockIds", $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      item.props.subStockIds\n    "}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }