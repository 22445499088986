const MapObjectType = {
    RACK: 0,
    OBSTACLE: 1,
    AISLE: 2,
    ZONE: 3,
    LOCATIONS_GROUP: 4,
};

const ZoneObjectType = {
    ZONE_INTAKE: "map.zoneTypes.receive",
    ZONE_EXPENDITURE: "map.zoneTypes.delivery",
    ZONE_PACKING: "map.zoneTypes.packing",
    ZONE_FORKLIFT: "map.zoneTypes.forklift",
};

const RackNamingSymbols = {
    COLUMN: "COLUMN",
    SHELF: "SHELF",
    CELL_COUNT: "CELL_COUNT"
};

const MapObjectAction = {
    NOTHING: "NOTHING",
    UPDATE: "UPDATE",
    CREATE: "CREATE",
    DELETE: "DELETE"
};

const PanelMode = {
    STANDBY: 0,
    MOVE: 1,
    NEW_RACK: 2,
    EDIT_RACK: 3,
    COPY: 4,
    NEW_OBSTACLE: 5,
    NEW_ZONE: 6,
    NEW_LOCATIONS_GROUP: 7,
    EDIT_LOCATIONS_GROUP: 8,
};

// Menus in the Rack Panel
const RackPanel = {
    GENERAL: 0,
    DIMENSIONS: 1,
    KEYS: 2
};

const MapViewOptions = {
    MAP_EDITOR: {icon: '$edit', label: "map.mapEditor"},
    USAGE_HEATMAP: {icon: '$heatMap', label: "map.usageHeatMap"}
};

export {MapObjectType, PanelMode, RackPanel, ZoneObjectType, RackNamingSymbols, MapObjectAction, MapViewOptions};
