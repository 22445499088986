<template>
  <td>
    <v-tooltip
      v-if="item.ico !== null"
      top
    >
      <template #activator="{ on }">
        <span v-on="on">
          {{ item.ico }}
          <v-icon small>
            {{ item.ares_validated ? '$aresValidated' : '$aresNonValidated' }}
          </v-icon>
        </span>
      </template>
      {{ item.ares_validated ? $t('base.ares.validated') : $t('base.ares.not_validated') }}
    </v-tooltip>
  </td>
</template>

<script>
    export default {
        name: "TableItemAresValidated",
        props: {
            item: {
                type: Object,
                default: () => ({
                    ico: '',
                    ares_validated: false
                })
            }
        }
    };
</script>

<style scoped>

</style>
