import {has} from "@/utils/object";
import {storeUserConfig} from "@/service/store/UserConfigHelper";
import {EventBus} from "@/service/EventBus";

const defaultState = {
    lang: 'cs',
    darkMode: false,
    theme: 'system',
    productModels: false,
    vibrate: true,
    beep: true,
    trackTime: true,
    showProductImagesInTasks: false,
    pickFromMobileLocation: false,
    automagicallyChooseInstance: false,
    drawer: true,
};

const stringifyBoolean = value => value ? "true" : "false";
const booleanizeString = value => value !== "false";

const userConfigModule = {
    namespaced: true,
    persistent: true,
    state: {...defaultState},
    actions: {
        replaceConfig({dispatch, state}, config) {
            const remoteState = {};
            config.forEach(el => {
                remoteState[el.key] = el.value;
            });
            for (const key of Object.keys(state)) {
                if (has(remoteState, key)) {
                    dispatch('set', {
                        key,
                        value: typeof defaultState[key] === "boolean" ? booleanizeString(remoteState[key]) : remoteState[key],
                        doNotSendToApi: true
                    });
                }
                // TODO only for compatibility, drop this in the future
            }
            if (remoteState['theme'] === undefined) {
                if (booleanizeString(remoteState['darkMode']) === true) {
                    dispatch('theme', 'dark');
                } else {
                    dispatch('theme', 'light');
                }
            }
        },
        set({dispatch}, {key, value, doNotSendToApi}) {
            dispatch(key, value);
            doNotSendToApi || storeUserConfig(key, typeof defaultState[key] === "boolean" ? stringifyBoolean(value) : value);
        },
        lang({commit}, newValue) {
            commit('set', {
                key: 'lang',
                value: newValue
            });
            EventBus.$emit('setLang', newValue);
        },
        darkMode() {
            console.warn('trying to set darkMode, should use theme instead');
        },
        theme({commit}, newValue) {
            commit('set', {
                key: 'theme',
                value: newValue
            });
            if (newValue === 'system') {
                EventBus.$emit('setDarkMode', window.matchMedia('(prefers-color-scheme: dark)').matches);
            } else {
                EventBus.$emit('setDarkMode', newValue === 'dark');
            }
        },
        productModels({commit}, newValue) {
            commit('set', {
                key: 'productModels',
                value: !!newValue
            });
        },
        vibrate({commit}, newValue) {
            commit('set', {
                key: 'vibrate',
                value: !!newValue
            });
        },
        beep({commit}, newValue) {
            commit('set', {
                key: 'beep',
                value: !!newValue
            });
        },
        trackTime({commit}, newValue) {
            commit('set', {
                key: 'trackTime',
                value: !!newValue
            });
        },
        showProductImagesInTasks({commit}, newValue) {
            commit('set', {
                key: 'showProductImagesInTasks',
                value: !!newValue
            });
        },
        pickFromMobileLocation({commit}, newValue) {
            commit('set', {
                key: 'pickFromMobileLocation',
                value: !!newValue
            });
        },
        automagicallyChooseInstance({commit}, newValue) {
            commit('set', {
                key: 'automagicallyChooseInstance',
                value: !!newValue
            });
        },
        drawer({commit}, newValue) {
            commit('set', {
                key: 'drawer',
                value: !!newValue
            });
        },
        clear({commit}) {
            for (const key of Object.keys(defaultState)) {
                commit('set', {
                    key,
                    value: defaultState[key]
                });
            }
        }
    },
    mutations: {
        set(state, keyValue) {
            state[keyValue.key] = keyValue.value;
        }
    },
    getters: {
        lang: state => state.lang,
        darkMode: state => state.darkMode, // deprecated, use theme
        theme: state => state.theme,
        productModels: state => 0 + state.productModels,
        vibrate: state => state.vibrate,
        beep: state => state.beep,
        trackTime: state => state.trackTime,
        showProductImagesInTasks: state => state.showProductImagesInTasks,
        pickFromMobileLocation: state => state.pickFromMobileLocation,
        automagicallyChooseInstance: state => state.automagicallyChooseInstance,
        drawer: state => state.drawer,
    }
};

export default userConfigModule;
