const AttributeDataTypes = {
    STRING: 'String',
    INTEGER: 'Integer',
    FLOAT: 'Float',
    BOOLEAN: 'Boolean'
};

const AttributeDataTypeIcons = {
    String: '$text',
    Integer: '$number',
    Float: '$float',
    Boolean: '$boolean'
};

export {AttributeDataTypes, AttributeDataTypeIcons};
