<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">
        <span v-if="absDateFirst">
          {{ dateTime | dateTime }}
        </span>
        <span v-else>
          {{ dateTime | momentAgo }}
        </span>
      </span>
    </template>
    <span v-if="absDateFirst">
      {{ dateTime | momentAgo }}
    </span>
    <span v-else>
      {{ dateTime | dateTime }}
    </span>
  </v-tooltip>
</template>

<script>
    export default {
        name: "DateTimeWithTooltip",
        props: {
            dateTime: {
                type: String,
                default: null
            },
            absDateFirst: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style scoped>

</style>
