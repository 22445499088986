import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VDivider,{staticClass:"my-3"})],1),_c(VRow,[_vm._t("title",function(){return [_c('div',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.carrierServiceParameters.title'))+": ")])]})],2),_vm._l((_vm.carrierServiceParameters),function(carrierServiceParam){return _c(VRow,{key:carrierServiceParam.id},[_c(VLayout,{attrs:{"wrap":"","align-center":""}},[_c(VFlex,{staticClass:"px-1",attrs:{"xs12":"","sm8":"","md7":"","lg4":"","xl4":""}},[_c(VTextField,{attrs:{"label":_vm.$t('tasks.externalOrder.carrierServiceParameters.name'),"prepend-icon":"$itemName","disabled":true},model:{value:(carrierServiceParam.name),callback:function ($$v) {_vm.$set(carrierServiceParam, "name", $$v)},expression:"carrierServiceParam.name"}})],1),_c(VFlex,{staticClass:"px-1",attrs:{"xs12":"","sm8":"","md7":"","lg4":"","xl4":""}},[_c(VTextField,{attrs:{"label":_vm.$t('tasks.externalOrder.carrierServiceParameters.value'),"prepend-icon":"$attributeValue","rules":carrierServiceParam.required ? [_vm.formRules.required] : [],"suffix":carrierServiceParam.required ? '*' : '',"disabled":_vm.readOnly},model:{value:(carrierServiceParam.value),callback:function ($$v) {_vm.$set(carrierServiceParam, "value", $$v)},expression:"carrierServiceParam.value"}})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }