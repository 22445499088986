const measurementUnits = {
    PIECE: 'PIECE',
    CARTON: 'CARTON',
    HECTOLITER: 'HECTOLITER',
    LITER: 'LITER',
    DECILITER: 'DECILITER',
    CENTILITER: 'CENTILITER',
    MILLILITER: 'MILLILITER',
    TONNE: 'TONNE',
    KILOGRAM: 'KILOGRAM',
    DECAGRAM: 'DECAGRAM',
    GRAM: 'GRAM',
    MILLIGRAM: 'MILIGRAM',
    KILOMETER: 'KILOMETER',
    METER: 'METER',
    CENTIMETER: 'CENTIMETER',
    MILLIMETER: 'MILLIMETER',
    SQUARE_METER: 'SQUARE_METER',
    CUBIC_METER: 'CUBIC_METER',


    US_GALLON: 'US_GALLON',
    UK_GALLON: 'UK_GALLON',
    SQUARE_FOOT: 'SQUARE_FOOT',
    CUBIC_FOOT: 'CUBIC_FOOT',
    POUND: 'POUND',
    OUNCE: 'OUNCE',
    INCH: 'INCH',
    MILE: 'MILE',
};

export {measurementUnits};