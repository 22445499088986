import {TableFilter} from "@/enum/table_filter";

function BuyerTypeForm() {
    return {
        name: ''
    };
}

function BuyerTypeRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
    };
}

const buyerTypeTable = {
    name: {
        filterType: TableFilter.TEXT
    }
};

export {BuyerTypeForm, BuyerTypeRender, buyerTypeTable};
