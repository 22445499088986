import {Status} from "@/enum/import&export_state";
import {Domains} from "@/enum/domains";

const configNotFound = "Nepodarilo sa nájsť vybranú šablónu";
const exportNotFound = "Nepodarilo sa nájsť vybraný import";
export default {
    title: "Export",
    exports: "Exporty",
    link: "Exportovať",
    status: "Stav",
    domain: "Doména",
    download: "Stiahnuť",
    errorMessage: "Chybová správa",
    processed_at: "Spracované o",
    created_by: "Vytvorené používateľom",
    created_at: "Vytvorené o",
    data_type: "Typ dát",
    type: "Typ súboru",
    export_create: "Vytvoriť export",
    error: "Chyba",
    continue: "Pokračovať v exporte",
    view: "Zobraziť export",
    created: "Export vytvorený",
    process: "Spracovať import",
    processed: "Export v rade na spracovanie",
    deleted: "Export vymazaný",
    showStatistics: "Vypočítať štatistiky exportu",

    configuration: {
        title: "Exportná šablóna",
        templates: "Exportné šablóny",
        file: "Zdrojový súbor",
        create: "Vytvoriť exportnú šablónu",
        created: "Exportná šablóna vytvorená",
        config: "Konfigurácia šablóny",
        config_subtitle: "Ak chcete, môžete tu konfigurovať svoju šablónu",
        updated: "Šablóna aktualizovaná",
        create_copy: "Vytvoriť kópiu exportnej šablóny",
        view: "Zobraziť šablónu",
        save: "Uložiť šablónu",
        copy: "Kopírovať šablónu",
        name: "Názov šablóny",
        domain: "Doména cieľových dát",
        deleted: "Šablóna vymazaná",
        parameters: "Parametre exportnej šablóny"
    },
    info: {
        title: "Informácie o exporote",
        hint: "Informácie o vytvorenom exporote sa počítajú, tento proces môže trvať niekoľko minút.",
        include: "Export bude zahŕňať: ",
        error: "Export s touto konfiguráciou spôsobí chybu s touto správou: ",
        exported: "Počet riadkov na export",
        config_hint: "Tu môžete konfigurovať svoju šablónu. Konkrétne stĺpce môžete skryť alebo zobraziť kliknutím na ich hlavičku. Skryté stĺpce sa nezahrnú do exportu",
        parameters_hint: "Parametre exportu sa používajú na filtrovanie exportovaných údajov. Vyplňte všetky zobrazené parametre, prosím.",
        statistics_warn: "Kým nie sú vypočítané štatistiky, export nie je možné dokončiť. Chcete pokračovať?"
    },
    create: {
        title: "Vytvoriť export",
        dateHint: "Použite formát RRRR-MM-DD pre dátumy"
    },
    parameters: {
        title: "Parametre exportu",
        warning: "Všetky parametre exportu musia byť vyplnené"
    },
    state: {
        [Status.CREATED]: 'Vytvorené',
        [Status.UPLOADED]: 'Nahrané',
        [Status.QUEUED]: 'V rade',
        [Status.PROCESSING]: 'Spracováva sa',
        [Status.FAILED]: 'Neúspešné',
        [Status.VALIDATION_FAILED]: 'Neplatná šablóna',
        [Status.FINISHED]: 'Dokončené',
        [Status.DRY_RUN_QUEUED]: 'Čaká sa na štatistiky',
        [Status.DRY_RUN_PROCESSING]: 'Prebieha výpočet štatistík',
        [Status.DRY_RUN_FAILED]: 'Chyba pri výpočte štatistík',
        [Status.DRY_RUN_VALIDATION_FAILED]: 'Neplatná šablóna pre štatistiky',
        [Status.DRY_RUN_FINISHED]: 'Štatistiky sú dokončené',
    },
    domains: {
        [Domains.BUYER]: 'Kupci',
        [Domains.PRODUCT]: 'Produkty',
        [Domains.BUY_PRICE]: 'Kupná cena',
        [Domains.BULK_EXTERNAL_ORDER]: "Externá objednávka",
        [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: 'Denná správa obsadenosti',
        [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: 'Denná správa obsadenosti podľa typu',
        [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: 'Denná správa obsadenosti podľa typu s pohybom',
        [Domains.INTERVAL_MOVEMENTS_REPORT]: 'Pohyby podľa intervalu',
        [Domains.SHARED_LOCATIONS_REPORT]: 'Spoločné lokality',
        [Domains.PRODUCT_AT_STOCK]: 'Zásoby produktov',
        [Domains.TASK_ATTRIBUTES]: 'Atribúty úlohy'
    },
    errors: {
        'export': {
            status: {
                404: exportNotFound,
            }
        },
        exportCreate: {
            status: {
                409: 'Nepodarilo sa vytvoriť export'
            }
        },
        exportDelete: {
            status: {
                404: exportNotFound,
                409: "Nepodarilo sa vymazať export"
            }
        },
        exportUpdate: {
            status: {
                404: exportNotFound,
                409: 'Nepodarilo sa aktualizovať export'
            }
        },
        exportProcess: {
            status: {
                404: exportNotFound,
                409: "Nepodarilo sa spracovať export"
            }
        },
        config: {
            status: {
                404: configNotFound
            }
        },
        configCreate: {
            status: {
                404: configNotFound,
                409: "Nepodarilo sa vytvoriť šablónu"
            }
        },
        configUpdate: {
            status: {
                404: configNotFound,
                409: "Nepodarilo sa aktualizovať šablónu"
            }
        },
        configDelete: {
            status: {
                404: configNotFound,
                409: "Nepodarilo sa vymazať šablónu"
            }
        },
    }
};
