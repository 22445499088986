import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":"","elevation":"4"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('div',{staticClass:"text-overline mb-3"},[_vm._v(" "+_vm._s(_vm.$t('stocks.stockStatus.buyPrices.title.' + _vm.type))+" ")]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"stocks.stockStatus.buyPrices."}}),_c(VBtn,{attrs:{"text":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":_vm.submit}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $export ")]),_vm._v(" "+_vm._s(_vm.$t('form.download'))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }