const TableActionsMixin = {
    props: {
        actions: {
            type: Array,
            default: () => []
        },
        item: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        possibleActions: function () {
            return this.actions.filter(ca => !ca.condition || ca.condition(this.item));
        }
    }
};

export {TableActionsMixin};
