import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VCard,{staticClass:"mb-5",attrs:{"loading":_vm.loading}},[_c(VCardText,{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.$t('imports.hints.fileTemplate'))+" ")]),_c('div',{staticClass:"d-flex col-md-6 col-lg-4 col-sm-12"},[(!_vm.loading)?_c(VSelect,{staticClass:"col-8 pb-0",attrs:{"items":_vm.domains,"clearable":"","label":_vm.$t('imports.domain')},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c(VIcon,{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.icons[item.domain])+" ")]),_vm._v(" "+_vm._s(item.text)+" ")]}}],null,false,2218357580),model:{value:(_vm.selectedDomain),callback:function ($$v) {_vm.selectedDomain=$$v},expression:"selectedDomain"}}):_vm._e(),_c(VSelect,{staticClass:"col-4 pb-0",attrs:{"items":_vm.types},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('div',{staticClass:"d-flex align-center"},[(_vm.selectedDomain)?_c(VBtn,{attrs:{"md":"","accent":"","color":"accent"},on:{"click":function($event){return _vm.downloadTemplate()}}},[_vm._v(" "+_vm._s(_vm.$t("exports.download"))+" ")]):_vm._e()],1)],1)],1),_c('ImportConfigListComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }