<template>
  <v-card>
    <v-tabs
      v-model="locationTableTab"
      :color="chipsColor"
    >
      <v-tab>
        {{ $t('map.locationMappingDetails.notMapped') }}
      </v-tab>
      <v-tab>
        {{ $t('map.locationMappingDetails.mapped') }}
      </v-tab>
      <v-tab>
        {{ $t('map.locationMappingDetails.toCreate') }}
      </v-tab>
      <v-tab>
        {{ $t('map.locationMappingDetails.duplicit') }}
      </v-tab>
    </v-tabs>
    <v-card-text 
      v-if="loading && !saving"
    >
      <div
        class="mt-2"
      >
        <v-progress-linear
          indeterminate
          color="primary"
        />
      </div>
    </v-card-text>
    <v-card-text
      v-if="!loading || saving"
    >
      <v-chip-group
        column
      >
        <v-tooltip
          v-for="(location, index) in locationsCurrentPage"
          :key="index"
          top
          :disabled="location.code === null"
        >
          <template #activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              class="ma-2"
              :color="chipsColor"
              text-color="white"
              :model-value="true"
              v-on="on"
            >
              <div>{{ location.name }}</div>
            </v-chip>
          </template>
          <span v-if="location.code !== null">{{ location.code }}</span>
        </v-tooltip>
      </v-chip-group>
    </v-card-text>
    <div
      class="text-center"
    >
      <v-pagination
        v-model="locationsPage"
        :length="locationsPageCount"
        :color="chipsColor"
        total-visible="20"
        class="pb-5"
        next-aria-label="next"
        previous-aria-label="previous"
        page-aria-label="page"
        wrapper-aria-label="wrapper"
        current-page-aria-label="current"
      />
    </div>
  </v-card>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {MapMixin} from "@/app/mixins/MapMixin";
    export default {
        name: 'LocationMappingPanel',
        mixins: [RouteParamsMapperMixin, MapMixin],
        data: () => ({
            locationsPage: 1,
            locationsArray: [],
            locationTableTab: null,
        }),
        computed: {
            locationsAll() {
                if (this.locationTableTab === 0) {
                    return this.$store.getters["storageMap/getNotMappedStockLocations"];
                }
                else if (this.locationTableTab === 1) {
                    return this.$store.getters["storageMap/getMappedStockLocations"];
                }
                else if (this.locationTableTab === 2) {
                    return this.$store.getters["storageMap/getStockLocationsToCreate"];
                }
                else {
                    return this.$store.getters["storageMap/getDuplicitMapLocations"];
                }
            },
            chipsColor() {
                if (this.locationTableTab === 0) {
                    return 'locationNotMapped';
                }
                else if (this.locationTableTab === 1) {
                    return 'locationMapped';
                }
                else if (this.locationTableTab === 2) {
                    return 'locationToCreate';
                }
                else {
                    return 'locationDuplicit';
                }
            },
            locationsPageCount(){
                return this.locationsAll.length%100 === 0 ? this.locationsAll.length/100 : ((this.locationsAll.length - this.locationsAll.length%100)/100)+1;
            },
            locationsCurrentPage(){
                if (this.locationsPageCount <=1) {
                    return this.locationsAll; 
                }
                else {
                    return this.locationsAll.slice(((this.locationsPage-1)*100),this.locationsPage*100);
                }
            },
        },
        watch: {
            locationTableTab: function (){
                this.locationsPage = 1;
            }
        }
    };
</script>

<style scoped>


</style>
