<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
        >
          <form-fields
            :form="form"
            :render="formRender"
            :single-per-row="false"
            lang-path="exports.configuration."
          >
            <template #domain-item="{item}">
              <v-icon class="pr-2">
                {{ domainsIcons[item] }}
              </v-icon>
              {{ $t('exports.domains.' + item) }}
            </template>
          </form-fields>
        </v-form>
        <div
          v-if="form.domain!==''"
        >
          <v-divider />
          <ExportConfig
            :config="config"
            :domain="form.domain"
            :new-config-name="form.name"
            :show-optional-parameters="!isEdit"
            :save-trigger="saveTrigger"
            @validConfig="valid => validConfig = valid"
          />
        </div>
        <v-btn
          v-if="form.domain"
          :disabled="!form.name || !validConfig"
          :loading="loading"
          class="ml-2 mb-2"
          color="accent"
          @click="isEdit ? saveTrigger = -1 : saveTrigger++"
        >
          {{ $t('exports.configuration.save') }}
        </v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {ExportsConfigForm, ExportsConfigFormRender} from "@/app/exports/definitions/configuration.form";
    import {ExportsAPI as API} from "@/api/ExportsAPI";
    import {ImportsAPI} from "@/api/ImportsAPI";
    import ExportConfig from "@/app/exports/components/ExportConfig.component";
    import {DomainIcon} from "@/enum/domains";


    export default {
        name: "ExportConfigCreate",
        components: {ExportConfig, FormFields},
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            form: new ExportsConfigForm,
            formRender: new ExportsConfigFormRender,
            config: null,
            saveTrigger: 0,
            templates: [],
            domainsIcons: DomainIcon,
            validConfig: false,
        }),
        createdOrActivated: function () {
            const configId = this.$route.path.split('/').pop();
            if (configId !== "createConfig") {
                this.formRender.domain.readonly = true;
                this.fillInfo(configId);
            }
            this.getTemplates();
        },
        methods: {
            fillInfo(id) {
                API.getConfiguration(id)
                    .then(res => {
                        this.config = res.data;
                        this.form.domain = this.config.domain;
                        if (this.isEdit) {
                            this.form.name = this.config.name + '(copy)';
                        }
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/exports');
                    });
            },
            getTemplates: function () {
                this.loading = true;
                ImportsAPI.getTemplates()
                    .then(response => {
                        this.templates = response.data;
                        this.formRender.domain.select = this.templates.filter(item => item.supports_export)
                            .map((item) => item.domain);
                    }).catch(err => {
                        this.snack(err);
                    }).finally(() => {
                        this.loading = false;
                    });
            },
        }

    };
</script>

