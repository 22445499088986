import {Roles} from "@/enum/roles";
import ImportsList from "@/app/imports/ImportsList.view";
import ImportCreate from "@/app/imports/ImportCreate.view";
import ImportConfig from "@/app/imports/ImportConfig.view";

const BASE_URL = '/imports';

const routes = {
    base: {
        path: BASE_URL,
        component: ImportsList,
        meta: {
            title: 'imports.imports',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    create: {
        path: BASE_URL + "/create",
        component: ImportCreate,
        props: {
            hasTemplate: false
        },
        meta: {
            title: 'imports.config.create',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        },
    },
    createWithConfig: {
        path: BASE_URL + "/create/:configurationId(\\d+)",
        component: ImportCreate,
        meta: {
            title: 'imports.create',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    processImport: {
        path: BASE_URL + "/:importId(\\d+)/process",
        component: ImportCreate,
        props: {
            hasImport: true,
        },
        meta: {
            title: 'imports.create',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    viewImport: {
        path: BASE_URL + "/:importId(\\d+)/view",
        component: ImportCreate,
        props: {
            hasImport: true,
        },
        meta: {
            title: 'imports.viewImport',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    config: {
        path: BASE_URL + '/config/:configId(\\d+)/copy',
        component: ImportConfig,
        props: {
            isEdit: true,
        },
        meta: {
            title: 'imports.config.edit',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    configView: {
        path: BASE_URL + '/config/:configId(\\d+)',
        component: ImportConfig,
        meta: {
            title: 'imports.config.view',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
};

export default Object.values(routes);
