<template>
  <div>
    <Alert
      v-if="item.part_of_inventory === false"
      show-alert
      type="warning"
      :display-text="$t(`tasks.stockTaking.items.partOfInventory.no.${taskInfo.details.restrict_type}`)"
    />
    <Alert
      v-if="itemOnWrongLocation"
      show-alert
      type="error"
      :display-text="$t(allowedLocationsWarningPath)"
      :actions="[{
        text: $t('tasks.locationsCard.reloadAllowedLocations'),
        icon: '$reloadData',
        handler: () => EventBus.$emit('fetch-allowed-locations', item, true)
      }]"
    />
  </div>
</template>

<script>
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {EventBus} from "@/service/EventBus";
    import Alert from "@/app/components/Alert.component.vue";

    export default {
        name: "TaskItemDetailAlerts",
        components: {Alert},
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            itemOnWrongLocation: {
                type: Boolean,
                default: false
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
        },
        data: () => ({
            EventBus: EventBus
        }),
        computed: {
            allowedLocationsWarningPath: function () {
                return this.cardType === TaskItemsCardType.IN_INVENTORY ? 'tasks.locationsCard.cannotBePutOnAnyLocation'
                    : 'tasks.locationsCard.cannotBePutOnThisLocation';
            }
        }
    };
</script>

<style scoped>

</style>
