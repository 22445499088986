import {Store} from "@/service/store/Store";

const routes = [
    {
        path: '/login',
        beforeEnter: (to, from, next) => {
            Store.dispatch('oauth/handleLoginRequest', to.query)
                .then(() => {
                    const redirectUrl = sessionStorage.getItem('remember-url');
                    if (redirectUrl !== null) {
                        sessionStorage.removeItem('remember-url');
                        next(redirectUrl);
                    } else {
                        next('/');
                    }
                }).catch(() => {
                    next('/');
                });
        }
    },
    {
        path: '/relogin',
        beforeEnter: () => {
            Store.dispatch('oauth/redirectToAuthUrl');
        }
    }
];

export default routes;
