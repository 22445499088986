import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar',{attrs:{"title":_vm.pageTitle}}),_c(VCard,[_c(VLayout,{staticClass:"mx-3",attrs:{"wrap":""}},[_c(VFlex,{staticClass:"px-2",attrs:{"xs12":""}},[_c(VAutocomplete,{staticClass:"mt-6",attrs:{"items":_vm.availableTypes,"label":_vm.$t('stocks.locations.locks.newType'),"loading":_vm.loading,"prepend-icon":'$locationLock',"rules":[_vm.formRules.required],"single-line":"","suffix":"*"},model:{value:(_vm.lockType),callback:function ($$v) {_vm.lockType=$$v},expression:"lockType"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),(_vm.lockType)?_c('span',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('stocks.locations.locks.lockItems.title'))+": ")]):_vm._e()],1),(_vm.lockType !== null)?_c('StockLocationLocksList',{attrs:{"lock-type":_vm.lockType,"is-edit":false}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }