<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <BuyerTypeCreateUpdateComponent
      :is-edit="isEdit"
      :type-id="isEdit ? typeId : null"
      is-in-view
      @buyer-type-updated="buyerTypeUpdated"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {BuyerTypeAPI} from "@/api/BuyerTypeAPI";
    import BuyerTypeCreateUpdateComponent from "@/app/buyers/types/components/BuyerTypeCreateUpdate.component";

    export default {
        name: "BuyerTypeCreateUpdate",
        components: {BuyerTypeCreateUpdateComponent},
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            buyerTypeName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.buyerTypeName !== '') {
                    title = this.$t('buyers.types.update.title', [this.buyerTypeName]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            if (this.isEdit) {
                BuyerTypeAPI.get(this.typeId).then(response => this.buyerTypeName = response.data.name)
                    .catch(this.snack);
            }
        },
        methods: {
            buyerTypeUpdated: function () {
                this.$router.push('/buyers/types');
            }
        }
    };
</script>

<style scoped>

</style>
