/**
 * @param configObject {Object}
 * @return {Promise}
 */
import {Store} from "@/service/store/Store";
import {UserAPI} from "@/api/UserAPI";

const Dashboard = {

    version: 3,
    versionKey: 'dashboard-config-version',
    lengthKey: 'dashboard-config-length',
    dashboardConfigKey: 'dashboard-config-data',

    saveHomepageConfig: function (configObject) {

        const userId = Store.getters['oauth/getUserId'];
        const configString = JSON.stringify(configObject);

        return UserAPI.getSetting(userId, this.dashboardConfigKey)
            .then(() => {
                return UserAPI.updateSetting(userId, this.dashboardConfigKey, configString);
            }).catch(() => {
                return UserAPI.createSetting(userId, this.dashboardConfigKey, configString);
            });
    },

    /**
     * @return {Promise}
     */
    loadHomepageConfig: async function () {
        return new Promise((resolve, reject) => {
            UserAPI.getAllSettings(Store.getters['oauth/getUserId'])
                .then(response => {
                    const settings = {};
                    for (const set of response.data) {
                        settings[set.key] = set.value;
                    }
                    const version = settings[this.versionKey];
                    if (version === undefined || parseInt(version, 10) < this.version) {
                        return new Promise(() => {
                            this.checkVersion([], version)
                                .then(() => resolve(this.loadHomepageConfig()));
                        });
                    }
                    const config = settings[this.dashboardConfigKey];
                    const oldConfigs = Object.keys(settings).filter(key => this.isOldDashboardConfig(key));
                    if (oldConfigs.length && parseInt(version, 10) === this.version) {
                        this.deleteOldConfigs(oldConfigs);
                    }
                    if (config === undefined) {
                        reject('not-defined');
                    }
                    try {
                        const configObject = JSON.parse(settings[this.dashboardConfigKey]);
                        if (Object.keys(configObject).length === 0) {
                            reject('not-defined');
                        } else {
                            resolve(configObject);
                        }
                    } catch (err) {
                        reject(err);
                    }
                    resolve();
                }).catch(reject);
        });
    },

    checkVersion: async function (config = [], version) {

        const userId = Store.getters['oauth/getUserId'];

        if (version === undefined) {
            return UserAPI.createSetting(userId, this.versionKey, this.version);
        } else {
            if (version === this.version) {
                return Promise.resolve();
            } else {
                await this.convertVersion(config, version);
                return Promise.all([
                    UserAPI.updateSetting(userId, this.versionKey, this.version),
                ]);
            }
        }
    },

    /**
     * Convert configObject in-place to the latest version
     * @param config
     * @param from
     */
    convertVersion: async function (config, from) {
        from = Number.parseInt(from, 10);
        if (from >= this.version) {
            return;
        }
        const promises = [];
        const userId = Store.getters['oauth/getUserId'];
        switch (from) {
            case 1: // 1 to 2
                window.console.info(`Converting dashboard config version ${from} -> ${from + 1}`);
                config.forEach(item => {
                    if (item.props.value === 'ordersStats') {
                        item.type = 'ordersStats';
                        item.props.label = 'stats.orders.label';
                    } else {
                        item.type = 'taskList';
                    }
                });
                break;
            case 2: // 2 to 3
                window.console.info(`Converting dashboard config version ${from} -> ${from + 1}`);
                await UserAPI.getAllSettings(userId)
                    .then(response => {
                        const settings = {};
                        for (const set of response.data) {
                            settings[set.key] = set.value;
                        }
                        const length = settings[this.lengthKey];
                        if (length !== undefined) {
                            let configString = '';
                            for (let i = 0; i < length; ++i) {
                                configString += settings['dashboard-config[' + i + ']'];
                            }
                            if (settings[this.dashboardConfigKey] !== undefined) {
                                promises.push(UserAPI.updateSetting(userId, this.dashboardConfigKey, configString));
                            } else {
                                promises.push(UserAPI.createSetting(userId, this.dashboardConfigKey, configString));
                            }
                        }
                    });
                await Promise.all(promises);
                break;
            // Add case for each version which needs to be upgraded
        }
        return this.convertVersion(config, from + 1);
    },

    isOldDashboardConfig(text) {
        const regex = /^dashboard-config(?:\[\d+\])?$/;
        return regex.test(text);
    },

    deleteOldConfigs(configKeys) {
        const userId = Store.getters['oauth/getUserId'];
        configKeys.forEach(key => {
            UserAPI.deleteSetting(userId, key)
                .catch(err => this.snack(err));
        });
    }
};

export {Dashboard};
