<template>
  <div class="d-flex flex-wrap">
    <WrappedChipList
      v-slot="{item: attribute}"
      :items="suggestedAttributes"
      inline
    >
      {{ attribute.key }}
    </WrappedChipList>
  </div>
</template>

<script>
    import WrappedChipList from "@/app/components/WrappedChipList.component";

    export default {
        name: "ProductTypeAttributesList",
        components: {WrappedChipList},
        props: {
            suggestedAttributes: {
                type: Array,
                default: () => []
            }
        }
    };
</script>

<style scoped>

</style>
