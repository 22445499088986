export default {
    title: "Отримувачі",
    link: "Отримувачі",

    name: "Назва",
    ico: "ІПН",
    dic: "ЄДРПОУ",
    phone: "Телефон",
    email: "E-mail",
    website: "Веб-сайт",
    is_minor: "він неповнолітній",
    billing_address: "Адреса для виставлення рахунків",
    delivery_address: "Адреса доставки",
    address: "Адреса доставки",
    delivery_addresses: "Адреси доставки",
    type_id: "Тип",
    type: {
        id: "Тип",
        label: "Тип"
    },

    addresses: {
        public: "<публічна>",
        privacy: "Приватна адреса користувача",
        default: "За замовчуванням",
        markDefault: "Позначити як за замовчуванням",
        searchHint: "Пошук по вулиці, номеру будинку, уточненню адреси, місту, поштовому індексу та країні",

        create: {
            title: "Створити адресу доставки для отримувача '{0}'",
            titleSimple: "Створити нову адресу доставки",
            done: "Адреса доставки створена."
        },
        update: {
            titleSimple: "Оновити адресу доставки отримувача",
            title: "Оновити адресу доставки для отримувача '{0}'",
            done: "Адреса доставки оновлена."
        },
        delete: {
            done: "Адресу доставки отримувача видалено."
        },
        status: {
            404: "Отримувач або його адреса не існують!",
            409: "Адресу доставки за замовчуванням не можна робити приватною!"
        }
    },

    types: {
        link: "Типи отримувачів",
        title: "Типи отримувачів",
        titleSingle: "Тип отримувача",
        name: "Назва",

        create: {
            label: "Створити новий тип отримувача",
            title: "Новий тип отримувача",
            done: "Тип '{0}' створено"
        },
        update: {
            titleSimple: "Оновити тип отримувача",
            title: "Оновити '{0}'",
            done: "'{0}' оновлено"
        },
        delete: {
            done: "'{0}' видалено.",
            failed: "'{0}' неможливо видалити. Будь ласка, спробуйте знову пізніше."
        },

        status: {
            404: "Тип отримувача не знайдено!",
            409: "Тип отримувача неможливо видалити, оскільки він використовується в одного з отримувачів!"
        }
    },

    list: {
        unableToLoad: "Не вдалося завантажити список отримувачів. Будь ласка, спробуйте знову пізніше."
    },
    show: {
        title: "Отримувач",
    },
    create: {
        title: "Створити отримувача",
        label: "Створити нового отримувача",
        done: "Отримувач '{0}' створено.",
        undo: {
            done: "Створення '{0}' успішно відмінено!",
            failed: "Не вдалося скасувати створення отримувача!"
        }
    },
    update: {
        titleSimple: "Оновити отримувача",
        title: "Оновити {0}",
        done: "Отримувач '{0}' оновлено",
        undo: {
            done: "Оновлення '{0}' успішно відмінено!",
            failed: "Не вдалося скасувати оновлення отримувача!"
        }
    },
    delete: {
        done: "Отримувач '{0}' видалено.",
        failed: "Отримувача '{0}' не можна видалити. Будь ласка, спробуйте знову пізніше.",
        undo: {
            done: "Видалення '{0}' успішно відмінено!",
            failed: "Не вдалося скасувати видалення отримувача!"
        },
        status: {
            404: "Вказаний отримувач не існує!",
            409: "Отримувача не можна видалити через його відношення до інших об'єктів!"
        }
    },
    status: {
        404: "Вказаний отримувач не існує!",
        409: "Заповнений ІПН вже має інший отримувач!"
    }
};
