<template>
  <div v-if="loading || items.length || showEmpty">
    <span class="text-subtitle-1 ml-2">
      {{ $t(langPath + '.productsAtSubstock') }}:
    </span>
    <template v-if="loading">
      <v-progress-circular
        indeterminate
        :size="20"
        :width="2"
        class="ml-4 mb-1"
      />
    </template>
    <template v-else>
      <template v-if="items.length === 0">
        <span class="text-h6">{{ $t(langPath + '.noItems') }}</span>
      </template>
      <template v-else>
        <WrappedChipList :items="items">
          <template #default="{ item }">
            {{ item.quantity }}&nbsp;
            <span class="caption">
              {{ item.instance.product | productMeasureLabel }}
            </span>&nbsp;
            {{ item.instance | instanceLabel }}
          </template>
          <template #bottomsheet="{ item }">
            <TaskItemDetails
              :card-type="TaskItemsCardType.ASSIGNMENT"
              :item="item"
              :item-quantity-on-location="itemQuantity"
              :can-load-locations="canLoadLocations"
            />
          </template>
        </WrappedChipList>
      </template>
    </template>
  </div>
</template>

<script>
    import TaskItemDetails from "@/app/tasks/components/taskItemsCard/TaskItemDetails.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {has} from "@/utils/object";
    import WrappedChipList from "@/app/components/WrappedChipList.component";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "ProductInstancesChips",
        components: {WrappedChipList, TaskItemDetails},
        mixins: [EventsListenerMixin],
        props: {
            items: {
                type: Array,
                default: () => []
            },
            subStockId: {
                type: Number,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            langPath: {
                type: String,
                default: 'stocks.substocks.items'
            },
            canLoadLocations: {
                type: Boolean,
                default: false
            },
            showEmpty: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            TaskItemsCardType: TaskItemsCardType,
            locationsOfItems: {}
        }),
        computed: {
            events: function () {
                return {
                    'fetch-locations': this.onFetchLocations
                };
            }
        },
        createdOrActivated: function () {
            this.locationsOfItems = {};
        },
        methods: {
            itemQuantity: function (item) {
                return item.quantity;
            },
            onFetchLocations: function (instanceId) {
                if (!has(this.locationsOfItems, instanceId)) {
                    this.$set(this.locationsOfItems, instanceId, []);
                }
                let promise = Promise.resolve();
                if (this.locationsOfItems[instanceId].length === 0) {
                    promise = StockStatusAPI.getInSubstockForInstance(this.subStockId, instanceId)
                        .then(response => {
                            this.locationsOfItems[instanceId] = response.data.items.filter(location => !has(location.stock_location, 'user'));
                        }).catch(this.snack);
                }
                promise.then(() => {
                    const item = this.items.find(item => item.instance.id === instanceId);
                    if (item) {
                        item.locations = this.locationsOfItems[instanceId];
                    }
                });
            }
        }
    };
</script>

<style scoped>

</style>
