export default {
    tasks: "Úlohy",
    noTasks: "Žiadne úlohy!",
    running: "Moje rozpracované úlohy",
    storekeepersRunning: "Rozpracované úlohy skladníkov",
    assigned: "Nové úlohy priradené mne",
    free: "Voľné úlohy",
    allTasks: "Všetky voľné úlohy",
    allDone: "Žiadne úlohy na zobrazenie!",
    overview: "Prehľad",
    toApprove: "K schváleniu",
    toPack: "K zabaleniu",
    status: "Stav",
    manage: "Spravovanie",
    done: "Dokončené",
    noDone: "Žiadne dokončené úlohy!",
    config: {
        title: "Konfigurácia domovskej obrazovky",
        config: "Nastavenie zoznamu",
        label: "Názov",
        addTaskList: "Pridať zoznam úloh",
        addOrdersStats: "Pridať prehľad objednávok",
        newList: "Nový zoznam",
        all: "všetky",
        export: {
            title: "Exportovať",
            done: "Domovská obrazovka exportovaná."
        },
        import: {
            title: "Importovať",
            done: "Domovská obrazovka importovaná.",
            fail: "Import domovskej obrazovky zlyhal!"
        },
        reset: "Obnoviť predvolené zoznamy",
        resetDone: "Predvolené zoznamy boli obnovené",
        showOn: "Zobrazovať na",
        desktop: "počítač",
        mobile: "mobil",
        showOnDesktop: "Zobrazovať na počítači",
        showOnMobile: "Zobrazovať na mobile",
        autoRefresh: "Automaticky obnovovať",
        autoRefreshValue: "Obnovovať po {0} sekundách",
        unableToLoad: "Nepodarilo sa načítať konfiguráciu domovskej obrazovky. Skúste to prosím opäť neskôr.",
        unableToSave: "Nepodarilo sa uložiť nastavenia!",
        arrayDateConflict: "Výber viacerých dátumov súčasne nie je podporovaný. Použite prosím rozmedzie od-do.",
        onlySubstocks: "Iba podsklady"
    },
    stats: {
        orders: {
            label: 'Stav objednávok',
            unprocessed: 'nových',
            inStockProcess: 'v spracovaní',
            toBePicked: 'čaká na vyskladnenie',
            beingPicked: 'vo vyskladnení',
            toBePacked: 'čaká na balenie',
            beingPacked: 'v balení',
            packed: 'zabalené',
            inShipping: 'v doprave',
            returning: 'vracia sa',
            delivered: 'doručených'
        },
        orderCountDisclaimer: 'Pri použití čiastočného uspokojovania môže jedna objednávka byť súčasne vo viacerých stavoch!'
    }
};
