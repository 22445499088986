<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <div v-if="details.parent_task_id">
                <v-divider
                  class="my-2"
                />
                <div>
                  <span class="text-caption">{{ $t(taskNames[parentTask.type]) + ': ' }}</span>
                  <router-link
                    :to="`${taskLinks[parentTask.type]}/${details.parent_task_id}`"
                  >
                    {{ '#' + details.parent_task_id }}
                  </router-link>
                </div>
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :stock-id="details.subordinate_stock.stock_id"
            :sub-stock-id="details.subordinate_stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
        <v-flex
          v-if="details.tasks"
          class="pa-1"
          md4
          xs12
        />
      </v-layout>
      <TaskItemsCard
        v-if="ready"
        :api="API"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :items="details.items"
        :task-info="taskInfo"
        task-lang-path="productsReservation."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {taskTypes} from "@/enum/task_type";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {taskLinks,taskNames} from "@/enum/task_type";
    import {TaskAPI} from "@/api/TaskAPI";
    import {ProductAPI} from "@/api/ProductAPI";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";

    export default {
        name: "ProductsReservationAssignment",
        components: {TaskAttributes, TaskItemsCard, EntityHistory, TaskAttachments, Notes},
        mixins: [TaskFetchItemsMixin, ReactiveLocationCacheMixin, ACLMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                require: true,
                default: () => ({}),
            }
        },
        data: () => ({
            ready: false,
            TaskItemsCardType: TaskItemsCardType,
            taskLinks: taskLinks,
            type: taskTypes.PRODUCTS_RESERVATION,
            taskNames: taskNames,
            parentTask: null,
            removeLoading: false,
            removeTask: null,
            removeDialog: false
        }),
        computed: {
            API: function () {
                return this.api;
            },
            details: function () {
                return this.taskInfo.details;
            },
        },
        watch: {
            'details.parent_task_id': {
                handler: function () {
                    TaskAPI.get(this.details.parent_task_id)
                        .then(res => {
                            this.parentTask = res.data;
                            this.mapItems();
                        }).catch(err => this.snack(err));
                },
                immediate: true
            },
            'details.items': function () {
                this.mapItems();
            }
        },
        methods: {
            mapItems() {
                this.ready = false;
                const promises = [];
                this.details.items.forEach(item => {
                    item.quantity_to_move = item.reserved_quantity;
                    item.id = item.product_instance_id;
                    promises.push(ProductAPI.getInstanceWOProduct(item.id)
                        .then(res => item.instance = res.data)
                        .catch(err => this.snack(err)));
                });
                Promise.all(promises)
                    .then(() => this.ready = true);
            },

        }
    };
</script>
