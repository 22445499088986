import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
import {taskTypes} from "@/enum/task_type";
import {TaskSetTargetLocationMixin} from "@/app/mixins/TaskSetTargetLocationMixin";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {EventBus} from "@/service/EventBus";

/**
 * Requires:
 * - this.details.assigned_user[.id]
 * - this.tab
 * - this.type from {taskType}
 * - this.typeName {translation of type name}
 * - this.API.assign()
 * - this.API.startProcessing() for STOCK TAKING
 * - this.getTask()
 */
const TaskAssignMixin = {
    mixins: [TaskStateMixin, TaskSetTargetLocationMixin],
    data: () => ({
        conflict: null
    }),
    computed: {
        assignedToCurrentUser: function () {
            let details = this.details;
            if (this.taskInfo !== undefined) {
                details = this.taskInfo.details;
            }
            return details === null || details.assigned_user === null
                ? false
                : details.assigned_user.id === this.$store.getters['oauth/getUserId'];
        },
        assignedToAnybody: function () {
            let details = this.details;
            if (this.taskInfo !== undefined) {
                details = this.taskInfo.details;
            }
            return details && details.assigned_user !== null;
        },
        assignable: function () {
            return !this.assignedToAnybody
                && this.isAssignableState
                && (this.isStorekeeper
                    || (this.type === taskTypes.EXTERNAL_ORDER && this.isChief && !this.isDoNotProcess)
                    || (this.type === taskTypes.PREPARE_PACKAGE && this.isPacker)
                ) && (this.type !== taskTypes.STOCK_PICKING || !this.details.parent_task_id);
        },
        allowStartProcessing: function () {
            return this.type === taskTypes.STOCK_TAKING && this.assignedToCurrentUser && this.isCreated;
        }
    },
    createdOrActivated: function () {
        this.conflict = null;
    },
    methods: {
        assign: function (taskStartsAfterAssign = true) {
            this.loading = true;
            const codeAvailable = !this.$store.getters['barcodeReaderQueue/empty'];
            let front;
            if (codeAvailable && taskStartsAfterAssign) {
                front = this.$store.getters['barcodeReaderQueue/front'];
                this.$store.dispatch('barcodeReaderQueue/pop');
                this.fetchBarcodeToCache(front.code);
            }
            return this.API.assign(this.taskId)
                .then(() => {
                    this.assignDone();
                    this.getTask()
                        .then(() => {
                            if (codeAvailable
                                && taskStartsAfterAssign
                                && this.details.target_location_id === null
                                && ((this.type === taskTypes.STOCK_PICKING
                                        && !this.details.parent_task_id
                                        && this.details.shipping_type !== TaskShippingType.PERSONAL_COLLECTION)
                                    || this.type === taskTypes.STOCK_PICKING_SET)) {
                                this.setTargetLocation(front.code);
                            } else {
                                EventBus.$emit('task-targetLocation-picked');
                            }
                            if (taskStartsAfterAssign) {
                                this.tab = 1;
                            }
                        }).catch(this.snack)
                        .finally(() => this.loading = false);
                }).catch(err => {
                    if (err.response && err.response.status === 409) {
                        this.conflict = err.response.data;
                    } else {
                        this.snack(err);
                    }
                    this.loading = false;
                });
        },
        assignDone: function () {
            this.conflict = null;
            this.advancedSnack({
                text: 'tasks.assign.done',
                params: [this.taskId, this.typeName]
            });
        },
        startProcessing: function () {
            this.loading = true;
            const codeAvailable = !this.$store.getters['barcodeReaderQueue/empty'];
            let front;
            if (codeAvailable) {
                front = this.$store.getters['barcodeReaderQueue/front'];
                this.$store.dispatch('barcodeReaderQueue/pop');
                this.fetchBarcodeToCache(front.code);
            }
            return this.API.startProcessing(this.taskId)
                .then(() => {
                    this.taskStarted();
                    EventBus.$emit('task-started');
                    return this.getTask()
                        .then(() => {
                            this.tab = 1;
                        })
                        .catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch(err => {
                    if (err.response && err.response.status === 409) {
                        this.conflict = err.response.data;
                    } else {
                        this.snack(err);
                    }
                    this.loading = false;
                });
        },
        taskStarted: function () {
            this.conflict = null;
            this.advancedSnack({
                text: 'tasks.startProcessing.done',
                params: [this.taskId, this.typeName]
            });
        },
    }
};

export {TaskAssignMixin};
