import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{class:{'ml-3': !_vm.first}},[(_vm.first && _vm.currentColumn.templateCode !== '')?_c(VListItem,{on:{"click":_vm.unmap}},[_c(VContainer,{staticClass:"pa-0 text-center"},[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" $closeItem ")]),_vm._v(" "+_vm._s(_vm.$t('imports.config.unmap'))+" ")],1)],1):_vm._e(),(_vm.domain.is_dynamic)?_c(VTextField,{staticClass:"px-4",attrs:{"prepend-icon":"$search"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),_vm._l((_vm.filteredList),function(header){return [(header.columns)?_c(VListGroup,{key:header.name,attrs:{"value":_vm.findCodeInGroup(header.columns)},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,{class:{'red--text':_vm.checkRequiredGroup(header)}},[_vm._v(" "+_vm._s(_vm.domainService.getTranslation(header.name, _vm.domainName))+" ")])]},proxy:true}],null,true)},[_c('DomainList',{attrs:{"column":_vm.currentColumn,"domain":header,"domain-name":_vm.domainName,"reload-trigger":_vm.reloadTrigger,"required-attributes":_vm.requiredAttributes},on:{"updateHeader":_vm.updateHeader,"valid":data => _vm.$emit('valid', data)}})],1):_c(VListItem,{key:header.name,class:{'primary': _vm.currentColumn.templateCode === header.code},attrs:{"disabled":header.is_used && _vm.currentColumn.templateCode !== header.code},on:{"click":function($event){return _vm.changeHeaderColumn(header.code)}}},[_c(VListItemTitle,[_c('div',{class:{
            'white--text': _vm.currentColumn.templateCode === header.code,
            'red--text' : _vm.currentColumn.templateCode !== header.code && _vm.checkRequired(header),
          }},[_vm._v(" "+_vm._s(!_vm.domain.is_dynamic ? _vm.domainService.getTranslation(header.name, _vm.domainName) : header.name)+" "+_vm._s(_vm.checkRequired(header) ? '*' : '')+" ")])]),(_vm.currentColumn.templateCode === header.code && !_vm.domain.is_dynamic)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({on,attr}){return [_c('div',_vm._g(_vm._b({on:{"click":[_vm.toggleHeaderPrimary,function($event){$event.stopPropagation();}]}},'div',attr,false),on),[(_vm.currentColumn.is_primary)?_c(VIcon,{attrs:{"color":"accent"}},[_vm._v(" $primaryKey ")]):_c(VIcon,{attrs:{"color":"accent"}},[_vm._v(" $primaryKeyOff ")])],1)]}}],null,true)},[(!_vm.currentColumn.is_primary)?_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t('imports.config.primary_set'))+" ")]):_c(VContainer,[_vm._v(" "+_vm._s(_vm.$t('imports.config.primary_unset'))+" ")])],1):_vm._e()],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }