import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";

/**
 * This includes also "storekeeper inventories"
 */
const StockStatusAPI = {

    API: BaseAPI,
    DOMAIN: 'stock-status',
    DOMAIN_SHORT: 'stock-status-short',
    DOMAIN_SUMMARY: 'summary',
    DOMAIN_RESERVATION: 'reservations',
    DOMAIN_BLOCKED: 'blocked',
    DOMAIN_ORDERS_FOR_PRODUCTS: 'orders-for-products',
    DOMAIN_ORDERS_FOR_INSTANCES: 'orders-for-instances',
    DOMAIN_ORDERS_FOR_TYPES: 'orders-for-instance-types',
    DOMAIN_LOCATIONS: 'locations',
    DOMAIN_PRODUCT_INSTANCES: 'product-instances',
    DOMAIN_STOCKS: 'stocks',
    DOMAIN_BY_DATE: 'by-date',
    DOMAIN_EXPORTS: 'exports',
    LANG: 'stocks',
    LANG_LOCATIONS: 'locations',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, merge(...params));
    },

    getAllShort(...params) {
        return this.API.get(this.DOMAIN_SHORT, undefined, merge(...params));
    },
    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getShortAllPages(...params) {
        return APIHelper.getAllPages(this.DOMAIN_SHORT, this.DOMAIN, merge(...params));
    },

    getInSubstockForInstance(subStockId, instanceId) {
        return this.getAllPages({
            filter: APIFilters.makeFilter([
                {[APIFilterOP.EQUALS]: {'substock.id': subStockId}},
                {[APIFilterOP.EQUALS]: {'product_instance.id': instanceId}},
                {[APIFilterOP.GREATER_THAN]: {quantity: 0}}
            ])
        });
    },

    getBlockedInSubstock(subStockId, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_BLOCKED],
            this.LANG,
            merge(...params)
        );
    },

    getInSubstockOnLocation(subStockId, locationId) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_LOCATIONS, locationId],
            this.LANG
        );
    },

    getInSubstockOnLocationByDate(subStockId, locationId, date) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_LOCATIONS, locationId, this.DOMAIN_BY_DATE, date],
            this.LANG
        );
    },

    /**
     * @polling
     * @param subStockId {int}
     * @param fromDate {string} ISO-8601 datetime
     * @param toDate {string} ISO-8601 datetime. Cannot be more than 31 days after fromDate
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getLocationUsage(subStockId, fromDate, toDate) {
        return APIHelper.polling(this.API.get.bind(
            this.API,
            [this.DOMAIN, subStockId, this.DOMAIN_LOCATIONS, 'from-date', fromDate, 'to-date', toDate],
            this.LANG
        ), 33).promise; // ~4.5 minutes
    },

    getBlockedInSubstockForInstance(subStockId, instanceId) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_PRODUCT_INSTANCES, instanceId, this.DOMAIN_RESERVATION],
            this.LANG
        );
    },

    getSubstockSummary(subStockId, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_SUMMARY],
            this.LANG,
            merge(...params)
        );
    },

    /**
     * @param {number} subStockId
     * @param {number[]} locationIds
     * @param {object} params
     * @returns {Promise}
     */
    getSubstockSummaryOnLocations(subStockId, locationIds, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_SUMMARY],
            this.LANG,
            merge({'location_ids': locationIds.join(',')}, ...params)
        );
    },

    getSubstockSummaryForProduct(subStockId, productId, ...params) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_SUMMARY, productId],
            this.LANG,
            merge(...params)
        );
    },

    getOrdersForProducts(...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_ORDERS_FOR_PRODUCTS], undefined, merge(...params));
    },

    getOrdersForProductsAllPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_ORDERS_FOR_PRODUCTS], undefined, merge(...params));
    },

    getOrdersForInstances(...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_ORDERS_FOR_INSTANCES], undefined, merge(...params));
    },

    getOrdersForInstancesAllPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_ORDERS_FOR_INSTANCES], undefined, merge(...params));
    },

    getOrdersForInstanceTypes(...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_ORDERS_FOR_TYPES], undefined, merge(...params));
    },

    getOrdersForInstanceTypesAllPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_ORDERS_FOR_TYPES], undefined, merge(...params));
    },

    getInStockByDate(stockId, date) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_STOCKS, stockId, this.DOMAIN_BY_DATE, date],
            [this.LANG]
        );
    },

    getInStockOnLocation(stockId, locationId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_STOCKS, stockId, this.DOMAIN_LOCATIONS, locationId],
            [this.LANG, this.LANG_LOCATIONS]
        );
    },

    ////
    // Buy price exports
    ////

    /**
     * @deprecated
     */
    exportBuyPricesIn(data) {
        return this.API.post(
            [this.DOMAIN_EXPORTS, 'buy-prices-for-stock-loadings'],
            data,
            [this.LANG, 'stockStatus', 'buyPrices']
        );
    },

    /**
     * @deprecated
     */
    exportBuyPrices(data) {
        return this.API.post(
            [this.DOMAIN_EXPORTS, 'buy-prices'],
            data,
            [this.LANG, 'stockStatus', 'buyPrices']
        );
    },

    /**
     * @deprecated
     */
    exportBuyPricesOut(data) {
        return this.API.post(
            [this.DOMAIN_EXPORTS, 'buy-prices-for-stock-pickings'],
            data,
            [this.LANG, 'stockStatus', 'buyPrices']
        );
    }
};

export {StockStatusAPI};
