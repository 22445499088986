import {Roles} from "@/enum/roles";
import StockMovements from "@/app/overview/movements/StockMovements.view";

const BASE_URL = '/movements';

const routes = {
    base: {
        path: BASE_URL,
        component: StockMovements,
        meta: {
            title: 'stocks.stockMovements.title',
            requireRole: [Roles.CHIEF, Roles.ORGANIZER],
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
