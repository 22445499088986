import {TableFilter} from "@/enum/table_filter";
import i18n from "@/service/lang/i18n";
import {ImportsAPI} from "@/api/ImportsAPI";

export const importConfigsTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    domain: {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => ImportsAPI.getTemplates(),
            thenFn: response => response.data.filter(item => item.supports_import).map(item => ({
                text: i18n.t('exports.domains.' + item.domain),
                value: item.domain
            }))
        }
    },
};
