import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VDialog,{attrs:{"max-width":"700px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t('map.locationsSync.sync'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('map.locationsSync.mapNotHavingStockLoc') + ': ' + _vm.getStockLocationsToCreate.length)+" "),_c(VBtn,{staticClass:"mb-4 ml-4 mt-4",attrs:{"small":"","depressed":"","disabled":_vm.getStockLocationsToCreate.length === 0,"color":"accent"},on:{"click":_vm.createStockLocations}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" $addItem ")]),_vm._v(" "+_vm._s(_vm.$t('map.locationsSync.createStockLocCorrespondingMap'))+" ")],1)],1),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('map.locationsSync.stockNotHavingMapLoc') + ': ' + _vm.getNotMappedStockLocations.length)+" "),_c(VBtn,{staticClass:"mb-4 ml-4 mt-4",attrs:{"small":"","depressed":"","disabled":_vm.getNotMappedStockLocations.length === 0,"color":"accent"},on:{"click":_vm.deleteUnmappedStockLocations}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" $deleteObject ")]),_vm._v(" "+_vm._s(_vm.$t('map.locationsSync.deleteStockLocCorrespondingMap'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }