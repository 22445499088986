import {BaseAPI} from "@/api/BaseAPI";
import {merge} from "@/utils/object";
import {APIHelper} from "@/service/APIHelper";
import {Status} from "@/enum/import&export_state";

const ImportsAPI = {

    API: BaseAPI,
    DOMAIN: 'imports',
    DOMAIN_CONFIGURATIONS: 'configurations',
    DOMAIN_DRAFT: 'draft',
    DOMAIN_TEMPLATES: 'templates',
    DOMAIN_BY_CONFIGURATION: 'by-configuration',
    DOMAIN_PROCESS: 'process',
    TYPE: 'domain',
    CONFIGURATION: 'configuration',
    IMPORT_MODE: 'importMode',
    CONFIG_NAME: 'name',

    /**
     * @param id
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(id, ...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_BY_CONFIGURATION, id], 'exports.errors.config', merge(...params));
    },
    getAllPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN], 'exports.errors.config', merge(...params));
    },

    getTemplates() {
        return this.API.get([this.DOMAIN, this.DOMAIN_TEMPLATES], undefined);
    },

    getTemplate(domain) {
        return this.API.get([this.DOMAIN, this.DOMAIN_TEMPLATES, domain], 'imports.errors.domainTemplate');
    },

    get(importId) {
        return this.API.get([this.DOMAIN, importId], 'imports.errors.import');
    },

    getStatistics(importId) {
        return APIHelper.polling(this.API.get.bind(
            this.API,
            [this.DOMAIN, importId],
            this.LANG
        ), 100, res => {
            return res.data.error || res.data.status === Status.DRY_RUN_FINISHED || res.data.status === Status.FINISHED;
        }); // ~40 minutes
    },

    create(configId, file) {
        const data = new FormData();
        data.append('file', file);
        return this.API.post(
            [this.DOMAIN],
            data,
            'imports.errors.importCreate',
            'multipart/form-data',
            {
                params: {
                    [this.CONFIGURATION]: configId,
                }
            });
    },


    update(id, data) {
        return this.API.patch([this.DOMAIN, id], data, 'imports.errors.importUpdate');
    },


    process(id, dryRun) {
        return this.API.post([this.DOMAIN, id, this.DOMAIN_PROCESS], dryRun, 'imports.errors.importProcess');
    },

    delete(id) {
        return this.API.delete([this.DOMAIN, id], 'imports.errors.importDelete');
    },

    getImports(...params) {
        return this.API.get(this.DOMAIN, null, merge(...params));
    },

    getAllConfigurations(...params) {
        return this.API.get([this.DOMAIN, this.DOMAIN_CONFIGURATIONS], undefined, merge(...params));
    },

    getAllConfigurationsPages(...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_CONFIGURATIONS], undefined, merge(...params));
    },

    getConfig(id) {
        return this.API.get([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], 'exports.errors.config');
    },

    getConfigDraft(domain, file) {
        const data = new FormData();
        data.append('file', file);
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_CONFIGURATIONS, this.DOMAIN_DRAFT],
            data,
            'exports.errors.config',
            'multipart/form-data',
            {
                params: {
                    [this.TYPE]: domain,
                }
            });
    },

    createConfig(data) {
        const bodyData = new FormData();
        bodyData.append('file', data.file);
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_CONFIGURATIONS],
            bodyData,
            'exports.errors.config',
            'multipart/form-data',
            {
                params: {
                    [this.TYPE]: data.domain,
                    [this.IMPORT_MODE]: data.importMode,
                    [this.CONFIG_NAME]: data.name
                }
            });
    },

    updateConfig(id, data) {
        return this.API.patch([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], data, 'exports.errors.configUpdate');
    },

    deleteConfig(id) {
        return this.API.delete([this.DOMAIN, this.DOMAIN_CONFIGURATIONS, id], 'exports.errors.configDelete');
    },


};

export {ImportsAPI};
