<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <BuyerCreateUpdateComponent
      :is-edit="isEdit"
      :buyer-id="isEdit ? buyerId : null"
      is-in-view
      @buyer-updated="buyerUpdated"
    />
    <x-btn-fab
      v-if="isEdit"
      :to="'/buyers/' + buyerId + '/address/create'"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import BuyerCreateUpdateComponent from "@/app/buyers/components/BuyerCreateUpdate.component";
    import {BuyerAPI} from "@/api/BuyerAPI";

    export default {
        name: "BuyerCreateUpdate",
        components: {BuyerCreateUpdateComponent},
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            buyerName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.buyerName !== '') {
                    title = this.$t('buyers.update.title', [this.buyerName]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            if (this.isEdit) {
                BuyerAPI.get(this.buyerId).then(response => this.buyerName = response.data.name)
                    .catch(this.snack);
            }
        },
        methods: {
            buyerUpdated: function () {
                this.$router.back();
            }
        }
    };
</script>

<style scoped>

</style>
