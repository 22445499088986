import {TaskState} from "@/enum/task_state";

function resolve(state) {
    return this.details && this.details.state === state
        || this.taskInfo && this.taskInfo.details && this.taskInfo.details.state === state;
}

function resolveIncludes(states) {
    return this.details && states.indexOf(this.details.state) !== -1
        || this.taskInfo && this.taskInfo.details && states.indexOf(this.taskInfo.details.state) !== -1;
}

/**
 * Requires:
 * - this[.taskInfo].details.state
 */
const TaskStateMixin = {
    computed: {
        isCreated: function () {
            return resolve.call(this, TaskState.CREATED);
        },
        isInProgress: function () {
            return resolve.call(this, TaskState.IN_PROGRESS);
        },
        isWaitingForApproval: function () {
            return resolve.call(this, TaskState.WAITING);
        },
        isClosed: function () {
            return resolve.call(this, TaskState.CLOSED);
        },
        isCancelled: function () {
            return resolve.call(this, TaskState.CANCELED);
        },
        isUnassigned: function () {
            return resolve.call(this, TaskState.UNASSIGNED);
        },

        isOpenState: function () {
            return resolveIncludes.call(this, [
                TaskState.CREATED,
                TaskState.IN_PROGRESS,
                TaskState.WAITING,
                TaskState.UNASSIGNED
            ]);
        },

        isAssignableState: function () {
            return resolveIncludes.call(this, [
                TaskState.CREATED,
                TaskState.IN_PROGRESS,
                TaskState.UNASSIGNED
            ]);
        }
    }
};

export {TaskStateMixin};
