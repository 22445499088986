export default {
    required: "Toto pole je vyžadováno.",
    maxLen: "Maximální povolená délka je {0} znaků",
    minLen: "Minimální požadovaná délka je {0} znaků",
    maxInteger: 'Maximální povolená hodnota je {0}',
    pattern: "Vstup nesplňuje požadovaný formát",
    integer: "Vyplňte celé číslo",
    positiveInteger: "Vyplňte celé kladné číslo",
    nonNegativeInteger: "Vyplňte celé nezáporné číslo",
    number: "Vyplňte číslo",
    nonNegativeNumber: "Vyplňte nezáporné číslo",
    range: "Musí být mezi {0} a {1}",
    save: "Uložit",
    send: "Odeslat",
    generate: "Vygenerovat",
    sequenceBegin: "Sekvence musí začínat číslem nebo písmenem",
    notAllowedChar: "Nepovolený symbol",
    download: "Stáhnout",
    notFilled: "Nevyplněno",
    invalidDate: "Zadané datum nelze zpracovat",
    now: "Teď",
    today: "Dnes",
    yesterday: "Včera",
    start_of_month: "Začátek měsíce",
    end_of_last_month: "Konec minulého měsíce"
};
