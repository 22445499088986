import {read, utils} from "xlsx";

/**
 *
 * @param fileBuffer {arrayBuffer}
 * @param readOptions
 * @param sheetToJsonOptions
 */
function getDataFromFile(fileBuffer, readOptions, sheetToJsonOptions) {
    const XLSX = require('xlsx');
    const wb = read(fileBuffer, readOptions);
    const workSheet = wb.Sheets[wb.SheetNames[0]];
    const range = XLSX.utils.decode_range(workSheet['!ref']);
    range.s = {
        c: 0,
        r: 0
    };
    sheetToJsonOptions['range'] = range;
    const emptyRow = Array.from({length: range.e.c}, () => 'null');
    const dataJSON = utils.sheet_to_json(workSheet, sheetToJsonOptions);
    dataJSON.forEach((row, rowKey) => {
        if (row.length === 0) {
            dataJSON[rowKey] = emptyRow;
        }
    });
    return dataJSON;
}

/**
 *
 * @param file {arrayBuffer}
 * @returns {number}
 */
function numberOfSheets(file) {
    const wb = read(file, {bookSheets: true});
    return wb.SheetNames.length;
}

export {getDataFromFile, numberOfSheets};