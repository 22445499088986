<template>
  <div
    class="d-flex"
  >
    <v-btn
      fab
      outlined
      small
      class="ml-3 mr-2 mt-4"
      :class="{'elevation-8': edit}"
      @click="toggleEdit"
    >
      <v-icon>
        $updateItem
      </v-icon>
    </v-btn>
    <div class="ma-2">
      <div
        v-if="!edit"
      >
        <div class="title">
          {{ $te('homepage.' + item.props.label) ? $t('homepage.' + item.props.label) : item.props.label }}
          <span
            v-for="(icon, index) of icons"
            :key="index"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  v-if="icon.if"
                  class="mx-1 text--disabled"
                  v-on="on"
                >
                  {{ icon.icon }}
                </v-icon>
              </template>
              <span>{{ $t(icon.label, [icon.param]) }}</span>
            </v-tooltip>
          </span>
        </div>
        <template
          v-if="item.type === 'taskList'"
        >
          <div>
            <span class="body-2">
              {{ $t('base.filterConfig.filter') }}:
            </span>
            {{ filterLabel(langPath, item.props.filter, renderValues) }}
          </div>
          <div>
            <span class="body-2">
              {{ $t('base.filterConfig.sort') }}:
            </span>
            {{ sortLabel(langPath, item.props.sort) }}
          </div>
        </template>
        <template v-if="item.type === 'ordersStats'">
          <div>
            <span class="body-2">
              {{ $t('stocks.substocks.label') }}:
            </span>
            <v-chip
              v-for="ssId of item.props.subStockIds"
              :key="ssId"
            >
              {{ subStockName(ssId) }}
            </v-chip>
            <span v-if="!item.props.subStockIds || item.props.subStockIds.length === 0">
              {{ $t('homepage.config.all') }}
            </span>
          </div>
        </template>
      </div>
      <div
        v-else
      >
        <div
          class="d-flex flex-nowrap"
          :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
        >
          <v-text-field
            v-model="
              /* eslint-disable-next-line vue/no-mutating-props */
              item.props.label
            "
            :label="$t('homepage.config.label')"
            prepend-icon="$itemName"
            :rules="[formRules.required]"
            class="mr-10"
          />
          <div class="mr-10">
            <span>{{ $t('homepage.config.showOn') }}:</span>
            <v-checkbox
              v-model="
                /* eslint-disable-next-line vue/no-mutating-props */
                item.showOnDesktop
              "
              color="secondary"
              :label="$t('homepage.config.desktop')"
              hide-details
              dense
              class="ml-3"
            />
            <v-checkbox
              v-model="
                /* eslint-disable-next-line vue/no-mutating-props */
                item.showOnMobile
              "
              color="secondary"
              :label="$t('homepage.config.mobile')"
              hide-details
              dense
              class="ml-3"
            />
          </div>
          <div>
            <v-checkbox
              v-model="
                /* eslint-disable-next-line vue/no-mutating-props */
                item.props.autoRefresh
              "
              color="secondary"
              :label="$t('homepage.config.autoRefresh')"
            />
            <v-text-field
              v-if="item.props.autoRefresh"
              v-model="
                /* eslint-disable-next-line vue/no-mutating-props */
                item.props.autoRefreshIn
              "
              type="number"
              :rules="[formRules.positiveInteger]"
              :label="$t('homepage.config.autoRefreshValue', [item.props.autoRefreshIn])"
            />
          </div>
        </div>
        <v-divider class="my-2" />
        <template v-if="item.type === 'taskList'">
          <DashboardTaskListConfig
            :item="item"
            :possible-values="possibleValues"
          />
        </template>
        <template v-if="item.type === 'ordersStats'">
          <DashboardOrdersStatsConfig
            :item="item"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
    import {APIFilters} from "@/service/APIFilters";
    import formRules from "@/utils/formRules";
    import {TaskFilterMixin} from "@/app/mixins/TaskFilterMixin";
    import DashboardTaskListConfig from "@/app/homepage/components/DashboardTaskListConfig.component";
    import DashboardOrdersStatsConfig from "@/app/homepage/components/DashboardOrdersStatsConfig.component";

    export default {
        name: "DashboardItemConfig",
        components: {DashboardOrdersStatsConfig, DashboardTaskListConfig},
        mixins: [TaskFilterMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            possibleValues: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            edit: false,
            langPath: 'tasks.filter.',
            filterLabel: APIFilters.makeHumanReadable,
            sortLabel: APIFilters.makeHumanReadableSort,
            formRules: formRules,
        }),
        computed: {
            icons: function () {
                return [{
                    if: this.item.showOnDesktop,
                    icon: 'computer',
                    label: 'homepage.config.showOnDesktop',
                }, {
                    if: this.item.showOnMobile,
                    icon: 'smartphone',
                    label: 'homepage.config.showOnMobile',
                }, {
                    if: this.item.props.autoRefresh,
                    icon: '$taskListAutoRefresh',
                    label: 'homepage.config.autoRefreshValue',
                    param: this.item.props.autoRefreshIn
                }];
            },

        },
        methods: {
            toggleEdit: function () {
                this.edit = !this.edit;
            },
            subStockName: function (ssId) {
                const substock = this.possibleValues['substock.id'].values.find(s => s.value === ssId);
                if (substock) {
                    return substock.text;
                } else {
                    return '...';
                }
            }
        }
    };
</script>

<style scoped>

</style>
