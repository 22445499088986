import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isNoticeShown)?_c('div',{staticClass:"overlay"},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VCard,{staticClass:"pa-6"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('tasks.timeEntries.saveTime'))+" "),_c('br',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVertical),expression:"isVertical"}]}),_vm._v(" ("+_vm._s(_vm._f("elapsedTime")(_vm.elapsed))+") ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('tasks.timeEntries.saveTimeDetails'))+" ")]),_c(VCardActions,[_c(VLayout,{attrs:{"row":!_vm.isVertical,"column":_vm.isVertical}},[_c(VSpacer),_c(VFlex,[_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('base.cancel'))+" ")])],1),_c(VFlex,[_c(VDivider,{class:{
                'mx-4': !_vm.isVertical,
                'my-3': _vm.isVertical
              },attrs:{"vertical":!_vm.isVertical}})],1),_c(VFlex,[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"accent darken-2","text":""},on:{"click":_vm.discardTime}},[_vm._v(" "+_vm._s(_vm.$t('base.notSave'))+" ")]),_c(VBtn,{attrs:{"color":"accent"},on:{"click":_vm.logTime}},[_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }