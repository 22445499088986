import {TableFilter} from "@/enum/table_filter";

function ProductTypeForm() {
    return {
        name: '',
        suggested_attributes: []
    };
}

function ProductTypeRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
    };
}

const productTypeTable = {
    name: {
        filterType: TableFilter.TEXT
    },
    suggested_attributes: {
        label: 'suggestedAttributes.name',
        sortable: false
    }
};

export {ProductTypeForm, ProductTypeRender, productTypeTable};
