<template>
  <div>
    <v-menu
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        settings.menuShow
      "
      :position-x="settings.menuX"
      :position-y="settings.menuY"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          v-for="action in possibleActions"
          :key="action.label"
          :disabled="action.loading"
          :to="action.routerLink ? action.routerLink(item) : null"
          @click="action.action ? action.action(item) : () => {}"
        >
          <v-list-item-title>
            <v-icon
              small
              class="mr-2 pb-1"
            >
              {{ action.icon }}
            </v-icon>
            {{ $t(action.label) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
    import {TableActionsMixin} from "@/app/mixins/TableActionsMixin";

    export default {
        name: "TableContextMenu",
        mixins: [TableActionsMixin],
        props: {
            settings: {
                type: Object,
                default: () => ({
                    menuShow: false,
                    menuX: 0,
                    menuY: 0
                })
            }
        }
    };
</script>

<style scoped>

</style>
