import {TaskPriority as TaskPriortyEnum} from "@/enum/task_priority";
import {StockId, TaskDescription, TaskPriority} from "@/app/tasks/definitions/taskCommon.render.form";
import {EventBus} from "@/service/EventBus";

function SubstockTransferCreateForm() {
    return {
        priority: TaskPriortyEnum.NORMAL,
        description: null,
        stock_id: null,
        source_subordinate_stock_id: null,
        destination_subordinate_stock_id: null,
        attachments: [],
        items: []
    };
}

function SubstockTransferCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        source_subordinate_stock_id: {
            icon: '$taskSource',
            required: true,
            autocomplete: {
                items: []
            }
        },
        destination_subordinate_stock_id: {
            icon: '$taskDestination',
            required: true,
            autocomplete: {
                items: []
            },
            createNew: {
                icon: '$addSubstock',
                label: 'stocks.substocks.create.label',
                condition: (form) => {
                    return form.stock_id !== null && form.stock_id !== undefined;
                },
                action: () => {
                    EventBus.$emit('create-substock');
                }
            }
        },
        description: new TaskDescription
    };
}

export {SubstockTransferCreateForm, SubstockTransferCreateRender};
