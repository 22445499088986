import {Parameters} from "@/enum/domains";
import {ManufacturerAPI} from "@/api/ManufacturerAPI";
import {ProductTypeAPI} from "@/api/ProductTypeAPI";
import i18n from "@/service/lang/i18n";
import {TimeIntervals} from "@/enum/time_intervals";
import {visibleProducts} from "@/app/tasks/definitions/taskItemsDataSources";
import {BaseAPI} from "@/api/BaseAPI";
import {DayOfWeek} from "@/enum/dates";
import {taskNames, taskTypes} from "@/enum/task_type";
import {stocks} from "@/app/stocks/definitions/stockDataSources";
import {emptySource} from "@/app/definitions/emptyDataSources";

const ParameterRenders = {
    [Parameters.STOCK]: {
        icon: '$stock',
        langPath: 'stocks.stockStatus.buyPrices.',
        xAutocomplete: stocks()
    },
    [Parameters.SUBSTOCK]: {
        icon: '$substock',
        langPath: 'base.shipping.table.',
        xAutocomplete: emptySource()
    },
    [Parameters.SUBSTOCKS]: {
        icon: '$substock',
        langPath: 'reports.parameters.',
        xAutocomplete: emptySource(true)
    },
    [Parameters.DATE]: {
        datePicker: true,
        sticky: true,
        langPath: 'reports.parameters.',
    },
    [Parameters.FROM_DATE]: {
        datePicker: true,
        sticky: true,
        langPath: 'stocks.stockStatus.buyPrices.',
    },
    [Parameters.TO_DATE]: {
        datePicker: true,
        sticky: true,
        endOfDayTime: true,
        langPath: 'stocks.stockStatus.buyPrices.',
    },
    [Parameters.INTERVAL]: {
        langPath: 'reports.parameters.',
        icon: '$productMeasureUnit',
        sticky: true,
        select: [
            {
                text: i18n.t('reports.attributes.day'),
                value: TimeIntervals.DAY
            },
            {
                text: i18n.t('reports.attributes.week'),
                value: TimeIntervals.WEEK
            },
            {
                text: i18n.t('reports.attributes.month'),
                value: TimeIntervals.MONTH
            },
            {
                text: i18n.t('reports.attributes.year'),
                value: TimeIntervals.YEAR
            }
        ],
        tooltipHint: 'reports.hints.interval'
    },
    [Parameters.INTERVAL_FROM]: {
        intervalPicker: {
            interval: TimeIntervals.DAY,
            isIntervalFrom: true,
            allowedDayOfWeek: DayOfWeek.MONDAY
        },
        sticky: true,
        langPath: 'reports.parameters.',
    },
    [Parameters.INTERVAL_TO]: {
        endOfDayTime: true,
        intervalPicker: {
            interval: TimeIntervals.DAY,
            isIntervalFrom: false,
            allowedDayOfWeek: DayOfWeek.SUNDAY
        },
        langPath: 'reports.parameters.',
    },
    [Parameters.MANUFACTURERS]: {
        icon: 'business',
        langPath: 'reports.parameters.',
        xAutocomplete: {
            apiDataSource: ManufacturerAPI.getAll.bind(ManufacturerAPI),
            resultTransform: el => ({
                text: el.name,
                value: el.id
            }),
            multiple: true,
            disableAutoselectFirst: true
        },
    },
    [Parameters.PRODUCT_TYPES]: {
        icon: 'category',
        langPath: 'reports.parameters.',
        xAutocomplete: {
            apiDataSource: ProductTypeAPI.getAll.bind(ProductTypeAPI),
            resultTransform: el => ({
                text: el.name,
                value: el.id
            }),
            multiple: true,
            disableAutoselectFirst: true
        },
    },
    [Parameters.PRODUCTS]: {
        icon: '$product',
        langPath: 'reports.parameters.',
        xAutocomplete: visibleProducts(true)
    },
    [Parameters.LOCATIONS]: {
        icon: '$location',
        langPath: 'reports.parameters.',
        xAutocomplete: emptySource(true)
    },
    [Parameters.REPORT_TYPE]: {
        langPath: 'reports.parameters.',
        sticky: true,
        select: [
            {
                text: i18n.t('products.titleSingle'),
                value: 'product'
            },
            {
                text: i18n.t('products.manufacturer_id'),
                value: 'manufacturer'
            },
            {
                text: i18n.t('products.types.titleSingle'),
                value: 'product_type'
            }
        ],
        tooltipHint: 'reports.hints.report_type'
    },
    [Parameters.TASK_TYPES]: {
        langPath: 'reports.parameters.',
        autocomplete:{
            items: Object.values(taskTypes).map(key => {
                return {
                    text: i18n.t(taskNames[key]),
                    value: key
                };
            }),
            multiple: true
        }
    },
    [Parameters.INCLUDE_MISSING_PRODUCTS]: {
        langPath: 'reports.parameters.',
        checkbox: true
    },
    [Parameters.INCLUDE_EMPTY_LOCATIONS]: {
        langPath: 'reports.parameters.',
        checkbox: true
    },
    [Parameters.CARRIER]: {
        icon: '$carrier',
        langPath: 'tasks.externalOrder.',
        xAutocomplete: emptySource(),
        sticky: true
    },
    [Parameters.CARRIER_SERVICE]: {
        icon: '$carrierService',
        langPath: 'tasks.externalOrder.',
        xAutocomplete: {
            apiDataSource: () => BaseAPI.emptyDataSource(),
            resultTransform: el => ({
                text: el.name,
                value: el.id,
                serviceParameters: el.carrier_service_parameters
            }),
            selectedValue: null
        },
        sticky: true
    },
    [Parameters.EXTERNAL_AUTHOR]: {
        icon: '$cacheUser',
        langPath: 'tasks.externalOrder.',
    },
    [Parameters.EXTERNAL_AUTHOR_NAME]: {
        icon: '$itemName',
        langPath: 'tasks.externalOrder.',
    },
    [Parameters.EXTERNAL_NOTE]: {
        icon: '$description',
        textarea: true,
        langPath: 'tasks.externalOrder.',
    },
    [Parameters.STORE_EXTERNAL_IMAGES]: {
        checkbox: true,
        icon: '$foilImage',
        langPath: 'products.images.',
    }
};

export const ParameterDefinitions = {
    [Parameters.STOCK]: {
        render: ParameterRenders[[Parameters.STOCK]],
        type: 'Number',
        default: null
    },
    [Parameters.SUBSTOCKS]: {
        render: ParameterRenders[[Parameters.SUBSTOCKS]],
        require: [Parameters.STOCK],
        type: '[Number]',
        default: null
    },
    [Parameters.SUBSTOCK]: {
        render: ParameterRenders[[Parameters.SUBSTOCK]],
        require: [Parameters.STOCK],
        type: 'Number',
        default: null
    },
    [Parameters.DATE]: {
        render: ParameterRenders[[Parameters.DATE]],
        type: 'Date',
        default: null
    },
    [Parameters.FROM_DATE]: {
        render: ParameterRenders[[Parameters.FROM_DATE]],
        type: 'Date',
        default: null
    },
    [Parameters.TO_DATE]: {
        render: ParameterRenders[[Parameters.TO_DATE]],
        type: 'Date',
        default: null
    },
    [Parameters.INTERVAL]: {
        render: ParameterRenders[[Parameters.INTERVAL]],
        type: 'String',
        default: TimeIntervals.DAY
    },
    [Parameters.INTERVAL_FROM]: {
        render: ParameterRenders[[Parameters.INTERVAL_FROM]],
        type: 'Date',
        default: null
    },
    [Parameters.INTERVAL_TO]: {
        render: ParameterRenders[[Parameters.INTERVAL_TO]],
        type: 'Date',
        default: null
    },
    [Parameters.MANUFACTURERS]: {
        render: ParameterRenders[[Parameters.MANUFACTURERS]],
        type: '[Number]',
        default: null
    },
    [Parameters.PRODUCT_TYPES]: {
        render: ParameterRenders[[Parameters.PRODUCT_TYPES]],
        type: '[Number]',
        default: null
    },
    [Parameters.PRODUCTS]: {
        render: ParameterRenders[[Parameters.PRODUCTS]],
        type: '[Number]',
        default: null
    },
    [Parameters.LOCATIONS]: {
        render: ParameterRenders[[Parameters.LOCATIONS]],
        type: '[Number]',
        required: false,
        require: [Parameters.STOCK],
        default: null
    },
    [Parameters.REPORT_TYPE]: {
        render: ParameterRenders[[Parameters.REPORT_TYPE]],
        type: 'String',
        default: 'product'
    },
    [Parameters.TASK_TYPES]: {
        render: ParameterRenders[[Parameters.TASK_TYPES]],
        type: '[String]',
        required: false,
        default: ''
    },
    [Parameters.INCLUDE_MISSING_PRODUCTS]: {
        render: ParameterRenders[[Parameters.INCLUDE_MISSING_PRODUCTS]],
        type: 'Boolean',
        default: false
    },
    [Parameters.INCLUDE_EMPTY_LOCATIONS]: {
        render: ParameterRenders[[Parameters.INCLUDE_EMPTY_LOCATIONS]],
        type: 'Boolean',
        default: false
    },
    [Parameters.CARRIER]: {
        render: ParameterRenders[[Parameters.CARRIER]],
        type: 'Number',
        require: [Parameters.SUBSTOCK],
        default: null
    },
    [Parameters.CARRIER_SERVICE]: {
        render: ParameterRenders[[Parameters.CARRIER_SERVICE]],
        require: [Parameters.CARRIER],
        type: 'Number',
        default: null
    },
    [Parameters.CARRIER_SERVICE_PARAMETERS]: {
        require: [Parameters.CARRIER_SERVICE],
        type: 'Number',
        default: null
    },
    [Parameters.EXTERNAL_AUTHOR]: {
        render: ParameterRenders[[Parameters.EXTERNAL_AUTHOR]],
        type: 'String',
        default: ''
    },
    [Parameters.EXTERNAL_AUTHOR_NAME]: {
        render: ParameterRenders[[Parameters.EXTERNAL_AUTHOR_NAME]],
        type: 'String',
        default: ''
    },
    [Parameters.EXTERNAL_NOTE]: {
        render: ParameterRenders[[Parameters.EXTERNAL_NOTE]],
        type: 'String',
        default: ''
    },
    [Parameters.STORE_EXTERNAL_IMAGES]: {
        render: ParameterRenders[Parameters.STORE_EXTERNAL_IMAGES],
        type: 'Boolean',
        default: false
    }
};

export function ParametersForm(parameters) {
    const usedParameters = filterParameters(parameters);
    Object.keys(usedParameters)
        .forEach(item => usedParameters[item] = ParameterDefinitions[item]?.default ?? null);
    return usedParameters;
}

export function ParametersRender(parameters, readOnly) {
    const usedParameters = filterParameters(parameters);
    Object.keys(usedParameters).forEach(item => {
        if (ParameterDefinitions[item].render === undefined) {
            delete usedParameters[item];
        }
    });
    Object.entries(usedParameters).filter(parameter => ParameterDefinitions[parameter[0]].render !== undefined)
        .forEach(([key, value]) => {
            // Create hard copy for intervals to be able to change them later with interval not affecting unwanted fields
            if (ParameterDefinitions[key].render.intervalPicker) {
                usedParameters[key] = JSON.parse(JSON.stringify(ParameterDefinitions[key].render));
            } else {
                usedParameters[key] = ParameterDefinitions[key].render;
            }
            usedParameters[key].readonly = readOnly;
            usedParameters[key].required = value.is_required;
        });
    return usedParameters;
}

function filterParameters(parameters) {
    const usedParameters = [];
     parameters.forEach(item => {
        if (ParameterDefinitions[item.key]) {
            if (ParameterDefinitions[item.key].require) {
                ParameterDefinitions[item.key].require.forEach(req => usedParameters.push({
                    key: req,
                    is_required: item.is_required
                }));
            }
            usedParameters.push(item);
        }
    });
    return Object.fromEntries(usedParameters
        .map(parameter => [parameter.key, parameter]));
}
