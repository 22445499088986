<template>
  <div
    @mouseenter="handleGlobalMouseEnter()"
  >
    <v-expand-transition>
      <v-card
        v-show="showMap"
        style="margin-bottom: 6px"
      >
        <v-card-title>
          {{ $t('map.stockObjects') }}
        </v-card-title>
        <v-card-text>
          <v-tabs
            height="30"
          >
            <v-tabs-slider />
            <v-tab
              width="30"
              @click="selectTab(MapObjectType.RACK)"
            >
              <v-icon
                small
                left
              >
                $rack
              </v-icon>
              {{ $t('map.racks') }}
            </v-tab>

            <v-tab
              @click="selectTab(MapObjectType.LOCATIONS_GROUP)"
            >
              <v-icon
                small
                left
              >
                $locationGroup
              </v-icon>
              {{ $t('map.locationGroups') }}
            </v-tab>

            <v-tab
              @click="selectTab(MapObjectType.ZONE)"
            >
              <v-icon
                small
                left
              >
                $zone
              </v-icon>
              {{ $t('map.zones') }}
            </v-tab>

            <v-tab
              @click="selectTab(MapObjectType.OBSTACLE)"
            >
              <v-icon
                small
                left
              >
                $obstacle
              </v-icon>
              {{ $t('map.obstacles') }}
            </v-tab>
          </v-tabs>

          <div
            v-if="loading && !saving"
            class="text-center pt-5"
          >
            <v-progress-linear
              indeterminate
              color="primary"
            />
          </div>

          <v-list-item-group
            v-if="!loading || saving"
            v-model="selectedItem"
            color="primary"
          >
            <v-list
              class="overflow-y-auto"
              height="200"
              dense
            >
              <div
                v-for="(object, i) in objectList(selectedTab)"
                :key="i"
              >
                <v-list-item
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ getObjectIdentityName(object) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </div>
            </v-list>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <ObjectDetailPanel
      v-if="selectedObject !== null && showMap"
    />

    <RackSetupPanel
      v-show="!showMap"
    />
  </div>
</template>

<script>
    import RackSetupPanel from '@/app/stocks/map/components/panels/RackSetupPanel.component.vue';
    import { MapObjectType, PanelMode, ZoneObjectType } from '@/enum/stock_map';
    import { MapMixin } from "@/app/mixins/MapMixin";
    import ObjectDetailPanel from "@/app/stocks/map/components/panels/ObjectDetailPanel.component.vue";
    export default {
        name: 'SidePanels',
        components: {
            ObjectDetailPanel,
            RackSetupPanel
        },
        mixins: [ MapMixin ],
        props: {},
        data: () => ({
            // index of selected item in array
            selectedItem: null,
            selectedItemListPos: null,
            MapObjectType: MapObjectType,
            selectedTab: MapObjectType.RACK,
        }),
        watch: {
            selectedObject: function () {
                this.selectedItem = undefined;
                this.selectedItemListPos = undefined;
                if (this.selectedObject !== null) {
                    for (let i = 0; i < this.objectList(this.selectedObject.type).length; ++i) {
                        if (this.objectList(this.selectedObject.type)[i] === this.selectedObject) {
                            this.selectedItemListPos = i;
                            if (this.selectedObject.type === this.selectedTab) {
                                this.selectedItem = this.selectedItemListPos;
                            }
                            return;
                        }
                    }
                }
            },
            selectedItem: function () {
                if (!isNaN(this.selectedItem)) {
                    if (this.objectList(this.selectedTab)[this.selectedItem] !== this.selectedObject) {
                        this.$store.commit('storageMap/selectObject', {
                            object: this.objectList(this.selectedTab)[this.selectedItem]
                        });
                        this.selectedItemListPos = this.selectedItem;
                    }
                }
            },
            selectedTab: function () {
                if (this.selectedObject === null || this.selectedObject.type !== this.selectedTab) {
                    this.selectedItem = undefined;
                }
                else {
                    this.selectedItem = this.selectedItemListPos;
                }
            },
        },
        methods: {
            selectTab: function (tab) {
                this.selectedTab = tab;
            },
            objectList: function (objectType) {
                if (objectType === MapObjectType.ZONE) {
                    return this.getZones;
                }
                else {
                    return this.getObjects.filter(obj => {
                        return obj.type === objectType;
                    });
                }

            },
            getObjectIdentityName: function (object) {
                if (object.type === MapObjectType.RACK) {
                    return object.name;
                }
                if (object.type === MapObjectType.OBSTACLE) {
                    return object.name;
                }
                if (object.type === MapObjectType.ZONE) {
                    const types = [];
                    for (const zoneType of object.zoneTypes) {
                        types.push(this.$t(ZoneObjectType[zoneType]));

                    }
                    return object.name + ' (' + types.join(', ') + ')';
                }
                if (object.type === MapObjectType.LOCATIONS_GROUP) {
                    return object.locations.map(loc => (loc.name === loc.code || loc.code === null) ? loc.name : (loc.name + " (" + loc.code + ")")).join(', ');
                }
            },
            // If user mouses over to this element while some temporary actions are still
            // happening (like moving rack or duplicating it), change the mode to default
            // -> cease those temporary actions
            handleGlobalMouseEnter() {
                if ([PanelMode.COPY, PanelMode.MOVE, PanelMode.NEW_RACK, PanelMode.NEW_OBSTACLE, PanelMode.NEW_ZONE, PanelMode.NEW_LOCATIONS_GROUP].includes(this.panelMode)) {
                    this.$store.commit('storageMap/setPanelMode', {
                        mode: PanelMode.STANDBY
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
