import {TableFilter} from "@/enum/table_filter";
import {ImportsAPI} from "@/api/ImportsAPI";
import i18n from "@/service/lang/i18n";

export function ExportsConfigForm() {
    return {
        name: '',
        domain: '',
    };
}

export function ExportsConfigFormRender() {
    return {
        name: {
            max: 50,
            icon: '$carrierName',
            required: true,
        },
        domain: {
            icon: '$tableFileType',
            select: [],
            valueLangPath: 'exports.domains.'
        }
    };
}

export const exportsConfigTable = {
    name:{
        filterType: TableFilter.TEXT
    },
    domain: {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete:{
            callFn: () => ImportsAPI.getTemplates(),
            thenFn: response => response.data.filter(item => item.supports_export).map(item => ({
                text: i18n.t('exports.domains.' + item.domain),
                value: item.domain
            }))
        }
    },
};
