<template>
  <div>
    <template v-if="details">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <span class="text-caption">{{ $t('tasks.locationTransfer.stock_id') + ': ' }}</span>
                {{ details.stock.name }}
              </div>
              <div>
                <span class="text-caption">{{
                  $t('tasks.locationTransfer.allow_filled_locations') + ': '
                }}</span>
                {{ details.allow_filled_locations | yesOrNo }}
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :stock-id="details.stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments :attachments="details.attachments" />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {taskTypes} from "@/enum/task_type";

    export default {
        name: "LocationTransferAssignment",
        components: {TaskAttributes, TaskAttachments, Notes, EntityHistory},
        mixins: [ACLMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            type: taskTypes.LOCATION_TRANSFER,
        }),
        computed: {
            details: function () {
                return this.taskInfo.details;
            }
        }
    };
</script>

<style scoped>

</style>
