const TaskMovementType = {
    FROM_SOURCE_TO_INVENTORY: "move-from-source-to-inventory",
    FROM_INVENTORY_TO_SOURCE: "move-from-inventory-to-source",
    FROM_INVENTORY_TO_DESTINATION: "move-from-inventory-to-destination",
    FROM_DESTINATION_TO_INVENTORY: "move-from-destination-to-inventory",
    FROM_SOURCE_TO_DESTINATION: "move-from-source-to-destination",
    FROM_DESTINATION_TO_SOURCE: "move-from-destination-to-source"
};

export {TaskMovementType};
