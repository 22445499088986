<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedCarrier !== null"
      :show.sync="confirmDialog"
      :text="deletedCarrier.name + ', ' + deletedCarrier.type"
      @confirm-delete="reallyDeleteCarrier"
    />
    <x-data-table
      :headers="headers"
      :loading.sync="loading"
      :items="tableItems"
      :item-class="item => itemClass(item)"
      :actions="actions"
      :search-hint="$t('carriers.searchHint')"
    >
      <template #item.tableImage="{ item }">
        <td>
          <v-img
            v-if="item.image !== null && item.image.url !== null"
            :src="item.image.url"
            contain
            max-height="40px"
            max-width="40px"
            style="float: right"
          />
        </td>
      </template>
      <template #item.tableFoilImage="{ item }">
        <td>
          <v-img
            v-if="item.foil_image !== null && item.foil_image.url !== null"
            :src="item.foil_image.url"
            contain
            max-height="40px"
            max-width="40px"
            style="float: right"
          />
        </td>
      </template>
      <template #item.services="{ item }">
        <CarrierServicesList
          :text-class="itemClass(item)"
          :services="item.services"
          :inline="true"
        />
      </template>
      <template
        v-if="ownerId"
        #item.contracted="{ item }"
      >
        {{ item.contracted | yesOrNo }}
      </template>
      <template
        v-else
        #item.active="{ item }"
      >
        {{ item.active | yesOrNo }}
      </template>
      <template #footer>
        <TableAddItemButton
          :to="carrierCreateLink"
          label="carriers.create.title"
        />
      </template>
    </x-data-table>
  </div>
</template>

<script>
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {createHeaders} from "@/utils/table";
    import {carrierTable, carrierTableInSubstock} from "@/app/carriers/definitions/carrier.form";
    import CarrierServicesList from "@/app/carriers/components/CarrierServicesList.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "CarriersListComponent",
        components: {TableAddItemButton, CarrierServicesList, ConfirmDeleteDialog},
        props: {
            ownerId: {
                type: Number,
                default: null
            },
            stockId: {
                type: Number,
                default: null
            },
            subStockId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            loading: true,
            items: [],
            triggerActions: 0,
            carrierCreateLink: '/carriers/create',
            deletedCarrier: null,
            confirmDialog: false
        }),
        computed: {
            headers: function () {
                return this.ownerId ? createHeaders(carrierTableInSubstock, 'carriers.')
                    : createHeaders(carrierTable, 'carriers.');
            },
            actions: function () {
                this.triggerActions;
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/carriers/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        action: this.updateItem,
                        routerLink: item => '/carriers/' + item.id + '/update',
                        icon: '$updateItem',
                        subscriptIcon: '$carrier',
                        label: 'carriers.update.titleSimple'
                    }, {
                        condition: () => !this.ownerId,
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }, {
                        condition: item => item.contracted && !item.allowedForThisSubstock,
                        loading: this.loading,
                        action: this.addSubstock,
                        icon: "$addItem",
                        subscriptIcon: '$substock',
                        label: 'carriers.contracted.update.substock.title'
                    }, {
                        condition: item => item.allowedForThisSubstock,
                        loading: this.loading,
                        action: this.removeSubstock,
                        icon: '$removeItem',
                        subscriptIcon: '$substock',
                        label: 'carriers.contracted.delete.substock.title'
                    }, {
                        condition: item => item.contracted,
                        loading: this.loading,
                        routerLink: item => '/stocks/' + this.stockId + '/substocks/' + this.subStockId + '/carriers/'
                            + item.id + '/update?ownerId=' + this.ownerId,
                        icon: '$updateItem',
                        subscriptIcon: '$carrierParameters',
                        label: 'carriers.contracted.update.titleSimple'
                    }, {
                        condition: item => item.contracted,
                        loading: this.loading,
                        action: this.removeContract,
                        icon: '$removeItem',
                        subscriptIcon: '$owner',
                        label: 'carriers.contracted.delete.titleSimple'
                    }, {
                        condition: item => this.ownerId && !item.contracted,
                        loading: this.loading,
                        routerLink: item => '/stocks/' + this.stockId + '/substocks/' + this.subStockId + '/carriers/'
                            + item.id + '/create?ownerId=' + this.ownerId,
                        icon: '$addItem',
                        subscriptIcon: '$owner',
                        label: 'carriers.contracted.create.label'
                    }
                ];
            },
            tableItems: function () {
                return this.ownerId ? this.items.filter(item => item.active) : this.items;
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                ShipmentCarrierAPI.getAll().then(response => {
                    const promises = [];
                    this.items = response.data
                        .map(item => {
                            item.contracted = false;
                            return item;
                        });
                    if (this.ownerId !== null) {
                        promises.push(
                            ShipmentCarrierAPI.getAllContractedByOwner(this.ownerId).then(contractedCarriers => {
                                this.items.map(carrier => {
                                    const contractedCarrier = contractedCarriers.data.find(contractedCarrier => contractedCarrier.carrier.id === carrier.id);
                                    if (contractedCarrier) {
                                        carrier.contracted = true;
                                        carrier.parameters = contractedCarrier.parameters;
                                        carrier.substocks = contractedCarrier.subordinate_stocks.map(substock => substock.id);
                                        carrier.allowedForThisSubstock = carrier.substocks.length && carrier.substocks.find(id => id === this.subStockId) !== undefined;
                                    } else {
                                        carrier.contracted = false;
                                        carrier.allowedForThisSubstock = false;
                                    }
                                    return carrier;
                                });
                            })
                        );
                    }
                    this.items.map(carrier => {
                        promises.push(
                            ShipmentCarrierAPI.getServices(carrier.id).then(response => {
                                carrier.services = response.data;
                            })
                        );
                    });
                    Promise.all(promises).catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                            this.triggerActions++;
                        });
                }).catch(err => {
                    this.snack(err);
                    this.loading = false;
                });
            },
            itemClass: function (item) {
                if (this.ownerId) {
                    return !item.allowedForThisSubstock ? 'text--disabled' : '';
                } else {
                    return !item.active ? 'text--disabled' : '';
                }
            },
            deleteItem: function (item) {
                this.deletedCarrier = item;
                this.confirmDialog = true;
            },
            reallyDeleteCarrier: function () {
                this.loading = true;
                this.confirmDialog = false;
                ShipmentCarrierAPI.delete(this.deletedCarrier.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'carriers.delete.done',
                            params: [this.deletedCarrier.name]
                        });
                        this.getData();
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addSubstock: function (item) {
                this.loading = true;
                item.substocks.push(this.subStockId);
                ShipmentCarrierAPI.updateContractedCarrierSubstocks(item.id, this.ownerId, {subordinate_stocks: item.substocks})
                    .then(() => {
                        this.advancedSnack({
                            text: 'carriers.contracted.update.substock.done',
                            params: [item.name]
                        });
                        item.allowedForThisSubstock = true;
                        this.triggerActions++;
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            removeSubstock: function (item) {
                this.loading = true;
                item.substocks = item.substocks.filter(id => id !== this.subStockId);
                ShipmentCarrierAPI.updateContractedCarrierSubstocks(item.id, this.ownerId, {subordinate_stocks: item.substocks})
                    .then(() => {
                        this.advancedSnack({
                            text: 'carriers.contracted.delete.substock.done',
                            params: [item.name]
                        });
                        item.allowedForThisSubstock = false;
                        this.triggerActions++;
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            removeContract: function (item) {
                this.loading = true;
                ShipmentCarrierAPI.removeContract(item.id, this.ownerId).then(() => {
                    this.advancedSnack({
                        text: 'carriers.contracted.delete.done',
                        params: [item.name]
                    });
                    item.contracted = false;
                    item.parameters = undefined;
                    item.substocks = undefined;
                    item.allowedForThisSubstock = false;
                    this.triggerActions++;
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            }
        }
    };
</script>

<style scoped>

</style>
