<template>
  <div>
    <v-btn
      small
      depressed
      :color="saveButtonState.color"
      :disabled="saveButtonState.disabled"
      class="mb-4"
      @click="saveMap"
    >
      <v-progress-circular
        v-if="saving || loading"
        indeterminate
        class="mr-2"
        :size="17"
        :width="2"
      />
      <v-icon
        v-if="!saving && !loading"
        left
      >
        {{ saveButtonState.icon }}
      </v-icon>
      {{ saveButtonState.label }}
    </v-btn>

    <v-btn
      v-if="saveButtonState === mapSaveButtonStates.SAVED && locationsSynchronizationNeeded && !saving && !loading"
      small
      depressed
      color="accent"
      class="mb-4 ml-4"
      @click="openSynchronizeLocationsDialog"
    >
      <v-progress-circular
        v-if="locationsSyncInProgress"
        indeterminate
        class="mr-2"
        :size="17"
        :width="2"
      />
      <v-icon
        v-if="!locationsSyncInProgress"
        left
      >
        {{ locSyncButtonState.icon }}
      </v-icon>
      {{ locSyncButtonState.label }}
    </v-btn>

    <SynchronizeLocationsDialog
      v-if="synchronizeLocationsDialog"
      :activate="synchronizeLocationsDialog"
      @closeDialog="closeSynchronizeLocationsDialog"
      @startSyncCreateStockLocations="startSyncCreateStockLocations"
      @startSyncDeleteUnmappedStockLocations="startSyncDeleteUnmappedStockLocations"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {MapMixin} from "@/app/mixins/MapMixin";
    import {SnackbarMixin} from "@/app/mixins/SnackbarMixin";
    import i18n from "@/service/lang/i18n";
    import SynchronizeLocationsDialog
        from "@/app/stocks/map/components/dialogs/SynchronizeLocationsDialog.component.vue";
    import {StockAPI} from "@/api/StockAPI";
    import {EventBus} from "@/service/EventBus";
    export default {
        name: 'MapEditorToolbar',
        components: {
            SynchronizeLocationsDialog,
        },
        mixins: [RouteParamsMapperMixin, MapMixin, SnackbarMixin],
        data: () => ({
            synchronizeLocationsDialog: false,
            locationsSyncInProgress: false,
            locationSyncPercentageIndicator: {
                syncsAmount: 0,
                syncsDone: 0
            },
        }),
        computed: {
            mapSaveButtonStates(){
                return {
                    SAVE: {color: 'accent', disabled: false, label: i18n.t('map.save'), icon: '$saveItem'},
                    SAVED: {color: 'accent', disabled: true, label: i18n.t('map.saved'), icon: '$success'},
                    ERROR_DUPLICIT: {color: 'error', disabled: false, label: i18n.t('map.mapCannotSaveDuplicit'), icon: '$saveItem'},
                    SAVING: {color: 'accent', disabled: false, label: Number.parseInt((this.savePercentageIndicator.savesDone / this.savePercentageIndicator.savesAmount)*100,10) + "%", icon: '$saveItem'}
                };
            },
            locSyncButtonStates(){
                return {
                    SYNC: {color: 'accent', disabled: false, label: i18n.t('map.locationsSync.sync'), icon: '$locationGroup'},
                    SYNCING: {color: 'accent', disabled: false, label: Number.parseInt((this.locationSyncPercentageIndicator.syncsDone / this.locationSyncPercentageIndicator.syncsAmount)*100,10) + "%", icon: '$locationGroup'},
                };
            },
            saveButtonState() {
                if (this.saving) {
                    return this.mapSaveButtonStates.SAVING;
                }
                else if (this.duplicitLocations) {
                    return this.mapSaveButtonStates.ERROR_DUPLICIT;
                }
                else if (this.changes && !this.duplicitLocations) {
                    return this.mapSaveButtonStates.SAVE;
                }
                else {
                    return this.mapSaveButtonStates.SAVED;
                }
            },
            locSyncButtonState() {
                if (this.locationsSyncInProgress) {
                    return this.locSyncButtonStates.SYNCING;
                }
                else {
                    return this.locSyncButtonStates.SYNC;
                }
            },
            locationsSynchronizationNeeded() {
                return this.notMappedStockLocations || this.stockLocationsToCreate;
            },
        },
        methods: {
            closeSynchronizeLocationsDialog: function () {
                this.synchronizeLocationsDialog = false;
            },
            openSynchronizeLocationsDialog: function () {
                if (this.saveButtonState === this.mapSaveButtonStates.SAVED && this.locationsSynchronizationNeeded && this.locSyncButtonState === this.locSyncButtonStates.SYNC) {
                    this.synchronizeLocationsDialog = true;
                }
            },
            startSyncCreateStockLocations: function () {
                this.locationsSyncInProgress = true;
                const locations = this.$store.getters["storageMap/getStockLocationsToCreate"];
                this.locationSyncPercentageIndicator.syncsAmount = locations.length + 1;
                this.locationSyncPercentageIndicator.syncsDone = 0;
                const promises = [];

                for (const location of locations){
                    promises.push(StockAPI.createLocation(this.stockId,{
                        name: location.name,
                        code: location.code,
                        is_expedition: false,
                        is_mobile: false,
                        availability: 50
                    }).then(() => this.locationSyncPercentageIndicator.syncsDone++));
                }

                return Promise.all(promises).catch(err => {
                    this.snack(err);
                }).finally( () => {
                    this.loadMap().finally( () => {
                        this.locationSyncPercentageIndicator.syncsDone++;
                        this.locationsSyncInProgress = false;
                        EventBus.$emit('map-locations-synchronize-finished');
                    });
                });
            },
            startSyncDeleteUnmappedStockLocations: function () {
                this.locationsSyncInProgress = true;
                const locations = this.$store.getters["storageMap/getNotMappedStockLocations"];
                this.locationSyncPercentageIndicator.syncsAmount = locations.length + 1;
                this.locationSyncPercentageIndicator.syncsDone = 0;
                const promises = [];

                for (const location of locations){
                    promises.push(StockAPI.deleteLocation(this.stockId, location.stockLocationId).then(() => this.locationSyncPercentageIndicator.syncsDone++));
                }

                return Promise.all(promises).catch(err => {
                    this.snack(err);
                }).finally( () => {
                    this.loadMap().finally( () => {
                        this.locationSyncPercentageIndicator.syncsDone++;
                        this.locationsSyncInProgress = false;
                        EventBus.$emit('map-locations-synchronize-finished');
                    });
                });

            },
        }
    };
</script>

<style scoped>

</style>
