import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.render),function(field,key){return _c('span',{key:key,staticClass:"mx-3 d-flex justify-center",class:{ 'hidden': !(field.show === undefined || (typeof field.show === 'function' ? field.show(_vm.form) : field.show)) },style:((_vm.stretchForm ? 'width: calc('+ (field.stretchCoefficient || 1)*100 +'% - 24px)' + '; min-' : '')
      + 'width: calc(' + 20 * (field.sizeCoefficient || 1) + 'em - 24px)' /* 24 px has to be same as mx-3 above */)},[(field.header === true)?_c('h3',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + key))+" ")]):(_vm.$scopedSlots[key] !== undefined)?_vm._t(key,null,{"fieldRender":field}):_vm._e(),(field.xAutocomplete !== undefined)?_c('x-autocomplete',{ref:key,refInFor:true,attrs:{"append-outer-icon":field.help !== undefined ? 'help_outline' : '',"api-data-source":field.xAutocomplete.apiDataSource,"api-filter":field.xAutocomplete.apiFilter,"api-sort":field.xAutocomplete.apiSort,"api-primary-key-name":field.xAutocomplete.apiPrimaryKeyName,"all-item-label":(field.required === true) ? undefined : field.xAutocomplete.allItemLabel,"clearable":!field.sticky,"disabled":field.readonly,"disable-autoselect-first":!!field.xAutocomplete.disableAutoselectFirst,"error-messages":field.errors,"hint":_vm.$t(field.hint),"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"multiple":!!field.xAutocomplete.multiple,"chips":!!field.xAutocomplete.chips,"persistent-hint":field.hint !== undefined,"prepend-icon":field.icon,"readonly":field.readonly,"result-transform":field.xAutocomplete.resultTransform,"rules":_vm.getFieldRules(field),"suffix":(field.required === true) ? '*' : '',"autocomplete":"off"},on:{"change":_vm.clearUserErrors,"update:loading":val => _vm.$set(field, 'loading', val),"click:append-outer":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(_vm.isChief && field.createNew !== undefined
          && (field.createNew.condition === undefined || field.createNew.condition(_vm.form)))?{key:"no-data",fn:function(){return [(field.loading === false)?_c(VList,[_c(VListItem,{on:{"click":field.createNew.action}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(field.createNew.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(field.createNew.label)))])],1)],1)],1):_c('span')]},proxy:true}:null,(_vm.$scopedSlots[key + '-append-each-item'] !== undefined)?{key:"append-each-item",fn:function({item}){return [_vm._t(key + '-append-each-item',null,{"item":item})]}}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}}):(field.autocomplete !== undefined)?_c(field.autocomplete?.allowCustom ? 'v-combobox' : 'v-autocomplete',{ref:key,refInFor:true,tag:"component",attrs:{"append-outer-icon":field.help !== undefined ? 'help_outline' : '',"chips":!!field.autocomplete.chips,"clearable":!field.sticky,"small-chips":field.autocomplete.chips,"disabled":field.readonly,"error-messages":field.errors,"hint":_vm.$t(field.hint),"items":field.autocomplete.items,"item-text":field.autocomplete.render || 'text',"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"loading":field.loading,"multiple":!!field.autocomplete.multiple,"persistent-hint":field.hint !== undefined,"prepend-icon":field.icon,"readonly":field.readonly,"rules":_vm.getFieldRules(field),"suffix":(field.required === true) ? '*' : '',"autocomplete":"off"},on:{"change":_vm.clearUserErrors,"click:append-outer":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(_vm.isChief && field.createNew !== undefined
          && (field.createNew.condition === undefined || field.createNew.condition(_vm.form)))?{key:"no-data",fn:function(){return [_c(VList,[_c(VListItem,{on:{"click":field.createNew.action}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(field.createNew.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(field.createNew.label)))])],1)],1)],1)]},proxy:true}:null,(_vm.$scopedSlots[key + '-item'] !== undefined)?{key:"item",fn:function({ item }){return [_vm._t(key + '-item',null,{"item":item})]}}:(field.tooltipHint)?{key:"append-outer",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"pl-3"},'v-icon',attrs,false),on),[_vm._v(" $info ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(field.tooltipHint)))])])]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}}):(field.select !== undefined)?_c(VSelect,{ref:key,refInFor:true,attrs:{"append-outer-icon":field.help !== undefined ? 'help_outline' : '',"disabled":field.readonly,"error-messages":field.errors,"hint":_vm.$t(field.hint),"items":field.select,"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"persistent-hint":field.hint !== undefined,"prepend-icon":field.icon,"readonly":field.readonly,"rules":_vm.getFieldRules(field),"suffix":field.required === true ? '*' : '',"autocomplete":"off"},on:{"change":_vm.clearUserErrors,"click:append-outer":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(_vm.$scopedSlots[key + '-append-outer'] !== undefined)?{key:"append-outer",fn:function(){return [_vm._t(key + '-append-outer')]},proxy:true}:(field.tooltipHint)?{key:"append-outer",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({staticClass:"pl-3"},'v-icon',attrs,false),on),[_vm._v(" $info ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(field.tooltipHint)))])])]},proxy:true}:null,(field.valueLangPath)?{key:"selection",fn:function(value){return [_vm._v(" "+_vm._s(_vm.$t(field.valueLangPath + value.item))+" ")]}}:null,(_vm.$scopedSlots[key + '-item'] !== undefined)?{key:"item",fn:function({ item }){return [_vm._t(key + '-item',null,{"item":item})]}}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}}):(field.textarea === true)?_c(VTextarea,{ref:key,refInFor:true,attrs:{"append-outer-icon":field.help !== undefined ? 'help_outline' : '',"auto-grow":"","disabled":field.readonly,"error-messages":field.errors,"hint":_vm.$t(field.hint),"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"name":key,"outlined":"","persistent-hint":field.hint !== undefined,"prepend-icon":(field.icon),"readonly":field.readonly,"rows":1,"rules":_vm.getFieldRules(field),"suffix":field.required === true ? '*' : ''},on:{"change":_vm.clearUserErrors,"click:append-outer":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}}):(field.checkbox === true)?_c(VCheckbox,{ref:key,refInFor:true,attrs:{"append-icon":field.help !== undefined ? 'help_outline' : '',"color":"secondary","disabled":field.readonly,"error-messages":field.errors,"hint":_vm.$t(field.hint),"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"name":key,"persistent-hint":field.hint !== undefined,"prepend-icon":(field.icon),"readonly":field.readonly},on:{"change":_vm.clearUserErrors,"click:append":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(_vm.$scopedSlots[key + '-append'] !== undefined)?{key:"label",fn:function(){return [_vm._t(key + '-append')]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}}):(field.datePicker || field.dateTimePicker ||
        (field.intervalPicker && field.intervalPicker.interval === _vm.TimeIntervals.DAY))?_c('FormDateTimePicker',_vm._b({attrs:{"end-of-day-time":!!field.endOfDayTime,"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"name":key,"time-picker":!!field.dateTimePicker,"disabled":field.readonly},on:{"input":_vm.clearUserErrors,"clickHelp":function($event){return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(field.appendOuter !== undefined)?{key:"append-outer",fn:function(){return [_vm._t(key + '-append-outer')]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}},'FormDateTimePicker',field,false)):(field.intervalPicker && field.intervalPicker.interval !== _vm.TimeIntervals.DAY)?_c('FormIntervalPicker',_vm._b({attrs:{"interval":field.intervalPicker.interval,"is-interval-from":field.intervalPicker.isIntervalFrom,"allowed-day-of-week":field.intervalPicker.allowedDayOfWeek,"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"name":key,"time-picker":!!field.dateTimePicker,"disabled":field.readonly,"required":field.required},on:{"input":_vm.clearUserErrors,"clickHelp":function($event){return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(field.appendOuter !== undefined)?{key:"append-outer",fn:function(){return [_vm._t(key + '-append-outer')]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}},'FormIntervalPicker',field,false)):(field.filePicker)?_c(VFileInput,_vm._b({directives:[{name:"async-validate",rawName:"v-async-validate"}],attrs:{"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"name":key,"rules":_vm.getFieldRules(field),"suffix":field.required === true ? '*' : '',"async-rules":field.asyncRules,"disabled":field.readonly},on:{"click":function($event){return _vm.$emit('fileClick')}},scopedSlots:_vm._u([(field.appendOuter !== undefined)?{key:"append-outer",fn:function(){return [_vm._t(key + '-append-outer')]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, $$v)},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}},'v-file-input',field,false)):_c(VTextField,{ref:key,refInFor:true,attrs:{"append-icon":field.type === 'password' ? (_vm.pwVisible[key] ? '$showItem' : '$hideItem') : undefined,"append-outer-icon":field.help !== undefined ? 'help_outline' : '',"counter":field.max,"disabled":field.readonly,"error-messages":field.errors,"hint":_vm.$t(field.hint),"label":field.label ? field.label : (field.langPath ? _vm.$t(field.langPath + key) : _vm.$t(_vm.langPath + key)),"loading":field.loading,"name":key,"persistent-hint":field.hint !== undefined,"prepend-icon":field.icon,"readonly":field.readonly,"rules":_vm.getFieldRules(field),"suffix":field.required === true ? '*' : '',"type":(field.type === undefined ? 'text'
        : (field.type === 'password' ? (_vm.pwVisible[key] ? 'text' : 'password')
          : field.type)),"min":field.min,"step":field.step},on:{"change":_vm.clearUserErrors,"input":_vm.clearUserErrors,"blur":function($event){return _vm.handleValue(key, field.type)},"click:append":function($event){return _vm.pwVisibleClick(key)},"click:append-outer":function($event){$event.stopPropagation();return _vm.helpClick(field.help)}},scopedSlots:_vm._u([(field.appendOuter !== undefined)?{key:"append-outer",fn:function(){return [_vm._t(key + '-append-outer')]},proxy:true}:null],null,true),model:{value:(
        /* eslint-disable-next-line vue/no-mutating-props */
        _vm.form[key]
      ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
        _vm.form, key, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n        /* eslint-disable-next-line vue/no-mutating-props */\n        form[key]\n      "}})],2)}),_c(VDialog,{attrs:{"max-width":"590"},model:{value:(_vm.helpDialog),callback:function ($$v) {_vm.helpDialog=$$v},expression:"helpDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.helpTitle)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.helpContent)+" ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }