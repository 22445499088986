<template>
  <v-snackbar
    v-model="show"
    :bottom="true"
    :timeout="timeout"
    class="pl-3"
    :class="{undoSnackbar : hasUndo}"
  >
    <v-btn
      v-if="hasUndo"
      class="snackbar-undoButton"
      color="accent"
      outlined
      small
      @click="undo()"
    >
      <v-icon>undo</v-icon>&nbsp;
      {{ $t('base.undo') }}
    </v-btn>
    <template v-if="translatedText">
      {{ translatedText }}
    </template>
    <template v-else>
      {{ $t(text, params) }}
    </template>
    <v-btn
      v-if="link"
      color="accent"
      outlined
      small
      @click="openLink"
    >
      {{ $t('base.open') }}
      <v-icon class="ml-1">
        $openItem
      </v-icon>&nbsp;
    </v-btn>
    <template #action>
      <v-btn
        color="secondary"
        text
        @click="show = false"
      >
        <v-icon>$closeItem</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
    // TODO refactor
    export default {
        name: "Snackbar",
        data: () => ({
            show: false,
            text: '',
            translatedText: '',
            params: [],
            timeout: 4000,
            link: undefined,
            hasUndo: false,
            undo: undefined,
        }),
        watch: {
            show: function (val) {
                this.$store.commit('snackbar/setVisible', {visible: val});
                if (!val) {
                    this.$store.commit('snackbar/set', {});
                }
            }
        },
        created: function () {
            this.$store.watch(state => state.snackbar.visible, () => {
                if (!this.$store.state.snackbar.visible) {
                    this.show = false;
                }
            });
            this.$store.watch(state => state.snackbar.text, () => {
                // TODO bug https://github.com/vuetifyjs/vuetify/issues/5865
                const payload = this.$store.state.snackbar;
                if (payload.text) {
                    this.text = payload.text;
                    this.handleSnackChange(payload);
                }
            });
            this.$store.watch(state => state.snackbar.translatedText, () => {
                const payload = this.$store.state.snackbar;
                if (payload.translatedText) {
                    this.translatedText = payload.translatedText;
                    this.handleSnackChange(payload);
                }
            });
        },
        methods: {
            openLink: function () {
                this.show = false;
                this.$router.push(this.link);
            },
            handleSnackChange: function (payload) {
                this.link = payload.link;
                if (payload.params && payload.params.length > 0) {
                    this.params = payload.params;
                } else {
                    this.params = [];
                }
                if (payload.undo !== undefined) {
                    this.hasUndo = true;
                    this.undo = payload.undo;
                } else {
                    this.hasUndo = false;
                    this.undo = () => null;
                }
                this.timeout = payload.timeout === null ? 4000 : payload.timeout;
                this.show = true;
            }
        }
    };
</script>

<style scoped lang="sass">
.snackbar-undoButton
  margin: 0 16px 0 0 !important

.undoSnackbar .v-snack__content
  padding: 14px 5px !important
</style>
