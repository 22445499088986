import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskMovementType} from "@/enum/task_movement_type";
import {TaskAction} from "@/enum/task_action";

const TaskLocationTransferAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/location-transfer',
    LANG: 'tasks.locationTransfer',
    LANG_TASKS: 'tasks',
    DOMAIN_ITEMS: 'items',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data,
            this.LANG
        );
    },

    moveFromSourceToDestination(taskId, source_location_id, destination_location_id) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskMovementType.FROM_SOURCE_TO_DESTINATION],
            {
                source_location_id: source_location_id,
                destination_location_id: destination_location_id
            },
            undefined
        );
    },

    assign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.ASSIGN],
            null,
            [this.LANG_TASKS, 'assign']
        );
    },

    unassign(taskId) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.UNASSIGN],
            null,
            [this.LANG_TASKS, 'unassign']
        );
    },

    cancel(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.CANCEL],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'cancel']
        );
    },

    finish(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.FINISH],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'finish']
        );
    },

    approve(taskId, hours, note) {
        return this.API.post(
            [this.DOMAIN, taskId, TaskAction.APPROVE],
            {
                hours: hours,
                note: note
            },
            [this.LANG_TASKS, 'approve']
        );
    },
};

export {TaskLocationTransferAPI};
