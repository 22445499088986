import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-1 pa-2 pt-3 orderItemsLoader",attrs:{"loading":_vm.loading,"outlined":_vm.shownItems.length === 0}},[_c('span',{staticClass:"text-caption"},[(_vm.toggle)?_c(VSelect,{attrs:{"items":_vm.toggleItems,"dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}):_c('span',[_vm._v(_vm._s(_vm.$t('tasks.preparePackage.items.type.' + _vm.selectedType))+":")])],1),_c(VList,[_vm._l(((_vm.shownItems)),function(item){return _c(VCard,{key:item.id + '-' + _vm.selectedType,staticStyle:{"background-color":"rgba(0, 0, 0, 0)"},attrs:{"flat":""}},[_c('PreparePackageOrderItem',{attrs:{"item":item,"item-instance":_vm.instanceDetails[item.product_instance_id],"type":_vm.selectedType},on:{"select":function($event){return _vm.$emit('input', item.product_instance_id)}}})],1)}),(!_vm.shownItems || _vm.shownItems.length === 0)?_c('span',{key:"nothing"},[_c(VListItem,{staticClass:"text--disabled"},[_c(VIcon,{staticClass:"mb-1 mx-2"},[_vm._v(" $nothing ")]),_vm._v(" "+_vm._s(_vm.$t('base.nothing'))+" ")],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }