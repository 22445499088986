import {reportsNames, domainsNames} from "@/enum/reports_type";

export const ReportDomains = [
    {
        value: domainsNames.STOCK_STATUS,
        text: 'stocks.stockStatus.link',
        reports: [
            {
                value: reportsNames.STOCK_LOCATION,
                text: "stocks.stockStatus.locations.link"
            },
            {
                value: reportsNames.STOCK_BUY_PRICES,
                text: "stocks.stockStatus.buyPrices.name"
            },
        ]
    },
    {
        value: domainsNames.SHIPPING,
        text: 'base.shipping.link',
        reports: [
            {
                value: reportsNames.SHIPPING_REPORT,
                text: "base.shipping.report.link"
            },
        ]
    }
];
