import {config} from "@/config/config";

const AUTH_BASE = config.AUTH_URL;
const APP_BASE = config.APP_URL;

export default {
    BASE: AUTH_BASE,
    AUTHORIZE_URL: AUTH_BASE + '/oauth/authorize',
    TOKEN_URL: AUTH_BASE + '/oauth/token',
    INTROSPECTION_URL: AUTH_BASE + '/oauth/introspection',
    LOGOUT_URL: AUTH_BASE + '/oauth/logout',
    LOGOUT_RETURN_URL: APP_BASE,
    REDIRECT_URL: APP_BASE + '/login',
    RELOGIN_URL: APP_BASE + '/relogin'
};
