<template>
  <v-card>
    <v-container fluid>
      <div class="text-overline mb-3">
        {{ $t('stocks.stockStatus.optional') }}
      </div>
      <FormFields
        :form="form"
        :render="formRender"
        lang-path="stocks.stockChoose.form."
      />
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockFilterForm} from "@/app/overview/stock/definitions/stockFilter.form";
    import {StocksXAutocompleteRender} from "@/app/overview/definitions/overview.render.form";
    import {EventBus} from "@/service/EventBus";
    import {substocksInStock} from "@/app/stocks/substocks/definitions/substockDataSources";
    import {emptySource} from "@/app/definitions/emptyDataSources";

    const possibleFilters = {
        STOCK: 'stock.id',
        SUB_STOCK: 'substock.id',
        STOCK_LOCATION: 'stock_location.id',
    };

    export default {
        name: "StockFilter",
        components: {FormFields},
        props: {
            form: {
                type: Object,
                default: () => new StockFilterForm()
            },
            formRender: {
                type: Object,
                default: () => new StocksXAutocompleteRender(false, false, true)
            },
        },
        data: () => ({
            initialFormSet: false,
        }),
        computed: {
            formLocal: {
                get: function () {
                    return this.form;
                },
                set: function (value) {
                    this.$emit('update:form', value);
                }
            },
        },
        watch: {
            'formLocal.stockId': function (newValue) {
                if (newValue) {
                    EventBus.$emit('set-table-filter', possibleFilters.STOCK, newValue);
                    this.$set(this.formRender.subStockId, 'xAutocomplete', substocksInStock(newValue, true, true));
                } else {
                    this.clearFilterAttributes([possibleFilters.STOCK]);
                    this.$set(this.formRender.subStockId, 'xAutocomplete', emptySource());
                }
                if (!this.initialFormSet) {
                    this.formLocal.subStockId = [];
                    this.clearFilterAttributes([possibleFilters.STOCK_LOCATION, possibleFilters.SUB_STOCK]);
                }
            },
            'formLocal.subStockId': function (newValue) {
                if (newValue && Array.isArray(newValue)) {
                    if (newValue.length === 0 || newValue.includes(null)) {
                        this.formLocal.subStockId = null;
                    } else {
                        EventBus.$emit('set-table-filter', possibleFilters.SUB_STOCK, newValue);
                    }
                } else {
                    this.clearFilterAttributes([possibleFilters.SUB_STOCK]);
                }
            },
        },
        createdOrActivated: function () {
            this.initialFormSet = true;
            setTimeout(() => this.initialFormSet = false);
        },
        methods: {
            clearFilterAttributes: function (attributes) {
                for (const attr of attributes) {
                    EventBus.$emit('set-table-filter', attr, null);
                }
            }
        }
    };
</script>

<style scoped>

</style>