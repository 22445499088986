<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <div v-if="isSet">
                <v-divider
                  class="my-2"
                />
                <div>
                  <span class="text-caption">{{
                    $t('tasks.stockPickingSet.stockPickingTasks') + ': '
                  }}</span>
                  {{ details.tasks.length }}
                </div>
                <div v-if="details.used_strategy">
                  <span class="text-caption">{{
                    $t('tasks.stockPickingSet.usedStrategy') + ': '
                  }}</span>
                  {{ $t('tasks.stockPickingSet.strategies.' + details.used_strategy) }}
                </div>
              </div>
              <div v-if="details.parent_task_id">
                <v-divider
                  class="my-2"
                />
                <div>
                  <span class="text-caption">{{ $t('tasks.stockPicking.partOfSet') + ': ' }}</span>
                  <router-link
                    :to="{ name: 'stockPickingSetShow', params: { taskId: details.parent_task_id }}"
                  >
                    {{ '#' + details.parent_task_id }}
                  </router-link>
                </div>
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <StockSubstock :substock="details.subordinate_stock" />
              </div>
              <div v-if="details.buyer">
                <span class="text-caption">{{ $t('tasks.stockPicking.buyer_id') + ': ' }}</span>
                <router-link :to="'/buyers/' + details.buyer.id">
                  {{ details.buyer.name }}
                </router-link>
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.stockPicking.type.label') + ': ' }}</span>
                {{ $t('tasks.stockPicking.type.' + details.shipping_type) }}
              </div>
              <div v-if="details.target_location_id">
                <span class="text-caption">{{
                  $t('tasks.stockPicking.destination_location_id') + ': '
                }}</span>
                {{ LocationCache[details.target_location_id] | locationLabel }}
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <div v-if="details.external_order_id">
                <span class="text-caption">{{
                  $t('tasks.stockPicking.external_order_id') + ': '
                }}</span>
                <router-link
                  :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + details.external_order_id"
                >
                  #{{ details.external_order_id }}
                </router-link>
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="details.type"
            :stock-id="details.subordinate_stock.stock_id"
            :sub-stock-id="details.subordinate_stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
        <v-flex
          v-if="details.tasks"
          md4
          xs12
          class="pa-1"
        >
          <TaskListList
            :tasks="details.tasks"
          >
            <template
              v-if="isChief"
              #item-append="{ task }"
            >
              <v-btn
                class="ml-2"
                icon
                :loading="removeLoading"
                @click.prevent="onItemRemove(task)"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-icon>
                        $deleteTableItem
                      </v-icon>
                    </div>
                  </template>
                  <span>{{ $t('tasks.stockPickingSet.delete.deleteOne') }}</span>
                </v-tooltip>
              </v-btn>
            </template>
          </TaskListList>
          <v-btn
            v-if="isChief"
            type="submit"
            color="accent darken-2"
            class="mt-2"
            :loading="removeLoading"
            @click="onDeleteSet()"
          >
            <v-icon
              class="mr-2"
            >
              $deleteTableItem
            </v-icon>
            {{ $t('tasks.stockPickingSet.delete.title') }}
          </v-btn>
        </v-flex>
      </v-layout>
      <TaskItemsCard
        :api="api"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :items="items"
        :task-info="taskInfo"
        task-lang-path="stockPicking."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
    <ConfirmDeleteDialog
      :show.sync="removeDialog"
      @confirm-delete="reallyRemove"
    />
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {taskTypes} from "@/enum/task_type";
    import TaskListList from "@/app/tasks/components/taskList/TaskListList.component";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {taskLinks} from "@/enum/task_type";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component.vue";
    import StockSubstock from "@/app/components/StockSubstock.component";
    import {EventBus} from "@/service/EventBus";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";

    export default {
        name: "StockPickingAssignment",
        components: {
            TaskAttributes,
            StockSubstock,
            ConfirmDeleteDialog, EntityHistory, TaskAttachments, Notes, TaskListList, TaskItemsCard},
        mixins: [TaskFetchItemsMixin, ReactiveLocationCacheMixin, ACLMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                require: true,
                default: () => ({}),
            }
        },
        data: () => ({
            ready: false,
            items: [],
            TaskItemsCardType: TaskItemsCardType,
            taskLinks: taskLinks,
            taskTypes: taskTypes,
            removeLoading: false,
            removeTask: null,
            removeDialog: false
        }),
        computed: {
            API: function () {
                return this.api;
            },
            details: function () {
                return this.taskInfo.details;
            },
            isSet: function () {
                return this.details.type === taskTypes.STOCK_PICKING_SET;
            }
        },
        watch: {
            'taskInfo.tab': function (tab) {
                if (tab === 0) {
                    this.fetchItemsDebounced({debounceQuantities: true});
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true}).catch(this.snack).then(() => this.ready = true);
            } else {
                this.fetchItems().catch(this.snack);
            }
        },
        methods: {
            onItemRemove: function (task) {
                this.removeTask = task;
                this.removeDialog = true;
            },
            onDeleteSet: function () {
                this.removeDialog = true;
            },
            reallyRemove: function () {
                this.removeDialog = false;
                this.removeLoading = true;
                let promise = Promise.resolve();
                if (this.removeTask === null) {
                    promise = this.api.delete(this.details.id)
                        .then(() => {
                            this.$router.push('/');
                        });
                } else {
                    promise = this.api.deleteFromSet(this.details.id, this.removeTask.id)
                        .then(() => {
                            this.$emit('refresh-task');
                            EventBus.$emit('fetch-items');
                            this.fetchItems().catch(this.snack);
                        });
                    this.removeTask = null;
                }
                promise
                    .catch(err => {
                        if (err.response.status === 409 && err.response.data.message) {
                            this.advancedSnack({translatedText: err.response.data.message});
                        } else {
                            this.snack(err);
                        }
                    }).finally(() => {
                        this.removeLoading = false;
                    });
            },

        }
    };
</script>

<style scoped>

</style>
