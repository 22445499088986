<template>
  <div>
    <v-card-subtitle v-if="loading">
      {{ $t(action + 'info.hint') }}
    </v-card-subtitle>
    <v-btn
      v-if="loading"
      :loading="loading"
      color="primary"
      text
      width="100%"
      x-large
    />
    <div
      v-if="!loading && !importData.error && sortedStatistic"
    >
      <v-card-subtitle class="text-md-h5">
        {{ $t(action + 'info.include') }}
      </v-card-subtitle>
      <v-layout
        justify-start
        wrap
      >
        <v-flex
          class="px-2"
          lg12
          md12
          sm12
        >
          <v-card>
            <v-card-title>
              {{ $t(langPath(sortedStatistic.mainEntity.code)) }}
            </v-card-title>
            <v-card-text
              class="ml-5"
            >
              {{ getMainCount() }}
              <div v-if="showEdit && !isExport">
                <span :class="{'orange--text' : sortedStatistic.mainEntity.updated > 0}">
                  {{ $t(action + 'info.updated') + ' : ' + sortedStatistic.mainEntity.updated }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          v-for="row in sortedStatistic.other"
          :key="row.code"
          class="pa-2"
          lg3
          md6
          sm12
          xs12
          xl2
        >
          <v-card>
            <v-card-title>
              {{ $t(langPath(row.code)) }}
            </v-card-title>
            <v-card-text
              class="ml-5"
            >
              <span>
                {{
                  isExport ? $t('exports.info.exported') + ' : ' + row.count : $t('imports.info.created') + ' : ' + row.created
                }}
              </span>
              <div v-if="showEdit && !isExport">
                <span :class="{'orange--text' : row.updated > 0}">
                  {{ $t(action + 'info.updated') + ' : ' + row.updated }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div
      v-if="!loading && importData.error"
    >
      <v-card-subtitle
        class="red--text text-xl-h6"
      >
        {{ $t(action + 'info.error') }}
      </v-card-subtitle>
      <v-card-text class="text-xl-body-1">
        {{ importData.error }}
      </v-card-text>
    </div>
  </div>
</template>

<script>
    import {DomainTranslationBridge} from "@/enum/domains";
    import {ImportsAPI} from "@/api/ImportsAPI";

    export default {
        name: "ImportStatistics",
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            importData: {
                type: Object,
                default: () => {
                }
            },
            isExport: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            template: null,
            templateLoad: true,
        }),
        computed: {
            sortedStatistic() {
                if (this.loading || !this.importData.statistics || this.importData.statistics.columns.length === 0 || !this.template) {
                    return null;
                }
                let statistic = this.importData.statistics;
                const otherStatistics = statistic.columns.filter(item => item.code.split('.').length > 1)
                    .map((item) => {
                        const groupData = this.matchTemplate(item.code);
                        item.is_mandatory = groupData.is_mandatory_for_main_entity;
                        return item;
                    });
                return {
                    mainEntity: statistic.columns.find(item => item.code.split('.').length === 1),
                    other: this.showEdit ? otherStatistics : otherStatistics.filter(item => item.is_mandatory)
                };
            },
            action() {
                return this.isExport ? 'exports.' : 'imports.';
            },
            showEdit() {
                // this function will get more complex as we start using more types of modes.
                return this.importData.configuration.import_mode !== 1;
            }
        },
        watch: {
            loading(newVal) {
                if (!newVal && this.importData.statistics) {
                    this.getDomain();
                }
            }
        },
        createdOrActivated() {
            this.getDomain();
        },
        methods: {
            getDomain() {
                this.templateLoad = true;
                ImportsAPI.getTemplate(this.importData.domain)
                    .then(res => {
                        this.template = res.data;
                        this.templateLoad = false;
                    })
                    .catch(err => this.snack(err));
            },
            langPath(code) {
                return DomainTranslationBridge[this.importData.domain][code.split('.').pop()];
            },
            matchTemplate(code) {
                const path = code.split('.');
                let result = this.template;
                for (const step of path) {
                    result = result.columns.find(item => item.name === step);
                }
                return result;
            },
            getMainCount() {
                return this.isExport ? this.$t('exports.info.exported') + ' : ' + this.sortedStatistic.mainEntity.count
                    : this.$t('imports.info.created') + ' : ' + this.sortedStatistic.mainEntity.created;
            }
        }
    };
</script>
