const notFound = "Zadaný výrobce neexistuje!";

export default {
    title: "Виробники",
    link: "Виробники",

    abbreviation: "Абревіатура",
    name: "Назва",

    list: {
        unableToLoad: "Неможливо завантажити список виробників. Будь ласка, спробуйте пізніше."
    },
    create: {
        label: "Створити нового виробника",
        title: "Створити виробника",
        done: "Виробника '{0}' створено.",
        undo: {
            done: "Створення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити створення виробника!"
        }
    },
    update: {
        titleSimple: "Оновити виробника",
        title: "Оновити {0}",
        done: "Виробника '{0}' оновлено",
        undo: {
            done: "Оновлення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити оновлення виробника!"
        }
    },
    delete: {
        done: "Виробника '{0}' видалено.",
        failed: "Виробника '{0}' неможливо видалити. Будь ласка, спробуйте пізніше.",
        undo: {
            done: "Видалення '{0}' успішно скасовано!",
            failed: "Не вдалося відмінити видалення виробника!"
        },
        status: {
            404: notFound,
            409: "Неможливо видалити виробника через зв'язки зі складськими позиціями."
        }
    },
    status: {
        404: notFound,
        409: "Введена абревіатура вже існує!"
    }
};
