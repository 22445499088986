export default {
    locationVsSubstock: {
        title: 'Яка різниця між розташуванням та підсховищем?',
        content: 'Розташування - це реальне місце на складі, тоді як підсховище - це віртуальна частина складу, в якому складові позиції може володіти інша особа. На одному місці можуть зберігатися товари з декількох підсховищ.'
    },
    smallWarehouse: {
        title: 'Що таке малий склад?',
        content: "Малий склад завжди має максимум одне місце розташування. Кількість підсховищ не обмежена."
    },
    productLabels: {
        title: 'Що змінює перемикач "Показати позиції" модель/назва?',
        content: "Змінює, чи відображається ім'я або модель окремих позицій на складі, це проявляється, наприклад, на сторінці складських переміщень, в завданнях та їх створенні."
    },
    setProductAttributes: {
        title: 'Як встановити атрибути складового товару?',
        content: 'При створенні складового товару послідовно завантажуються рекомендовані атрибути вибраного типу складового товару. ' +
            'З цих атрибутів зберігаються тільки ті, які матимуть заповнене значення. ' +
            'При редагуванні складового товару завантажуються атрибути даного складового товару, при зміні типу складової позиції знову завантажуються і рекомендовані атрибути даного типу. ' +
            'Якщо є відповідність в назві між атрибутом складового товару та рекомендованим атрибутом, то атрибут складового товару приймає опис рекомендованого. ' +
            'В обох випадках (при створенні та редагуванні) можна одночасно встановлювати рекомендовані атрибути вибраного типу. ' +
            "Вони не повинні мати заповненого значення, а опис є необов'язковим для атрибутів складового товару та рекомендованих атрибутів."
    },
    markAsDeliveredAfterPacking: {
        title: 'Коли відправлення замовлення позначається як доставлене?',
        content: 'Зазвичай відправлення замовлення типу "особисте отримання" позначається як доставлене лише при складанні протоколу прийому-передачі.'
    },
};
