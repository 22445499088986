const notFound = "Manufacturer does not exist!";

export default {
    title: "Manufacturers",
    link: "Manufacturers",

    abbreviation: "Abbreviation",
    name: "Name",

    list: {
        unableToLoad: "Unable to load list of manufacturers. Please try again later."
    },
    create: {
        label: "Create new manufacturer",
        title: "Create manufacturer",
        done: "Manufacturer '{0}' created.",
        undo: {
            done: "Creation of '{0}' successfully canceled!",
            failed: "Failed to undo manufacturer creation!"
        }
    },
    update: {
        titleSimple: "Update manufacturer",
        title: "Update {0}",
        done: "Manufacturer '{0}' updated.",
        undo: {
            done: "Update of '{0}' successfully canceled!",
            failed: "Failed to undo manufacturer update!"
        }
    },
    delete: {
        done: "'{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
        undo: {
            done: "Deletion of '{0}' successfully canceled!",
            failed: "Failed to undo manufacturer delete!"
        },
        status: {
            404: notFound,
            409: "Unable to delete manufacturer due to references to products!"
        }
    },
    status: {
        404: notFound,
        409: "Abbreviation is already in use!"
    }
};
