import {Roles} from "@/enum/roles";
import Reports from "@/app/overview/reports/Reports.view";

const BASE_URL = '/reports';

const routes = {
    base: {
        path: BASE_URL,
        component: Reports,
        meta: {
            title: 'base.reports.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
};

export default Object.values(routes);
