import {Roles} from "@/enum/roles";
import List from "@/app/users/UsersList.view";
import CreateUpdate from "@/app/users/UserCreateUpdate.view";
import {Store} from "@/service/store/Store";
import UserShow from "@/app/users/UserShow.view";

const BASE_URL = '/users';

const chiefOrSelf = (to, from, next) => {
    const userId = Number.parseInt(to.params.userId);
    if (Store.getters["oauth/hasScope"](Roles.CHIEF) || userId === Store.getters['oauth/getUserId']) {
        next();
    } else {
        next('/');
    }
};

const routes = {
    base: {
        path: BASE_URL,
        component: List,
        meta: {
            title: 'users.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    show: {
        path: BASE_URL + '/:userId(\\d+)',
        component: UserShow,
        meta: {
            title: 'users.detail',
            getParent: () => routes.base
        },
        beforeEnter: chiefOrSelf
    },
    create: {
        path: BASE_URL + '/create',
        component: CreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'users.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:userId(\\d+)/update',
        component: CreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'users.update.titleSimple',
            getParent: () => routes.base
        },
        beforeEnter: chiefOrSelf
    },
    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            // TODO snack message
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
