<template>
  <div>
    <v-card-title class="text-h5">
      {{ config.name }}
    </v-card-title>
    <v-card-subtitle class="text-subtitle-1 pt-2 pb-1">
      {{ $t('imports.domain') + ' : ' + getTranslation(null, config.domain) }}
    </v-card-subtitle>
    <v-checkbox
      v-model="easyMode"
      class="pl-3 ma-0"
      :label="$t('imports.config.easyMode')"
      disabled
    />
    <v-checkbox
      v-model="ignoreDuplicity"
      class="pl-3 ma-0"
      :label="$t('imports.config.latestOnDuplicity')"
      disabled
    />
  </div>
</template>

<script>
    import {getTranslation} from "@/enum/domains";
    import {isEasyMode} from "@/utils/importMode";
    import {ImportModes} from "@/enum/importModes";

    export default {
        name: "ImportConfigHeader",
        props: {
            config: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            easyMode: true,
            getTranslation: getTranslation,
        }),
        computed: {
            ignoreDuplicity() {
                return (this.config.import_mode & ImportModes.LATEST_ON_DUPLICITY) !== 0;
            }
        },
        createdOrActivated() {
            this.easyMode = isEasyMode(this.config.import_mode);
        }
    };
</script>

<style scoped>

</style>