<template>
  <v-container fluid>
    <x-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :item-class="item => item.active === false && 'text--disabled'"
      :actions="actions"
      :batch-actions="batchActions"
    >
      <template #item.roles="{ item }">
        <td>
          <span
            v-for="role of Roles"
            :key="role"
            :class="{visibilityHidden: !item['is_' + role.split('_')[1].toLowerCase()]}"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-chip
                  outlined
                  v-on="on"
                >
                  <v-icon>
                    {{ icons[role] }}
                  </v-icon>
                </v-chip>
              </template>
              <span>
                {{ $t('users.role.' + role) }}
              </span>
            </v-tooltip>
          </span>
        </td>
      </template>
      <template #item.has_permission="{ item }">
        <template v-if="loading">
          <v-progress-circular
            size="25"
            color="muted"
            indeterminate
          />
        </template>
        <template v-else>
          <v-icon
            v-if="item.has_permission"
            color="success"
          >
            $success
          </v-icon>
          <v-icon
            v-else
            color="error"
          >
            $closeItem
          </v-icon>
        </template>
      </template>
    </x-data-table>
  </v-container>
</template>

<script>
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {roleIcons} from "@/enum/icons";
    import {Roles} from "@/enum/roles";
    import {createHeaders} from "@/utils/table";
    import {UserAPI as API} from "@/api/UserAPI";
    import {UserPermissionsAPI} from "@/api/UserPermissionsAPI";
    import {usersPermissionTable} from "@/app/stocks/substocks/definitions/userPermissionTable";
    import {PermissionType} from "@/enum/permissionTypes";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "StockSubstockAssignedUsersComponent",
        components: {XDataTable},
        mixins: [ACLMixin, RouteParamsMapperMixin],
        props: {
            substockId: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            loading: true,
            items: [],
            icons: roleIcons,
            Roles: Roles,
            reload: 0,
            initialLoad: true
        }),
        computed: {
            headers: () => createHeaders(usersPermissionTable, 'users.'),
            actions: function () {
                return [
                    {
                        condition: item => item.active && this.isChief,
                        loading: this.loading,
                        routerLink: item => '/users/' + item.id,
                        icon: 'visibility',
                        label: 'base.view'
                    }, {
                        condition: item => (this.isChief || item.id === this.$store.getters['oauth/getUserId']) && item.has_permission,
                        loading: this.loading,
                        action: item => this.updatePermissions(item, false),
                        icon: '$unassign',
                        label: 'users.permissions.removeAccess'
                    }, {
                        condition: item => (this.isChief || item.id === this.$store.getters['oauth/getUserId']) && !item.has_permission,
                        loading: this.loading,
                        action: item => this.updatePermissions(item, true),
                        icon: '$addUserAlt',
                        label: 'users.permissions.addAccess'
                    }
                ];
            },
            batchActions: function () {
                return [
                    {
                        action: users => this.updatePermissions(users, true),
                        loading: this.loading,
                        icon: '$addUserAlt',
                        label: 'users.permissions.addAccess'
                    }, {
                        action: users => this.updatePermissions(users, false),
                        loading: this.loading,
                        icon: '$unassign',
                        label: 'users.permissions.removeAccess'
                    },
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData() {
                this.loading = true;
                API.getAll()
                    .then(response => {
                        this.items = response.data;
                        UserPermissionsAPI.getPermissionsBySubstock(this.substockId)
                            .then(res => {
                                const permissions = res.data.items;
                                this.items.map(item => {
                                    const permission = permissions.find(el => el.user_id === item.id);
                                    item.has_permission = permission ? permission.allowed : false;
                                });
                            }).finally(() => {
                                this.loading = false;
                                if (this.initialLoad) {
                                    this.initialLoad = false;
                                    this.$nextTick(() => {
                                        this.$emit('loaded');
                                    });
                                }
                            });
                    }).catch(() => {
                        this.snack('users.list.unableToLoad');
                    });
            },
            updatePermissions(users, allowAccess) {
                const data = {
                    user_ids: Array.isArray(users) ? users : [users.id],
                    type: allowAccess ? PermissionType.ALLOWED : PermissionType.DENIED
                };
                UserPermissionsAPI.updateSubordinatePermissions(this.substockId, data)
                    .then(() => {
                        this.getData();
                    }).catch(err => this.snack(err));
            },
        }
    };
</script>

<style scoped lang="sass">
.visibilityHidden
  visibility: hidden
</style>
