import {BarcodeAPI} from "@/api/BarcodeAPI";
import {CodeType} from "@/enum/code_type";

/**
 * Requires:
 * - this.productId
 * - this.instanceId
 *
 */
const CreateNewBarcodeMixin = {
    computed: {
        barcodeCreateLink: function () {
            return '/products/' + this.productId + '/instance/' + this.instanceId + '/barcode/create';
        }
    },
    methods: {
        createNewBarcode: function (code) {
            BarcodeAPI.decode(code)
                .then(response => {
                    if (response.data.type === CodeType.PRODUCT_INSTANCE) {
                        this.advancedSnack({
                            text: 'products.instances.barcodes.conflict',
                            params: [this.$options.filters.productLabel(response.data.object_info.product)]
                        });
                    } else if (response.data.type === CodeType.STOCK_LOCATION) {
                        this.advancedSnack({
                            text: 'products.instances.barcodes.conflict',
                            params: [this.$options.filters.locationLabel(response.data.object_info)]
                        });
                    }
                })
                .catch(() => {
                    this.$router.push(this.barcodeCreateLink + '?code=' + encodeURIComponent(code));
                });
        }
    }
};

export {CreateNewBarcodeMixin};
