import i18n from "@/service/lang/i18n";

function barcodeReaderError(error) {
    const retError = {
        message: error,
        params: []
    };
    if (typeof error.response !== 'undefined') {
        const data = error.response.data;
        const message = 'tasks.stockPickingSet.advancedErrorMessages.' + data.type;
        let params = [];
        const type = data.type;
        switch (type) {
            case "invalid_task_state" :
                params = [i18n.t('tasks.state.' + data.task.state)];
                break;
            case "location_not_allowed" :
                break;
            case "missing_location" :
                break;
            case "movement_failed" :
                params = [data.references.quantity];
                break;
            case "task_not_found" :
                break;
            case "invalid_moving_quantity" :
                params = [data.references.amount_try_to_process, data.references.amount_can_be_processed];
                break;
        }
        retError.message = message;
        retError.params = params;
    }
    return retError;
}

export {barcodeReaderError};
