<template>
  <div>
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('tasks.externalOrder.show.label') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :actions="actions"
            :search-hint="$t('tasks.externalOrder.searchHint')"
          >
            <template #item.id="{ item }">
              #{{ item.id }}
            </template>
            <template #item.priority="{ item }">
              <div :class="'p' + item.priority">
                {{ item.priority | taskPriority }}
              </div>
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {createHeaders} from "@/utils/table";
    import {TableFilter} from "@/enum/table_filter";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {fullName} from "@/utils/filters";
    import {TaskState} from "@/enum/task_state";
    import {TaskPriority} from "@/enum/task_priority";
    import {UserAPI} from "@/api/UserAPI";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";

    export default {
        name: "BuyerExternalOrdersList",
        mixins: [RouteParamsMapperMixin, CreatedOrActivatedMixin],
        data: () => ({
            loading: false,
            items: [],
            users: []
        }),
        computed: {
            headers: function () {
                return createHeaders({
                    id: {
                        label: 'name',
                        filterType: TableFilter.NUMBER
                    },
                    external_id: {
                        label: 'externalOrder.id',
                        filterType: TableFilter.TEXT
                    },
                    state: {
                        label: 'state.name',
                        filterType: TableFilter.SELECT_MULTIPLE,
                        filterItems: Object.values(TaskState).map(state => ({
                            text: this.$t('tasks.state.' + state),
                            value: state,
                        })),
                        itemLabel: item => this.$t('tasks.state.' + item.state),
                    },
                    priority: {
                        label: 'priority.name',
                        filterType: TableFilter.SELECT_MULTIPLE,
                        filterItems: Object.values(TaskPriority).map(priority => ({
                            text: this.$t('tasks.priority.' + priority),
                            value: priority,
                        })),
                    },
                    'assigned_user.id': {
                        label: 'assigned_user',
                        filterType: TableFilter.SELECT_MULTIPLE,
                        filterItems: this.users.map(user => ({
                            text: user.first_name + ' ' + user.last_name,
                            value: user.id
                        })),
                        itemLabel: item => item.assigned_user ? fullName(item.assigned_user) : '-',
                        sortBy: 'assigned_user.first_name' + 'assigned_user.last_name'
                    }
                }, 'tasks.', true);
            },
            actions: function () {
                return [
                    {
                        routerLink: item => '/external-order/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.loading = true;
            TaskExternalOrderAPI.getByBuyer(this.buyerId)
                .then(response => {
                    this.items = response.data;
                    this.items.sort((lhs, rhs) => rhs.id - lhs.id);
                    UserAPI.getAll()
                        .then(response => this.users = response.data)
                        .finally(() => this.loading = false);
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/buyers');
                });
        }
    };
</script>

<style scoped lang="sass">
    .p1
     color: var(--v-priorityTextLow-base)

    .p3
     color: var(--v-priorityTextHigh-base)
</style>
