<template>
  <v-list-item class="d-flex justify-space-between">
    <span class="mt-5">
      {{ $t('base.onBeep') }}:
    </span>
    <v-checkbox
      v-model="vibrate"
      on-icon="vibration"
      off-icon="mobile_off"
      hide-details
      class="ml-8"
    />
    <v-checkbox
      v-model="beep"
      on-icon="volume_up"
      off-icon="volume_off"
      hide-details
      class="ml-8"
    />
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarProductModels",
        computed: {
            vibrate: {
                get() {
                    return this.$store.getters['userConfig/vibrate'];
                },
                set(val) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'vibrate',
                        value: val
                    });
                }
            },
            beep: {
                get() {
                    return this.$store.getters['userConfig/beep'];
                },
                set(val) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'beep',
                        value: val
                    });
                }
            }
        }
    };
</script>

<style scoped>

</style>
