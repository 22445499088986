import moment from "moment";
import {APIFilterDataType} from "@/service/APIFilters";
import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";
import {operatorType} from "@jagu/rest-api-filters-client/src/abstractFilters";

/**
 * API filters syntax validation
 * group : {operator: [filter, filter, ...]} [].length > 0
 * unary : {operator: attr} attr not empty
 * binary : {operator: {attr: value}} value not empty
 * ternary : {operator: {attr: [value, value]}} values not empty, value[0] <= value[1]
 * array : {operator: {attr: [value, value, ...]}} values not empty
 */

/**
 * ValidationParams type definition
 * @typedef {Object} ValidationParams
 * @property {Object} filterObject - filter to validate
 * @property {Object} filters - implementation of filters
 * @property {Object} operators - enum of operators
 * @property {Object} possibleValues - possible values for attributes
 */

/**
 * Validates filter component
 * @param params {ValidationParams}
 * @returns {boolean}
 * @recursive
 */
const validateComponent = function (params) {
    const { filterObject } = params;

    // base value validation
    if (filterObject === undefined || filterObject === null || filterObject === "") {
        return false;
    }
    if (typeof filterObject !== 'object' || Object.keys(filterObject).length === 0) {
        return true;
    }

    // extensions
    const { operators, filters } = params;
    const key = Object.keys(filterObject)[0];
    if (Object.values(operators).includes(key)) {
        const operatorType = filters.getType(key);
        const extension = OperatorTypeValidationExtensions[filters.type][operatorType.type];
        if (extension && !extension(params)) {
            return false;
        }
    }

    // nested value validation
    const value = filterObject[key];
    if (Array.isArray(value)) {
        let isValid = true;
        for (const nested of value) {
            if (!validateComponent({ ...params, filterObject: nested })) {
                isValid = false;
                break;
            }
        }
        return isValid;
    } else {
        return validateComponent({ ...params, filterObject: value });
    }
};

/**
 * FilterValidate type definition
 * @typedef {function} FilterValidate
 * @param {Object} filterObject - filter object to validate
 * @param {Object} filters - implementation of filters
 * @param {Object} operators - enum of operators
 * @param {Object} possibleValues - possible values for attributes
 * @returns {boolean}
 */

/**
 * FilterValidator type definition
 * @typedef {Object} FilterValidatorType
 * @property {FilterValidate} validate - Validates filter syntax
 */

/**
 * FilterValidator used to validate filter syntax
 * @type {FilterValidatorType}
 */
export const FilterValidator = {
    validate: function (filterObject, filters, operators, possibleValues) {
        return validateComponent({ filterObject, filters, operators, possibleValues });
    }
};

/**
 * Validates ternary operators - expects two values and if first is less than second
 * @param {ValidationParams} params
 * @returns {boolean}
 */
const ternaryValidation = function (params) {
    const { filterObject, possibleValues } = params;
    const operator = Object.keys(filterObject)[0];
    const attr = Object.keys(filterObject[operator])[0];

    const values = filterObject[operator][attr];
    if (values.length !== 2) {
        return false;
    }

    const { numberType, dateType } = params;
    const valueType = possibleValues[attr].type;
    if (numberType && valueType === numberType) {
        return parseFloat(values[0]) <= parseFloat(values[1]);
    } else if (dateType && valueType === dateType) {
        return moment(values[0]).isSameOrBefore(values[1]);
    }
    return values[0] <= values[1];
};

/**
 * ValidationExtensionFunction type definition
 * @typedef {function} ValidationExtensionFunction
 * @param {ValidationParams} params
 * @returns {boolean}
 */

/**
 * ValidationExtension type definition
 * - keys are {@link operatorType}
 * @typedef {Object.<string, ValidationExtensionFunction>} ValidationExtension
 */

/**
 * Extensions for operator type specific validation
 * - here you can add additional validation checks for each operator type for your implementation
 * - keys are {@link FilterType} for implementation
 * - values are {@link ValidationExtension}
 * @type {Object.<string, ValidationExtension>}
 */
const OperatorTypeValidationExtensions = {
    [FilterType.API]: {
        [operatorType.groupOperators]: function (params) {
            const { filterObject } = params;
            const operator = Object.keys(filterObject)[0];
            return filterObject[operator].length !== 0;
        },
        [operatorType.ternaryOperators]: function (params) {
            return ternaryValidation({ ...params, numberType: APIFilterDataType.NUMBER, dateType: APIFilterDataType.DATE });
        }
    },
};
