import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pb-3"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('GeneralConfigFilterComponent',{attrs:{"filters":_vm.APIFilters,"filter":_vm.filter,"possible-values":_vm.possibleValues,"lang-path":"base.table.filter.","hardcoded-attribute":_vm.attribute}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close');}}},[_vm._v(" "+_vm._s(_vm.$t('base.close'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('base.reset'))+" ")]),_c(VBtn,{staticClass:"px-4",attrs:{"color":"accent","text":!_vm.isFilterValid},on:{"click":_vm.save}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }