<template>
  <div>
    <x-data-table
      v-if="productId"
      :headers="headers"
      :api-data-source="apiDataSource"
      :api-data-source-all-pages="apiDataSourceAllPages"
      :api-filter="apiFilter"
      :items.sync="items"
      :show-search-bar="false"
    >
      <template #item.parent_task.type="{item}">
        {{ $t(taskNames[item.parent_task.type]) }}
      </template>
      <template #item.parent_task.id="{item}">
        <router-link
          :to="taskLinks[item.parent_task.type] + '/' + item.parent_task.id"
        >
          {{ '#' + item.parent_task.id }}
        </router-link>
      </template>
      <template #item.parent_task.state="{ item }">
        {{ item.parent_task.state | taskState }}
      </template>
      <template #item.id="{item}">
        <router-link
          :to="taskLinks[item.type] + '/' + item.id"
        >
          {{ '#' + item.id }}
        </router-link>
      </template>
    </x-data-table>
  </div>
</template>

<script>
    import {TaskProductReservationAPI} from "@/api/TaskProductReservationAPI";
    import { taskLinks, taskNames} from "@/enum/task_type";
    import {APIFilterOP} from "@jagu/rest-api-filters-client/apiFilters";
    import XDataTable from "@/app/vuetify-extend/x-data-table";
    import {createHeaders} from "@/utils/table";
    import {ProductReservationsTable} from "@/app/products/definitions/productReservationTable.form";
    import {TaskState} from "@/enum/task_state";
    import {EventBus} from "@/service/EventBus";

    export default {
        name: "ProductReservationTasksCard",
        components: {XDataTable},
        props: {
            productId: {
                type: Number,
                default: 0
            },
            substockId: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            taskNames: taskNames,
            taskLinks: taskLinks,
            items: []
        }),
        computed: {
            headers: () => createHeaders(ProductReservationsTable(), '', false),
            apiDataSource: function () {
                return TaskProductReservationAPI.getAllByProduct.bind(TaskProductReservationAPI, this.productId);
            },
            apiDataSourceAllPages: function () {
                return TaskProductReservationAPI.getAllPagesByProduct.bind(TaskProductReservationAPI, this.productId);
            },
            apiFilter: function () {
                return [{
                    [APIFilterOP.EQUALS]: {
                        'substock.id': this.substockId
                    }
                }];
            }
        },
        mounted: function () {
            const defaultStates = [TaskState.CREATED, TaskState.IN_PROGRESS, TaskState.WAITING, TaskState.UNASSIGNED];
            EventBus.$emit('set-table-filter', 'parent_task.state', defaultStates);
        }
    };
</script>

<style scoped>

</style>
