import {snakeCase, camelCase} from 'lodash';
import {Store} from "@/service/store/Store";
import {MapObjectAction, MapObjectType, PanelMode} from "@/enum/stock_map";

// Get symbol that is STEP away from the VALUE in the ascii table
function nextInSequence(value, step = 1, startingSymbol = null) {
    if ([undefined, null, ''].includes(value)) {
        return '';
    }
    const number = Number.parseInt(value, 10);
    if (!isNaN(number)) {
        if (startingSymbol !== null && !isNaN(startingSymbol)) {
            let prefix = "";
            for (let i = 0; i < startingSymbol.toString().length - (number + step).toString().length; i++) {
                prefix = prefix + '0';
            }
            return prefix + (number + step).toString();
        }
        return number + step;
    }
    else if (value.charCodeAt !== undefined) {
        return value.slice(0, -1) + String.fromCharCode(value.charCodeAt(value.length-1) + step);
    }
    else {
        console.error('Unsupported type of sequence: ' + typeof value + '(supported: String, Number');
        return 0;
    }
}

// Convert Object keys (including nested objects, arrays etc.) to snake case
function snakeKeys(obj) {
    if (Array.isArray(obj)) {
        return obj.map(v => snakeKeys(v));
    }
    else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [snakeCase(key)]: snakeKeys(obj[key]),
            }),
            {},
        );
    }
    return obj;
}

// Convert Object keys (including nested objects, arrays etc.) to camel case
function camelKeys(obj) {
    if (Array.isArray(obj)) {
        return obj.map(v => camelKeys(v));
    }
    else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelKeys(obj[key]),
            }),
            {},
        );
    }
    return obj;
}

// Provided some name, increment its latest character.
function incrementLast(name) {
    const arr = [...name];
    arr[arr.length - 1] = nextInSequence(arr[arr.length - 1], 1, arr[arr.length - 1]);
    return arr.join('');
}

// Rack is an object in the map with a certain height and width.
// Return its longer side to get the amount of columns a rack has, since a rack is a 1 x n rectangle.
function getRackColumns(rackObject) {
    return (rackObject.width > rackObject.height) ? rackObject.width : rackObject.height;
}

// Get the bottom right coordinate (point) of an object in a map, using its width and height attribute.
function getObjectBrPoint(object) {
    const br = [
        object.tl[0],
        object.tl[1],
    ];
    br[0] = br[0] + object.height;
    br[1] = br[1] + object.width;
    return br;
}

// Get the bottom right coordinate (cell) of an object in a map, using its width and height attribute.
function getObjectBrCell(object) {
    const br = [
        object.tl[0],
        object.tl[1],
    ];
    br[0] = br[0] + object.height - 1;
    br[1] = br[1] + object.width - 1;
    return br;
}

function ImportMapData(data) {
    Store.commit('storageMap/setMapSize', {
        x: data.mapData.width,
        y: data.mapData.height
    });
    Store.commit('storageMap/selectObject', {object: null});
    Store.commit('storageMap/setPanelMode', {mode: PanelMode.STANDBY});
    Store.commit('storageMap/setChanges', {value: false});
    Store.commit('storageMap/setMapId', {value: data.mapData.id});
    Store.commit('storageMap/setStockId', {value: data.mapData.stock_id});
    Store.commit('storageMap/resetStockLocationMapping', {data: data.stockLocations});

    let dimensions = [Store.getters["storageMap/getDefaultDimension"]];

    const rackParse = ParseRacks(data.racks, dimensions);
    dimensions = rackParse.dimensions;
    const objects = rackParse.objects.concat(ParseLocationGroups(data.locationGroups)).concat(ParseObstacles(data.obstacles));
    const zones = ParseZones(data.zones);

    Store.commit('storageMap/setMapObjects', {
        objects: objects,
        zones: zones,
        dimensions: dimensions,
        deletedObjectsAndZones: [],
    });

}

function ExportMap() {

    const map = Store.getters["storageMap/getMap"];

    const mapData = {
        height: map.height,
        width: map.width
    };

    const racks = [];
    const obstacles = [];
    const locationGroups = [];
    map.objects.forEach(mapObject => {
        if (mapObject.action !== MapObjectAction.NOTHING) {

            // Add to rack
            if (mapObject.type === MapObjectType.RACK) {
                const cells = [];
                mapObject.locations.forEach((row, rowIndex) => {
                    row.forEach((cell, cellIndex) => {
                        if (cell.mapLocations.length > 0) {
                            const locations = cell.mapLocations.map(loc => {
                                return {
                                    name: loc.name,
                                    code: loc.code,
                                    stock_location_id: loc.stockLocationId
                                };
                            });
                            cells.push({
                                width_coordinate: cellIndex + 1,
                                shelf_coordinate: rowIndex + 1,
                                width: cell.dimensions.width,
                                height: cell.dimensions.height,
                                depth: cell.dimensions.depth,
                                dimension_color: cell.dimensions.color,
                                locations: locations
                            });
                        }
                    });
                });
                racks.push({
                    action: mapObject.action,
                    data: {
                        id: mapObject.id,
                        name: mapObject.name,
                        x_coordinate: mapObject.tl[1],
                        y_coordinate: mapObject.tl[0],
                        vertical: mapObject.vertical,
                        width: mapObject.vertical ? mapObject.height : mapObject.width,
                        shelves: mapObject.shelves,
                        name_shelves_from_up: mapObject.naming.numberShelvesFromUp,
                        name_columns_from_right: mapObject.naming.numberColsFromRight,
                        first_naming_symbol: mapObject.naming.symbols[0].namingSymbol,
                        second_naming_symbol: mapObject.naming.symbols[1].namingSymbol,
                        third_naming_symbol: mapObject.naming.symbols[2].namingSymbol,
                        first_starting_symbol: mapObject.naming.symbols[0].startingSymbol,
                        second_starting_symbol: mapObject.naming.symbols[1].startingSymbol,
                        third_starting_symbol: mapObject.naming.symbols[2].startingSymbol,
                        first_naming_separator: mapObject.naming.separators[0],
                        second_naming_separator: mapObject.naming.separators[1],
                        third_naming_separator: mapObject.naming.separators[2],
                        cells: cells
                    }
                });
            }

            // Add locations group
            else if (mapObject.type === MapObjectType.LOCATIONS_GROUP) {
                const locations = mapObject.locations.map(loc => {
                    return {
                        name: loc.name,
                        code: loc.code,
                        stock_location_id: loc.stockLocationId
                    };
                });
                locationGroups.push({
                    action: mapObject.action,
                    data: {
                        id: mapObject.id,
                        x_coordinate: mapObject.tl[1],
                        y_coordinate: mapObject.tl[0],
                        locations: locations
                    }
                });
            }

            // Add obstacles
            else if (mapObject.type === MapObjectType.OBSTACLE) {
                obstacles.push({
                    action: mapObject.action,
                    data: {
                        id: mapObject.id,
                        name: mapObject.name,
                        x_coordinate: mapObject.tl[1],
                        y_coordinate: mapObject.tl[0],
                        height: mapObject.height,
                        width: mapObject.width
                    }
                });
            }
        }
    });

    const zones = map.zones.filter(allZones => allZones.action !== MapObjectAction.NOTHING).map(zone => {
        return {
            action: zone.action,
            data: {
                id: zone.id,
                name: zone.name,
                x_coordinate: zone.tl[1],
                y_coordinate: zone.tl[0],
                height: zone.height,
                width: zone.width,
                zone_types: zone.zoneTypes
            }
        };
    });

    const rackDeletes = [];
    const locationGroupDeletes = [];
    const obstaclesDeletes = [];
    const zonesDeletes = [];

    map.deletedObjectsAndZones.forEach(del => {
        if (del.type === MapObjectType.RACK) {
            rackDeletes.push(del.id);
        }
        else if (del.type === MapObjectType.LOCATIONS_GROUP) {
            locationGroupDeletes.push(del.id);
        }
        else if (del.type === MapObjectType.OBSTACLE) {
            obstaclesDeletes.push(del.id);
        }
        else if (del.type === MapObjectType.ZONE) {
            zonesDeletes.push(del.id);
        }
    });

    return {
        map_data: mapData,
        map_racks: racks,
        map_location_groups: locationGroups,
        map_obstacles: obstacles,
        map_zones: zones,
        map_racks_delete: rackDeletes,
        map_location_groups_delete: locationGroupDeletes,
        map_obstacles_delete: obstaclesDeletes,
        map_zones_delete: zonesDeletes
    };
}

function ParseRacks(racks, dimensions) {
    const objects = racks.map(rack => {
        const rackCells = rack.cells.map(row => {
            return row.map(cell => {
                const locations = [];
                // Map locations
                cell.locations.forEach(loc => {
                    Store.dispatch('storageMap/mapToStockLocation', {name: loc.name, stockLocationId: loc.stock_location_id})
                        .then(response => locations.push({name: loc.name, code: response.code, stockLocationId: response.stockLocationId}));
                });

                let dimension = {
                    width: cell.width,
                    height: cell.height,
                    depth: cell.depth,
                    color: cell.dimension_color
                };
                // Set dimensions
                if (cell.locations.length !== 0) {
                    const existingDimension = dimensions.find(dim => (dim.height === dimension.height && dim.width === dimension.width && dim.depth === dimension.depth && dim.color === dimension.color));
                    if (existingDimension === undefined) {
                        dimensions.push(dimension);
                    }
                    else {
                        cell.dimensions = existingDimension;
                    }
                }
                else {
                    dimension = null;
                }
                return {
                    dimensions: dimension,
                    mapLocations: locations
                };
            });
        });
        return {
            action: MapObjectAction.NOTHING,
            id: rack.id,
            type: MapObjectType.RACK,
            name: rack.name,
            tl: [
                rack.y_coordinate,
                rack.x_coordinate
            ],
            vertical: rack.vertical,
            width: rack.vertical ? 1 : rack.width,
            height: rack.vertical ? rack.width : 1,
            shelves: rack.shelves,
            naming: {
                numberShelvesFromUp: rack.name_shelves_from_up,
                numberColsFromRight: rack.name_columns_from_right,
                symbols: [
                    {
                        namingSymbol: rack.first_naming_symbol,
                        startingSymbol: rack.first_starting_symbol,
                    },
                    {
                        namingSymbol: rack.second_naming_symbol,
                        startingSymbol: rack.second_starting_symbol,
                    },
                    {
                        namingSymbol: rack.third_naming_symbol,
                        startingSymbol: rack.third_starting_symbol,
                    },
                ],
                separators: [
                    rack.first_naming_separator,
                    rack.second_naming_separator,
                    rack.third_naming_separator
                ]
            },
            locations: rackCells
        };
    });
    return {objects: objects, dimensions: dimensions};
}

function ParseLocationGroups(locationGroups) {
    return locationGroups.map(locationGroup => {
        const locations = [];
        locationGroup.locations.forEach(location => {
            Store.dispatch('storageMap/mapToStockLocation', {name: location.name, stockLocationId: location.stock_location_id})
                .then(response => {
                    locations.push({
                        name: location.name,
                        code: response.code,
                        stockLocationId: response.stockLocationId
                    });
                });
        });
        return {
            action: MapObjectAction.NOTHING,
            id: locationGroup.id,
            type: MapObjectType.LOCATIONS_GROUP,
            vertical: false,
            width: 1,
            height: 1,
            tl: [
                locationGroup.y_coordinate,
                locationGroup.x_coordinate
            ],
            locations: locations
        };
    });
}

function ParseObstacles(obstacles) {
    return obstacles.map(obstacle => {
        return {
            action: MapObjectAction.NOTHING,
            id: obstacle.id,
            type: MapObjectType.OBSTACLE,
            width: obstacle.width,
            height: obstacle.height,
            name: obstacle.name,
            vertical: obstacle.vertical,
            tl: [
                obstacle.y_coordinate,
                obstacle.x_coordinate
            ]
        };
    });
}

function ParseZones(zones) {
    return zones.map(zone => {
        return {
            action: MapObjectAction.NOTHING,
            id: zone.id,
            type: MapObjectType.ZONE,
            zoneTypes: zone.zone_types,
            width: zone.width,
            height: zone.height,
            name: zone.name,
            vertical: zone.vertical,
            tl: [
                zone.y_coordinate,
                zone.x_coordinate
            ]
        };
    });
}

export {
    nextInSequence,
    snakeKeys,
    camelKeys,
    incrementLast,
    getRackColumns,
    getObjectBrPoint,
    getObjectBrCell,
    ImportMapData,
    ExportMap
};