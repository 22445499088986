<template>
  <v-expansion-panels class="my-2 px-1">
    <v-expansion-panel>
      <v-expansion-panel-header class="text-subtitle-1">
        {{ $t(langPath + 'label') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-for="group in taskTypeGroups"
          :key="group.labelPath"
          class="mb-4"
        >
          <div
            class="d-flex flex-row align-center mb-2"
          >
            <span class="text-subtitle-2">
              {{ $t(langPath + group.labelPath) }}
            </span>
            <v-tooltip
              v-if="group.hintPath"
              top
            >
              <template #activator="{ on }">
                <v-icon
                  class="ml-1"
                  v-on="on"
                >
                  $hint
                </v-icon>
              </template>
              {{ $t(langPath + group.hintPath) }}
            </v-tooltip>
          </div>
          <v-row>
            <v-col
              v-for="(taskType, index) of group.items"
              :key="index"
              class="d-flex justify-space-between"
              sm="12"
              md="6"
              lg="4"
            >
              <x-autocomplete
                v-model="values[taskType]"
                :api-data-source="apiDataSource"
                :label="$t(taskNames[taskType])"
                :prepend-icon="taskIcons[taskType]"
                :result-transform="transform"
                multiple
                chips
                disable-autoselect-first
              />
            </v-col>
          </v-row>
        </div>
        <v-btn
          :loading="loading"
          type="submit"
          color="accent"
          @click="submit()"
        >
          <v-icon
            class="mr-2"
          >
            $saveItem
          </v-icon>
          {{ $t('form.save') }}
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import XAutocomplete from "@/app/vuetify-extend/x-autocomplete";
    import {taskIcons, taskNames, tasksWithoutSubstock, tasksWithSubstock, taskTypes} from "@/enum/task_type";
    import {AttributesAPI} from "@/api/AttributesAPI";
    import {APIFilterOP} from "@/service/APIFilters";
    import {APIFilters} from "@jagu/rest-api-filters-client/apiFilters";
    import {has} from "@/utils/object";

    export default {
        name: "StockSubstockTaskAttributesSettings",
        components: {XAutocomplete},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            loading: false,
            taskNames: taskNames,
            taskIcons: taskIcons,
            values: {},
            existingConfigs: [],
            langPath: 'stocks.substocks.task_attributes.'
        }),
        computed: {
            apiDataSource: function () {
                return AttributesAPI.getAll.bind(AttributesAPI);
            },
            taskTypeGroups: function () {
                return [
                    {
                        labelPath: 'forSubstock',
                        items: tasksWithSubstock
                    },
                    {
                        labelPath: 'forStock',
                        hintPath: 'forStockHint',
                        items: tasksWithoutSubstock,
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.fetchAllowedConfigurations();
        },
        methods: {
            fetchAllowedConfigurations: function () {
                this.loading = true;
                AttributesAPI.getAllConfigurationsAllPages({filter: APIFilters.makeFilter(this.getConfigurationsFilter())})
                    .then(response => {
                        this.existingConfigs = response.data.items ?? [];
                        const values = {};
                        (response.data.items ?? []).forEach(config => {
                            const configTaskType = config.task_type;
                            if (!has(values, configTaskType)) {
                                values[configTaskType] = [];
                            }
                            values[configTaskType].push(config.template_id);
                        });
                        this.values = values;
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            getConfigurationsFilter: function (allowed = true) {
                return [
                    {
                        [APIFilterOP.EQUALS]: {
                            'substock.id': this.subStockId
                        }
                    },
                    {
                        [APIFilterOP.EQUALS]: {
                            allowed,
                        }
                    },
                ];
            },
            transform: function (el) {
                return {
                    text: el.value,
                    value: el.id
                };
            },
            submit: function () {
                this.loading = true;
                const inverseValues = {};
                for (const taskType in taskTypes) {
                    this.values[taskType]?.forEach(attributeTemplateId => {
                        if (!has(inverseValues, attributeTemplateId)) {
                            inverseValues[attributeTemplateId] = [];
                        }
                        inverseValues[attributeTemplateId].push(taskType);
                    });
                }

                const existingAttributes = {};
                const promises = [];
                // update configs which were not allowed now
                AttributesAPI.getAllConfigurationsAllPages({filter: APIFilters.makeFilter(this.getConfigurationsFilter(false))})
                    .then(response => {
                        const disallowedConfigs = response.data.items ?? [];
                        disallowedConfigs.forEach(config => {
                            const configTaskType = config.task_type;
                            const configTemplateId = config.template_id;
                            // config was not allowed and user set it as allowed now
                            if (inverseValues[configTemplateId]?.includes(configTaskType)) {
                                // allow
                                promises.push(
                                    AttributesAPI.updateConfiguration(configTemplateId, config.id, {
                                        task_type: configTaskType,
                                        allowed: true
                                    }).catch(this.snack)
                                );
                            }
                            // else do nothing

                            if (!has(existingAttributes, configTemplateId)) {
                                existingAttributes[configTemplateId] = [];
                            }
                            existingAttributes[configTemplateId].push(configTaskType);
                        });

                        // update configs which were allowed now
                        this.existingConfigs.forEach(config => {
                            const configTaskType = config.task_type;
                            const configTemplateId = config.template_id;
                            // config was allowed and user does not want it allowed anymore
                            if (!inverseValues[configTemplateId]?.includes(configTaskType)) {
                                // disallow
                                promises.push(
                                    AttributesAPI.updateConfiguration(configTemplateId, config.id, {
                                        task_type: configTaskType,
                                        allowed: false
                                    }).catch(this.snack)
                                );
                            }
                            // else do nothing

                            if (!has(existingAttributes, configTemplateId)) {
                                existingAttributes[configTemplateId] = [];
                            }
                            existingAttributes[configTemplateId].push(configTaskType);
                        });

                        // create new configs
                        Object.entries(inverseValues).forEach(([attributeTemplateId, taskTypes]) => {
                            // filter out existing configurations for this attribute template
                            let nonExistingTaskTypes = taskTypes;
                            if (existingAttributes[attributeTemplateId] !== undefined) {
                                nonExistingTaskTypes = taskTypes.filter(type => !existingAttributes[attributeTemplateId].includes(type));
                            }
                            if (nonExistingTaskTypes.length === 0) {
                                // continue
                                return;
                            }

                            const configurations = nonExistingTaskTypes
                                .map(type => ({
                                    task_type: type,
                                    allowed: true,
                                }));

                            promises.push(
                                AttributesAPI.createConfiguration(attributeTemplateId, {
                                    substock_id: this.subStockId,
                                    configurations
                                }).catch(this.snack)
                            );
                        });

                        Promise.all(promises)
                            .then(() => {
                                this.snack(this.langPath + 'updateDone');
                            }).finally(() => this.fetchAllowedConfigurations());
                    }).catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
