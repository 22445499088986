export default {
    dailyLocation: 'Napi helyfoglalási jelentés',
    dailyLocationByType: 'Napi helyfoglalási jelentés típusonként',
    dailyLocationWithMovement: 'Napi helyfoglalási jelentés mozgással',
    dailyLocationOccupancy: 'Napi helyfoglaltság',

    parameters: {
        date: 'Dátum',
        interval: 'Intervallum',
        interval_from: 'Dátumtól',
        interval_to: 'dátumig',
        subordinate_stock_ids: 'Alraktárak',
        manufacturer_ids: "Gyártók",
        product_ids: "Készletelemek",
        product_type_ids: 'A készletelemek típusai',
        location_ids: "Raktárhelyszín",
        report_type: 'A jelentés típusa',
        task_types: 'Feladattípus',
        include_missing_products: 'Hiányzó termékek felvétele',
        include_empty_locations: "Zahrnout prázdné umístění"

    },

    attributes: {
        last_loaded_at: 'Utolsó Bevételezés',
        last_picked_at: 'Utolsó Szedés',
        filled_locations_count: 'A betöltött helyek száma',
        object_id: 'Objektum azonosító',
        stock_picked_amount: 'A betöltött helyek száma az időszak végén',
        transfers_count: 'A készletre betöltött összeg',
        period_end_items_amount: 'A tételek összege az időszak végén',
        stock_loaded_amount: 'Az átutalások száma',
        filled_location_count: 'Készletről szedett mennyiség',
        product_type_id: 'Terméktípus azonosító',
        manufacturer_id: 'Gyártó ID',
        hour: 'Óra',
        day: 'Nap',
        week: 'Hét',
        month: 'Hónap',
        year: 'Év',
        week_of_month: 'A hónap hete',
        occupancy: 'foglaltság'
    },

    hints: {
        report_type: 'A jelentés típusa határozza meg hogy az első oszlopok, az "Objektum azonosító" és a "Név" mit tartalmazzanak. Az utolsó oszlop értéke az első oszlopok közül az objektumhoz fog tartozni.',
        interval: 'Az intervallum határozza meg azoknak az időszakoknak a hosszát, amelyekre a teljes kiválasztott időszak felosztásra kerül.'
    }
};