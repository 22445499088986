import {Scope} from "@/enum/oauth_scope";

const Roles = {
    CHIEF: Scope.CHIEF,
    STOREKEEPER: Scope.STOREKEEPER,
    PACKER: Scope.PACKER,
    ORGANIZER: Scope.ORGANIZER,
    ESHOP: Scope.ESHOP,
};

export {Roles};
