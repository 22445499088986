import {measurementUnits} from "@/enum/measurement_unit";

const measurementUnit = {
    name: 'Unit of measurement',
    [measurementUnits.PIECE]: "Piece",
    [measurementUnits.CARTON]: "Carton",
    [measurementUnits.HECTOLITER]: "Hectoliter",
    [measurementUnits.LITER]: "Liter",
    [measurementUnits.DECILITER]: "Deciliter",
    [measurementUnits.CENTILITER]: "Centiliter",
    [measurementUnits.MILLILITER]: "Milliliter",
    [measurementUnits.TONNE]: "Tonne",
    [measurementUnits.KILOGRAM]: "Kilogram",
    [measurementUnits.DECAGRAM]: "Decagram",
    [measurementUnits.GRAM]: "Gram",
    [measurementUnits.MILLIGRAM]: "Milligram",
    [measurementUnits.KILOMETER]: "Kilometer",
    [measurementUnits.METER]: "Meter",
    [measurementUnits.CENTIMETER]: "Centimeter",
    [measurementUnits.MILLIMETER]: "Milimeter",
    [measurementUnits.SQUARE_METER]: "Square meter",
    [measurementUnits.CUBIC_METER]: "Cubic meter",

    [measurementUnits.MILE]: "Mile",
    [measurementUnits.INCH]: "Inch",
    [measurementUnits.POUND]: "Pound",
    [measurementUnits.OUNCE]: "Ounce",
    [measurementUnits.US_GALLON]: "US gallon",
    [measurementUnits.UK_GALLON]: "UK gallon",
    [measurementUnits.SQUARE_FOOT]: "Square foot",
    [measurementUnits.CUBIC_FOOT]: "Cubic foot"
};

export default {
    title: "Products",
    titleSingle: "Product",
    link: "Products",

    name: "Name",
    description: "Description",
    model: "Model",
    manufacturer_id: "Manufacturer",
    type_id: "Type",
    manufacturer: {
        id: "Manufacturer",
        label: "Manufacturer",
        name: "Manufacturer"
    },
    type: {
        id: "Type",
        label: "Type"
    },
    measure_unit: "Unit",
    measurementUnit: measurementUnit,
    visible: "Visible",
    notVisible: "Hidden",
    deleted: "Deleted",
    can_have_batch: "Can have batches",
    can_have_serial_number: "Can have serial numbers",
    created_at: "Created at",
    suggested_buy_price: "Standard purchase price without VAT",
    suggested_sell_price: "Suggested selling price without VAT",
    buy_price: "Purchase price per unit",
    sell_price: "Sell price per unit",
    price_vat: "VAT rate",
    vat_included: "incl. VAT",
    unknown: "<Unknown>",
    tableImage: "",
    instance: "Batch / Series",
    quantity: "Quantity",
    searchHint: "Searches through name, model, manufacturer and unit",

    attributeAlreadyEntered: "This attribute already exists!",
    unableToRemoveLastAttribute: "Last attribute cannot be removed!",

    attributes: {
        name: "Attributes",
        key: "Name",
        value: "Value",
        description: "Description",
        existing_attributes: "Existing Atributes",
        unableToLoad: "Unable to load product attributes. Please try again later.",
        hint: "Empty attributes will not be saved",
        create: {
            titleSimple: "Add attribute",
            title: "Add attribute to '{0}'",
            done: "'{0}' added to '{1}'"
        },
        update: {
            titleSimple: "Update attribute",
            title: "Update '{0}' at '{1}'",
            done: "Updated '{0}' at '{1}'"
        },
        delete: {
            done: "Attribute '{0}' deleted.",
            failed: "Unable to delete '{0}'. Please try again later."
        },
        status: {
            404: "Product card or attribute key not found!",
            409: "Attribute name already exists!"
        }
    },

    overview: {
        name: "Item overview",
        inStock: "In stock",
        notInStock: "This item is not in stock",
        InTasks: "Blocked by tasks",
        notInTasks: "This item is not blocked by any tasks",
        Ordered: "Ordered",
        notOrdered: "There are no orders for this item",
        altogetherPieces: "{0} pcs"
    },

    types: {
        link: "Product types",
        title: "Product types",
        titleSingle: "Product type",
        name: "Name",
        default: "<default>",
        suggestedAttributes: {
            name: "Suggested attributes",
            key: "Name",
            description: "Description",
            hint: "Type attr."
        },

        removeAttribute: "Remove attribute",

        create: {
            label: "Create new product type",
            title: "New product type",
            done: "Product type '{0}' created"
        },
        update: {
            titleSimple: "Update product type",
            title: "Update '{0}'",
            done: "Product type '{0}' updated."
        },
        delete: {
            done: "Product type '{0}' deleted.",
            failed: "Unable to delete product type '{0}'. Please try again later.",
        },

        status: {
            404: "Product type not found!",
            409: "Unable to remove product type, because products with this type are present in the warehouse!"
        }
    },

    batches: {
        name: "Batch",
        code: "Code",
        manufactured_date: "Date of manufacture",
        manufactured_time: "Time of manufacture",
        expire_date: "Date of expiration",
        expire_time: "Time of expiration",
        create: {
            titleSimple: "Add batch",
            title: "Add batch to '{0}'",
            done: "'{0}' added to '{1}'"
        },
        update: {
            titleSimple: "Update batch",
            title: "Update batch '{0}' at '{1}'",
            done: "Updated batch '{0}' at '{1}'"
        },
        delete: {
            done: "Batch '{0}' deleted.",
            failed: "Unable to delete '{0}'. Please try again later.",
            status: {
                404: "Product card or batch code not found!",
                409: "Unable to change batch code due to references to other objects!"
            }
        },
        status: {
            404: "Product card or batch code not found!",
            409: "Batch code is already in use!"
        }
    },

    customInstances: {
        name: "Custom instances",
        hint: "Use these to differentiate items, for example by their condition, and manage their barcodes.",
        product_instance_type_id: "Type",
        create: {
            titleSimple: "Add custom instance",
            label: "Create new concrete item",
            title: "Add custom instance to '{0}'",
            done: "Instance of type '{0}' added to '{1}'"
        },
        update: {
            titleSimple: "Update custom instance",
            title: "Update instance of type '{0}' at '{1}'",
            done: "Updated instance of type '{0}' at '{1}'",
            status: {
                404: "Product card or instance not found!",
                409: "Unable to change instance type due to references to other objects!"
            }
        },
        delete: {
            titleSimple: "Delete custom instance",
            title: "Delete instance of type '{0}' at '{1}'",
            done: "Deleted instance of type '{0}' at '{1}'",
            status: {
                404: "Product card or instance not found!",
                409: "Unable to Delete instance type due to references to other objects!"
            }
        },
        status: {
            404: "Product card not found!",
            409: "Unknown or invalid instance type!"
        }
    },

    images: {
        alt: "Image attached to the product",
        altCreate: "Image to be attached to the product",
        unableToLoad: "Unable to load product images. Please try again later.",
        store_external_images: 'Save external images',
        status: {
            404: "Product card or image not found!"
        }
    },

    instances: {
        name: "Base item barcodes",
        id: "ID",
        type: {
            name: "Type",
            NORMAL: "Normal",
            SERIAL_NUMBER: "Serial number",
            BATCH: "Batch"
        },
        manufactured_date: "Date of manufacture",
        expire_date: "Date of expiration",
        serial_number: "Serial number",
        batch_code: "Batch code",
        serial: "Serial: {0}",
        batch: "Batch: {0}",

        types: {
            label: "Custom instance types",
            name: "Name",

            create: {
                titleSimple: "Add custom instance type",
                title: "New custom instance type",
                label: "Create new custom instance type",
                done: "Custom instance type '{0}' added"
            },
            update: {
                title: "Update custom instance type '{0}'",
                titleSimple: "Update custom instance type",
                done: "Custom instance type'{0}' updated"
            },
            delete: {
                done: "Custom instance type '{0}' deleted!",
                status: {
                    404: "Custom instance type not found!",
                    409: "There are instances with this type in stock!"
                }
            },
            status: {
                404: "Custom instance type not found!",
                409: "Instance type already exists!"
            }
        },

        barcodes: {
            link: "Details",
            title: "'{0}' barcodes",
            titleSimple: "Barcodes",
            name: "Barcode",
            code: "Code",
            image: "Preview",
            quantity: "Quantity",
            description: "Description",
            descriptionHint: 'e.g. package type: "carton", "palette" etc.',
            conflict: "Code is already in use by {0}",

            print: "Print barcode",
            printMany: "Print barcodes",
            printHowMuch: "How many '{0}' barcodes to print?",
            printMultipleHowMuch: "How many barcodes to print?",
            printDone: "Printing barcode '{0}'...",

            batchPrint: {
                label: "Set print configuration",
                printAll: "Print as many labels as there are item pieces",
                printJustOne: "Print just one label for each item",
                totally: "i.e. in total",
            },

            create: {
                title: "Add barcode to '{0}'",
                titleSimple: "Add barcode",
                done: "Barcode '{0}' added to '{1}'"
            },
            update: {
                title: "Update code '{0}' at '{1}",
                titleSimple: "Update code at product",
                done: "Code '{0}' at '{1}' updated!"
            },
            delete: {
                done: "Code '{0}' at '{1}' deleted!"
            },
            status: {
                404: "Product card, item or barcode not found!",
                409: "Barcode is already in use!"
            }
        },

        status: {
            404: "Product card or concrete product not found!"
        }
    },

    serial_numbers: {
        name: "Serial numbers",
        number: "Serial number",
        serial_number: "Serial number",
        same_barcode: "Also create the same barcode",
        create: {
            titleSimple: "Add serial number",
            title: "Add serial number to '{0}'",
            done: "'{0}' added to '{1}'"
        },
        delete: {
            done: "Serial number removed.",
            failed: "Unable to remove serial number. Please try again later.",
            status: {
                404: "Product card, item or serial number not found!",
                409: "Unable to delete serial number due to references to other objects!"
            }
        },
        status: {
            404: "Product card, item or serial number not found!",
            409: "Serial number is already in use!"
        }
    },

    pieces: {
        name: "In stock",
        status: "Stock status",
        movements: "Stock movement"
    },

    show: {
        titleSimple: "Product details",
        title: "{0}"
    },
    create: {
        title: "New product",
        done: "Product '{0}' created."
    },
    update: {
        titleSimple: "Update product",
        title: "Update {0}",
        done: "Product '{0}' updated."
    },
    delete: {
        done: "Product '{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
    },

    status: {
        404: "Product card not found!",
        409: "Unable to remove product because it is present in the warehouse!",
        412: "This product was updated by another user in the meantime. Please try again."
    }
};
