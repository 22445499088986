import {ReportDomains} from "@/app/overview/reports/definitions/reports.definitions";
import i18n from "@/service/lang/i18n";

function ReportsForm() {
    return {
        domain: null,
        type: null
    };
}

function ReportsFormRender() {
    return {
        domain: {
            autocomplete: { items: ReportDomains.map(item => ({
                text: i18n.t(item.text),
                value: item.value
                })) },
            icon: '$graph',
            sticky: true,
        },
        type: {
            autocomplete: {
                items: []
            },
            sticky: true,
        }
    };
}

export {ReportsForm, ReportsFormRender};
