const TaskState = {
    CREATED: 1,
    IN_PROGRESS: 2,
    WAITING: 3,
    CLOSED: 4,
    CANCELED: 5,
    UNASSIGNED: 6
};

/**
 * @see https://projects.jagu.cz/projects/atlantis/wiki/Stavy_objednavky
 */
const ExternalOrderState = {
    EO_STATE_CREATED: "EO_STATE_CREATED",
    EO_STATE_UNABLE_TO_PROCESS: "EO_STATE_UNABLE_TO_PROCESS",
    EO_STATE_IN_PROGRESS: "EO_STATE_IN_PROGRESS",
    EO_STATE_WAITING: "EO_STATE_WAITING",
    EO_STATE_STOCK_PICKING_WAITING: "EO_STATE_STOCK_PICKING_WAITING",
    EO_STATE_STOCK_PICKING_IN_PROGRESS: "EO_STATE_STOCK_PICKING_IN_PROGRESS",
    EO_STATE_STOCK_PICKING_COMPLETED: "EO_STATE_STOCK_PICKING_COMPLETED",
    EO_STATE_STOCK_PICKING_CANCELED: "EO_STATE_STOCK_PICKING_CANCELED",
    EO_STATE_PREPARE_PACKAGE_WAITING: "EO_STATE_PREPARE_PACKAGE_WAITING",
    EO_STATE_PREPARE_PACKAGE_IN_PROGRESS: "EO_STATE_PREPARE_PACKAGE_IN_PROGRESS",
    EO_STATE_PREPARE_PACKAGE_COMPLETED: "EO_STATE_PREPARE_PACKAGE_COMPLETED",
    EO_STATE_PREPARE_PACKAGE_CANCELED: "EO_STATE_PREPARE_PACKAGE_CANCELED",
    EO_STATE_SHIPMENTS_WAITING: "EO_STATE_SHIPMENTS_WAITING",
    EO_STATE_SHIPMENT_CANCELLED: "EO_STATE_SHIPMENT_CANCELLED",
    EO_STATE_SHIPPED: "EO_STATE_SHIPPED",
    // More detailed EO_STATE_SENT
    EO_STATE_SENT: "EO_STATE_SENT",
    EO_STATE_BEING_DELIVERED: "EO_STATE_BEING_DELIVERED",
    EO_STATE_DELIVERED_TO_PICKUP_LOCATION: "EO_STATE_DELIVERED_TO_PICKUP_LOCATION",
    EO_STATE_LOST: "EO_STATE_LOST",
    // end of EO_STATE_SENT details
    EO_STATE_DELIVERED: "EO_STATE_DELIVERED",
    EO_STATE_RETURNING: "EO_STATE_RETURNING",
    EO_STATE_RETURNED: "EO_STATE_RETURNED",
    EO_STATE_CANCELLED: "EO_STATE_CANCELLED",
    EO_STATE_CLOSED: "EO_STATE_CLOSED"
};

export {TaskState, ExternalOrderState};
