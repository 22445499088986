import { render, staticRenderFns } from "./StockLocationLocksList.component.vue?vue&type=template&id=6deab97a&scoped=true"
import script from "./StockLocationLocksList.component.vue?vue&type=script&lang=js"
export * from "./StockLocationLocksList.component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.95.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6deab97a",
  null
  
)

export default component.exports