const notFound = "A megadott gyártó nem létezik!";

export default {
    title: "Gyártók",
    link: "Gyártók",

    abbreviation: "Rövidítés",
    name: "Név",

    list: {
        unableToLoad: "A gyártók listáját nem sikerült betölteni. Kérjük, próbálja meg később újra."
    },
    create: {
        label: "Új gyártó létrehozása",
        title: "Termelő létrehozása",
        done: "A '{0}' termelőt létrehozták.",
        undo: {
            done: "A '{0}' létrehozása sikeresen visszaküldött!",
            failed: "Nem sikerült visszaadni a producer létrehozását!"
        }
    },
    update: {
        titleSimple: "Szerkessze a gyártót",
        title: "Szerkesztette {0}",
        done: "Gyártó '{0}' módosítva",
        undo: {
            done: "A '{0}' szerkesztése sikeresen visszaküldésre került!",
            failed: "Nem sikerült visszaállítani a gyártó szerkesztését!"
        }
    },
    delete: {
        done: "A '{0}' gyártó törlésre került.",
        failed: "A '{0}' termelő nem törölhető. Kérjük, próbálja meg később újra.",
        undo: {
            done: "A '{0}' törlése sikeresen visszaküldött!",
            failed: "Nem sikerült a gyártó törlését visszacsinálni!"
        },
        status: {
            404: notFound,
            409: "A gyártókat nem lehet törölni a raktárcikkekre mutató hivatkozások miatt."
        }
    },
    status: {
        404: notFound,
        409: "A kitöltött parancsikon már létezik!"
    }
};
