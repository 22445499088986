<template>
  <div>
    <template v-if="!showItemsThatCanBePutOn">
      <TaskItemsLocationHeader :location-id="locationId" />
    </template>
    <template v-else>
      <v-bottom-sheet
        scrollable
        eager
      >
        <template #activator="{ on }">
          <div>
            <span
              style="cursor: pointer"
              v-on="on"
            >
              <TaskItemsLocationHeader :location-id="locationId" />
            </span>
            <span v-if="$vuetify.breakpoint.smAndDown">
              <v-icon class="iconPutItems mr-1">
                $scannerDestination
              </v-icon>
              <v-btn
                class="text--primary text-decoration-none"
                text
                x-small
                v-on="on"
              >
                <v-icon
                  x-small
                >
                  $openItem
                </v-icon>
              </v-btn>
            </span>
            <v-layout
              v-else
              align-center
              wrap
            >
              <v-icon class="iconPutItems mr-1">
                $scannerDestination
              </v-icon>
              <v-chip
                v-for="(instance, index) of items"
                :key="index"
                class="x-smallEllipsis"
                label
                outlined
                x-small
              >
                {{ instance | instanceLabel }}
              </v-chip>
              <v-btn
                v-if="allowedInstances.length > 2"
                class="text--primary text-decoration-none"
                text
                x-small
                v-on="on"
              >
                <v-icon
                  x-small
                >
                  $openItem
                </v-icon>
              </v-btn>
            </v-layout>
          </div>
        </template>
        <v-sheet>
          <v-layout
            align-center
            column
            wrap
            class="py-3"
          >
            <v-flex
              class="title mx-4"
            >
              {{ location | locationLabel }}
            </v-flex>
            <span>
              <v-divider
                class="my-2"
              />
              <TaskLocationDetailItems
                :allowed-instances="allowedInstances"
                style="max-width: 95vw"
              />
            </span>
          </v-layout>
        </v-sheet>
      </v-bottom-sheet>
      <v-divider
        class="mt-1"
      />
    </template>
  </div>
</template>

<script>
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import TaskLocationDetailItems from "@/app/tasks/components/taskLocationsCard/TaskLocationDetailItems.component";
    import TaskItemsLocationHeader from "@/app/tasks/components/taskItemsCard/TaskItemsLocationHeader.component.vue";

    export default {
        name: "TaskItemsLocationHeaderWithAllowedItems",
        components: {TaskItemsLocationHeader, TaskLocationDetailItems},
        mixins: [ReactiveLocationCacheMixin],
        props: {
            locationId: {
                type: Number,
                default: 0
            },
            allowedInstances: {
                type: Array,
                default: null
            },
            allowedLocationsLoaded: {
                type: Boolean,
                default: false
            },
            cardType: {
                type: Number,
                default: 0
            },
            numOfInstances: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            TaskItemsCardType: TaskItemsCardType
        }),
        computed: {
            location: function () {
                return this.LocationCache[this.locationId] || {
                    name: '',
                    code: `<${this.$t('base.loading')}...>`
                };
            },
            items: function () {
                return this.allowedInstances.slice(0, 2);
            },
            showItemsThatCanBePutOn: function () {
                return this.cardType === TaskItemsCardType.MOVED
                    && this.allowedLocationsLoaded
                    && this.allowedInstances
                    && this.allowedInstances.length !== 0
                    && this.allowedInstances.length < this.numOfInstances;
            }
        }
    };
</script>

<style lang="sass">
.iconPutItems
  transform: rotate(90deg)

.v-chip.x-smallEllipsis .v-chip__content
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  display: inline-block
  margin-bottom: 0.5em

</style>
