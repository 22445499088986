<template>
  <v-container fluid>
    <template v-if="!loading && domainTemplate.export_parameters.length > 0 && !hideParameters">
      <Parameters
        :parameters="domainTemplate.export_parameters"
        :read-only="readOnly"
        :parameter-values="config ? config.parameters : []"
        :domain="domain"
        is-export
        :show-optional="showOptionalParameters"
        :title="$t('exports.parameters.title')"
        @emitParameters="values => parameterValues = values"
        @validParameters="valid => validConfig = valid"
      />
    </template>
    <v-card-title>
      {{ $t('exports.configuration.config') }}
      <v-icon
        class="ml-2"
        color="muted"
        @click="showConfigHint=!showConfigHint"
      >
        $info
      </v-icon>
    </v-card-title>
    <v-card-subtitle
      v-if="showConfigHint"
    >
      {{ $t('exports.info.config_hint') }}
    </v-card-subtitle>
    <div class="overflow-x-auto">
      <div style="width: max-content">
        <HeaderGroup
          v-if="!loading"
          :group="domainTemplate.columns[0]"
          :domain="domain"
          :read-only="readOnly"
          :depth="countDepth()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
    import {ExportsAPI as API} from "@/api/ExportsAPI";
    import {ImportsAPI} from "@/api/ImportsAPI";
    import formRules from "@/utils/formRules";
    import Parameters from "@/app/exports/components/Parameters.component";
    import HeaderGroup from "@/app/exports/components/HeaderGroup";

    export default {
        name: "ExportConfig",
        components: {Parameters, HeaderGroup},
        props: {
            domain: {
                type: String,
                default: null
            },
            newConfigName: {
                type: String,
                default: '',
            },
            config: {
                type: Object,
                default: null
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            hideParameters: {
                type: Boolean,
                default: false,
            },
            saveTrigger: {
                type: Number,
                default: 0
            },
            showOptionalParameters: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: true,
            formRules: formRules,
            domainTemplate: null,
            parameterValues: null,
            showConfigHint: false,
            showParametersHint: false,
            newConfig: true,
            validConfig: true
        }),
        watch: {
            domain() {
                this.getDomainTemplate();
            },
            saveTrigger() {
                this.save();
            },
            validConfig: {
                immediate: true,
                handler: function() {
                    this.$emit('validConfig',this.validConfig);
                }
            }
        },
        createdOrActivated() {
            this.getDomainTemplate();
        },
        methods: {
            getDomainTemplate() {
                this.loading = true;
                ImportsAPI.getTemplate(this.domain)
                    .then(res => {
                        this.domainTemplate = res.data;
                        if (this.config !== null) {
                            this.applyConfig(this.domainTemplate.columns[0]);
                            this.newConfig = false;
                        }
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loading = false;
                        if (!this.domainTemplate.export_parameters.length) {
                            this.validConfig = true;
                        }
                    });
            },

            applyConfig(group) {
                group.columns.forEach(item => {
                    if (item.columns) {
                        this.applyConfig(item);
                    } else {
                        const configVal = this.config.columns.find(configItem => configItem.code === item.code);
                        item.is_hidden = configVal.hidden ?? false;
                    }
                });
                group.is_hidden = !group.columns.some(item => !item.is_hidden);
            },

            countDepth() {
                return this.countGroupDepth(this.domainTemplate.columns[0]);
            },

            countGroupDepth(group) {
                let maxDepth = 0;
                group.columns.filter(item => item.columns).forEach(group => {
                    maxDepth = Math.max(maxDepth, this.countGroupDepth(group));
                });
                return maxDepth + 1;
            },

            scalarsInGroup(group) {
                let scalars = group.columns.filter(item => !item.columns);
                group.columns.filter(item => item.columns).forEach(item => {
                    scalars = scalars.concat(this.scalarsInGroup(item));
                });
                return scalars;
            },

            save() {
                this.loading = true;
                const headers = this.scalarsInGroup(this.domainTemplate.columns[0]).map(item => ({
                    code: item.code,
                    hidden: item.is_hidden,
                }));

                const data = {
                    domain: this.domain,
                    name: this.newConfigName,
                    columns: headers,
                    parameters: this.parameterValues
                };
                if (this.newConfig || this.saveTrigger === -1) {
                    API.createConfiguration(data)
                        .then(() => {
                            this.snack('exports.configuration.created');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/exports');
                        }).catch(err => {
                            this.snack(err);
                            this.loading = false;
                        });
                } else {
                    delete data.domain;
                    API.updateConfiguration(this.config.id, data)
                        .then(() => {
                            this.snack('exports.configuration.updated');
                        }).catch(err => {
                            this.snack(err);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
        }
    };
</script>
