import {TableFilter} from "@/enum/table_filter";

function ManufacturerForm() {
    return {
        name: '',
        abbreviation: '',
    };
}

function ManufacturersRender() {
    return {
        name: {
            icon: '$itemName',
            max: 50,
            required: true
        },
        abbreviation: {
            icon: '$manufacturerAbbreviation',
            max: 16,
            required: true
        }
    };
}

const manufacturersTable = {
    abbreviation: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    }
};

export {ManufacturerForm, ManufacturersRender, manufacturersTable};
