const StockLocationLockType = {
    PRODUCT_TYPE: 'LOCK_PRODUCT_TYPE',
    MANUFACTURER: 'LOCK_MANUFACTURER',
    PRODUCT: 'LOCK_PRODUCT',
    INSTANCE: 'LOCK_INSTANCE'
};

const StockLocationLockIcons = {
    LOCK_PRODUCT_TYPE: '$productType',
    LOCK_MANUFACTURER: '$manufacturer',
    LOCK_PRODUCT: '$product',
    LOCK_INSTANCE: '$productInstance'
};

export {StockLocationLockType, StockLocationLockIcons};
