import {MapObjectType, PanelMode} from "@/enum/stock_map";
import {Store} from "@/service/store/Store";

function ToolbarButtons(){
    return {
        NEW_RACK_BTN: {
            type: buttonType.NEW_OBJECT,
            label: 'map.addRack',
            icon: '$rack',
            conditions: ButtonsConditions().NEW_OBJECT_BTN_COND,
            action: () => {HandleNewObjectButton(PanelMode.NEW_RACK);}
        },
        NEW_LOCATIONS_GROUP_BTN: {
            type: buttonType.NEW_OBJECT,
            label: 'map.addLocationsGroup',
            icon: '$locationGroup',
            conditions: ButtonsConditions().NEW_OBJECT_BTN_COND,
            action: () => {HandleNewObjectButton(PanelMode.NEW_LOCATIONS_GROUP);}
        },
        NEW_ZONE_BTN: {
            type: buttonType.NEW_OBJECT,
            label: 'map.addZone',
            icon: '$zone',
            conditions: ButtonsConditions().NEW_OBJECT_BTN_COND,
            action: () => {HandleNewObjectButton(PanelMode.NEW_ZONE);}},
        NEW_OBSTACLE_BTN: {
            type: buttonType.NEW_OBJECT,
            label: 'map.addObstacle',
            icon: '$obstacle',
            conditions: ButtonsConditions().NEW_OBJECT_BTN_COND,
            action: () => {HandleNewObjectButton(PanelMode.NEW_OBSTACLE);}
        },
        EDIT_BTN: {type: buttonType.OBJECT_ACTION,
            label: 'map.detail',
            icon: '$rackDetail',
            conditions: ButtonsConditions().EDIT_RACK_BTN_COND,
            action: HandleEditButton
        },
        MOVE_BTN: {
            type: buttonType.OBJECT_ACTION,
            label: 'map.tools.move',
            icon: '$moveObject',
            conditions: ButtonsConditions().MOVE_DELETE_CANCEL_BTN_COND,
            action: HandleMoveButton
        },
        ROTATE_BTN: {
            type: buttonType.OBJECT_ACTION,
            label: 'map.tools.rotate',
            icon: '$rotateObject',
            conditions: ButtonsConditions().ROTATE_COPY_BTN_COND,
            action: HandleRotationButton
        },
        COPY_BTN: {
            type: buttonType.OBJECT_ACTION,
            label: 'map.tools.copy',
            icon: '$copyObject',
            conditions: ButtonsConditions().ROTATE_COPY_BTN_COND,
            action: HandleCopyButton},
        DELETE_BTN: {
            type: buttonType.OBJECT_DELETE,
            label: 'map.tools.delete',
            icon: '$deleteObject',
            conditions: ButtonsConditions().MOVE_DELETE_CANCEL_BTN_COND,
            action: HandleDeleteButton},
        CANCEL_SELECTION_BTN: {
            type: buttonType.OBJECT_ACTION,
            label: 'map.tools.cancel',
            icon: '$close',
            conditions: ButtonsConditions().MOVE_DELETE_CANCEL_BTN_COND,
            action: HandleCancelButton
        },
        CANCEL_ACTION_BTN: {
            type: buttonType.OBJECT_ACTION,
            label: 'map.tools.cancelAction',
            icon: '$close',
            conditions: ButtonsConditions().CANCEL_ACTION_BTN_COND,
            action: HandleCancelButton
        },
    };
}

function ButtonsConditions ()  {
    const selectedObject = Store.getters["storageMap/getSelectedObject"];
    const panelMode = Store.getters["storageMap/getPanelMode"];
    return {
        NEW_OBJECT_BTN_COND: selectedObject === null && panelMode === PanelMode.STANDBY,
        EDIT_RACK_BTN_COND: selectedObject !== null && selectedObject.type === MapObjectType.RACK && panelMode === PanelMode.STANDBY,
        MOVE_DELETE_CANCEL_BTN_COND: selectedObject !== null && panelMode === PanelMode.STANDBY,
        ROTATE_COPY_BTN_COND: selectedObject !== null && selectedObject.type !== MapObjectType.LOCATIONS_GROUP && panelMode === PanelMode.STANDBY,
        CANCEL_ACTION_BTN_COND: panelMode !== PanelMode.STANDBY
    };
}

const buttonType= {
    NEW_OBJECT: {
        color: 'accent',
        iconColor: 'mapNewObjectButtonIcon'
    },
    OBJECT_ACTION: {
        color: 'mapObjectActionButton',
        iconColor: 'mapObjectActionButtonIcon'
    },
    OBJECT_DELETE: {
        color: 'mapDeleteObjectButton',
        iconColor: 'mapNewObjectButtonIcon'
    }
};

function HandleNewObjectButton(newMode) {
    Store.commit('storageMap/setPanelMode', {
        mode: newMode
    });
    Store.commit('storageMap/selectObject', {
        object: null
    });
}

function HandleEditButton() {
    Store.commit('storageMap/setPanelMode', {
        mode: PanelMode.EDIT_RACK
    });
}

function HandleMoveButton() {
    Store.commit('storageMap/setPanelMode', {
        mode: PanelMode.MOVE
    });
}

function HandleRotationButton() {
    const object = Store.getters["storageMap/getSelectedObject"];
    const height = object.height;
    const width = object.width;
    Store.dispatch('storageMap/rotateObject', {
        object: object
    }).then(()=>{
        const newObject = Store.getters["storageMap/getSelectedObject"];
        if(!(height===newObject.width && width===newObject.height)){
            Store.commit('snackbar/set',{text: 'map.cannotFinishActionDueToCollision'});
        }
    });
}

function HandleCopyButton() {
    Store.commit('storageMap/setPanelMode', {
        mode: PanelMode.COPY
    });
}

function HandleDeleteButton() {
    Store.dispatch('storageMap/deleteObject');
}

function HandleCancelButton() {
    Store.commit('storageMap/setPanelMode', {
        mode: PanelMode.STANDBY
    });
    Store.commit('storageMap/selectObject',{
        object: null
    });
}

export {ToolbarButtons};
