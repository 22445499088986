import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{staticClass:"my-2 px-1"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + 'label'))+" ")]),_c(VExpansionPanelContent,[_vm._l((_vm.settings),function(setting,index){return _c('div',{key:index,staticClass:"mt-4"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.langPath + setting.title))+" ")]),_c('div',{staticClass:"d-flex flex-wrap flex-row"},_vm._l((_vm.configurableTasks),function(taskType){return _c('div',{key:taskType,staticClass:"px-2"},[_c(VCheckbox,{attrs:{"label":_vm.$t(_vm.taskNames[taskType])},model:{value:(setting.model[taskType]),callback:function ($$v) {_vm.$set(setting.model, taskType, $$v)},expression:"setting.model[taskType]"}})],1)}),0)])}),_c(VBtn,{attrs:{"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }