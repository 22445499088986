/**
 * @see https://cli.vuejs.org/guide/mode-and-env.html
 * @type {{DOMAIN_LABEL, APP_VERSION, AUTH_URL, API_URL, OAUTH_CLIENT_ID, APP_URL}}
 */
import Axios from "axios";

let config = {
    DOMAIN_LABEL: process.env.VUE_APP_DOMAIN_LABEL,
    APP_VERSION: process.env.VUE_APP_VERSION,
    API_URL: process.env.VUE_APP_API_URL,
    AUTH_URL: process.env.VUE_APP_AUTH_BASE,
    APP_URL: window.location.origin,
    OAUTH_CLIENT_ID: process.env.VUE_APP_OAUTH_CLIENT_ID
};

function loadDynamicConfig() {
    return new Promise((resolve) => {
        Axios.get(config.APP_URL + '/config.json')
            .then(dynamicConfig => {
                window.console.info('Dynamic config found:');
                window.console.info(dynamicConfig.data);
                config = Object.assign(config, dynamicConfig.data);
                window.console.info('Successfully loaded dynamic config.');
            })
            .catch(() => {
                window.console.info('No dynamic config found or failed to load. Using defaults.');
            })
            .finally(resolve);
    });
}

export {config, loadDynamicConfig};
