export default {
    dailyLocation: 'Denní report obsazenosti umístění',
    dailyLocationByType: 'Denní report obsazenosti umístění podle typu',
    dailyLocationWithMovement: 'Denní report obsazenosti umístění s pohyby',
    dailyLocationOccupancy: 'Denní obsazenost umístění',


    parameters: {
        date: 'Datum',
        interval: 'Interval',
        interval_from: 'Datum od',
        interval_to: 'Datum do',
        subordinate_stock_ids: 'Podsklady',
        manufacturer_ids: "Výrobci",
        product_ids: "Skladové položky",
        product_type_ids: 'Typy skladových položek',
        location_ids: "Umístění ve skladu",
        report_type: 'Typ reportu',
        task_types: 'Typ úkolu',
        include_missing_products: "Zahrnout chybějící skladové položky",
        include_empty_locations: "Zahrnout prázdné umístění"
    },

    attributes: {
        last_loaded_at: 'Naposledy naskladněno',
        last_picked_at: 'Naposledy vyskladněno',
        filled_locations_count: 'Mnoství plných lokací',
        object_id: 'Identifikátor objektu',
        filled_location_count: 'Počet obsazených umístění na konci období',
        stock_loaded_amount: 'Množství vložené na sklad',
        period_end_items_amount: 'Množství položek na konci období',
        transfers_count: 'Počet přesunů',
        stock_picked_amount: 'Množství vybrané ze skladu',
        product_type_id: 'ID typu skladové položky',
        manufacturer_id: 'ID výrobce',
        hour: 'Hodina',
        day: 'Den',
        week: 'Týden',
        month: 'Měsíc',
        year: 'Rok',
        week_of_month: 'Týden v měsíci',
        occupancy: 'obsazenost',
    },

    hints: {
        report_type: 'Typ reportu zadává co bude obsahem prvních sloupců: "Identifikátor objektu" a "Název". Hodnota posledného sloupce bude patřit objektu z prvních sloupců.',
        interval: 'Interval určuje délku časových úseků, na které se bude dělit celé zvolené období.'
    }
};