import {Roles} from "@/enum/roles";
import TimeOverview from "@/app/overview/time/TimeOverview.view";

const BASE_URL = '/time';

const routes = {
    base: {
        path: BASE_URL,
        component: TimeOverview,
        meta: {
            title: 'tasks.timeEntries.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    }
};

export default Object.values(routes);
