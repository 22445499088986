import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

/**
 * @see https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
 */
export default function loadExtendComponents() {
    const requireComponent = require.context('@/app/vuetify-extend', false, /x-.*\.(vue|js)$/); // do not look into subfolders

    requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName);
        const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));
        Vue.component(componentName, componentConfig.default || componentConfig);
    });
}
