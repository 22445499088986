import i18n from "@/service/lang/i18n";

const PrintType = {
    PDF: 'pdf',
    ZPL: 'zpl',
    ZPLX: 'zplx',
    ZPLX2: 'zplx2',
    ZPL_PD: 'zplpd',
    ESCP: 'escp',
    HTML: 'html'
};

const barcodePrintTypes = [
    PrintType.PDF,
    PrintType.ZPL,
    PrintType.ZPLX,
    PrintType.ZPLX2,
    PrintType.ZPL_PD,
    PrintType.ESCP,
];

function getBarcodePrintTypeItems () {
    return barcodePrintTypes.map(printType => ({
        value: printType,
        text: i18n.t('base.print_type.' + printType)
    }));
}

export {PrintType, getBarcodePrintTypeItems};
