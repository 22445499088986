import {populate} from "@/utils/object";
import {routeBackTo} from "@/utils/url";
import {formMountedProcedure} from "@/utils/form";

/**
 * Requires:
 * - this.isEdit
 */
const FormFetchItemMixin = {
    mounted: function () {
        formMountedProcedure(this);
    },
    createdOrActivated: function (lifeCycleHook) {
        this.fetchItem(lifeCycleHook === this.LifeCycleHook.CREATED);
    },
    methods: {
        fetchItem: function (isCreated) {
            if (this.isEdit) {
                isCreated && this.$store.commit('loader/overlay/show');
                this.formFetchItem()
                    .then(response => {
                        populate(this.form, response.data);
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push(routeBackTo(this.$route));
                    }).finally(() => {
                        isCreated && this.$store.commit('loader/overlay/hide');
                    });
            }
        },
    }
};

export {FormFetchItemMixin};
