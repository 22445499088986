import {APIFilterOP} from "@/service/APIFilters";

const TableFilter = {
    TEXT: 0,
    NUMBER: 1,
    BOOLEAN: 2,
    IS_NULL: 3,
    IS_NOT_NULL: 4,
    ARRAY_EMPTY: 5,
    ARRAY_NOT_EMPTY: 6,
    DATETIME: 7,
    MULTI_BOOLEAN: 8,
    SELECT: 9,
    SELECT_MULTIPLE: 10,
    ARRAY_CONTAINS: 11,
    ARRAY_CONTAINS_MULTIPLE: 12
};

const TableFilterApiOperator = {
    [TableFilter.TEXT]: APIFilterOP.LIKE,
    [TableFilter.NUMBER]: APIFilterOP.EQUALS,
    [TableFilter.BOOLEAN]: APIFilterOP.EQUALS,
    [TableFilter.IS_NULL]: {
        true: APIFilterOP.IS_NULL,
        false: APIFilterOP.IS_NOT_NULL
    },
    [TableFilter.IS_NOT_NULL]: {
        true: APIFilterOP.IS_NOT_NULL,
        false: APIFilterOP.IS_NULL
    },
    [TableFilter.ARRAY_EMPTY]: {
        true: APIFilterOP.ARRAY_EMPTY,
        false: APIFilterOP.ARRAY_NOT_EMPTY
    },
    [TableFilter.ARRAY_NOT_EMPTY]: {
        true: APIFilterOP.ARRAY_NOT_EMPTY,
        false: APIFilterOP.ARRAY_EMPTY
    },
    [TableFilter.ARRAY_CONTAINS]: APIFilterOP.ARRAY_CONTAINS,
    [TableFilter.ARRAY_CONTAINS_MULTIPLE]: APIFilterOP.ARRAY_CONTAINS,
    [TableFilter.DATETIME]: null, // handled by TableDateFilter component
    [TableFilter.MULTI_BOOLEAN]: APIFilterOP.IN,
    [TableFilter.SELECT]: APIFilterOP.EQUALS,
    [TableFilter.SELECT_MULTIPLE]: APIFilterOP.IN
};

export {TableFilter, TableFilterApiOperator};
