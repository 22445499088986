import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"item-class":item => item.active === false && 'text--disabled',"actions":_vm.actions,"batch-actions":_vm.batchActions},scopedSlots:_vm._u([{key:"item.roles",fn:function({ item }){return [_c('td',_vm._l((_vm.Roles),function(role){return _c('span',{key:role,class:{visibilityHidden: !item['is_' + role.split('_')[1].toLowerCase()]}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({attrs:{"outlined":""}},on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons[role])+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('users.role.' + role))+" ")])])],1)}),0)]}},{key:"item.has_permission",fn:function({ item }){return [(_vm.loading)?[_c(VProgressCircular,{attrs:{"size":"25","color":"muted","indeterminate":""}})]:[(item.has_permission)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" $success ")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" $closeItem ")])]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }