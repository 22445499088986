<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.locationTransfer."
          />
          <v-flex
            v-if="form.stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskLocationTransfer
              </v-icon>
              {{ $t('tasks.locationTransfer.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {
        LocationTransferCreateForm,
        LocationTransferCreateRender
    } from "@/app/tasks/locationTransfer/definitions/locationTransferCreate.form";
    import {TaskLocationTransferAPI} from "@/api/TaskLocationTransferAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";

    export default {
        name: "LocationTransferCreate",
        components: {TaskAttributes, TaskAttachmentUpload, FormFields},
        data: () => ({
            form: new LocationTransferCreateForm,
            formRender: new LocationTransferCreateRender,
            valid: true,
            validAttachments: true,
            validAttributes: true,
            loading: false,
            type: taskTypes.LOCATION_TRANSFER,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            validAll: function () {
                return this.valid && this.validAttachments && this.validAttributes;
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                TaskLocationTransferAPI.create(this.form)
                    .then(response => {
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.locationTransfer.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    })
                    .catch(err => {
                        setFormErrors.call(this, err);
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
