<template>
  <div
    v-show="isEnabled"
  >
    <v-menu
      v-if="isRunning"
      offset-y
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-icon
          class="pa-2"
          v-on="on"
        >
          timer
        </v-icon>
      </template>
      <v-list>
        <v-list-item>
          {{ elapsed | elapsedTime }}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-icon
      v-else
      disabled
      class="pa-2"
    >
      timer_off
    </v-icon>
  </div>
</template>

<script>
    import {LiveTimeTrackerMixin} from "@/app/mixins/LiveTimeTrackerMixin";

    export default {
        name: "ToolbarTrackTime",
        mixins: [LiveTimeTrackerMixin],
        computed: {
            isEnabled: function () {
                return this.$store.getters['userConfig/trackTime'];
            }
        }
    };
</script>

<style scoped>

</style>
