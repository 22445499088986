import {Store} from "@/service/store/Store";
import {Roles} from "@/enum/roles";

function drawerMenu() {
    const isChief = Store.getters['oauth/hasScope'](Roles.CHIEF);
    const isOrganizer = Store.getters['oauth/hasScope'](Roles.ORGANIZER);

    return [
        {
            header: 'homepage.overview',
            items: [
                {
                    to: '/',
                    label: 'homepage.tasks',
                    icon: '$task',
                    show: isChief || isOrganizer
                }, {
                    to: '/status',
                    label: 'stocks.stockStatus.link',
                    icon: '$stockStatus',
                    show: isChief || isOrganizer
                }, {
                    to: '/movements',
                    label: 'stocks.stockMovements.link',
                    icon: '$stockMovements',
                    show: isChief || isOrganizer
                    // }, { // TODO #15618
                    //     to: '/time',
                    //     label: 'tasks.timeEntries.link',
                    //     icon: 'timer'
                }, {
                    to: '/shipping',
                    label: 'base.shipping.link',
                    icon: '$shipping',
                    show: isChief
                }, {
                    to: '/shipping/protocols',
                    label: 'base.shipping.handover.link',
                    icon: '$shippingHandover',
                    submenu: true,
                    show: isChief
                },
                {
                    to: '/reports',
                    label: 'base.reports.title',
                    icon: '$graph',
                    submenu: false,
                    show: isChief
                }
            ]
        }, {
            header: 'homepage.manage',
            items: [
                {
                    to: '/products',
                    label: 'products.link',
                    icon: '$product',
                    show: isChief
                }, {
                    to: '/products/types',
                    label: 'products.types.link',
                    icon: '$productType',
                    submenu: true,
                    show: isChief
                }, {
                    to: '/stocks',
                    label: 'stocks.link',
                    icon: '$stock',
                    show: isChief
                }, {
                    to: '/stocks/owners',
                    label: 'stocks.owners.link',
                    icon: '$owners',
                    submenu: true,
                    show: isChief
                }, {
                    to: '/manufacturers',
                    label: 'manufacturers.link',
                    icon: '$manufacturer',
                    show: isChief
                }, {
                    to: '/suppliers',
                    label: 'suppliers.link',
                    icon: '$supplier',
                    show: isChief
                }, {
                    to: '/carriers',
                    label: 'carriers.link',
                    icon: '$carrier',
                    show: isChief
                }, {
                    to: '/attribute-templates',
                    label: 'base.attributes.templates.link',
                    icon: '$attributeTemplate',
                    show: isChief
                }, {
                    to: '/buyers',
                    label: 'buyers.link',
                    icon: '$buyers',
                    show: isChief
                }, {
                    to: '/buyers/types',
                    label: 'buyers.types.link',
                    icon: '$buyerType',
                    submenu: true,
                    show: isChief
                }, {
                    to: '/users',
                    label: 'users.link',
                    icon: '$users',
                    show: isChief
                }, {
                    to: '/imports',
                    label: 'imports.link',
                    icon: '$imports',
                    show: isChief

                },
                {
                    to: '/exports',
                    label: 'exports.link',
                    icon: '$exports',
                    show: isChief
                }
            ]
        }
    ];
}

export {drawerMenu};
