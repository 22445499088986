import Vue from "vue";

// Share Cache values across all components which use this mixin
const UserCache = {};
let trigger = Vue.observable({count: 0});

const ReactiveUserCacheMixin = {
    computed: {
        UserCache: function () {
            // We need this to trigger an update in each components which might be using this Mixin simultaneously
            trigger.count;
            return UserCache;
        }
    },
    methods: {
        cacheUser: function (runningRequest, key) {
            UserCache[key] = `<${this.$t('base.loading')}...`;
            return runningRequest.then(response => {
                UserCache[key] = response.data;
                trigger.count++;
                return response;
            });
        }
    }
};

export {ReactiveUserCacheMixin};
