import {Note} from "@/app/tasks/definitions/taskCommon.render.form";
import {TerminalShipmentStates} from "@/enum/shipment_state";
import i18n from "@/service/lang/i18n";

function ExternalOrderCloseForm() {
    return {
        note: null,
        shipment_state: null
    };
}

function ExternalOrderCloseRender() {
    return {
        note: new Note,
        shipment_state: {
            icon: '$package',
            required: true,
            autocomplete: {
                items: TerminalShipmentStates.map(state => ({
                    text: i18n.t('base.shipping.shipment.state.' + state),
                    value: state
                }))
            }
        }
    };
}

export {
    ExternalOrderCloseForm,
    ExternalOrderCloseRender
};
