<template>
  <div>
    <x-toolbar
      :tabs="isSmall"
      :extension="isSmall"
    >
      <template
        v-if="isSmall && !loading"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary"
          grow
          center-active
        >
          <v-tabs-slider color="accent" />
          <v-tab
            v-for="(item, index) of visibleItems"
            :key="index"
          >
            {{ item.props.label }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <template v-if="isSmall">
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) of visibleItems"
          :key="index"
        >
          <template v-if="item.type === 'taskList'">
            <v-skeleton-loader
              v-if="showSkeletonLoaders"
              type="card-heading, list-item-two-line"
            />
            <TaskList
              v-else
              v-bind="item.props"
            />
          </template>
          <OrdersStatsTimeline
            v-if="!loading && item.type === 'ordersStats'"
            v-bind="item.props"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-else>
      <!-- layouts need to be ready when grid layout is created, therefore I can't display skeleton loaders in grid -->
      <masonry
        v-if="showSkeletonLoaders"
        :cols="$vuetify.breakpoint.lgAndUp ? 4 : 2"
        :gutter="0"
      >
        <div
          v-for="(_, index) of visibleItems"
          :key="'loader-' + index"
        >
          <v-container fluid>
            <v-skeleton-loader
              type="card-heading, list-item-two-line"
            />
          </v-container>
        </div>
      </masonry>
      <grid-layout
        v-else
        :layout.sync="layout"
        :responsive-layouts="layouts"
        :cols="gridCols"
        :breakpoints="gridBreakpoints"
        :is-resizable="false"
        :row-height="10"
        responsive
        @breakpoint-changed="breakpointChanged"
      >
        <grid-item
          v-for="item in layout"
          :key="item.i"
          style="touch-action: pan-y"
          :i="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          @moved="itemsMoved"
        >
          <Resizable>
            <v-container fluid>
              <template v-if="loadedItems[item.i].type === 'taskList'">
                <TaskList
                  v-bind="loadedItems[item.i].props"
                  :config-enter-callback="saveItems"
                />
              </template>
              <OrdersStatsTimeline
                v-if="loadedItems[item.i].type === 'ordersStats'"
                v-bind="loadedItems[item.i].props"
                :config-enter-callback="saveItems"
              />
            </v-container>
          </Resizable>
        </grid-item>
      </grid-layout>
    </template>
    <x-btn-fab-expander
      :actions="actions"
    />
  </div>
</template>

<script>
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import OrdersStatsTimeline from "@/app/components/OrdersStatsTimeline.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskList from "@/app/tasks/components/taskList/TaskList.component";
    import {CodeType} from "@/enum/code_type";
    import {actionsStorekeeper} from "@/app/homepage/definitions/actionsStorekeeper.definition";
    import {actionsChief} from "@/app/homepage/definitions/actionsChief.definition";
    import {actionsOrganizer} from "@/app/homepage/definitions/actionsOrganizer";
    import {Dashboard} from "@/service/Dashboard";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import Resizable from "@/app/homepage/components/Resizable.component";
    import {debounce} from "lodash";
    import {has} from "@/utils/object";
    import {TaskFilterMixin} from "@/app/mixins/TaskFilterMixin";

    export default {
        name: "Dashboard",
        components: {OrdersStatsTimeline, TaskList, Resizable},
        mixins: [EventsListenerMixin, ACLMixin, TaskFilterMixin],
        data: () => ({
            loading: true,
            tab: null,
            loadedItems: [],
            items: [{}, {}, {}],
            // vuetify and grid breakpoints may differ, grid breakpoint is from width of grid while vuetify takes whole page in account
            curGridBreakpoint: null,
            layout: [],
            layouts: {},
            unsavedLayout: false,
            savingPromise: Promise.resolve()
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.onBarcodeRead
                };
            },
            actions: function () {
                return [
                    ...(this.isChief ? actionsChief : []),
                    ...(this.isStorekeeper ? actionsStorekeeper : []),
                    ...(this.isOrganizer && !this.isChief ? actionsOrganizer: [])
                ];
            },
            gridBreakpoints: function () {
                return {lg: 1264, md: 960, sm: 600, xs: 0};
            },
            isSmall: function () {
                return this.$vuetify.breakpoint.smAndDown;
            },
            gridCols: function () {
                return {lg: 4, md: 2, sm: 2, xs: 2};
            },
            numOfCols: function () {
                return this.gridCols[this.curGridBreakpoint];
            },
            visibleItems: function () {
                return this.items.filter(item => this.isSmall ? !(item.showOnMobile === false) : !(item.showOnDesktop === false));
            },
            showSkeletonLoaders: function () {
                return (this.items.length !== 0 && this.loading && Object.keys(this.items[0]).length === 0);
            },
            saveItemsDebounced: function () {
                return debounce(this.saveItems, 5000);
            }
        },
        deactivated: function () {
            this.saveItemsDebounced.flush();
        },
        destroyed: function () {
            this.saveItemsDebounced.flush();
        },
        createdOrActivated: function () {
            this.savingPromise.then(() => {
                Dashboard.loadHomepageConfig()
                    .then(config => {
                        if (JSON.stringify(this.items) !== JSON.stringify(config)) {
                            // Only replace homepage lists if they have changed
                            this.items = config;
                        }
                    })
                    .catch(() => {
                        this.items = this.getDefaultLists();
                    })
                    .finally(() => {
                        this.prepareLayout();
                        this.loading = false;
                    });
            });
        },
        methods: {
            prepareLayout: function () {
                this.loadedItems = this.items;
                this.layouts['lg'] = this.createLayout('lg');
                this.layouts['md'] = this.createLayout('md');
            },
            createLayout: function (layoutBreakpoint) {
                const newLayout = [];
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].showOnDesktop === false) {
                        continue;
                    }
                    const numOfCols = this.gridCols[layoutBreakpoint];
                    const curTaskList = this.items[i];
                    const curBreakpointLayout = curTaskList.layout?.[layoutBreakpoint];
                    const curCol = curBreakpointLayout?.col;
                    const curRow = curBreakpointLayout?.row;
                    // try to load saved layout, otherwise create new
                    const curTaskLayout = {
                        x: (curCol !== null && curCol !== undefined) ? curCol : i % numOfCols,
                        y: (curRow !== null && curRow !== undefined) ? curRow : i + numOfCols,
                        w: 1,
                        h: curTaskList.type === 'ordersStats' ? 34 : 7,
                        i: i
                    };
                    newLayout.push(curTaskLayout);
                }
                return newLayout;
            },
            breakpointChanged: function (newBreakpoint) {
                this.curGridBreakpoint = newBreakpoint;
            },
            itemsMoved: function () {
                this.saveItemsDebounced();
                this.unsavedLayout = true;
            },
            saveItems: function () {
                if (!this.unsavedLayout) {
                    return Promise.resolve();
                }
                this.loading = true;
                const colItems = [];
                // linearize for mobile view
                for (let colIdx = 0; colIdx < this.numOfCols; colIdx++) {
                    const curColItems = this.layout.filter(item => item.x === colIdx);
                    curColItems.sort((listA, listB) => listA.y - listB.y);
                    colItems.push(curColItems);
                }
                const sortedItems = [];
                const numOfRows = Math.max(...colItems.map(curColItems => curColItems.length));
                for (let rowIdx = 0; rowIdx < numOfRows; rowIdx++) {
                    colItems.forEach(curColItems => {
                        const item = curColItems[rowIdx];
                        if (item) {
                            sortedItems.push(this.loadedItems[item.i]);
                        }
                    });
                }
                this.layout.forEach(layoutItem => {
                    const item = this.loadedItems[layoutItem.i];
                    if (!has(item, 'layout')) {
                        item.layout = {};
                    }
                    // save current layout
                    item.layout[this.curGridBreakpoint] = {
                        col: layoutItem.x,
                        row: layoutItem.y
                    };
                });
                const mobileOnlyLists = this.items.filter(item => item.showOnMobile && !item.showOnDesktop);
                this.items = [...mobileOnlyLists, ...sortedItems];
                this.savingPromise = Dashboard.saveHomepageConfig(this.items)
                    .then(() => {
                        this.unsavedLayout = false;
                        this.savingPromise = Promise.resolve();
                    }).catch(() => {
                        this.snack('homepage.config.unableToSave');
                    }).finally(() => {
                        this.loading = false;
                    });
                return this.savingPromise;
            },
            onBarcodeRead: function (code) {
                if (this.isChief) {
                    BarcodeAPI.decode(code).then(response => {
                        const data = response.data;
                        if (data.type === CodeType.PRODUCT_INSTANCE) {
                            this.$router.push('/products/' + data.object_link.split('products')[1].split('/')[1]);
                        } else if (data.type === CodeType.STOCK_LOCATION) {
                            IndexedDB.save(CachePath.locations, data.object_id, data.object_info);
                            this.$router.push('/stocks/' + data.object_link.split('stocks')[1].split('/')[1]);
                        }
                    }).catch(err => {
                        this.snack(err);
                    });
                }
            }
        }
    };
</script>

<style lang="sass">
.vue-grid-item.vue-grid-placeholder
    background: var(--v-primary-base) !important

</style>
