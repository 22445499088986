import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar',{attrs:{"title":_vm.pageTitle}}),(_vm.details)?[_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_c('div',[_c(VBtn,{staticClass:"mr-2",attrs:{"fab":"","outlined":"","small":"","type":"submit","to":'/users/' + _vm.userId + '/update'}},[_c(VIcon,[_vm._v(" edit ")])],1),_vm._v(" "+_vm._s(_vm.details.first_name)+" "+_vm._s(_vm.details.last_name)+" ")],1)]),_c(VExpansionPanelContent,[_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"sm6":"","xs12":""}},[_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('users.username') + ': '))]),_vm._v(" "+_vm._s(_vm.details.username)+" ")]),(_vm.details.phone)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('users.phone') + ': '))]),_vm._v(" "+_vm._s(_vm.details.phone)+" ")]):_vm._e(),_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('users.email') + ': '))]),_vm._v(" "+_vm._s(_vm.details.email)+" ")])]),_c(VFlex,{attrs:{"sm6":"","xs12":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('users.roles') + ': '))]),_vm._l((_vm.Roles),function(role){return _c('span',{key:role},[(_vm.details['is_' + role.split('_')[1].toLowerCase()])?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VChip,_vm._g({attrs:{"outlined":""}},on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons[role])+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('users.role.' + role))+" ")])]):_vm._e()],1)})],2)],1)],1)],1)],1)],1)],1),(_vm.isChief)?_c('UserPermissionsList',{attrs:{"user-id":_vm.userId}}):_vm._e()]:[_c(VProgressLinear,{attrs:{"indeterminate":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }