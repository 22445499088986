<template>
  <div>
    <x-toolbar />
    <v-card class="d-flex flex-column">
      <div
        v-if="showFilters"
        class="ml-6 mt-4"
      >
        <Alert
          class="mr-4"
          :show-alert="!filterValid"
          :display-text="$t('base.filterConfig.invalid')"
          :type="'warning'"
        />
        <span class="body-2">
          {{ $t('base.filterConfig.filter') }}:
        </span>
        <v-fade-transition mode="out-in">
          <span
            :key="filterLabel(langPath, apiFilter, renderValues)"
            class="font-italic"
          >
            {{ filterLabel(langPath, apiFilter, renderValues) }}
          </span>
        </v-fade-transition>
        <div class="d-flex overflow-y-auto">
          <GeneralConfigFilterComponent
            :filters="APIFilters"
            :filter="apiFilter"
            :possible-values="possibleValues"
            lang-path="tasks.filter."
            class="mt-3"
            @removedLastCondition="removeFilters"
          />
        </div>
      </div>
      <v-container fluid>
        <x-data-table
          ref="dataTable"
          :headers="headers"
          :loading.sync="loading"
          :items.sync="items"
          :actions="actions"
          :api-data-source="apiDataSource"
          :api-data-source-all-pages="apiDataSourceAllPages"
          :reload="reload"
          :custom-filters="true"
          :search-hint="$t('tasks.searchHint')"
          @toggleFilters="toggleFilters"
        >
          <template #item.task="{ item }">
            <div>
              <v-icon>
                {{ taskIcons[item.type] }}
              </v-icon>
              #{{ item.id }} {{ ' ' + $t(taskNames[item.type]) }}
            </div>
          </template>
          <template #item.parent_task_id="{ item }">
            <span
              v-if="item.parent_task_id"
              @click.stop
            >
              <router-link :to="'/task/' + item.parent_task_id">
                #{{ item.parent_task_id }}
              </router-link>
            </span>
          </template>
          <template #item.state="{ item }">
            {{ item.state | taskState }}
          </template>
          <template #item.priority="{ item }">
            <div :class="'p' + item.priority">
              {{ item.priority | taskPriority }}
            </div>
          </template>
          <template #item.created_at="{ item }">
            {{ item.created_at | dateTime }}
          </template>
          <template #item.updated_at="{ item }">
            {{ item.updated_at | dateTime }}
          </template>
        </x-data-table>
      </v-container>
    </v-card>
  </div>
</template>
<script>
    import {TaskAPI} from "@/api/TaskAPI";
    import {taskIcons, taskLinks, taskNames, taskTypes} from "@/enum/task_type";
    import {APIFilterOP, APIFilters, APIFilterDataType} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {fullName} from "@/utils/filters";
    import GeneralConfigFilterComponent from "@/app/components/filterConfig/GeneralConfigFilterPart.component";
    import {TaskFilterMixin} from "@/app/mixins/TaskFilterMixin";
    import Alert from "@/app/components/Alert.component";
    import {taskFilterColumns} from "@/app/tasks/definitions/taskFilterColumns";
    import {ACLMixin} from "@/app/mixins/ACLMixin";

    export default {
        name: "TasksOverview",
        components: {GeneralConfigFilterComponent, Alert},
        mixins: [TaskFilterMixin, ACLMixin],
        data: () => ({
            items: [],
            apiFilter: {},
            showFilters: false,
            loading: false,
            reload: 0,
            taskIcons: taskIcons,
            taskNames: taskNames,
            filterLabel: APIFilters.makeHumanReadable,
            langPath: 'tasks.filter.',
            possibleValues: taskFilterColumns,
            APIFilters: APIFilters,
        }),
        createdOrActivated: function (lifeCycleHook) {
            this.loading = true;
            this.fetchPossibleValues().then(() => {
                if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                    this.createDefaultFilter();
                }
            }).finally(() => this.loading = false);
        },
        computed: {
            headers: function () {
                const headers = {
                    task: {
                        label: 'name',
                        sortBy: 'id'
                    },
                    parent_task_id: {},
                    external_id: {},
                    state: {
                        label: 'state.name'
                    },
                    priority: {
                        label: 'priority.name'
                    },
                    assigned_user: {
                        itemLabel: item => fullName(item.assigned_user),
                        sortBy: 'assigned_user.id'
                    },
                    created_at: {},
                    updated_at: {}
                };
                return createHeaders(headers, 'tasks.');
            },
            actions: function () {
                return [
                    {
                        condition: item => this.isChief ? true : [taskTypes.MOVE_PRODUCTS, taskTypes.LOCATION_TRANSFER].includes(item.type),
                        routerLink: item => taskLinks[item.type] + '/' + item.id,
                        icon: '$task',
                        label: 'tasks.viewTask'
                    }
                ];
            },
            apiDataSource: function () {
                return TaskAPI.getAll.bind(TaskAPI, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return TaskAPI.getAllPages.bind(TaskAPI, this.apiFilterParam);
            },
            apiFilterParam: function () {
                if (this.showFilters) {
                    return this.filterValid ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
                } else {
                    return {};
                }
            },
            filterValid: function () {
                return this.validateFilter(this.apiFilter);
            }
        },
        watch: {
            apiFilter: {
                handler: function () {
                    this.reload++;
                },
                deep: true
            }
        },
        methods: {
            createDefaultFilter: function () {
                const defaultColumn = this.possibleValues.state;
                const defaultValue = Array.isArray(defaultColumn.values)
                    ? defaultColumn.values[0].value
                    : (defaultColumn.type === APIFilterDataType.NUMBER ? 0 : '');
                this.apiFilter = {
                    [APIFilterOP.AND]: [{
                        [APIFilterOP.EQUALS]: {
                            state: defaultValue
                        }
                    }]
                };
            },
            toggleFilters: function (showFilters) {
                this.showFilters = showFilters;
                this.reload++;
            },
            removeFilters: function () {
                if (this.$refs.dataTable) {
                    this.$refs.dataTable.changeShowFilters(false);
                }
                this.createDefaultFilter();
            }
        }
    };
</script>

<style scoped lang="sass">
.p1
  color: var(--v-priorityTextLow-base)

.p3
  color: var(--v-priorityTextHigh-base)
</style>
