import {EventBus} from "@/service/EventBus";

/**
 * Requires:
 * - this.events: [{listener, executor}]
 */
const EventsListenerMixin = {
    data: () => ({
        _eventListenerActive: false
    }),
    deactivated: function () {
        this.eventsOff();
    },
    destroyed: function () {
        this.eventsOff();
    },
    createdOrActivated: function () {
        if (!this._eventListenerActive) {
            this._eventListenerActive = true;
            for (const [listener, executor] of Object.entries(this.events)) {
                EventBus.$on(listener, executor);
            }
        }
    },
    methods: {
        eventsOff: function () {
            if (this._eventListenerActive) {
                this._eventListenerActive = false;
                for (const [listener, executor] of Object.entries(this.events)) {
                    EventBus.$off(listener, executor);
                }
            }
        }
    }
};

export {EventsListenerMixin};
