import Vue from "vue";
import VueI18n from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    const langFiles = require.context('@/lang', true, /[A-Za-z0-9-_,\s]+\.js$/i);
    const messages = {};
    langFiles.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)(?=\.)/ig);
        let currentLocaleMessages = {};
        if (matched && matched.length > 0) {
            const matchedLength = matched.length;
            for (let i = 0; i < matchedLength; i++) {
                if (i === 0) {
                    // first part of name
                    currentLocaleMessages[matched[i]] = langFiles(key)[matched[i]] || langFiles(key).default;
                } else if (i === matchedLength - 1) {
                    // last part of name - locale, for example cs
                    messages[matched[i]] = {
                        ...messages[matched[i]],
                        ...currentLocaleMessages
                    };
                } else {
                    currentLocaleMessages = {
                        [matched[i]]: currentLocaleMessages
                    };
                }
            }
        }
    });
    return messages;
}

Vue.use(VueI18n);

// Set up pluralization rules for Czech
// source: https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
const defaultPluralization = VueI18n.prototype.getChoiceIndex;

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 **/
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (this.locale !== 'cs') {
        // proceed to the default implementation
        return defaultPluralization.apply(this, arguments);
    }

    if (choice === 0) {
        return 0;
    }

    if (choice === 1) {
        return 1;
    }

    if (choice >= 2 && choice <= 4) {
        return 2;
    }

    // if (choice >= 5) { // else default
    return choicesLength > 2 ? 3 : 0;
};

const i18n = new VueI18n({
    locale: 'cs',
    messages: loadLocaleMessages(),
});


// TODO all i18n string in .js files not updated on change
// TODO fallback string for undefined keys

export default i18n;
