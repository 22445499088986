export default {
    required: "Toto pole je vyžadované.",
    maxLen: "Maximálna povolená dĺžka je {0} znakov",
    minLen: "Minimálna požadovaná dĺžka je {0} znakov",
    maxInteger: 'Maximálna povolená hodnota je {0}',
    pattern: "Vstup nespĺňa požadovaný formát",
    integer: "Vyplňte celé číslo",
    positiveInteger: "Vyplňte celé kladné číslo",
    nonNegativeInteger: "Vyplňte celé nezáporné číslo",
    number: "Vyplňte číslo",
    nonNegativeNumber: "Vyplňte nezáporné číslo",
    range: "Musí byť medzi {0} a {1}",
    save: "Uložiť",
    send: "Odoslať",
    generate: "Generovať",
    sequenceBegin: "A sorozatnak számmal vagy betűvel kell kezdődnie",
    notAllowedChar: "Karakter nem engedélyezett",
    download: "Stiahnuť",
    notFilled: "Nevyplnené",
    invalidDate: "Nie je možné spracovať daný dátum",
    now: "Teraz",
    today: "Dnes",
    yesterday: "Včera",
    start_of_month: "Začiatok mesiaca",
    end_of_last_month: "Koniec minulého mesiaca"

};
