import {ImportModes} from "@/enum/importModes";

export function getImportMode(easyMode, latestOnDuplicity) {
    let importMode = easyMode ? 1 : 15;
    if (latestOnDuplicity) {
        return  importMode | ImportModes.LATEST_ON_DUPLICITY;
    } else {
        return importMode;
    }
}

export function isEasyMode(importMode) {
    return (importMode & ImportModes.CREATE_SUB) === 0;
}
