import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar',{attrs:{"title":_vm.pageTitle}}),_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"stocks."}}),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c(VSubheader,[_vm._v(_vm._s(_vm.$t('stocks.address')))])],1),_c('FormAddress',{attrs:{"form":_vm.form.address}}),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"text":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],1)],1)],1)],1),_c('div',{staticClass:"my-2"}),(_vm.showCreateUser)?_c('UserCreateUpdateComponent',{ref:"userForm",attrs:{"id":"userForm","is-edit":false,"creating-chief":""},on:{"user-updated":_vm.onCreateUserCreated}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }