<template>
  <v-container fluid>
    <v-row>
      <template v-if="!$vuetify.breakpoint.smAndDown">
        <v-col
          v-show="hasImage"
          cols="2"
          md="2"
        >
          <TaskItemDetailImages
            :product-id="product.id"
            :product-name="product.name"
            just-one-image
            @has-image="val => {hasImage = val}"
          />
        </v-col>
        <v-divider
          v-show="hasImage"
          vertical
        />
      </template>
      <v-col>
        <div class="d-flex flex-column flex-wrap align-center">
          <TaskItemDetailAlerts
            :item="item"
            :item-on-wrong-location="itemOnWrongLocation"
            :task-info="taskInfo"
          />
          <component
            :is="isChief ? 'router-link' : 'div'"
            :to="'/products/' + product.id"
            class="title text--primary text-decoration-none"
            :class="isChief ? 'cursor-pointer' : ''"
          >
            {{ itemQuantityOnLocation(item, locationId) }}
            <span class="caption">{{ product | productMeasureLabel }}</span>&nbsp;
            <span class="font-weight-bold">{{ product.name }}</span> - {{ product.model }}
            <span class="caption">{{ item.instance | instanceTypeSpecialLabel }}</span>
            <v-icon
              v-if="isChief"
              class="mb-1"
            >
              $openItem
            </v-icon>
          </component>
          <TaskItemDetailPrice
            v-if="isAllowedToChangePrices"
            :item="item"
            :prices="prices"
            :task-type="taskInfo.details.type"
            class="mt-3"
          />
          <TaskItemDetailQuantity
            v-if="isAllowedToChangeQuantity"
            :item="item"
            :location-id="locationId"
            :task-info="taskInfo"
            :total-picked-quantity="itemQuantityOnLocation(item, locationId)"
            :stock-loading-strict-mode="taskInfo.details.strict_mode || taskInfo.details.transfer_mode"
            :inventory-empty="inventoryEmpty"
            :source-location-id="(isType(taskTypes.STOCK_LOADING) && !locationId)
              || cardType === TaskItemsCardType.MOVED
              || activeLocationId === locationId ? null : activeLocationId"
            :task-shipping-type="taskInfo.details.shipping_type"
            class="mt-3"
          />
          <TaskItemDetailReturn
            v-if="isAllowedToReturn"
            :item="item"
            :location-id="locationId"
            :card-type="cardType"
            class="mt-3"
            :item-quantity-on-location="itemQuantityOnLocation"
            @back="(amount, locId, callback) => {$emit('back', item, amount, locId, callback)}"
          />
          <TaskItemDetailRemove
            v-if="isAllowedToRemove"
            :item="item"
            class="mt-3"
            @remove="removedItem => {$emit('remove', removedItem, locationId)}"
          />
          <TaskItemDetailLocations
            v-if="showPickedFrom"
            :item="item"
            type="picked"
            class="mt-3"
            :stock-id="stockId"
          />
          <TaskItemDetailLocations
            v-if="showSourceLocations && (item.locations || canLoadLocations)"
            :item="item"
            type="pick"
            class="mt-3"
            :stock-id="stockId"
          />
          <TaskItemDetailLocations
            v-if="showAlreadyPlacedAt"
            :item="item"
            type="placed"
            class="mt-3"
            :stock-id="stockId"
          />
          <TaskItemDetailLocations
            v-if="showAllowedLocations"
            :item="item"
            type="put"
            :card-type="cardType"
            class="mt-3"
            :stock-id="stockId"
          />
          <div class="d-flex flex-column align-center mt-3">
            <v-btn
              text
              color="secondary"
              @click="showDetails = !showDetails"
            >
              <v-icon :class="{'upsideDown': showDetails}">
                $showMore
              </v-icon>
              {{ $t('tasks.itemsCard.moreDetails') }}
            </v-btn>
            <v-expand-transition>
              <div
                v-show="showDetails"
                class="mt-3"
              >
                <div class="d-flex flex-column align-center">
                  <div>
                    <span class="text-caption">{{ $t('products.model') + ': ' }}</span>
                    {{ product.model }}
                  </div>
                  <div>
                    <span class="text-caption">{{ $t('products.manufacturer.label') + ': ' }}</span>
                    {{ product.manufacturer.name }}
                  </div>
                </div>
                <TaskItemDetailBarcodes
                  :product="product"
                  :instance="item.instance"
                />
                <TaskItemDetailImages
                  v-if="showDetails /* to achieve lazy load */"
                  :product-id="product.id"
                  :product-name="product.name"
                />
              </div>
            </v-expand-transition>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {TaskItemsCardType} from '@/enum/task_items_card_type';
    import {taskTypes} from "@/enum/task_type";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import TaskItemDetailQuantity from "@/app/tasks/components/taskItemsCard/TaskItemDetailQuantity.component";
    import TaskItemDetailPrice from "@/app/tasks/components/taskItemsCard/TaskItemDetailPrice.component";
    import TaskItemDetailLocations from "@/app/tasks/components/taskItemsCard/TaskItemDetailLocations.component";
    import TaskItemDetailImages from "@/app/tasks/components/taskItemsCard/TaskItemDetailImages.component";
    import TaskItemDetailRemove from "@/app/tasks/components/taskItemsCard/TaskItemDetailRemove.component";
    import TaskItemDetailReturn from "@/app/tasks/components/taskItemsCard/TaskItemDetailReturn.component";
    import TaskItemDetailBarcodes from "@/app/tasks/components/taskItemsCard/TaskItemDetailBarcodes.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import TaskItemDetailAlerts from "@/app/tasks/components/taskItemsCard/TaskItemDetailAlerts.component.vue";

    export default {
        name: "TaskItemDetails",
        components: {
            TaskItemDetailAlerts,
            TaskItemDetailReturn, TaskItemDetailRemove, TaskItemDetailImages, TaskItemDetailLocations,
            TaskItemDetailPrice, TaskItemDetailQuantity, TaskItemDetailBarcodes
        },
        mixins: [TaskStateMixin, TaskAssignMixin, TaskTypeMixin, ACLMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            cardType: {
                type: Number,
                default: 0
            },
            locationId: {
                type: Number,
                default: null
            },
            prices: {
                type: Object,
                default: () => ({})
            },
            itemQuantityOnLocation: {
                type: Function,
                default: () => () => {
                }
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            canLoadLocations: {
                type: Boolean,
                default: false
            },
            itemOnWrongLocation: {
                type: Boolean,
                default: false
            },
            activeLocationId: {
                type: Number,
                default: null
            },
            activeLocationSide: {
                type: String,
                default: null
            }
        },
        data: () => ({
            TaskItemsCardType: TaskItemsCardType,
            showDetails: false,
            hasImage: false,
            taskTypes: taskTypes
        }),
        computed: {
            product: function () {
                return this.item.instance.product;
            },
            stockId: function () {
                return (this.taskInfo.details
                    && ((this.taskInfo.details.stock && this.taskInfo.details.stock.id)
                        || (this.taskInfo.details.subordinate_stock && this.taskInfo.details.subordinate_stock.stock_id)
                        || (this.taskInfo.details.source_subordinate_stock && this.taskInfo.details.source_subordinate_stock.stock_id))
                );
            },
            showSourceLocations: function () {
                return (this.cardType === TaskItemsCardType.TO_MOVE || this.cardType === TaskItemsCardType.ASSIGNMENT)
                    && !this.isType(taskTypes.STOCK_TAKING);
            },
            showAllowedLocations: function () {
                if (this.isType(taskTypes.MOVE_PRODUCTS)) {
                    return [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)
                        && (this.cardType === TaskItemsCardType.ASSIGNMENT
                            || this.cardType === TaskItemsCardType.IN_INVENTORY);
                }
                if (this.isType(taskTypes.STOCK_LOADING)) {
                    return this.cardType === TaskItemsCardType.ASSIGNMENT
                        || this.cardType === TaskItemsCardType.TO_MOVE
                        || this.cardType === TaskItemsCardType.IN_INVENTORY
                        || (this.cardType === TaskItemsCardType.MOVED && this.itemOnWrongLocation);
                }
                if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])) {
                    return this.cardType === TaskItemsCardType.MOVED && this.itemOnWrongLocation;
                }
                return this.isType(taskTypes.SUBSTOCK_TRANSFER)
                    && (this.cardType === TaskItemsCardType.IN_INVENTORY || this.cardType === TaskItemsCardType.ASSIGNMENT);
            },
            showPickedFrom: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])
                    && (this.cardType === TaskItemsCardType.IN_INVENTORY || this.cardType === TaskItemsCardType.MOVED);
            },
            showAlreadyPlacedAt: function () {
                return this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER]);
            },
            isAllowedToChangePrices: function () {
                return (this.isWaitingForApproval || (this.isStorekeeper && this.isChief))
                    && (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET]))
                    && this.prices[this.item.id] !== undefined
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT;
            },
            isAllowedToChangeQuantity: function () {
                let isLocationSource = true;
                if (this.activeLocationSide === 'pick') {
                    isLocationSource = true;
                } else if (this.activeLocationSide === 'put') {
                    isLocationSource = false;
                } else {
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                        const destinationLocation = this.taskInfo.details.destination_location || this.taskInfo.details.target_location;
                        const sourceLocation = this.taskInfo.details.source_location;
                        if (destinationLocation && destinationLocation.id === this.activeLocationId) {
                            isLocationSource = false;
                        } else if (sourceLocation && sourceLocation.id !== this.activeLocationId) {
                            isLocationSource = false;
                        } else if (!sourceLocation) {
                            const sourceLocIds = this.item.locations.map(loc => loc.stock_location.id);
                            sourceLocIds.push(...this.item.source_locations.map(loc => loc.stock_location_id));
                            if (!sourceLocIds.includes(this.activeLocationId)) {
                                isLocationSource = false;
                            }
                        }
                    }
                }
                return this.isInProgress
                    && this.assignedToCurrentUser
                    && (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING])
                        || (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])
                            && this.activeLocationId
                            && ((this.cardType === TaskItemsCardType.IN_INVENTORY && isLocationSource)
                                || (this.cardType === TaskItemsCardType.MOVED && !isLocationSource))))
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT
                    && this.cardType !== TaskItemsCardType.TO_MOVE;
            },
            isAllowedToReturn: function () {
                // may be possible in STOCK_LOADING, STOCK_PICKING, STOCK_PICKING_SET, MOVE_PRODUCTS and SUBSTOCK_TRANSFER
                if (this.cardType === TaskItemsCardType.ASSIGNMENT) {
                    return false;
                }
                if (this.isType(taskTypes.STOCK_TAKING)) {
                    return false;
                } else if (this.isType(taskTypes.SUBSTOCK_TRANSFER)) {
                    if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                        if (!this.item.quantity_in_source_user_inventory > 0) {
                            return false; // TODO display message why (picked from destination)
                        }
                    }
                } else if (this.isType(taskTypes.STOCK_LOADING)) {
                    if (this.cardType === TaskItemsCardType.IN_INVENTORY) {
                        return false;
                    }
                }
                return this.isInProgress && this.assignedToCurrentUser && this.cardType !== TaskItemsCardType.TO_MOVE;
            },
            isAllowedToRemove: function () {
                return this.isInProgress
                    && this.assignedToCurrentUser
                    && this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING])
                    && this.cardType !== TaskItemsCardType.ASSIGNMENT
                    && this.cardType !== TaskItemsCardType.TO_MOVE;
            }
        }
    };
</script>

<style scoped>

</style>
