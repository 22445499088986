export default {
    link: "Dopravcovia",
    title: "Dopravcovia",
    name: "Názov",
    type: "Typ",
    active: "Aktívny",
    notActive: "Neaktívny",
    services: {
        title: "Služby",
        noServices: "Žiadne dostupné služby",
        showAll: "Zobraziť všetky",
        hideAll: "Skryť všetky",

        name: "Služba",
        carrier_service_parameters: "Parametre zásielky",
        required_carrier_service_parameters: "Povinné parametre zásielok",
        optional_carrier_service_parameters: "Voliteľné parametre zásielok",
        type: "Formát tlače štítku",
        create: {
            title: "Vytvoriť službu dopravcu",
            label: "Vytvoriť novú službu dopravcu",
            done: "Služba dopravcu '{0}' vytvorená."
        },
        update: {
            titleSimple: "Upraviť službu dopravcu",
            title: "Upraviť {0}",
            done: "Služba dopravcu '{0}' upravená"
        },
        delete: {
            done: "Služba dopravcu '{0}' zmazaná.",
            failed: "Službu dopravcu '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
        },
    },
    tableImage: "",
    tableFoilImage: "Fólie",
    doesNotSupportMultiPackageShipments: "Tento dopravca nepodporuje viackusové zásielky",
    searchHint: "Prehľadáva názov a typ",

    show: {
        titleSimple: "Detail dopravcu",
        configInSubstock: "Konfiguráciu napojenia na dopravcu nájdete v detaile každého podskladu.",
    },

    create: {
        title: "Vytvoriť dopravcu",
        done: "Dopravca '{0}' vytvorený."
    },
    update: {
        titleSimple: "Upraviť dopravcu",
        title: "Upraviť {0}",
        done: "Dopravca '{0}' upravený"
    },
    delete: {
        done: "Dopravca '{0}' zmazaný.",
        failed: "Dopravcu '{0}' nie je možné zmazať. Skúste to prosím opäť neskôr.",
    },
    status: {
        404: "Zadaný dopravca neexistuje!",
        409: "Vyplnený názov už existuje!"
    },

    images: {
        nameFoil: "Obrázok fólie",
        alt: "Obrázok priradený dopravcovi",
        altCreate: "Obrázok k priradeniu dopravcovi",
        unableToLoad: "Obrázok dopravcu nebolo možné načítať. Skúste to prosím opäť neskôr.",
        create: {
            foil: {
                titleSimple: "Pridať obrázok fólie",
                title: "Pridať obrázok fólie k '{0}'",
                done: "Obrázok fólie pridaný k '{0}'"
            }
        },
        update: {
            foil: {
                titleSimple: "Upraviť obrázok fólie",
                title: "Upraviť obrázok fólie '{0}'",
                done: "Obrázok fólie '{0}' upravený"
            }
        },
        status: {
            404: "Dopravca alebo obrázok nenájdený!"
        }
    },

    contracted: {
        label: "Je zazmluvnený",
        parameters: {
            required: {
                name: "Potrebné parametre"
            },
            optional: {
                name: "Ďalšie voliteľné parametre"
            },
            key: "Názov",
            value: "Hodnota",
            writeOnly: "Nie je možné načítať z dôvodu bezpečnosti"
        },

        create: {
            label: "Pridať do zoznamu zazmluvnených dopravcov vlastníka",
            titleSimple: "Nastaviť parametre zazmluvneného dopravcu",
            title: "Nastaviť parametre '{0}'",
            info: "Nastavujete parametre '{0}' pre '{1}'",
            done: "'{0}' pridaný do zoznamu zazmluvnených dopravcov vlastníka"
        },
        update: {
            label: "Upraviť parametre zazmluvnených dopravcov vlastníka",
            titleSimple: "Upraviť parametre zazmluvneného dopravcu",
            title: "Upraviť parametre '{0}'",
            info: "Upravujete parametre '{0}' pre '{1}'",
            done: "Parametre '{0}' upravené",
            substock: {
                title: "Zazmluvniť pre tento podsklad",
                done: "'{0}' je teraz zazmluvnený pre tento podsklad"
            }
        },
        delete: {
            titleSimple: "Odobrať zo zoznamu zazmluvnených dopravcov vlastníka",
            done: "'{0}' odobraný zo zoznamu zazmluvnených dopravcov vlastníka",
            substock: {
                title: "Zrušiť zazmluvnenie pre tento podsklad",
                done: "'{0}' už nie je zazmluvnený pre tento podsklad"
            }
        }
    },

    run: {
        job: "Spustiť prácu na pozadí",
        validatePackages: "Validácia zásielok",
        generateProtocols: "Odovzdávacie protokoly",
        trackPackages: "Sledovanie zásielok"
    }
};
