<template>
  <v-list-item
    to="/cache"
  >
    <v-icon
      class="mr-3"
    >
      $manageCache
    </v-icon>
    <span>
      {{ $t('base.cache.title') }}
    </span>
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarCacheManager"
    };
</script>

<style scoped>

</style>
