import {FilterDataTypeIcon, FilterHTMLType} from "@/enum/filters";
import {APIFilterDataType, APIFilterOP} from "@/service/APIFilters";
import {FilterType} from "@jagu/rest-api-filters-client/src/enum/filterType";
import {operatorType} from "@jagu/rest-api-filters-client/src/abstractFilters";

const FilterMixin = {
    model: {
        prop: 'filter',
        event: 'setFilter'
    },
    props: {
        filters: {
            type: Object,
            required: true
        },
        filter: {
            type: Object,
            default: null
        },
        possibleValues: {
            type: Object,
            default: () => ({})
        },
        langPath: {
            type: String,
            default: ''
        },
        hardcodedAttribute: {
            type: String,
            default: null
        },
        allowedOperators: {
            type: Array,
            default: null
        },
        totalFilterCount: {
            type: Number,
            default: 0
        },
        depth: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isFilterPropEmpty: function () {
            return this.isFilterEmpty(this.filter);
        },
        operator: function () {
            return !this.isFilterPropEmpty ? Object.keys(this.filter)[0] : undefined;
        },
        items: function () {
            return Object.keys(this.possibleValues).filter(key => {
                return this.filters.isFilterOPAllowedForDataType(this.operator, this.possibleValues[key].type);
            }).map(key => ({
                text: this.hardcodedAttribute ? '' : this.$t(this.langPath + key + '.name'),
                value: key,
                icon: this.getIcon(this.possibleValues[key].type)
            }));
        },
        newOperator: function () {
            if (this.filters.type === FilterType.API) {
                return this.getNewOperator(APIFilterOP.EQUALS, [APIFilterOP.AND, APIFilterOP.OR]);
            } else {
                console.warn('Computed property `newOperator` in FilterMixin is not set for ' + this.filters.type + ' implementation');
                return undefined;
            }
        },
        htmlType: function () {
            const type = this.possibleValues[this.attr].type;
            if (Object.keys(FilterHTMLType).includes(type)) {
                return FilterHTMLType[type];
            }
            console.warn('Computed property `htmlType` for "' + type + '" type in FilterMixin is not set for ' + this.filters.type + ' implementation');
            return undefined;
        },
        filterCount: function () {
            return this.getFilterCount(this.filter);
        }
    },
    methods: {
        getDefaultDataTypeValue: function (attr) {
            const value = this.possibleValues[attr];
            if (Array.isArray(value.values)) {
                return value.values.find(el => el.value).value;
            } else {
                switch (value.type) {
                    case APIFilterDataType.NUMBER:
                        return 0;
                    case APIFilterDataType.DATE:
                        return this.$moment().startOf('day').format();
                    case APIFilterDataType.BOOLEAN:
                        return true;
                    case APIFilterDataType.TEXT:
                        return '';
                    default:
                        console.warn('Method `getDefaultDataTypeValue` in FilterMixin does not have case for "' + value.type + '" type from ' + this.filters.type + ' implementation');
                        return undefined;
                }
            }
        },
        getIcon: function (dataType) {
            if (Object.keys(FilterDataTypeIcon).includes(dataType)) {
                return FilterDataTypeIcon[dataType];
            }
            console.warn('Method `getIcon` in FilterMixin does not have value for "' + dataType + '" type from ' + this.filters.type + ' implementation');
            return undefined;
        },
        getNewOperator: function (defaultOperator, groupOperators) {
            if (this.allowedOperators === null) {
                return defaultOperator;
            } else {
                return this.allowedOperators.find(filter => groupOperators.indexOf(filter) === -1);
            }
        },
        getFilterCount: function (filter) {
            let count = 0;
            if (filter) {
                const operator = Object.keys(filter)[0];
                if (this.filters.getType(operator).type === operatorType.groupOperators) {
                    count += filter[operator].reduce((acc, filter) => acc + this.getFilterCount(filter), 0);
                }
                count += 1;
            }
            return count;
        },
        isFilterEmpty: function (filter) {
            return !filter || (typeof filter === 'object' && Object.keys(filter).length === 0);
        },
        isValueEmpty: function (value) {
            return value === null || value === undefined || value.length === 0;
        },
    }
};

export {FilterMixin};
