<template>
  <v-breadcrumbs
    v-if="path.length > 1"
    :items="path"
    class="breadcrumbs"
  >
    <template #divider>
      <v-icon>
        $navigationDivider
      </v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
    import {buildPath} from "@/utils/url";

    export default {
        name: "Breadcrumbs",
        computed: {
            path: function () {
                const path = [];
                let route = this.$route;
                for (; ;) {
                    if (route === '/') {
                        path.unshift({
                            text: this.$t('base.home'),
                            to: '/'
                        });
                        break;
                    }
                    path.unshift({
                        text: this.$t(route.meta.title),
                        to: buildPath(route.fullPath || route.path, this.$route.params),
                        exact: true
                    });
                    if (typeof route.meta.getParent !== 'function') {
                        break;
                    }
                    route = route.meta.getParent();
                }
                return path;
            }
        }
    };
</script>

<style scoped lang="sass">
    .breadcrumbs
        padding-top: 4px
</style>
