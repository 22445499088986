export default {
    tasks: "Завдання",
    noTasks: "Немає завдань!",
    running: "Мої незавершені завдання",
    storekeepersRunning: "Незавершені завдання комірників",
    assigned: "Нові завдання, які призначені мені",
    free: "Вільні завдання",
    allTasks: "Всі вільні завдання",
    allDone: "Немає завдань для відображення!",
    overview: "Огляди",
    toApprove: "На затвердження",
    toPack: "На упаковку",
    status: "Статус",
    manage: "Управління",
    done: "Завершені",
    noDone: "Немає завершених завдань!",
    config: {
        title: "Налаштування головної сторінки",
        config: "Налаштування списків",
        label: "Назва",
        addTaskList: "Додати список завдань",
        addOrdersStats: "Додати огляд замовлень",
        newList: "Новий список",
        all: "всі",
        export: {
            title: "Експортувати",
            done: "Домашню сторінку експортовано."
        },
        import: {
            title: "Імпортувати",
            done: "Домашню сторінку імпортовано.",
            fail: "Не вдалося імпортувати домашню сторінку!"
        },
        reset: "Відновити списки за замовчуванням",
        resetDone: "Списки за замовчуванням відновлені",
        showOn: "Показувати на",
        desktop: "комп'ютер",
        mobile: "мобільний",
        showOnDesktop: "Показувати на комп'ютері",
        showOnMobile: "Показувати на мобільному",
        autoRefresh: "Автоматичне оновлення",
        autoRefreshValue: "Оновлювати кожні {0} секунд",
        unableToLoad: "Не вдалося завантажити конфігурацію домашньої сторінки. Будь ласка, спробуйте ще раз пізніше.",
        unableToSave: "Не вдалося зберегти налаштування!",
        arrayDateConflict: "Вибір декількох дат одночасно не підтримується. Використовуйте діапазон від-до.",
        onlySubstocks: "Тільки підпорядковані склади"
    },
    stats: {
        orders: {
            label: 'Стан замовлень',
            unprocessed: 'нові',
            toBePicked: 'очікують на відвантаження',
            beingPicked: 'у процесі відвантаження',
            toBePacked: 'очікують на пакування',
            beingPacked: 'у процесі пакування',
            packed: 'упаковано',
            inShipping: 'у доставці',
            returning: 'повертається',
            delivered: 'доставлено'
        },
        orderCountDisclaimer: 'При використанні часткового задоволення, одне замовлення може бути одночасно в декількох статусах!'
    }
};
