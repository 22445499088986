export const reportsNames = {
    SHIPPING_REPORT: "shippingReport",
    STOCK_LOCATION: "stockLocation",
    STOCK_BUY_PRICES: "buyPrices"
};

export const domainsNames = {
    SHIPPING: "shipping",
    STOCK_STATUS: "stock"
};

export const domainIcons = {
    [domainsNames.STOCK_STATUS]: '$stockStatus',
    [domainsNames.SHIPPING]: '$shipping'
};
