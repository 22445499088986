<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col
          cols="10"
          class="mt-2 mb-5"
        >
          <RenameObjectForm />
        </v-col>
        <v-col
          style="text-align: right"
          class="mt-2"
        >
          <v-btn
            icon
          >
            <v-icon
              @click="unselectObject()"
            >
              $close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      v-if="selectedObject.type !== MapObjectType.LOCATIONS_GROUP"
      class="d-flex flex-wrap justify-center"
    >
      <v-form
        ref="objectColumnAmountFormValid"
        @submit.prevent
      >
        <FormFields
          :form="objectColumnAmountForm"
          :render="objectColumnAmountFormRender"
          lang-path="map."
        />
      </v-form>
      <v-form
        ref="objectRowsAmountFormValid"
        @submit.prevent
      >
        <FormFields
          v-if="selectedObject.type !== MapObjectType.RACK"
          :form="objectRowsAmountForm"
          :render="objectRowsAmountFormRender"
          lang-path="map."
        />
      </v-form>
    </v-card-text>
    <v-card-text
      v-if="selectedObject.type === MapObjectType.RACK"
      class="d-flex flex-wrap justify-center"
    >
      <v-btn
        outlined
        class="mb-1 ml-4"
        @click="startRackEdit"
      >
        <v-icon
          left
          small
        >
          $rackDetail
        </v-icon>
        {{ $t('map.bookmarks.rack') }}
      </v-btn>
    </v-card-text>
    <v-card-text
      v-if="selectedObject.type === MapObjectType.LOCATIONS_GROUP"   
    >
      <EditLocationsForm
        :locations="getlocationsInLocationsGroupInput"
        @addLocation="addNewLocationToLocationGroup"
        @removeLocation="removeLocationFromLocationGroup"
      />
    </v-card-text>
    <v-card-title
      v-if="selectedObject.type === MapObjectType.ZONE"
      class="ml-8"
    >
      {{ $t('map.oneZoneTypes') }}
    </v-card-title>
    <v-card-text
      v-if="selectedObject.type === MapObjectType.ZONE"
    >
      <v-checkbox
        v-for="[zoneType, label] of Object.entries(ZoneObjectType)"
        :key="zoneType"
        v-model="zoneTypeInput"
        :label="$t(label)"
        :value="zoneType"
        class="ml-16"
      />
    </v-card-text>
  </v-card>
</template>

<script>
    import {MapObjectType, PanelMode} from '@/enum/stock_map';
    import { getRackColumns } from '@/service/Map.js';
    import {MapMixin} from "@/app/mixins/MapMixin";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {
        ObjectColumnAmountForm, ObjectColumnAmountRender,
        ObjectRowsAmountForm, ObjectRowsAmountRender
    } from "@/app/stocks/map/definition/objectDetails.form";
    import EditLocationsForm from "@/app/stocks/map/components/forms/EditLocationsForm.component.vue";
    import RenameObjectForm from "@/app/stocks/map/components/forms/RenameObjectForm.component.vue";
    export default {
        name: 'ObjectDetailPanel',
        components: {RenameObjectForm, EditLocationsForm, FormFields},
        mixins: [ MapMixin ],
        data: () => ({
            objectColumnAmountForm: new ObjectColumnAmountForm(),
            objectRowsAmountForm: new ObjectRowsAmountForm(),
        }),
        computed: {
            objectColumnAmountFormRender: function () {
                return new ObjectColumnAmountRender(this.ruleObjectColumns);
            },
            objectRowsAmountFormRender: function () {
                return new ObjectRowsAmountRender(this.ruleObjectRows);
            },
            getlocationsInLocationsGroupInput: function () {
                return this.selectedObject.locations;
            },
            zoneTypeInput: {
                get() {
                    if (this.selectedObject.type === MapObjectType.ZONE) {
                        return this.selectedObject.zoneTypes;
                    }
                    else
                        return [];
                },
                set(val) {
                    if (this.selectedObject.type === MapObjectType.ZONE) {
                        this.$store.commit('storageMap/setZoneTypes', {
                            types: val
                        });
                    }
                }
            },

        },
        watch: {
            selectedObject: function () {
                this.initializeFormValues();
            },
            objectColumnAmountForm: {
                handler: function (){
                    if (this.selectedObject !== null && this.$refs.objectColumnAmountFormValid?.validate()) {
                        if (this.selectedObject.type === MapObjectType.RACK && getRackColumns(this.selectedObject) !== this.objectColumnAmountForm.objectWidth) {
                            this.$store.dispatch('storageMap/setRackColumns', {
                                columns: Number.parseInt(this.objectColumnAmountForm.objectWidth, 10)
                            });
                        }
                        else if (this.selectedObject.type !== MapObjectType.RACK && this.selectedObject.width !== this.objectColumnAmountForm.objectWidth) {
                            this.$store.dispatch('storageMap/setNonRackObjectColumns', {
                                columns: Number.parseInt(this.objectColumnAmountForm.objectWidth, 10)
                            });
                        }
                        
                    }
                },
                deep: true,
            },
            objectRowsAmountForm: {
                handler: function (){
                    if (this.selectedObject !== null && this.$refs.objectRowsAmountFormValid?.validate()) {
                        if (this.selectedObject.type !== MapObjectType.RACK && this.selectedObject.height !== this.objectRowsAmountForm.objectHeight) {
                            this.$store.dispatch('storageMap/setNonRackObjectRows', {
                                rows: Number.parseInt(this.objectRowsAmountForm.objectHeight, 10)
                            });
                        }
                    }
                },
                deep: true,
            },
        },
        createdOrActivated() {
            this.initializeFormValues();
        },
        methods: {
            unselectObject: function() {
                this.$store.commit('storageMap/selectObject', {
                    object: null
                });
            },
            initializeFormValues: function() {
                if (this.selectedObject.type === MapObjectType.RACK) {
                    this.objectColumnAmountForm.objectWidth = getRackColumns(this.selectedObject);
                }
                else {
                    this.objectColumnAmountForm.objectWidth = this.selectedObject.width;
                    this.objectRowsAmountForm.objectHeight = this.selectedObject.height;
                }
            },
            addNewLocationToLocationGroup: function (data) {
                this.$store.dispatch('storageMap/addNewLocationToLocationGroup', {
                    name: data.name,
                    stockLocationId: data.stockLocationId
                });
            },
            removeLocationFromLocationGroup: function (data) {
                this.$store.dispatch('storageMap/removeLocationFromLocationGroup', {
                    name: data.location.name,
                    stockLocationId: data.location.stockLocationId
                });
            },
            startRackEdit: function () {
                this.$store.commit('storageMap/setPanelMode', {
                    mode: PanelMode.EDIT_RACK
                });
            }
        }
    };
</script>

<style scoped>

</style>
