<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('base.attributes.templates.create') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="base.attributes.templates.form."
          >
            <template #data_type-item="{ item }">
              <AttributeTemplateDataTypeItem
                :item="item"
              />
            </template>
          </FormFields>
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import FormFields from "@/app/components/form/FormFields.component";
    import AttributeTemplateDataTypeItem from "@/app/attributes/templates/components/AttributeTemplateDataTypeItem.component.vue";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {AttributeTemplateType} from "@/enum/attribute_template_type";
    import {
        AttributeTemplateForm,
        AttributeTemplateRender
    } from "@/app/attributes/templates/definitions/attributeTemplate.form";
    import {AttributesAPI} from "@/api/AttributesAPI";
    import {APIFilterOP, APIFilters} from "@jagu/rest-api-filters-client/apiFilters";
    import {TaskAPI} from "@/api/TaskAPI";

    export default {
        name: "AttributeTemplateCreateUpdate",
        components: {FormFields, AttributeTemplateDataTypeItem},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new AttributeTemplateForm(),
            formRender: new AttributeTemplateRender(),
            valid: true,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.value !== '') {
                    title = this.$t('base.attributes.templates.update.title', [this.form.value]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            if (this.isEdit) {
                this.loadUsages();
            }
        },
        methods: {
            formFetchItem: function () {
                return AttributesAPI.get(this.attributeTemplateId);
            },
            loadUsages: function () {
                const filter = {
                    [APIFilterOP.EQUALS]: {
                        'template.id': this.attributeTemplateId
                    }
                };
                TaskAPI.getAllAttributes({ itemsPerPage: 1, filter: APIFilters.makeFilter(filter)} )
                    .then(response => {
                        if (response.data.item_count !== 0) {
                            this.$set(this.formRender.data_type, 'readonly', true);
                        } else {
                            // reset form
                            this.$set(this.formRender.data_type, 'readonly', false);
                        }
                    }).catch(() => this.$set(this.formRender.data_type, 'readonly', true));
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    AttributesAPI.update(this.attributeTemplateId, this.form)
                        .then(() => {
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/attribute-templates');
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    const data = this.form;
                    data.template_type = AttributeTemplateType.TASK;
                    AttributesAPI.create(data)
                        .then(() => {
                            if (!this.isComponent) {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/attribute-templates');
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
        }
    };
</script>

<style scoped>

</style>
