<template>
  <v-card
    outlined
    elevation="4"
  >
    <v-container fluid>
      <div class="text-overline mb-3">
        {{ $t('stocks.stockStatus.buyPrices.title.' + type) }}
      </div>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <FormFields
          :form="form"
          :render="formRender"
          lang-path="stocks.stockStatus.buyPrices."
        />
        <v-btn
          :text="!valid"
          :loading="loading"
          type="submit"
          color="accent"
          @click="submit"
        >
          <v-icon
            class="mr-2"
          >
            $export
          </v-icon>
          {{ $t('form.download') }}
        </v-btn>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import * as Export from "@/service/Export";
    import {StockBuyPricesForm, StockBuyPricesRender} from "@/app/overview/reports/definitions/stockBuyPrices.form";
    import {substocksInStock} from "@/app/stocks/substocks/definitions/substockDataSources";
    import {emptySource} from "@/app/definitions/emptyDataSources";

    export default {
        name: "StockBuyPricesComponent",
        components: {FormFields},
        props: {
            type: {
                type: String,
                default: 'IN' // or 'CURR', 'OUT'
            }
        },
        data: () => ({
            form: {},
            formRender: {},
            valid: false,
            loading: false
        }),
        watch: {
            'form.stockId': function () {
                if (this.form.stockId) {
                    this.$set(this.formRender.subStockId, 'xAutocomplete', substocksInStock(this.form.stockId));
                } else {
                    this.$set(this.formRender.subStockId, 'xAutocomplete', emptySource());
                }
            }
        },
        created: function () {
            this.form = {
                'IN': new StockBuyPricesForm,
                'CURR': new StockBuyPricesForm(true),
                'OUT': new StockBuyPricesForm
            }[this.type];
            this.formRender = {
                'IN': new StockBuyPricesRender,
                'CURR': new StockBuyPricesRender(true),
                'OUT': new StockBuyPricesRender
            }[this.type];
        },
        methods: {
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const data = {
                    substock_id: this.form.subStockId,
                    ...this.form
                };
                const promise = {
                    'IN': StockStatusAPI.exportBuyPricesIn(data),
                    'CURR': StockStatusAPI.exportBuyPrices(data),
                    'OUT': StockStatusAPI.exportBuyPricesOut(data)
                }[this.type];
                promise
                    .then(response => {
                        Export.url('data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(response.data),
                                   'buy_prices_' + this.type + '_export_' + this.form.from_date + '-' + this.form.to_date);
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            }
        }
    };
</script>

<style scoped>

</style>
