import {StockId, TaskDescription, TaskPriority} from "@/app/tasks/definitions/taskCommon.render.form";
import {TaskStockTakingRestriction} from "@/enum/task_stock_taking_restriction";
import i18n from "@/service/lang/i18n";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";

const restrictTypeItems = () => {
    return Object.values(TaskStockTakingRestriction).map(restrictionType => ({
        text: i18n.t('tasks.stockTaking.restrictions.' + restrictionType),
        value: restrictionType
    }));
};

function StockTakingCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        restrict_type: TaskStockTakingRestriction.STOCK,
        restrict_references: null,
        product: null,
        stock_id: null,
        attachments: []
    };
}

function StockTakingCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        restrict_type: {
            icon: '$restrictType',
            select: restrictTypeItems()
        },
        product: {
            icon: '$product',
            autocomplete: {
                items: []
            },
            show: false,
            loading: false
        },
        restrict_references: {
            icon: '$manufacturer',
            autocomplete: {
                items: [],
                multiple: true
            },
            show: false,
            loading: false
        },
        description: new TaskDescription
    };
}

export {StockTakingCreateForm, StockTakingCreateRender};
