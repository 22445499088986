import {filterIcons} from "@/enum/icons";
import {APIFilterDataType} from "@/service/APIFilters";
import {operatorType} from "@jagu/rest-api-filters-client/src/abstractFilters";

export const FilterDataTypeIcon = {
    [APIFilterDataType.TEXT]: filterIcons.text,
    [APIFilterDataType.BOOLEAN]: filterIcons.bool,
    [APIFilterDataType.DATE]: filterIcons.datetime,
    [APIFilterDataType.NUMBER]: filterIcons.number,
    [APIFilterDataType.ARRAY_TEXT]: filterIcons.text,
    [APIFilterDataType.ARRAY_NUMBER]: filterIcons.number
};

const HTMLTypes = {
    TEXT: 'text',
    NUMBER: 'number'
};

export const FilterHTMLType = {
    [APIFilterDataType.TEXT]: HTMLTypes.TEXT,
    [APIFilterDataType.NUMBER]: HTMLTypes.NUMBER,
    [APIFilterDataType.ARRAY_TEXT]: HTMLTypes.TEXT,
    [APIFilterDataType.ARRAY_NUMBER]: HTMLTypes.NUMBER
};

export const FilterConfigComponent = {
    [operatorType.groupOperators]: 'FilterConfigGroupOp',
    [operatorType.fulltextOperators]: undefined, // Not supported
    [operatorType.unaryOperators]: 'FilterConfigUnaryOp',
    [operatorType.binaryOperators]: 'FilterConfigBinaryOp',
    [operatorType.ternaryOperators]: 'FilterConfigTernaryOp',
    [operatorType.arrayOperators]: 'FilterConfigArrayOp'
};
