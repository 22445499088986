<template>
  <div v-if="order.carrier_service_parameters && order.carrier_service_parameters.length > 0">
    <span class="text-caption">{{ $t('carriers.services.carrier_service_parameters') }}:</span>
    <ul>
      <li
        v-for="{name, value} of order.carrier_service_parameters"
        :key="name"
      >
        <span class="text-caption">{{ name }}</span>:
        <template v-if="value.startsWith('http')">
          <a
            :href="value"
            target="_blank"
          >{{ value }}</a>
        </template>
        <template v-else>
          {{ value }}
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
        name: "ExternalOrderParametersList",
        props: {
            order: {
                type: Object,
                default: () => ({
                    carrier_service_parameters: null
                })
            }
        }
    };
</script>

<style scoped>

</style>
