<template>
  <span>
    <span class="text-caption">{{ $t('tasks.substockTransfer.' + direction) + ': ' }}</span>
    {{ (stockDetails !== null ? (stockDetails.name + ' / ') : '') + substock.name }}
  </span>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "StockSubstock",
        props: {
            substock: {
                type: Object,
                default: null
            },
            direction: {
                type: String,
                default: 'from' // 'to'
            }
        },
        data: () => ({
            stockDetails: null
        }),
        createdOrActivated: function () {
            if (this.substock && this.stockDetails === null && !this.substock.stock) {
                if (this.substock.stock) {
                    this.stockDetails = this.substock.stock;
                } else {
                    this.fetchStockDetails();
                }
            }
        },
        methods: {
            fetchStockDetails: function () {
                StockAPI.get(this.substock.stock_id)
                    .then(response => {
                        this.stockDetails = response.data;
                    }).catch(this.snack);
            }
        }
    };
</script>

<style scoped>

</style>
