<template>
  <v-layout>
    <v-flex>
      {{ item.text }}
    </v-flex>
    <v-flex class="text-right">
      <v-icon> {{ AttributeDataTypeIcons[item.value] }} </v-icon>
    </v-flex>
  </v-layout>
</template>

<script>
    import {AttributeDataTypeIcons} from "@/enum/attribute_data_types";

    export default {
        name: "TaskCreateStrictModeItem",
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            AttributeDataTypeIcons: AttributeDataTypeIcons
        })
    };
</script>

<style scoped>

</style>
