import {TableFilter} from "@/enum/table_filter";
import {ManufacturerAPI} from "@/api/ManufacturerAPI";
import {vatRateItems} from "@/app/products/definitions/product.form";

const stockLoadingItemPricesTable = {
    instance: {
        filterType: TableFilter.TEXT
    },
    'manufacturer.id': {
        filterType: TableFilter.SELECT_MULTIPLE,
        autocomplete: {
            callFn: () => ManufacturerAPI.getAllPages(),
            thenFn: response => response.data.items.map(el => ({
                text: el.name,
                value: el.id
            }))
        },
        itemLabel: item => item.manufacturer.name,
        sortBy: 'manufacturer.name'
    },
    measure_unit: {
        filterType: TableFilter.TEXT,
        itemLabel: item => item.measure_unit !== '' ? item.measure_unit : '-'
    },
    price: {
        filterType: TableFilter.TEXT
    },
    vat: {
        filterType: TableFilter.SELECT_MULTIPLE,
        filterItems: vatRateItems
    }
};

export {stockLoadingItemPricesTable};