<template>
  <v-dialog
    v-model="model"
    width="450"
  >
    <v-card>
      <v-card-title>
        {{ $t('stocks.locations.setAvailability') }}
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <v-card-text>
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="stocks.locations."
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :text="!valid"
            type="submit"
            color="accent"
            @click="submit"
          >
            <v-icon class="mr-2">
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockLocationRender} from "@/app/stocks/locations/definitions/stockLocation.form";

    export default {
        name: "AvailabilityDialog",
        components: {FormFields},
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            availability: null,
            form: {availability: null},
            formRender: {availability: new StockLocationRender().availability},
            valid: false
        }),
        computed: {
            model: {
                get: function () {
                    return this.show;
                },
                set: function (newValue) {
                    this.$emit('update:show', newValue);
                }
            },
        },
        methods: {
            cancel: function () {
                this.model = false;
                this.$emit('cancel');
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.$emit('set-availability', this.form.availability);
                this.model = false;
            }
        }
    };
</script>

<style scoped>

</style>
