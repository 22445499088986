<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header class="text-subtitle-1">
        <div>
          <v-btn
            fab
            outlined
            small
            type="submit"
            class="mr-2"
            :to="'/suppliers/' + supplierId + '/update'"
          >
            <v-icon>
              $updateItem
            </v-icon>
          </v-btn>
          {{ details.name }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card>
          <v-container fluid>
            <v-layout wrap>
              <v-flex
                sm6
                xs12
              >
                <div v-if="details.ico">
                  <span class="text-caption">{{ $t('suppliers.ico') + ': ' }}</span>
                  {{ details.ico }}
                </div>
                <div v-if="details.dic">
                  <span class="text-caption">{{ $t('suppliers.dic') + ': ' }}</span>
                  {{ details.dic }}
                </div>
                <div v-if="details.phone">
                  <span class="text-caption">{{ $t('suppliers.phone') + ': ' }}</span>
                  {{ details.phone }}
                </div>
                <div v-if="details.email">
                  <span class="text-caption">{{ $t('suppliers.email') + ': ' }}</span>
                  {{ details.email }}
                </div>
                <div v-if="details.website">
                  <span class="text-caption">{{ $t('suppliers.website') + ': ' }}</span>
                  {{ details.website }}
                </div>
                <div v-if="addressInline(details.billing_address) !== ''">
                  <span class="text-caption">{{ $t('suppliers.billing_address') + ': ' }}</span>
                  {{ details.billing_address | addressInline }}
                </div>
              </v-flex>
              <v-flex
                sm6
                xs12
              >
                <EntityHistory :changes="details" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component.vue";
    import {addressInline} from "@/utils/filters";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";

    export default {
        name: "SupplierDetail" ,
        components: {EntityHistory},
        mixins: [RouteParamsMapperMixin],
        props: {
            details: {
                type: Object,
                default: () => ({})
            },
        },
        data: () => ({
            addressInline: addressInline,
        })
    };
</script>

<style scoped>

</style>