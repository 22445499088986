import {Note} from "@/app/tasks/definitions/taskCommon.render.form";

function StockLocationLockForm() {
    return {
        lock_items_ids: [],
        strict_mode: false,
        note: null
    };
}

function StockLocationLockRender() {
    return {
        strict_mode: {
            icon: '$strictLocationLock',
            checkbox: true,
            hint: 'stocks.locations.locks.strictHint'
        },
        note: new Note
    };
}

export {StockLocationLockForm, StockLocationLockRender};
