import {StocksXAutocompleteRender} from "@/app/overview/definitions/overview.render.form";

function StockLocationsStatusForm() {
    return {
        stockId: null,
        subStockId: null,
        dateFrom: null,
        dateTo: null
    };
}

function StockLocationsStatusRender() {
    return {
        ...(new StocksXAutocompleteRender(true, true)),
        dateFrom: {
            datePicker: true,
            required: true,
            sticky: true
        },
        dateTo: {
            datePicker: true,
            required: true,
            sticky: true
        }
    };
}

export {StockLocationsStatusForm, StockLocationsStatusRender};
