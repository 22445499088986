import {Roles} from "@/enum/roles";
import CarriersList from "@/app/carriers/CarriersList.view";
import CarrierShow from "@/app/carriers/CarrierShow.view";
import CreateUpdate from "@/app/carriers/CarrierCreateUpdate.view";
import ServiceCreateUpdate from "@/app/carriers/CarrierServiceCreateUpdate.view";
import ImageCreate from "@/app/images/ImageCreate.view";

const BASE_URL = '/carriers';

const routes = {
    base: {
        path: BASE_URL,
        component: CarriersList,
        meta: {
            title: 'carriers.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },

    show: {
        path: BASE_URL + '/:carrierId(\\d+)',
        component: CarrierShow,
        meta: {
            title: 'carriers.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    create: {
        path: BASE_URL + '/create',
        component: CreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'carriers.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:carrierId(\\d+)/update',
        component: CreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'carriers.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    serviceCreate: {
        path: BASE_URL + '/:carrierId(\\d+)/services/create',
        component: ServiceCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'carriers.services.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    serviceUpdate: {
        path: BASE_URL + '/:carrierId(\\d+)/services/:serviceId(\\d+)/update',
        component: ServiceCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'carriers.services.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    imageCreate: {
        path: BASE_URL + '/:carrierId(\\d+)/image/create',
        component: ImageCreate,
        meta: {
            title: 'base.images.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    foilImageCreate: {
        path: BASE_URL + '/:carrierId(\\d+)/foil-image/create',
        component: ImageCreate,
        props: {
            isFoilImage: true
        },
        meta: {
            title: 'carriers.images.create.foil.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    imageUpdate: {
        path: BASE_URL + '/:carrierId(\\d+)/image/update',
        component: ImageCreate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'base.images.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },

    foilImageUpdate: {
        path: BASE_URL + '/:carrierId(\\d+)/foil-image/update',
        component: ImageCreate,
        props: {
            isEdit: true,
            isFoilImage: true
        },
        meta: {
            title: 'carriers.images.update.foil.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    }
};

export default Object.values(routes);
