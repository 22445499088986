const importNotFound = "požadovaný import nebyl nalezen";
export default {
    title: "Import",
    imports: "Importy",
    link: "Importy",
    create: "Vytvořit import",
    deleted: "Import smazán",
    file: "Soubor",
    file_info_create: "Soubor pomocí kterého se bude vytvářet šablona pro budoucí importy",
    file_info_use: "Zadejte soubor ze kterého se budou importovat data. Zadejte soubor s odpovídajícím formátem dat podle zvolené šablony.",
    domain: "Cílová oblast dat",
    domain_info: "Oblast dat do které se budou importovat data pomocí této šablony. Zvolte oblast dat, do které chcete pomocí této šablony v budoucnu importovat data",
    choose_file: "Vybrat soubor",
    process: "Zpracovat import",
    process_started: "Import byl odeslán ke zpracování",
    processed: "Import zpracován",
    download_imported_file: "Stáhnout přiložený soubor",
    download_error: 'Nepodařilo se stáhnout uživatelský soubor',
    array_buffer_error: 'Nepodařilo se převést uživatelský soubor',
    viewImport: "Zobrazit import",
    configuration: "Nastavení importu",


    stepper: {
        first_step: "Vytvořit šablonu",
        second_step: "Připravit import",
        second_step_back: "Smazat import a připravit nový",
        third_step: "Zkontrolovat & importovat",
    },

    hints: {
        parameters_hint: "Parametry importu umožňují specifikovat další potřebné informace k importovaným datům.",
        header_rows: "Nastavte počet řádků hlavičky vašeho souboru.",
        first_row: "Pokud soubor obsahuje data mimo import, nastavte první řádek k importu.",
        first_column: "Pokud soubor obsahuje data mimo import, nastavte první sloupec k importu.",
        required_attributes: "Všechny povinné atributy musí být v tabulce níže namapovány, pokud není možné namapovat všechny povinné atributy váš soubor a zvolená oblast dat nemusí být kompatibilní.",
        mapping: "Zde namapujte váš soubor na zvolenou oblast dat, sloupec namapujete kliknutím na jeho hlavičku a zvolení cíleného atributu. Při mapování také zvolte sloupce, které chcete použít jako porovnávací při importu.",
        primary_key: "Tento Sloupec je označen jako porovnávací atribut.",
        easy_mode: "V tomto módu je možné pouze přidávat nové položky do zvolené cílové oblasti dat.",
        latest_on_duplicity: "Pokud se při identifikaci záznamu nalezne více odpovídajících záznamů, použije se ten co byl vytvořen jako poslední",
        fileTemplate: "Stáhněte si šablonu pro importovací soubor cílové oblasti dat."
    },

    rules: {
        file_type: "Nepodporovaný typ souboru",
        multiple_sheets: "Vybraný soubor má více než jeden list",
        cannot_access: "Vybraný soubor nelze otevřít"
    },

    config: {
        title: "Šablona importu",
        templates: "Šablony importů",
        create: "Vytvořit šablonu importu",
        edit: "Duplikovat a upravit šablonu",
        view: "Zobrazit šablonu",
        created: "Šablona byla vytvořena",
        save: "Uložit šablonu",
        save_new: "Uložit novou šablonu",
        deleted: "Šablona importu smazána",
        easyMode: "Bezpečný mód",
        latestOnDuplicity: 'Ignorovat duplicity',

        name: "Jméno šablony",
        domain: "Cílová oblast dat šablony",
        file: "Soubor",
        defaultParameters: "Výchozí parametry šablony",
        parameters: "Parametery importu",
        required_attributes: "Povinné atributy",
        mapping: "Mapování sloupců",

        firstRow: "První řádek",
        firstColumn: "První sloupec",
        headerRows: "Hlavičkové řádky",

        unmap: "zrušit výběr",
        not_mapped: "nemapováno",
        primary_set: "Nastavit jako porovnávací atribut",
        primary_unset: "Zrušit porovnávací atribut",
        empty_error: "Konfigurace nemá import, ze kterého by se braly referenční data",
    },
    info: {
        title: "Informace o importu",
        hint: "Statistiky o importu se počítají, tato akce může trvat několik minut.",
        include: "Import bude obsahovat: ",
        error: "Zpracování tohoto importu povede k chybě s tímto hlášením: ",
        created: 'Počet vytvoření',
        updated: 'Počet upravení',
        warn: "Upozornění",

        primary_key_toggle_warn: "Hlavní skupina musí mít alspoň jeden porovnávací attribut.",
        primary_key_group_warn: "Skupinám {0} chybí alespoň jeden porovnávací atribut.",
        fake_file_warn: "Šablona zobrazuje ukázkový soubor, opravdový soubor nebyl dodán, nebo nespňuje podmínky.",
        mapping_warn: "Pokud máte nenamapované povinné atributy, znamená to, že vaše šablona a vybraný soubor nesou kompatibilní.",
        mapping_warn_create: "Nemáte namapovány všechny Povinné Atributy požadované zvolenou Cílovou Oblastí Dat.",
        parameters_warn: "Všechny povinné parametry musí být vyplněny.",
        change_value_warn: "Změnou této hodnoty příjdete o aktuální mapování.",
        edit_values_warn: "Zpracovní tohoto Importu zmnění existující data. Přejete si pokračovat?",
        short_file_warn: "Počet sloupců nahraného souboru je příliš malý pro tuto šablonu.",
        delete_import: "Přejete si chybný import ponechat?",
    },

    errors: {
        'import': {
            status: {
                404: importNotFound,
            }
        },
        importCreate: {
            status: {
                409: 'Import se nepodařilo vztvořit'
            }
        },
        importDelete: {
            status: {
                404: importNotFound,
                409: "Import se nepodařilo smazat"
            }
        },
        importUpdate: {
            status: {
                404: importNotFound,
                409: 'Import se nepodařilo upravit'
            }
        },
        importProcess: {
            status: {
                404: importNotFound,
                409: "Import se napodailoodeslat ke zpracování."
            }
        },
        domainTemplate: {
            status: {
                404: "Nepodažilo se nalézt data o zvoleném zdroji dat",
            }
        },
    }
};
