<template>
  <div>
    <v-container
      fluid
      class="pa-0"
    >
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :api-filter="localApiFilter"
        :reload="reload"
        class="movementsTable"
        @update:filter="onTableFilterChange"
      >
        <!-- desktop view -->
        <template
          v-for="column of headers.filter(header => header.value)"
          #[slotName(column)]="{ item }"
        >
          <StockMovementItem
            :key="column.value"
            :value="column.value"
            :item="item"
          />
        </template>
        <!-- mobile view -->
        <template #item="{ item }">
          <v-container
            fluid
            class="d-flex flex-column"
          >
            <div class="d-flex flex-row justify-space-between">
              <span>
                <StockMovementItem
                  v-if="showTaskId"
                  value="task.id"
                  :item="item"
                  class="ml-2"
                />
                <StockMovementItem
                  v-if="showTaskType"
                  value="task.type"
                  :item="item"
                  class="ml-2"
                />
              </span>
              <StockMovementItem
                value="created_at"
                :item="item"
              />
            </div>
            <div class="d-flex flex-row justify-space-between align-center my-3">
              <StockMovementItem
                value="product_instance.id"
                :item="item"
                class="ml-2"
              />
              <StockMovementItem
                value="amount"
                :item="item"
                class="mr-3"
              />
            </div>
            <div class="d-flex flex-row justify-space-between">
              <span class="d-flex align-center">
                <StockMovementItem
                  v-if="item.type === 'PUT'"
                  value="type"
                  :item="item"
                />
                <StockMovementItem
                  value="location.type"
                  :item="item"
                  class="mx-2"
                />
                <StockMovementItem
                  v-if="item.type === 'PICK'"
                  value="type"
                  :item="item"
                />
                <StockMovementItem
                  v-if="item.location.type === 'STOCK'"
                  value="location.id"
                  :item="item"
                  class="mx-2"
                />
              </span>
              <StockMovementItem
                value="created_by"
                :item="item"
                class="text-caption ml-2"
              />
            </div>
          </v-container>
          <v-divider class="my-2" />
        </template>
      </x-data-table>
    </v-container>
  </div>
</template>
<script>
    import {StockMovementsAPI} from "@/api/StockMovementsAPI";
    import {UserAPI} from "@/api/UserAPI";
    import {ReactiveUserCacheMixin} from "@/app/mixins/ReactiveUserCacheMixin";
    import StockMovementItem from "@/app/overview/movements/components/StockMovementItem.component";
    import {createHeaders} from "@/utils/table";
    import {movementsTable} from "@/app/overview/movements/definitions/stockMovements.form";
    import {ReactiveSubStockCacheMixin} from "@/app/mixins/ReactiveSubStockCacheMixin";
    import {StockAPI} from "@/api/StockAPI";
    import {has} from "@/utils/object";

    export default {
        name: "StockMovementsTable",
        components: {StockMovementItem},
        mixins: [ReactiveUserCacheMixin, ReactiveSubStockCacheMixin],
        props: {
            showTaskId: {
                type: Boolean,
                default: true
            },
            showTaskType: {
                type: Boolean,
                default: true
            },
            showSubStock: {
                type: Boolean,
                default: true
            },
            apiFilter: {
                type: Array,
                default: () => []
            },
            reload: {
                type: Number,
                default: null
            },
            chosenStock: {
                type: Number,
                default: null,
            }
        },
        data: () => ({
            headers: [],
            items: [],
            loading: false,
            filter: {},
        }),
        computed: {
            apiDataSource: function () {
                return StockMovementsAPI.getAll.bind(StockMovementsAPI);
            },
            apiDataSourceAllPages: function () {
                return StockMovementsAPI.getAllPages.bind(StockMovementsAPI);
            },
            localApiFilter: function () {
                return [...this.apiFilter, ...Object.values(this.filter).filter(filter => filter !== null)];
            },
        },
        watch: {
            loading: function () {
                this.$emit('update:loading', this.loading);
            },
            items: function () {
                [...new Set([
                    ...this.items.map(item => item.created_by),
                    ...this.items.map(item => item.location.user_id)
                ])]
                    .filter(userId => !!userId)
                    .forEach(userId => {
                        this.cacheUser(UserAPI.get(userId), userId).catch(this.snack);
                    });

                [...new Set([
                    ...this.items.map(item => item.subordinate_stock_id)
                ])]
                    .forEach(subStockId => {
                        this.cacheSubStock(StockAPI.getSubstockWOStock(subStockId), subStockId).catch(this.snack);
                    });
            },
        },
        created: function () {
            this.headers = createHeaders(movementsTable(this.showTaskId, this.showTaskType, this.showSubStock), 'stocks.stockMovements.table.', false);
        },
        methods: {
            slotName: function (column) {
                return 'item.' + column.value;
            },
            onTableFilterChange: function (filters) {
                if (has(filters, 'stock.id')) {
                    this.$emit('stock-chosen', filters['stock.id']);
                    this.$emit('substock-chosen', []);
                }
                if (has(filters, 'substock.id')) {
                    this.$emit('substock-chosen', filters['substock.id']);
                }
            },
        }
    };
</script>

<style scoped lang="sass">
.movementsTable .col
  padding: 5px
</style>
