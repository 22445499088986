const InstanceType = {
    NORMAL: 'NORMAL',
    SERIAL_NUMBER: 'SERIAL_NUMBER',
    BATCH: 'BATCH'
};

function isBaseInstance(instance) {
    return Object.values(InstanceType).includes(instance.type.name);
}

export {InstanceType, isBaseInstance};
