import {BaseAPI} from "@/api/BaseAPI";

const ShipmentCarrierAPI = {

    API: BaseAPI,
    DOMAIN: 'shipments/carriers',
    DOMAIN_SERVICES: 'services',
    DOMAIN_IMAGE: 'image',
    DOMAIN_FOIL_IMAGE: 'foil-image',
    DOMAIN_CONTRACTED: 'contracted-for',
    DOMAIN_ALL_CONTRACTED: 'contracted',
    DOMAIN_PARAMETERS: 'parameters',
    LANG: 'base.api.shipmentCarrier',
    IMAGE_LANG: 'carriers.images',
    CONTRACTED_LANG: 'base.api.shipmentCarrier.contracted',

    _pruneDeleted: response => ({data: response.data.filter(item => !item.deleted)}),
    _pruneDeletedContracted: response => ({data: response.data.filter(item => !item.carrier.deleted)}),

    getAll() {
        return this.API.get(
            this.DOMAIN,
            this.LANG
        ).then(this._pruneDeleted);
    },

    get(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId],
            this.LANG
        );
    },

    create(data) {
        return this.API.post(
            this.DOMAIN,
            data
        );
    },

    update(carrierId, data) {
        return this.API.patch(
            [this.DOMAIN, carrierId],
            data
        );
    },

    delete(carrierId) {
        return this.API.delete([this.DOMAIN, carrierId]);
    },

    // contracted carriers

    getAllContractedByOwner(ownerId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_ALL_CONTRACTED, 'by-owner', ownerId],
            [this.CONTRACTED_LANG, 'all', 'byOwner']
        ).then(this._pruneDeletedContracted);
    },

    getAllContractedBySubStock(subStockId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_ALL_CONTRACTED, 'by-substock', subStockId],
            [this.CONTRACTED_LANG, 'all', 'bySubStock']
        ).then(this._pruneDeletedContracted);
    },

    getContracted(carrierId, ownerId) {
        return this.API.get(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId],
            this.CONTRACTED_LANG
        );
    },

    getServices(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId, this.DOMAIN_SERVICES],
            this.LANG
        ).then(this._pruneDeleted);
    },

    getService(carrierId, serviceId) {
        return this.API.get(
            [this.DOMAIN, carrierId, this.DOMAIN_SERVICES, serviceId],
            this.LANG
        );
    },

    createService(carrierId, data) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_SERVICES],
            data
        );
    },

    updateService(carrierId, serviceId, data) {
        return this.API.patch(
            [this.DOMAIN, carrierId, this.DOMAIN_SERVICES, serviceId],
            data
        );
    },

    deleteService(carrierId, serviceId) {
        return this.API.delete([this.DOMAIN, carrierId, this.DOMAIN_SERVICES, serviceId]);
    },

    getRequiredParameters(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId, 'required-parameters'],
            this.LANG
        );
    },

    getOptionalParameters(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId, 'optional-parameters'],
            this.LANG
        );
    },

    addContract(carrierId, ownerId, data) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId],
            data,
            this.CONTRACTED_LANG
        );
    },

    removeContract(carrierId, ownerId) {
        return this.API.delete(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId],
            this.CONTRACTED_LANG
        );
    },

    createOrUpdateParameter(carrierId, ownerId, key, newValue) {
        return this.API.put(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId, this.DOMAIN_PARAMETERS, key],
            {value: newValue},
            this.CONTRACTED_LANG
        );
    },

    deleteParameter(carrierId, ownerId, key) {
        return this.API.delete(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId, this.DOMAIN_PARAMETERS, key],
            this.CONTRACTED_LANG
        );
    },

    updateContractedCarrierSubstocks(carrierId, ownerId, data) {
        return this.API.put(
            [this.DOMAIN, carrierId, this.DOMAIN_CONTRACTED, ownerId, 'substocks'],
            data,
            this.CONTRACTED_LANG
        );
    },

    getImage(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId, this.DOMAIN_IMAGE],
            this.IMAGE_LANG
        );
    },

    createLocalImage(carrierId, image) {
        const data = new FormData();
        data.append('image', image);
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_IMAGE, 'local'],
            data,
            this.IMAGE_LANG,
            'multipart/form-data'
        );
    },

    createExternalImage(carrierId, url, store) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_IMAGE, 'external'],
            {url: url, store: store}
        );
    },

    deleteImage(carrierId) {
        return this.API.delete(
            [this.DOMAIN, carrierId, this.DOMAIN_IMAGE],
            this.IMAGE_LANG
        );
    },

    storeExternalImage(carrierId) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_IMAGE, 'store'],
            this.IMAGE_LANG
        );
    },

    getFoilImage(carrierId) {
        return this.API.get(
            [this.DOMAIN, carrierId, this.DOMAIN_FOIL_IMAGE],
            this.IMAGE_LANG
        );
    },

    createLocalFoilImage(carrierId, image) {
        const data = new FormData();
        data.append('image', image);
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_FOIL_IMAGE, 'local'],
            data,
            this.IMAGE_LANG,
            'multipart/form-data'
        );
    },

    createExternalFoilImage(carrierId, url, store) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_FOIL_IMAGE, 'external'],
            {url: url, store: store}
        );
    },

    deleteFoilImage(carrierId) {
        return this.API.delete(
            [this.DOMAIN, carrierId, this.DOMAIN_FOIL_IMAGE],
            this.IMAGE_LANG
        );
    },

    storeExternalFoilImage(carrierId) {
        return this.API.post(
            [this.DOMAIN, carrierId, this.DOMAIN_FOIL_IMAGE, 'store'],
            this.IMAGE_LANG
        );
    },

    runValidatePackages() {
        return this.API.post([this.DOMAIN, 'run-validate-packages'], this.LANG);
    },

    runGenerateProtocols() {
        return this.API.post([this.DOMAIN, 'run-generate-protocols'], this.LANG);
    },

    runTrackPackages() {
        return this.API.post([this.DOMAIN, 'run-track-packages'], this.LANG);
    }
};

export {ShipmentCarrierAPI};
