<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <template v-if="details">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                type="submit"
                class="mr-2"
                :to="'/stocks/' + stockId + '/update'"
              >
                <v-icon>
                  $updateItem
                </v-icon>
              </v-btn>
              {{ details.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-container fluid>
                <v-layout wrap>
                  <v-flex
                    sm6
                    xs12
                  >
                    <div>
                      <span class="text-caption">{{ $t('stocks.description') + ': ' }}</span>
                      {{ details.description }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('stocks.is_small') + ': ' }}</span>
                      {{ details.is_small | yesOrNo }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('stocks.address') + ': ' }}</span>
                      {{ details.address | addressInline }}
                    </div>
                    <div>
                      <span class="text-caption">{{ $t('stocks.chief') + ': ' }}</span>
                      {{ details.chief | fullName }}
                    </div>
                  </v-flex>
                  <v-flex
                    sm6
                    xs12
                  >
                    <EntityHistory :changes="details" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-layout wrap>
        <v-flex
          xl6
          xs12
          class="pa-1"
        >
          <StockSubstocksList />
        </v-flex>
        <v-flex
          xl6
          xs12
          class="pa-1"
        >
          <StockLocationsList
            :substock="false"
          />
        </v-flex>
      </v-layout>
      <br>
      <v-expansion-panels
        v-if="displayMapEditor"
        :value="1"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1">
            <div>
              <v-btn
                fab
                outlined
                small
                class="mr-2"
              >
                <v-icon>$mapEditor</v-icon>
              </v-btn>
              {{ $t('map.warehouseMap') }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <StockMapEdit />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
    <x-btn-fab-expander
      :actions="fabActions"
    />
  </div>
</template>

<script>
    import {StockAPI as API} from "@/api/StockAPI";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import StockLocationsList from "@/app/stocks/locations/components/StockLocationsList.component";
    import {tabTitle} from "@/utils/string";
    import StockSubstocksList from "@/app/stocks/substocks/components/StockSubstocksList.component";
    import {stockFabActions} from "@/app/stocks/definitions/stockFabActions.definition";
    import StockMapEdit from "@/app/stocks/map/StockMapEdit.component.vue";

    export default {
        name: "StockShow",
        components: {StockSubstocksList, StockLocationsList, EntityHistory, StockMapEdit},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            details: null,
            displayMapEditor: false,
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.details !== null) {
                    title = this.details.name;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            fabActions: function () {
                return stockFabActions(this.stockId);
            }
        },
        createdOrActivated: function () {
            API.get(this.stockId)
                .then(response => {
                    this.details = response.data;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/stocks');
                });
            this.displayMapEditor = true;
        },
        deactivated: function () {
            this.$store.commit('storageMap/resetMap');
            this.displayMapEditor = false;
        },
        destroyed: function () {
            this.$store.commit('storageMap/resetMap');
            this.displayMapEditor = false;
        },
    };
</script>

<style scoped>

</style>
