<template>
  <div class="fabspacer">
    <!-- This is just empty space so user can move FAB away from content -->
  </div>
</template>

<script>
    export default {
        name: "FabSpacer"
    };
</script>

<style scoped lang="sass">
    .fabspacer
        height: 72px
</style>
