import {BarcodeAPI} from "@/api/BarcodeAPI";
import Vue from "vue";
import {TaskAPI} from "@/api/TaskAPI";
import {CodeType} from "@/enum/code_type";
import {taskLinks, taskNames, taskTypes} from "@/enum/task_type";
import {TaskState} from "@/enum/task_state";
import {TaskPriority} from "@/enum/task_priority";
import {taskPriority} from "@/utils/filters";
import i18n from "@/service/lang/i18n";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {ProductAPI} from "@/api/ProductAPI";
import {Store} from "@/service/store/Store";
import {StockAPI} from "@/api/StockAPI";
import {BuyerAPI} from "@/api/BuyerAPI";
import {SupplierAPI} from "@/api/SupplierAPI";

async function tasksById(search) {
    const numberSearch = Number.parseInt(search, 10);
    if (isNaN(numberSearch)) {
        return [];
    } else {
        return TaskAPI.getAll({
            itemsPerPage: 11,
            filter: APIFilters.makeFilter({
                [APIFilterOP.OR]: [{
                    [APIFilterOP.EQUALS]: {
                        id: numberSearch
                    }
                }, {
                    [APIFilterOP.BETWEEN]: {
                        id: [numberSearch + '0', numberSearch + '9']
                    }
                }]
            })
        }).then(response => {
            return response.data.items.map(task => ({
                text: `#${task.id} (${i18n.t(taskNames[task.type])})`,
                value: {
                    id: 'tasksById' + task.id,
                    link: `${taskLinks[task.type]}/${task.id}`
                }
            }));
        }).catch(() => {
            return [];
        });
    }
}

async function tasksByExternalId(search) {
    return TaskAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.EQUALS]: {
                external_id: search
            }
        })
    }).then(response => {
        return response.data.items.map(task => ({
            text: `#${task.id} (${i18n.t(taskNames[task.type])})`,
            value: {
                id: 'tasksByExternalId' + task.id,
                link: `${taskLinks[task.type]}/${task.id}`
            }
        }));
    }).catch(() => {
        return [];
    });
}

async function tasksByState(search) {
    let numberSearch = -1;
    for (const state in TaskState) {
        if (i18n.t('tasks.state.' + TaskState[state]).toLowerCase() === search.toLowerCase()) {
            numberSearch = TaskState[state];
            break;
        }
    }
    if (numberSearch === -1) return [];
    return TaskAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.EQUALS]: {
                state: numberSearch
            }
        })
    }).then(response => {
        return response.data.items.map(task => ({
            text: `#${task.id} (${i18n.t(taskNames[task.type])})`,
            value: {
                id: 'tasksByState' + task.id,
                link: `${taskLinks[task.type]}/${task.id}`
            }
        }));
    }).catch(() => {
        return [];
    });
}

async function tasksByPriority(search) {
    let numberSearch = -1;
    for (const priority in TaskPriority) {
        if (taskPriority(TaskPriority[priority]).toLowerCase() === search.toLowerCase()) {
            numberSearch = TaskPriority[priority];
            break;
        }
    }
    if (numberSearch === -1) return [];
    return TaskAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.EQUALS]: {
                priority: numberSearch
            }
        })
    }).then(response => {
        return response.data.items.map(task => ({
            text: `#${task.id} (${i18n.t(taskNames[task.type])})`,
            value: {
                id: 'tasksByPriority' + task.id,
                link: `${taskLinks[task.type]}/${task.id}`
            }
        }));
    }).catch(() => {
        return [];
    });
}

async function tasksByType(search) {
    let translatedSearch = '';
    for (const task in taskNames) {
        if (i18n.t(taskNames[task]).toLowerCase() === search.toLowerCase()) {
            translatedSearch = taskTypes[task];
            break;
        }
    }
    if (translatedSearch === '') return [];
    return TaskAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.EQUALS]: {
                type: translatedSearch
            }
        })
    }).then(response => {
        return response.data.items.map(task => ({
            text: `#${task.id}`,
            value: {
                id: 'tasksByType' + task.id,
                link: `${taskLinks[task.type]}/${task.id}`
            }
        }));
    }).catch(() => {
        return [];
    });
}

async function productsByBarcode(search) {
    return BarcodeAPI.decode(search)
        .then(response => {
            const data = response.data;
            if (data.type === CodeType.PRODUCT_INSTANCE) {
                const instanceId = data.object_link.split('products')[1].split('/')[1];
                return [{
                    id: 'productByBarcode' + instanceId,
                    text: Vue.options.filters.instanceLabel(data.object_info),
                    value: {
                        id: 'productByBarcode' + instanceId,
                        link: '/products/' + instanceId
                    }
                }];
            } else {
                return [];
            }
        }).catch(() => {
            return [];
        });
}

function productLabel(product) {
    let productLabel;
    if (Store.getters['userConfig/productModels']) {
        productLabel = product.manufacturer.abbreviation + ' ' + product.model;
    } else {
        productLabel = product.name;
    }
    return productLabel;
}

async function productsByManufacturer(search) {
    return ProductAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.LIKE]: {
                ['manufacturer.name']: search
            }
        })
    }).then(response => {
        return response.data.items.map(product => ({
                text: productLabel(product),
                value: {
                    id: 'productByManufacturer' + product.id,
                    link: '/products/' + product.id
                }
            })
        );
    }).catch(() => {
        return [];
    });
}

async function productsByName(search) {
    return ProductAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.LIKE]: {
                name: search
            }
        })
    }).then(response => {
        return response.data.items.map(product => ({
                text: productLabel(product),
                value: {
                    id: 'productByName' + product.id,
                    link: '/products/' + product.id
                }
            })
        );
    }).catch(() => {
        return [];
    });
}

async function productsByModel(search) {
    return ProductAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.EQUALS]: {
                model: search
            }
        })
    }).then(response => {
        return response.data.items.map(product => ({
                text: productLabel(product),
                value: {
                    id: 'productByModel' + product.id,
                    link: '/products/' + product.id
                }
            })
        );
    }).catch(() => {
        return [];
    });
}

async function stocksByName(search) {
    return StockAPI.getAll({
        itemsPerPage: 11,
        filter: APIFilters.makeFilter({
            [APIFilterOP.LIKE]: {
                name: search
            }
        })
    }).then(response => {
        return response.data.items.map(stock => ({
                text: stock.name,
                value: {
                    id: 'stockByName' + stock.id,
                    link: '/stocks/' + stock.id
                }
            })
        );
    }).catch(() => {
        return [];
    });
}

async function locationsByBarcode(search) {
    return BarcodeAPI.decode(search)
        .then(response => {
            const data = response.data;
            if (data.type === CodeType.STOCK_LOCATION) {
                return [{
                    text: Vue.options.filters.locationLabel(data.object_info),
                    value: {
                        id: 'location' + data.object_id,
                        link: '/stocks' + data.object_link.split('stocks')[1]
                    }
                }];
            } else {
                return [];
            }
        }).catch(() => {
            return [];
        });
}

async function buyersByIco(search) {
    return BuyerAPI.getByIco(search)
        .then(response => ([{
                text: response.data.name,
                value: {
                    id: 'buyer' + response.data.id,
                    link: '/buyers/' + response.data.id + '/update/'
                }
            }])
        ).catch(() => {
            return [];
        });
}

async function suppliersByIco(search) {
    return SupplierAPI.getByIco(search)
        .then(response => ([{
                text: response.data.name,
                value: {
                    id: 'supplier' + response.data.id,
                    link: '/suppliers/' + response.data.id + '/update/'
                }
            }])
        ).catch(() => {
            return [];
        });
}

async function stockOwnersByIco(search) {
    return StockAPI.getOwnerByIco(search)
        .then(response => ([{
                text: response.data.name,
                value: {
                    id: 'stockOwner' + response.data.id,
                    link: '/stocks/owners/' + response.data.id + '/update/'
                }
            }])
        ).catch(() => {
            return [];
        });
}

export {
    tasksById,
    tasksByExternalId,
    tasksByState,
    tasksByPriority,
    tasksByType,
    productsByBarcode,
    productsByManufacturer,
    productsByName,
    productsByModel,
    stocksByName,
    locationsByBarcode,
    buyersByIco,
    suppliersByIco,
    stockOwnersByIco
};
