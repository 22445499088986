import {TableFilter} from "@/enum/table_filter";
import rules from "@/utils/formRules";

function ProductInstanceBarcodeForm() {
    return {
        code: null,
        quantity: 1,
        description: null
    };
}

function ProductInstanceBarcodeRender() {
    return {
        code: {
            icon: '$barcode',
            max: 20,
            required: true,
            rules: [
                rules.pattern(/^[\x21-\x7E]*$/),
            ]
        },
        quantity: {
            icon: '$quantity',
            type: 'number',
            required: true
        },
        description: {
            icon: '$barcodeDescription',
            max: 30,
            hint: 'products.instances.barcodes.descriptionHint'
        }
    };
}

const productInstanceBarcodeTable = {
    code: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    quantity: {
        filterType: TableFilter.NUMBER
    },
    description: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    image: {
        sortable: false
    }
};

export {ProductInstanceBarcodeForm, ProductInstanceBarcodeRender, productInstanceBarcodeTable};
