export default {
    title: "Odběratelé",
    link: "Odběratelé",

    name: "Název",
    ico: "IČO",
    dic: "DIČ",
    phone: "Telefon",
    email: "E-mail",
    website: "Web",
    is_minor: "Je nezletilý",
    billing_address: "Fakturační adresa",
    delivery_address: "Doručovací adresa",
    address: "Doručovací adresa",
    delivery_addresses: "Doručovací adresy",
    type_id: "Typ",
    type: {
        id: "Typ",
        label: "Typ"
    },

    addresses: {
        public: "<veřejná>",
        privacy: "Soukromá uživatele",
        default: "Výchozí",
        markDefault: "Označit jako výchozí",
        searchHint: "Prohledává ulici, číslo popisné, upřesnění adresy, město, PSČ a zemi",

        create: {
            title: "Vytvořit doručovací adresu pro odběratele '{0}'",
            titleSimple: "Vytvořit novou doručovací adresu",
            done: "Doručovací adresa vytvořena."
        },
        update: {
            titleSimple: "Upravit doručovací adresu odběratele",
            title: "Upravit doručovací adresu pro odběratele '{0}'",
            done: "Doručovací adresa aktualizována."
        },
        delete: {
            done: "Doručovací adresa odběratele smazána."
        },
        status: {
            404: "Odběratel nebo jeho adresa neexistují!",
            409: "Výchozí dodací adresa odběratele nemůže být soukromá!"
        }
    },

    types: {
        link: "Typy odběratelů",
        title: "Typy odběratelů",
        titleSingle: "Typ odběratele",
        name: "Název",

        create: {
            label: "Vytvořit nový typ odběratele",
            title: "Nový typ odběratele",
            done: "Typ '{0}' vytvořen"
        },
        update: {
            titleSimple: "Upravit typ odběratele",
            title: "Upravit '{0}'",
            done: "'{0}' upraven"
        },
        delete: {
            done: "'{0}' smazán.",
            failed: "'{0}' nelze smazat. Zkuste to prosím znovu později."
        },

        status: {
            404: "Typ odběratele nenalezen!",
            409: "Typ odběratele nelze odstranit, protože je použitý u některého z odběratelů!"
        }
    },

    list: {
        unableToLoad: "Seznam odběratelů nebylo možné načíst. Zkuste to prosím znovu později."
    },
    show: {
        title: "Odběratel",
    },
    create: {
        title: "Vytvořit odběratele",
        label: "Vytvořit nového odběratele",
        done: "Odběratel '{0}' vytvořen.",
        undo: {
            done: "Tvorba '{0}' úspěšně vrácena!",
            failed: "Nezdařilo se vrátit tvorbu odběratele!"
        }
    },
    update: {
        titleSimple: "Upravit odběratele",
        title: "Upravit {0}",
        done: "Odběratel '{0}' upraven",
        undo: {
            done: "Úpravy '{0}' úspěšně vráceny!",
            failed: "Nezdařilo se vrátit úpravu odběratele!"
        }
    },
    delete: {
        done: "Odběratel '{0}' smazán.",
        failed: "Odběratele '{0}' nelze smazat. Zkuste to prosím znovu později.",
        undo: {
            done: "Smazání '{0}' úspěšně vráceno!",
            failed: "Nezdařilo se vrátit smazání odběratele!"
        },
        status: {
            404: "Zadaný odběratel neexistuje!",
            409: "Odběratele nelze smazat kvůli jeho vztahům k dalším objektům!"
        }
    },
    status: {
        404: "Zadaný odběratel neexistuje!",
        409: "Vyplněné IČO již má jiný odběratel!"
    }
};
