<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            cols="7"
            class="mt-2 mb-5"
          >
            <RenameObjectForm />
          </v-col>
          <v-col
            style="text-align: right"
            cols="5"
            class="mt-2 mb-5"
          >
            <v-btn
              v-if="!showAdvanced"
              outlined
              small
              class="my-1 ml-4"
              @click="showAdvanced = true"
            >
              <v-icon
                left
                small
              >
                $generalSettings
              </v-icon>
              {{ $t('map.advancedSettings') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card
      v-if="showAdvanced"
      class="mt-4"
    >
      <v-card-title
        class="justify-end mb-0"
      >
        <v-row>
          <v-col
            class="mt-2"
          >
            {{ $t('map.advancedSettings') }}
          </v-col>
          <v-col
            style="text-align: right"
            class="mt-2"
          >
            <v-btn
              icon
            >
              <v-icon
                @click="closeAdvancedPanel"
              >
                $close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-model="panel"
        height="30"
      >
        <v-tabs-slider />
        <v-tab
          width="30"
        >
          <v-icon
            small
            left
          >
            $generalSettings
          </v-icon>
          {{ $t('map.naming') }}
        </v-tab>
        <v-tab>
          <v-icon
            small
            left
          >
            $dimensions
          </v-icon>
          {{ $t('map.dimensions') }}
        </v-tab>
      </v-tabs>

      <v-divider />

      <div
        v-show="panel === 0"
      >
        <v-card-text>
          <v-form
            ref="rackNamingForm"
            class="mb-4 mt-2"
            @submit.prevent
          >
            <FormFields
              :form="rackNamingForm"
              :render="rackNamingFormRender"
              stretch-form
              lang-path="map."
              md-sizing="6"
            />
          </v-form>
        </v-card-text>
      </div>

      <v-card-text
        v-show="panel === 1"
      >
        <v-list-item-group
          v-model="selectedDim"
          color="primary"
        >
          <v-list
            class="overflow-y-auto"
            height="200"
            style="width: 100%"
            dense
          >
            <div
              v-for="(dimension, i) in getDimensions"
              :key="i"
            >
              <v-row>
                <v-col
                  class="px-0"
                  style="flex-basis: 50%;"
                >
                  <v-list-item
                    class="mr-3"
                  >
                    <v-list-item-content>
                      <div
                        class="dimensionColorIco"
                        :style="'background-color: ' + dimension.color + ';' "
                      />
                      {{ dimension.width }} cm x {{ dimension.height }} cm x {{ dimension.depth }} cm
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  class="px-0"
                  style="flex-basis: 20%;"
                >
                  <v-btn
                    small
                    outlined
                    class="mt-1"
                    @click="applyDim(i)"
                  >
                    {{ $t('map.assign') + ' (' + rackCellsSelectedAmount + ')' }}
                  </v-btn>
                </v-col>
                <v-col
                  class="px-0"
                  style="flex-basis: 10%;"
                >
                  <v-btn
                    v-if="dimension.default === undefined"
                    small
                    outlined
                    color="error"
                    class="mt-1"
                    @click="removeDim(i)"
                  >
                    <v-icon>
                      $deleteObject
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider />
            </div>
          </v-list>
        </v-list-item-group>
        <v-btn
          small
          depressed
          style="margin-top: 5px"
          @click="newDimension"
        >
          <v-icon
            left
          >
            $addItem
          </v-icon>
          {{ $t('map.newDimension') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <RackCellDetailPanel
      v-show="panel === 0 && doesRackSelectionExist"
      style="margin-top: 8px"
    />

    <v-card
      v-if="showAdvanced"
      v-show="panel === 1 && !isNaN(selectedDim)"
      style="margin-top: 8px"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <h3>{{ $t('map.dimension') }}</h3>
          </v-col>
          <v-col
            style="text-align: right;"
          >
            <v-btn
              icon
            >
              <v-icon
                @click="selectedDim=undefined"
              >
                $close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-container
          class="mt-4"
        >
          <v-form
            ref="dimensionForm"
            @submit.prevent
          >
            <FormFields
              :form="dimensionsForm"
              :render="dimensionsFormRender"
              lang-path="map."
              stretch-form
            />
            <v-color-picker
              v-model="dimensionsForm.inputColor"
              style="margin: 0 auto;"
              class="mt-4"
              canvas-height="50"
              @input="handleChangeDimension"
            />
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
    import { RackPanel } from "@/enum/stock_map";
    import { getRackColumns } from "@/service/Map.js";
    import {
        DimensionsForm, DimensionsRender,
        RackNamingForm, RackNamingRender,
    } from "@/app/stocks/map/definition/rackSetup.form";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {MapMixin} from "@/app/mixins/MapMixin";
    import {SnackbarMixin} from "@/app/mixins/SnackbarMixin";
    import RackCellDetailPanel from "@/app/stocks/map/components/panels/RackCellDetailPanel.component.vue";
    import RenameObjectForm from "@/app/stocks/map/components/forms/RenameObjectForm.component.vue";
    export default {
        name: "RackSetupPanel",
        components: {RenameObjectForm, RackCellDetailPanel, FormFields},
        mixins: [ MapMixin, SnackbarMixin ],
        data: () => ({
            rackNamingForm: new RackNamingForm(),
            rackNamingFormRender: new RackNamingRender(),
            dimensionsForm: new DimensionsForm(),
            dimensionsFormRender: new DimensionsRender(),
            panel: RackPanel.GENERAL,
            selectedDim: undefined,
            cols: 4,
            showAdvanced: false,
        }),
        computed: {
            // grouped info of selected cells
            getRackSelectionAttributes() {
                return this.$store.getters["storageMap/getRackSelectionAttributes"];
            },
            rackCellsSelectedAmount() {
                return this.getRackSelectionAttributes.cellsAmount;
            },

        },
        watch: {
            showMap: function () {
                if (this.showMap === false) {
                    const rack = this.selectedObject;
                    this.cols = getRackColumns(rack);
                    this.selectedDim = undefined;
                    this.panel = RackPanel.GENERAL;
                    this.showAdvanced = false;
                    this.loadRackNamingFormData();
                }
            },
            rackNamingForm: {
                handler: function (){
                    if (this.showMap === false && this.$refs.rackNamingForm !== undefined && this.$refs.rackNamingForm.validate()) {
                        this.$store.dispatch("storageMap/setNaming", {
                            numberShelvesFromUp: this.rackNamingForm.numberingShelf, 
                            numberColsFromRight: this.rackNamingForm.numberingColumn, 
                            firstNamingSymbol: this.rackNamingForm.firstSymbolValue, 
                            firstStartingSymbol: this.rackNamingForm.firstStartingSymbol, 
                            secondNamingSymbol: this.rackNamingForm.secondSymbolValue, 
                            secondStartingSymbol: this.rackNamingForm.secondStartingSymbol, 
                            thirdNamingSymbol: this.rackNamingForm.thirdSymbolValue, 
                            thirdStartingSymbol: this.rackNamingForm.thirdStartingSymbol, 
                            firstNamingSeparator: this.rackNamingForm.firstSeparator, 
                            secondNamingSeparator: this.rackNamingForm.secondSeparator, 
                            thirdNamingSeparator: this.rackNamingForm.thirdSeparator
                        });
                    }

                },
                deep: true,
            },
            selectedDim: function () {
                if (!isNaN(this.selectedDim)) {
                    const dim = this.getDimensions[this.selectedDim];
                    this.dimensionsForm.width = dim.width;
                    this.dimensionsForm.height = dim.height;
                    this.dimensionsForm.depth = dim.depth;
                    this.dimensionsForm.inputColor = dim.color;
                }
            },
            dimensionsForm: {
                handler: function () {
                    this.handleChangeDimension();
                },
                deep: true
            },
        },
        methods: {
            loadRackNamingFormData: function (){
                const naming = this.selectedObject.naming;
                this.rackNamingForm.numberingShelf = naming.numberShelvesFromUp;
                this.rackNamingForm.numberingColumn = naming.numberColsFromRight;
                this.rackNamingForm.firstSymbolValue = naming.symbols[0].namingSymbol;
                this.rackNamingForm.firstStartingSymbol = naming.symbols[0].startingSymbol;
                this.rackNamingForm.secondSymbolValue = naming.symbols[1].namingSymbol;
                this.rackNamingForm.secondStartingSymbol = naming.symbols[1].startingSymbol;
                this.rackNamingForm.thirdSymbolValue = naming.symbols[2].namingSymbol;
                this.rackNamingForm.thirdStartingSymbol = naming.symbols[2].startingSymbol;
                this.rackNamingForm.firstSeparator = naming.separators[0];
                this.rackNamingForm.secondSeparator = naming.separators[1];
                this.rackNamingForm.thirdSeparator = naming.separators[2];
            },
            handleChangeDimension: function () {
                if (this.showMap === false && this.$refs.dimensionForm?.validate() && !isNaN(this.selectedDim)) {
                    const dim = this.getDimensions[this.selectedDim];
                    if (dim.width === this.dimensionsForm.width && dim.height === this.dimensionsForm.height && dim.depth === this.dimensionsForm.depth && dim.color === this.dimensionsForm.inputColor) {
                        return;
                    }
                    this.$store.commit("storageMap/changeDimension", {
                        dimension: dim,
                        newDimension: {
                            width: this.dimensionsForm.width,
                            height: this.dimensionsForm.height,
                            depth: this.dimensionsForm.depth,
                            color: this.dimensionsForm.inputColor
                        }
                    });
                }
            },
            newDimension: function () {
                this.$store.commit('storageMap/addDimension', {
                    dimension: {
                        width: 1,
                        height: 1,
                        depth: 1,
                        color: "#a3c7c6" // Color that represents the dimension - in app, user inputs this in color picker
                    }
                });
                this.selectedDim = this.getDimensions.length > 0
                    ? this.getDimensions.length - 1
                    : 0;
            },
            removeDim: function (dimensionIndex) {
                this.$store.commit('storageMap/removeDimension', {
                    dimension: this.getDimensions[dimensionIndex],
                });
                if (this.selectedDim === dimensionIndex) {
                    this.selectedDim = undefined;
                }
                    
            },
            applyDim: function (dimensionIndex) {
                if (this.getRackSelectionAttributes.amount !== null) {
                    this.$store.commit('storageMap/applyDimension', {
                        dimension: this.getDimensions[dimensionIndex],
                    });
                }
                else {
                    this.snack('map.error.applyDimensionsToNull');
                }
            },
            closeAdvancedPanel: function () {
                this.showAdvanced = false;
                this.panel = RackPanel.GENERAL;
            },
        }
    };
</script>

<style scoped  lang="sass">

.dimensionColorIco
  height: 15px
  border: 0.3px solid var(--v-mapFont-base)

</style>
