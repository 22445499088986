const Scope = {
    CLIENTS: 'auth:manage:clients',
    CLIENTS_PROTECTED: 'auth:manage:clients:protected',
    SCOPES: 'auth:manage:scopes',
    USERS: 'auth:manage:users',
    CHIEF: 'ROLE_CHIEF',
    CUSTOMER: 'ROLE_CUSTOMER',
    STOREKEEPER: 'ROLE_STOREKEEPER',
    PACKER: 'ROLE_PACKER',
    ORGANIZER: 'ROLE_ORGANIZER',
    ESHOP: 'ROLE_ESHOP',
};

export {Scope};
