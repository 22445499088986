<template>
  <v-card
    class="rackWindow"
    draggable="false"
  >
    <div
      v-if="!loading"
      class="fullTable"
      draggable="false"
    >
      <div>
        <table
          class="rackTable"
          draggable="false"
          @mouseleave="mouseLeaveMapAreaEvent()"
          @mouseup.left="mouseUpCellEvent()"
        >
          <tr>
            <td
              class="numberGuideCell"
            />
            <td
              v-for="(symbol, g) in columnSymbolArray"
              :key="g"
              class="numberGuideCell numberGuideCellTop"
            />
          </tr>
          <tr
            v-for="(shelf, i) in rack"
            :key="i"
          >
            <td
              class="numberGuideCell numberGuideCellLeft"
            >
              {{ shelfSymbolArray[i] }}
            </td>
            <td
              v-for="(col, j) in shelf"
              :key="j"
              class="cell"
              :class="overrideClass([i, j])"
              :style="'background-color: ' + col.color"
              draggable="false"
              @mouseenter="mouseEnterCellEvent([i, j])"
              @mousedown.left="mouseDownCellEvent([i, j])"
            >
              <p
                v-for="(name, k) in col.names"
                :key="k"
              >
                {{ name }}
              </p>
            </td>
            <td
              class="numberGuideCell numberGuideCellRight"
            />
          </tr>
          <tr>
            <td
              class="numberGuideCell"
            />
            <td
              v-for="(symbol, i) in columnSymbolArray"
              :key="i"
              class="numberGuideCell numberGuideCellBottom"
            >
              {{ symbol }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
    import {getRackColumns, nextInSequence} from "@/service/Map.js";
    import {MapObjectType, RackNamingSymbols} from "@/enum/stock_map";
    import {MapMixin} from "@/app/mixins/MapMixin";
    export default {
        name: "RackView",
        mixins: [MapMixin],
        data: () => ({
            mouseOverCoords: [-1, -1],  // coords of latest mouseover
            mouseDownCoords: [0, 0],  // coords of latest mousedown
            mouseUpCoords: [0, 0],    // coords of latest mouseup
            mouseDown: false,
        }),
        computed: {
            rack() {
                return this.$store.getters["storageMap/renderRack"];
            },
            // Get array of column symbols to use as labels for the table.
            columnSymbolArray() {
                let array = [];
                if (this.selectedObject !== null) {
                    if (this.selectedObject.type === MapObjectType.RACK) {
                        let colSymbol = this.selectedObject.naming.symbols.find(symbol => symbol.namingSymbol === RackNamingSymbols.COLUMN)?.startingSymbol;
                        for (let i = 0; i < getRackColumns(this.selectedObject); ++i) {
                            array.push(colSymbol);
                            colSymbol = nextInSequence(colSymbol, 1, colSymbol);
                        }
                        if (this.selectedObject.naming.numberColsFromRight) {
                            array = array.reverse();
                        }
                    }
                }
                return array;
            },
            // Get array of column symbols to use as labels for the table.
            shelfSymbolArray() {
                let array = [];
                if (this.selectedObject !== null) {
                    if (this.selectedObject.type === MapObjectType.RACK) {
                        let shelfSymbol = this.selectedObject.naming.symbols.find(symbol => symbol.namingSymbol === RackNamingSymbols.SHELF)?.startingSymbol;
                        for (let i = 0; i < this.selectedObject.shelves; ++i) {
                            array.push(shelfSymbol);
                            shelfSymbol = nextInSequence(shelfSymbol, 1, shelfSymbol);
                        }
                        if (!this.selectedObject.naming.numberShelvesFromUp) {
                            array = array.reverse();
                        }
                    }
                }
                return array;
            },
        },
        watch: {
            mouseOverCoords: {
                handler: function () {
                    if (this.mouseDown) {
                        this.updateSelectedCoords();
                    }
                },
                deep: true,
            },
            mouseDownCoords: {
                handler: function () {
                    this.updateSelectedCoords();
                },
                deep: true,
            },
            mouseUpCoords: {
                handler: function () {
                    this.updateSelectedCoords();
                },
                deep: true,
            },
        },
        createdOrActivated: function() {
            this.$store.commit('storageMap/setRackSelection', {
                tl: [0,0],
                br: [0,0]
            });
        },
        methods: {
            overrideClass(cell) {
                if (this.isCellSelected(cell)) {
                    return 'cellSelected';
                }
                return '';
            },
            updateSelectedCoords() {
                const newCoords = [[], []];
                if (this.mouseDown) {
                    if (this.mouseOverCoords[0] > this.mouseDownCoords[0]) {
                        this.$set(newCoords[0], 0, this.mouseDownCoords[0]);
                        this.$set(newCoords[1], 0, this.mouseOverCoords[0]);
                    }
                    else {
                        this.$set(newCoords[0], 0, this.mouseOverCoords[0]);
                        this.$set(newCoords[1], 0, this.mouseDownCoords[0]);
                    }

                    if (this.mouseOverCoords[1] > this.mouseDownCoords[1]) {
                        this.$set(newCoords[0], 1, this.mouseDownCoords[1]);
                        this.$set(newCoords[1], 1, this.mouseOverCoords[1]);
                    }
                    else {
                        this.$set(newCoords[0], 1, this.mouseOverCoords[1]);
                        this.$set(newCoords[1], 1, this.mouseDownCoords[1]);
                    }

                    this.$store.commit('storageMap/setRackSelection', {
                        tl: newCoords[0],
                        br: newCoords[1]
                    });
                }
                else {
                    if (this.mouseUpCoords[0] > this.mouseDownCoords[0]) {
                        this.$set(newCoords[0], 0, this.mouseDownCoords[0]);
                        this.$set(newCoords[1], 0, this.mouseUpCoords[0]);
                    }
                    else {
                        this.$set(newCoords[0], 0, this.mouseUpCoords[0]);
                        this.$set(newCoords[1], 0, this.mouseDownCoords[0]);
                    }

                    if (this.mouseUpCoords[1] > this.mouseDownCoords[1]) {
                        this.$set(newCoords[0], 1, this.mouseDownCoords[1]);
                        this.$set(newCoords[1], 1, this.mouseUpCoords[1]);
                    }
                    else {
                        this.$set(newCoords[0], 1, this.mouseUpCoords[1]);
                        this.$set(newCoords[1], 1, this.mouseDownCoords[1]);
                    }

                    this.$store.commit('storageMap/setRackSelection', {
                        tl: newCoords[0],
                        br: newCoords[1]
                    });
                }
            },
            mouseLeaveMapAreaEvent: function () {
                if (this.mouseDown) {
                    // Do the mouseUp - use the latest mouse overed cell before cursor left map
                    this.mouseUpCoords = this.mouseOverCoords;
                    this.mouseDown = false;
                }
                // Once mouse leaves map, no cell is selected - reset mouseOverCoords
                this.mouseOverCoords = [-1, -1];
            },
            // Triggers when hovering over a cell on map
            mouseEnterCellEvent: function (coords) {
                this.mouseOverCoords = coords;
            },
            // Triggers when pressing mouse on a cell
            mouseDownCellEvent: function (coords) {
                this.mouseDownCoords = coords;
                this.mouseDown = true;
            },
            // Triggers when releasing mouse in entire table
            mouseUpCellEvent: function () {
                // this.updateSelection();
                if (this.mouseDown) {
                    this.mouseDown = false;
                    this.mouseUpCoords = this.mouseOverCoords;
                }
            },
            // Is a cell of the rack table inside the selected area of cells?
            isCellSelected: function(cell) {
                const selection = this.$store.getters["storageMap/getSelectedRackArea"];

                if (cell[0] >= selection.tl[0] && cell[0] <= selection.br[0]) {
                    return cell[1] >= selection.tl[1] && cell[1] <= selection.br[1];
                }
                return false;
            },
        }
    };
</script>

<style scoped lang="sass">

.rackWindow
  height: 60vh
  overflow-y: auto
  overflow-x: auto

  display: flex
  margin: auto
  user-select: none !important

.cell
  border: 1px dashed var(--v-rackCellBorder-base)
  height: 48px
  min-width: 70px
  background-color: var(--v-mapViewBackground-base)
  text-align: center
  user-select: none !important
  transition: background-color 0.3s

.cellSelected
  border: 3px dashed var(--v-primary-base) !important

.rackTable
  user-select: none !important

.fullTable
  user-select: none !important
  margin: auto
  display: flex
  align-items: flex-start

.numberGuideCell
  color: var(--v-mapFont-base)
  height: 48px
  min-width: 70px
  text-align: center

.numberGuideCellRight
  border-left: 1px solid var(--v-rackCellBorder-base)

.numberGuideCellLeft
  border-right: 1px solid var(--v-rackCellBorder-base)

.numberGuideCellTop
  border-bottom: 1px solid var(--v-rackCellBorder-base)

.numberGuideCellBottom
  border-top: 1px solid var(--v-rackCellBorder-base)

</style>
