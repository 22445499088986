<template>
  <v-card
    class="mx-1 pa-2 pt-3"
    :outlined="orders.length === 0"
  >
    <span class="text-caption">{{ $t('tasks.preparePackage.orders.label') }} ({{ orders.length }}):</span>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="{external_order, shipment} of orders"
          :key="external_order.id"
          :color="(shipment.state === ShipmentState.TO_BE_CANCELLED || shipment.state === ShipmentState.CANCELLED) ? 'red' : ''"
          :class="(shipment.state === ShipmentState.TO_BE_CANCELLED || shipment.state === ShipmentState.CANCELLED) ? 'v-list-item--active' : ''"
          @click="openOrder(external_order.id)"
        >
          <v-list-item-content class="ma-1 pb-1">
            <v-list-item-title>
              <strong>#{{ external_order.external_order_id || external_order.id }}</strong>
              <span class="text-caption"> | {{ shipment.carrier.name }}</span>
            </v-list-item-title>
            <i>{{ external_order.external_note }}</i>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {ShipmentState} from "@/enum/shipment_state";

    export default {
        name: "PreparePackageOrdersList",
        mixins: [RouteParamsMapperMixin],
        props: {
            orders: {
                type: Array,
                default: () => ([])
            }
        },
        data: () => ({
            ShipmentState: ShipmentState
        }),
        methods: {
            openOrder: function (orderId) {
                const order = this.orders.find(order => order.external_order.id === orderId);
                if (order.shipment.state === ShipmentState.TO_BE_CANCELLED || order.shipment.state === ShipmentState.CANCELLED) {
                    this.snack('tasks.preparePackage.orders.cancelled');
                    return;
                }
                this.$emit('open-order', orderId);
            }
        }
    };
</script>

<style scoped>

</style>
