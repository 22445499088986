import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
import {taskTypes} from "@/enum/task_type";

const BarcodeListenerMixin = {
    mixins: [TaskStateMixin, TaskAssignMixin],
    methods: {
        activateTab: function (data) {
            if (this.isInProgress && this.assignedToCurrentUser && this.type !== taskTypes.EXTERNAL_ORDER) {
                if (this.tab !== 1) {
                    this.tab = 1;
                }
            } else if (this.assignable && this.type !== taskTypes.STOCK_TAKING) {
                // TODO called twice on barcode read
                // prevent infinite loop if there is conflict during task assign
                if (!this.conflict) {
                    this.assign()
                        .then(() => {
                            this.tab = 1;
                            window.onBarcodeRead(data);
                        });
                }
            } else if (this.type === taskTypes.STOCK_TAKING && this.assignedToCurrentUser && this.isCreated) {
                this.startProcessing()
                    .then(() => {
                        this.tab = 1;
                        window.onBarcodeRead(data);
                    });
            }
        }
    }
};

export {BarcodeListenerMixin};
