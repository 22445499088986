<template>
  <div>
    <FabSpacer />
    <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      v-bind="$attrs"
      :class="{raised : isRaised}"
      @click="click"
    >
      <slot name="content" />
    </v-btn>
  </div>
</template>

<script>
    import FabSpacer from "@/app/components/FabSpacer.component";

    export default {
        // eslint-disable-next-line
        name: "x-btn-fab",
        components: {FabSpacer},
        props: {
            click: {
                type: Function,
                default: () => () => {}
            }
        },
        computed: {
            isRaised: function () {
                return this.$vuetify.breakpoint.xs && this.$store.getters['snackbar/isVisible'];
            }
        }
    };
</script>

<style scoped lang="sass">
    .raised
        bottom: 60px
</style>
