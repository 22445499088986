import {
    DeliveryType,
    DeliveryNumber,
    InvoiceNumber,
    Note,
    SupplierId,
    ExternalOrderTaskId
} from "@/app/tasks/definitions/taskCommon.render.form";
import {TaskPriority} from "@/enum/task_priority";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import {StockLoadingCreateRender} from "@/app/tasks/stockLoading/definitions/stockLoadingCreate.form";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

function DeliveryAcceptApproveForm() {
    return {
        stock_loading_priority: TaskPriority.NORMAL,
        stock_loading_preferred_location: TaskPreferredLocation.LAST,
        stock_loading_description: null,
        stock_loading_stock_id: null,
        stock_loading_subordinate_stock_id: null,
        stock_loading_strict_mode: TaskItemsStrictMode.FREE,
        delivery_type: null,
        delivery_number: null,
        invoice_number: null,
        supplier_id: null,
        external_order_task_id: null,
        hours: null,
        note: null
    };
}


function DeliveryAcceptApproveNewTaskRender() {
    const StockLoadingCreateRenderInstance = new StockLoadingCreateRender();
    return {
        stock_loading_priority: StockLoadingCreateRenderInstance.priority,
        stock_loading_stock_id: StockLoadingCreateRenderInstance.stock_id,
        stock_loading_subordinate_stock_id: StockLoadingCreateRenderInstance.subordinate_stock_id,
        stock_loading_preferred_location: StockLoadingCreateRenderInstance.preferred_location,
        stock_loading_strict_mode: StockLoadingCreateRenderInstance.strict_mode,
        stock_loading_description: StockLoadingCreateRenderInstance.description
    };
}

function DeliveryAcceptApproveRender() {
    return {
        delivery_type: new DeliveryType,
        delivery_number: new DeliveryNumber,
        invoice_number: new InvoiceNumber,
        supplier_id: new SupplierId,
        external_order_task_id: new ExternalOrderTaskId,
        note: new Note
    };
}

export {DeliveryAcceptApproveForm, DeliveryAcceptApproveNewTaskRender, DeliveryAcceptApproveRender};
