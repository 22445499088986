<template>
  <div
    class="mapWindow"
  >
    <div
      v-if="loading && !saving"
      class="mt-2"
    >
      <v-progress-linear
        indeterminate
        color="primary"
      />
    </div>
    <table
      v-if="!loading || saving"
      style="user-select: none; border-spacing: 0;"
    >
      <tr
        v-for="(row, indexY) of render.map"
        :key="indexY"
      >
        <td
          v-for="(cell, indexX) of row"
          :key="indexY * 100 + indexX"
          :colspan="cell.width"
          :rowspan="cell.height"
        >
          <MapCell
            :debounced-zoom="debouncedZoom"
            :cell="cell"
            :show-heat-map="showHeatMap"
            @cellClick="mouseClickCellEvent"
          />
        </td>
      </tr>
    </table>
    <MapCreateObjectDialog
      v-if="newObjectDialog"
      :activate="newObjectDialog"
      :new-object-cell="newObjectCell"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
    import { MapObjectType, PanelMode } from '@/enum/stock_map';
    import MapCell from "@/app/stocks/map/components/map-view/MapCell.component.vue";
    import MapCreateObjectDialog from "@/app/stocks/map/components/dialogs/MapCreateObjectDialog.component.vue";
    import {MapMixin} from "@/app/mixins/MapMixin";
    import {SnackbarMixin} from "@/app/mixins/SnackbarMixin";
    export default {
        name: 'MapView',
        components: {MapCreateObjectDialog, MapCell},
        mixins: [ MapMixin, SnackbarMixin ],
        props: {
            debouncedZoom: {
                type: Number,
                default: 0
            },
            showHeatMap: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            newObjectCell: null,
            newObjectDialog: false,
        }),
        computed: {
            // Keep getting the table to be rendered
            table: function () {
                return this.$store.getters['storageMap/render'];
            },
        },
        methods: {
            closeDialog: function () {
                this.newObjectDialog = false;
                this.newObjectCell = null;
            },
            displayActionCollisionError: function() {
                this.snack('map.cannotFinishActionDueToCollision');
            },
            mouseClickCellEvent: function (data) {

                const zones = this.render.zones;
                const cell = data.cell;
                const zoneClick = data.zoneClick;
                const x = data.coordinates.x;
                const y = data.coordinates.y;
                const zone = this.getZoneOnThisPosition(cell,x,y,zones);

                if (this.panelMode === PanelMode.STANDBY) {
                    if (zoneClick && zone != null) {
                        this.$store.commit('storageMap/selectObject', {
                            object: zone
                        });
                    }
                    else if (cell.object !== null) {
                        if ([MapObjectType.RACK, MapObjectType.OBSTACLE, MapObjectType.LOCATIONS_GROUP].includes(cell.object.type)) {
                            this.$store.commit('storageMap/selectObject', {
                                object: cell.object
                            });
                        }
                    }
                    else {
                        this.$store.commit('storageMap/selectObject', {
                            object: null
                        });
                    }
                }
                else if (this.panelMode === PanelMode.MOVE) {
                    !this.$store.dispatch('storageMap/moveObject', {
                        object: this.selectedObject,
                        newTl: [y, x]
                    }).then(()=>{
                        if (this.panelMode === PanelMode.MOVE) {
                            this.displayActionCollisionError();
                        }
                    });
                }
                else if (this.panelMode === PanelMode.COPY) {
                    const object = this.selectedObject;
                    this.$store.dispatch('storageMap/copyObject', {
                        object: this.selectedObject,
                        newTl: [y, x]
                    }).then(()=>{
                        if (object === this.selectedObject) {
                            this.displayActionCollisionError();
                        }
                    });
                }
                else if ([PanelMode.NEW_RACK, PanelMode.NEW_OBSTACLE, PanelMode.NEW_LOCATIONS_GROUP].includes(this.panelMode)){
                    if (cell.object === null) {
                        this.newObjectCell = [y, x];
                        this.newObjectDialog = true;
                    }
                    else {
                        this.displayActionCollisionError();
                    }
                }
                else if (this.panelMode === PanelMode.NEW_ZONE) {
                    if (!this.isZonePresentOnThisPosition(cell, x, y, this.render.zones)) {
                        this.newObjectCell = [y, x];
                        this.newObjectDialog = true;
                    }
                    else {
                        this.displayActionCollisionError();
                    }
                }
            },
        }
    };
</script>

<style scoped lang="sass">

.mapWindow
  height: 50vh
  overflow: auto
  background-color: var(--v-mapViewBackground-base) !important

</style>
