<template>
  <div>
    <x-toolbar />
    <v-card>
      <CarriersListComponent />
    </v-card>
    <v-expansion-panels
      class="mt-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('carriers.run.job') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn
            v-for="option of options"
            :key="option.label"
            class="mr-2"
            color="accent"
            :disabled="option.disabledBy"
            @click="option.action"
          >
            {{ $t('carriers.run.' + option.label) }}
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <x-btn-fab :to="carrierCreateLink">
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import CarriersListComponent from "@/app/carriers/components/CarriersList.component";

    export default {
        name: "CarriersList",
        components: {CarriersListComponent},
        data: () => ({
            carrierCreateLink: '/carriers/create',
            validateRunning: false,
            protocolsRunning: false,
            trackRunning: false
        }),
        computed: {
            options: function () {
                return [{
                    label: 'validatePackages',
                    action: this.runValidatePackages,
                    disabledBy: this.validateRunning
                }, {
                    label: 'generateProtocols',
                    action: this.runGenerateProtocols,
                    disabledBy: this.protocolsRunning
                }, {
                    label: 'trackPackages',
                    action: this.runTrackPackages,
                    disabledBy: this.trackRunning
                }];
            }
        },
        activated: function () {
            this.validateRunning = false;
            this.protocolsRunning = false;
            this.trackRunning = false;
        },
        methods: {
            runValidatePackages: function () {
                this.validateRunning = true;
                ShipmentCarrierAPI.runValidatePackages().catch(this.snack);
            },
            runGenerateProtocols: function () {
                this.protocolsRunning = true;
                ShipmentCarrierAPI.runGenerateProtocols().catch(this.snack);
            },
            runTrackPackages() {
                this.trackRunning = true;
                ShipmentCarrierAPI.runTrackPackages().catch(this.snack);
            }
        }
    };
</script>

<style scoped>

</style>
