<template>
  <v-card
    class="mx-1 pa-2 pt-3 orderItemsLoader"
    :loading="loading"
    :outlined="shownItems.length === 0"
  >
    <span
      class="text-caption"
    >
      <v-select
        v-if="toggle"
        v-model="selectedType"
        :items="toggleItems"
        dense
        outlined
        hide-details
      />
      <span v-else>{{ $t('tasks.preparePackage.items.type.' + selectedType) }}:</span>
    </span>
    <v-list>
      <v-card
        v-for="item of (shownItems)"
        :key="item.id + '-' + selectedType"
        flat
        style="background-color: rgba(0, 0, 0, 0)"
      >
        <PreparePackageOrderItem
          :item="item"
          :item-instance="instanceDetails[item.product_instance_id]"
          :type="selectedType"
          @select="$emit('input', item.product_instance_id)"
        />
      </v-card>
      <span
        v-if="!shownItems || shownItems.length === 0"
        key="nothing"
      >
        <v-list-item class="text--disabled">
          <v-icon class="mb-1 mx-2">
            $nothing
          </v-icon>
          {{ $t('base.nothing') }}
        </v-list-item>
      </span>
    </v-list>
  </v-card>
</template>

<script>
    import PreparePackageOrderItem from "@/app/tasks/preparePackage/components/PreparePackageOrderItem.component";
    import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";

    export default {
        name: "PreparePackageOrderItems",
        components: {PreparePackageOrderItem},
        props: {
            items: {
                type: Array,
                default: () => ([])
            },
            loading: {
                type: Boolean,
                default: false
            },
            instanceDetails: {
                type: Object,
                default: () => ({})
            },
            toggle: {
                type: Boolean,
                default: false
            },
            type: {
                type: Number,
                default: PreparePackageItemListTypes.ALL
            }
        },
        data: () => ({
            selectedType: PreparePackageItemListTypes.ALL,
            PreparePackageItemListTypes: PreparePackageItemListTypes
        }),
        computed: {
            toggleItems: function () {
                return Object.values(PreparePackageItemListTypes)
                    .map(type => ({
                        text: this.$t('tasks.preparePackage.items.type.' + type),
                        value: type
                    }));
            },
            shownItems: function () {
                switch (this.selectedType) {
                case PreparePackageItemListTypes.TODO:
                    return this.items.filter(item => item.quantity_ordered > item.quantity_packed);
                case PreparePackageItemListTypes.DONE:
                    return this.items.filter(item => item.quantity_packed > 0);
                default:
                    return this.items;
                }
            }
        },
        created: function () {
            this.selectedType = this.type;
        }
    };
</script>

<style lang="sass">
.orderItemsLoader .v-progress-linear--absolute
    position: unset
    opacity: 0.10

.orderItemsLoader .v-card__progress
    height: 0
</style>
