<template>
  <div>
    <v-dialog
      v-model="showDialog"
      width="600"
    >
      <v-card>
        <v-card-text class="pt-5">
          <v-container fluid>
            <div class="d-flex flex-column align-center">
              <div class="d-flex align-center">
                <v-text-field
                  ref="quantityInput"
                  v-model="newQuantity"
                  outlined
                  hide-details
                  type="number"
                  min="1"
                  step="1"
                  class="centered-input"
                  @input="inputChanged"
                  @keydown.stop="event => event.key === 'Enter' ? updateQuantity() : undefined"
                />
              </div>
              <div v-if="hasReturnOptions">
                <v-chip-group
                  v-model="returnCard"
                  mandatory
                  column
                  active-class="primary--text"
                >
                  <v-chip
                    v-for="option of returnOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ $t('tasks.stockLoading.returnTo.' + option) }}
                  </v-chip>
                </v-chip-group>
              </div>
              <v-btn
                :color="quantityChanged ? 'accent' : 'default'"
                class="mt-2"
                @click="updateQuantity"
              >
                <v-icon>
                  $saveItem
                </v-icon>
                <span
                  class="ml-1"
                >
                  {{ $t('base.save') }}
                </span>
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card v-if="hasChangeQuantityOption">
      <span
        v-if="sourceLocationId && $vuetify.breakpoint.xsOnly"
        class="d-flex justify-center"
      >
        {{ LocationCache[sourceLocationId] | locationLabel }}
      </span>
      <v-flex>
        <v-btn-toggle :value="[]">
          <v-btn
            v-if="sourceLocationId && $vuetify.breakpoint.smAndUp"
            outlined
            color="primary"
            :ripple="false"
            class="v-btn--active"
          >
            {{ LocationCache[sourceLocationId] | locationLabel }}
          </v-btn>
          <v-btn
            v-for="option of changeByPositiveOptions"
            :key="option"
            class="quantityIncrease"
            :disabled="disableOption(option)"
            outlined
            @click="changeQuantity(option)"
          >
            {{ (option > 0 ? '+' : '') + option }}
          </v-btn>
          <v-btn
            @click="toggleShowInput()"
          >
            <v-icon>
              $itemQuantityChange
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
    import {EventBus} from "@/service/EventBus";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {changeByPositiveOptions} from "@/app/tasks/definitions/changeByPositiveOptions";
    import {taskTypes} from "@/enum/task_type";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";

    export default {
        name: "TaskItemDetailQuantity",
        mixins: [EventsListenerMixin, TaskTypeMixin, ReactiveLocationCacheMixin],
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            locationId: {
                type: Number,
                default: null
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            taskStrictMode: {
                type: String,
                default: TaskItemsStrictMode.FREE
            },
            totalPickedQuantity: {
                type: Number,
                default: 0
            },
            inventoryEmpty: {
                type: Boolean,
                default: true
            },
            sourceLocationId: {
                type: Number,
                default: null
            },
            taskShippingType: {
                type: String,
                default: TaskShippingType.COURIER
            }
        },
        data: () => ({
            quantity: 0,
            newQuantity: 0,
            showDialog: false,
            listenForChange: false,
            changeByPositiveOptions: changeByPositiveOptions,
            taskTypes: taskTypes,
            TaskItemsStrictMode: TaskItemsStrictMode,
            quantityChangedByEmit: false,
            EventBus: EventBus,
            returnCard: TaskItemsCardType.IN_INVENTORY
        }),
        computed: {
            events: function () {
                return {
                    'taskItems-quantitiesLoaded': this.startListeningForChange,
                    'bad-quantity-entered': this.onBadQuantityEntered
                };
            },
            hasChangeQuantityOption: function () {
                if (this.isType(taskTypes.STOCK_TAKING)) {
                    return true;
                }
                for (const option of changeByPositiveOptions) {
                    if (!this.disableOption(option)) {
                        return true;
                    }
                }
                return !this.disableOption(-1);
            },
            activeLocationPickedQuantity: function () {
                if (this.sourceLocationId) {
                    return Math.min(this.totalPickedQuantity, this.item?.source_locations
                        ?.find(loc => loc.stock_location_id === this.sourceLocationId)?.pick_quantity || 0);
                } else {
                    return this.totalPickedQuantity;
                }
            },
            quantityChanged: function () {
                return this.newQuantity !== this.quantity;
            },
            hasReturnOptions: function () {
                return this.isType(taskTypes.STOCK_LOADING) && this.locationId !== null && this.newQuantity < this.quantity;
            },
            returnOptions: function () {
                return [TaskItemsCardType.TO_MOVE, TaskItemsCardType.IN_INVENTORY];
            }
        },
        watch: {
            totalPickedQuantity: function () {
                this.resetQuantity();
            },
            sourceLocationId: function () {
                this.resetQuantity();
            },
            quantity: function (newValue) {
                if ((this.isType(taskTypes.STOCK_TAKING) || this.totalPickedQuantity !== 0) && this.listenForChange && !this.quantityChangedByEmit) {
                    EventBus.$emit('update-quantity', {
                        itemId: this.item.id,
                        quantity: newValue + this.totalPickedQuantity - this.activeLocationPickedQuantity,
                        locationId: this.locationId,
                        returnCard: this.returnCard
                    });
                } else {
                    this.quantityChangedByEmit = false;
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.quantity = this.activeLocationPickedQuantity;
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                if (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.STOCK_TAKING, taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                    // ignore quantity change due to initial set
                    this.$nextTick(() => this.listenForChange = true);
                }
            }
        },
        methods: {
            startListeningForChange: function () {
                this.listenForChange = true;
            },
            resetQuantity: function () {
                this.listenForChange = false;
                this.quantity = this.activeLocationPickedQuantity;
                this.$nextTick(() => this.listenForChange = true);
            },
            onBadQuantityEntered: function (itemId) {
                // multiple components might be active on same page, have to check if they are for same item
                if (itemId !== this.item.id) {
                    return;
                }
                this.quantityChangedByEmit = true;
                this.$nextTick(() => {
                    this.quantity = this.activeLocationPickedQuantity;
                });
            },
            changeQuantity: function (changeBy) {
                this.quantity += changeBy;
            },
            updateQuantity: function () {
                if (this.quantityChanged) {
                    this.quantity = this.newQuantity;
                }
                this.toggleShowInput();
            },
            toggleShowInput: function () {
                this.newQuantity = this.quantity;
                this.showDialog = !this.showDialog;
                if (this.showDialog) {
                    this.$nextTick(() => {
                        const input = this.$refs['quantityInput'];
                        input.focus();
                        input.$refs.input.select();
                    });
                }
            },
            inputChanged: function () {
                this.newQuantity = Number.parseInt(this.newQuantity, 10);
                if (this.newQuantity === 0 || isNaN(this.newQuantity)) {
                    this.newQuantity = 1;
                }
                if (this.newQuantity < 1) {
                    this.newQuantity = Math.abs(this.newQuantity);
                }
            },
            disableOption: function (option) {
                if (this.quantity + option < 1) {
                    return true;
                }
                // stock taking or free modes
                if (this.isType(taskTypes.STOCK_TAKING)
                    || (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.MOVE_PRODUCTS])
                        && this.taskStrictMode === TaskItemsStrictMode.FREE)) {
                    return false;
                }
                // in inventory or moving directly from source to destination (in stock loading)
                // when task is stock loading, user can move right to destination when inventory is empty (therefore check for empty inventory)
                if (this.locationId === null || (this.isType(taskTypes.STOCK_LOADING) && this.inventoryEmpty)) {
                    if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET, taskTypes.SUBSTOCK_TRANSFER])) {
                        if (option < 1) {
                            // trying to return to source location
                            const sourceLocation = this.item.source_locations.find(srcLoc => srcLoc.stock_location_id === this.sourceLocationId);
                            if (!sourceLocation) {
                                return true;
                            }
                            return sourceLocation.pick_quantity < option
                                || (this.isType(taskTypes.SUBSTOCK_TRANSFER)
                                    ? this.item.quantity_in_source_user_inventory < -option : false);
                        } else {
                            // trying to pick more from location
                            const itemLocation = this.item.locations.find(loc => loc.stock_location.id === this.sourceLocationId);
                            if (!itemLocation) {
                                return true;
                            }
                            return itemLocation.quantity < option
                                || (this.taskShippingType === TaskShippingType.COURIER ? this.item.quantity_in_user_inventory : 0)
                                    + option + this.item.processed_quantity > this.item.quantity_to_move;
                        }
                    }
                    return this.item.quantity_in_user_inventory + option + this.item.processed_quantity > this.item.quantity_to_move;
                }
                // on destination
                return this.item.quantity_in_user_inventory - option < 0;
            }
        }
    };
</script>

<style scoped>

</style>
