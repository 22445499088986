<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedOwner !== null"
      :show.sync="confirmDialog"
      :text="personInLine(deletedOwner)"
      @confirm-delete="reallyDeleteOwner"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :actions="actions"
      >
        <template #item.ico="{ item }">
          <TableItemAresValidated :item="item" />
        </template>
        <template #footer>
          <TableAddItemButton
            :to="stockOwnerCreateLink"
            label="stocks.owners.create.titleSimple"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="stockOwnerCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {StockAPI as API} from "@/api/StockAPI";
    import {createHeaders} from "@/utils/table";
    import {ownerTable} from "@/app/stocks/owners/definitions/owner.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import TableItemAresValidated from "@/app/components/table/TableItemAresValidated.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {personInLine} from "@/utils/string";

    export default {
        name: "StockOwnersList",
        components: {TableItemAresValidated, TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            items: [],
            stockOwnerCreateLink: '/stocks/owners/create',
            deletedOwner: null,
            confirmDialog: false,
            personInLine: personInLine
        }),
        computed: {
            headers: () => createHeaders(ownerTable, 'stocks.owners.', true),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/stocks/owners/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        routerLink: item => '/stocks/owners/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                API.getAllOwners()
                    .then(response => {
                        this.items = response.data;
                    }).catch(() => {
                        this.snack('stocks.owners.list.unableToLoad');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            deleteItem: function (item) {
                this.deletedOwner = item;
                this.confirmDialog = true;
            },
            reallyDeleteOwner: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.deleteOwner(this.deletedOwner.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'stocks.owners.delete.done',
                            params: [this.deletedOwner.name]
                        });
                    }).catch(this.snack)
                    .finally(this.getData);
            }
        }
    };
</script>

<style scoped>

</style>
