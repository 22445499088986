import {
    BuyerId,
    StockId,
    SubordinateStockId,
    TaskDescription,
    TaskPriority
} from "@/app/tasks/definitions/taskCommon.render.form";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {TaskShippingType} from "@/enum/task_shipping_type";

function StockPickingCreateForm(shipping_type = TaskShippingType.PERSONAL_COLLECTION) {
    return {
        priority: TaskPriorityEnum.NORMAL,
        description: null,
        stock_id: null,
        subordinate_stock_id: null,
        shipping_type: shipping_type,
        buyer_id: null,
        destination_stock_location_id: null,
        attachments: [],
        items: []
    };
}

function StockPickingCreateRender(shipping_type = TaskShippingType.PERSONAL_COLLECTION) {
    const ret = {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: new SubordinateStockId,
        ...(shipping_type === TaskShippingType.PERSONAL_COLLECTION && {buyer_id: BuyerId(false)}),
        // hardcoded by shipping_type
        // shipping_type: {
        //     icon: 'local_shipping',
        //     required: true,
        //     readonly: true,
        //     select: Object.values(TaskShippingType).map(type => ({
        //             text: i18n.t('tasks.stockPicking.type.' + type),
        //             value: type
        //         }))
        // },
        description: new TaskDescription,
    };
    if (shipping_type === TaskShippingType.COURIER) {
        ret.destination_stock_location_id = {
            readonly: false,
            icon: '$location',
            autocomplete: {
                items: []
            },
            hint: 'tasks.stockPicking.destination_stock_location_id_hint',
        };
    }
    return ret;
}

export {StockPickingCreateForm, StockPickingCreateRender};
