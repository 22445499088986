<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedType !== null"
      :show.sync="confirmDialog"
      :text="deletedType.name"
      @confirm-delete="reallyDeleteType"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :show-search-bar="false"
      >
        <template #footer>
          <TableAddItemButton
            :to="buyerTypeCreateLink"
            label="buyers.types.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="buyerTypeCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {BuyerTypeAPI} from "@/api/BuyerTypeAPI";
    import {createHeaders} from "@/utils/table";
    import {buyerTypeTable} from "@/app/buyers/types/definitions/buyerType.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "BuyerTypesList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: false,
            items: [],
            confirmDialog: false,
            buyerTypeCreateLink: '/buyers/types/create',
            deletedType: null,
            reload: 0
        }),
        computed: {
            actions: function () {
                return [
                    {
                        routerLink: item => '/buyers/types/' + item.id + '/update',
                        icon: "$updateItem",
                        label: 'base.edit'
                    }, {
                        condition: item => !item.default,
                        action: this.deleteItem,
                        icon: '$deleteItemForever',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return BuyerTypeAPI.getAll.bind(BuyerTypeAPI);
            },
            apiDataSourceAllPages: function () {
                return BuyerTypeAPI.getAllPages.bind(BuyerTypeAPI);
            },
            headers: function () {
                return createHeaders(buyerTypeTable, 'buyers.types.');
            }
        },
        methods: {
            deleteItem: function (item) {
                this.deletedType = item;
                this.confirmDialog = true;
            },
            reallyDeleteType: function () {
                this.loading = true;
                this.confirmDialog = false;
                BuyerTypeAPI.delete(this.deletedType.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'buyers.types.delete.done',
                            params: [this.deletedType.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.reload++;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
