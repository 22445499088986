import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 d-flex"},[_c(VTextField,{staticClass:"priceInput",attrs:{"dense":"","label":_vm.priceLabel,"outlined":"","type":"number","step":"0.001","min":"0","rules":[_vm.formRules.nonNegativeNumber]},model:{value:(_vm.itemPrice),callback:function ($$v) {_vm.itemPrice=$$v},expression:"itemPrice"}}),_c(VSelect,{staticClass:"vatSelect ml-1",attrs:{"dense":"","hide-details":"","items":_vm.vatRateItems,"label":_vm.$t('products.price_vat'),"outlined":""},on:{"change":function($event){_vm.priceChanged = true}},model:{value:(
      /* eslint-disable-next-line vue/no-mutating-props */
      _vm.prices[_vm.item.id].vat
    ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
      _vm.prices[_vm.item.id], "vat", $$v)},expression:"\n      /* eslint-disable-next-line vue/no-mutating-props */\n      prices[item.id].vat\n    "}}),_c(VBtn,{staticClass:"ml-2",attrs:{"color":_vm.priceChanged ? 'accent' : 'default',"disabled":_vm.itemPrice < 0},on:{"click":_vm.updatePrice}},[_c(VIcon,[_vm._v(" $saveItem ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }