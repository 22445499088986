import {Roles} from "@/enum/roles";
import AttributeTemplatesList from "@/app/attributes/templates/components/AttributeTemplatesList.view.vue";
import AttributeTemplateCreateUpdate
    from "@/app/attributes/templates/components/AttributeTemplateCreateUpdate.view.vue";

const BASE_URL = '/attribute-templates';

const routes = {
    base: {
        path: BASE_URL,
        component: AttributeTemplatesList,
        meta: {
            title: 'base.attributes.templates.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },
    attributesCreate: {
        path: BASE_URL + '/create',
        component: AttributeTemplateCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'base.attributes.templates.create',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    attributesUpdate: {
        path: BASE_URL + '/:attributeTemplateId(\\d+)/update',
        component: AttributeTemplateCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'base.attributes.templates.update.link',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },

    fallback: {
        path: BASE_URL + '/*',
        beforeEnter: (to, from, next) => {
            // TODO snack message
            next(BASE_URL);
        }
    }
};

export default Object.values(routes);
