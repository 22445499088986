import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('map.selectedLocations'))+" ")]),_c(VCol,{staticClass:"mt-2",staticStyle:{"text-align":"right"}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{on:{"click":function($event){return _vm.unselectCells()}}},[_vm._v(" $close ")])],1)],1)],1)],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('map.cellCount'))+" ")]),_c(VCol,[_vm._v(" "+_vm._s(_vm.getRackSelectionAttributes.cellsAmount)+" ")])],1),_c(VRow,{staticClass:"pt-4 pb-3 text-h6"},[_vm._v(" "+_vm._s(_vm.$t('map.automaticLocationEdit'))+" ")]),_c(VRow,[_c(VCol,{staticClass:"pt-6",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('map.locationCount'))+" ")]),_c(VCol,{staticClass:"pt-0 pl-0 pr-6"},[_c(VForm,{ref:"locationAmountForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('FormFields',{attrs:{"form":_vm.locationAmountForm,"render":_vm.locationAmountFormRender,"lang-path":"map.","stretch-form":""}})],1)],1)],1)],1),(_vm.getRackSelectionAttributes.cellsAmount === 1)?_c('div',[_c('div',{staticClass:"mb-5 text-h6"},[_vm._v(" "+_vm._s(_vm.$t('map.manualLocationEdit'))+" ")]),_c('EditLocationsForm',{attrs:{"locations":_vm.getRackSelectionAttributes.locations},on:{"addLocation":_vm.addNewLocationToCell,"removeLocation":_vm.removeLocationFromLocationGroup}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }