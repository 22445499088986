import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c(VBtn,{staticClass:"ml-3 mr-2 mt-4",class:{'elevation-8': _vm.edit},attrs:{"fab":"","outlined":"","small":""},on:{"click":_vm.toggleEdit}},[_c(VIcon,[_vm._v(" $updateItem ")])],1),_c('div',{staticClass:"ma-2"},[(!_vm.edit)?_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$te('homepage.' + _vm.item.props.label) ? _vm.$t('homepage.' + _vm.item.props.label) : _vm.item.props.label)+" "),_vm._l((_vm.icons),function(icon,index){return _c('span',{key:index},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(icon.if)?_c(VIcon,_vm._g({staticClass:"mx-1 text--disabled"},on),[_vm._v(" "+_vm._s(icon.icon)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(icon.label, [icon.param])))])])],1)})],2),(_vm.item.type === 'taskList')?[_c('div',[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('base.filterConfig.filter'))+": ")]),_vm._v(" "+_vm._s(_vm.filterLabel(_vm.langPath, _vm.item.props.filter, _vm.renderValues))+" ")]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('base.filterConfig.sort'))+": ")]),_vm._v(" "+_vm._s(_vm.sortLabel(_vm.langPath, _vm.item.props.sort))+" ")])]:_vm._e(),(_vm.item.type === 'ordersStats')?[_c('div',[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('stocks.substocks.label'))+": ")]),_vm._l((_vm.item.props.subStockIds),function(ssId){return _c(VChip,{key:ssId},[_vm._v(" "+_vm._s(_vm.subStockName(ssId))+" ")])}),(!_vm.item.props.subStockIds || _vm.item.props.subStockIds.length === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('homepage.config.all'))+" ")]):_vm._e()],2)]:_vm._e()],2):_c('div',[_c('div',{staticClass:"d-flex flex-nowrap",class:{'flex-column': _vm.$vuetify.breakpoint.smAndDown}},[_c(VTextField,{staticClass:"mr-10",attrs:{"label":_vm.$t('homepage.config.label'),"prepend-icon":"$itemName","rules":[_vm.formRules.required]},model:{value:(
            /* eslint-disable-next-line vue/no-mutating-props */
            _vm.item.props.label
          ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
            _vm.item.props, "label", $$v)},expression:"\n            /* eslint-disable-next-line vue/no-mutating-props */\n            item.props.label\n          "}}),_c('div',{staticClass:"mr-10"},[_c('span',[_vm._v(_vm._s(_vm.$t('homepage.config.showOn'))+":")]),_c(VCheckbox,{staticClass:"ml-3",attrs:{"color":"secondary","label":_vm.$t('homepage.config.desktop'),"hide-details":"","dense":""},model:{value:(
              /* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.showOnDesktop
            ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
              _vm.item, "showOnDesktop", $$v)},expression:"\n              /* eslint-disable-next-line vue/no-mutating-props */\n              item.showOnDesktop\n            "}}),_c(VCheckbox,{staticClass:"ml-3",attrs:{"color":"secondary","label":_vm.$t('homepage.config.mobile'),"hide-details":"","dense":""},model:{value:(
              /* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.showOnMobile
            ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
              _vm.item, "showOnMobile", $$v)},expression:"\n              /* eslint-disable-next-line vue/no-mutating-props */\n              item.showOnMobile\n            "}})],1),_c('div',[_c(VCheckbox,{attrs:{"color":"secondary","label":_vm.$t('homepage.config.autoRefresh')},model:{value:(
              /* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.props.autoRefresh
            ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.props, "autoRefresh", $$v)},expression:"\n              /* eslint-disable-next-line vue/no-mutating-props */\n              item.props.autoRefresh\n            "}}),(_vm.item.props.autoRefresh)?_c(VTextField,{attrs:{"type":"number","rules":[_vm.formRules.positiveInteger],"label":_vm.$t('homepage.config.autoRefreshValue', [_vm.item.props.autoRefreshIn])},model:{value:(
              /* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.props.autoRefreshIn
            ),callback:function ($$v) {_vm.$set(/* eslint-disable-next-line vue/no-mutating-props */
              _vm.item.props, "autoRefreshIn", $$v)},expression:"\n              /* eslint-disable-next-line vue/no-mutating-props */\n              item.props.autoRefreshIn\n            "}}):_vm._e()],1)],1),_c(VDivider,{staticClass:"my-2"}),(_vm.item.type === 'taskList')?[_c('DashboardTaskListConfig',{attrs:{"item":_vm.item,"possible-values":_vm.possibleValues}})]:_vm._e(),(_vm.item.type === 'ordersStats')?[_c('DashboardOrdersStatsConfig',{attrs:{"item":_vm.item}})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }