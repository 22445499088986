<template>
  <div>
    <template v-if="details && ready">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') + ': ' }}</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') + ': ' }}</span>
                {{ details.priority | taskPriority }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') + ': ' }}</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') + ': ' }}</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <v-divider
                class="my-2"
              />
              <div>
                <span class="text-caption">{{ $t('tasks.stockTaking.stock_id') + ': ' }}</span>
                {{ details.stock.name }}
              </div>
              <div>
                <span class="text-caption">{{
                  $t('tasks.stockTaking.restrict_references') + ': '
                }}</span>
                {{ $t('tasks.stockTaking.restrictions.' + details.restrict_type) + ': ' }}
                <span>
                  {{ restrictedLabel }}
                </span>
              </div>
              <div v-show="details.description">
                <span class="text-caption">{{ $t('tasks.description') + ': ' }}</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :stock-id="details.stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-show="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
      <TaskItemsCard
        v-if="isChief"
        :api="API"
        :card-type="TaskItemsCardType.ASSIGNMENT"
        :items="items"
        :items-not-ready-yet="isCreated"
        :task-info="taskInfo"
        task-lang-path="stockTaking."
      />
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import EntityHistory from "@/app/components/EntityHistory.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import {TaskStockTakingRestriction as RestrictionType} from "@/enum/task_stock_taking_restriction";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskStockTakingAPI as API} from "@/api/TaskStockTakingAPI";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {mapReduce} from "@/utils/array";
    import {instanceLabel} from "@/utils/filters";
    import {ProductAPI} from "@/api/ProductAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {taskTypes} from "@/enum/task_type";

    export default {
        name: "StockTakingAssignment",
        components: {TaskAttributes, TaskAttachments, Notes, EntityHistory, TaskItemsCard},
        mixins: [TaskStateMixin, TaskFetchItemsMixin, ACLMixin, EventsListenerMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            TaskItemsCardType: TaskItemsCardType,
            API: API,
            RestrictionType: RestrictionType,
            instanceLabel: instanceLabel,
            loadingProducts: false,
            type: taskTypes.STOCK_TAKING
        }),
        computed: {
            events: function () {
                return {
                    'task-started': this.loadItems.bind(this, true),
                };
            },
            details: function () {
                return this.taskInfo.details;
            },
            restrictType: function () {
                return this.details.restrict_type;
            },
            restrictReferences: function () {
                return this.details.restrict_references;
            },
            restrictedLabel: function () {
                if (!this.restrictReferences || this.restrictReferences.length === 0 || this.loadingProducts) {
                    return '-';
                } else {
                    return this.restrictReferences
                        .map(el => this.restrictType === RestrictionType.PRODUCT_INSTANCE ? instanceLabel(el) : el.name)
                        .join(', ');
                }
            }
        },
        watch: {
            restrictReferences: {
                immediate: true,
                handler: function () {
                    if (this.restrictType === RestrictionType.PRODUCT_INSTANCE) {
                        this.loadInstanceProducts();
                    }
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.loadItems(lifeCycleHook === this.LifeCycleHook.CREATED);
        },
        methods: {
            loadItems(initial = false) {
                if (initial) {
                    this.fetchItems({initial: true}).then(() => {
                        this.items = this.items.filter(item => item.expected_amount !== 0);
                        this.items = mapReduce(this.items, ['product_instance_id'], 'expected_amount');
                        this.ready = true;
                    }).catch(this.snack);
                } else {
                    this.fetchItems().catch(this.snack);
                }
            },
            loadInstanceProducts: function () {
                this.loadingProducts = true;
                const productIds = [...new Set(this.restrictReferences.map(instance => instance.product_id))];
                ProductAPI.getAllPages({
                    filter: APIFilters.makeFilter({
                        [APIFilterOP.IN]: {
                            'id': productIds
                        }
                    })
                }).then(response => {
                    const products = response.data.items;
                    this.restrictReferences.forEach(instance => {
                        instance.product = products.find(prod => prod.id === instance.product_id);
                    });
                }).catch(this.snack)
                    .finally(() => this.loadingProducts = false);
            }
        }
    };
</script>

<style scoped>

</style>
