import {Store} from "@/service/store/Store";
import Axios from "axios";
import {config} from "@/config/config";

/**
 * We have to use Axios because UserAPI depends on BaseAPI which is not yet initialized when the Store is loaded.
 */
const fetchUserConfig = () => {
    Axios.get(config.API_URL + '/users/' + Store.getters['oauth/getUserId'] + '/settings')
        .then(response => {
            Store.dispatch('userConfig/replaceConfig', response.data);
        })
        .catch(error => {
            Store.commit('snackbar/set', {text: error});
        });
};

const storeUserConfig = (key, value) => {
    if (Store.getters['oauth/isAuthenticated']) {
        Axios.put(config.API_URL + '/users/' + Store.getters['oauth/getUserId'] + '/settings/' + key,{value})
            .catch(() => {
                Axios.post(config.API_URL + '/users/' + Store.getters['oauth/getUserId'] + '/settings', {key, value})
                    .catch(error => {
                        Store.commit('snackbar/set', {text: error});
                    });
            });
    }
};

export {fetchUserConfig, storeUserConfig};
