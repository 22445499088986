import Vue from "vue";
import VueGtm from "vue-gtm";
import {Router} from "@/service/router/Router";

/**
 * @src https://www.npmjs.com/package/vue-gtm
 */
function useGoogleTagManager() {
    Vue.use(VueGtm, {
        id: "GTM-WN43WPF",
        defer: false,
        compatibility: false,
        nonce: "2726c7f26c",
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: Router,
        ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
        trackOnNextTick: false
    });
}

export {useGoogleTagManager};
