export const productFabActions = (productId, instanceId, can_have_batch, can_have_serial_number) => {
    const addImage = {
        icon: '$addImage',
        label: 'base.images.create.titleSimple',
        to: '/products/' + productId + '/images/create'
    };
    const addCustomInstance = {
        icon: '$productInstance',
        label: 'products.customInstances.create.titleSimple',
        to: '/products/' + productId + '/custom-instances/create'
    };
    const addSerial = {
        icon: '$serialNumber',
        label: 'products.serial_numbers.create.titleSimple',
        to: '/products/' + productId + '/serial-numbers/create'
    };
    const addBatch = {
        icon: '$addBatch',
        label: 'products.batches.create.titleSimple',
        to: '/products/' + productId + '/batches/create'
    };
    const addAttribute = {
        icon: '$addAttribute',
        label: 'products.attributes.create.titleSimple',
        to: '/products/' + productId + '/attributes/create'
    };
    const addBarcode = {
        icon: '$addBarcode',
        label: 'products.instances.barcodes.create.titleSimple',
        to: `/products/${productId}/instance/${instanceId}/barcode/create`
    };

    if (!can_have_batch) {
        addBatch.disabled = true;
    }

    if (!can_have_serial_number) {
        addSerial.disabled = true;
    }

    if(instanceId === null) {
        addBarcode.disabled = true;
    }

    return [
        addImage,
        addCustomInstance,
        addSerial,
        addBatch,
        addAttribute,
        addBarcode
    ];
};
