<template>
  <v-autocomplete
    ref="autocomplete"
    outlined
    dense
    hide-details
    :label="$t(langPath + 'chooseAttr')"
    :value="value"
    :items="items"
    v-on="{...$listeners, input: propertyChanged }"
    @blur="reSelectValue"
  >
    <template #item="{ parent, item }">
      <v-list-item-icon class="mr-3">
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <!-- eslint-disable-next-line vue/no-v-html vue/no-v-text-v-html-on-component -->
        <v-list-item-title v-html="getItemHtml(parent.genFilteredText(item.text))" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
    export default {
        name: "AutocompleteWithIcons",
        props: {
            langPath: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: undefined
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getItemHtml: function (filteredText) {
                if (typeof filteredText === "string") {
                    return filteredText;
                } else {
                    const [ start, middleVNode, end ] = filteredText;
                    return `${ start }<span class="v-list-item__mask">${ middleVNode.children[0].text }</span>${ end }`;
                }
            },
            reSelectValue: function () {
                if (this.value !== undefined) {
                    this.$refs.autocomplete.selectItem(this.value);
                }
            },
            propertyChanged: function (newValue) {
                if (newValue !== null && newValue !== this.value) {
                    this.$emit('input', newValue);
                }
            }
        }
    };
</script>

<style scoped>

</style>