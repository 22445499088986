<template>
  <div>
    <Alert
      :show-alert="showAutoMoveAlert"
      :display-text="$t('tasks.stockPicking.autoMoveInProgress')"
    />
    <Alert
      :show-alert="showMovingAllAlert"
      :display-text="$t('tasks.itemsCard.moveEverythingInProgress')"
    />
    <Alert
      v-if="showChooseTargetLocationAlert"
      :show-alert="showChooseTargetLocationAlert"
      type="warning"
    >
      <template #content>
        <StockPickingTargetPicker
          :task-info="taskInfo"
          :api="api"
          :inverted-colors="true"
        />
      </template>
    </Alert>
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser && !taskInfo.details.parent_task_id"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      :show-scan-confirmation="showScanConfirmation"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetActiveLocationId"
    />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-flex
        v-for="card of cardTypes"
        :key="card.type"
        xs12
        :class="'md' + 12 / cardTypes.length "
      >
        <TaskItemsCard
          :active-location-id="activeLocationId"
          :api="api"
          :card-type="card.type"
          :items="card.items"
          items-update-emit="fetch-items"
          :prices="prices"
          :task-info="taskInfo"
          task-lang-path="stockPicking."
          :is-destination-scanned="isDestinationScanned"
          :highlight-single-location="highlightSingleLocation(card.type)"
          @moveAll="moveAllFromInventory"
          @return-item="onReturnItem"
        >
          <template
            v-if="card.type === TaskItemsCardType.IN_INVENTORY && isDirectHandover"
            #cardTitle="{ itemQuantity }"
          >
            {{ $t('tasks.stockPicking.pickedToHandover') }} ({{ itemQuantity }})
          </template>
        </TaskItemsCard>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import StockPickingTargetPicker from "@/app/tasks/stockPicking/components/StockPickingTargetPicker.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import {CodeType} from "@/enum/code_type";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {scrollTo} from "@/service/Vuetify";
    import {readerFeedback} from "@/utils/readerFeedback";
    import Alert from "@/app/components/Alert.component";
    import {TaskSetTargetLocationMixin} from "@/app/mixins/TaskSetTargetLocationMixin";
    import {MoveAllMixin} from "@/app/mixins/MoveAllMixin";
    import {UpdateQuantityMixin} from "@/app/mixins/UpdateQuantityMixin";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";

    export default {
        name: "StockPickingPicking",
        components: {TaskConflict, StockPickingTargetPicker, TaskBarcodeReader, TaskItemsCard, Alert},
        mixins: [
            TaskStateMixin,
            TaskAssignMixin,
            TaskFetchItemsMixin,
            EventsListenerMixin,
            TaskSetTargetLocationMixin,
            MoveAllMixin,
            ReactiveLocationCacheMixin,
            UpdateQuantityMixin
        ],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            api: {
                type: Object,
                default: () => TaskStockPickingAPI
            }
        },
        data: () => ({
            ready: false,
            items: [],
            prices: {},
            TaskItemsCardType: TaskItemsCardType,
            activeLocationId: null,
            autoMoveTriggered: false,
            showAutoMoveAlert: false,
            showMovingAllAlert: false,
            conflict: null
        }),
        computed: {
            API: function () {
                return this.api;
            },
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'update-price': this.updateSellPrice,
                    'taskItems-quantitiesLoaded': this.moveAlreadyCorrectItems,
                    'update-quantity': this.updateQuantity,
                    'task-movement-conflict': cnflct => this.conflict = cnflct
                };
            },
            isDirectHandover: function () {
                return this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION;
            },
            showChooseTargetLocationAlert: function () {
                return !this.isDirectHandover && this.taskInfo.details.target_location_id === null && !this.taskInfo.details.parent_task_id;
            },
            itemsToMove: function () {
                return this.items.filter(el => el.quantity_to_move - ((this.isDirectHandover ? 0 : el.quantity_in_user_inventory) + el.processed_quantity) > 0);
            },
            itemsInInventory: function () {
                return this.items.filter(el => el.quantity_in_user_inventory !== 0);
            },
            itemsMoved: function () {
                return this.items.filter(el => el.processed_quantity !== 0);
            },
            cardTypes: function () {
                const ret = [{
                    type: TaskItemsCardType.TO_MOVE,
                    items: this.itemsToMove
                }, {
                    type: TaskItemsCardType.IN_INVENTORY,
                    items: this.itemsInInventory
                }];
                if (!this.isDirectHandover) {
                    ret.push({
                        type: TaskItemsCardType.MOVED,
                        items: this.itemsMoved
                    });
                }
                return ret;
            },
            scannerMode: function () {
                if (this.activeLocationId !== null) {
                    if (this.activeLocationId !== this.taskInfo.details.target_location_id) {
                        return scannerModes.SOURCE;
                    } else {
                        return scannerModes.DESTINATION;
                    }
                }
                return scannerModes.IDLE;
            },
            isDestinationScanned: function () {
                return this.scannerMode === scannerModes.DESTINATION;
            },
            highlightSingleLocation: function () {
                return cardType => {
                    switch (cardType) {
                    case TaskItemsCardType.MOVED:
                        return this.scannerMode === scannerModes.DESTINATION;
                    case TaskItemsCardType.IN_INVENTORY:
                        return this.scannerMode !== scannerModes.IDLE;
                    default:
                        return false;
                    }
                };
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.fetchItems({initial: true})
                    .then(() => {
                        this.computePrices();
                        this.ready = true;
                    }).catch(this.snack);
            } else {
                this.fetchItems().catch(this.snack);
                this.autoMoveTriggered = false;
            }
            this.conflict = null;
        },
        methods: {
            onFetchItems: function (callback) {
                this.fetchItems()
                    .then(callback)
                    .catch(this.snack);
            },
            showScanConfirmation: function (locationToCheckId) {
                return this.taskInfo.details.target_location_id !== null && locationToCheckId !== this.taskInfo.details.target_location_id;
            },
            computePrices: function () {
                this.items.forEach(item => {
                    this.$set(this.prices, item.id, {
                        price: item.sell_price_per_unit,
                        vat: Number.parseFloat(item.price_vat)
                    });
                });
            },
            moveAlreadyCorrectItems: function () {
                if (this.autoMoveTriggered || !this.isInProgress) {
                    return;
                }
                this.autoMoveTriggered = true;
                const targetLocationId = this.taskInfo.details.target_location_id;
                const promises = [];
                this.items.forEach(item => {
                    if (item.locations !== undefined) {
                        const location = item.locations.find(location => location.stock_location.id === targetLocationId);
                        if (location !== undefined && item.destination_location) {
                            const quantity = Math.min(location.quantity - item.destination_location.quantity, item.quantity_to_move - (item.quantity_in_user_inventory + item.processed_quantity));
                            if (quantity > 0) {
                                promises.push(new Promise((resolve, reject) => {
                                    this.api.pickUpFromSource(this.taskInfo.taskId, item.id, targetLocationId, quantity)
                                        .then(() => {
                                            this.api.putToDestination(this.taskInfo.taskId, item.id, targetLocationId, quantity)
                                                .then(resolve)
                                                .catch(reject);
                                        })
                                        .catch(reject);
                                }));
                            }
                        }
                    }
                });
                if (promises.length > 0) {
                    this.showAutoMoveAlert = true;
                    Promise.all(promises)
                        .then(() => {
                            this.conflict = null;
                            this.fetchItems()
                                .then(() => {
                                    this.showAutoMoveAlert = false;
                                });
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 409) {
                                this.conflict = err.response.data;
                            } else {
                                this.snack(err);
                            }
                        });
                }
            },
            updateSellPrice: function (payload, callback) {
                this.api.updateSellPrice(this.taskInfo.taskId, payload.itemId, payload.price, payload.vat)
                    .then(() => {
                        this.fetchItems({debounceQuantities: true})
                            .then(() => {
                                this.computePrices();
                            })
                            .catch(this.snack);
                        callback();
                    })
                    .catch(this.snack);
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    if (this.scannerMode === scannerModes.IDLE) {
                        this.readingFail('tasks.itemsCard.scanLocation');
                    } else {
                        this.tryToMove(barcodeInfo.object_id, barcodeInfo.quantity * quantity);
                    }
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    if (!this.isDirectHandover && this.taskInfo.details.target_location_id === null && !this.taskInfo.details.parent_task_id) {
                        this.setTargetLocation(barcodeInfo.code);
                    }
                    this.changeActiveLocation(barcodeInfo);
                    this.$nextTick(() => {
                        if (this.activeLocationSide === 'pick') {
                            scrollTo('taskItemsCard-' + TaskItemsCardType.TO_MOVE);
                        } else if (this.activeLocationSide === 'put' && !this.isDirectHandover) {
                            scrollTo('taskItemsCard-' + TaskItemsCardType.MOVED);
                        }
                    });
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                this.readingFail();
            },
            tryToMove: function (instanceId, quantity) {
                let promiseCallback = () => Promise.resolve();
                if (this.scannerMode === scannerModes.SOURCE) {
                    const item = this.itemsToMove.find(item => item.product_instance_id === instanceId);
                    if (item) {
                        promiseCallback = () => this.api.pickUpFromSource(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                    } else {
                        ProductAPI.getDirectProductIdForInstance(instanceId).then(productId => {
                            const foundInstance = this.itemsToMove.find(item => item.instance.product.id === productId);
                            foundInstance ? this.readingFail('tasks.stockPicking.lookForAnotherCode') : this.readingFail('tasks.stockPicking.notToBeMoved');
                        }).catch(() => this.readingFail());
                        return;
                    }
                } else {
                    const item = this.itemsInInventory.find(item => item.product_instance_id === instanceId);
                    if (item) {
                        if ((item.processed_quantity + quantity <= item.quantity_to_move)) {
                            if (this.showChooseTargetLocationAlert) {
                                this.readingFail('tasks.stockPicking.chooseTargetLocation');
                                return;
                            }
                            promiseCallback = () => this.api.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                        } else {
                            this.advancedSnack({
                                text: 'tasks.itemsCard.updateQuantity.destinationLimit',
                                params: [item.processed_quantity + quantity, item.quantity_to_move]
                            });
                            this.readingFail();
                            return;
                        }
                    } else {
                        ProductAPI.getDirectProductIdForInstance(instanceId).then(productId => {
                            const foundInstance = this.itemsInInventory.find(item => item.instance.product.id === productId);
                            foundInstance ? this.readingFail('tasks.stockPicking.lookForAnotherCode') : this.readingFail('tasks.stockPicking.notInInventory');
                        }).catch(() => this.readingFail());
                        return;
                    }
                }
                this.sendUpdatedQuantity().then(() => {
                    promiseCallback().then(() => {
                        readerFeedback.success();
                        this.conflict = null;
                        this.fetchItems({debounceQuantities: true, onlyInstanceId: instanceId})
                            .catch(this.snack)
                            .finally(this.readingDoneNoVibrate);
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            this.conflict = err.response.data;
                            // send empty message to stop barcode loading
                            this.readingFail('');
                        } else {
                            this.readingFail(err);
                        }
                    });
                }).catch(() => this.readingFail());
            },
            resetActiveLocationId: function () {
                this.activeLocationId = null;
            },
            changeActiveLocation: function (barcodeLocation) {
                this.activeLocationId = barcodeLocation.object_id;
                this.readingDone();
            }
        }
    };
</script>

<style scoped>

</style>
