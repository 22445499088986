<template>
  <div>
    <x-data-table
      :headers="tableHeaders"
      :items="items"
      :loading="loading"
      :item-class="item => item.unavailable === true && 'warning--text'"
    >
      <template #item.toBePicked="{ item }">
        <template v-if="item.available !== 0 && item.satisfied_quantity < item.quantity">
          <v-edit-dialog
            :key="'toBePicked' + item.id"
            @save="savePickedQuantity(item)"
            @close="savePickedQuantity(item)"
            @open="editedPickQuantity = item.toBePicked"
          >
            {{ item.toBePicked }}
            <v-progress-circular
              v-if="item.toBePicked === undefined || item.toBePicked === null"
              indeterminate
              size="16"
              class="ml-1"
            />
            <v-icon
              v-else
              small
            >
              $updateItem
            </v-icon>
            <template #input>
              <v-text-field
                v-model="editedPickQuantity"
                type="number"
                min="0"
                step="1"
                :rules="[formRules.required]"
              />
              <v-flex class="mb-4">
                <v-btn-toggle>
                  <v-btn
                    v-for="option of changeByOptions"
                    :key="option"
                    :class="option < 0 ? 'quantityDecrease' : 'quantityIncrease'"
                    :disabled="disableOption(item, option)"
                    outlined
                    @click="editedPickQuantity = +editedPickQuantity + option"
                  >
                    {{ (option > 0 ? '+' : '') + option }}
                  </v-btn>
                </v-btn-toggle>
              </v-flex>
            </template>
          </v-edit-dialog>
        </template>
        <template v-else>
          {{ item.toBePicked }}
        </template>
      </template>
    </x-data-table>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import {createHeaders} from "@/utils/table";
    import {externalOrderTable, externalOrderAssignedTable} from "@/app/tasks/externalOrder/definitions/externalOrder.table";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import formRules from "@/utils/formRules";
    import {changeByOptions} from "@/app/tasks/definitions/changeByOptions";
    import {TaskExternalOrderProcessingModeMixin} from "@/app/mixins/TaskExternalOrderProcessingModeMixin";

    export default {
        name: "ExternalOrderOrder",
        mixins: [TaskStateMixin, TaskAssignMixin, EventsListenerMixin, TaskExternalOrderProcessingModeMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            loading: false,
            prices: {},
            API: API,
            formRules: formRules,
            changeByOptions: changeByOptions,
            editedPickQuantity: null,
            reservedQuantityAdded: false,
        }),
        createdOrActivated: function () {
            this.reservedQuantityAdded = false;
        },
        computed: {
            tableHeaders: function () {
                if (this.assignedToCurrentUser && this.anyItemsLeftToBeProcessed && this.isProcessAvailable) {
                    return createHeaders(externalOrderAssignedTable, 'tasks.externalOrder.', false);
                }
                return createHeaders(externalOrderTable, 'tasks.externalOrder.', false);
            },
            events: function () {
                return {
                    'item-unavailable': this.handleItemUnavailable
                };
            },
            anyItemsLeftToBeProcessed: function () {
                return this.items.some(item => item.quantity > item.satisfied_quantity);
            }
        },
        watch: {
            items: {
                handler: function () {
                    this.items
                        .map(item => {
                            // setting availability
                            if (!this.reservedQuantityAdded && item.reserved_quantity > 0 && !this.isClosed) {
                                // adding reserved quantity to available once
                                this.$set(item, 'available', item.available + item.reserved_quantity);
                                this.reservedQuantityAdded = true;
                            }
                            const remainingQuantity = item.quantity - item.satisfied_quantity;
                            this.$set(item, 'toBePicked', Math.min(remainingQuantity, item.available));
                            const unavailable = item.quantity - item.satisfied_quantity > item.available;
                            this.$set(item, 'unavailable', unavailable);
                            return item;
                        })
                        .map(item => {
                            // setting labels
                            let label = '';
                            if (item.instance) {
                                label = this.$options.filters.instanceLabel(item.instance, item.product);
                            } else if (item.product) {
                                label = this.$options.filters.productLabel(item.product);
                                if (item.product_instance_type_id) {
                                    label += ` (${item.product_instance_type_name || '...'})`;
                                }
                            }
                            this.$set(item, 'name', label);
                            return item;
                        })
                        .filter(item => item.product_instance_type_id)
                        .forEach(item => {
                            // setting product instance type name
                            ProductAPI.getCustomInstanceType(item.product_instance_type_id)
                                .then(response => {
                                    this.$set(item, 'product_instance_type_name', response.data.name);
                                }).catch(this.snack);
                        });
                }
            }
        },
        methods: {
            handleItemUnavailable: function (itemId) {
                const item = this.items.find(item => item.id === itemId);
                if (item) {
                    this.$set(item, 'unavailable', true);
                }
            },
            savePickedQuantity: function (item) {
                const remainingQuantity = item.quantity - item.satisfied_quantity;
                if (this.editedPickQuantity < 0 || this.editedPickQuantity > remainingQuantity || this.editedPickQuantity > item.available) {
                    return;
                }
                if (isNaN(this.editedPickQuantity)) {
                    return;
                }
                item.toBePicked = this.editedPickQuantity;
            },
            disableOption: function (item, option) {
                const remainingQuantity = item.quantity - item.satisfied_quantity;
                const toBePickedWithOption = +this.editedPickQuantity + option;
                if (toBePickedWithOption < 0) {
                    return true;
                }
                if (option < 0) {
                    return false;
                }
                return toBePickedWithOption > item.available || toBePickedWithOption > remainingQuantity;
            }
        }
    };
</script>

<style scoped>

</style>
