import {AddressForm} from "@/app/components/form/definitions/address.form";
import {UserAPI} from "@/api/UserAPI";
import {TableFilter} from "@/enum/table_filter";
import i18n from "@/service/lang/i18n";

function BuyerAddressForm() {
    return {
        privacy: null,
        default: false,
        address: new AddressForm
    };
}

function BuyerAddressRender() {
    return {
        privacy: {
            icon: '$buyerAddressPrivacy',
            autocomplete: {
                callFn: () => UserAPI.getAll(),
                thenFn: response => [
                    ...[{
                        text: i18n.t('buyers.addresses.public'),
                        value: null
                    }],
                    ...response.data.map(el => ({
                        text: (el.first_name + ' ' + el.last_name + ' (' + el.username + ')'),
                        value: el.id
                    }))
                ]
            }
        },
        default: {
            icon: '$buyerAddressDefault',
            checkbox: true
        }
    };
}

const buyerAddressTable = {
    default: {
        filterType: TableFilter.BOOLEAN
    },
    privacy: {}
};

export {BuyerAddressForm, BuyerAddressRender, buyerAddressTable};
