import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"column":"","align-center":""}},[_c(VFlex,{staticClass:"text-caption"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.titleIcon)?_c(VIcon,{staticClass:"mr-2 mb-1"},[_vm._v(" "+_vm._s(_vm.titleIcon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.' + _vm.titleLangPath))+" ")],1)]),_c(VFlex,{staticClass:"mx-2"},[_c(VLayout,{attrs:{"align-center":"","wrap":"","justify-center":""}},[(_vm.locations === undefined)?[_c(VChip,{attrs:{"label":"","outlined":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"16","width":"2"}})],1)]:[_c('WrappedChipList',{attrs:{"items":_vm.locations,"cache-many-callback":_vm.type === 'put' || _vm.type === 'picked' ? _vm.cacheLocationMany.bind(this, _vm.stockId) : undefined,"get-item-id":_vm.type === 'picked' ? itm => itm.stock_location_id : undefined},scopedSlots:_vm._u([{key:"default",fn:function({item: location}){return [(_vm.type === 'put')?[_vm._v(" "+_vm._s(_vm._f("locationLabel")(_vm.LocationCache[location]))+" ")]:(_vm.type === 'picked')?[_vm._v(" "+_vm._s(location.pick_quantity)+"  "),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("productMeasureLabel")((_vm.item.product || _vm.item.instance.product))))]),_vm._v("  "+_vm._s(_vm._f("locationLabel")(_vm.LocationCache[location.stock_location_id]))+" ")]:[_vm._v(" "+_vm._s(location.quantity)+"  "),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("productMeasureLabel")((_vm.item.product || _vm.item.instance.product))))]),_vm._v("  "+_vm._s(_vm._f("locationLabel")(location.stock_location))+" ")]]}}])})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }