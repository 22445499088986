export const productTypeFabActions = () => [
    {
        icon: '$productType',
        label: 'products.types.create.title',
        to: '/products/types/create'
    },
    {
        icon: '$productInstanceType',
        label: 'products.instances.types.create.title',
        to: '/products/instance-types/create'
    }
];
