import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.deletedInstance !== null)?_c('ConfirmDeleteDialog',{attrs:{"show":_vm.confirmDialog,"text":_vm.instanceType === _vm.InstanceType.SERIAL_NUMBER ? _vm.deletedInstance.serial_number : _vm.deletedInstance.code},on:{"update:show":function($event){_vm.confirmDialog=$event},"confirm-delete":_vm.reallyDeleteInstance}}):_vm._e(),_c(VExpansionPanels,{attrs:{"value":0}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.header))+" ")]),(_vm.instanceType === 'CUSTOM')?_c(VTooltip,{staticClass:"ml-2",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c(VIcon,[_vm._v("$info")])],1)]}}],null,false,2914527458)},[_c('span',[_vm._v(_vm._s(_vm.$t('products.customInstances.hint')))])]):_vm._e()],1)]),_c(VExpansionPanelContent,[_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload},on:{"update:loading":function($event){_vm.loading=$event},"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(_vm._f("instanceType")(item.type)))])]}},{key:"item.manufactured_date",fn:function({ item }){return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.manufactured_date,"abs-date-first":""}})]}},{key:"item.expire_date",fn:function({ item }){return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.expire_date,"abs-date-first":""}})]}},{key:"footer",fn:function(){return [_c('TableAddItemButton',{attrs:{"to":_vm.instanceCreateLink,"label":_vm.instanceCreateLabel}})]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }