<template>
  <div>
    <Alert
      :show-alert="showMovingAllAlert"
      :display-text="$t('tasks.itemsCard.moveEverythingInProgress')"
    />
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      :show-scan-confirmation="isLocationSource"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetActiveLocationId"
    />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-flex
        v-for="card of cardTypes"
        :key="card.type"
        xs12
        :class="'md' + 12 / cardTypes.length "
      >
        <TaskItemsCard
          :active-location-id="activeLocationId"
          :active-location-side="activeLocationSide"
          :api="API"
          :card-type="card.type"
          :items="card.items"
          items-update-emit="fetch-items"
          :task-info="taskInfo"
          task-lang-path="moveProducts."
          :is-destination-scanned="isDestinationScanned"
          :highlight-single-location="highlightSingleLocation(card.type)"
          @moveAll="moveAllFromInventory"
          @return-item="onReturnItem"
        >
          <template #items-location-header="{ locationId }">
            <TaskItemsLocationHeaderWithAllowedItems
              :location-id="locationId"
              :allowed-instances="allowedInstances[locationId]"
              :allowed-locations-loaded="allowedLocationsLoaded"
              :card-type="card.type"
              :num-of-instances="items.length"
            />
          </template>
        </TaskItemsCard>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {TaskMoveProductsAPI as API} from "@/api/TaskMoveProductsAPI";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {CodeType} from "@/enum/code_type";
    import {TaskMoveProductsMixin} from "@/app/mixins/TaskMoveProductsMixin";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {scrollTo} from "@/service/Vuetify";
    import {readerFeedback} from "@/utils/readerFeedback";
    import Alert from "@/app/components/Alert.component";
    import {TaskAllowedLocationsMixin} from "@/app/mixins/TaskAllowedLocationsMixin";
    import {MoveAllMixin} from "@/app/mixins/MoveAllMixin";
    import {UpdateQuantityMixin} from "@/app/mixins/UpdateQuantityMixin";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import TaskItemsLocationHeaderWithAllowedItems
        from "@/app/tasks/components/taskItemsCard/TaskItemsLocationHeaderWithAllowedItems.component.vue";

    export default {
        name: "MoveProductsMove",
        components: {TaskItemsLocationHeaderWithAllowedItems, TaskConflict, TaskBarcodeReader, TaskItemsCard, Alert},
        mixins: [
            TaskStateMixin,
            TaskAssignMixin,
            TaskFetchItemsMixin,
            EventsListenerMixin,
            TaskMoveProductsMixin,
            TaskAllowedLocationsMixin,
            MoveAllMixin,
            UpdateQuantityMixin
        ],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            activeLocationId: null,
            activeLocationSide: 'pick', /* 'pick'|'put' */
            whitelist: [],
            API: API,
            showMovingAllAlert: false,
            conflict: null
        }),
        computed: {
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'update-quantity': this.updateQuantity,
                    'task-movement-conflict': cnflct => this.conflict = cnflct
                };
            },
            itemsToMove: function () {
                return this.items.filter(el => el.quantity_to_move - (el.quantity_in_user_inventory + el.processed_quantity) > 0);
            },
            itemsInInventory: function () {
                return this.items.filter(el => el.quantity_in_user_inventory !== 0);
            },
            itemsMoved: function () {
                return this.items.filter(el => el.processed_quantity !== 0);
            },
            cardTypes: function () {
                return [
                    {
                        type: TaskItemsCardType.TO_MOVE,
                        items: this.itemsToMove
                    }, {
                        type: TaskItemsCardType.IN_INVENTORY,
                        items: this.itemsInInventory
                    }, {
                        type: TaskItemsCardType.MOVED,
                        items: this.itemsMoved
                    }
                ];
            },
            scannerMode: function () {
                if (this.activeLocationId !== null) {
                    switch (this.activeLocationSide) {
                    case 'pick':
                        return scannerModes.SOURCE;
                    case 'put':
                        return scannerModes.DESTINATION;
                    default:
                        window.console.error('Unknown activeLocationSide');
                        return scannerModes.IDLE;
                    }
                }
                return scannerModes.IDLE;
            },
            isDestinationScanned: function () {
                return this.scannerMode === scannerModes.DESTINATION;
            },
            highlightSingleLocation: function () {
                return cardType => {
                    switch (cardType) {
                    case TaskItemsCardType.MOVED:
                        return this.scannerMode === scannerModes.DESTINATION;
                    case TaskItemsCardType.IN_INVENTORY:
                        return this.scannerMode !== scannerModes.IDLE;
                    default:
                        return false;
                    }
                };
            },
            allowedLocationsLoaded: function () {
                return !this.loadAllowedLocations
                    || !this.items.filter(item => item.allowedLocationIds === undefined).length;
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.fetchAllowedLocations() // There is race condition if these finish in the other order, but I don't know why...
                .then(() => {
                    this.fetchItems({initial: lifeCycleHook === this.LifeCycleHook.CREATED})
                        .then(() => {
                            this.ready = true;
                        }).catch(this.snack);
                }).catch(this.snack);
            this.conflict = null;
        },
        methods: {
            onFetchItems: function (callback) {
                this.fetchItems()
                    .then(callback)
                    .catch(this.snack);
            },
            isLocationSource: function (locationToCheckId) {
                return this.details.source_location !== null && this.details.source_location.id === locationToCheckId;
            },
            fetchAllowedLocations: function () {
                const subStock = this.taskInfo.details.subordinate_stock;
                return StockAPI.getAllSubstockAvailableLocationsAllPages(subStock.stock_id, subStock.id)
                    .then(response => {
                        this.whitelist = response.data.items.map(location => location.id);
                    }).catch(this.snack);
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    if (this.scannerMode === scannerModes.IDLE) {
                        this.readingFail('tasks.itemsCard.scanLocation');
                    } else {
                        this.tryToMove(barcodeInfo.object_id, barcodeInfo.quantity * quantity)
                            .then(() => {
                                if (this.loadAllowedLocations) {
                                    const item = this.items.find(item => item.product_instance_id === barcodeInfo.object_id);
                                    if (item) {
                                        this.fetchInstanceAllowedLocations(item);
                                    }
                                }
                            });
                    }
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    if (this.taskInfo.movementType === TaskMoveProductsType.RELOCATE
                        && !(barcodeInfo.object_id === this.details.source_location.id
                            || barcodeInfo.object_id === this.details.destination_location.id)
                    ) {
                        this.advancedSnack({
                            text: 'tasks.moveProducts.scanSourceOrDestLocation',
                            params: [this.sourceString, this.destString]
                        });
                        this.readingFail();
                    } else {
                        this.changeActiveLocation(barcodeInfo, quantity);
                        this.$nextTick(() => {
                            if (this.activeLocationSide === 'pick') {
                                scrollTo('taskItemsCard-' + TaskItemsCardType.TO_MOVE);
                            } else if (this.activeLocationSide === 'put') {
                                scrollTo('taskItemsCard-' + TaskItemsCardType.MOVED);
                            }
                        });
                    }
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                this.readingFail();
            },
            tryToMove: function (instanceId, quantity) {
                return new Promise((resolve) => {
                    let promiseCallback = () => Promise.resolve();
                    if (this.scannerMode === scannerModes.SOURCE) {
                        let item;
                        if (this.taskInfo.details.transfer_mode === TaskItemsStrictMode.FREE) {
                            item = this.items.find(item => item.product_instance_id === instanceId);
                        } else {
                            item = this.itemsToMove.find(item => item.product_instance_id === instanceId);
                        }
                        if (item) {
                            const alreadyUsedAsDestination = item.destination_locations
                                .find(loc => loc.stock_location_id === this.activeLocationId);
                            if (alreadyUsedAsDestination) {
                                this.readingFail('tasks.moveProducts.alreadyUsedAsDestination');
                                return;
                            } else {
                                promiseCallback = () => API.pickUpFromSource(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                            }
                        } else {
                            if (this.taskInfo.details.transfer_mode === TaskItemsStrictMode.FREE) {
                                promiseCallback = () => API.createItem(this.taskInfo.taskId, this.activeLocationId, instanceId, quantity);
                            } else {
                                this.readingFail('tasks.moveProducts.notToBeMoved');
                                return;
                            }
                        }
                    } else {
                        const item = this.itemsInInventory.find(item => item.product_instance_id === instanceId);
                        if (item) {
                            if ([TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)) {
                                if (this.allowedInstances[this.activeLocationId]
                                    && item.allowedLocationIds
                                    && !this.allowedInstances[this.activeLocationId].find(instance => instance.id === item.instance.id)) {
                                    this.readingFail('tasks.moveProducts.itemCanNotBePutOnLocation');
                                    return;
                                }
                            }
                            if (this.taskInfo.details.transfer_mode === TaskItemsStrictMode.FREE) {
                                const alreadyUsedAsSource = item.source_locations.find(loc => loc.stock_location_id === this.activeLocationId);
                                if (alreadyUsedAsSource) {
                                    this.readingFail('tasks.moveProducts.alreadyUsedAsSource');
                                    return;
                                }
                            } else if (item.processed_quantity + quantity > item.quantity_to_move) {
                                this.advancedSnack({
                                    text: 'tasks.itemsCard.updateQuantity.destinationLimit',
                                    params: [item.processed_quantity + quantity, item.quantity_to_move]
                                });
                                this.readingFail();
                                return;
                            }
                            promiseCallback = () => API.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                        } else {
                            this.readingFail('tasks.moveProducts.notInInventory');
                            return;
                        }
                    }
                    this.sendUpdatedQuantity().then(() => {
                        promiseCallback().then(() => {
                            this.conflict = null;
                            readerFeedback.success();
                            this.fetchItems({
                                debounceQuantities: true,
                                onlyInstanceId: instanceId
                            })
                                .catch(this.snack)
                                .finally(() => {
                                    this.readingDoneNoVibrate();
                                    resolve();
                                });
                        }).catch(err => {
                            if (err.response && err.response.status === 409) {
                                this.conflict = err.response.data;
                                // send empty message to stop barcode loading
                                this.readingFail('');
                            } else {
                                this.readingFail(err);
                            }
                        });
                    }).catch(() => this.readingFail());
                });
            },
            resetActiveLocationId: function () {
                this.activeLocationId = null;
            },
            changeActiveLocation: function (barcodeLocation, quantity) {
                const locationId = barcodeLocation.object_id;
                if (this.details.source_location) {
                    if (this.details.source_location.id === locationId) {
                        this.activeLocationSide = 'pick';
                    } else {
                        this.activeLocationSide = 'put';
                    }
                } else if (this.details.destination_location) {
                    if (this.details.destination_location.id === locationId) {
                        this.activeLocationSide = 'put';
                    } else {
                        this.activeLocationSide = 'pick';
                    }
                } else {
                    /// Swap between put and pick if active location = given location or if given location is given 2x
                    if (this.activeLocationId === locationId || quantity % 2 === 0) {
                        if (this.itemsInInventory.length) {
                            this.activeLocationSide = this.activeLocationSide === 'pick' ? 'put' : 'pick';
                        } else {
                            this.activeLocationSide = 'pick';
                        }
                    }
                    if (!this.whitelist.includes(locationId)) {
                        this.readingFail('tasks.moveProducts.locationNotAllowed');
                        return;
                    }
                    if ([TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)) {
                        const locationAllowedInstances = this.getAllowedInstances(locationId);
                        if (this.activeLocationSide === 'put' && locationAllowedInstances.length === 0) {
                            this.readingFail('tasks.moveProducts.noItemsCanBePutOnLocation');
                            return;
                        }
                    }
                }
                this.activeLocationId = locationId;
                this.readingDone();
            }
        }
    };
</script>

<style scoped>

</style>
