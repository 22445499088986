import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-1 pa-2 pt-3",attrs:{"outlined":_vm.orders.length === 0}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('tasks.preparePackage.orders.label'))+" ("+_vm._s(_vm.orders.length)+"):")]),_c(VList,[_c(VListItemGroup,_vm._l((_vm.orders),function({external_order, shipment}){return _c(VListItem,{key:external_order.id,class:(shipment.state === _vm.ShipmentState.TO_BE_CANCELLED || shipment.state === _vm.ShipmentState.CANCELLED) ? 'v-list-item--active' : '',attrs:{"color":(shipment.state === _vm.ShipmentState.TO_BE_CANCELLED || shipment.state === _vm.ShipmentState.CANCELLED) ? 'red' : ''},on:{"click":function($event){return _vm.openOrder(external_order.id)}}},[_c(VListItemContent,{staticClass:"ma-1 pb-1"},[_c(VListItemTitle,[_c('strong',[_vm._v("#"+_vm._s(external_order.external_order_id || external_order.id))]),_c('span',{staticClass:"text-caption"},[_vm._v(" | "+_vm._s(shipment.carrier.name))])]),_c('i',[_vm._v(_vm._s(external_order.external_note))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }