import Vue from "vue";
import Vuex from "vuex";
import connectionModule from "@/service/store/modules/connection.store";
import loaderModule from "@/service/store/modules/loader.store";
import oauthModule from "@/service/store/modules/oauth.store";
import snackbarModule from "@/service/store/modules/snackbar.store";
import userConfigModule from "@/service/store/modules/userConfig.store";
import timeModule from "@/service/store/modules/time.store";
import barcodeReaderQueueModule from "@/service/store/modules/barcodeReaderQueue.store";
import routerModule from "@/service/store/modules/router.store";
import storageMapModule from "@/service/store/modules/storageMap.store";
import {assign} from "@/utils/object";
import {Env} from "@/service/Environment";

Vue.use(Vuex);

/**
 * For each module, you can set up 'persistent' and 'devPersistent' properties, which persists their state into localStorage.
 * The priority is same as 'dependencies' in package.json: 'persistent' means persistent in all environments,
 *      while 'devPersistent' means persistent only in development environment. False is default for both.
 */
const modules = {
    connection: connectionModule,
    loader: loaderModule,
    oauth: oauthModule,
    snackbar: snackbarModule,
    userConfig: userConfigModule,
    time: timeModule,
    barcodeReaderQueue: barcodeReaderQueueModule,
    router: routerModule,
    storageMap: storageMapModule
};

const persistentModuleNames = Object.keys(modules)
    .filter(moduleName => {
        const module = modules[moduleName];
        return module.persistent || (Env.isDevelopment() && module.devPersistent);
    });

const Store = new Vuex.Store({
    modules: modules,
    mutations: {
        initialiseStore(state) {
            const localStore = localStorage.getItem('store');
            if (localStore !== null) {
                this.replaceState(assign(state, JSON.parse(localStore), true));
                state.time.reloaded = true;
                // TODO drop this later
                if (state.cache) {
                    window.console.warn('Clearing all LocalStorage');
                    localStorage.removeItem('store');
                }
            }
        }
    }
});

Store.subscribe((mutation, state) => {
    if (persistentModuleNames.includes(mutation.type.split('/', 1)[0])) {
        const persistentState = {};
        persistentModuleNames.forEach(moduleName => {
            persistentState[moduleName] = state[moduleName];
        });
        localStorage.setItem('store', JSON.stringify(persistentState));
    }
});

export {Store};
