export default {
    warehouseMap: "Warehouse map",
    usageHeatMap: "Warehouse location usage heatmap",
    backToMap: "Back to map",
    mapEditor: "Warehouse map editor",
    rack: "Rack",
    obstacle: "Obstacle",
    location: "Location",
    locations: "Locations",
    zone: "Zone",
    shelf: "Shelf",
    shelfCount: "Number of shelves",
    column: "Column",
    cell: "Cell",
    countInCell: "Order in cell",
    firstSymbol: "First symbol",
    secondSymbol: "Second symbol",
    thirdSymbol: "Third symbol",
    firstSymbolValue: "Symbol",
    secondSymbolValue: "Symbol",
    thirdSymbolValue: "Symbol",
    firstStartingSymbol: "Starting symbol",
    secondStartingSymbol: "Starting symbol",
    thirdStartingSymbol: "Starting symbol",
    startingSymbol: "Starting symbol",
    numbering: "Numbering",
    namingDirection: "Naming direction",
    startingSymbolColumn: "Starting symbol",
    numberingColumn: "Columns",
    startingSymbolShelf: "Starting symbol",
    numberingShelf: "Shelves",
    separators: "Separators",
    firstSeparator: "First separator",
    secondSeparator: "Second separator",
    thirdSeparator: "Third separator",
    actions: "Actions",
    undefined: "Undefined",
    multipleValues: "Multiple values",
    untitled: "Untitled",
    width: "Width",
    height: "Height",
    mapWidth: "Map width",
    mapHeight: "Map height",
    depth: "Depth",
    general: "General",
    naming: "Naming system",
    dimensions: "Dimensions",
    stockObjects: "Warehouse objects",
    racks: "Racks",
    locationGroups: "Locations",
    zones: "Zones",
    obstacles: "Obstacles",
    detail: "Detail",
    remove: "Delete",
    rename: "Rename",
    newName: "New name",
    advancedSettings: "Settings",

    objectWidth: "Width (number of columns)",
    objectHeight: "Height (number of rows)",
    rackShelves: "Height (number of shelves)",
    locationsOnPosition: "Locations on position",
    locationName: "Location name",
    oneZoneTypes: "Zone types",

    dimension: "Dimension",
    newDimension: "New dimension",
    selectedLocations: "Selected locations",
    selectedCells: "Selected cells",
    count: "Count",
    locationCount: "Count of locations",
    cellCount: "Count of cells",
    assign: "Assign",
    moreValues: "More values",
    withoutDimensions: "Without dimensions",

    bottomToTop: "Bottom to top",
    topToBottom: "Top to bottom",
    leftToRight: "Left to right",
    rightToLeft: "Right to left",

    noMaps: "You haven't created any maps yet.",
    createMap: "Create new map",
    goBack: "Back to overview",
    goBackAnyway: "Discard changes and leave",
    stay: "Stay",
    unsavedChanges1: "Are you sure you want to leave?",
    unsavedChanges2: "You have unsaved changes that will be discarded after leaving.",
    deleteConfirm1: "Are you sure you want to delete this map?",
    deleteConfirm2: "This action is irreversible.",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    saved: "Map saved",
    create: "Create",
    mapCannotSaveDuplicit: "Couldn't save - duplicit locations",
    errors: "Not saved, correct mistakes",

    addRack: "New rack",
    addObstacle: "New obstacle",
    addZone: "New zone",
    addLocationsGroup: "New locations",
    newObjectName: "New object name",
    newLocationGroupLocations: "New locations on this position",
    addLocation: "Add location",
    newZoneType: "Type of a new zone",

    detectedCollisions: "Collisions were detected",
    cannotFinishActionDueToCollision: "Couldn't complete the action, collision was detected.",
    zoom: "Zoom",

    automaticLocationEdit: "Edit locations automatically",
    manualLocationEdit: "Edit locations manually",

    zoneTypes: {
        receive: "Receive",
        delivery: "Delivery",
        packing: "Packing",
        forklift: "Forklift parking",
    },

    locationMappingDetails: {
        notMapped: "Not mapped locations",
        mapped: "Mapped locations",
        toCreate: "To be created (non-existing)",
        duplicit: "Duplicit (more than once in map)",
    },

    bookmarks: {
        map: "Map",
        rack: "Rack detail"
    },

    tools: {
        edit: "Edit",
        move: "Move",
        rotate: "Rotate",
        copy: "Copy",
        delete: "Delete",
        cancel: "Cancel selection",
        cancelAction: "Cancel action"
    },

    guide: {
        move: "Click an empty space to move the object.",
        createRack: "Click an empty space to create a rack.",
        createObstacle: "Click an empty space to create an obstacle.",
        createZone: "Click an empty space to create a zone.",
        createLocationsZone: "Click an empty space to create a locations group.",
        copy: "Click an empty space where the object will be pasted."
    },

    locationsSync: {
        sync: "Synchronize locations",
        mapNotHavingStockLoc: "Locations in map that doesn't have corresponding locations in warehouse",
        stockNotHavingMapLoc: "Warehouse locations that doesn't have corresponding locations in map",
        createStockLocCorrespondingMap: "Automatically create corresponding locations in warehouse",
        deleteStockLocCorrespondingMap: "Automatically delete locations unmapped locations in warehouse"
    },

    locationCodeDecide: {
        chooseLocation: "Choose location",
        guide1: "There are multiple locations with the name ",
        guide2: " in this stock. Choose one location that you want to add.",
        notMapped: "Not mapped locations",
        mapped: "Already mapped locations",
        add1: "Add location",
        add2: "with code",
    },

    error: {
        applyDimensionsToNull: "Unable to appply dimensions. Select rack cells first."
    }
};
