import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";

const CreatedOrActivatedPlugin = {
    install(Vue) {
        const mergeCreatedStrategy = Vue.config.optionMergeStrategies.created;
        Vue.config.optionMergeStrategies.createdOrActivated = function (parent, child) {
            return mergeCreatedStrategy(parent, child);
        };
        Vue.mixin(CreatedOrActivatedMixin);
    }
};

export {CreatedOrActivatedPlugin};
