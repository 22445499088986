<template>
  <v-layout>
    <v-flex>
      {{ item.text }}
    </v-flex>
    <v-flex
      v-if="item.quantity"
      class="text-right ml-2"
    >
      <v-tooltip
        v-if="item.blocked"
        left
      >
        <template #activator="{ on }">
          <span
            v-on="on"
          >
            <span
              v-if="item.blocked > 0 && $vuetify.breakpoint.smAndUp"
              class="text--disabled"
            >
              ({{ item.quantity }} - {{ item.blocked }}) =
            </span>
            {{ item.quantity - item.blocked }}
          </span>
        </template>
        {{ item.quantity }} {{ $t('tasks.itemPick.inStock') }}
        <br>{{ item.blocked }} {{ $t('tasks.itemPick.inProgress') }}
        <br>{{ item.quantity - item.blocked }} {{ $t('tasks.itemPick.available') }}
      </v-tooltip>
      <span v-else>
        {{ item.quantity }}
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
    export default {
        name: "TaskChooseItemsItemSelector",
        props: {
            item: {
                type: Object,
                default: () => ({
                    text: '',
                    value: 0,
                    quantity: 0,
                    blocked: 0
                })
            }
        }
    };

</script>

<style scoped>

</style>
