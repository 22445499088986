import {TableFilter} from "@/enum/table_filter";
import {AttributeDataTypes} from "@/enum/attribute_data_types";
import i18n from "@/service/lang/i18n";

const dataTypeItems = () => {
    return Object.values(AttributeDataTypes).map(dataType => ({
        text: i18n.t('base.attributes.dataTypes.' + dataType),
        value: dataType
    }));
};

export function AttributeTemplateForm() {
    return {
        value: '',
        description: '',
        data_type: AttributeDataTypes.STRING,
    };
}

export function AttributeTemplateRender() {
    return {
        value: {
            icon: '$itemName',
            max: 100,
            required: true
        },
        data_type: {
            icon: '$manufacturerAbbreviation',
            required: true,
            select: dataTypeItems(),
            readonly: false
        },
        description: {
            icon: '$description',
            textarea: true
        }
    };
}

export const attributeTemplateTable = () => {
    return {
        value: {
            filterType: TableFilter.TEXT,
            showInHint: true
        },
        data_type: {
            filterType: TableFilter.SELECT_MULTIPLE,
            filterItems: dataTypeItems(),
            itemLabel: item => i18n.t('base.attributes.dataTypes.' + item.data_type)
        }

    };
};
