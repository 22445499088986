<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <BuyerAddressCreateUpdateComponent
      :is-edit="isEdit"
      :buyer-address-id="isEdit ? addressId : null"
      is-in-view
      :buyer-id="buyerId"
      @buyer-address-updated="buyerAddressUpdated"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import BuyerAddressCreateUpdateComponent from "@/app/buyers/components/BuyerAddressCreateUpdate.component";

    export default {
        name: "BuyerAddressCreateUpdate",
        components: {BuyerAddressCreateUpdateComponent},
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            buyerName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.buyerName !== '') {
                    if (this.isEdit) {
                        title = this.$t('buyers.addresses.update.title', [this.buyerName]);
                    } else {
                        title = this.$t('buyers.addresses.create.title', [this.buyerName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            BuyerAPI.get(this.buyerId)
                .then(response => {
                    this.buyerName = response.data.name;
                }).catch(this.snack);
        },
        methods: {
            buyerAddressUpdated: function () {
                this.$router.back();
            }
        }
    };
</script>

<style scoped>

</style>
