import StocksList from "@/app/products/ProductsList.view";
import {Roles} from "@/enum/roles";
import ProductShow from "@/app/products/ProductShow.view";
import ProductCreateUpdate from "@/app/products/ProductCreateUpdate.view";
import ProductAttributeCreateUpdate from "@/app/products/attributes/ProductAttributeCreateUpdate.view";
import ProductBatchCreateUpdate from "@/app/products/batches/ProductBatchCreateUpdate.view";
import ImageCreate from "@/app/images/ImageCreate.view";
import ProductSerialNumberCreate from "@/app/products/serial-numbers/ProductSerialNumberCreate.view";
import ProductInstanceBarcodesList from "@/app/products/instances/barcodes/ProductInstanceBarcodesList.view";
import ProductInstanceBarcodeCreateUpdate from "@/app/products/instances/barcodes/ProductInstanceBarcodeCreateUpdate.view";
import ProductTypeCreateUpdate from "@/app/products/types/ProductTypeCreateUpdate.view";
import ProductTypesList from "@/app/products/types/ProductTypesList.view";
import ProductCustomInstanceCreateUpdate from "@/app/products/custom-instances/ProductCustomInstanceCreateUpdate.view";
import InstanceTypeCreateUpdate from "@/app/products/instances/types/InstanceTypeCreateUpdate.view";

const BASE_URL = '/products';

const routes = {
    base: {
        path: BASE_URL,
        component: StocksList,
        meta: {
            title: 'products.title',
            requireRole: [Roles.CHIEF],
            getParent: () => '/'
        }
    },


    show: {
        path: BASE_URL + '/:productId(\\d+)',
        component: ProductShow,
        meta: {
            title: 'products.show.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    create: {
        path: BASE_URL + '/create',
        name: 'productsCreate',
        component: ProductCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.create.title',
            requireRole: [Roles.CHIEF, Roles.STOREKEEPER],
            getParent: () => routes.base
        }
    },
    update: {
        path: BASE_URL + '/:productId(\\d+)/update',
        component: ProductCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    types: {
        path: BASE_URL + '/types',
        component: ProductTypesList,
        meta: {
            title: 'products.types.title',
            requiredRole: [Roles.CHIEF],
            getParent: () => routes.base
        }
    },
    typeCreate: {
        path: BASE_URL + '/types/create',
        component: ProductTypeCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.types.create.title',
            requiredRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },
    typeUpdate: {
        path: BASE_URL + '/types/:typeId(\\d+)/update',
        component: ProductTypeCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.types.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },


    attributeCreate: {
        path: BASE_URL + '/:productId(\\d+)/attributes/create',
        component: ProductAttributeCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.attributes.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    attributeUpdate: {
        path: BASE_URL + '/:productId(\\d+)/attributes/:attributeKey',
        component: ProductAttributeCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.attributes.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    batchCreate: {
        path: BASE_URL + '/:productId(\\d+)/batches/create',
        component: ProductBatchCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.batches.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    batchUpdate: {
        path: BASE_URL + '/:productId(\\d+)/batches/:batchCode',
        component: ProductBatchCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.batches.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    customInstanceCreate: {
        path: BASE_URL + '/:productId(\\d+)/custom-instances/create',
        component: ProductCustomInstanceCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.customInstances.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    customInstanceUpdate: {
        path: BASE_URL + '/:productId(\\d+)/custom-instances/:instanceId(\\d+)/update',
        component: ProductCustomInstanceCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.customInstances.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    imageCreate: {
        path: BASE_URL + '/:productId(\\d+)/images/create',
        component: ImageCreate,
        meta: {
            title: 'base.images.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    serialNumberCreate: {
        path: BASE_URL + '/:productId(\\d+)/serial-numbers/create',
        component: ProductSerialNumberCreate,
        meta: {
            title: 'products.serial_numbers.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },


    instanceTypeCreate: {
        path: BASE_URL + '/instance-types/create',
        component: InstanceTypeCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.instances.types.create.title',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },
    instanceTypeUpdate: {
        path: BASE_URL + '/instance-types/:instanceTypeId(\\d+)/update',
        component: InstanceTypeCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.instances.types.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.types
        }
    },


    instanceBarcodes: {
        path: BASE_URL + '/:productId(\\d+)/instance/:instanceId(\\d+)/barcodes',
        component: ProductInstanceBarcodesList,
        meta: {
            title: 'products.instances.barcodes.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.show
        }
    },
    instanceBarcodeCreate: {
        path: BASE_URL + '/:productId(\\d+)/instance/:instanceId(\\d+)/barcode/create',
        component: ProductInstanceBarcodeCreateUpdate,
        props: {
            isEdit: false
        },
        meta: {
            title: 'products.instances.barcodes.create.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.instanceBarcodes
        }
    },
    instanceBarcodeUpdate: {
        path: BASE_URL + '/:productId(\\d+)/instance/:instanceId(\\d+)/barcode/:code',
        component: ProductInstanceBarcodeCreateUpdate,
        props: {
            isEdit: true
        },
        meta: {
            title: 'products.instances.barcodes.update.titleSimple',
            requireRole: [Roles.CHIEF],
            getParent: () => routes.instanceBarcodes
        }
    }
};

export default Object.values(routes);
