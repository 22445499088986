import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,_vm._b({ref:"autocomplete",attrs:{"value":_vm.value,"clearable":_vm.clearable,"items":_vm.items,"loading":_vm.loading,"multiple":_vm.multiple,"search-input":_vm.search,"small-chips":_vm.chips,"prepend-icon":_vm.prependIcon},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.onUpdateSearch],"input":_vm.onInput,"click:clear":function($event){return _vm.$emit('click:clear')}},scopedSlots:_vm._u([(_vm.loading)?{key:"no-data",fn:function(){return [_c('span')]},proxy:true}:null,(_vm.$scopedSlots['append-each-item'] !== undefined)?{key:"item",fn:function({item}){return [_vm._t("append-each-item",null,{"item":item})]}}:null,{key:"prepend-item",fn:function(){return [(_vm.prependSearchBar)?[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._t("prepend-item")],2)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[(_vm.prependSearchBar)?_c(VTextField,{attrs:{"label":_vm.$attrs.label,"prepend-icon":"$search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1)]:_vm._t("prepend-item")]},proxy:true},{key:"append-item",fn:function(){return [(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"card-heading"}}):_vm._e(),_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:(_vm.checkIntersect),expression:"checkIntersect"}]})]},proxy:true},_vm._l((_vm.scopedSlotNames),function(name){return {key:name,fn:function(slotProps){return [_vm._t(name,null,null,slotProps)]}}})],null,true)},'v-autocomplete',_vm.$attrs,false),[_vm._l((_vm.slotNames),function(name){return _vm._t(name,null,{"slot":name})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }