import {
    TaskPriority,
    SupplierId,
    DeliveryNumber,
    InvoiceNumber,
    StockId,
    TaskStrictMode,
    TaskDescription, SubordinateStockId
} from "@/app/tasks/definitions/taskCommon.render.form";
import {TaskPreferredLocation} from "@/enum/task_preferred_location";
import i18n from "@/service/lang/i18n";
import {TaskPriority as TaskPriorityEnum} from "@/enum/task_priority";
import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";

function StockLoadingCreateForm() {
    return {
        priority: TaskPriorityEnum.NORMAL,
        preferred_location: TaskPreferredLocation.LAST,
        description: null,
        stock_id: null,
        subordinate_stock_id: null,
        supplier_id: null,
        delivery_number: null,
        invoice_number: null,
        strict_mode: TaskItemsStrictMode.FREE,
        attachments: [],
    };
}

const preferredLocationItems = Object.values(TaskPreferredLocation).map(location => ({
    text: i18n.t('tasks.location.' + location),
    value: location
}));

function StockLoadingCreateRender() {
    return {
        priority: new TaskPriority,
        stock_id: new StockId,
        subordinate_stock_id: new SubordinateStockId,
        preferred_location: {
            icon: '$location',
            select: preferredLocationItems,
            show: false // #14694
        },
        supplier_id: new SupplierId,
        delivery_number: new DeliveryNumber,
        invoice_number: new InvoiceNumber,
        strict_mode: new TaskStrictMode,
        description: new TaskDescription
    };
}

export {StockLoadingCreateForm, StockLoadingCreateRender};
