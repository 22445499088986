<template>
  <div>
    <x-toolbar />
    <ConfirmDeleteDialog
      v-if="deletedBuyer !== null"
      :show.sync="confirmDialog"
      :text="personInLine(deletedBuyer)"
      @confirm-delete="reallyDeleteBuyer"
    />
    <v-card>
      <x-data-table
        :headers="headers"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
      >
        <template #item.ico="{ item }">
          <TableItemAresValidated :item="item" />
        </template>
        <template #footer>
          <TableAddItemButton
            :to="buyerCreateLink"
            label="buyers.create.title"
          />
        </template>
      </x-data-table>
    </v-card>
    <x-btn-fab
      :to="buyerCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {APIFilters} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {buyerTable} from "@/app/buyers/definitions/buyer.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import TableItemAresValidated from "@/app/components/table/TableItemAresValidated.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {personInLine} from "@/utils/string";

    export default {
        name: "BuyersList",
        components: {TableItemAresValidated, TableAddItemButton, ConfirmDeleteDialog},
        data: () => ({
            loading: true,
            headers: [],
            items: [],
            reload: 0,
            buyerCreateLink: '/buyers/create',
            deletedBuyer: null,
            confirmDialog: false,
            personInLine: personInLine
        }),
        computed: {
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/buyers/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        routerLink: item => '/buyers/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return BuyerAPI.getAll.bind(BuyerAPI, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return BuyerAPI.getAllPages.bind(BuyerAPI);
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders(buyerTable, 'buyers.');
        },
        methods: {
            deleteItem: function (item) {
                this.deletedBuyer = item;
                this.confirmDialog = true;
            },
            reallyDeleteBuyer: function () {
                this.loading = true;
                this.confirmDialog = false;
                BuyerAPI.delete(this.deletedBuyer.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'buyers.delete.done',
                            params: [this.deletedBuyer.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.reload++;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
