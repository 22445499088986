<template>
  <v-container fluid>
    <x-toolbar />
    <v-card
      :loading="loading"
    >
      <v-flex
        class="pl-4 pt-5"
        md4
        sm6
        xl3
        xs12
      >
        <v-text-field
          v-model="configName"
          :label="$t('exports.configuration.name')"
        >
          <template #prepend>
            <v-icon>
              $edit
            </v-icon>
          </template>
        </v-text-field>
      </v-flex>
      <ExportConfig
        v-if="!loading"
        :config="{
          id: config.id,
          columns: config.columns,
          parameters: config.parameters
        }"
        :domain="config.domain"
        show-optional-parameters
        read-only
        :new-config-name="config.name"
      />
      <v-btn
        v-if="config"
        :loading="btnLoad"
        :disabled="configName === config.name || configName === ''"
        class="mb-3 ml-3"
        color="accent"
        @click="save"
      >
        {{ $t('exports.configuration.save') }}
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
    import ExportConfig from "@/app/exports/components/ExportConfig.component";
    import {ExportsAPI as API} from "@/api/ExportsAPI";

    export default {
        name: "ExportConfigView",
        components: {ExportConfig},
        data: () => ({
            loading: true,
            config: null,
            configName: '',
            btnLoad: true,
        }),
        computed: {
            configId() {
                return this.$route.path.split('/').pop();
            }
        },
        createdOrActivated() {
            this.getConfig();
        },
        methods: {
            getConfig() {
                this.loading = true;
                API.getConfiguration(this.configId)
                    .then(res => {
                        this.config = res.data;
                        this.configName = res.data.name;
                    })
                    .catch(err => {
                        this.snack(err);
                        this.$router.push('/exports');
                    })
                    .finally(() => {
                        this.loading = false;
                        this.btnLoad = false;
                    });
            },
            save() {
                this.btnLoad = true;
                const updatedData = {
                    name: this.configName,
                    columns: this.config.columns,
                    parameters: this.config.parameters
                };
                API.updateConfiguration(this.configId, updatedData)
                    .then(() => {
                        this.snack('exports.configuration.updated');
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.getConfig();
                    })
                    .catch(err => {
                        this.snack(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }

    };
</script>
