const importNotFound = "A kért import nem található";
export default {
    title: "Importálás",
    imports: "Importok",
    link: "Importok",
    create: "Import létrehozása",
    deleted: "Import törölve",
    file: "Fájl",
    file_info_create: "Fájl a jövőbeli importok sablonjának létrehozásához",
    file_info_use: "Adja meg a fájlt, amelyből az adatokat importálni fogja. Adja meg a fájlt a kiválasztott sablonnak megfelelő adatformátummal.",
    domain: "Céladat-terület",
    domain_info: "Az adatokat ebbe az adat-területbe importálja majd a sablon felhasználásával. Válassza ki azt az adat-területet, ahová a jövőben ezt a sablont felhasználva szeretné az adatokat importálni.",
    choose_file: "Fájl kiválasztása",
    process: "Import feldolgozása",
    process_started: "Az importálást feldolgozásra elküldték",
    processed: "Az importálás feldolgozva",
    download_imported_file: "adott fájl letöltése",
    download_error: 'Nem sikerült letölteni a felhasználói fájlt',
    array_buffer_error: 'Nem sikerült konvertálni a felhasználói fájlt',
    viewImport: "Import megtekintése",
    configuration: "Beállítások importálása",


    stepper: {
        first_step: "Sablon létrehozása",
        second_step: "Import előkészítése",
        second_step_back: "Törlés és új importálás előkészítése",
        third_step: "Ellenőrzés és importálás",
    },

    hints: {
        parameters_hint: "Az importálási paraméterek lehetővé teszik az importált adatokhoz szükséges további információk megadását.",
        header_rows: "Állítsa be a fejlécsorok számát a fájlban",
        first_row: "Ha a fájlban olyan adatok is szerepelnek, amelyek nem az importáláshoz tartoznak, állítsa be az első sort az importáláshoz",
        first_column: "Ha a fájlban olyan adatok is szerepelnek, amelyek nem az importáláshoz tartoznak, állítsa be az első oszlopot az importáláshoz",
        required_attributes: "Az összes kötelező attribútumnak fel kell lennie térképezve a lenti táblázatban. Ha nem lehetséges az összes kötelező attribútum leképezése a fájlból, és az kiválasztott adat-terület nem kompatibilis",
        mapping: "Térképezze a fájlt a kiválasztott adat-területre úgy, hogy kattintson a fejlécére, és kiválassza a célattribútumot. A térképezés során válassza ki az importálás során használandó oszlopokat is.",
        primary_key: "Ez az oszlop beállítva összehasonlító attribútumként",
        easy_mode: "Ebben a módban csak új elemeket adhat hozzá a kiválasztott céladat-területre",
        latest_on_duplicity: "Ha egy rekord azonosítása során több egyező rekordot találnak, akkor az utoljára létrehozott rekordot használják.",
        fileTemplate: "Az importfájl sablonjának letöltése Céladat-terület"
    },

    rules: {
        file_type: "Nem támogatott fájltípus",
        multiple_sheets: "A kiválasztott fájlban több mint egy lap van",
        cannot_access: "Nem lehet megnyitni a kiválasztott fájlt"
    },

    config: {
        title: "Importálási sablon",
        templates: "Importálási sablonok",
        create: "Importálási sablon létrehozása",
        edit: "Duplikálás és szerkesztés sablon",
        view: "Sablon megtekintése",
        created: "A sablon frissítve létrehozott",
        save: "Sablon mentése",
        save_new: "Új sablon mentése",
        deleted: "Az importálási sablon törölve",
        easyMode: "Könnyű mód",
        latestOnDuplicity: 'Kettősségek figyelmen kívül hagyása',

        name: "Sablon neve",
        domain: "Céladat-terület a sablonhoz",
        file: "Fájl",
        defaultParameters: "Alapértelmezett sablon paraméterek",
        parameters: "Importálási paraméterek",
        required_attributes: "Kötelező attribútumok",
        mapping: "Oszlopok leképezése",

        firstRow: "Első sor",
        firstColumn: "Első oszlop",
        headerRows: "Fejlécsorok",

        unmap: "kiválasztás megszüntetése",
        not_mapped: "nem leképezett",
        primary_set: "Beállítás összehasonlítási attribútumként",
        primary_unset: "Összehasonlítási attribútum beállításának megszüntetése",
        empty_error: "A konfigurációnak nincs importálása, amelyből referenciákat lehetne venni",
    },
    info: {
        title: "Import információ",
        hint: "Az import létrehozásáról szóló információk számolása folyamatban van, ez a művelet néhány percig is eltarthat.",
        include: "Az import tartalmazni fogja: ",
        error: "Az import feldolgozása során az alábbi hiba következik be: ",
        created: 'Sorok létrehozva',
        updated: 'Sorok frissítve',
        warn: "Figyelmeztetés",

        primary_key_toggle_warn: "A fő csoportnak legalább egy összehasonlítási attribútummal kell rendelkeznie",
        primary_key_group_warn: "A {0} csoportokból hiányzik legalább egy összehasonlító attribútum.",
        fake_file_warn: "A sablon egy példa fájlt mutat be, a valós fájl nem volt megadva vagy nem felel meg a feltételeknek",
        mapping_warn: "Ha nem térképezte le a kötelező attribútumokat, az azt jelenti, hogy a sablonja és a kiválasztott fájl nem kompatibilis.",
        mapping_warn_create: "Nem térképezte le az összes kötelező attribútumot a kiválasztott céladat-terület által követelt",
        parameters_warn: "Minden szükséges paramétert ki kell tölteni",
        change_value_warn: "Az érték megváltoztatása az aktuális leképezés elvesztéséhez vezet",
        edit_values_warn: "Az importálás feldolgozása során módosítások történnek az adatokon. Folytatja?",
        short_file_warn: "A feltöltött fájlban lévő oszlopok száma túl kicsi a jelenlegi sablonhoz.",
        delete_import: "Szeretné megtartani a sikertelen importot?",
    },

    errors: {
        'import': {
            status: {
                404: importNotFound,
            }
        },
        importCreate: {
            status: {
                409: 'Az import létrehozása sikertelen'
            }
        },
        importDelete: {
            status: {
                404: importNotFound,
                409: "Az import törlése sikertelen"
            }
        },
        importUpdate: {
            status: {
                404: importNotFound,
                409: 'Az import frissítése sikertelen'
            }
        },
        importProcess: {
            status: {
                404: importNotFound,
                409: "Az import feldolgozásra történő elküldése sikertelen"
            }
        },
        domainTemplate: {
            status: {
                404: "Nem sikerült az adatokat megtalálni a kiválasztott adatforrásban",
            }
        },
    }
};
