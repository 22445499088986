<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <ProductInstanceBarcodeCreateUpdateComponent
      :is-edit="isEdit"
      :product-id="productId"
      :product-name="productName"
      :code="isEdit ? code : null"
      :instance-id="instanceId"
      @barcodeUpdated="barcodeUpdated"
    />
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI} from "@/api/ProductAPI";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import ProductInstanceBarcodeCreateUpdateComponent
        from "@/app/products/instances/components/ProductInstanceBarcodeCreateUpdate.component";

    export default {
        name: "ProductInstanceBarcodeCreateUpdate",
        components: {ProductInstanceBarcodeCreateUpdateComponent},
        mixins: [InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            productName: null,
            productModel: null,
            decodedCode: null
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.productName !== null) {
                    if (this.isEdit) {
                        title = this.$t('products.instances.barcodes.update.title', [this.decodedCode, this.productName + ' - ' + this.productModel]);
                    } else {
                        title = this.$t('products.instances.barcodes.create.title', [this.productName + ' - ' + this.productModel]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            ProductAPI.get(this.productId)
                .then(response => {
                    this.productName = response.data.name;
                    this.productModel = response.data.model;
                }).catch(this.snack);
            if (this.code !== undefined) {
                this.decodedCode = decodeURIComponent(this.code);
            }
        },
        methods: {
            barcodeUpdated: function () {
                this.$router.push('/products/' + this.productId + '/instance/' + this.instanceId + '/barcodes');
            }
        }
    };
</script>

<style scoped>

</style>
