import Axios from "axios";
import {Env} from "@/service/Environment";

export function setLang(Vue) {

    const lang = Vue.$store.getters['userConfig/lang'];

    Vue.$vuetify.lang.current = lang;
    Vue.$i18n.locale = lang;
    Vue.$moment.locale(lang);
    Axios.defaults.headers.common['Accept-Language'] = lang;

    if (Env.isElectron()) {
        try {
            window.require('electron').ipcRenderer.invoke('public.lang.set', lang);
        } catch {
            // ElectroWhale <= 5
        }
    }
}

export function setTheme(Vue) {
    const theme = Vue.$store.getters['userConfig/theme'];
    if (theme === 'system') {
        Vue.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
        Vue.$vuetify.theme.dark = theme === 'dark';
    }
}
