import {StockAPI} from "@/api/StockAPI";

export function stocks(multiple = false) {
    return {
        apiDataSource: StockAPI.getAll.bind(StockAPI),
        resultTransform: el => {
            el.value = el.id;
            el.text = el.name;
            return el;
        },
        multiple: multiple,
        disableAutoselectFirst: multiple
    };
}