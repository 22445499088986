<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockLoading."
          >
            <template #strict_mode-item="{ item }">
              <TaskCreateStrictModeItem
                :item="item"
                lang-path="tasks.stockLoading.strictHint."
              />
            </template>
          </FormFields>
          <v-flex
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :valid.sync="validItems"
              title-path="tasks.stockLoading.itemsToLoad"
              :readonly="loading"
              require-product-specification
              :allow-price-set="isChief"
              :allow-empty="form.strict_mode === TaskItemsStrictMode.FREE"
            />
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockLoading
              </v-icon>
              {{ $t('tasks.stockLoading.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <SupplierCreateUpdate
      v-if="showCreateSupplier"
      id="supplierForm"
      ref="supplierForm"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {
        StockLoadingCreateForm,
        StockLoadingCreateRender
    } from "@/app/tasks/stockLoading/definitions/stockLoadingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {TaskStockLoadingAPI} from "@/api/TaskStockLoadingAPI";
    import {StockAPI} from "@/api/StockAPI";
    import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import formRules from "@/utils/formRules";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskCreateStrictModeItem from "@/app/tasks/components/TaskCreateStrictModeItem.component";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {ACLMixin} from "@/app/mixins/ACLMixin";

    export default {
        name: "StockLoadingCreate",
        components: {
            TaskAttributes,
            TaskCreateStrictModeItem, TaskAttachmentUpload, FormFields, SupplierCreateUpdate, TaskChooseItems},
        mixins: [EventsListenerMixin, ACLMixin],
        data: () => ({
            form: new StockLoadingCreateForm,
            formRender: new StockLoadingCreateRender,
            valid: true,
            validAttachments: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            showCreateSupplier: false,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            rules: formRules,
            TaskItemsStrictMode: TaskItemsStrictMode,
            type: taskTypes.STOCK_LOADING,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-supplier': this.onCreateSupplier,
                    'create-supplier-created': this.onCreateSupplierCreated,
                    'create-supplier-cancelled': () => this.showCreateSupplier = false
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.stockIdChanged();
                }
            },
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            onCreateSupplier: function () {
                this.showCreateSupplier = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.supplier_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('supplierForm');
                });
            },
            onCreateSupplierCreated: function (supplierId) {
                this.showCreateSupplier = false;
                this.$set(this.formRender.supplier_id, 'loading', true);
                this.formRender.supplier_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.supplier_id.autocomplete.items = this.formRender.supplier_id.autocomplete.thenFn(response);
                        this.form.supplier_id = Number.parseInt(supplierId, 10);
                    }).finally(() => this.$set(this.formRender.supplier_id, 'loading', false));
            },
            stockIdChanged: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstockPages(this.form.stock_id)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_instance_id;
                    });
                TaskStockLoadingAPI.create(data)
                    .then(response => {
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.stockLoading.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    })
                    .catch(err => {
                        setFormErrors.call(this, err);
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
