<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedBarcode !== null"
      :show.sync="confirmDialog"
      :text="deletedBarcode.code + ', ' + deletedBarcode.quantity + 'x'+ (deletedBarcode.description ? `, ${deletedBarcode.description}` : '')"
      @confirm-delete="reallyDeleteBarcode"
    />
    <BarcodePrintDialog
      v-if="modalItem !== null || modalCodes.length !== 0"
      :show.sync="barcodeModal"
      :barcode-count.sync="barcodeCount"
      :print-type.sync="printType"
      :title="modalItem ? $t('products.instances.barcodes.printHowMuch', [modalItem.code]) : $t('products.instances.barcodes.printMultipleHowMuch')"
      :confirm-text="$t('products.instances.barcodes.printMany')"
      @print-barcode="reallyPrintBarcode"
    />
    <x-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :actions="actions"
      :batch-actions="batchActions"
    >
      <template #item.image="{ item }">
        <svg
          :id="'JsBarcode-' + item.code"
          class="JsBarcode"
          :value="item.code"
        />
      </template>
      <template #footer>
        <TableAddItemButton
          :to="barcodeCreateLink"
          label="products.instances.barcodes.create.titleSimple"
        />
      </template>
    </x-data-table>
  </div>
</template>

<script>
    import {copyToClipboard} from "@/utils/string";
    import JsBarcode from "jsbarcode";
    import {createHeaders} from "@/utils/table";
    import {productInstanceBarcodeTable} from "@/app/products/instances/barcodes/definitions/productInstanceBarcode.form";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {PrintType} from "@/enum/print_type";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import {ProductBarcodePrintMixin} from "@/app/mixins/ProductBarcodePrintMixin";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "ProductInstanceBarcodesListComponent",
        components: {TableAddItemButton, BarcodePrintDialog, ConfirmDeleteDialog},
        mixins: [ProductBarcodePrintMixin],
        props: {
            product: {
                type: Object,
                default: () => ({})
            },
            instanceId: {
                type: Number,
                default: null
            },
            instance: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            loading: false,
            items: [],
            barcodeModal: false,
            modalItem: null,
            barcodeCount: 1,
            printType: PrintType.PDF,
            deletedBarcode: null,
            confirmDialog: false,
            modalCodes: []
        }),
        computed: {
            barcodeCreateLink: function () {
                return '/products/' + this.product.id + '/instance/' + this.instanceId + '/barcode/create';
            },
            headers: () => createHeaders(productInstanceBarcodeTable, 'products.instances.barcodes.'),
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/products/' + this.product.id + '/instance/' + this.instanceId + '/barcode/' + item.code,
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        action: this.copyToClipboard,
                        icon: '$copyToClipboard',
                        label: 'base.copyToClipboard'
                    }, {
                        loading: this.loading,
                        action: this.printBarcode,
                        icon: '$printItem',
                        label: 'products.instances.barcodes.print'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            batchActions: function () {
                return [
                    {
                        loading: this.loading,
                        action: this.batchPrintBarcode,
                        icon: '$printItem',
                        label: 'products.instances.barcodes.print'
                    }
                ];
            }
        },
        createdOrActivated: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                this.loading = true;
                API.getAllInstanceBarcodes(this.product.id, this.instanceId)
                    .then(response => {
                        this.items = response.data.map(item => {
                            item.id = item.code;
                            return item;
                        });
                        this.$nextTick(() => this.displayBarcodes());
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            displayBarcodes: function () {
                for (const element of document.getElementsByClassName('JsBarcode')) {
                    JsBarcode(element, element.attributes['value'].value, {
                        displayValue: false,
                        height: 40,
                        width: this.$vuetify.breakpoint.smAndDown ? 1 : 2
                    });
                }
            },
            copyToClipboard: function (item) {
                copyToClipboard(item.code)
                    .then(() => {
                        this.advancedSnack({
                            text: 'base.copyToClipboardDone',
                            params: [item.code]
                        });
                    }).catch(() => {
                        this.snack('base.copyToClipboardFail');
                    });
            },
            batchPrintBarcode: function (codes) {
                this.barcodeModal = true;
                this.modalCodes = codes;
            },
            deleteItem: function (item) {
                this.deletedBarcode = item;
                this.confirmDialog = true;
            },
            reallyDeleteBarcode: function () {
                this.loading = true;
                this.confirmDialog = false;
                API.deleteInstanceBarcode(this.product.id, this.instanceId, this.deletedBarcode.code)
                    .then(() => {
                        IndexedDB.get(CachePath.instanceBarcodes, this.instanceId).then(instanceBarcodes => {
                            instanceBarcodes = instanceBarcodes.filter(barcode => barcode.code !== this.deletedBarcode.code);
                            IndexedDB.save(CachePath.instanceBarcodes, this.instanceId, instanceBarcodes);
                        }).finally(this.getData);
                        this.advancedSnack({
                            text: 'products.instances.barcodes.delete.done',
                            params: [this.deletedBarcode.code, this.product.name],
                        });
                    }).catch(err => {
                        this.snack(err);
                        this.getData();
                    });
            }
        }
    };
</script>

<style scoped>

</style>
