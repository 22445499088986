const TaskPriority = {
    LOW: 1,
    NORMAL: 2,
    HIGH: 3
};

const TaskPriorityColor = {
    1: 'priorityLow',
    2: 'priorityNormal',
    3: 'priorityHigh'
};

export {TaskPriority, TaskPriorityColor};
