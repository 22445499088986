import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VImg,{attrs:{"src":_vm.bigImageUrl}})],1),(_vm.justOneImage && _vm.singleImage)?_c(VImg,{staticClass:"ma-1",attrs:{"src":_vm.singleImage.url,"lazy-src":_vm.publicPath + 'assets/product_image_placeholder.jpg',"contain":""},on:{"click":function($event){return _vm.showImage(_vm.singleImage.url)}}}):(!_vm.justOneImage)?_c('div',{staticClass:"d-flex flex-column justify-center mt-1"},[_c('div',{staticClass:"d-flex flex-wrap justify-center mt-1"},_vm._l((_vm.images),function(image,index){return _c('div',{key:index},[(index < _vm.imageLimit)?_c(VImg,{staticClass:"grey lighten-2 ma-1",attrs:{"src":image.url,"lazy-src":_vm.publicPath + 'assets/product_image_placeholder.jpg',"aspect-ratio":1,"max-width":"28vw"},on:{"click":function($event){return _vm.showImage(image.url)}}}):_vm._e()],1)}),0),_c(VBtn,{staticClass:"my-2",attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.addImageDialog = true}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $addItem ")]),_vm._v(" "+_vm._s(_vm.$t('base.images.create.titleSimple'))+" ")],1)],1):_vm._e(),_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.addImageDialog),callback:function ($$v) {_vm.addImageDialog=$$v},expression:"addImageDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('base.images.create.title', [_vm.productName]))+" ")]),_c('ImageCreateComponent',{attrs:{"item-name":_vm.productName,"item-id":_vm.productId},on:{"imagesUpdated":_vm.imagesUpdated}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }