import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-1"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VIcon,{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.actionIcons[_vm.action])+" ")]),_c('span',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.actionLabel)+" ")]),(_vm.finishAndApprove)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mb-1 ml-1"},on),[_vm._v(" $hint ")])]}}],null,false,2355825299)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tasks.finishAndApproveHint'))+" ")])]):_vm._e(),_c(VDivider,{staticClass:"my-2"}),(_vm.action !== _vm.TaskAction.UNASSIGN)?_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"tasks."}}):_vm._e(),_c('div',[(_vm.storekeeperOptions)?_c(VCheckbox,{attrs:{"label":_vm.$t('tasks.keep_storekeeper')},model:{value:(_vm.keep_storekeeper),callback:function ($$v) {_vm.keep_storekeeper=$$v},expression:"keep_storekeeper"}}):_vm._e(),_c(VTooltip,{attrs:{"disabled":!_vm.actionDisabled,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":_vm.actionDisabled,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.actionIcons[_vm.action])+" ")]),_vm._v(" "+_vm._s(_vm.actionLabel)+" ")],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$te('tasks.' + _vm.action + '.disabled') ? _vm.$t('tasks.' + _vm.action + '.disabled') : '')+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }