<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <ProductInstanceBarcodesListComponent
        v-if="product !== null"
        :product="product"
        :instance-id="instanceId"
        :instance="instance"
      />
      <v-progress-linear
        v-else
        indeterminate
      />
    </v-card>
    <x-btn-fab
      :to="barcodeCreateLink"
    >
      <template #content>
        <v-icon>$addItem</v-icon>
      </template>
    </x-btn-fab>
  </div>
</template>

<script>
    import {CreateNewBarcodeMixin} from "@/app/mixins/CreateNewBarcodeMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import ProductInstanceBarcodesListComponent
        from "@/app/products/instances/components/ProductInstanceBarcodesList.component";

    export default {
        name: "ProductInstanceBarcodesList",
        components: {ProductInstanceBarcodesListComponent},
        mixins: [EventsListenerMixin, CreateNewBarcodeMixin, RouteParamsMapperMixin],
        data: () => ({
            product: null,
            instance: null
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.createNewBarcode
                };
            },
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.product !== null) {
                    title = this.$t('products.instances.barcodes.title', [
                        this.$options.filters.instanceLabel(this.instance, this.product)
                    ]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        createdOrActivated: function () {
            return API.getInstance(this.productId, this.instanceId)
                .then(response => {
                    this.instance = response.data;
                    this.product = response.data.product;
                }).catch(this.snack);
        }
    };
</script>

<style scoped>

</style>
