import {BaseAPI} from "@/api/BaseAPI";

const AresAPI = {

    API: BaseAPI,
    DOMAIN: 'ares',

    getSubjectInfo(ico) {
        return this.API.get(
            [this.DOMAIN, 'subject-info', ico],
            'base.api.ares'
        );
    }
};

export {AresAPI};
