const Product = {
    getSingleImage: (product) => {
        if (product && product.images) {
            return product.images.find(image => image.is_main_photo) || product.images[0];
        } else {
            return undefined;
        }
    }
};

export {Product};
