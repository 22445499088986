import {ProductAPI} from "@/api/ProductAPI";
import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
import {update} from "@/utils/array";
import {EventBus} from "@/service/EventBus";
import {StockStatusAPI} from "@/api/StockStatusAPI";
import {taskTypes} from "@/enum/task_type";
import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
import {StockAPI} from "@/api/StockAPI";
import {TaskMoveProductsType} from "@/enum/task_move_products_type";
import {IndexedDB} from "@/service/cache/IndexedDB";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {TaskAllowedLocationsMixin} from "@/app/mixins/TaskAllowedLocationsMixin";
import {debounce} from "lodash";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
import {has} from "@/utils/object";
import {TaskLocationType} from "@/enum/TaskLocationType";

const MAX_INSTANCES_PER_REQUEST = 100;

/**
 * Requires:
 * - this.API.getAllItems()
 * - this.items
 * - this.taskInfo.taskId
 * - this.taskInfo.details
 * - this.taskInfo.movementType (if STOCK_LOADING or SUBSTOCK_TRANSFER)
 */
const TaskFetchItemsMixin = {
    mixins: [TaskTypeMixin, TaskStateMixin, ReactiveLocationCacheMixin, TaskAllowedLocationsMixin, EventsListenerMixin],
    computed: {
        events: function () {
          return {
              'fetch-allowed-locations': this.onFetchAllowedLocations,
              'fetch-already-placed-at': this.fetchInstanceAlreadyPlacedAt
          };
        },
        stockId: function () {
            return (this.taskInfo.details
                && ((this.taskInfo.details.stock && this.taskInfo.details.stock.id)
                    || (this.taskInfo.details.subordinate_stock && this.taskInfo.details.subordinate_stock.stock_id)
                    || (this.taskInfo.details.source_subordinate_stock && this.taskInfo.details.source_subordinate_stock.stock_id))
            );
        },
        subStockId: function () {
            return (this.taskInfo.details
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.source_subordinate_stock)
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.source_subordinate_stock).id
            );
        },
        destinationSubStock: function () {
            return (this.taskInfo.details
                && (this.taskInfo.details.subordinate_stock || this.taskInfo.details.destination_subordinate_stock)
            );
        },
        fetchItemsDebounced: function () {
            return debounce((config) => this.fetchItems(config), 500);
        },
        fetchQuantitiesDebounced: function () {
            return debounce(this.fetchQuantities, 500);
        },
    },
    methods: {
        fetchItems: function (config = {}) {
            const {initial, debounceQuantities, onlyInstanceId} = {
                ...{
                    initial: false,
                    debounceQuantities: false,
                    onlyInstanceId: null
                }, ...config
            };
            return new Promise((resolve, reject) => {
                this.API.getAllItems(this.taskInfo.taskId)
                    .then(response => {
                        if (this.isType(taskTypes.STOCK_TAKING)) {
                            response.data = response.data.items;
                        }
                        let items = this.items.sort((a, b) => b.id - a.id);
                        if (initial) {
                            items = response.data.sort((a, b) => b.id - a.id);
                        } else {
                            update(items, response.data.sort((a, b) => b.id - a.id));
                        }
                        items = items.sort((a, b) => 0.5 - ((a.updated_at || a.created_at) > (b.updated_at || b.created_at)));
                        this.items = items;
                        this.fetchUnknownInstances()
                            .then(() => {
                                const promises = [
                                    this.getInstancesAllowedLocationsFromCache(),
                                    this.fetchUnknownProducts()
                                ];
                                if (onlyInstanceId !== null) {
                                    promises.push(this.fetchQuantities(onlyInstanceId));
                                }
                                if (debounceQuantities) {
                                    this.fetchQuantitiesDebounced();
                                } else if (!onlyInstanceId) {
                                    promises.push(this.fetchQuantities());
                                }
                                Promise.all(promises).then(resolve).catch(reject);
                            });
                    });
            });
        },
        fetchUnknownInstances: function () {
            return new Promise((resolve, reject) => {
                const promises = [];
                this.items
                    .filter(item => item.instance === undefined)
                    .filter(item => item.product_instance_id)
                    .forEach(item => {
                        promises.push(ProductAPI.getInstanceWOProduct(item.product_instance_id)
                            .then(response => {
                                this.$set(item, 'instance', response.data);
                            })
                        );
                    });
                Promise.all(promises)
                    .then(resolve)
                    .catch(err => {
                        this.snack(err);
                        reject();
                    });
            });
        },
        fetchUnknownProducts: function () {
            return new Promise((resolve, reject) => {
                const promises = [];
                this.items
                    .filter(item => item.instance === undefined)
                    .filter(item => item.product === undefined)
                    .filter(item => item.product_id)
                    .forEach(item => {
                        promises.push(ProductAPI.get(item.product_id)
                            .then(response => {
                                this.$set(item, 'product', response.data);
                            })
                        );
                    });
                Promise.all(promises)
                    .then(resolve)
                    .catch(err => {
                        this.snack(err);
                        reject();
                    });
            });
        },
        fetchQuantities: function (onlyInstanceId = null) {
            return new Promise((resolve, reject) => {
                if (this.isAnyOfTypes([
                    taskTypes.DELIVERY_ACCEPT,
                    taskTypes.STOCK_LOADING,
                    taskTypes.STOCK_TAKING,
                    taskTypes.LOCATION_TRANSFER,
                    taskTypes.EXTERNAL_ORDER
                ])) {
                    resolve();
                } else {
                    const fetchingForItems = this.items.filter(item =>
                        onlyInstanceId === null
                            ? item.instance && item.instance.id
                            : item.instance && item.instance.id === onlyInstanceId
                    );
                    const filterObject = [{
                        [APIFilterOP.EQUALS]: {
                            'substock.id': this.subStockId
                        }
                    }, {
                        [APIFilterOP.GREATER_THAN]: {
                            quantity: 0
                        }
                    }];
                    const fetchInstanceIds = [...new Set(fetchingForItems.map(item => item.instance.id))];
                    const promises = [];
                    while (fetchInstanceIds.length) {
                        promises.push(StockStatusAPI.getShortAllPages({
                            filter: APIFilters.makeFilter([...filterObject, {
                                [APIFilterOP.IN]: {
                                    'product_instance.id': fetchInstanceIds.splice(0, MAX_INSTANCES_PER_REQUEST)
                                }
                            }])
                        }));
                    }
                    Promise.all(promises).then(responses => {
                        const stockLocations = responses.reduce(
                            (acc, curr) => acc.concat(curr.data.items.filter(
                                location => location.stock_location.user === undefined
                            )), []
                        );
                        fetchingForItems.forEach(item => {
                            this.$set(item, 'locations', stockLocations.filter(location => location.product_instance_id === item.instance.id));
                            // TODO CRITICAL filter by allowed in substock
                        });
                        if (onlyInstanceId === null) {
                            EventBus.$emit('taskItems-quantitiesLoaded');
                        }
                        resolve();
                    }).catch(reject);
                }
            });
        },
        getInstancesAllowedLocationsFromCache: function () {
            // gets allowed locations if they are present in cache
            return new Promise((resolve) => {
                if (this.isAnyOfTypes([taskTypes.STOCK_LOADING, taskTypes.SUBSTOCK_TRANSFER])
                    || (this.isType(taskTypes.MOVE_PRODUCTS)
                        && [TaskMoveProductsType.DISTRIBUTE, TaskMoveProductsType.MANYTOMANY].includes(this.taskInfo.movementType)
                    )) {
                    const promises = [];
                    this.items
                        .filter(item => item.instance && item.instance.id)
                        .forEach(item => {
                            promises.push(this.getInstanceAllowedLocationsFromCache(item.instance.id)
                                .then(data => {
                                    this.$set(item, 'allowedLocationIds', data);
                                })
                            );
                        });
                    // We do not care if some promises failed
                    Promise.allSettled(promises)
                        .finally(() => {
                            this.computeAllowedInstances();
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        getInstanceAllowedLocationsFromCache: function (instanceId) {
            return IndexedDB.get(CachePath.allowedLocationIds, [this.destinationSubStock.stock_id, this.destinationSubStock.id, instanceId].join('-'));
        },
        onFetchAllowedLocations: function (item, reallyFetch) {
            if (this.loadAllowedLocations || reallyFetch) {
                this.fetchInstanceAllowedLocations(item, reallyFetch);
            }
        },
        fetchInstanceAllowedLocations: function (item, reallyFetch = false) {
            return new Promise((resolve, reject) => {
                if (item.instance && item.instance.id && (item.allowedLocationIds === undefined || reallyFetch)) {
                    StockAPI.getSubstockAllowedLocationIds(this.destinationSubStock.stock_id, this.destinationSubStock.id, item.instance.id, reallyFetch)
                        .then(response => {
                            this.$set(item, 'allowedLocationIds', response.data);
                            this.computeAllowedInstances();
                            resolve();
                        }).catch(reject);
                } else {
                    resolve();
                }
            });
        },
        fetchInstanceAlreadyPlacedAt: function (item, stockId) {
            return new Promise((resolve, reject) => {
                if (item.instance && item.instance.id && item.alreadyPlacedAt === undefined) {
                    // Deprecated is OK here as we filter only certain product instances
                    StockStatusAPI.getShortAllPages({
                        filter: APIFilters.makeFilter([
                            {[APIFilterOP.EQUALS]: {'product_instance.id': item.instance.id}},
                            {[APIFilterOP.EQUALS]: {'stock.id': stockId}},
                            {[APIFilterOP.GREATER_THAN]: {quantity: 0}}
                        ]),
                        sort: APIFilters.makeSort({quantity: 'DESC'})
                    }).then(response => {
                        const filtered = response.data.items
                            .filter(location => location.stock_location.user === undefined);
                        filtered.forEach(location => {
                            delete location.product_instance;
                        });
                        const value = {};
                        for (const location of filtered) {
                            // location can have different stock status for each substock, therefore stock location id might not be unique
                            // reduce quantity on location across all substocks
                            if (has(value, location.stock_location.id)) {
                                value[location.stock_location.id].quantity += location.quantity;
                            } else {
                                value[location.stock_location.id] = location;
                            }
                        }
                        this.$set(item, 'alreadyPlacedAt', value);
                        if (!this.isClosed) { // When closed, data are already good from stock-status
                            this.enrichAlreadyPlacedAtByLocationsInThisTask(item, TaskLocationType.SOURCE, null, true);
                            this.enrichAlreadyPlacedAtByLocationsInThisTask(item, TaskLocationType.DESTINATION, null, true);
                            if (!item.enrichAlreadyPlacedAtByLocationsInThisTaskWatcherEnabled) {
                                item.enrichAlreadyPlacedAtByLocationsInThisTaskWatcherEnabled = true;
                                this.$watch(() => item.source_locations, (_, oldSourceLocations) => {
                                    this.enrichAlreadyPlacedAtByLocationsInThisTask(item, TaskLocationType.SOURCE, oldSourceLocations);
                                }, {deep: true});
                                this.$watch(() => item.destination_locations, (_, oldDestinationLocations) => {
                                    this.enrichAlreadyPlacedAtByLocationsInThisTask(item, TaskLocationType.DESTINATION, oldDestinationLocations);
                                }, {deep: true});
                            }
                        }
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },
        enrichAlreadyPlacedAtLocation: function (item, locationInThisTask, locationType, oldLocations, isLocationSourceAndDestination = false, initial = false) {
            const locationId = locationInThisTask.location_id || locationInThisTask.stock_location_id;

            let quantityInThisTask = 0;
            if (locationType === TaskLocationType.SOURCE) {
                quantityInThisTask = locationInThisTask.pick_quantity;
            } else {
                if (this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                    quantityInThisTask = locationInThisTask.store_quantity;
                }
                if (this.isType(taskTypes.STOCK_LOADING)) {
                    quantityInThisTask = locationInThisTask.quantity;
                }
            }

            const locationInAlreadyPlaced = item.alreadyPlacedAt[locationId];
            if (locationInAlreadyPlaced !== undefined) {
                if (locationInAlreadyPlaced.original_quantity === undefined) {
                    // Save the original quantity from StockStatus when we first encounter this location in already placed at.
                    // It might not be directly after task is fetched from BE, because only source and destination locations
                    // are being enriched.
                    locationInAlreadyPlaced.original_quantity = locationInAlreadyPlaced.quantity;
                }
                let originalTaskQuantityKey = 'original_task_pick_quantity';
                if (locationType === TaskLocationType.DESTINATION) {
                    originalTaskQuantityKey = 'original_task_put_quantity';
                }
                if (locationInAlreadyPlaced[originalTaskQuantityKey] === undefined) {
                    // save the original pick or put quantity in this task
                    if (this.isType(taskTypes.STOCK_LOADING)) {
                        // even if task quantity is not 0 BE does not count these items into stock status because movements are created after stock loading approve
                        locationInAlreadyPlaced[originalTaskQuantityKey] = 0;
                    } else if (oldLocations && oldLocations[locationId] === undefined) {
                        // destination or source location added to task just now, it is in already placed locations, which means there are some items in stock
                        // but original quantity in this task is 0
                        locationInAlreadyPlaced[originalTaskQuantityKey] = 0;
                    } else {
                        locationInAlreadyPlaced[originalTaskQuantityKey] = quantityInThisTask;
                    }
                }
                if (locationType === TaskLocationType.SOURCE) {
                    quantityInThisTask *= -1;
                }
                if (isLocationSourceAndDestination) {
                    // Location is source and destination location at the same time which can only happen during auto move
                    // of items from source to destination.
                    // If we picked x items from location but put them back we really took 0 items from it.
                    locationInAlreadyPlaced.original_task_pick_quantity = 0;
                }
                locationInAlreadyPlaced.quantity = locationInAlreadyPlaced.original_quantity
                    + (locationInAlreadyPlaced.original_task_pick_quantity ?? 0)
                    - (locationInAlreadyPlaced.original_task_put_quantity ?? 0)
                    + quantityInThisTask;
            } else {
                // add new location for already placed at
                const newLocationInAlreadyPlaced = {...locationInThisTask};
                let stolenQuantity = 0;
                if (initial && this.isAnyOfTypes([taskTypes.MOVE_PRODUCTS, taskTypes.SUBSTOCK_TRANSFER])) {
                    // In MOVE PRODUCTS and SUBSTOCK TRANSFER items are moved immediately,
                    // this means these items can be stolen from that location in another task.
                    // Item has to be placed on destination location in this task to be able to be stolen.
                    // That means item cannot be stolen from newly added destination location, and we want to
                    // compute stolen quantity only during initial walkthrough.
                    // If there are no items in stock however there are some in this task some other task stole
                    // all the items, and we need to compensate for this.
                    stolenQuantity = quantityInThisTask;
                }

                if (locationType === TaskLocationType.SOURCE) {
                    // item was picked from this location
                    newLocationInAlreadyPlaced.quantity = 0;
                    newLocationInAlreadyPlaced.original_task_pick_quantity = quantityInThisTask;
                } else {
                    // item was put on this location
                    newLocationInAlreadyPlaced.original_task_put_quantity = stolenQuantity;
                    newLocationInAlreadyPlaced.quantity = quantityInThisTask - stolenQuantity;
                }
                // might set loading label which is not reactive
                newLocationInAlreadyPlaced.stock_location = this.LocationCache[locationId];
                newLocationInAlreadyPlaced.original_quantity = 0;
                // cache location, wait for response and set real data
                this.cacheLocation(locationId).then(response => {
                    newLocationInAlreadyPlaced.stock_location = response.data;
                    this.$set(item.alreadyPlacedAt, locationId, newLocationInAlreadyPlaced);
                });
                this.$set(item.alreadyPlacedAt, locationId, newLocationInAlreadyPlaced);
            }
        },
        // called for STOCK LOADING, MOVE PRODUCTS and SUBSTOCK TRANSFER
        enrichAlreadyPlacedAtByLocationsInThisTask: function (item, locationType, oldLocations, initial = false) {
            const getLocationId = (location) => location.location_id || location.stock_location_id;

            const sourceLocations = [...(item.source_locations ?? [])];
            const destinationLocations = [...(item.destination_locations)];
            if (locationType === TaskLocationType.SOURCE) {
                for (const locationInThisTask of sourceLocations) {
                    const locationId = getLocationId(locationInThisTask);
                    const isLocationSourceAndDestination = destinationLocations.some(loc => getLocationId(loc) === locationId);
                    this.enrichAlreadyPlacedAtLocation(item, locationInThisTask, locationType, oldLocations, isLocationSourceAndDestination, initial);
                }
            } else {
                // location type is TaskLocationType.DESTINATION
                for (const locationInThisTask of destinationLocations) {
                    const locationId = getLocationId(locationInThisTask);
                    const isLocationSourceAndDestination = sourceLocations.some(loc => getLocationId(loc) === locationId);
                    this.enrichAlreadyPlacedAtLocation(item, locationInThisTask, locationType, oldLocations, isLocationSourceAndDestination, initial);
                }
            }
            // handle removed source or destination locations
            for (const oldLocation of (oldLocations ?? [])) {
                const oldLocationId = getLocationId(oldLocation);
                let currentLocation = null;
                if (locationType === TaskLocationType.SOURCE) {
                    currentLocation = (item.source_locations ?? []).find(loc => loc.stock_location_id === oldLocationId);
                } else {
                    currentLocation = item.destination_locations
                        .find(loc => getLocationId(loc) === oldLocationId);
                }
                if (!currentLocation) {
                    const locationInAlreadyPlaced = item.alreadyPlacedAt[oldLocationId];
                    if (locationInAlreadyPlaced !== undefined) {
                        locationInAlreadyPlaced.quantity = locationInAlreadyPlaced.original_quantity
                            + (locationInAlreadyPlaced.original_task_pick_quantity ?? 0)
                            - (locationInAlreadyPlaced.original_task_put_quantity ?? 0);
                        if (locationInAlreadyPlaced.quantity === 0) {
                            delete item.alreadyPlacedAt[oldLocationId];
                        }
                    }
                }
            }
        },
        readingDoneNoVibrate: function () {
            EventBus.$emit('reading-done', false);
        },
        readingDone: function (doVibrate = true) {
            EventBus.$emit('reading-done', doVibrate);
        },
        readingFail: function (message) {
            EventBus.$emit('reading-fail', message);
        }
    }
};

export {TaskFetchItemsMixin};
