<template>
  <span>
    {{ codeLabel }}
  </span>
</template>

<script>
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import {ReactiveBarcodeCacheMixin} from "@/app/mixins/ReactiveBarcodeCacheMixin";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {CodeType} from "@/enum/code_type";
    import {locationLabel} from "@/utils/string";

    export default {
        name: "TaskCodeLabel",
        mixins: [ReactiveBarcodeCacheMixin, ReactiveLocationCacheMixin],
        props: {
            code: {
                type: String,
                default: ''
            },
            showX: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            codeLabel: function () {
                const item = this.BarcodeCache[this.code];
                if (item) {
                    if (item.type === CodeType.PRODUCT_INSTANCE) {
                        return item.quantity + (this.showX ? 'x ' : ' ')
                            + this.$options.filters.instanceLabel(item.object_info);
                    } else if (item.type === CodeType.STOCK_LOCATION) {
                        return locationLabel(item.object_info);
                    }
                } else {
                    this.cacheBarcode(BarcodeAPI.decode(this.code), this.code);
                }
                return ' ' + this.code;
            }
        }
    };
</script>

<style scoped>

</style>
