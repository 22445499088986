import i18n from "@/service/lang/i18n";

export default {

    /**
     * We want to allow zero as number, explicitly check falsy values
     * @return {boolean|string|VueI18n.LocaleMessages}
     */
    required: v => (v !== undefined && v !== null && v !== '') || i18n.t('form.required'),

    /**
     * @param max
     * @returns {function (*=): (boolean|*|string | VueI18n.LocaleMessages)}
     */
    maxLen: max => v => (!v || (v && v.length <= max)) || i18n.t('form.maxLen', [max]),

    /**
     * @param min
     * @return {function(*=): (boolean|*|string | VueI18n.LocaleMessages)}
     */
    minLen: min => v => (!v || (v && v.length >= min)) || i18n.t('form.minLen', [min]),

    /**
     * @param pattern {RegExp}
     * @returns {function (*=): (*|string | VueI18n.LocaleMessages)}
     */
    pattern: pattern => v => (!v || pattern.test(v) || i18n.t('form.pattern')),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    integer: v => (!v || (Number.isInteger(Number(v)) && String(Number(v)) === String(v))) || i18n.t('form.integer'),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    positiveInteger: v => (Number.isInteger(Number(v)) && Number(v) > 0) || i18n.t('form.positiveInteger'),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    number: v => (!v || !Number.isNaN(Number(v)) && !v.endsWith(".")) || i18n.t('form.number'),

    /**
     * @returns {boolean|string|VueI18n.LocaleMessages}
     */
    nonNegativeNumber: v => Number(v) >= 0 || i18n.t('form.nonNegativeNumber'),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    nonNegativeInteger: v => Number.parseInt(v, 10) >= 0 || i18n.t('form.nonNegativeInteger'),

    /**
     * @param max
     * @return {function(*=): (boolean|*|string | VueI18n.LocaleMessages)}
     */
    maxInteger: max => v => (!v || v <= max) || i18n.t('form.maxInteger', [max.toString()]),

    /**
     * @return {boolean|string | VueI18n.LocaleMessages}
     */
    sequenceBegin: value => {
        // Check if number
        if (isNaN(value)) {
            // Check allowed character
            for (const character of value) {
                const unicodeVal = character.charCodeAt(0);
                if (!(unicodeVal >= 32 && unicodeVal <= 126)) {
                    return i18n.t("form.notAllowedChar");
                }
            }
            return true;
        }
        return true;
    },

    /**
     * @param min {Number}
     * @param max {Number}
     * @returns {function (*=): (*|string | VueI18n.LocaleMessages)}
     */
    range: (min, max) => v => (Number.parseFloat(v) >= min && Number.parseFloat(v) <= max)
        || i18n.t('form.range', [min, max]),

    // Suppresses the error message
    suppressMessage: rule => value => {
        return rule(value) === true ? true : false;
    }
};
