<template>
  <div>
    <x-toolbar />
    <v-card :loading="loading">
      <v-skeleton-loader
        v-if="loading"
        loading
        type="text, divider, text, table-heading, table, action"
      />
      <v-container
        v-else
        fluid
      >
        <div v-if="!isEdit">
          <ImportConfigHeader :config="config" />
        </div>
        <v-form
          v-else
          ref="form"
        >
          <FormFields
            :form="form"
            :render="render"
            lang-path="imports.config."
          >
            <template #easyMode-append>
              <CheckBoxHint
                name="imports.config.easyMode"
                hint="imports.hints.easy_mode"
              />
            </template>
            <template #latestOnDuplicity-append>
              <CheckBoxHint
                name="imports.config.latestOnDuplicity"
                hint="imports.hints.latest_on_duplicity"
              />
            </template>
          </FormFields>
        </v-form>
        <ImportConfigComponent
          :config="configCopy"
          :existing-config="isEdit ? null : config"
          :source-file="file"
          :domain="config.domain"
          :parameters="parameters"
          :import-mode="importMode"
          :read-only="!isEdit"
          :config-name="form.name"
          :save-trigger="saveTrigger"
          :show-optional-parameters="isEdit"
          @config-save="handleSaveConfig"
        />
        <v-btn
          v-if="isEdit"
          :loading="loading"
          class="mb-3 ml-3 align-right"
          color="accent"
          @click="createCopy"
        >
          {{ $t('imports.config.save') }}
        </v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {ImportsAPI as API} from "@/api/ImportsAPI";
    import ImportConfigComponent from "@/app/imports/components/ImportConfig.component";
    import FormFields from "@/app/components/form/FormFields.component";
    import {ImportEditForm, ImportEditFormRender} from "@/app/imports/definitions/import.form";
    import {ImportModes} from "@/enum/importModes";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import CheckBoxHint from "@/app/components/CheckBoxHint";
    import {isEasyMode, getImportMode} from "@/utils/importMode";
    import ImportConfigHeader from "./components/ImportConfigHeader";


    export default {
        name: "ImportConfig",
        components: {ImportConfigHeader, CheckBoxHint, FormFields, ImportConfigComponent},
        mixins: [RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false,
            }
        },
        data: () => ({
            loading: true,
            config: null,
            saveTrigger: 0,
            configCopy: null,
            parameters: null,
            file: null,
            domain: '',
            form: ImportEditForm(),
            render: ImportEditFormRender(),

        }),
        computed: {
            easyMode() {
                if (!this.config) {
                    return true;
                }
                return isEasyMode(this.config.import_mode);
            },
            importMode() {
                return this.isEdit ? getImportMode(this.form.easyMode, this.form.latestOnDuplicity) : this.config.import_mode;
            }
        },
        createdOrActivated() {
            this.getConfig();
        },
        methods: {
            getConfig() {
                this.loading = true;
                API.getConfig(this.configId)
                    .then(res => {
                        this.config = res.data;
                        if (this.isEdit) {
                            this.copyConfig();
                        } else {
                            this.loading = false;
                        }
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/imports');
                    });
            },
            handleSaveConfig(is_success) {
                if (is_success !== false) {
                    this.$router.push('/imports');
                } else {
                    this.snack('imports.attribute_error');
                }
            },
            copyConfig() {
                fetch(this.config.download_link)
                    .then(res => res.blob())
                    .then(blob => {
                        this.file = blob;
                        this.domain = this.config.domain;
                        this.form.name = this.config.name + ' (copy)';
                        this.form.easyMode = isEasyMode(this.config.import_mode);
                        this.form.latestOnDuplicity = this.config.import_mode & ImportModes.LATEST_ON_DUPLICITY;
                        this.configCopy = this.config;
                        this.parameters = this.config.parameters;
                        this.loading = false;
                    });
            },
            createCopy() {
                if (!this.$refs.form.validate()) {
                    return;
                }
                const data = {
                    name: this.form.name,
                    domain: this.domain,
                    file: this.file,
                    importMode: this.importMode
                };
                API.createConfig(data)
                    .then(res => {
                        const configId = res.headers.location.split('/').pop();
                        this.configCopy.id = configId;
                        API.getConfig(configId)
                            .then(() => {
                                this.saveTrigger++;
                            });
                    }).catch(err => {
                        this.snack(err);
                    });
            }
        }
    };
</script>
