import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"text-subtitle-1"},[_c('div',[_c(VBtn,{staticClass:"mr-2",attrs:{"fab":"","outlined":"","small":"","type":"submit","to":'/suppliers/' + _vm.supplierId + '/update'}},[_c(VIcon,[_vm._v(" $updateItem ")])],1),_vm._v(" "+_vm._s(_vm.details.name)+" ")],1)]),_c(VExpansionPanelContent,[_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"sm6":"","xs12":""}},[(_vm.details.ico)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.ico') + ': '))]),_vm._v(" "+_vm._s(_vm.details.ico)+" ")]):_vm._e(),(_vm.details.dic)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.dic') + ': '))]),_vm._v(" "+_vm._s(_vm.details.dic)+" ")]):_vm._e(),(_vm.details.phone)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.phone') + ': '))]),_vm._v(" "+_vm._s(_vm.details.phone)+" ")]):_vm._e(),(_vm.details.email)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.email') + ': '))]),_vm._v(" "+_vm._s(_vm.details.email)+" ")]):_vm._e(),(_vm.details.website)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.website') + ': '))]),_vm._v(" "+_vm._s(_vm.details.website)+" ")]):_vm._e(),(_vm.addressInline(_vm.details.billing_address) !== '')?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('suppliers.billing_address') + ': '))]),_vm._v(" "+_vm._s(_vm._f("addressInline")(_vm.details.billing_address))+" ")]):_vm._e()]),_c(VFlex,{attrs:{"sm6":"","xs12":""}},[_c('EntityHistory',{attrs:{"changes":_vm.details}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }