<template>
  <v-card
    :class="{'ma-1': !inline}"
    :flat="inline"
  >
    <v-container
      fluid
      :class="{'ma-0 pa-0': inline}"
    >
      <span class="text-caption">{{ $t('tasks.notes.name') + ': ' }}</span>
      <v-list
        dense
      >
        <template v-for="note of notes">
          <v-list-group
            :key="note.id"
          >
            <template #activator>
              <v-icon class="mr-3">
                {{ note.created_by.is_chief ? roleIcons[Roles.CHIEF] : roleIcons[Roles.STOREKEEPER] }}
              </v-icon>
              <span class="body-2">
                {{ note.text }}
              </span>
            </template>
            <v-container
              fluid
              class="text-caption"
            >
              {{ note.created_by | fullName }}
              <DateTimeWithTooltip :date-time="note.created_at" />
            </v-container>
          </v-list-group>
        </template>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import {roleIcons} from "@/enum/icons";
    import {Roles} from "@/enum/roles";

    export default {
        name: "Notes",
        components: {DateTimeWithTooltip},
        props: {
            notes: {
                type: Array,
                default: () => []
            },
            inline: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            roleIcons: roleIcons,
            Roles: Roles
        })
    };
</script>

<style scoped>

</style>
