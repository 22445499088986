<template>
  <div>
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('tasks.deliveryAccept.show.label') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :api-filter="apiFilter"
            :search-hint="$t('tasks.deliveryAccept.searchHint')"
          >
            <template #item.id="{ item }">
              #{{ item.id }}
            </template>
            <template #item.priority="{ item }">
              <div :class="'p' + item.priority">
                {{ item.priority | taskPriority }}
              </div>
            </template>
            <template #item.delivery_type="{ item }">
              {{ item.delivery_type | deliveryType }}
            </template>
            <template #item.external_order.id="{ item }">
              <span @click.stop>
                <router-link
                  v-if="item.external_order_task_id"
                  :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_task_id"
                >
                  #{{ item.external_order_task_id }}
                </router-link>
              </span>
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {TaskDeliveryAcceptAPI} from "@/api/TaskDeliveryAcceptAPI";
    import {createHeaders} from "@/utils/table";
    import {deliveryAcceptTasksTable} from "@/app/tasks/deliveryAccept/definitions/deliveryAccept.form";
    import {UserAPI} from "@/api/UserAPI";
    import {CreatedOrActivatedMixin} from "@/app/mixins/CreatedOrActivatedMixin";
    import {taskLinks, taskTypes} from "@/enum/task_type";

    export default {
        name: 'SupplierDeliveryAcceptTasksList',
        mixins: [RouteParamsMapperMixin, CreatedOrActivatedMixin],
        data: () => ({
            loading: true,
            items: [],
            users: [],
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            headers: function () {
                return createHeaders(deliveryAcceptTasksTable(this.users), 'tasks.');
            },
            actions: function () {
                return [
                    {
                        routerLink: item => '/delivery-accept/' + item.id,
                        icon: '$showItem',
                        label: 'base.view',
                    }
                ];
            },
            apiDataSource: function () {
                return TaskDeliveryAcceptAPI.getAll.bind(
                    TaskDeliveryAcceptAPI,
                    this.supplierId,
                    {...this.apiFilterParam, sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return TaskDeliveryAcceptAPI.getAllPages.bind(TaskDeliveryAcceptAPI, this.supplierId, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return {filter: APIFilters.makeFilter(this.apiFilter)};
            },
            apiFilter: function () {
                return [{
                    [APIFilterOP.EQUALS]: {
                        'supplier.id': this.supplierId
                    }
                }];
            }
        },
        createdOrActivated: function () {
            this.loading = true;
            UserAPI.getAll()
                .then(response => this.users = response.data)
                .finally(() => this.loading = false);
        }
    };
</script>

<style scoped>

</style>