import {AddressForm} from "@/app/components/form/definitions/address.form";
import {TableFilter} from "@/enum/table_filter";

function SupplierForm() {
    return {
        name: '',
        ico: null,
        dic: null,
        phone: null,
        email: null,
        website: null,
        billing_address: new AddressForm
    };
}

function SupplierRender() {
    return {
        ico: {
            icon: '$ico',
            type: 'number',
            max: 30,
            appendOuter: true
        },
        name: {
            icon: '$itemName',
            max: 100,
            required: true
        },
        dic: {
            icon: '$dic',
            max: 30
        },
        phone: {
            icon: '$phone',
            max: 20
        },
        email: {
            icon: '$email',
            type: 'email',
            max: 50
        },
        website: {
            icon: '$website'
        }
    };
}

const supplierTable = {
    name: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    ico: {
        filterType: TableFilter.NUMBER,
        showInHint: true
    },
    dic: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    phone: {
        filterType: TableFilter.NUMBER,
        showInHint: true
    },
    email: {
        filterType: TableFilter.TEXT,
        showInHint: true
    },
    website: {
        filterType: TableFilter.TEXT,
        showInHint: true
    }
};

export {SupplierForm, SupplierRender, supplierTable};
